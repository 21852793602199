/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import * as APIS from "../../utils/service";
import { findJson } from "../../utils/utils";
import { open, close } from "../../redux/popupSlice";
import { openSub } from "../../redux/subPopupSlice";
import { imgOpen } from "../../redux/imgSlice";
import Input from "../../components/Input";
import InputSelect from "../../components/InputSelect";
import TextArea from "../../components/TextArea";
import images from "../../libs/images";
import consts from "../../libs/consts";
import NoticeUpdate from "./NoticeUpdate";

export default function NoticeView(props) {
  const { x, type } = props;
  const dispatch = useDispatch();
  const userData = useSelector((s) => s.usersReducer);
  const [id, setId] = useState(x.notice_table_id);
  const [reg_date, setreg_date] = useState(x.reg_date);
  const [update_date, setupdate_date] = useState(x.update_date);

  const [state, setState] = useState(x.state);
  const [noticetype, setNoticeType] = useState(x.n_type);
  const [noticetypeText, setNoticeTypeText] = useState(
    findJson(consts.noticeTypeUser, x.n_type)
  );
  const [title, setTitle] = useState(x.title);
  const [content, setContent] = useState(x.contents);
  const [url, setUrl] = useState(x.link);
  const btn = x.btn * 1;
  const [btn_name, setBtn_name] = useState(x.btn_name);

  const [talkMsg, setTalkMsg] = useState("2회 / 2022.02.02 13:13");

  const detail_img = [{ src: images.mask11 }, { src: images.mask12 }];
  const [error, setError] = useState("");

  const [sdate, setSdate] = useState(x.start_date); // 시공시작 예정일
  const [edate, setEdate] = useState(x.end_date); // 시공종료 예정일

  const deleteFunc = () => {
    dispatch(
      openSub({
        message: "해당 공지를 삭제 하시겠습니까?",
        buttonCencle: "취소",
        onPress: () => {
          // alert("삭제!");
          const sender = {
            notice_table_id: id,
            admin_table_id: userData.admin_table_id,
          };

          APIS.postData("/AdminNotice/NoticeDelete", sender, 1)
            .then(() => {
              dispatch(close());
            })
            .catch((e) => {
              console.log("catch", e);
            });
        },
      })
    );
  };

  const talkSend = () => {
    dispatch(
      openSub({
        message:
          "파트너에게 공지를 알림톡으로 발송하시겠습니까?\n이벤트 공지에 경우 마케팅 수신동의를 한 파트너에게 알림톡이 발송됩니다.",
        messageAuto: true,
        buttonCencle: "취소",
      })
    );
  };

  const updateFunc = () => {
    dispatch(
      open({
        title: type === 1 ? "파트너 공지 수정" : "사용자 공지 수정",
        mideum: true,
        component: <NoticeUpdate x={x} w={"u"} type={type} />,
      })
    );
  };

  useEffect(() => {
    setSdate(x.start_date);
    setEdate(x.end_date);
  }, [type]);

  return (
    <form className="popup_form">
      {type === 1 ? (
        <div className="admin_pay_form_list">
          <button
            type="button"
            className="btn btn2 popup_form_delete_button"
            onClick={() => deleteFunc()}
          >
            삭제
          </button>

          <Input
            className="input_text"
            type="text"
            placeholder="내용을 입력해 주세요."
            name="id"
            value={id}
            setValue={setId}
            label="공지번호"
            readOnly={true}
          />
          <Input
            className="input_text"
            type="text"
            placeholder="내용을 입력해 주세요."
            name="start_date"
            value={
              moment(sdate).format("YYYY-MM-DD") === "Invalid date"
                ? "x"
                : moment(sdate).format("YYYY.MM.DD")
            }
            setValue={setSdate}
            label="공지 시작일"
            readOnly={true}
          />
          <Input
            className="input_text"
            type="text"
            placeholder="내용을 입력해 주세요."
            name="end_date"
            value={
              moment(edate).format("YYYY-MM-DD") === "Invalid date"
                ? "x"
                : moment(edate).format("YYYY.MM.DD")
            }
            setValue={setEdate}
            label="공지 종료일"
            readOnly={true}
          />
          <Input
            className="input_text"
            type="text"
            placeholder="내용을 입력해 주세요."
            name="reg_date"
            value={moment(reg_date).format("YYYY-MM-DD HH:mm")}
            setValue={setreg_date}
            label="등록일시"
            readOnly={true}
          />
          <Input
            className="input_text"
            type="text"
            placeholder="내용을 입력해 주세요."
            name="update_date"
            value={moment(update_date).format("YYYY-MM-DD HH:mm")}
            setValue={setupdate_date}
            label="최종일시"
            readOnly={true}
          />
          <Input
            className="input_text"
            type="text"
            placeholder="내용을 입력해 주세요."
            name="talkMsg"
            value={talkMsg}
            setValue={setTalkMsg}
            label="알림톡 발송여부"
            readOnly={true}
            withButton={"발송"}
            withButtonPress={talkSend}
          />
          <InputSelect
            name="type"
            label="공지유형"
            value={noticetype}
            setValue={setNoticeType}
            option={consts.noticeTypeOption}
            readOnly={true}
          />
          <InputSelect
            name="type"
            label="공지상태"
            value={state}
            setValue={setState}
            option={consts.themeState}
            readOnly={true}
          />
          <Input
            className="input_text"
            type="text"
            placeholder="내용을 입력해 주세요."
            name="title"
            value={title}
            setValue={setTitle}
            label="제목"
            error={error}
            setError={setError}
            readOnly={true}
          />
          <TextArea
            className="input_textarea"
            placeholder="내용을 입력해 주세요. (2000자 이내)"
            name="content"
            value={content}
            setValue={setContent}
            label="내용"
            maxlength={2000}
            readOnly={true}
          />

          <div className="input_box input_box_full">
            <label className="input_label" style={{ marginBottom: "6px" }}>
              사진
            </label>
            <div className="input_section_multi">
              {detail_img.map((x, i) => {
                return (
                  <div key={i} className="input_multi_preview">
                    <img
                      src={x.src}
                      alt="input_multi_preview"
                      onClick={() =>
                        dispatch(imgOpen({ imgs: detail_img, active: i }))
                      }
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <div className="admin_pay_form_list">
          <button
            type="button"
            className="btn btn2 popup_form_delete_button"
            onClick={() => deleteFunc()}
          >
            삭제
          </button>

          <Input
            className="input_text"
            type="text"
            placeholder="내용을 입력해 주세요."
            name="id"
            value={id}
            setValue={setId}
            label="공지번호"
            readOnly={true}
          />
          <Input
            className="input_text"
            type="text"
            placeholder="내용을 입력해 주세요."
            name="start_date"
            value={
              moment(sdate).format("YYYY-MM-DD") === "Invalid date"
                ? "x"
                : moment(sdate).format("YYYY.MM.DD")
            }
            setValue={setSdate}
            label="공지 시작일"
            readOnly={true}
          />
          <Input
            className="input_text"
            type="text"
            placeholder="내용을 입력해 주세요."
            name="end_date"
            value={
              moment(edate).format("YYYY-MM-DD") === "Invalid date"
                ? "x"
                : moment(edate).format("YYYY.MM.DD")
            }
            setValue={setEdate}
            label="공지 종료일"
            readOnly={true}
          />
          <Input
            className="input_text"
            type="text"
            placeholder="내용을 입력해 주세요."
            name="reg_date"
            value={moment(reg_date).format("YYYY-MM-DD HH:mm")}
            setValue={setreg_date}
            label="등록일시"
            readOnly={true}
          />
          <Input
            className="input_text"
            type="text"
            placeholder="내용을 입력해 주세요."
            name="update_date"
            value={moment(update_date).format("YYYY-MM-DD HH:mm")}
            setValue={setupdate_date}
            label="최종일시"
            readOnly={true}
          />

          <Input
            className="input_text"
            type="text"
            placeholder="내용을 입력해 주세요."
            name="noticetypeText"
            value={noticetypeText}
            setValue={setNoticeTypeText}
            label="공지유형"
            readOnly={true}
          />
          <InputSelect
            name="type"
            label="공지상태"
            value={state}
            setValue={setState}
            option={consts.themeState}
            readOnly={true}
          />
          {/*<div className=" popup_instance_step " style={{padding:0, borderBottom:0}}>*/}
          {/*    <div className="instance_form_list estimate_form_list">*/}
          {/*        <InputDate type="text" placeholder="날짜선택" name="sdate" label="공지 시작일" dateValue={ moment(x.work_start_date).format('YYYY-MM-DD') } setDateValue={ setSdate } readOnly={true}/>*/}
          {/*        <InputDate type="text" placeholder="날짜선택" name="edate" label="공지 종료일" dateValue={ moment(x.work_end_date).format('YYYY-MM-DD') } setDateValue={ setEdate } readOnly={true}/>*/}
          {/*    </div>*/}
          {/*</div>*/}

          <Input
            className="input_text"
            type="text"
            placeholder="내용을 입력해 주세요."
            name="title"
            value={title}
            setValue={setTitle}
            label="제목"
            error={error}
            setError={setError}
            readOnly={true}
          />
          {/* <TextArea className="input_textarea" placeholder="내용을 입력해 주세요. (2000자 이내)" name="content" value={content} setValue={setContent} label="내용" maxlength={2000} readOnly={true}/> */}

          <Input
            className="input_text"
            type="text"
            placeholder="내용을 입력해 주세요."
            name="url"
            value={url}
            setValue={setUrl}
            label="링크"
            readOnly={true}
          />

          <Input
            className="input_text"
            type="text"
            placeholder="20자 이내로 입력하세요."
            name="btn_name"
            value={btn ? btn_name : "미사용"}
            setValue={setBtn_name}
            label="버튼명"
            readOnly={!btn}
            maxlength={20}
          />

          {x.main_img && (
            <div className="input_box input_box_full">
              <label className="input_label" style={{ marginBottom: "6px" }}>
                사진
              </label>
              <div className="input_section_multi">
                <div className="input_multi_preview">
                  <img
                    src={x.main_img}
                    alt="input_multi_preview"
                    onClick={() =>
                      dispatch(imgOpen({ imgs: [{ src: x.main_img }] }))
                    }
                  />
                </div>
              </div>
            </div>
          )}
          {x.main_mobile && (
            <div className="input_box input_box_full">
              <label className="input_label" style={{ marginBottom: "6px" }}>
                모바일 사진
              </label>
              <div className="input_section_multi">
                <div className="input_multi_preview">
                  <img
                    src={x.main_mobile}
                    alt="input_multi_preview"
                    onClick={() =>
                      dispatch(imgOpen({ imgs: [{ src: x.main_mobile }] }))
                    }
                  />
                </div>
              </div>
            </div>
          )}
          {x.detail_img && (
            <div className="input_box input_box_full">
              <label className="input_label" style={{ marginBottom: "6px" }}>
                상세사진
              </label>
              <div className="input_section_multi">
                <div className="input_multi_preview">
                  <img
                    src={x.detail_img}
                    alt="input_multi_preview"
                    onClick={() =>
                      dispatch(imgOpen({ imgs: [{ src: x.detail_img }] }))
                    }
                  />
                </div>
              </div>
            </div>
          )}
          {x.detail_mobile && (
            <div className="input_box input_box_full">
              <label className="input_label" style={{ marginBottom: "6px" }}>
                모바일 상세사진
              </label>
              <div className="input_section_multi">
                <div className="input_multi_preview">
                  <img
                    src={x.detail_mobile}
                    alt="input_multi_preview"
                    onClick={() =>
                      dispatch(imgOpen({ imgs: [{ src: x.detail_mobile }] }))
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      <div className="popup_btn">
        <button
          type="button"
          className="popbtn cencle_btn"
          onClick={() => {
            dispatch(close());
          }}
          style={{ width: "100%", marginRight: "10px" }}
        >
          취소
        </button>
        <button
          type="button"
          className="popbtn ok_btn"
          onClick={() => {
            updateFunc();
          }}
          style={{ width: "100%" }}
        >
          수정
        </button>
      </div>
    </form>
  );
}
