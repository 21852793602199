import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import Modal from "../../../Common/ModalPortal";
import images from "../../../../libs/images";
import Button from "../../../Common/Button/Button";
import * as Colors from "../../../../constants/color";
import { marginTopStyles } from "../../home.style";
import Input from "../../../Common/Input/Input";
import Upload from "../../../Common/Upload/Upload";
import Select from "../../../Common/Select/Select";
import consts from "../../../../libs/consts";
import useHomeManagePick, { List } from "../homeManagePick.hook";
import { handlerImgError } from "../../../../utils/home-manage";

const Container = styled.div`
  width: 560px;
  background-color: #ffffff;
  margin: auto;
`;

const Header = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${Colors.gray_200};

  & > h3 {
    margin: auto 0;
  }
`;

const Content = styled.div`
  padding: 10px 20px 30px 20px;
`;

const Footer = styled.div`
  padding: 20px;
  border-top: 1px solid ${Colors.gray_200};
  display: flex;
  justify-content: center;
  gap: 15px;
`;

interface Props {
  close: () => void;
  id: string;
  callback: () => void;
}

const UpdateHomeManagePick = (props: Props) => {
  const { close, id, callback } = props;

  const { detail, update } = useHomeManagePick({
    id,
  });

  const [item, setItem] = useState<Omit<List, "update_date">>({
    pick_table_id: "",
    pick_img: "",
    pick_name: "",
    state: "",
    pick_url: "",
  });

  useEffect(() => {
    if (detail) {
      setItem({ ...detail });
    }
  }, [detail]);

  const changeInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setItem((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const changeSelectHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setItem((prevData) => ({
      ...prevData,
      state: e.target.value,
    }));
  };

  const updateHandler = async (): Promise<void> => {
    const { pick_name, pick_img, pick_url, state } = item;

    const params = {
      pick_table_id: id,
      pick_name,
      pick_img,
      pick_url,
      state,
    };

    const res = await update(params);

    if (res) {
      callback();
    }
  };

  if (!detail) {
    return <></>;
  }

  return (
    <Modal>
      <Container>
        <Header>
          <h3>픽 메뉴 변경</h3>
          <button onClick={close}>
            <img src={images.exit} alt="exit_btn" />
          </button>
        </Header>
        <Content>
          <div css={[marginTopStyles]}>
            <Select
              label="* 등록상태"
              options={consts.notice_avt_state.map((notice) => ({
                value: notice.idx,
                label: notice.title,
              }))}
              value={item?.state}
              onChange={changeSelectHandler}
            />
          </div>
          <div css={[marginTopStyles]}>
            <Input
              label="* 메뉴 명"
              placeholder="키워드 작성"
              size="small"
              name="pick_name"
              value={item?.pick_name}
              onChange={changeInputHandler}
            />
          </div>
          <div css={[marginTopStyles]}>
            <Upload
              id="upload"
              label="* 이미지 등록 (140 X 140)"
              placeholder="사진을 첨부해주세요."
              preview={item.pick_img}
              deletePreivew={() => setItem({ ...item, pick_img: "" })}
              callback={(file) =>
                setItem({ ...item, pick_img: file.fileImage })
              }
              onError={handlerImgError}
            />
          </div>
          <div css={[marginTopStyles]}>
            <Input
              label="* 연결 URL"
              placeholder="https://www.layhome.co.kr/EventPage?id=28"
              size="big"
              name="pick_url"
              value={item?.pick_url}
              onChange={changeInputHandler}
            />
          </div>
        </Content>
        <Footer>
          <Button theme="tertiary" onClick={close}>
            취소
          </Button>
          <Button onClick={updateHandler}>변경</Button>
        </Footer>
      </Container>
    </Modal>
  );
};

export default UpdateHomeManagePick;
