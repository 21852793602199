import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as APIS from "../../utils/service";
import NoticeView from "./NoticeView";
import NoticeAdd from "./NoticeAdd";
import { open } from "../../redux/popupSlice";

/**
 *
 * @param type 1: 'partner' 2: 'user'
 */

const useNotice = (type: 1 | 2) => {
  const dispatch = useDispatch();
  const userData = useSelector((s: any) => s.usersReducer);

  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);

  const params = new URLSearchParams(window.location.search);
  const page = params.get("page") || "1";

  const dataFunc = useCallback(async () => {
    const sender = {
      admin_table_id: userData.admin_table_id,
      user_type: type,
      page: page,
    };

    try {
      const res = await APIS.postData("/AdminNotice/NoticeList", sender, 1);

      if (res.data.code === "OK") {
        setList(res.data.notice_list);
        setTotal(Number(res.data.total_cnt));
      } else {
        setList([]);
      }
    } catch (err) {
      console.log(err);
    }
  }, [page, type, userData.admin_table_id]);

  useEffect(() => {
    dataFunc();
  }, [page, dataFunc]);

  const viewFunc = async (id: number | string): Promise<void> => {
    const sender = {
      admin_table_id: userData.admin_table_id,
      notice_table_id: id,
    };

    try {
      const res = await APIS.postData("/AdminNotice/NoticeTarget", sender, 1);

      if (res.data.code === "OK" && res.data.notice_list.length > 0) {
        dispatch(
          open({
            title: type === 1 ? "파트너 공지 상세" : "사용자 공지 상세",
            mideum: true,
            component: (
              <NoticeView x={res.data.notice_list[0]} w={"u"} type={type} />
            ),
          })
        );
      } else {
        dispatch(
          open({
            message: "공지 정보가 없습니다.",
            button: "확인",
          })
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const addFunc = () => {
    dispatch(
      open({
        title: type === 1 ? "파트너 공지 등록" : "사용자 공지 등록",
        mideum: true,
        component: <NoticeAdd x={""} w={"i"} type={type} />,
      })
    );
  };

  return {
    list,
    viewFunc,
    addFunc,
    total,
    page,
  };
};

export default useNotice;
