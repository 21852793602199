/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { css } from "@emotion/react";
import Header from "./Header";
import Footer from "../Footer";
import AdminMenu from "./Menu";
import Tabs from "../../Common/Tabs/Tabs";
import { Header as TitleHeader } from "../../Common/Typography";
import * as Colors from "../../../constants/color";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

interface AdminLayoutProps {
  children: React.ReactNode;
  title?: string;
  tabs?: {
    id: number;
    title: string;
    as?: string;
    callback?: () => void;
    isActive?: boolean;
  }[];
}

const AdminLayout = (props: AdminLayoutProps) => {
  const { children, title, tabs } = props;
  const userData = useSelector((s: any) => s.usersReducer);

  const navigate = useNavigate();

  useEffect(() => {
    if (userData && !userData.grade) {
      navigate("/");
    }
  }, []);

  return (
    <div>
      <Header />
      <AdminMenu />
      <div
        css={css`
          padding: 52px 0 0 220px;
          width: 100%;
          min-height: 80vh;
        `}
      >
        {title && (
          <div
            css={css`
              padding: 28px 0 0 26px;
              border-bottom: 1px solid ${Colors.gray_200};
            `}
          >
            <div
              css={css`
                margin-bottom: 25px;
              `}
            >
              <TitleHeader strong>{title}</TitleHeader>
            </div>
            {tabs && tabs.length > 0 && (
              <div>
                <Tabs
                  datas={tabs}
                  type="horizontal"
                  activeText={String(tabs.find((tab) => tab.isActive)?.title)}
                  underline
                  activeColor="primary"
                />
              </div>
            )}
          </div>
        )}
        <div
          css={css`
            padding: 0 30px 30px 30px;
            min-height: 65vh;
          `}
        >
          {children}
        </div>
        <footer
          css={css`
            position: relative;
          `}
        >
          <Footer style={{ transform: "none" }} />
        </footer>
      </div>
    </div>
  );
};

export default AdminLayout;
