import { css } from "@emotion/react";
import * as Colors from "../../constants/color";

const stepStyles = css`
  display: inline-block;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: ${Colors.black};
  color: ${Colors.white};
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  line-height: 26px;
`;

const stepTitleStyles = css`
  margin: auto 0 auto 20px;
`;

const marginTopStyles = css`
  margin-top: 20px;
`;

const labelStyles = css`
  font-size: 12px;
  color: ${Colors.blue_600};
  font-weight: bold;
`;

export { stepStyles, stepTitleStyles, marginTopStyles, labelStyles };
