import React, { useState } from "react";
import { css } from "@emotion/react";
import Table from "../../Common/Table/Table";
import Pagination from "../../Common/Pagination/Pagination";
import moment from "moment";
import Select from "../../Common/Select/Select";
import useHomeManageHero, { List } from "./homeManageHero.hook";
import Button from "../../Common/Button/Button";
import DateRange from "../../Common/Date/DateRange";
import Input from "../../Common/Input/Input";
import useSeq from "../seq.hook";
import {
  getEventState,
  getEventType,
  handlerImgError,
} from "../../../utils/home-manage";
import { eventTypeList, eventStateList } from "../../../constants/home-manage";
import { CSVLink } from "react-csv";
import useCsv from "../../../hooks/useCsv";

const HomeManageHero = () => {
  const [avtState, setAvtState] = useState("");
  const [avtType, setAvtType] = useState("");
  const [partnerName, setPartnerName] = useState("");

  const [dateData, setDateData] = useState({
    start_date: "",
    end_date: "",
  });

  const { list, page, total, search, updateSeq } = useHomeManageHero({
    start_date: dateData.start_date,
    end_date: dateData.end_date,
    avt_state: avtState,
    avt_type: avtType,
    seller_biz_name: partnerName,
  });

  const { csvLinkRef, getCsvHeader, downloadHandler } = useCsv();

  const { seqList, isUpdateSeq, updateSeqHandler, updateTableInputHandler } =
    useSeq<List>({
      list: list,
      key_id: "hero_banner_id",
      key_seq: "display_seq",
    });

  const col = [
    { key: "hero_banner_id", title: "이벤트 번호" },
    {
      key: "avt_type",
      title: "구분",
      render: (data: any) => <span>{getEventType(data.avt_type)}</span>,
    },
    { key: "seller_biz_name", title: "파트너 명" },
    {
      key: "hero_thumbnail",
      title: "썸네일",
      render: (data: any) => (
        <div
          css={css`
            width: 40px;
            height: 40px;
          `}
        >
          {data.hero_thumbnail && (
            <img
              src={data.hero_thumbnail}
              onError={handlerImgError}
              alt="pick_img"
              css={css`
                width: 100%;
                height: 100%;
              `}
            />
          )}
        </div>
      ),
    },
    { key: "avt_title", title: "타이틀 명" },
    {
      key: "avt_days",
      title: "운영기간",
    },
    {
      key: "remain_day",
      title: "잔여 운영일",
    },
    {
      key: "state",
      title: "상태",
      render: (data: any) => <span>{getEventState(data.avt_state)}</span>,
    },
    {
      key: "display_seq",
      title: "노출 순위",
      className: "table-td-seq",
      render: (data: any) => (
        <div
          onClick={(e) => e.stopPropagation()}
          css={css`
            width: 100px;
          `}
        >
          {isUpdateSeq ? (
            <Input
              value={seqList.find((seq) => seq.id === data.hero_banner_id)?.seq}
              onChange={(e) => updateTableInputHandler(data.hero_banner_id, e)}
              size="full"
              placeholder="입력"
            />
          ) : (
            <span>{data.display_seq}</span>
          )}
        </div>
      ),
    },
  ];

  const changeDateHandler = (type: string, date?: Date) => {
    if (date) {
      setDateData((prevData) => ({
        ...prevData,
        ...(type === "start" && {
          start_date: moment(date).format("YYYY-MM-DD 00:00:00"),
        }),
        ...(type === "end" && {
          end_date: moment(date).format("YYYY-MM-DD 23:59:59"),
        }),
      }));
    }
  };

  const onClickUpdateSeq = async (): Promise<void> => {
    const changedList = seqList.filter((item) => item.isChanged);

    if (changedList.length === 0) {
      alert("노출순위를 변경하는 데이터가 존재하지 않습니다.");
      return;
    }

    const params = changedList.map((item) => ({
      hero_banner_id: item.id,
      display_seq: item.seq,
    }));

    const res = await updateSeq(params);

    if (res) {
      alert("변경이 완료 되었습니다.");
      updateSeqHandler(false);
      search();
    } else {
      alert("노출 순위 변경 중 오류가 발생하였습니다.");
    }
  };

  return (
    <div>
      <div
        css={css`
          margin-top: 24px;
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
        `}
      >
        <div>
          <DateRange
            label="날짜 선택"
            startPlaceholder="시작날짜"
            startValue={
              dateData.start_date ? new Date(dateData.start_date) : ""
            }
            endPlaceholder="종료날짜"
            endValue={dateData.end_date ? new Date(dateData.end_date) : ""}
            onChange={changeDateHandler}
          />
        </div>
        <div
          css={css`
            width: 130px;
          `}
        >
          <Select
            label="구분"
            defaultValue="전체"
            options={eventTypeList}
            size="full"
            onChange={(e) => setAvtType(e.target.value)}
          />
        </div>
        <div
          css={css`
            width: 130px;
          `}
        >
          <Select
            label="상태"
            defaultValue="전체"
            options={eventStateList}
            onChange={(e) => setAvtState(e.target.value)}
            size="full"
          />
        </div>
        <div>
          <Input
            label="파트너 명"
            placeholder="입력"
            size="small"
            onChange={(e) => setPartnerName(e.target.value)}
          />
        </div>
        <div
          css={css`
            margin: auto 0 0 0;
          `}
        >
          <Button theme="tertiary" onClick={search}>
            검색
          </Button>
        </div>
        <div
          css={css`
            margin: auto 0 0 0;
          `}
        >
          <Button theme="tertiary" onClick={() => downloadHandler()}>
            엑셀 다운로드
          </Button>
          <CSVLink
            data={list.map((l) => ({
              ...l,
              avt_type: getEventType(l.avt_type),
              state: getEventState(l.avt_state),
            }))}
            ref={csvLinkRef}
            headers={getCsvHeader(col)}
            filename={
              "홈화면관리_히어로배너_" +
              moment(new Date()).format("YYYY_MM_DD(HH)") +
              ".csv"
            }
          />
        </div>
      </div>
      <div
        css={css`
          margin: 30px 0;
        `}
      >
        <div
          css={css`
            margin-bottom: 10px;
            display: flex;
            justify-content: end;
          `}
        >
          {isUpdateSeq ? (
            <div
              css={css`
                display: flex;
                gap: 20px;
              `}
            >
              <Button
                theme="tertiary"
                size="small"
                onClick={() => updateSeqHandler(false)}
              >
                취소
              </Button>
              <Button size="small" onClick={onClickUpdateSeq}>
                저장
              </Button>
            </div>
          ) : (
            <Button size="small" onClick={() => updateSeqHandler(true)}>
              노출순위 변경
            </Button>
          )}
        </div>
        <Table columns={col} datas={list} />
      </div>
      <Pagination curPage={page} total={total} pageSize={20} />
    </div>
  );
};

export default HomeManageHero;
