import React, { useState } from "react";

import moment from "moment";

export default function ScheduleList(props) {
  const [more, setMore] = useState(false); // 접기 펼치기 상태

  return (
    <div className="day_top">
      <p className="title">다가올 주요일정</p>
      <ul className={more ? "day_soon_ul active" : "day_soon_ul"}>
        {props.list.length > 0 ? (
          props.list.map((x, i) => {
            if (x.state < 6) {
              return (
                <li
                  key={i}
                  onClick={() => {
                    props.updateFunc(x.order_table_id);
                  }}
                >
                  <p className={"title cl_01"}>
                    <span>•</span>
                    {x.order_work}
                  </p>
                  <div className="date">
                    [
                    {x.meet_date === "0000-00-00 00:00:00" || null
                      ? "미기입"
                      : x.state < 6
                      ? moment(x.meet_date).format("YYYY-MM-DD HH:mm")
                      : moment(x.work_start_date).format("YYYY-MM-DD") +
                        "~" +
                        moment(x.work_end_date).format("YYYY-MM-DD")}
                    ]
                  </div>
                  <p className="addr">
                    {x.apt === null ? "아파트 이름x" : x.apt}({x.pyung}평)/
                    {x.area}
                  </p>
                  <p className="state">{x.order_state_word}</p>
                </li>
              );
            } else return null;
          })
        ) : (
          <li key="null" className="null">
            <p>주요일정이 없습니다.</p>
          </li>
        )}
      </ul>
      <button
        type="button"
        className={more ? "m_more_btn active" : "m_more_btn"}
        onClick={() => setMore(!more)}
      >
        {more ? "접기" : "펼치기"}
      </button>
    </div>
  );
}
