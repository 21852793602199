/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import * as APIS from "../../../utils/service";
import { useSelector } from "react-redux";
import debounce from "lodash/debounce";
import { useNavigate } from "react-router-dom";

export interface List {
  area_detail_id: string;
  area_id: string;
  evt_name: string;
  evt_table_id: string;
  evt_type: string;
  order_table_id: string;
  reg_date: string;
  request_desc: string;
  seller_biz_name: string;
  seller_table_id: string;
  size: string;
  state: string;
  update_date: string;
  user_name: string;
  user_phone: string;
  work_date: string;
  admin_desc: string;
}

interface Props {
  isList?: boolean;
  searchData?: {
    evt_type?: string;
    state?: string;
    evt_name?: string;
    seller_biz_name?: string;
    user_name?: string;
    user_phone?: string;
  };
  id?: string;
}

const useEventDb = (props?: Props) => {
  const { isList, searchData, id } = props ?? {};
  const userData = useSelector((s: any) => s.usersReducer);
  const navigate = useNavigate();
  const { pathname } = window.location;

  const [list, setList] = useState<List[]>([]);
  const [total, setTotal] = useState(0);

  const params = new URLSearchParams(window.location.search);
  const page = params.get("page") || "1";

  const [detail, setDetail] = useState<List>();

  const getList = async (page: string) => {
    const params = {
      admin_table_id: userData.admin_table_id,
      page: page,
      ...searchData,
    };

    try {
      const res = await APIS.postData("/EventAp/EventTotalList", params, 1);

      if (res.data.code === "OK") {
        setList(res.data.event_db_list);
        setTotal(res.data.event_cnt);
      } else {
        setList([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (isList) {
      getList(page);
    }
  }, [page]);

  const getTarget = async (): Promise<void> => {
    try {
      const res = await APIS.postData("/EventAp/EventTotalTarget", {
        admin_table_id: userData.admin_table_id,
        evt_table_id: id,
      });

      if (res.data.code === "OK") {
        setDetail(res.data.event_db_list[0]);
      } else {
        setDetail(undefined);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (id) {
      getTarget();
    }
  }, [id]);

  const search = debounce(() => {
    if (page === "1") {
      getList("1");
    } else {
      navigate({
        pathname: pathname,
        search: `?page=${1}`,
      });
    }
  }, 200);

  const update = async (params: {
    evt_table_id: string;
    state: string;
    admin_desc: string;
  }): Promise<boolean | undefined> => {
    try {
      const res = await APIS.postData(
        "/EventAp/EventTotalUpdate",
        {
          admin_table_id: userData.admin_table_id,
          ...params,
        },
        1
      );

      if (res.data.code === "OK") {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const postEsitimate = async (id: string) => {
    try {
      const res = await APIS.postData("/EventAp/EventTotalOrder", {
        admin_table_id: userData.admin_table_id,
        evt_table_id: id,
      });

      if (res.data.code === "OK") {
        alert("견적 넘기기 되었습니다.");
        search();
      } else if (res.data.code === "ERR_502") {
        alert("이미 견적넘기기 되었습니다.");
      } else {
        console.log(res.data.code);
        alert("견적 넘기기 중 오류가 발생하였습니다.");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return {
    list,
    total,
    page,
    search,
    detail,
    update,
    postEsitimate,
  };
};

export default useEventDb;
