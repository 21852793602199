import React from "react";

import { NavLink } from "react-router-dom";

export default function Notice(props) {
  const { nav } = props;

  return (
    <div className="sub_nav">
      {nav &&
        nav.map((x, i) => {
          return (
            <NavLink key={i} to={x.routes} activeclassname={"active"}>
              {x.title}
            </NavLink>
          );
        })}
    </div>
  );
}
