import React from "react";

export default function FrontTop10(props) {
  const { rows, data } = props;

  return (
    <>
      {rows && (
        <div className="home_detail_popup_left_slide_box">
          <img src={rows.img_url} alt="row_img" />
          <div className="home_detail_popup_left_slide_box1">
            <p className="home_detail_popup_left_slide_title1">
              {data.style_tag_text.map((xx, ii) => {
                return <span key={ii}>{xx}</span>;
              })}
            </p>
            <p className="home_detail_popup_left_slide_title2">
              {data.model_name}
            </p>
            <p className="home_detail_popup_left_slide_title3">
              이 테마에 스타일링된 제품 정보입니다.
              <br />
              구매를 원치 않으시면 견적받기에서 뺄 수 있습니다.
            </p>
          </div>
        </div>
      )}
    </>
  );
}
