import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import * as APIS from "../../utils/service";
import { css } from "@emotion/react";

export default function Clause() {
  const [searchParams] = useSearchParams();
  const vidx = searchParams.get("t");

  const [terms, setTerms] = useState("");

  useEffect(() => {
    const sender = {
      tid: vidx ? vidx : "1",
    };

    APIS.postData("/Login/GetTerms", sender, 2)
      .then((res) => {
        if (res.data.code === "OK") {
          setTerms(res.data.terms);
        } else {
          setTerms("");
        }
      })
      .catch((e) => {
        console.log("catch", e);
      });
  }, [vidx]);

  return (
    <>
      <div
        className="clause_page"
        dangerouslySetInnerHTML={{ __html: terms }}
        css={css`
          font-family: Noto Sans KR;
        `}
      ></div>
    </>
  );
}
