import React from "react";
import AdminLayout from "../../../components/Layout/admin";
import useEventTabs from "./event.hook";
import EventAptnCounsel from "../../../components/Event/AptnCounsel";

const EventAptnCounselPage = () => {
  const { tabs } = useEventTabs();

  return (
    <AdminLayout title="이벤트 접수 현황" tabs={tabs}>
      <EventAptnCounsel />
    </AdminLayout>
  );
};

export default EventAptnCounselPage;
