/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { setData, reset } from "../../redux/searchSlice";
import { findJson } from "../../utils/utils";
import useWindowScreen from "../../utils/useWindowScreen";
import moment from "moment";
import consts from "../../libs/consts";
import routes from "../../libs/routes";
import InputSelect from "../../components/InputSelect";
import InputDate from "../../components/InputDate";
import MemberShip from "../../components/MemberShip";

export default function Pay({ list, membership }) {
  const dispatch = useDispatch();
  const search = useSelector((s) => s.searchReducer);
  const popup = useSelector((s) => s.popupReducer);
  const [step] = useState(1);
  const windowSize = useWindowScreen();
  const navigte = useNavigate();

  if (windowSize.width <= 720) {
    navigte(routes.membership);
  }

  useEffect(() => {
    dispatch(reset());
  }, [step]);

  useEffect(() => {}, [
    search.state,
    search.sdate,
    search.edate,
    search.page,
    popup.open,
  ]);

  const searchFunc = (key, value) => {
    dispatch(
      setData({
        key: key,
        value: value,
      })
    );
  };

  return (
    <>
      <div className="search_form">
        <div className="search_form_left">
          <InputSelect
            name="state"
            label="상태"
            value={search.state}
            onSearch={searchFunc}
            option={[{ idx: "", title: "전체" }, ...consts.supply_options]}
          />
          <InputDate
            type="text"
            placeholder="시작날짜"
            placeholderEnd="종료날짜"
            name="ipd1"
            label="날짜선택"
            dateValue={search.sdate}
            onSearch={searchFunc}
            dateValueEnd={search.edate}
            multiple={true}
          />
          <button type="button" className="btn btn3">
            검색
          </button>
        </div>
        <div className="search_form_right">
          <div className="pay_membership_info">
            <div className="sub_info">
              <p className="title">정액제</p>
              <MemberShip type={1} title={true} />
              <p className="content">
                {membership.available_count}/{membership.max_count})
              </p>
            </div>
            <div className="fix_info">
              <p className="title">정량제</p>
              <p className="content">{membership.period_cnt}</p>
            </div>
          </div>
        </div>
      </div>
      <table className="tableStyle1">
        <thead>
          <tr>
            <th className="wd130">견적요청번호</th>
            <th className="wd110">구분</th>
            <th className="wd150">아파트명</th>
            <th className="wd150">지역</th>
            <th className="wd110">평형</th>
            <th className="wd130">희망가격</th>
            <th className="wd130">등록일자</th>
            <th className="wd130">수정일자</th>
            {/*<th className="wdFull">비고</th>*/}
          </tr>
        </thead>
        <tbody>
          {list.length > 0 ? (
            list.map((x, i) => {
              return (
                <tr key={i} className={"pointer"}>
                  <td>{x.order_table_id}</td>
                  <td>{findJson(consts.supply_options, x.supply_type)}</td>
                  <td>{x.work_name}</td>
                  <td>{x.area_name + "/" + x.area_detail_name}</td>
                  <td>{x.size}</td>
                  <td>{x.price}</td>
                  <td>{moment(x.reg_date).format("YYYY.MM.DD")}</td>
                  <td>{moment(x.update_date).format("YYYY.MM.DD")}</td>
                  {/*<td>
                                    { x.desc }
                                </td>*/}
                </tr>
              );
            })
          ) : (
            <tr>
              <td className="null_td" colSpan={8}>
                데이터가 없습니다.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
}
