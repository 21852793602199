import React, { useState, useEffect } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import Modal from "../../../Common/ModalPortal";
import images from "../../../../libs/images";
import Button from "../../../Common/Button/Button";
import * as Colors from "../../../../constants/color";
import { marginTopStyles, labelStyles } from "../../home.style";
import Input from "../../../Common/Input/Input";
import Upload, { FileData } from "../../../Common/Upload/Upload";
import Select from "../../../Common/Select/Select";
import useHomeManageRecommend, { Item } from "../homeManageRecommend.hook";
import consts from "../../../../libs/consts";
import { handlerImgError } from "../../../../utils/home-manage";

const Container = styled.div`
  width: 1160px;
  background-color: #ffffff;
  margin: auto;
`;

const Header = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${Colors.gray_200};

  & > h3 {
    margin: auto 0;
  }
`;

const Content = styled.div`
  padding: 10px 20px 30px 20px;
`;

const Footer = styled.div`
  padding: 20px;
  border-top: 1px solid ${Colors.gray_200};
  display: flex;
  justify-content: center;
  gap: 15px;
`;

interface Props {
  close: () => void;
  callback: () => void;
  id: string;
}

const UpdateHomeManageRecommend = (props: Props) => {
  const { close, callback, id } = props;

  const { detail, update } = useHomeManageRecommend({
    id,
  });

  const [item, setItem] = useState<Item>({
    content_table_id: "",
    content_name: "",
    state: "",
    detail_list: [],
  });

  useEffect(() => {
    if (detail) {
      setItem({ ...detail });
    }
  }, [detail]);

  const changeInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setItem((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const changeMultiInputHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
    seq: string
  ) => {
    const { name, value } = e.target;

    setItem((prevData) => ({
      ...prevData,
      detail_list: prevData.detail_list.map((item) =>
        item.content_seq === seq
          ? {
              ...item,
              [name]: value,
            }
          : item
      ),
    }));
  };

  const changeMultiUploadHandler = ({ fileImage }: FileData, seq: string) => {
    setItem((prevData) => ({
      ...prevData,
      detail_list: prevData.detail_list.map((l) =>
        l.content_seq === seq
          ? {
              ...l,
              content_detail_img: fileImage,
            }
          : l
      ),
    }));
  };

  const deleteMultiUploadHandler = (seq: string) => {
    setItem((prevData) => ({
      ...prevData,
      detail_list: prevData.detail_list.map((l) =>
        l.content_seq === seq
          ? {
              ...l,
              content_detail_img: "",
            }
          : l
      ),
    }));
  };

  const changeSelectHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setItem((prevData) => ({
      ...prevData,
      state: e.target.value,
    }));
  };

  const updateHandler = async () => {
    const res = await update(item);

    if (res) {
      callback();
    }
  };

  if (!detail) {
    return <></>;
  }

  return (
    <Modal>
      <Container>
        <Header>
          <h3>추천 컨텐츠 변경</h3>
          <div
            css={css`
              display: flex;
            `}
          >
            <div
              css={css`
                display: flex;
              `}
            >
              <p
                css={[
                  labelStyles,
                  css`
                    margin: auto 10px auto 0;
                  `,
                ]}
              >
                * 등록상태
              </p>
              <Select
                options={consts.notice_avt_state.map((notice) => ({
                  value: notice.idx,
                  label: notice.title,
                }))}
                value={item?.state}
                onChange={changeSelectHandler}
                size="small"
              />
            </div>
            <button
              onClick={close}
              css={css`
                margin-left: 35px;
              `}
            >
              <img src={images.exit} alt="exit_btn" />
            </button>
          </div>
        </Header>
        <Content>
          <div css={[marginTopStyles]}>
            <Input
              label="* 키워드 명"
              placeholder="키워드 작성"
              name="content_name"
              value={item.content_name}
              onChange={changeInputHandler}
            />
          </div>
          <div
            css={css`
              margin-top: 20px;
              display: flex;
              justify-content: space-between;
            `}
          >
            {item.detail_list.map((detail_list, i) => (
              <div
                key={detail_list.content_seq}
                css={css`
                  width: 32%;
                  padding-right: 2%;
                  border-right: 1px solid ${Colors.gray_200};

                  &:last-of-type {
                    border: none;
                  }
                `}
              >
                <div css={[marginTopStyles]}>
                  <Upload
                    id={`file_${detail_list.content_seq}`}
                    label={`* 대표 이미지 ${i + 1} 등록`}
                    size="full"
                    preview={detail_list.content_detail_img}
                    deletePreivew={() =>
                      deleteMultiUploadHandler(String(detail_list.content_seq))
                    }
                    callback={(file) =>
                      changeMultiUploadHandler(
                        file,
                        String(detail_list.content_seq)
                      )
                    }
                    onError={handlerImgError}
                  />
                </div>
                <div css={[marginTopStyles]}>
                  <Input
                    label="* 타이틀 (25자 이내)"
                    maxLength={25}
                    placeholder="타이틀을 입력해주세요."
                    size="full"
                    name="content_detail_title"
                    value={detail_list.content_detail_title}
                    onChange={(e) =>
                      changeMultiInputHandler(
                        e,
                        String(detail_list.content_seq)
                      )
                    }
                  />
                </div>
                <div css={[marginTopStyles]}>
                  <Input
                    label="* 설명 (50자 이내)"
                    maxLength={50}
                    placeholder="보조 문구를 입력해주세요."
                    size="full"
                    name="content_detail_desc"
                    value={detail_list.content_detail_desc}
                    onChange={(e) =>
                      changeMultiInputHandler(
                        e,
                        String(detail_list.content_seq)
                      )
                    }
                  />
                </div>
                <div css={[marginTopStyles]}>
                  <Input
                    label="* 연결 URL"
                    placeholder="URL을 입력해주세요."
                    size="full"
                    name="content_detail_url"
                    value={detail_list.content_detail_url}
                    onChange={(e) =>
                      changeMultiInputHandler(
                        e,
                        String(detail_list.content_seq)
                      )
                    }
                  />
                </div>
              </div>
            ))}
          </div>
        </Content>
        <Footer>
          <Button theme="tertiary" onClick={close}>
            취소
          </Button>
          <Button onClick={updateHandler}>변경</Button>
        </Footer>
      </Container>
    </Modal>
  );
};

export default UpdateHomeManageRecommend;
