import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

import * as APIS from "../../utils/service";
import { logIn } from "../../redux/usersSlice";
import routes from "../../libs/routes";
import Input from "../../components/Input";
import Footer from "../../components/Layout/Footer";

export default function Admin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [id, setId] = useState("");
  const [pw, setPw] = useState("");

  const [error, setError] = useState("");
  const [error2, setError2] = useState("");

  const loginFunc = () => {
    /* 에러메시지 */
    if (!id) {
      setError("아이디를 입력해주세요.");
      return false;
    }
    if (!pw) {
      setError2("비밀번호를 입력해주세요.");
      return false;
    }

    const sender = {
      admin_id: id,
      admin_passwd: pw,
    };

    APIS.postData("/Admin/SiteLogin", sender, 1)
      .then((res) => {
        if (res.data.code === "OK") {
          dispatch(
            logIn({
              mbId: res.data.admin_id,
              mbName: res.data.admin_name,
              admin_table_id: res.data.admin_table_id,
              grade: 10,
            })
          );
          navigate(routes.partner);
        } else if (res.data.code === "ERR_102") {
          setError("해당 아이디가 존재 하지 않습니다.");
        } else if (res.data.code === "ERR_105") {
          setError2("비밀번호를 확인해주세요.");
          return;
        }
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };

  return (
    <div className="login">
      <div className="login_section login_section_admin">
        <Link to="#" className="admin_logo indent999">
          LOGO
        </Link>

        <p className="title">레이홈 ADMIN 사이트</p>

        <div className="admin_login_box">
          <Input
            className="input_text"
            type="text"
            placeholder="아이디를 입력해 주세요."
            name="id"
            value={id}
            setValue={setId}
            label="아이디"
            error={error}
            setError={setError}
          />
          <Input
            className="input_text"
            type="password"
            placeholder="비밀번호를 입력해 주세요."
            name="pw"
            value={pw}
            setValue={setPw}
            label="비밀번호"
            error={error2}
            setError={setError2}
          />
          <button
            type="button"
            className="btn btn1"
            onClick={() => loginFunc()}
          >
            로그인
          </button>
        </div>

        <Footer type="admin" />

        {/* <button type="button" onClick={() => testLogin()}>테스트 로그인</button> */}
      </div>
    </div>
  );
}
