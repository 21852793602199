import React from "react";
import AdminLayout from "../../../components/Layout/admin";
import useNotice from "./notice.hook";
import NoticeUrl from "../../../components/Notice/Url";

const NoticeUrlPage = () => {
  const { tabs } = useNotice();

  return (
    <AdminLayout title="광고 관리" tabs={tabs}>
      <NoticeUrl />
    </AdminLayout>
  );
};

export default NoticeUrlPage;
