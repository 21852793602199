import images from "../libs/images";
import { eventStateList, eventDbStateList } from "../constants/home-manage";

export const getEventType = (type: string) => {
  return type === "1" ? "이벤트" : type === "2" ? "컨텐츠" : "알수 없음";
};

export const getEventState = (state: string) => {
  return (
    eventStateList.find((item) => item.value === state)?.label || "알수 없음"
  );
};

export const getEventDbState = (state: string) => {
  return (
    eventDbStateList.find((item) => item.value === state)?.label || "알수 없음"
  );
};

export const handlerImgError = (
  e: React.SyntheticEvent<HTMLImageElement, Event>
) => {
  e.currentTarget.src = images.fallback;
};
