import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import Modal from "../../../Common/ModalPortal";
import images from "../../../../libs/images";
import Button from "../../../Common/Button/Button";
import * as Colors from "../../../../constants/color";
import Input from "../../../Common/Input/Input";
import Select from "../../../Common/Select/Select";
import TextArea from "../../../TextArea";
import useEventDb from "../eventDb.hook";
import useNoticeAd from "../../../Notice/Ad/noticeAd.hook";
import { eventDbStateList } from "../../../../constants/home-manage";

const contentStyles = css`
  margin-top: 20px;
`;

const Container = styled.div`
  width: 373px;
  background-color: #ffffff;
  margin: auto;
`;

const Header = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${Colors.gray_200};

  & > h3 {
    margin: auto 0;
  }
`;

const Content = styled.div`
  padding: 20px;
  max-height: 700px;
  overflow: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const Footer = styled.div`
  padding: 20px;
  border-top: 1px solid ${Colors.gray_200};
  display: flex;
  justify-content: center;
  gap: 15px;
`;

interface Props {
  close: () => void;
  id: string;
  callback: () => void;
}

const UpdateEventDb = (props: Props) => {
  const { close, id, callback } = props;
  const { area } = useNoticeAd();

  const { detail, update } = useEventDb({
    id,
  });

  const [evtState, setEvtState] = useState("");
  const [desc, setDesc] = useState("");

  useEffect(() => {
    if (detail) {
      setDesc(detail.admin_desc);
      setEvtState(detail.state);
    }
  }, [detail]);

  const changeSelectHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setEvtState(e.target.value);
  };

  const updateHandler = async (): Promise<void> => {
    const res = await update({
      evt_table_id: detail?.evt_table_id as string,
      state: evtState,
      admin_desc: desc,
    });

    if (typeof res === "boolean") {
      if (res) {
        callback();
      } else {
        alert("등록중 오류가 발생하였습니다.");
      }
    }
  };

  if (!detail) {
    return <></>;
  }

  return (
    <Modal>
      <Container>
        <Header>
          <h3>Event 관리 정보</h3>
          <button onClick={close}>
            <img src={images.exit} alt="exit_btn" />
          </button>
        </Header>
        <Content>
          <div>
            <Input
              label="이름"
              value={detail.user_name}
              onChange={() => null}
              disabled
            />
          </div>
          <div css={contentStyles}>
            <Input
              label="연락처"
              value={detail.user_phone}
              onChange={() => null}
              disabled
            />
          </div>
          <div css={contentStyles}>
            <Input
              label="평형"
              value={detail.size}
              onChange={() => null}
              disabled
            />
          </div>
          <div css={contentStyles}>
            <Input
              label="공사 주소"
              value={`${
                area.find((item) => item.area_id === detail.area_id)
                  ?.area_name || ""
              } ${
                area
                  .find((item) => item.area_id === detail.area_id)
                  ?.area_2nd.find(
                    (item) => item.area_detail_id === detail.area_detail_id
                  )?.area_detail_name || ""
              }`}
              onChange={() => null}
              disabled
            />
          </div>
          <div css={contentStyles}>
            <Input
              label="공사 예정일"
              value={detail.work_date}
              onChange={() => null}
              disabled
            />
          </div>
          <div css={contentStyles}>
            <Input
              label="요청 사항"
              value={detail.request_desc}
              onChange={() => null}
              disabled
            />
          </div>
          <div css={contentStyles}>
            <Select
              label="처리 상태"
              options={eventDbStateList}
              value={evtState}
              onChange={changeSelectHandler}
            />
          </div>
          <div css={contentStyles}>
            <TextArea
              className="input_textarea"
              placeholder="내용을 입력해 주세요. (500자 이내)"
              name="estimate_msg"
              value={desc}
              setValue={setDesc}
              label="요청내역"
            />
          </div>
        </Content>
        <Footer>
          <Button theme="tertiary" onClick={close}>
            취소
          </Button>
          <Button onClick={updateHandler}>수정</Button>
        </Footer>
      </Container>
    </Modal>
  );
};

export default UpdateEventDb;
