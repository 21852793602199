import React, { useState } from "react";
import { css } from "@emotion/react";
import Table from "../../Common/Table/Table";
import Pagination from "../../Common/Pagination/Pagination";
import Button from "../../Common/Button/Button";
import CreatehomeManageLifeMonthly from "./Create";
import UpdatehomeManageLifeMonthly from "./Update";
import useHomeManageLifeMonthly from "./homeManageLifeMonthly.hook";
import consts from "../../../libs/consts";
import Select from "../../Common/Select/Select";
import Input from "../../Common/Input/Input";

const HomeManageLifeMonthly = () => {
  const [searchData, setSearchData] = useState({
    isApplication: "",
    title: "",
  });

  const { list, page, total, search } = useHomeManageLifeMonthly({
    isList: true,
    searchData,
  });

  const [isOpenCreate, setIsOpenCreate] = useState(false);

  const [id, setId] = useState<string | undefined>();

  const changeSelectHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setSearchData((prevData) => ({ ...prevData, isApplication: value }));
  };

  const changeInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchData((prevData) => ({ ...prevData, title: value }));
  };

  const col = [
    { key: "monthly_table_id", title: "번호" },
    { key: "title", title: "타이틀 명" },
    { key: "image", title: "이미지 URL" },
    {
      key: "isApplication", title: "신청가능여부", render: (data: any) => (
        <span>{
          consts.life_monthly_application_options.find((item) => item.idx === data.isApplication)?.title
        }</span>
      )
    },
    {
      key: "url", title: "URL", render: (data: any) => (
        <span>{data.url}</span>
      )
    },
  ];

  const callback = () => {
    setIsOpenCreate(false);
    setId(undefined);
    search();
  };

  return (
    <div>
      <div
        css={css`
          margin-top: 24px;
          display: flex;
          gap: 20px;
        `}
      >
        <div
          css={css`
            width: 200px;
          `}
        >
          <Select
            label="신청가능여부"
            defaultValue="전체"
            options={consts.life_monthly_application_options.map((item) => ({
              value: item.idx,
              label: item.title,
            }))}
            size="full"
            onChange={changeSelectHandler}
          />
        </div>
        <div>
          <Input
            label="타이틀"
            placeholder="입력"
            onChange={changeInputHandler}
          />
        </div>
        <div
          css={css`
            margin: auto 0 0 10px;
          `}
        >
          <Button theme="tertiary" onClick={search}>
            검색
          </Button>
        </div>
        <div
          css={css`
            margin: auto 0 0 auto;
          `}
        >
          <Button onClick={() => setIsOpenCreate(true)}>등록</Button>
        </div>
      </div>
      <div
        css={css`
          margin: 30px 0;

          .table-td-seq {
            width: 140px;
          }
        `}
      >
        <Table
          columns={col}
          datas={list}
          rowCallback={(data) => setId(data.monthly_table_id)}
        />
      </div>
      <Pagination curPage={page} total={total} pageSize={20} />
      {isOpenCreate && (
        <CreatehomeManageLifeMonthly
          close={() => setIsOpenCreate(false)}
          callback={callback}
        />
      )}
      {id && (
        <UpdatehomeManageLifeMonthly
          id={id}
          close={() => setId(undefined)}
          callback={callback}
        />
      )}
    </div>
  );
};

export default HomeManageLifeMonthly;
