import React from "react";
import moment from "moment";
import { findJson } from "../../../utils/utils";
import consts from "../../../libs/consts";
import Table from "../../../components/Common/Table/Table";
import { css } from "@emotion/react";

export default function LogsSupplyManage({ list }) {
  const col = [
    { key: "payment_log_id", title: "로그번호" },
    { key: "payment_table_id", title: "결제번호" },
    {
      key: "payment_type",
      title: "결제구분",
      render: (x) => (
        <span>{findJson(consts.paymentoption, x.payment_type)}</span>
      ),
    },
    { key: "payment_cnt", title: "건수" },
    { key: "partner_name", title: "파트너명" },
    { key: "partner_id", title: "파트너ID" },
    { key: "payment_price", title: "금액" },
    {
      key: "payment_state",
      title: "결제상태",
      render: (x) => (
        <span>{findJson(consts.payStateOption, x.payment_state)}</span>
      ),
    },
    { key: "charge", title: "작업자" },
    {
      key: "reg_date",
      title: "처리일시",
      render: (x) => <span>{moment(x.reg_date).format("YYYY-MM-DD")}</span>,
    },
    { key: "payment_log_desc", title: "비고" },
  ];

  return (
    <div
      css={css`
        margin: 30px 0 20px 0;
      `}
    >
      <Table columns={col} datas={list} />
    </div>
  );
}
