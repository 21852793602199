/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as APIS from "../../utils/service";
import { findJson, numFormat } from "../../utils/utils";
import moment from "moment";
import Input from "../../components/Input";
import InputSelect from "../../components/InputSelect";
import Pagenation from "../../components/Pagenation";
import OrderAdd from "./OrderAdd";
import consts from "../../libs/consts";
import { open } from "../../redux/popupSlice";
import { setData } from "../../redux/searchSlice";
import AdminLayout from "../../components/Layout/admin";
import Table from "../../components/Common/Table/Table";
import { css } from "@emotion/react";

export default function Order() {
  const dispatch = useDispatch();
  const userData = useSelector((s) => s.usersReducer);
  const search = useSelector((s) => s.searchReducer);
  const popup = useSelector((s) => s.popupReducer);

  /* search form */
  const [pageing, setPageing] = useState("");

  const [list, setList] = useState([]);
  /* search form End */

  useEffect(() => {
    dataFunc();
  }, [
    search.city,
    search.range,
    search.membership,
    search.state,
    search.license,
    search.sdate,
    search.edate,
    search.page,
    popup.open,
  ]);

  const dataFunc = () => {
    const sender = {
      admin_table_id: userData.admin_table_id,
      order_estimate_type: search.license,
      work_type: search.range,
      case_state: search.state,
      cont_table_id: search.stx,
      page: search.page + 1,
    };

    APIS.postData("/AdminCont/ContList", sender, 1)
      .then((res) => {
        if (res.data.code === "OK") {
          setList(res.data.cont_list);
          setPageing({
            totalPages: Math.ceil(res.data.total_cnt / consts.pagerows),
          });
        } else {
          setList([]);
          setPageing({ totalPages: 1 });
        }
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };

  const searchFunc = (key, value) => {
    dispatch(
      setData({
        key: key,
        value: value,
      })
    );
  };

  const updateFunc = (x) => {
    dispatch(
      open({
        title: "계약관리",
        wide: true,
        component: (
          <OrderAdd
            data={""}
            w={"u"}
            id={x.cont_table_id}
            order_id={x.order_table_id}
          />
        ),
      })
    );
  };
  const keyPressFunc = (e) => {
    if (e.key === "Enter") {
      dataFunc();
    }
  };

  const col = [
    { key: "cont_table_id", title: "계약번호" },
    { key: "order_table_id", title: "요청번호" },
    {
      key: "order_estimate_type",
      title: "견적유형",
      render: (x) => (
        <span>{findJson(consts.sellerOrderType, x.order_estimate_type)}</span>
      ),
    },
    {
      key: "work_type",
      title: "시공범위",
      render: (x) => (
        <span>{findJson(consts.work_type_option, x.work_type)}</span>
      ),
    },
    {
      key: "style_price",
      title: "홈스타일링",
      render: (x) => <span>{numFormat(x.style_price)}만원</span>,
    },
    {
      key: "order_price",
      title: "시공금액",
      render: (x) => <span>{numFormat(x.order_price)}만원</span>,
    },
    {
      key: "pay_per",
      title: "수수료",
      render: (x) => <span>{numFormat(x.pay_per)}</span>,
    },
    { key: "x.seller_biz_name", title: "파트너명" },
    {
      key: "order_state",
      title: "진행상태",
      render: (x) => (
        <span>{findJson(consts.sellerOrderState, x.order_state)}</span>
      ),
    },
    {
      key: "is_review",
      title: "고객리뷰",
      render: (x) => <span>{x.is_review ? "있음" : "없음"}</span>,
    },
    {
      key: "reg_date",
      title: "등록일자",
      render: (x) => <span>{moment(x.reg_date).format("YYYY.MM.DD")}</span>,
    },
    {
      key: "update_date",
      title: "수정일자",
      render: (x) => <span>{moment(x.update_date).format("YYYY.MM.DD")}</span>,
    },
  ];

  return (
    <AdminLayout title="계약 관리">
      <div>
        <div className="search_form search_form_long">
          <div className="search_form_left">
            <InputSelect
              name="license"
              label="견적유형"
              value={search.license}
              onSearch={searchFunc}
              option={[{ idx: "", title: "전체" }, ...consts.sellerOrderType]}
            />
            <InputSelect
              name="range"
              label="시공범위"
              value={search.range}
              onSearch={searchFunc}
              option={[{ idx: "", title: "전체" }, ...consts.work_type_option]}
            />
            <InputSelect
              name="state"
              label="진행상태"
              value={search.state}
              onSearch={searchFunc}
              option={[{ idx: "", title: "전체" }, ...consts.sellerOrderState]}
            />
            <Input
              className="input_text"
              type="text"
              placeholder={"계약번호 입력"}
              name="stx"
              value={search.stx}
              onSearch={searchFunc}
              onKeyDown={keyPressFunc}
              label={"검색"}
            />

            <button
              type="button"
              className="btn btn3"
              onClick={() => dataFunc()}
            >
              검색
            </button>
          </div>
          <div className="search_form_right">
            {/* <button type="button" className="btn btn1" onClick={() => addFunc()}>등록</button> */}
          </div>
        </div>

        <div
          css={css`
            margin: 30px 0 20px 0;
          `}
        >
          <Table
            columns={col}
            datas={list}
            rowCallback={(x) => updateFunc(x)}
          />
        </div>

        <Pagenation
          pageing={pageing}
          onSearch={searchFunc}
          curpage={search.page}
        />
      </div>
    </AdminLayout>
  );
}
