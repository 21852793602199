/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";

import * as APIS from "../../utils/service";
import { numFormat, findJson } from "../../utils/utils";

import moment from "moment";

import Input from "../../components/Input";
import InputDate from "../../components/InputDate";
import InputSelect from "../../components/InputSelect";
import Pagenation from "../../components/Pagenation";

import Add from "./Add";

import consts from "../../libs/consts";

import { open, close } from "../../redux/popupSlice";
import { openSub } from "../../redux/subPopupSlice";
import { setData } from "../../redux/searchSlice";
import AdminLayout from "../../components/Layout/admin";
import PartnerLayout from "../../components/Layout/partner";
import * as Colors from "../../constants/color";

export default function Instance() {
  const dispatch = useDispatch();
  const location = useLocation();
  const userData = useSelector((s) => s.usersReducer);
  const search = useSelector((s) => s.searchReducer);
  const popup = useSelector((s) => s.popupReducer);

  const [list, setList] = useState([]);

  const [pageing, setPageing] = useState("");

  useEffect(() => {
    if (location.state && location.state.vid) {
      updateFunc(location.state.vid);
    }
  }, [location]);

  useEffect(() => {
    dataFunc();
  }, [search.state, search.sdate, search.edate, search.page, popup.open]);

  const dataFunc = () => {
    let sender = "";
    let urls = "";
    let types = "";

    if (userData.grade === 10) {
      urls = "/Admin/CaseList";
      types = 1;
      sender = {
        admin_table_id: userData.admin_table_id,
        case_state: search.state,
        case_table_id: search.stxId,
        seller_biz_name: search.stx,
        start_date: search.sdate,
        end_date: search.edate,
        page: search.page + 1,
      };
    } else {
      urls = "/WorkCase/CaseList";
      types = 2;
      sender = {
        seller_table_id: userData.seller_table_id,
        case_state: search.state,
        start_date: search.sdate,
        end_date: search.edate,
        page: search.page + 1,
      };
    }

    APIS.postData(urls, sender, types)
      .then((res) => {
        if (res.data.code === "OK" && res.data.case_list) {
          setList(res.data.case_list);
          setPageing({
            totalPages: Math.ceil(res.data.total_cnt / consts.pagerows),
          });
        } else {
          setList([]);
          setPageing({ totalPages: 1 });
        }
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };

  const odFunc = (key) => {
    if (search.od === key) {
      dispatch(
        setData({
          key: "od",
          value: "",
        })
      );
      sortHandler(key, "asc");
    } else {
      dispatch(
        setData({
          key: "od",
          value: key,
        })
      );
      sortHandler(key, "desc");
    }
  };

  const sortHandler = (key, od) => {
    let listArr = [...list]; // 새로 복사된 numbers

    if (od === "asc") {
      listArr.sort((a, b) => {
        return a[key] - b[key];
      });
    } else {
      listArr.sort((a, b) => {
        return b[key] - a[key];
      });
    }
    setList(listArr);
  };

  const addFunc = () => {
    let sender = "";
    let urls = "";
    let types = "";

    if (userData.grade === 10) {
      urls = "/Admin/CaseCreateChk";
      types = 1;
      sender = {
        admin_table_id: userData.admin_table_id,
      };
    } else {
      urls = "/WorkCase/CaseCreateChk";
      types = 2;
      sender = {
        seller_table_id: userData.seller_table_id,
      };
    }

    APIS.postData(urls, sender, types)
      .then((res) => {
        dispatch(
          open({
            title: "시공사례등록",
            wide: true,
            component: <Add data={""} w={"i"} />,
            onCancelPressAlert: () => {
              dispatch(
                openSub({
                  message: "시공사례 등록을 중단 하시겠습니까?",
                  buttonCencle: "취소",
                  onPress: () => {
                    dispatch(close());
                  },
                })
              );
            },
          })
        );
        return;
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };

  const updateFunc = (id) => {
    let sender = "";
    let urls = "";
    let types = "";

    if (userData.grade === 10) {
      urls = "/Admin/CaseTarget";
      types = 1;
      sender = {
        admin_table_id: userData.admin_table_id,
        case_table_id: id,
      };
    } else {
      urls = "/WorkCase/CaseTarget";
      types = 2;
      sender = {
        seller_table_id: userData.seller_table_id,
        case_table_id: id,
      };
    }
    APIS.postData(urls, sender, types)
      .then((res) => {
        if (res.data) {
          dispatch(
            open({
              title: "시공사례수정",
              wide: true,
              component: <Add data={res.data} w={"u"} />,
              onCancelPressAlert: () => {
                dispatch(
                  openSub({
                    message: "시공사례 수정을 중단 하시겠습니까?",
                    buttonCencle: "취소",
                    onPress: () => {
                      dispatch(close());
                    },
                  })
                );
              },
            })
          );
        } else {
          dispatch(
            openSub({
              message: "잠시 후 다시 시도해주세요.",
              button: "확인",
            })
          );
        }
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };

  const searchFunc = (key, value) => {
    dispatch(
      setData({
        key: key,
        value: value,
      })
    );
  };
  const keyPressFunc = (e) => {
    if (e.key === "Enter") {
      dataFunc();
    }
  };

  const instance = () => {
    return (
      <div>
        <div className="search_form">
          <div className="search_form_left">
            <InputSelect
              name="state"
              label="상태"
              value={search.state}
              onSearch={searchFunc}
              option={[
                { idx: "", title: "전체" },
                ...consts.instanceStateoption,
              ]}
            />
            <InputDate
              type="text"
              placeholder="시작날짜"
              placeholderEnd="종료날짜"
              name="ipd1"
              label="날짜선택"
              dateValue={search.sdate}
              onSearch={searchFunc}
              dateValueEnd={search.edate}
              multiple={true}
            />

            {userData.grade === 10 && (
              <>
                <Input
                  className="input_text"
                  type="text"
                  placeholder={"시공사례번호 입력"}
                  name="stxId"
                  value={search.stxId}
                  onSearch={searchFunc}
                  onKeyDown={keyPressFunc}
                  label={"시공사례번호"}
                />
                <Input
                  className="input_text"
                  type="text"
                  placeholder={"파트너명 입력"}
                  name="stx"
                  value={search.stx}
                  onSearch={searchFunc}
                  onKeyDown={keyPressFunc}
                  label={"검색"}
                />
              </>
            )}

            <button
              type="button"
              className="btn btn3"
              onClick={() => dataFunc()}
            >
              검색
            </button>
          </div>
          <div className="search_form_right">
            <button
              type="button"
              className="btn btn1"
              onClick={() => addFunc()}
            >
              등록
            </button>
          </div>
        </div>

        <table className="tableStyle1">
          <thead>
            <tr>
              <th className="wd110">시공사례번호</th>
              {userData.grade === 10 && <th className="wd220">파트너명</th>}
              <th className="wd220">아파트명(사례명)</th>
              <th className="wd110">주거유형</th>
              <th className="wd80">평형</th>
              <th className="wd110">시공가격</th>
              <th
                className={
                  "wd80 od " + (search.od === "case_view_cnt" ? "active" : "")
                }
                onClick={() => odFunc("case_view_cnt")}
              >
                조회수
              </th>
              <th
                className={
                  "wd80 od " +
                  (search.od === "case_provide_cnt" ? "active" : "")
                }
                onClick={() => odFunc("case_provide_cnt")}
              >
                제공수
              </th>
              <th
                className={
                  "wd80 od " + (search.od === "case_order_cnt" ? "active" : "")
                }
                onClick={() => odFunc("case_order_cnt")}
              >
                계약수
              </th>
              <th className="wd80">상태</th>
              <th className="wd130">등록일자</th>
              <th className="wd130">수정일자</th>
              <th className="wdFull">비고</th>
            </tr>
          </thead>
          <tbody>
            {list.length > 0 ? (
              list.map((x, i) => {
                return (
                  <tr
                    key={i}
                    className={"pointer"}
                    onClick={() => updateFunc(x.case_table_id)}
                  >
                    <td>{x.case_table_id}</td>
                    {userData.grade === 10 && <td>{x.seller_biz_name}</td>}
                    <td>
                      <p
                        css={css`
                          display: flex;
                        `}
                      >
                        {x.case_name}
                        {x.seller_classic === "1" && (
                          <p
                            css={css`
                              margin-left: 15px;
                              border: 1px solid ${Colors.gray_300};
                              background-color: ${Colors.gray_150};
                              padding: 0 10px;
                              font-size: 12px;
                            `}
                          >
                            대표
                          </p>
                        )}
                      </p>
                    </td>
                    <td>{findJson(consts.workoption, x.case_home_type)}</td>
                    <td>{x.case_size}</td>
                    <td>{numFormat(x.case_work_price)}만원</td>
                    <td>{numFormat(x.case_view_cnt)}</td>
                    <td>{numFormat(x.case_provide_cnt)}</td>
                    <td>{numFormat(x.case_order_cnt)}</td>
                    <td
                      className={
                        x.case_state === "0" || x.case_state === "3"
                          ? "cl_7"
                          : x.case_state === "1" || x.case_state === "5"
                          ? "cl_6"
                          : x.case_state === "2"
                          ? "cl_4"
                          : "cl_7"
                      }
                    >
                      {findJson(consts.instanceStateoption, x.case_state)}
                    </td>
                    <td>{moment(x.reg_date).format("YYYY.MM.DD")}</td>
                    <td>{moment(x.update_date).format("YYYY.MM.DD")}</td>
                    <td>
                      {x.case_price_min !== "0" || x.case_price_max !== "0"
                        ? numFormat(x.case_price_min) +
                          "만원 ~ " +
                          numFormat(x.case_price_max) +
                          "만원"
                        : "-"}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="null_td" colSpan={11}>
                  데이터가 없습니다.
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <Pagenation
          pageing={pageing}
          onSearch={searchFunc}
          curpage={search.page}
        />
      </div>
    );
  };

  if (userData.grade) {
    return <AdminLayout title="사례관리">{instance()}</AdminLayout>;
  } else {
    return <PartnerLayout title="사례관리">{instance()}</PartnerLayout>;
  }
}
