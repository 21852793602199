import React from "react";
import AdminLayout from "../../../components/Layout/admin";
import useEventTabs from "./event.hook";
import EventHanssem from "../../../components/Event/Hanssem";

const EventHanssemPage = () => {
  const { tabs } = useEventTabs();

  return (
    <AdminLayout title="이벤트 접수 현황" tabs={tabs}>
      <EventHanssem />
    </AdminLayout>
  );
};

export default EventHanssemPage;
