/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";

import * as APIS from "../../utils/service";
import { findJson } from "../../utils/utils";

import moment from "moment";

import Input from "../../components/Input";
import InputSelect from "../../components/InputSelect";
import InputDate from "../../components/InputDate";
import InputSelectArea from "../../components/InputSelectArea";
import TextArea from "../../components/TextArea";
import EstimatePartner from "./EstimatePartner";
import EstimatePartnerState from "./EstimatePartnerState";
import consts from "../../libs/consts";
import images from "../../libs/images";

import { open } from "../../redux/popupSlice";
import { imgOpen } from "../../redux/imgSlice";
import AdminLayout from "../../components/Layout/admin";
import { css } from "@emotion/react";

export default function EstimateDetail() {
  let { vidx } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((s) => s.usersReducer);
  const popup = useSelector((s) => s.popupReducer);

  const [load, setLoad] = useState(true);

  const [state, setState] = useState("1");

  const [partners, setPartners] = useState([]); // 견적의뢰 파트너들

  const [rows, setRows] = useState(""); // 상세정보

  const [order_table_id, setorder_table_id] = useState(""); // 요청번호

  const [name, setName] = useState("");
  const [hp, setHp] = useState("");
  const [city, setCity] = useState("");
  const [area, setArea] = useState("");
  const [apt, setApt] = useState("");
  const [pyung, setPyung] = useState("");
  const [sdate, setSdate] = useState("");
  const [order_desired_period, setorder_desired_period] = useState("");

  const [hope_price, setHope_price] = useState("");
  const [addr, setAddr] = useState("");
  const [addrDetail, setAddrDetail] = useState("");
  const [range, setRange] = useState("");
  const [order_demolition_yn, setOrder_demolition_yn] = useState("");
  const [order_msg, setOrder_msg] = useState("");
  const [style, setStyle] = useState("");
  const [img_list, setImg_list] = useState([
    images.mask11,
    images.mask12,
    images.mask13,
    images.mask14,
  ]);

  const [counselList, setCounselList] = useState([]);

  const [request_list, setRequest_list] = useState([]);

  const [serve, setServe] = useState([]);

  const [csDelete, setCsDelete] = useState([]);

  useEffect(() => {
    dataFunc();
  }, []);

  useEffect(() => {
    partnerdataFunc();
  }, [popup.open]);

  const dataFunc = () => {
    setLoad(true);

    const sender = {
      admin_table_id: userData.admin_table_id,
      order_table_id: vidx,
    };

    APIS.postData("/AdminOrder/OrderTarget", sender, 1)
      .then((res) => {
        if (
          res.data.code === "OK" &&
          res.data.order_list &&
          res.data.order_list[0].order_table_id
        ) {
          setRows(res.data.order_list[0]);

          setorder_table_id(res.data.order_list[0].order_table_id);

          setState(res.data.order_list[0].order_state);
          setPartners(res.data.order_list[0].order_seller_list);

          setName(res.data.order_list[0].order_user_name);
          setHp(res.data.order_list[0].order_phone);

          setApt(res.data.order_list[0].order_home_name);
          setPyung(res.data.order_list[0].order_size);

          setCity(res.data.order_list[0].order_area_1st);
          setArea(res.data.order_list[0].order_area_2nd);

          setAddr(res.data.order_list[0].order_addr);
          setAddrDetail(res.data.order_list[0].order_addr_detail);

          setServe(
            res.data.order_list[0].work_list_adm.length > 0
              ? res.data.order_list[0].work_list_adm.map((item, index) => {
                  return item.tag_id * 1;
                })
              : []
          );

          setorder_desired_period(res.data.order_list[0].order_desired_period);

          setSdate(
            res.data.order_list[0].order_desired_date !== "0000-00-00 00:00:00"
              ? moment(res.data.order_list[0].order_desired_date).format(
                  "YYYY.MM.DD"
                )
              : ""
          );
          setHope_price(res.data.order_list[0].order_desired_price);
          setRange(res.data.order_list[0].work_type);

          setStyle(res.data.order_list[0].style_buy);

          //setEstimate_msg(res.data.order_list[0].order_estimate_msg ? res.data.order_list[0].order_estimate_msg : "");
          setOrder_msg(res.data.order_list[0].order_msg);

          setRequest_list(res.data.order_list[0].request_list);
          setImg_list(
            res.data.order_list[0].img_list.length > 0
              ? res.data.order_list[0].img_list.map((item, index) => {
                  return {
                    src: item.order_img,
                  };
                })
              : []
          );
          setCounselList(
            res.data.order_list[0].estimate_msg_list.length > 0
              ? res.data.order_list[0].estimate_msg_list
              : [{ counsel: "", counsel_desc: "" }]
          );
          setOrder_demolition_yn(res.data.order_list[0].order_demolition_yn);
        } else {
          dispatch(
            open({
              message: "견적 정보가 없습니다.",
              button: "확인",
              onPress: () => navigate(-1),
            })
          );
        }
      })
      .catch((e) => {
        console.log("catch", e);
      })
      .finally(() => {
        setLoad(false);
      });
  };

  const partnerdataFunc = () => {
    const sender = {
      admin_table_id: userData.admin_table_id,
      order_table_id: vidx,
    };

    APIS.postData("/AdminOrder/OrderTarget", sender, 1)
      .then((res) => {
        if (res.data.code === "OK" && res.data.order_list[0].order_table_id) {
          setPartners(res.data.order_list[0].order_seller_list);
        }
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };

  const sellerFind = () => {
    dispatch(
      open({
        title: "시공파트너선택",
        wide: true,
        component: (
          <EstimatePartner
            value={partners}
            setValue={setPartners}
            order_table_id={order_table_id}
            order_estimate_type={rows ? rows.order_estimate_type : ""}
          />
        ),
      })
    );
    return;
  };

  const closeFunc = () => {
    dispatch(
      open({
        message: "견적관리 정보를 수정하지 않고\n목록이동하시겠습니까?",
        buttonCencle: "취소",
        onPress: () => navigate(-1),
      })
    );
  };

  const submitAlert = () => {
    dispatch(
      open({
        message: "저장 하시겠습니까?",
        button: "저장",
        buttonCencle: "취소",
        onPress: submitFunc,
      })
    );
  };
  const submitFunc = () => {
    let arr = [];

    const checkboxes = document.getElementsByClassName("info");
    for (let cb of checkboxes) {
      if (cb.checked) {
        arr.push({ tag_id: cb.value, tag_text: cb.dataset.text });
      }
    }

    const sender = {
      admin_table_id: userData.admin_table_id,
      order_table_id: order_table_id,
      // order_save_type: '0',
      // order_estimate_type: estimate_cate,
      // order_fk_id: estimate_theme_id,
      order_state: state,
      order_user_name: name,
      order_phone: hp,
      order_area_1st: city,
      order_area_2nd: area,
      order_addr: addr,
      order_addr_detail: addrDetail,
      order_home_name: apt,
      order_size: pyung,
      order_desired_period: order_desired_period,
      order_desired_date: moment(sdate).format("YYYY-MM-DD"),
      order_desired_price: hope_price,
      work_type: range,
      work_list_adm: arr,
      order_msg: order_msg,
      estimate_msg_list: counselList,
      style_buy: style,
      order_demolition_yn: order_demolition_yn,
    };

    APIS.postData("/AdminOrder/OrderCreate", sender, 1)
      .then(() => {
        navigate(-1);
        return;
      })
      .catch((e) => {
        console.log("catch", e);
      });

    /* 정보 저장후 새로고침 */
  };

  const deletePartner = (x) => {
    const sender = {
      admin_table_id: userData.admin_table_id,
      seller_table_id: x.seller_table_id,
      order_table_id: order_table_id,
    };

    APIS.postData("/AdminOrder/OrderSellerDel", sender, 1)
      .then((res) => {
        if (res.data.code === "OK") {
          setPartners(
            partners.filter(
              (item) => item.seller_table_id !== x.seller_table_id
            )
          );
        }
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };

  const sellerState = () => {
    if (partners.length > 0) {
      dispatch(
        open({
          title: "파트너별 진행 상태",
          wide: true,
          component: (
            <EstimatePartnerState
              value={partners}
              setValue={setPartners}
              order_table_id={order_table_id}
            />
          ),
        })
      );
    } else {
      dispatch(
        open({
          message: "견적의뢰 파트너가 없습니다.",
        })
      );
    }
  };

  const goTheme = (type, id) => {
    window.open(
      consts.frontUrl +
        "/" +
        (type === "1"
          ? consts.frontReport
          : type === "2"
          ? consts.frontTheme
          : type === "3"
          ? consts.frontPartner
          : consts.frontEvent) +
        id,
      "_blank"
    );
    return;
  };
  const counselAppend = () => {
    setCounselList([...counselList, { counsel: "", counsel_desc: "" }]);
  };

  const counselDelete = (i, id) => {
    let newFormValues = [...counselList];
    newFormValues.splice(i, 1);
    setCounselList(newFormValues);
    if (id) setCsDelete([...csDelete, id]);
  };

  const caseAllSetValue = (i, e, val) => {
    const newFormValues = counselList.map((item, index) => {
      if (index !== i) {
        return item;
      }
      return {
        ...item,
        [e.target.name]: val,
      };
    });
    setCounselList(newFormValues);
  };

  return (
    <AdminLayout>
      <div
        css={css`
          margin: 0 -30px -30px;
        `}
      >
        <div className="page_title_box page_title_box1">
          <p className="title">
            <button
              type="button"
              className="goBackBtn"
              onClick={() => closeFunc()}
            ></button>
            견적관리
          </p>
        </div>

        <div className="content_section admin_content_section">
          <div className="form_section ">
            <p className="title">기본 정보</p>

            <div className="form_list">
              <ul className="list_style4" style={{ marginBottom: "20px" }}>
                <li>
                  <p className="title">요청번호</p>
                  <p className="content">{rows.order_table_id}</p>
                </li>
                <li>
                  <p className="title">요청구분</p>
                  <p className="content">
                    {findJson(consts.orderSaveType, rows.order_save_type)}
                  </p>
                </li>
                <li>
                  <p className="title">요청일시</p>
                  <p className="content">
                    {moment(rows.reg_date).format("YYYY/MM/DD HH:mm")}
                  </p>
                </li>
                <li>
                  <p className="title">최종일시</p>
                  <p className="content">
                    {moment(rows.update_date).format("YYYY/MM/DD HH:mm")}
                  </p>
                </li>
                {rows.order_estimate_type === "0" ? (
                  <li>
                    <p className="title">견적유형</p>
                    <p className="content">일반</p>
                  </li>
                ) : (
                  <li>
                    <p className="title">견적유형</p>
                    <p
                      className="content link"
                      onClick={() =>
                        goTheme(rows.order_estimate_type, rows.order_fk_id)
                      }
                    >
                      {findJson(
                        consts.sellerOrderType,
                        rows.order_estimate_type
                      )}
                      (
                      {rows.order_estimate_type === "3"
                        ? rows.seller_id
                        : rows.order_fk_id}
                      )
                    </p>
                  </li>
                )}
              </ul>

              <InputSelect
                name="state"
                label="진행상태"
                value={state}
                setValue={setState}
                option={consts.sellerOrderState}
              />

              <div class="input_box input_box_select">
                <label class="input_label">파트너별 진행상태</label>
                <button
                  type="button"
                  className="btn btn3"
                  style={{ marginTop: "6px" }}
                  onClick={() => sellerState()}
                >
                  상태관리
                </button>
              </div>

              <div class="input_box input_box_full">
                <label class="input_label">견적의뢰 파트너</label>
                <div
                  class="input_section input_section_list"
                  style={{ height: "auto", alignItems: "flex-start" }}
                >
                  <div className="input_section_list_items">
                    {partners.length > 0 ? (
                      partners.map((x, i) => {
                        return (
                          <>
                            <p key={i}>
                              {x.seller_biz_name}
                              <button
                                type="button"
                                className="exit_btn"
                                onClick={() => deletePartner(x)}
                              />
                            </p>
                          </>
                        );
                      })
                    ) : (
                      <p style={{ border: "none" }}>파트너검색을 해주세요.</p>
                    )}
                  </div>
                  <button
                    type="button"
                    class="input_with_button input_with_button_long"
                    onClick={() => sellerFind()}
                    style={{ alignSelf: "stretch", height: "auto" }}
                  >
                    파트너검색
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="form_section ">
            <p className="title">고객정보</p>

            <div className="form_list">
              <Input
                className="input_text"
                type="text"
                placeholder="이름을 입력해 주세요."
                name="name"
                value={name}
                setValue={setName}
                label="성명"
              />
              <Input
                className="input_text"
                type="text"
                placeholder="휴대폰번호를 입력해 주세요."
                name="hp"
                value={hp}
                setValue={setHp}
                label="휴대폰번호"
              />

              {/* <Input className="input_text" type="text" placeholder="내용을 입력해 주세요." name="hp" value={safeHp} setValue={setSafeHp} label="안심번호" /> */}

              {!load && (
                <InputSelectArea
                  name="work"
                  label="지역"
                  value={city}
                  setValue={setCity}
                  area={area}
                  setArea={setArea}
                />
              )}
              <div
                className="instance_form_list_half instance_form_list_half_margin"
                style={{ marginRight: "11px" }}
              >
                <InputDate
                  type="text"
                  placeholder="공사예정일"
                  name="ipd1"
                  label="공사예정일"
                  dateValue={sdate === "Invalid date" ? "" : sdate}
                  setDateValue={setSdate}
                />
                <Input
                  className="input_text"
                  type="text"
                  name="hope_price"
                  value={hope_price}
                  setValue={setHope_price}
                  label="희망예산"
                  valid={"num"}
                  maxlength={5}
                  withText={"만원"}
                />
              </div>

              <InputSelect
                name="order_desired_period"
                label="공사예정기간"
                value={order_desired_period}
                setValue={setorder_desired_period}
                option={consts.period_option}
              />

              <Input
                className="input_text"
                type="text"
                placeholder="내용을 입력해 주세요."
                name="apt"
                value={apt}
                setValue={setApt}
                label="아파트명"
              />
              <Input
                className="input_text"
                type="text"
                placeholder="내용을 입력해 주세요."
                name="pyung"
                value={pyung}
                setValue={setPyung}
                label="평형"
                valid={"num"}
                withText={"평"}
              />

              <Input
                className="input_text"
                type="text"
                placeholder="주소 찾기를 해 주세요."
                name="addr"
                value={addr}
                setValue={setAddr}
                label="공사지 주소"
                withButton={"주소찾기"}
              />
              <Input
                className="input_text"
                type="text"
                placeholder="상세주소를 입력해 주세요."
                name="addrDetail"
                value={addrDetail}
                setValue={setAddrDetail}
                label="상세주소"
              />

              <div className="checkbox_section">
                <p className="input_label">시공범위</p>
                <div className="radio_box">
                  <input
                    type="radio"
                    id="rd1"
                    name="range"
                    className="radio2"
                    value="1"
                    checked={range === "1"}
                    onChange={(e) => setRange(e.target.value)}
                  />
                  <label htmlFor="rd1">전체</label>
                  <input
                    type="radio"
                    id="rd2"
                    name="range"
                    className="radio2"
                    value="2"
                    checked={range === "2"}
                    onChange={(e) => setRange(e.target.value)}
                  />
                  <label htmlFor="rd2">부분</label>
                </div>
              </div>

              <InputSelect
                name="style"
                label="스타일링 제품"
                value={style}
                setValue={setStyle}
                option={consts.style_buy}
              />
              <InputSelect
                name="style"
                label="철거"
                value={order_demolition_yn}
                setValue={setOrder_demolition_yn}
                option={[
                  { idx: "", title: "미선택" },
                  { idx: "y", title: "필요" },
                  { idx: "n", title: "필요없음" },
                ]}
              />

              <div className="input_check_box input_check_box_long">
                <p className="input_label">시공정보</p>
                <div>
                  {!load &&
                    consts.tag_id.map((x, i) => {
                      return (
                        <Fragment key={i}>
                          <input
                            type="checkbox"
                            id={"servechk" + i}
                            className="checkbox2 info"
                            value={x.idx}
                            defaultChecked={serve.includes(x.idx * 1)}
                            data-text={x.title}
                          />
                          <label htmlFor={"servechk" + i}>{x.title}</label>
                        </Fragment>
                      );
                    })}
                </div>
              </div>
              {/*<TextArea className="input_textarea" placeholder="내용을 입력해 주세요. (500자 이내)" name="estimate_msg" value={estimate_msg} setValue={setEstimate_msg} label="상담내용" /> */}
              <TextArea
                className="input_textarea"
                placeholder="내용을 입력해 주세요. (500자 이내)"
                name="order_msg"
                value={order_msg}
                setValue={setOrder_msg}
                label="요청사항"
              />
              <>
                <p className="casezone_title">
                  상담 내용
                  <button
                    type="button"
                    className="btn btn1"
                    onClick={() => counselAppend()}
                  >
                    추가
                  </button>
                </p>
                <table className="tableStyle1 instance_add_table">
                  <thead>
                    <tr>
                      <th className="wd150">유형</th>
                      <th className="wdFull">내용</th>
                      <th className="wd50"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {counselList.map((x, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            <InputSelect
                              name="counsel"
                              label="진행상태"
                              value={x.counsel}
                              option={[
                                { idx: "", title: "선택" },
                                ...consts.tag_id,
                              ]}
                              onChange={caseAllSetValue}
                              index={i}
                            />
                          </td>
                          <td>
                            <TextArea
                              className="input_textarea"
                              placeholder="내용을 입력해 주세요. (500자 이내)"
                              name="counsel_desc"
                              value={x.counsel_desc}
                              onChange={caseAllSetValue}
                              index={i}
                              maxlength={1000}
                            />
                          </td>
                          <td>
                            <button
                              type="button"
                              className="trash_btn_red"
                              onClick={() => counselDelete(i, "")}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </>

              <div className="input_box input_box_full">
                <label className="input_label" style={{ marginBottom: "6px" }}>
                  참고사진
                </label>
                <div className="input_section_multi">
                  {img_list &&
                    img_list.map((x, i) => {
                      return (
                        <div key={i} className="input_multi_preview">
                          <img
                            src={x.src}
                            alt={x.src}
                            onClick={() =>
                              dispatch(imgOpen({ imgs: img_list, active: i }))
                            }
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>

          <div className="form_section ">
            <p className="title">진행이력</p>

            <div className="form_list">
              <table className="tableStyle1" style={{ marginTop: 0 }}>
                <thead>
                  <tr>
                    <th className="wd110">진행상태</th>
                    <th className="wd150">대상</th>
                    <th className="wd110">회신상태</th>
                    <th className="wd220">일시</th>
                    <th className="wd220">파트너알림</th>
                    <th className="wd220">사용자알림</th>
                  </tr>
                </thead>
                <tbody>
                  {request_list.length > 0 ? (
                    request_list.map((x, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            {findJson(consts.sellerOrderState, x.order_state)}
                          </td>
                          <td>{x.seller_biz_name}</td>
                          <td>
                            {x.order_resp_state === "-"
                              ? x.order_resp_state
                              : findJson(
                                  consts.sellerOrderRespState,
                                  x.order_resp_state
                                )}
                          </td>
                          <td>{x.reg_date}</td>
                          <td>
                            {x.reg_date}
                            <br />
                            <button
                              type="button"
                              className="table_link_btn"
                              style={{ marginLeft: "0" }}
                            >
                              재발송
                            </button>
                          </td>
                          <td>
                            {x.reg_date}
                            <br />
                            <button
                              type="button"
                              className="table_link_btn"
                              style={{ marginLeft: "0" }}
                            >
                              재발송
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td className="null_td" colSpan={6}>
                        데이터가 없습니다.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="my_btn_box">
          <button
            type="button"
            className="btn btn3"
            onClick={() => closeFunc()}
          >
            취소
          </button>
          <button
            type="button"
            className="btn btn1"
            onClick={() => submitAlert()}
          >
            저장
          </button>
        </div>
      </div>
    </AdminLayout>
  );
}
