/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import Modal from "../../../Common/ModalPortal";
import images from "../../../../libs/images";
import * as Colors from "../../../../constants/color";
import Button from "../../../Common/Button/Button";
import {
  stepStyles,
  stepTitleStyles,
  labelStyles,
  marginTopStyles,
} from "../../notice.style";

import useNoticeUrl from "../noticeUrl.hook";
import { Item } from "../noticeUrl.type";
import InputWithButton from "../../../Common/Input/InputWithButton";
import Input from "../../../Common/Input/Input";
import Upload from "../../../Common/Upload/Upload";
import { handlerImgError } from "../../../../utils/home-manage";
import debounce from "lodash/debounce";
import Checkbox from "../../../Common/Checkbox/Checkbox";

const Container = styled.div`
  width: 560px;
  background-color: #ffffff;
  margin: auto;
`;

const Header = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${Colors.gray_200};

  & > h3 {
    margin: auto 0;
  }
`;

const Content = styled.div`
  padding: 20px;
  max-height: 550px;
  overflow-y: auto;
`;

const Footer = styled.div`
  padding: 20px;
  border-top: 1px solid ${Colors.gray_200};
  display: flex;
  justify-content: center;
  gap: 15px;
`;

interface Props {
  close: () => void;
  callback: () => void;
  id: string;
}

const UpdateNoticeUrl = (props: Props) => {
  const { close, id, callback } = props;
  const [item, setItem] = useState<Item>({} as Item);

  const { detail, update } = useNoticeUrl({
    id,
  });

  useEffect(() => {
    if (detail) {
      setItem({
        ...detail,
      });
    }
  }, [detail]);

  const formTypeList = [
    { value: "1", name: "이름" },
    { value: "2", name: "연락처" },
    { value: "3", name: "평형" },
    { value: "4", name: "공사 주소" },
    { value: "5", name: "공사 희망일" },
    { value: "6", name: "요청 사항" },
  ];

  const changeRadioHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setItem((prevData) => ({
      ...prevData,
      url_type: value,
    }));
  };

  const updateHandler = async () => {
    const res = await update(item);

    if (typeof res === "boolean") {
      if (res) {
        callback();
      } else {
        alert("등록중 오류가 발생하였습니다.");
      }
    }
  };

  const changeInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setItem((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const deletePreviewHandler = () => {
    setItem((prevData) => ({
      ...prevData,
      detail_page: "",
    }));
  };

  const changeFileHandler = debounce(({ fileImage }) => {
    setItem((prevData) => ({
      ...prevData,
      detail_page: fileImage,
    }));
  }, 200);

  const changeCheckboxHandler = (form_value?: string) => {
    setItem((prevData) => {
      if (form_value) {
        return {
          ...prevData,
          form_types: prevData.form_types.includes(form_value)
            ? prevData.form_types.filter((form) => form !== form_value)
            : [...prevData.form_types, form_value],
        };
      } else {
        return {
          ...prevData,
          btn_yn: prevData.btn_yn === "1" ? "0" : "1",
        };
      }
    });
  };

  if (!detail) {
    return <></>;
  }

  return (
    <Modal>
      <Container>
        <Header>
          <h3>URL 변경</h3>
          <button onClick={close}>
            <img src={images.exit} alt="exit_btn" />
          </button>
        </Header>
        <Content>
          <div>
            <div
              css={css`
                display: flex;
              `}
            >
              <p css={stepStyles}>1</p>
              <h3 css={stepTitleStyles}>기본 정보</h3>
            </div>
            <div css={[marginTopStyles]}>
              <p css={[labelStyles]}>* 구분</p>
              <div
                css={css`
                  margin-top: 12px;
                  display: flex;
                  gap: 40px;
                `}
              >
                <div>
                  <input
                    type="radio"
                    className="radio"
                    id="event"
                    value="1"
                    onChange={changeRadioHandler}
                    checked={item.url_type === "1"}
                  />
                  <label htmlFor="event">이벤트</label>
                </div>
                <div>
                  <input
                    type="radio"
                    className="radio"
                    id="contents"
                    value="2"
                    onChange={changeRadioHandler}
                    checked={item.url_type === "2"}
                  />
                  <label htmlFor="contents">컨텐츠</label>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div css={[marginTopStyles]}>
              <InputWithButton
                label="* 파트너 명"
                buttonText="검색"
                onClick={() => null}
                value={item?.seller_biz_name || ""}
                disabled
                disabled_button
              />
            </div>
            <div css={[marginTopStyles]}>
              <Input
                label="* 타이틀 명"
                placeholder="기존 이벤트/컨텐츠의 타이틀 명 입력"
                value={item?.url_title || ""}
                name="url_title"
                onChange={changeInputHandler}
              />
            </div>
            <div css={[marginTopStyles]}>
              <Upload
                label="* 상세 페이지 등록"
                id="detail_page"
                onError={handlerImgError}
                preview={item.detail_page || ""}
                deletePreivew={deletePreviewHandler}
                callback={changeFileHandler}
              />
            </div>
            <div
              css={[
                css`
                  margin-top: 30px;
                  display: flex;
                `,
              ]}
            >
              <p css={stepStyles}>2</p>
              <h3 css={stepTitleStyles}>선택 정보</h3>
            </div>
            <div css={[marginTopStyles]}>
              <Checkbox
                labelText="버튼(신청폼) 사용"
                value={item.btn_yn === "1"}
                onChange={() => changeCheckboxHandler()}
              />
            </div>
            {item.btn_yn === "1" && (
              <div css={[marginTopStyles]}>
                <Input
                  label="* 버튼 명 (최대 10자)"
                  placeholder="버튼명을 입력해주세요."
                  size="full"
                  value={item.btn_name || ""}
                  name="btn_name"
                  onChange={changeInputHandler}
                />
              </div>
            )}
            <div css={[marginTopStyles]}>
              <p css={[labelStyles]}>* 항목</p>
              <div
                css={css`
                  margin-top: 12px;
                  display: flex;
                  flex-wrap: wrap;
                  gap: 20px 40px;
                `}
              >
                {formTypeList.map((type) => (
                  <React.Fragment key={type.value}>
                    <Checkbox
                      labelText={type.name}
                      value={!!item?.form_types?.includes(type.value)}
                      onChange={() => changeCheckboxHandler(type.value)}
                    />
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </Content>
        <Footer>
          <Button theme="tertiary" onClick={close}>
            취소
          </Button>
          <Button onClick={updateHandler}>변경</Button>
        </Footer>
      </Container>
    </Modal>
  );
};

export default UpdateNoticeUrl;
