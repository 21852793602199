import React, { useState } from "react";

import { useDispatch } from "react-redux";

import { open, close } from "../../redux/popupSlice";

import Input from "../../components/Input";

import LoginId from "./LoginId";

export default function FindIdSuc(props) {
  const { fid } = props;
  const dispatch = useDispatch();

  const [id, setId] = useState(fid);

  /* 아이디 찾기 */
  const idLogin = () => {
    dispatch(close());

    setTimeout(() => {
      dispatch(
        open({
          title: "아이디와 비밀번호 입력 후\n로그인 요청드립니다.",
          titleLong: true,
          message: "*5회 실패 시 5분간 접속불가",
          component: <LoginId />,
        })
      );
    }, 50);
  };

  return (
    <form className="popup_form">
      <Input
        className="input_text"
        type="text"
        placeholder="입력해주세요."
        name="ip4"
        value={id}
        setValue={setId}
        label="아이디"
        readOnly={true}
        withButton="복사"
      />
      <div className="popup_btn">
        <button
          type="button"
          className="popbtn ok_btn"
          onClick={() => {
            idLogin();
          }}
          style={{ width: "100%" }}
        >
          로그인 하러 가기
        </button>
      </div>
    </form>
  );
}
