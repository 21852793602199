import { css } from "@emotion/react";
import * as Colors from "../../constants/color";

const deleteButtonStyles = css`
  background-color: ${Colors.error};
  color: ${Colors.white};
  padding: 5px 10px;
  border-radius: 5px;
`;

const marginTopStyles = css`
  margin-top: 20px;
`;

const labelStyles = css`
  font-size: 12px;
  color: ${Colors.blue_600};
  font-weight: bold;
`;

export { deleteButtonStyles, marginTopStyles, labelStyles };
