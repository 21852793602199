import React, { useState, useEffect } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import Modal from "../../../Common/ModalPortal";
import images from "../../../../libs/images";
import * as Colors from "../../../../constants/color";
import Button from "../../../Common/Button/Button";
import BasicInfo from "./BasicInfo";
import AddInfo from "./AddInfo";
import useNoticeEvent from "../noticeEvent.hook";
import { Item } from "../noticeEvent.type";
import Select from "../../../Common/Select/Select";
import { eventStateList } from "../../../../constants/home-manage";

const Container = styled.div`
  width: 1128px;
  background-color: #ffffff;
  margin: auto;
`;

const Header = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${Colors.gray_200};

  & > h3 {
    margin: auto 0;
  }
`;

const Content = styled.div`
  max-height: 580px;
  overflow-y: auto;
`;

const Footer = styled.div`
  padding: 20px;
  border-top: 1px solid ${Colors.gray_200};
  display: flex;
  justify-content: center;
  gap: 15px;
`;

const stepStyles = css`
  display: inline-block;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: ${Colors.black};
  color: ${Colors.white};
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  line-height: 26px;
`;

const stepTitleStyles = css`
  margin: auto 0 auto 20px;
`;

interface Props {
  close: () => void;
  callback: () => void;
  id: string;
}

const UpdateNoticeEvent = (props: Props) => {
  const { close, id, callback } = props;

  const { detail, update, urlList } = useNoticeEvent({
    id,
  });

  const [item, setItem] = useState<Item>({} as Item);

  useEffect(() => {
    if (detail) {
      setItem({ ...detail });
    }
  }, [detail]);

  const changeRadioHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setItem((prevData) => ({
      ...prevData,
      avt_type: value,
    }));
  };

  const changeSelectHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setItem((prevData) => ({
      ...prevData,
      avt_state: e.target.value,
    }));
  };

  const updateHandler = async (): Promise<void> => {
    const res = await update(item);

    if (typeof res === "boolean") {
      if (res) {
        callback();
      } else {
        alert("변경중 오류가 발생하였습니다.");
      }
    }
  };

  if (!detail) {
    return <></>;
  }

  return (
    <Modal>
      <Container>
        <Header>
          <h3>이벤트 변경</h3>
          <button onClick={close}>
            <img src={images.exit} alt="exit_btn" />
          </button>
        </Header>
        <Content>
          <div
            css={css`
              display: flex;
              padding: 30px 20px;
              border-bottom: 1px solid ${Colors.gray_200};
            `}
          >
            <div
              css={css`
                display: flex;
                align-items: center;
                width: 20%;
              `}
            >
              <p css={stepStyles}>1</p>
              <h3 css={stepTitleStyles}>카테고리 구분</h3>
            </div>
            <div
              css={css`
                display: flex;
                width: 80%;
              `}
            >
              <div
                css={css`
                  display: flex;
                  gap: 30px;
                  margin: 2px 0 0 0;

                  & > div {
                    display: flex;
                    align-items: center;
                  }
                `}
              >
                <div>
                  <input
                    type="radio"
                    id="event"
                    value="1"
                    className="radio"
                    onChange={changeRadioHandler}
                    checked={item.avt_type === "1"}
                  />
                  <label htmlFor="event">이벤트</label>
                </div>

                <div>
                  <input
                    type="radio"
                    id="content"
                    value="2"
                    className="radio"
                    onChange={changeRadioHandler}
                    checked={item.avt_type === "2"}
                  />
                  <label htmlFor="content">컨텐츠</label>
                </div>
              </div>
              <div
                css={css`
                  margin-left: auto;
                `}
              >
                <Select
                  label="등록 상태"
                  options={eventStateList}
                  value={item.avt_state}
                  onChange={changeSelectHandler}
                  size="small"
                />
              </div>
            </div>
          </div>
          <div
            css={css`
              padding: 30px 20px;
              border-bottom: 1px solid ${Colors.gray_200};
            `}
          >
            <div
              css={css`
                display: flex;
              `}
            >
              <p css={stepStyles}>2</p>
              <h3 css={stepTitleStyles}>기본 정보 등록</h3>
            </div>
            {item && (
              <BasicInfo data={item} setData={setItem} urlList={urlList} />
            )}
          </div>
          <div
            css={css`
              padding: 30px 20px;
            `}
          >
            <div
              css={css`
                display: flex;
              `}
            >
              <p css={stepStyles}>3</p>
              <h3 css={stepTitleStyles}>추가 정보 등록</h3>
            </div>
            {item && <AddInfo data={item} setData={setItem} detail={detail} />}
          </div>
        </Content>
        <Footer>
          <Button theme="tertiary" onClick={close}>
            취소
          </Button>
          <Button onClick={updateHandler}>변경</Button>
        </Footer>
      </Container>
    </Modal>
  );
};

export default UpdateNoticeEvent;
