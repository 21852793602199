/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import * as APIS from "../../utils/service";
import debounce from "lodash/debounce";
import { useNavigate } from "react-router-dom";

interface Props {
  step: number;
  construction_type?: string;
  start_date?: string;
  end_date?: string;
}
const useEvent = (props: Props) => {
  const navigate = useNavigate();
  const { pathname } = window.location;
  const { step, start_date, end_date, construction_type } = props;
  const params = new URLSearchParams(window.location.search);
  const page = params.get("page") || "1";

  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);

  const getList = async (paging: string): Promise<void> => {
    const params = {
      step: step,
      page: paging,
      start_date: start_date || "",
      end_date: end_date || "",
      construction_type: construction_type || "",
    };
    try {
      const res = await APIS.postData("/EventAp/EventList", params, 1);
      if (res.data.code === "OK") {
        setList(res.data.event_list);
        setTotal(res.data.event_cnt);
      } else {
        setList([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const search = debounce(() => {
    if (page === "1") {
      getList("1");
    } else {
      navigate({
        pathname: pathname,
        search: `?page=${1}`,
      });
    }
  }, 200);

  useEffect(() => {
    getList(page);
  }, [page]);

  const getDownloadList = async () => {
    let data = [];

    try {
      const res = await APIS.postData(
        "/EventAp/EventList",
        { step: step, all: 1 },
        1
      );

      if (res.data.code === "OK") {
        data = res.data.event_list;
      }

      return data;
    } catch (err) {
      console.log(err);
    }
  };

  return {
    list,
    total,
    page,
    search,
    getDownloadList,
  };
};

export default useEvent;
