import React, { useState } from "react";
import Input from "../../../components/Input";
import { close, open } from "../../../redux/popupSlice";
import PaySuccess from "../../../components/PaySuccess";
import { useDispatch } from "react-redux";
import { openSub } from "../../../redux/subPopupSlice";
import * as APIS from "../../../utils/service";
import TabTitle from "../TabTitle";
import { numFormat } from "../../../utils/utils";

export default function AddInfo(props) {
  const dispatch = useDispatch();
  const { userData, case_table_id, seller, data, tabNum } = props;

  const [minPrice, setMinPrice] = useState(
    data.case_info && data.case_info.case_price_min
      ? numFormat(data.case_info.case_price_min)
      : ""
  );
  const [maxPrice, setMaxPrice] = useState(
    data.case_info && data.case_info.case_price_max
      ? numFormat(data.case_info.case_price_max)
      : ""
  );

  const stepChange = () => {
    if (!case_table_id) {
      dispatch(
        openSub({
          message: "잘못된 접근입니다.",
          button: "확인",
        })
      );
      return;
    }
    const sender = {
      seller_table_id:
        userData.grade === 10 ? seller : userData.seller_table_id,
      case_table_id: case_table_id,
      case_price_min: minPrice ? minPrice.replaceAll(",", "") : 0,
      case_price_max: maxPrice ? maxPrice.replaceAll(",", "") : 0,
    };
    if (
      sender.case_price_min &&
      sender.case_price_max &&
      sender.case_price_min * 1 > sender.case_price_max * 1
    ) {
      dispatch(
        openSub({
          message: "최소가격과 최대가격을 확인해 주세요.",
          button: "확인",
        })
      );
      return;
    }
    /* 3step 임시저장 시키기 */
    APIS.postData("/WorkCase/CaseCreate4th", sender, 2)
      .then((res) => {
        if (res.data.code === "OK") {
          submitFunc();
        } else {
          dispatch(
            openSub({
              message: "잠시 후 다시 시도해주세요.",
              button: "확인",
            })
          );
        }
      })
      .catch((e) => {
        console.log("catch", e);
      });
    return;
  };

  const submitFunc = () => {
    dispatch(close());
    setTimeout(() => {
      dispatch(
        open({
          title: " ",
          suc: true,
          component: (
            <PaySuccess
              imgType={2}
              title={"시공사례 등록이 완료 되었습니다."}
              msg={
                "레이홈 운영자가 시공사례 검토 및 보완 후\n등록 완료 예정입니다."
              }
              helpMsg={
                "* 영업일 기준 1~2일 소요 예상\n* 필요에 따라 레이홈에서 등록한 사례에 대한\n문의전화를 요청 할 수 있습니다."
              }
            />
          ),
          button: "확인",
        })
      );
    }, 50);
  };
  return (
    <>
      <div className="popup_list_ul popup_instance_step">
        <TabTitle num={tabNum} />
        <div className="instance_form_list">
          <div className="input_range_box">
            <Input
              className="input_text"
              type="text"
              placeholder="숫자만 입력해 주세요."
              name="minPrice"
              maxlength={4}
              value={minPrice}
              setValue={setMinPrice}
              label="평당 예상 가격"
              withText={"만원"}
              valid={"price"}
            />
            <span>~</span>
            <Input
              className="input_text"
              type="text"
              placeholder="숫자만 입력해 주세요."
              name="maxPrice"
              maxlength={4}
              value={maxPrice}
              setValue={setMaxPrice}
              withText={"만원"}
              valid={"price"}
            />
          </div>
        </div>
      </div>
      <div className="popup_list_btn_box popup_pay_step">
        <button type="button" className="btn btn3" onClick={() => submitFunc()}>
          건너뛰기
        </button>
        <button type="button" className="btn btn1" onClick={() => stepChange()}>
          등록
        </button>
      </div>
    </>
  );
}
