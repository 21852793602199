import React, { useState, useEffect } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import Modal from "../../../Common/ModalPortal";
import images from "../../../../libs/images";
import Button from "../../../Common/Button/Button";
import {
  stepStyles,
  stepTitleStyles,
  marginTopStyles,
  labelStyles,
} from "../../notice.style";
import * as Colors from "../../../../constants/color";
import InputWithButton from "../../../Common/Input/InputWithButton";
import DateRange from "../../../Common/Date/DateRange";
import moment from "moment";
import { Item } from "../noticeAd.type";
import useNoticeAd from "../noticeAd.hook";
import Select from "../../../Common/Select/Select";
import Upload, { FileData } from "../../../Common/Upload/Upload";
import { eventStateList } from "../../../../constants/home-manage";

const Container = styled.div`
  width: 1128px;
  background-color: #ffffff;
  margin: auto;
`;

const Header = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${Colors.gray_200};

  & > h3 {
    margin: auto 0;
  }
`;

const Footer = styled.div`
  padding: 20px;
  border-top: 1px solid ${Colors.gray_200};
  display: flex;
  justify-content: center;
  gap: 15px;
`;

interface Props {
  close: () => void;
  callback: () => void;
  id: string;
}

const UpdateNoticeAd = (props: Props) => {
  const { close, callback, id } = props;
  const { area, detail, update } = useNoticeAd({
    id,
  });

  const [item, setItem] = useState<Item>({} as Item);

  useEffect(() => {
    if (detail) {
      setItem({
        ...detail,
        area_list: Array.from({ length: 3 }, (_, i) => {
          if (i < detail.area_list.length) {
            return detail.area_list[i];
          } else {
            return {
              ad_area_seq: String(i + 1),
              area1: "",
              area2: "",
              area_picture: "",
              isCreate: true,
            };
          }
        }),
      });
    }
  }, [detail]);

  const changeDateHandler = (type: string, date?: Date) => {
    if (date) {
      setItem((prevDAta) => ({
        ...prevDAta,
        ...(type === "start" && {
          ad_start_date: moment(date).format("YYYY-MM-DD 00:00:00"),
        }),
        ...(type === "end" && {
          ad_end_date: moment(date).format("YYYY-MM-DD 23:59:59"),
        }),
      }));
    }
  };

  const updateHandler = async (): Promise<void> => {
    const res = await update(item);

    if (typeof res === "boolean") {
      if (res) {
        callback();
      } else {
        alert("등록중 오류가 발생하였습니다.");
      }
    }
  };

  const changeSelectHandler = (
    type: "area1" | "area2",
    e: React.ChangeEvent<HTMLSelectElement>,
    id?: string
  ) => {
    const { value } = e.target;

    setItem((prevData) => ({
      ...prevData,
      area_list: prevData.area_list.map((item) =>
        item.ad_area_seq === id
          ? {
              ...item,
              [type]: value,
              ...(type === "area1" && { area2: "" }),
            }
          : item
      ),
    }));
  };

  const uploadHandler = (file: FileData, id?: string) => {
    setItem((prevData) => ({
      ...prevData,
      area_list: prevData.area_list.map((item) =>
        item.ad_area_seq === id
          ? {
              ...item,
              area_picture: file.fileImage,
            }
          : item
      ),
    }));
  };

  const deletePreviewHandler = (seq?: string) => {
    setItem((prevData) => ({
      ...prevData,
      area_list: prevData.area_list.map((item) =>
        item.ad_area_seq === seq
          ? {
              ...item,
              area_picture: "",
            }
          : item
      ),
    }));
  };

  const changeStateHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setItem((prevData) => ({
      ...prevData,
      ad_state: e.target.value,
    }));
  };

  if (!detail) {
    return <></>;
  }

  return (
    <Modal>
      <Container>
        <Header>
          <h3>노출 광고 변경</h3>
          <div
            css={css`
              display: flex;
            `}
          >
            <div
              css={css`
                display: flex;
              `}
            >
              <p
                css={[
                  labelStyles,
                  css`
                    margin: auto 10px auto 0;
                  `,
                ]}
              >
                * 등록상태
              </p>
              <Select
                options={eventStateList}
                value={item.ad_state}
                onChange={changeStateHandler}
                size="small"
              />
            </div>
            <button
              onClick={close}
              css={css`
                margin-left: 35px;
              `}
            >
              <img src={images.exit} alt="exit_btn" />
            </button>
          </div>
        </Header>
        <div>
          <div
            css={css`
              padding: 30px 20px;
              border-bottom: 1px solid ${Colors.gray_200};
            `}
          >
            <div
              css={css`
                display: flex;
              `}
            >
              <p css={stepStyles}>1</p>
              <h3 css={stepTitleStyles}>기본 정보</h3>
            </div>

            <div css={[marginTopStyles]}>
              <InputWithButton
                label="* 파트너 명"
                buttonText="검색"
                onClick={() => null}
                value={item.seller_biz_name || ""}
                size="big"
                disabled
                disabled_button
              />
            </div>
            <div css={[marginTopStyles]}>
              <DateRange
                label="* 운영 기간"
                onChange={changeDateHandler}
                startValue={
                  item.ad_start_date ? new Date(item.ad_start_date) : ""
                }
                endValue={item.ad_end_date ? new Date(item.ad_end_date) : ""}
                startPlaceholder="시작일"
                endPlaceholder="종료일"
                size="big"
              />
            </div>
          </div>
          <div
            css={css`
              padding: 30px 20px;
            `}
          >
            <div
              css={css`
                display: flex;
              `}
            >
              <p css={stepStyles}>2</p>
              <h3 css={stepTitleStyles}>지역 노출 정보</h3>
            </div>
            <div
              css={[
                marginTopStyles,
                css`
                  display: flex;
                  justify-content: space-between;
                `,
              ]}
            >
              {item.area_list?.map((item) => (
                <div
                  key={item.ad_area_seq}
                  css={css`
                    width: 30%;
                  `}
                >
                  <p css={[labelStyles]}>* 지역 {item.ad_area_seq}</p>
                  <div
                    css={[
                      marginTopStyles,
                      css`
                        display: flex;
                        justify-content: space-between;
                      `,
                    ]}
                  >
                    <div
                      css={css`
                        width: 40%;
                      `}
                    >
                      <Select
                        defaultValue="도/시"
                        options={area.map((item) => {
                          return {
                            value: item.area_id,
                            label: item.area_name,
                          };
                        })}
                        onChange={(e) =>
                          changeSelectHandler("area1", e, item.ad_area_seq)
                        }
                        value={item.area1 || ""}
                        size="full"
                      />
                    </div>

                    <div
                      css={css`
                        width: 55%;
                      `}
                    >
                      <Select
                        defaultValue="시/구/군"
                        options={
                          area
                            .find((it) => it.area_id === item.area1)
                            ?.area_2nd.map((item) => {
                              return {
                                value: item.area_detail_id,
                                label: item.area_detail_name,
                              };
                            }) || []
                        }
                        onChange={(e) =>
                          changeSelectHandler("area2", e, item.ad_area_seq)
                        }
                        value={item.area2 || ""}
                        size="full"
                      />
                    </div>
                  </div>
                  <div
                    css={[
                      marginTopStyles,
                      css`
                        & > div > div {
                          height: 68px;
                        }
                      `,
                    ]}
                  >
                    <Upload
                      id={`file_${item.ad_area_seq}`}
                      callback={(cb) => {
                        uploadHandler(cb, item.ad_area_seq);
                      }}
                      size="full"
                      placeholder="사진을 첨부해주세요."
                      deletePreivew={() =>
                        deletePreviewHandler(item.ad_area_seq)
                      }
                      preview={item.area_picture}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <Footer>
          <Button theme="tertiary" onClick={close}>
            취소
          </Button>
          <Button onClick={updateHandler}>변경</Button>
        </Footer>
      </Container>
    </Modal>
  );
};

export default UpdateNoticeAd;
