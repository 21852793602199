import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as APIS from "../utils/service";
import { reload } from "../redux/usersSlice";
export default function User() {
  const dispatch = useDispatch();
  const userData = useSelector((s) => s.usersReducer);

  useEffect(() => {
    if (userData.isReload) {
      const sender = {
        seller_table_id: userData.seller_table_id,
      };

      APIS.postData("/SellerInfo/BaseInfo", sender, 2)
        .then((res) => {
          dispatch(
            reload({
              data: res.data,
            })
          );
        })
        .catch((e) => {
          console.log("catch", e);
        });
    }
  }, [dispatch, userData.isReload, userData.seller_table_id]);

  return <></>;
}
