import React from "react";
import AdminLayout from "../../../components/Layout/admin";
import NoticePartner from "../../../components/Notice/Partner";
import useNotice from "./notice.hook";

const NoticePartnerPage = () => {
  const { tabs } = useNotice();

  return (
    <AdminLayout title="광고 관리" tabs={tabs}>
      <NoticePartner />
    </AdminLayout>
  );
};

export default NoticePartnerPage;
