import React, { useState } from "react";
import { css } from "@emotion/react";
import Button from "../../Common/Button/Button";
import Table from "../../Common/Table/Table";
import Pagination from "../../Common/Pagination/Pagination";
import moment from "moment";

import CreateNoticeAd from "./Create";
import UpdateNoticeAd from "./Update";
import useNoticeAd from "./noticeAd.hook";
import DateRange from "../../Common/Date/DateRange";
import Select from "../../Common/Select/Select";
import Input from "../../Common/Input/Input";
import { getEventState } from "../../../utils/home-manage";
import { eventStateList } from "../../../constants/home-manage";
import { CSVLink } from "react-csv";
import useCsv from "../../../hooks/useCsv";

const NoticeAd = () => {
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [id, setId] = useState<string | undefined>();

  const [areaData, setAreaData] = useState({
    area_id: "",
    area_detail_id: "",
  });

  const [dateData, setDateData] = useState({
    start_date: "",
    end_date: "",
  });

  const [searchData, setSearchData] = useState({
    ad_state: "",
    seller_biz_name: "",
  });

  const { list, page, total, search, area } = useNoticeAd({
    isList: true,
    searchData: {
      start_date: dateData.start_date,
      end_date: dateData.end_date,
      area1: areaData.area_id,
      area2: areaData.area_detail_id,
      ...searchData,
    },
  });

  const { csvLinkRef, getCsvHeader, downloadHandler } = useCsv();

  const changeAreaHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;

    setAreaData((prevData) => ({
      ...prevData,
      [name]: value,
      ...(name === "area_id" && { area_detail_id: "" }),
    }));
  };

  const changeSelectHandler = (
    e: React.ChangeEvent<HTMLSelectElement>,
    filed: string
  ) => {
    const { value } = e.target;

    setSearchData((prevData) => ({
      ...prevData,
      [filed]: value,
    }));
  };

  const changeInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setSearchData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const col = [
    { key: "ad_table_id", title: "번호" },
    {
      key: "seller_biz_name",
      title: "파트너 명",
    },
    {
      key: "area1",
      title: "도/시",
      render: (x: any) => (
        <span>{area.find((item) => item.area_id === x.area1)?.area_name}</span>
      ),
    },
    {
      key: "area2",
      title: "시/군/구",
      render: (x: any) => (
        <span>
          {
            area
              .find((item) => item.area_id === x.area1)
              ?.area_2nd.find((item) => item.area_detail_id === x.area2)
              ?.area_detail_name
          }
        </span>
      ),
    },
    {
      key: "ad_days",
      title: "운영기간",
    },
    {
      key: "remain_day",
      title: "잔여 운영일",
    },
    {
      key: "update_date",
      title: "최종 수정 일시",
      render: (x: any) => (
        <span>{moment(x.update_date).format("YYYY.MM.DD")}</span>
      ),
    },
    {
      key: "state",
      title: "상태",
      render: (data: any) => <span>{getEventState(data.ad_state)}</span>,
    },
  ];

  const changeDateHandler = (type: string, date?: Date) => {
    if (date) {
      setDateData((prevData) => ({
        ...prevData,
        ...(type === "start" && {
          start_date: moment(date).format("YYYY-MM-DD 00:00:00"),
        }),
        ...(type === "end" && {
          end_date: moment(date).format("YYYY-MM-DD 23:59:59"),
        }),
      }));
    }
  };

  const callback = () => {
    setIsOpenCreate(false);
    setId(undefined);
    search();
  };

  return (
    <div>
      <div
        css={css`
          margin-top: 24px;
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
        `}
      >
        <div>
          <DateRange
            label="날짜 선택"
            startPlaceholder="시작날짜"
            startValue={
              dateData.start_date ? new Date(dateData.start_date) : ""
            }
            endPlaceholder="종료날짜"
            endValue={dateData.end_date ? new Date(dateData.end_date) : ""}
            onChange={changeDateHandler}
          />
        </div>
        <div
          css={css`
            width: 110px;
          `}
        >
          <Select
            label="도/시"
            defaultValue="전체"
            options={area.map((item) => ({
              value: item.area_id,
              label: item.area_name,
            }))}
            size="full"
            name="area_id"
            value={areaData.area_id}
            onChange={changeAreaHandler}
          />
        </div>
        <div
          css={css`
            width: 110px;
          `}
        >
          <Select
            label="시/구/군"
            defaultValue="전체"
            options={
              area
                .find((item) => item.area_id === areaData.area_id)
                ?.area_2nd.map((item) => ({
                  value: item.area_detail_id,
                  label: item.area_detail_name,
                })) || []
            }
            size="full"
            name="area_detail_id"
            value={areaData.area_detail_id}
            onChange={changeAreaHandler}
          />
        </div>
        <div
          css={css`
            width: 130px;
          `}
        >
          <Select
            label="상태"
            defaultValue="전체"
            options={eventStateList}
            size="full"
            onChange={(e) => changeSelectHandler(e, "ad_state")}
          />
        </div>
        <div>
          <Input
            label="파트너 명"
            placeholder="입력"
            name="seller_biz_name"
            onChange={changeInputHandler}
            size="small"
          />
        </div>
        <div
          css={css`
            width: 110px;
            margin: auto 10px 0 0;

            & > button {
              width: 100%;
            }
          `}
        >
          <Button theme="tertiary" onClick={search}>
            검색
          </Button>
        </div>
        <div
          css={css`
            width: 110px;
            margin: auto 0 0 0;
          `}
        >
          <Button theme="tertiary" onClick={() => downloadHandler()}>
            엑셀 다운로드
          </Button>
          <CSVLink
            data={list.map((l) => ({
              ...l,
              area1: area.find((item) => item.area_id === l.area1)?.area_name,
              area2: area
                .find((item) => item.area_id === l.area1)
                ?.area_2nd.find((item) => item.area_detail_id === l.area2)
                ?.area_detail_name,
              state: getEventState(l.ad_state),
            }))}
            ref={csvLinkRef}
            headers={getCsvHeader(col)}
            filename={
              "광고관리_노출광고" +
              moment(new Date()).format("YYYY_MM_DD(HH)") +
              ".csv"
            }
          />
        </div>
        <div
          css={css`
            margin: auto 0 0 auto;
          `}
        >
          <Button onClick={() => setIsOpenCreate(true)}>등록</Button>
        </div>
      </div>
      <div
        css={css`
          margin: 30px 0;
        `}
      >
        <Table
          columns={col}
          datas={list}
          rowCallback={(data) => setId(data.ad_table_id)}
        />
      </div>

      <Pagination curPage={page} total={total} pageSize={20} />

      {isOpenCreate && (
        <CreateNoticeAd
          close={() => setIsOpenCreate(false)}
          callback={callback}
        />
      )}
      {id && (
        <UpdateNoticeAd
          close={() => setId(undefined)}
          callback={callback}
          id={id}
        />
      )}
    </div>
  );
};

export default NoticeAd;
