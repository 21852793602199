/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import * as APIS from "../../../utils/service";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export interface List {
  line_banner_id: string;
  avt_type: string;
  serller_table_id: string;
  seller_biz_name: string;
  avt_name: string;
  line_title: string;
  avt_days: string;
  remain_day: string;
  update_date: string;
  avt_state: string;
  display_seq?: string;
}

interface Props {
  start_date?: string;
  end_date?: string;
  avt_type?: string;
  seller_biz_name?: string;
  avt_title?: string;
  avt_state?: string;
}

const useHomeManageLineBanner = (props: Props) => {
  const userData = useSelector((s: any) => s.usersReducer);
  const navigate = useNavigate();
  const { pathname } = window.location;

  const [list, setList] = useState<List[]>([]);
  const [total, setTotal] = useState(0);

  const params = new URLSearchParams(window.location.search);
  const page = params.get("page") || "1";

  const getList = async (page: string) => {
    const params = {
      admin_table_id: userData.admin_table_id,
      page: page,
      ...props,
    };

    try {
      const res = await APIS.postData("/AdminHome/LineList", params, 1);

      if (res.data.code === "OK") {
        setList(res.data.line_list);
        setTotal(res.data.total_cnt);
      } else {
        setList([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const search = () => {
    if (page === "1") {
      getList("1");
    } else {
      navigate({
        pathname: pathname,
        search: `?page=${1}`,
      });
    }
  };

  useEffect(() => {
    getList(page);
  }, [page]);

  const updateSeq = async (
    data: {
      line_banner_id: string;
      display_seq: string;
    }[]
  ): Promise<boolean | undefined> => {
    if (window.confirm("노출순위를 변경하시겠습니까?")) {
      try {
        const res = await APIS.postData(
          "/AdminHome/LineSeqUpdate",
          {
            admin_table_id: userData.admin_table_id,
            line_data: data,
          },
          1
        );

        if (res.data.code === "OK") {
          return true;
        } else {
          return false;
        }
      } catch (err) {
        console.log(err);
        return false;
      }
    }
  };

  return {
    list,
    page,
    total,
    search,
    updateSeq,
  };
};

export default useHomeManageLineBanner;
