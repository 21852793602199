import React, { useState, useEffect } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import Modal from "../../Common/ModalPortal";
import images from "../../../libs/images";
import Button from "../../Common/Button/Button";
import * as Colors from "../../../constants/color";
// import { marginTopStyles } from "../home.style";
import Input from "../../Common/Input/Input";
import Select from "../../Common/Select/Select";
import consts from "../../../libs/consts";
import useMember, { Target } from "../member.hook";

const Container = styled.div`
  width: 560px;
  background-color: #ffffff;
  margin: auto;
`;

const Header = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${Colors.gray_200};

  & > h3 {
    margin: auto 0;
  }
`;

const Content = styled.div`
  max-height: 300px;
  margin: 0 20px 10px 20px;
`;

const Footer = styled.div`
  padding: 20px;
  border-top: 1px solid ${Colors.gray_200};
  display: flex;
  justify-content: center;
  gap: 15px;
`;

interface Props {
  close: () => void;
  id: string;
  callback: () => void;
}

const UpdateMember = (props: Props) => {
  const { close, id, callback } = props;

  const { target, optionFunc, getUserType, update } = useMember({
    id,
  });

  const [item, setItem] = useState<Omit<Target, "update_date">>({
    user_table_id: "",
    user_id: "",
    kakao_auth_key: "",
    user_name: "",
    user_type: "",
    user_phone: "",
    user_state: "",
    gender: "",
    market_agree: "",
    user_birth: "",
  });

  useEffect(() => {
    if (target) {
      setItem({ ...target });
    }
  }, [target]);

  if (!target) {
    return <></>;
  }

  const changeInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setItem((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const changeRadioHandler = (name: string, value: string) => {
    setItem((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const changeSelectHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setItem((prevData) => ({
      ...prevData,
      user_state: e.target.value,
    }));
  };

  const updateHandler = async (): Promise<void> => {
    const {
      user_name,
      user_phone,
      user_state,
      user_birth,
      gender,
      market_agree,
    } = item;

    const params = {
      user_table_id: id,
      user_name,
      user_phone,
      user_state,
      user_birth,
      gender,
      market_agree,
    };

    const res = await update(params);

    if (res) {
      callback();
    }
  };

  if (!item) {
    return <></>;
  }

  return (
    <Modal>
      <Container>
        <Header>
          <h3>회원번호</h3>
          <button onClick={close}>
            <img src={images.exit} alt="exit_btn" />
          </button>
        </Header>
        <Content>
          <div
            css={css`
              margin-top: 20px;
              display: flex;
              justify-content: space-between;
            `}
          >
            <div>
              <div>
                <Input
                  label="이름"
                  size="small"
                  name="user_name"
                  value={item?.user_name}
                  onChange={changeInputHandler}
                />
              </div>
              <div>
                <Input
                  label="전화번호"
                  size="small"
                  name="user_phone"
                  value={item?.user_phone}
                  onChange={changeInputHandler}
                />
              </div>
              <div>
                <Input
                  label="회원구분"
                  size="small"
                  name="kakao_auth_key"
                  value={getUserType(item?.kakao_auth_key)}
                  disabled={true}
                />
              </div>
              <label className="input_label">성별</label>
              <div
                css={css`
                display: flex;
                gap: 30px;
                margin: 2px 0 0 0;

                & > div {
                  display: flex;
                  align-items: center;
                }
              `}
              >
                <div>
                  <input
                    type="radio"
                    id="male"
                    value="1"
                    className="radio"
                    onChange={(e) => { changeRadioHandler('gender', e.target.value) }}
                    checked={item.gender === "1"}
                  />
                  <label htmlFor="male">남성</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="female"
                    value="2"
                    className="radio"
                    onChange={(e) => { changeRadioHandler('gender', e.target.value) }}
                    checked={item.gender === "2"}
                  />
                  <label htmlFor="female">여성</label>
                </div>
              </div>
            </div>
            <div>
              <div>
                <Input
                  label="회원아이디"
                  size="small"
                  name="user_id"
                  value={item?.user_id}
                  disabled={true}
                />
              </div>
              <div>
                <Select
                  label="회원상태"
                  size="small"
                  options={optionFunc(consts.userStateOption)}
                  value={item?.user_state}
                  onChange={changeSelectHandler}
                />
              </div>
              <div>
                <Input
                  label="생년월일"
                  size="small"
                  name="user_birth"
                  value={item?.user_birth}
                  onChange={changeInputHandler}
                />
              </div>
              <label className="input_label">마케팅동의여부</label>
              <div
                css={css`
                display: flex;
                gap: 30px;
                margin: 2px 0 0 0;

                & > div {
                  display: flex;
                  align-items: center;
                }
              `}
              >
                <div>
                  <input
                    type="radio"
                    id="agree"
                    value="1"
                    className="radio"
                    onChange={(e) => { changeRadioHandler('market_agree', e.target.value) }}
                    checked={item.market_agree === "1"}
                  />
                  <label htmlFor="agree">동의</label>
                </div>

                <div>
                  <input
                    type="radio"
                    id="disagree"
                    value="0"
                    className="radio"
                    onChange={(e) => { changeRadioHandler('market_agree', e.target.value) }}
                    checked={item.market_agree === "0"}
                  />
                  <label htmlFor="disagree">비동의</label>
                </div>
              </div>
            </div>
          </div>
        </Content>
        <Footer>
          <Button theme="tertiary" onClick={close}>
            취소
          </Button>
          <Button onClick={updateHandler}>변경</Button>
        </Footer>
      </Container>
    </Modal >
  );
};
export default UpdateMember;
