import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import * as APIS from "../../../utils/service";
import { close } from '../../../redux/popupSlice';
import Input from '../../../components/Input';

export default function LogsUpdate(props) {
    const dispatch = useDispatch();
    const userData = useSelector((s) => s.usersReducer);
    const [reason, setReason] = useState('');
    const [error, setError] = useState('');
    const stateFunc = () => {
        if(!reason) {
            setError("취소사유를 입력해 주세요.");
        } else {
            let sender = {
                supply_table_id: props.supply_table_id,
                admin_table_id: userData.admin_table_id,
                seller_table_id: props.seller_table_id,
                desc : reason,
                supply_type:props.supplyType==="1" ? "2" : "1",
            }
            APIS.postData("/AdminLog/CancelDeduct", sender, 1).then(() => {
                props.setRefundState(props.supplyType==="1" ? "2" : "1");
            }).catch((e) => {
                console.log("catch", e);
            });
            dispatch(close());
        }
    }
    return (
        <form className="popup_form" >
            <Input className="input_text" type="text" placeholder="내용을 입력해 주세요." name="id" value={reason} setValue={setReason} label="취소사유" error={error} setError={setError}/>
            <div className="popup_btn">
                <button type="button" className="popbtn cencle_btn" onClick={() => { dispatch(close());}} style={{ width: "100%", marginRight: "10px"}}>
                    취소
                </button>
                <button type="button" className="popbtn ok_btn" onClick={() => { stateFunc();}} style={{ width: "100%"}}>
                    확인
                </button>
            </div>
        </form>
    );
}
