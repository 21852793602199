import React from "react";

import { useDispatch } from "react-redux";

import { close } from "../redux/popupSlice";

export default function PaySuccess(props) {
  const dispatch = useDispatch();
  const { imgType = "", title, msg, helpMsg, onPress } = props;

  return (
    <>
      <div className="success_pop">
        {imgType === 2 ? (
          <p className={"indent999 success_img2"}>success_img</p>
        ) : (
          <p className={"indent999 success_img"}>success_img</p>
        )}
        <p className="success_title">{title}</p>
        <p className="success_msg">{msg}</p>
        {helpMsg && <p className="success_help_msg">{helpMsg}</p>}
      </div>
      <div className="success_pop_btn_box">
        <button
          type="button"
          className="btn btn1"
          onClick={() => {
            dispatch(close());
            onPress && onPress();
          }}
        >
          확인
        </button>
      </div>
    </>
  );
}
