import React from "react";
import AdminLayout from "../../../components/Layout/admin";
import useNotice from "./notice.hook";
import NoticeAd from "../../../components/Notice/Ad";

const NoticeAdPage = () => {
  const { tabs } = useNotice();

  return (
    <AdminLayout title="광고 관리" tabs={tabs}>
      <NoticeAd />
    </AdminLayout>
  );
};

export default NoticeAdPage;
