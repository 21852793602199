import React, { useState } from "react";
import { css } from "@emotion/react";
import Button from "../../Common/Button/Button";
import Table from "../../Common/Table/Table";
import Pagination from "../../Common/Pagination/Pagination";
import CreateNoticeUrl from "./Create";
import UpdateNoticeUrl from "./Update";
import useNoticeUrl from "./noticeUrl.hook";
import Select from "../../Common/Select/Select";
import Input from "../../Common/Input/Input";
import { getEventType } from "../../../utils/home-manage";
import * as Colors from "../../../constants/color";
import { noticeUrlTypeList } from "../../../constants/home-manage";
import { CSVLink } from "react-csv";
import useCsv from "../../../hooks/useCsv";
import moment from "moment";
import consts from "../../../libs/consts";

const NoticeUrl = () => {
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [id, setId] = useState<string | undefined>();

  const [searchData, setSearchData] = useState({
    url_type: "",
    seller_biz_name: "",
    url_title: "",
  });

  const { list, page, total, search } = useNoticeUrl({
    isList: true,
    searchData,
  });

  const { csvLinkRef, getCsvHeader, downloadHandler } = useCsv();

  const copyClipBoardHandler = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      alert("클립보드에 링크가 복사되었습니다.");
    } catch (e) {
      alert("복사에 실패하였습니다");
    }
  };

  const changeSelectHandler = (
    e: React.ChangeEvent<HTMLSelectElement>,
    filed: string
  ) => {
    const { value } = e.target;

    setSearchData((prevData) => ({
      ...prevData,
      [filed]: value,
    }));
  };

  const changeInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setSearchData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const getUrlName = (id: string) => {
    return `${consts.apiBaseUrl}/event/${id}`;
  };

  const col = [
    { key: "url_table_id", title: "번호" },
    {
      key: "url_type",
      title: "구분",
      render: (data: any) => <span>{getEventType(data.url_type)}</span>,
    },
    {
      key: "seller_biz_name",
      title: "파트너명",
    },
    {
      key: "url_title",
      title: "타이틀 명",
    },
    {
      key: "form_url",
      title: "URL 생성",
      render: (data: any) => <span>{getUrlName(data.url_table_id)}</span>,
    },
    {
      key: "etc",
      title: "비고",
      className: "table_etc",
      render: (data: any) => (
        <button
          css={css`
            border: 1px solid ${Colors.gray_300};
            background-color: ${Colors.gray_100};
            padding: 3px 8px;
            font-size: 12px;
          `}
          onClick={(e) => {
            e.stopPropagation();
            const url = getUrlName(data.url_table_id);

            copyClipBoardHandler(url);
          }}
        >
          복사
        </button>
      ),
    },
  ];

  const callback = () => {
    setIsOpenCreate(false);
    setId(undefined);
    search();
  };

  return (
    <div>
      <div
        css={css`
          margin-top: 24px;
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
        `}
      >
        <div
          css={css`
            width: 130px;
          `}
        >
          <Select
            label="구분"
            defaultValue="전체"
            options={noticeUrlTypeList}
            size="full"
            onChange={(e) => changeSelectHandler(e, "url_type")}
          />
        </div>
        <div>
          <Input
            label="파트너 명"
            placeholder="입력"
            name="seller_biz_name"
            onChange={changeInputHandler}
            size="small"
          />
        </div>
        <div>
          <Input
            label="타이틀 명"
            placeholder="입력"
            size="small"
            name="url_title"
            onChange={changeInputHandler}
          />
        </div>
        <div
          css={css`
            margin: auto 0 0 0;
          `}
        >
          <Button theme="tertiary" onClick={search}>
            검색
          </Button>
        </div>
        <div
          css={css`
            margin: auto 0 0 0;
          `}
        >
          <Button theme="tertiary" onClick={() => downloadHandler()}>
            엑셀 다운로드
          </Button>
          <CSVLink
            data={list.map((l) => ({
              ...l,
              url_type: getEventType(l.url_type),
            }))}
            ref={csvLinkRef}
            headers={getCsvHeader(col)}
            filename={
              "광고관리_URL생성" +
              moment(new Date()).format("YYYY_MM_DD(HH)") +
              ".csv"
            }
          />
        </div>
        <div
          css={css`
            margin: auto 0 0 auto;
          `}
        >
          <Button onClick={() => setIsOpenCreate(true)}>등록</Button>
        </div>
      </div>
      <div
        css={css`
          margin: 30px 0;

          .table_etc {
            width: 130px;
          }
        `}
      >
        <Table
          columns={col}
          datas={list}
          rowCallback={(data) => setId(data.url_table_id)}
        />
      </div>

      <Pagination curPage={page} total={total} pageSize={20} />
      {isOpenCreate && (
        <CreateNoticeUrl
          close={() => setIsOpenCreate(false)}
          callback={callback}
        />
      )}
      {id && (
        <UpdateNoticeUrl
          close={() => setId(undefined)}
          callback={callback}
          id={id}
        />
      )}
    </div>
  );
};

export default NoticeUrl;
