import React, { Fragment } from "react";

import { useDispatch } from "react-redux";
import consts from "../libs/consts";

import { closeSub } from "../redux/subPopupSlice";

export default function Tags(props) {
  const {
    value,
    setValue,
    setValueText,
    maxlength = 10,
    onChange,
    onChange2,
    index,
    name,
    textname,
  } = props;
  const dispatch = useDispatch();

  const submitFunc = () => {
    let arr = [];
    let arrText = [];

    const checkboxes = document.getElementsByClassName("checkbox_list");
    for (let cb of checkboxes) {
      if (cb.checked) {
        arr.push(cb.value);
        arrText.push(cb.dataset.text);
      }
    }

    if (arr.length > 10) {
      alert("최대 " + maxlength + "개까지 선택 가능합니다.");
      return;
    }

    if (onChange) {
      let nm = { target: { name: name ? name : "case_tag" } };
      onChange(index, nm, arr);
      nm = {
        target: {
          name: textname ? textname : name ? name + "_text" : "case_tag_text",
        },
      };
      onChange(index, nm, arrText);
    } else if (onChange2) {
      let nm = { target: { name: name ? name : "case_tag" } };
      let nm2 = {
        target: {
          name: textname ? textname : name ? name + "_text" : "case_tag_text",
        },
      };

      onChange2(index, nm, nm2, arr, arrText);
    } else {
      setValue(arr);
      setValueText(arrText.join(", "));
    }

    dispatch(closeSub());
  };

  return (
    <form className="popup_tag">
      <div className="tags_box">
        {consts.tagoption.map((x, i) => {
          return (
            <Fragment key={i}>
              <input
                type="checkbox"
                id={"tagchk" + i}
                className="checkbox2 checkbox_list"
                value={x.idx}
                data-text={x.title}
                defaultChecked={value.includes(x.idx)}
              />
              <label htmlFor={"tagchk" + i}>{x.title}</label>
            </Fragment>
          );
        })}
      </div>

      <div className="popup_btn">
        <button
          type="button"
          className="popbtn cencle_btn"
          onClick={() => {
            dispatch(closeSub());
          }}
          style={{ width: "100%", marginRight: "10px" }}
        >
          취소
        </button>
        <button
          type="button"
          className="popbtn ok_btn"
          onClick={() => {
            submitFunc();
          }}
          style={{ width: "100%" }}
        >
          확인
        </button>
      </div>
    </form>
  );
}
