/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { getList } from "../../apis/adminLifeOrder";
import { useSelector } from "react-redux";

const useList = (page = 1) => {
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);

  const userData = useSelector((s) => s.usersReducer);

  useEffect(() => {
    getData();
  }, [page]);

  const getData = async () => {
    try {
      const params = {
        admin_table_id: userData.admin_table_id,
        page: Number(page),
      };

      const res = await getList(params);

      setList(res.life_order_list);
      setTotal(res.total_cnt);
    } catch (err) {
      console.log(err);
    }
  };

  return {
    list,
    total,
  };
};

export default useList;
