/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import * as APIS from "../../utils/service";
import { close } from "../../redux/popupSlice";
import { openSub } from "../../redux/subPopupSlice";
import { loadingStart, loadingEnd } from "../../redux/loadingSlice";
import Input from "../../components/Input";
import InputDate from "../../components/InputDate";
import InputSelect from "../../components/InputSelect";
import InputSelectArea from "../../components/InputSelectArea";
import InputFile from "../../components/InputFile";
import InputFileArr from "../../components/InputFileArr";
import InputFileMulti2 from "../../components/InputFileMulti2";
import TextArea from "../../components/TextArea";
import Tags from "../../components/Tags";
import images from "../../libs/images";
import consts from "../../libs/consts";

export default function OrderAdd(props) {
  const { id, order_id } = props;
  const dispatch = useDispatch();
  const userData = useSelector((s) => s.usersReducer);

  const [step, setStep] = useState(1);
  const [load, setLoad] = useState(true);

  const cont_table_id = id || "";
  const [order_table_id, setOrder_table_id] = useState(
    order_id ? order_id : ""
  );

  /* step1 state */

  const [date1, setDate1] = useState(); // 요청일자
  const [date2, setDate2] = useState(); // 최종일지

  const [cont_num, setCont_num] = useState(""); // 계약번호

  const [case_name, setCase_name] = useState(""); // 아파트명
  const [case_size, setCase_size] = useState(""); // 평형

  const [price, setPrice] = useState(""); // 시공가격
  const [price1, setPrice1] = useState(""); // 계약금
  const [price2, setPrice2] = useState(""); // 중도금
  const [price3, setPrice3] = useState(""); // 잔금

  const [reqDesc, setReqDesc] = useState(""); // 요청사항
  const [desc, setDesc] = useState(""); // 비고

  const [case_work_area, setcase_work_area] = useState(""); // 시공지역 1depth
  const [case_work_area2, setcase_work_area2] = useState(""); // 시공지역 2depth

  const [recpPhoto, setRecpPhoto] = useState(""); // 도면사진
  const [recp_img, setRecp_img] = useState(""); // 도면사진 Base64 엔코딩한거
  const [is_recp_del, setis_recp_del] = useState(id ? 0 : 1); // 도면사진 삭제여부

  const [mainPhoto, setmainPhoto] = useState(""); // 메인사진
  const [main_img, setmain_img] = useState(""); // 메인사진 Base64 엔코딩한거
  const [is_main_del, setis_main_del] = useState(id ? 0 : 1); // 메인사진 삭제여부

  const [receipt, setReceipt] = useState(""); // 계약서 파일
  const [receiptBase, setReceiptBase] = useState(""); // 계약서 파일 Base64 엔코딩한거
  const [is_receipt_del, setis_receipt_del] = useState(id ? 0 : 1);

  const [asWarranty, setAsWarranty] = useState(""); // AS 보증 기간

  const [range, setRange] = useState(""); //시공 범위

  const [sdate, setSdate] = useState(""); // 시공시작 예정일
  const [edate, setEdate] = useState(""); // 시공종료 예정일

  const [info, setInfo] = useState([]); // 시공정보
  const [case_work_parts, setcase_work_parts] = useState([]); // 공간구성

  const [material_list, setMaterial_list] = useState([]); // 시공별 상세정보 배열
  const [material_del, setMaterial_del] = useState([]); // 시공별 가격정보 삭제 고유번호 리스트

  const [reg_date, setReg_date] = useState(""); // 등록일시
  const [update_date, setupdate_date] = useState(""); // 수정일시

  const [real_start_date, setReal_start_date] = useState(""); // 시공 시작일
  const [real_end_date, setReal_end_date] = useState(""); // 시공 종료일
  const [before_client_msg, setBefore_client_msg] = useState(""); // 시공전 고객 요청사항
  const [after_client_msg, setAfter_client_msg] = useState(""); // 시공후 고객 요청사항
  const [partner_msg, setPartner_msg] = useState([]); // 파트너 요청사항
  const [last_desc, setLast_desc] = useState([]); // 설명

  const [zone_list, setZone_list] = useState([]); // 공간별 상세 정보 배열

  const [zone_del, setZone_del] = useState([]); // 공간별 삭제 고유번호 리스트
  const [zone_del_img, setZone_del_img] = useState([]); // 공간별 상세 정보 이미지 삭제 고유번호 리스트

  /* step2 state end */

  /* step3 state */

  const [style_check, setStyle_check] = useState(true); // 홈스타일링 사용여부

  const [style_price0, setStyle_price0] = useState(""); // 홈스타일가격
  const [style_price1, setStyle_price1] = useState(""); // 계약금
  const [style_price2, setStyle_price2] = useState(""); // 중도금
  const [style_price3, setStyle_price3] = useState(""); // 잔금

  const [style_list, setStyle_list] = useState([]); // 홈스타일링 배열
  const [style_del, setStyle_del] = useState([]); // 홈스타일링 삭제 고유번호 리스트
  const [style_del_img, setStyle_del_img] = useState([]); // 홈스타일링 이미지 삭제 고유번호 리스트

  const [review_table_id, setReview_table_id] = useState("");
  const [review_reg_date, setReview_reg_date] = useState(""); // 등록일시
  const [star, setStar] = useState(""); // 고객평점

  const [review_price, setReview_price] = useState(); // 가격
  const [review_service, setReview_service] = useState(); // 서비스
  const [review_quality, setReview_quality] = useState(); // 품질
  const [review_style, setReview_style] = useState(); // 홈스타일링
  const [review_desc, setReview_desc] = useState(""); // 총평

  const [review_photo, setReview_photo] = useState("");
  const [review_img, setReview_img] = useState(""); // 리뷰사진
  const [is_review_del, setis_review_del] = useState(0); // 리뷰사진 삭제여부

  /* step4 state end */

  useEffect(() => {
    document.querySelector(".popup_list_ul").scrollTo(0, 0);

    if (step === 1) {
      setLoad(true);
      setInfo([]);
      setcase_work_parts([]);

      const sender = {
        admin_table_id: userData.admin_table_id,
        order_table_id: order_table_id,
        cont_table_id: cont_table_id,
      };

      APIS.postData("/AdminCont/ContBase", sender, 1)
        .then((res) => {
          if (res.data.code === "OK") {
            const one = res.data.cont_info;
            const two = res.data.case_info;
            const three = two.zone_parts;

            setDate1(one.reg_date);
            setDate2(one.update_date);

            setCase_name(two.case_name);
            setCase_size(two.case_size);

            setcase_work_area(two.case_work_area);
            setcase_work_area2(two.case_work_area2);

            setRecpPhoto("");
            setmainPhoto("");

            setmain_img(two.main_img);
            setRecp_img(two.recp_img);

            setis_main_del(two.main_img ? 0 : 1);
            setis_recp_del(two.recp_img ? 0 : 1);

            setis_receipt_del(one.contract_file ? 0 : 1);
            setReceiptBase(one.contract_file);
            setAsWarranty(one.warranty);
            setRange(one.range);

            setCont_num(one.cont_table_id);
            setPrice(one.order_price);
            setPrice1(one.cont_price);
            setPrice2(one.m_price);
            setPrice3(one.last_price);

            setSdate(
              one.work_start_date &&
                one.work_start_date !== "0000-00-00 00:00:00"
                ? moment(one.work_start_date).format("YYYY.MM.DD")
                : ""
            );
            setEdate(
              one.work_end_date && one.work_end_date !== "0000-00-00 00:00:00"
                ? moment(one.work_end_date).format("YYYY.MM.DD")
                : ""
            );

            setReqDesc(one.style_desc);
            setDesc(one.base_desc);
            setInfo(two.case_work_type ? two.case_work_type.split(",") : []);
            setcase_work_parts(
              two.case_work_parts ? two.case_work_parts.split(",") : []
            );

            setMaterial_list(three);
          }
        })
        .catch((e) => {
          console.log("catch", e);
        })
        .finally(() => {
          setLoad(false);
        });
    } else if (step === 2) {
      const sender = {
        admin_table_id: userData.admin_table_id,
        cont_table_id: cont_table_id,
      };

      APIS.postData("/AdminCont/ContWork", sender, 1)
        .then((res) => {
          if (res.data.code === "OK") {
            const one = res.data.cont_info;
            const two = res.data.case_info;

            setReg_date(one.reg_date);
            setupdate_date(one.update_date);

            setReal_start_date(
              one.real_start_date &&
                one.real_start_date !== "0000-00-00 00:00:00"
                ? moment(one.real_start_date).format("YYYY.MM.DD")
                : ""
            ); /* 시공시작일 */
            setReal_end_date(
              one.real_end_date && one.real_end_date !== "0000-00-00 00:00:00"
                ? moment(one.real_end_date).format("YYYY.MM.DD")
                : ""
            ); /* 시공종료일 */

            setBefore_client_msg(
              one.before_client_msg
            ); /* 시공전 고객 요청사항 */
            setAfter_client_msg(
              one.after_client_msg
            ); /* 시공후 고객 요청사항 */
            setPartner_msg(one.partner_msg); /* 파트너 요청사항 */
            setLast_desc(one.last_desc); /* 설명 */

            setZone_list(
              two.case_parts.map((x, i) => {
                return { ...x, photoList: "", photoList_after: "" };
              })
            ); /* 공간별 상세 정보 배열 */
          }
        })
        .catch((e) => {
          console.log("catch", e);
        })
        .finally(() => {
          setLoad(false);
        });
    } else if (step === 3) {
      setLoad(true);
      const sender = {
        admin_table_id: userData.admin_table_id,
        cont_table_id: cont_table_id,
      };

      APIS.postData("/AdminCont/ContStyle", sender)
        .then((res) => {
          if (res.data.code === "OK") {
            const one = res.data.cont_info;
            const two = res.data.case_info;

            setStyle_check(one.style_buy_chk === "1" ? true : false);
            setStyle_price0(one.style_price);
            setStyle_price1(one.style_cont_price);
            setStyle_price2(one.style_m_price);
            setStyle_price3(one.style_last_price);

            setStyle_list(
              two.style_list.map((x) => {
                return {
                  ...x,
                  photoList: x.style_imgs,
                  mainphotoList: x.style_main_img,
                };
              })
            ); // 홈스타일링 상세정보 배열
          }
        })
        .catch((e) => {
          console.log("catch", e);
        })
        .finally(() => {
          setLoad(false);
        });
    } else if (step === 4) {
      setLoad(true);

      const sender = {
        admin_table_id: userData.admin_table_id,
        cont_table_id: cont_table_id,
      };

      APIS.postData("/AdminCont/ContReview", sender, 1)
        .then((res) => {
          if (res.data.code === "OK" && res.data.review_list.length > 0) {
            const one = res.data.review_list[0];

            setReview_photo("");
            setis_review_del(0);

            setReview_table_id(one.review_table_id); /* 고유키 */
            setReview_reg_date(one.reg_date); /* 등록일 */
            setStar(one.star); /* 고객 평점 */
            setReview_price(one.price); /* 가격 */
            setReview_service(one.service); /* 서비스 */
            setReview_quality(one.quality); /* 품질 */
            setReview_desc(one.desc); /* 총평 */
            setReview_style(one.homes); /* 홈스타일링 */
            setReview_img(one.img_url); /* 사진 */
          }
        })
        .catch((e) => {
          console.log("catch", e);
        })
        .finally(() => {
          setLoad(false);
        });
    } else {
      setLoad(false);
    }
  }, [step]);

  const stepChange = () => {
    if (step === 1) {
      dispatch(loadingStart());
      const sender = {
        admin_table_id: userData.admin_table_id,
        cont_table_id: cont_table_id,
        case_name: case_name,
        case_size: case_size,

        order_price: price /* 시공가격 */,
        cont_price: price1 /* 계약금 */,
        m_price: price2 /* 중도금 */,
        last_price: price3 /* 잔금 */,

        main_img: is_main_del === 1 && mainPhoto ? main_img : "",
        is_main_del: id ? is_main_del : 0,

        recp_img: is_recp_del === 1 && recpPhoto ? recp_img : "",
        is_recp_del: id ? is_recp_del : 0,

        contract_file: is_receipt_del === 1 && receipt ? receiptBase : "",
        warranty: asWarranty,
        work_type: range,

        base_desc: desc /* 비고 */,
        style_desc: reqDesc,

        case_work_type: info.join(",") /* 시공정보 */,
        case_work_parts: case_work_parts.join(",") /* 공간구성 */,

        case_work_area: case_work_area,
        case_work_area2: case_work_area2,

        work_start_date: moment(sdate).format("YYYY-MM-DD HH:mm:ss"),
        work_end_date: moment(edate).format("YYYY-MM-DD HH:mm:ss"),

        zone_parts: material_list,
        zone_delete_ids: material_del,
      };

      APIS.postData("/AdminCont/ContBaseSave", sender, 1)
        .then(() => {})
        .catch((e) => {
          console.log("catch", e);
        })
        .finally(() => {
          submitCloseFunc();
        });
    } else if (step === 2) {
      if (
        zone_list.filter((item) => !item.case_zone_img || !item.case_zone_img_2)
          .length > 0
      ) {
        dispatch(
          openSub({
            message: "시공전, 시공후 이미지를 업로드해주세요.",
          })
        );
        return;
      }

      dispatch(loadingStart());

      const sender = {
        admin_table_id: userData.admin_table_id,
        cont_table_id: cont_table_id,
        real_start_date: real_start_date,
        real_end_date: real_end_date,
        before_client_msg: before_client_msg,
        after_client_msg: after_client_msg,
        partner_msg: partner_msg,
        last_desc: last_desc,
        case_parts: zone_list.map((x, i) => {
          return {
            ...x,
            case_zone_img: x.photoList ? x.case_zone_img : "",
            case_zone_img_2: x.photoList_after ? x.case_zone_img_2 : "",
          };
        }),
        case_delete_ids: zone_del,
        cz_delete_ids: zone_del_img,
      };

      APIS.postData("/AdminCont/ContWorkSave", sender)
        .catch((e) => {
          console.log("catch", e);
        })
        .finally(() => {
          submitCloseFunc();
        });
    } else if (step === 3) {
      dispatch(loadingStart());
      const sender = {
        admin_table_id: userData.admin_table_id,
        cont_table_id: cont_table_id,

        style_buy_chk: style_check ? 1 : 0,
        style_price: style_price0,
        style_cont_price: style_price1,
        style_m_price: style_price2,
        style_last_price: style_price3,
        style_list: style_list,
        style_del: style_del,
        style_del_img: style_del_img,
        style_del_main_img: [],
      };

      APIS.postData("/AdminCont/ContStyleSave", sender, 1)
        .catch((e) => {
          console.log("catch", e);
        })
        .finally(() => {
          submitCloseFunc();
        });
    } else if (step === 4) {
      dispatch(loadingStart());
      const sender = {
        admin_table_id: userData.admin_table_id,
        cont_table_id: cont_table_id,
        review_table_id: review_table_id,

        price: review_price,
        service: review_service,
        quality: review_quality,
        homes: review_style,
        desc: review_desc,

        img_url: review_photo ? review_img : "",
        del_img: is_review_del ? 1 : 0,
      };

      APIS.postData("/AdminCont/ContReviewSave", sender)
        .catch((e) => {
          console.log("catch", e);
        })
        .finally(() => {
          submitCloseFunc();
        });
    } else {
      submitCloseFunc();
    }
  };

  const submitCloseFunc = () => {
    dispatch(loadingEnd());
    dispatch(
      openSub({
        message: "저장되었습니다.",
        button: "확인",
      })
    );
  };
  const closeFunc = () => {
    dispatch(close());
  };

  const zoneAppend = (type) => {
    if (type === 3) {
      setStyle_list([
        ...style_list,
        {
          zone_type: "1",
          type: "1",
          model_name: "",
          price: "",
          cnt: "",
          total_price: "",
          url: "",
          style_tags: "",
          style_tags_text: "",
          photoList: [],
          style_imgs: [],
          mainphotoList: [],
          style_main_img: [],
          style_main_check: "0",
        },
      ]);
    } else if (type === 2) {
      setMaterial_list([
        ...material_list,
        {
          case_price_table_id: "",
          case_parts_no: "1",
          case_price: "",
          case_desc: "",
        },
      ]);
    } else {
      setZone_list([
        ...zone_list,
        {
          case_img_table_id: "",
          case_parts_type: "1",
          case_tag: "",
          case_tag_text: "",
          case_desc: "",
          photoList: "",
          case_zone_img: "",
          photoList_after: "",
          case_zone_img_2: "",
          case_zone_table_id: "",
        },
      ]);
    }
  };

  const zoneDelete = (i, id, type) => {
    if (type === 3) {
      let newFormValues = [...style_list];
      newFormValues.splice(i, 1);
      setStyle_list(newFormValues);

      if (id) setStyle_del([...style_del, id]);
    } else if (type === 2) {
      let newFormValues = [...material_list];
      newFormValues.splice(i, 1);
      setMaterial_list(newFormValues);

      if (id) setMaterial_del([...material_del, id]);
    } else {
      let newFormValues = [...zone_list];
      newFormValues.splice(i, 1);
      setZone_list(newFormValues);

      if (id) setZone_del([...zone_del, id]);
    }
  };

  const allSetValue = (i, e, val, list) => {
    let newFormValues = [...list];

    newFormValues[i][e.target.name] = val;

    return newFormValues;
  };
  const allSetValueAppend = (i, e, val, list) => {
    let newFormValues = [...list];
    newFormValues[i][e.target.name] = [
      ...newFormValues[i][e.target.name],
      ...val,
    ];

    return newFormValues;
  };

  const allSetDeletePhoto = (i, e, val, list) => {
    let newFormValues = [...list];
    newFormValues[i][e.target.name] = val;

    return newFormValues;
  };

  const allSetValueMulti = (i, e, ee, val, val2, list) => {
    const newFormValues = list.map((item, index) => {
      if (index !== i) {
        return item;
      }
      return {
        ...item,
        [e.target.name]: val,
        [ee.target.name]: val2,
      };
    });

    return newFormValues;
  };

  const tagFunc = (
    value,
    setValue,
    setValueText,
    onChange,
    maxlength,
    index,
    name,
    textname
  ) => {
    dispatch(
      openSub({
        title: "태그 정보 선택",
        message: "최대 10개까지 태그 정보 선택 가능합니다.",
        component: (
          <Tags
            value={value}
            setValue={setValue}
            setValueText={setValueText}
            onChange={onChange}
            maxlength={maxlength}
            index={index}
            name={name}
            textname={textname}
          />
        ),
      })
    );
  };

  const tagFuncStyle = (
    value,
    setValue,
    setValueText,
    onChange,
    maxlength,
    index,
    name,
    textname
  ) => {
    dispatch(
      openSub({
        title: "태그 정보 선택",
        message: "최대 10개까지 태그 정보 선택 가능합니다.",
        component: (
          <Tags
            value={value}
            setValue={setValue}
            setValueText={setValueText}
            onChange2={onChange}
            maxlength={maxlength}
            index={index}
            name={name}
            textname={textname}
          />
        ),
      })
    );
  };

  const zoneSetValue = (i, e, val) => {
    setZone_list(allSetValue(i, e, val, zone_list));
  };

  const zoneDeletePhoto = (i, id) => {
    let newFormValues = [...zone_list];

    newFormValues[i].photoList = "";
    newFormValues[i].case_zone_img = "";
    newFormValues[i].photoList_after = "";
    newFormValues[i].case_zone_img_2 = "";

    setZone_list(newFormValues);
    if (id) setZone_del_img((deleteKey) => [...deleteKey, id]);
  };

  const materialSetValue = (i, e, val) => {
    setMaterial_list(allSetValue(i, e, val, material_list));
  };

  const styleSetValue = (i, e, val) => {
    setStyle_list(allSetValue(i, e, val, style_list));
  };
  const styleSetValueAppend = (i, e, val) => {
    setStyle_list(allSetValueAppend(i, e, val, style_list));
  };
  const styleDeletePhoto = (i, e, val) => {
    setStyle_list(allSetDeletePhoto(i, e, val, style_list));
  };
  const styleSetValueMulti = (i, e, ee, val, val2) => {
    setStyle_list(allSetValueMulti(i, e, ee, val, val2, style_list));
  };

  const topNavFunc = (num) => {
    setStep(num);
  };
  const onLink = () => {
    alert("준비중입니다.");
  };

  return (
    <div className="popup_list">
      <div className="pop_progress">
        <p className={step === 1 ? "active" : ""} onClick={() => topNavFunc(1)}>
          기본정보
        </p>
        <p className={step === 2 ? "active" : ""} onClick={() => topNavFunc(2)}>
          시공정보
        </p>
        <p className={step === 3 ? "active" : ""} onClick={() => topNavFunc(3)}>
          홈스타일
        </p>
        <p className={step === 4 ? "active" : ""} onClick={() => topNavFunc(4)}>
          고객후기
        </p>
      </div>
      <div className="popup_list_ul popup_instance_step popup_estimate_step">
        {step === 1 ? (
          <>
            <div className="instance_form_list estimate_form_list">
              <Input
                className="input_text"
                type="text"
                placeholder={"내용을 입력해주세요."}
                name="date1"
                value={moment(date1).format("YYYY.MM.DD HH:mm")}
                setValue={setDate1}
                label={"등록일시"}
                readOnly={true}
              />
              <Input
                className="input_text"
                type="text"
                placeholder={"내용을 입력해주세요."}
                name="date2"
                value={moment(date2).format("YYYY.MM.DD HH:mm")}
                setValue={setDate2}
                label={"최종일시"}
                readOnly={true}
              />

              <Input
                className="input_text"
                type="text"
                placeholder={"내용을 입력해주세요."}
                name="cont_table_id"
                value={order_table_id}
                setValue={setOrder_table_id}
                label={"요청번호"}
                readOnly={true}
                onLink={onLink}
                onLinkText={"자세히보기"}
              />
              <Input
                className="input_text"
                type="text"
                placeholder={"내용을 입력해주세요."}
                name="cont_num"
                value={cont_num}
                setValue={setCont_num}
                label={"계약번호"}
                readOnly={true}
              />

              <Input
                className="input_text"
                type="text"
                placeholder="내용을 입력해주세요."
                name="case_name"
                value={case_name}
                setValue={setCase_name}
                label="아파트명/시공사"
              />
              <Input
                className="input_text"
                type="text"
                placeholder="숫자만 입력해주세요."
                name="case_size"
                value={case_size}
                setValue={setCase_size}
                label="평형"
                maxlength={3}
                valid={"num"}
                withText={"평"}
              />

              <Input
                className="input_text"
                type="text"
                placeholder="숫자만 입력해주세요."
                name="price"
                value={price}
                setValue={setPrice}
                label="시공가격"
                valid={"num"}
                maxlength={5}
                withText={"만원"}
              />

              <Input
                className="input_text"
                type="text"
                placeholder="숫자만 입력해주세요."
                name="price1"
                value={price1}
                setValue={setPrice1}
                label="계약금"
                valid={"num"}
                maxlength={5}
                withText={"만원"}
              />
              <Input
                className="input_text"
                type="text"
                placeholder="숫자만 입력해주세요."
                name="price2"
                value={price2}
                setValue={setPrice2}
                label="중도금"
                valid={"num"}
                maxlength={5}
                withText={"만원"}
              />
              <Input
                className="input_text"
                type="text"
                placeholder="숫자만 입력해주세요."
                name="price3"
                value={price3}
                setValue={setPrice3}
                label="잔금"
                valid={"num"}
                maxlength={5}
                withText={"만원"}
              />

              <InputFile
                type="file"
                placeholder="파일을 업로드해주세요."
                name="recpPhoto"
                label="도면"
                valid={"image"}
                value={recpPhoto}
                setValue={setRecpPhoto}
                imgvalue={recp_img}
                setImgValue={setRecp_img}
                deleteFunc={() => setis_recp_del(1)}
              />
              <InputFile
                type="file"
                placeholder="파일을 업로드해주세요."
                name="mainPhoto"
                label="메인사진"
                valid={"image"}
                value={mainPhoto}
                setValue={setmainPhoto}
                imgvalue={main_img}
                setImgValue={setmain_img}
                deleteFunc={() => setis_main_del(1)}
              />

              <InputFile
                type="file"
                placeholder="파일을 업로드해주세요."
                name="receipt"
                label="계약서"
                valid={"doc"}
                value={receipt}
                setValue={setReceipt}
                imgvalue={receiptBase}
                setImgValue={setReceiptBase}
                deleteFunc={() => setis_receipt_del(1)}
              />

              <div className="input_date_section_multi_half">
                <InputSelect
                  name="as_warranty"
                  label="A/S 보증"
                  value={asWarranty}
                  setValue={setAsWarranty}
                  option={consts.asoption}
                />
                <div
                  className="checkbox_section1"
                  style={{ width: "calc(50% - 12px)" }}
                >
                  <p className="input_label">시공범위</p>
                  <div
                    className="radio_box"
                    style={{ width: "100%", marginTop: "4px" }}
                  >
                    <input
                      type="radio"
                      id="rd1"
                      name="range"
                      className="radio2"
                      value="1"
                      checked={range === "1"}
                      onChange={(e) => setRange(e.target.value)}
                    />
                    <label htmlFor="rd1">전체</label>
                    <input
                      type="radio"
                      id="rd2"
                      name="range"
                      className="radio2"
                      value="2"
                      checked={range === "2"}
                      onChange={(e) => setRange(e.target.value)}
                    />
                    <label htmlFor="rd2">부분</label>
                  </div>
                </div>
              </div>
              {!load && (
                <InputSelectArea
                  name="work"
                  label="시공지역"
                  value={case_work_area}
                  setValue={setcase_work_area}
                  area={case_work_area2}
                  setArea={setcase_work_area2}
                />
              )}

              <div className="input_date_section_multi_half">
                <InputDate
                  type="text"
                  placeholder="날짜선택"
                  name="sdate"
                  label="시공시작 예정일"
                  dateValue={sdate}
                  setDateValue={setSdate}
                />
                <InputDate
                  type="text"
                  placeholder="날짜선택"
                  name="sdate"
                  label="시공종료 예정일"
                  dateValue={edate}
                  setDateValue={setEdate}
                />
              </div>

              <div className="input_check_box">
                <p className="input_label">시공정보</p>
                <div>
                  {!load &&
                    consts.tag_id.map((x, i) => {
                      return (
                        <Fragment key={i}>
                          <input
                            type="checkbox"
                            id={"chk" + i}
                            className="checkbox2 info"
                            checked={info.includes(x.title)}
                            data-text={x.title}
                            onChange={() => {
                              if (info.includes(x.title)) {
                                setInfo(
                                  info.filter((item) => item !== x.title)
                                );
                              } else {
                                setInfo([...info, x.title]);
                              }
                            }}
                          />
                          <label htmlFor={"chk" + i}>{x.title}</label>
                        </Fragment>
                      );
                    })}
                </div>
              </div>

              <div className="input_check_box">
                <p className="input_label">공간구성</p>
                <div>
                  {!load &&
                    consts.partsoption.map((x, i) => {
                      return (
                        <Fragment key={i}>
                          <input
                            type="checkbox"
                            id={"chk2_" + i}
                            className="checkbox2 parts"
                            checked={case_work_parts.includes(x.title)}
                            data-text={x.title}
                            onChange={() => {
                              if (case_work_parts.includes(x.title)) {
                                setcase_work_parts(
                                  case_work_parts.filter(
                                    (item) => item !== x.title
                                  )
                                );
                              } else {
                                setcase_work_parts([
                                  ...case_work_parts,
                                  x.title,
                                ]);
                              }
                            }}
                          />
                          <label htmlFor={"chk2_" + i}>{x.title}</label>
                        </Fragment>
                      );
                    })}
                </div>
              </div>

              <TextArea
                className="input_textarea"
                placeholder=""
                name="reqDesc"
                label="요청사항"
                maxlength={1000}
                value={reqDesc}
                setValue={setReqDesc}
                setError={null}
              />
              <TextArea
                className="input_textarea"
                placeholder=""
                name="desc"
                label="비고"
                maxlength={1000}
                value={desc}
                setValue={setDesc}
                setError={null}
              />
            </div>

            <div className="theme_multi_info_box">
              <p className="title">시공별 상세정보</p>
              {material_list &&
                material_list.map((x, i) => {
                  return (
                    <div className="casezone_box" key={i}>
                      <button
                        type="button"
                        className="trash_btn_red"
                        onClick={() =>
                          zoneDelete(
                            i,
                            x.case_price_table_id ? x.case_price_table_id : "",
                            2
                          )
                        }
                      />
                      <div className="one_line half_three">
                        <InputSelect
                          name="case_parts_no"
                          label="시공"
                          value={x.case_parts_no}
                          onChange={materialSetValue}
                          index={i}
                          option={consts.buildsoption}
                        />
                        <Input
                          className="input_text"
                          type="text"
                          placeholder="숫자만 입력해주세요."
                          name="case_price"
                          value={x.case_price}
                          onChange={materialSetValue}
                          index={i}
                          label="가격"
                          valid={"num"}
                          maxlength={5}
                          withText={"만원"}
                        />
                      </div>

                      <div className="one_line full">
                        <Input
                          className="input_text"
                          type="text"
                          placeholder="내용을 입력해 주세요."
                          name="case_desc"
                          value={x.case_desc}
                          onChange={materialSetValue}
                          index={i}
                          label="시공설명"
                          full={true}
                        />
                      </div>
                    </div>
                  );
                })}
              <button
                type="button"
                className="case_add_btn"
                onClick={() => zoneAppend(2)}
              >
                <img
                  src={images.plus}
                  alt="자재 추가하기"
                  className="plus_img"
                />
                추가하기
              </button>
            </div>
          </>
        ) : step === 2 ? (
          <>
            <div className="instance_form_list estimate_form_list">
              <Input
                className="input_text"
                type="text"
                placeholder={"내용을 입력해주세요."}
                name="date1"
                value={moment(reg_date).format("YYYY.MM.DD HH:mm")}
                setValue={setReg_date}
                label={"등록일시"}
                readOnly={true}
              />
              <Input
                className="input_text"
                type="text"
                placeholder={"내용을 입력해주세요."}
                name="date2"
                value={moment(update_date).format("YYYY.MM.DD HH:mm")}
                setValue={setupdate_date}
                label={"최종일시"}
                readOnly={true}
              />

              <InputDate
                type="text"
                placeholder="날짜선택"
                name="real_start_date"
                label="시공시작일"
                dateValue={real_start_date}
                setDateValue={setReal_start_date}
              />
              <InputDate
                type="text"
                placeholder="날짜선택"
                name="real_end_date"
                label="시공종료일"
                dateValue={real_end_date}
                setDateValue={setReal_end_date}
              />

              <Input
                className="input_text"
                type="text"
                placeholder={"내용을 입력해주세요."}
                name="before_client_msg"
                value={before_client_msg}
                setValue={setBefore_client_msg}
                label={"시공전 고객요청사항"}
                full={true}
              />
              <Input
                className="input_text"
                type="text"
                placeholder={"내용을 입력해주세요."}
                name="after_client_msg"
                value={after_client_msg}
                setValue={setAfter_client_msg}
                label={"시공후 고객요청사항"}
                full={true}
              />
              <Input
                className="input_text"
                type="text"
                placeholder={"내용을 입력해주세요."}
                name="partner_msg"
                value={partner_msg}
                setValue={setPartner_msg}
                label={"파트너요청사항"}
                full={true}
              />

              <Input
                className="input_text"
                type="text"
                placeholder={"내용을 입력해주세요."}
                name="last_desc"
                value={last_desc}
                setValue={setLast_desc}
                label={"설명"}
                full={true}
              />
            </div>
            <div className="theme_multi_info_box">
              <p className="title">공간별 상세 정보</p>
              {zone_list &&
                zone_list.map((x, i) => {
                  return (
                    <div className="casezone_box" key={i}>
                      <button
                        type="button"
                        className="trash_btn_red"
                        onClick={() =>
                          zoneDelete(
                            i,
                            x.case_img_table_id ? x.case_img_table_id : ""
                          )
                        }
                      />
                      <div className="one_line small_select_two">
                        <InputSelect
                          name="case_parts_type"
                          label="공간"
                          value={x.case_parts_type}
                          onChange={zoneSetValue}
                          index={i}
                          option={consts.partsoption}
                        />
                        <Input
                          className="input_text"
                          type="text"
                          placeholder="설명을 입력해 주세요."
                          name="case_desc"
                          value={x.case_desc}
                          onChange={zoneSetValue}
                          index={i}
                          label="설명"
                        />
                      </div>
                      <div className="one_line full">
                        <Input
                          className="input_text"
                          type="text"
                          placeholder="관리버튼을 눌러 태그를 선택하세요."
                          name="case_tag"
                          value={x.case_tag_text}
                          onChange={zoneSetValue}
                          label="태그정보 (최대10개)"
                          withButton={"관리"}
                          withButtonPress={() =>
                            tagFunc(
                              x.case_tag,
                              zoneSetValue,
                              "",
                              zoneSetValue,
                              10,
                              i,
                              "case_tag",
                              "case_tag_text"
                            )
                          }
                          maxlength={5000}
                          full={true}
                        />
                      </div>
                      <div className="one_line half_two">
                        <InputFileArr
                          type="file"
                          placeholder="파일을 업로드해주세요."
                          index={i}
                          name="photoList"
                          imgName="case_zone_img"
                          label="시공전"
                          valid={"image"}
                          value={x.photoList}
                          imgvalue={x.case_zone_img}
                          onChange={zoneSetValue}
                          deleteFunc2={() =>
                            zoneDeletePhoto(i, x.case_zone_table_id)
                          }
                        />
                        <InputFileArr
                          type="file"
                          placeholder="파일을 업로드해주세요."
                          index={i}
                          name="photoList_after"
                          imgName="case_zone_img_2"
                          label="시공후"
                          valid={"image"}
                          value={x.photoList_after}
                          imgvalue={x.case_zone_img_2}
                          onChange={zoneSetValue}
                          deleteFunc2={() =>
                            zoneDeletePhoto(i, x.case_zone_table_id)
                          }
                        />
                      </div>
                    </div>
                  );
                })}
              <button
                type="button"
                className="case_add_btn"
                onClick={() => zoneAppend()}
              >
                <img
                  src={images.plus}
                  alt="공간 추가하기"
                  className="plus_img"
                />
                공간 추가하기
              </button>
            </div>
          </>
        ) : step === 3 ? (
          <>
            <div className="instance_form_list estimate_form_list">
              <div className="checkbox_section2">
                <label className="input_label">홈스타일링</label>
                <div>
                  <input
                    type="checkbox"
                    id={"chkfix"}
                    className="checkbox"
                    name="style_check"
                    checked={style_check}
                    onChange={(e) => setStyle_check(e.target.checked)}
                  />
                  <label htmlFor={"chkfix"}>있음</label>
                </div>
              </div>

              <Input
                className="input_text"
                type="text"
                placeholder="숫자만 입력해주세요."
                name="style_price0"
                value={style_price0}
                setValue={setStyle_price0}
                label="가격"
                valid={"num"}
                maxlength={5}
                withText={"만원"}
                readOnly={!style_check}
              />
              <Input
                className="input_text"
                type="text"
                placeholder="숫자만 입력해주세요."
                name="style_price1"
                value={style_price1}
                setValue={setStyle_price1}
                label="계약금"
                valid={"num"}
                maxlength={5}
                withText={"만원"}
                readOnly={!style_check}
              />
              <Input
                className="input_text"
                type="text"
                placeholder="숫자만 입력해주세요."
                name="style_price2"
                value={style_price2}
                setValue={setStyle_price2}
                label="중도금"
                valid={"num"}
                maxlength={5}
                withText={"만원"}
                readOnly={!style_check}
              />
              <Input
                className="input_text"
                type="text"
                placeholder="숫자만 입력해주세요."
                name="style_price3"
                value={style_price3}
                setValue={setStyle_price3}
                label="잔금"
                valid={"num"}
                maxlength={5}
                withText={"만원"}
                readOnly={!style_check}
              />
            </div>
            <div className="theme_multi_info_box">
              <p className="title">홈스타일링 정보</p>
              {!load &&
                style_list.map((x, i) => {
                  return (
                    <div className="casezone_box" key={i}>
                      <button
                        type="button"
                        className="trash_btn_red"
                        onClick={() =>
                          zoneDelete(i, x.zs_table_id ? x.zs_table_id : "", 3)
                        }
                      />
                      <div className="one_line half_three">
                        <InputSelect
                          name="zone_type"
                          label="공간"
                          value={x.zone_type}
                          onChange={styleSetValue}
                          index={i}
                          option={consts.partsoption}
                        />
                        <InputSelect
                          name="type"
                          label="종류"
                          value={x.type}
                          onChange={styleSetValue}
                          index={i}
                          option={consts.themeEqCode}
                        />
                      </div>

                      <div className="one_line half_three">
                        <Input
                          className="input_text"
                          type="text"
                          placeholder="내용을 입력해 주세요."
                          name="model_name"
                          value={x.model_name}
                          onChange={styleSetValue}
                          index={i}
                          label="제품명"
                        />
                        <Input
                          className="input_text"
                          type="text"
                          placeholder="내용을 입력해 주세요."
                          name="price"
                          value={x.price}
                          onChange={styleSetValue}
                          index={i}
                          label="단가"
                          valid={"num"}
                          maxlength={5}
                          withText={"만원"}
                        />
                        <Input
                          className="input_text"
                          type="text"
                          placeholder="숫자만 입력해주세요."
                          name="cnt"
                          value={x.cnt}
                          onChange={styleSetValue}
                          index={i}
                          label="수량"
                          valid={"num"}
                          maxlength={5}
                        />
                      </div>

                      <div className="one_line full">
                        <Input
                          className="input_text"
                          type="text"
                          placeholder="URL주소를 입력해 주세요."
                          name="url"
                          value={x.url}
                          onChange={styleSetValue}
                          index={i}
                          label="URL"
                        />
                      </div>
                      <div className="one_line full">
                        <Input
                          className="input_text"
                          type="text"
                          placeholder="관리버튼을 눌러 태그를 선택하세요."
                          name="style_tags"
                          value={x.style_tag_text}
                          onChange={styleSetValue}
                          label="태그정보 (최대10개)"
                          withButton={"관리"}
                          withButtonPress={() =>
                            tagFuncStyle(
                              x.style_tags,
                              styleSetValueMulti,
                              "",
                              styleSetValueMulti,
                              10,
                              i,
                              "style_tags",
                              "style_tag_text"
                            )
                          }
                          maxlength={5000}
                          full={true}
                        />
                      </div>

                      <div className="one_line">
                        <InputFileMulti2
                          type="file"
                          multiple={true}
                          placeholder="입력해주세요."
                          name="photoList"
                          imgName="style_imgs"
                          label="*상세사진 (10개까지 등록 가능)"
                          value={x.photoList}
                          imgValue={x.style_imgs}
                          onChange={styleSetValueAppend}
                          onDelete={styleDeletePhoto}
                          onDeleteAddKey={"si_table_id"}
                          onDeleteAddFunc={setStyle_del_img}
                          index={i}
                          valid="image"
                          full={true}
                        />
                      </div>
                    </div>
                  );
                })}
              <button
                type="button"
                className="case_add_btn"
                onClick={() => zoneAppend(3)}
              >
                <img
                  src={images.plus}
                  alt="홈스타일링 추가하기"
                  className="plus_img"
                />
                홈스타일링 추가하기
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="instance_form_list estimate_form_list">
              <Input
                className="input_text"
                type="text"
                placeholder={""}
                name="review_reg_date"
                value={review_reg_date}
                setValue={setReview_reg_date}
                label={"등록일시"}
                readOnly={true}
              />
              <Input
                className="input_text"
                type="text"
                placeholder={""}
                name="review_num"
                value={star}
                setValue={setStar}
                label={"고객평점"}
                readOnly={true}
              />

              <InputSelect
                name="review_price"
                label="가격"
                value={review_price}
                setValue={setReview_price}
                option={consts.reviewCode}
              />
              <InputSelect
                name="review_service"
                label="서비스"
                value={review_service}
                setValue={setReview_service}
                option={consts.reviewCode}
              />
              <InputSelect
                name="review_quality"
                label="품질"
                value={review_quality}
                setValue={setReview_quality}
                option={consts.reviewCode}
              />
              <InputSelect
                name="review_style"
                label="홈스타일링"
                value={review_style}
                setValue={setReview_style}
                option={consts.reviewCode}
                readOnly={!style_check}
              />

              <TextArea
                className="input_textarea"
                placeholder=""
                name="review_desc"
                label="총평"
                maxlength={1000}
                value={review_desc}
                setValue={setReview_desc}
                setError={null}
              />

              <InputFile
                type="file"
                placeholder="사진을 첨부해 주세요."
                name="review_photo"
                label="사진"
                value={review_photo}
                setValue={setReview_photo}
                imgvalue={review_img}
                setImgValue={setReview_img}
                valid="image"
                deleteFunc={() => setis_review_del(1)}
              />
            </div>
          </>
        )}
      </div>

      <div className="popup_list_btn_box popup_pay_step">
        <button type="button" className="btn btn3" onClick={() => closeFunc()}>
          {"취소"}
        </button>
        <button type="button" className="btn btn1" onClick={() => stepChange()}>
          {"확인"}
        </button>
      </div>
    </div>
  );
}
