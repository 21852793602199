import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Routes, Route, useLocation } from "react-router-dom";
import routes from "./libs/routes";

import Main from "./pages/main/Main";
import My from "./pages/my/My";
import Membership from "./pages/my/Membership";
import Acc from "./pages/my/Acc";
import Instance from "./pages/instance/Instance";
import Schedule from "./pages/day/Schedule";
import PayManage from "./pages/pay/PayManage";

import Login from "./pages/login/Login";

/* 관리자 페이지 */
import Member from "./pages/admin/Member";

import Partner from "./pages/admin/Partner";
import PartnerDetail from "./pages/admin/PartnerDetail";

import Estimate from "./pages/estimate/Estimate";
import User from "./pages/user/User";
import Theme from "./pages/admin/Theme";
import AdmEstimate from "./pages/admin/Estimate";
import EstimateDetail from "./pages/admin/EstimateDetail";
import Setting from "./pages/admin/Setting";
import Order from "./pages/admin/Order";

import AdmPay from "./pages/admin/Pay";
import Real from "./pages/admin/Real";
import As from "./pages/admin/As";

import NoticePartnerPage from "./pages/admin/Notice/Partner";
import NoticeUserPage from "./pages/admin/Notice/User";
import NoticeEventPage from "./pages/admin/Notice/Event";
import NoticeAdPage from "./pages/admin/Notice/Ad";
import NoticeUrlPage from "./pages/admin/Notice/Url";

import Logs from "./pages/admin/logs/Logs";

import HomeManageHeroPage from "./pages/admin/HomeMange/Hero";
import HomeManagePickPage from "./pages/admin/HomeMange/Pick";
import HomeManageRecommendPage from "./pages/admin/HomeMange/Recommend";
import HomeManageMonthlyPage from "./pages/admin/HomeMange/Monthly";
import HomeManageLineBannerPage from "./pages/admin/HomeMange/LineBanner";
import HomeManageCenterBannerPage from "./pages/admin/HomeMange/CenterBanner";
import HomeManagePopupPage from "./pages/admin/HomeMange/Popup";
import HomeManageLifeMonthlyPage from "./pages/admin/HomeMange/LifeMonthly";

// 이벤트 페이지
import EventHomePage from "./pages/admin/Event/Home";
import EventAptnPage from "./pages/admin/Event/Aptn";
import EventHanssemPage from "./pages/admin/Event/Hanssem";
import EventAptnCounselPage from "./pages/admin/Event/AptnCounsel";
import EventDbPage from "./pages/admin/Event/Db";

// 생활시공 페이지
import LifeConstructionPage from "./pages/admin/LifeConstruction";

// 안심번호 관리 페이지
import SafeNumbersPage from "./pages/admin/SafeNumbers";

function Layout() {
  const location = useLocation();
  const userData = useSelector((s) => s.usersReducer);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Routes>
      <Route exact path={routes.login} element={<Login />} />
      {/* 파트너 */}
      <Route exact path={routes.my} element={<My />} />
      <Route exact path={routes.membership} element={<Membership />} />
      <Route exact path={routes.acc} element={<Acc />} />
      <Route exact path={routes.schedule} element={<Schedule />} />
      <Route exact path={routes.payManage} element={<PayManage />} />
      <Route exact path={routes.estimate} element={<Estimate />} />
      <Route exact path={routes.user} element={<User />} />
      {/* 관리자 */}
      <Route exact path={routes.member} element={<Member />} />
      <Route exact path={routes.partner} element={<Partner />} />
      <Route exact path={routes.partnerDetail} element={<PartnerDetail />} />
      <Route
        exact
        path={routes.life_construction}
        element={<LifeConstructionPage />}
      />
      <Route exact path={routes.safe_numbers} element={<SafeNumbersPage />} />
      <Route exact path={routes.theme} element={<Theme />} />
      <Route exact path={routes.admEstimate} element={<AdmEstimate />} />
      <Route exact path={routes.estimateDetail} element={<EstimateDetail />} />
      <Route exact path={routes.setting} element={<Setting />} />
      <Route exact path={routes.order} element={<Order />} />
      <Route exact path={routes.admPay} element={<AdmPay />} />
      <Route exact path={routes.real} element={<Real />} />
      <Route exact path={routes.as} element={<As />} />
      <Route
        exact
        path={routes.notice_partner}
        element={<NoticePartnerPage />}
      />
      <Route exact path={routes.notice_user} element={<NoticeUserPage />} />
      <Route exact path={routes.notice_event} element={<NoticeEventPage />} />
      <Route exact path={routes.notice_ad} element={<NoticeAdPage />} />
      <Route exact path={routes.notice_url} element={<NoticeUrlPage />} />
      <Route exact path={routes.logs} element={<Logs />} />

      <Route
        exact
        path={routes.home_manage_hero}
        element={<HomeManageHeroPage />}
      />
      <Route
        exact
        path={routes.home_manage_pick}
        element={<HomeManagePickPage />}
      />
      <Route
        exact
        path={routes.home_manage_recommend}
        element={<HomeManageRecommendPage />}
      />
      <Route
        exact
        path={routes.home_manage_monthly}
        element={<HomeManageMonthlyPage />}
      />
      <Route
        exact
        path={routes.home_manage_line_banner}
        element={<HomeManageLineBannerPage />}
      />
      <Route
        exact
        path={routes.home_manage_center_banner}
        element={<HomeManageCenterBannerPage />}
      />
      <Route
        exact
        path={routes.home_manage_popup}
        element={<HomeManagePopupPage />}
      />
      <Route
        exact
        path={routes.home_manage_life_monthly}
        element={<HomeManageLifeMonthlyPage />}
      />

      <Route exact path={routes.event_home} element={<EventHomePage />} />
      <Route exact path={routes.event_aptn} element={<EventAptnPage />} />
      <Route exact path={routes.event_hanssem} element={<EventHanssemPage />} />
      <Route
        exact
        path={routes.event_aptn_counsel}
        element={<EventAptnCounselPage />}
      />
      <Route exact path={routes.event_db} element={<EventDbPage />} />

      <Route exact path={routes.instance} element={<Instance />} />
      <Route
        exact
        path={routes.main}
        element={!userData.grade ? <Main /> : <Partner />}
      />
      <Route
        exact
        path={"*"}
        element={!userData.grade ? <Main /> : <Partner />}
      />
    </Routes>
  );
}

export default Layout;
