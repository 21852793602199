import { useState } from "react";

const useInstance = () => {
  const [isReceiptOpen, setIsReceiptOpen] = useState(false);

  const openReceiptModal = () => {
    setIsReceiptOpen(true);
  };

  const closeReceiptModal = () => {
    setIsReceiptOpen(false);
  };

  return {
    isReceiptOpen,
    openReceiptModal,
    closeReceiptModal,
  };
};

export default useInstance;
