import {combineReducers} from 'redux';
import usersReducer from './usersSlice';
import popupReducer from './popupSlice';
import subPopupReducer from './subPopupSlice';
import joinReducer from './joinSlice';
import searchReducer from './searchSlice';
import loadingReducer from './loadingSlice';
import imgReducer from './imgSlice';

export default combineReducers({
  usersReducer,
  popupReducer,
  subPopupReducer,
  joinReducer,
  searchReducer,
  loadingReducer,
  imgReducer
});
