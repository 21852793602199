import React from "react";

import moment from "moment";

import { findJson } from "../../utils/utils";

import consts from "../../libs/consts";

export default function History(props) {
  const { rows, id } = props;

  return (
    <form className="popup_form history_pop">
      <p className="messageEffect">
        요청번호 <span>{id}</span>
      </p>
      <table className="tableStyle1">
        <thead>
          <tr>
            <th className="wd80">진행</th>
            <th className="wd110">회신</th>
            <th className="wdFull">일시</th>
          </tr>
        </thead>
      </table>
      <div className="table_body">
        <table className="tableStyle1">
          <tbody>
            {rows.length > 0 ? (
              rows.map((x, i) => {
                return (
                  <tr key={i}>
                    <td className="wd80">
                      {findJson(consts.sellerOrderState, x.order_state)}
                    </td>
                    {/*<td className="wd110">{x.prog_result}</td>*/}
                    <td className="wd110">
                      {x.order_resp_state === "-"
                        ? x.order_resp_state
                        : findJson(
                            consts.sellerOrderRespState,
                            x.order_resp_state
                          )}
                    </td>
                    <td className="wdFull">
                      {moment(x.reg_date).format("YYYY/MM/DD HH:mm")}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="null_td" colSpan={3}>
                  데이터가 없습니다.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </form>
  );
}
