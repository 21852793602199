import React from "react";
import moment from "moment";
import { findJson } from "../../../utils/utils";
import consts from "../../../libs/consts";
import Table from "../../../components/Common/Table/Table";
import { css } from "@emotion/react";

export default function LogsOrderManage({ list }) {
  const col = [
    { key: "resp_table_id", title: "로그번호" },
    { key: "order_table_id", title: "요청번호" },
    {
      key: "sellerOrderState",
      title: "진행상태",
      render: (x) => (
        <span>{findJson(consts.sellerOrderState, x.order_state)}</span>
      ),
    },
    {
      key: "reg_date",
      title: "로그일시",
      render: (x) => <span>{moment(x.reg_date).format("YYYY.MM.DD")}</span>,
    },
  ];

  return (
    <div
      css={css`
        margin: 30px 0 20px 0;
      `}
    >
      <Table columns={col} datas={list} />
    </div>
  );
}
