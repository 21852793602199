import React from "react";
import { css } from "@emotion/react";
import * as Colors from "../../constants/color";
import images from "../../libs/images";

const Preview = () => {
  return (
    <div
      css={css`
        position: absolute;
        z-index: 1000;
        background-color: ${Colors.white};
        left: -100px;
        padding: 20px;
        border: 1px solid ${Colors.gray_200};
        width: 280px;
      `}
    >
      <div>
        <p>[업체 소개 문장]</p>
        <p
          css={css`
            margin-top: 5px;
          `}
        >
          시공 전문가 페이지에
        </p>
        <p
          css={css`
            margin-top: 5px;
          `}
        >
          메인 사진으로 노출 됩니다.
        </p>
      </div>
      <div
        css={css`
          margin-top: 40px;
        `}
      >
        <h3
          css={css`
            font-size: 18px;
            border-bottom: 2px solid ${Colors.secondary};
            padding-bottom: 5px;
            margin: 0 20px;
          `}
        >
          2,000만원 이하 전문인
        </h3>
        <p
          css={css`
            margin: 5px 0 0 32px;
            text-align: left;
          `}
        >
          테스트인테리어
        </p>
        <img
          src={images.partner_introduce_img}
          alt="partner_introduce_img"
          css={css`
            margin-top: 30px;
            width: 100%;
            height: 160px;
          `}
        />
      </div>
      <div
        css={css`
          margin-top: 10px;
          text-align: left;
        `}
      >
        <p
          css={css`
            font-size: 13px;
            font-weight: 600;
          `}
        >
          세 식구의 도심 속 작은 숲
        </p>
        <p>신도림 가평 푸르지오 아파트</p>
        <p
          css={css`
            margin-top: 15px;
            font-size: 12px;
            font-weight: 500;
          `}
        >
          32평 3,000만원
        </p>
      </div>
    </div>
  );
};

export default Preview;
