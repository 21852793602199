import React, { useState } from "react";

import {
  patternEngUpper,
  patternSpcId,
  patternSpcPw,
  patternKor,
} from "../utils/utils";

export default function TextArea(props) {
  const {
    className,
    placeholder,
    name,
    maxlength,
    value,
    setValue,
    valid,
    label,
    error,
    setError = () => console.log(""),
    success,
    readOnly,
    onBlur,
    onChange,
    index,
    withButton,
    withButtonPress,
    withText,
    msg,
  } = props;

  const [f, setF] = useState(false);

  const handleChange = (e) => {
    if (valid === "num") {
      const num = e.target.value.replace(/\D/g, "");
      if (onChange) {
        onChange(index, e, num);
      } else {
        setValue(num);
      }
    } else if (valid === "id") {
      let val = e.target.value.replace(patternKor, ""); // 한글제거
      val = val.replace(patternEngUpper, ""); // 대문자제거
      val = val.replace(patternSpcId, ""); // 특수문자제거
      if (onChange) {
        onChange(index, e, val);
      } else {
        setValue(val);
      }
    } else if (valid === "pw") {
      let val = e.target.value.replace(patternKor, ""); // 한글제거
      val = val.replace(patternSpcPw, ""); // 특수문자제거
      val = val.toLowerCase(); // 대문자 소문자로 변환
      if (onChange) {
        onChange(index, e, val);
      } else {
        setValue(val);
      }
    } else {
      if (onChange) {
        onChange(index, e, e.target.value);
      } else {
        setValue(e.target.value);
      }
    }
  };

  const handleBlur = () => {
    setF(false);
  };
  const handleFocus = () => {
    setError("");
    setF(true);
  };

  const handleClick = () => {
    if (withButton === "주소찾기") {
    } else if (withButton === "지역선택") {
      withButtonPress();
    }
  };

  return (
    <>
      <div className="input_box textarea_box">
        <label className="input_label" htmlFor={name}>
          {label}
        </label>

        <div className="input_section input_section_textarea">
          <textarea
            name={name}
            id={name}
            className={
              className +
              (readOnly ? " disable" : "") +
              (withButton ? " withButton" : "") +
              (withText ? " withText" : "")
            }
            placeholder={placeholder}
            onChange={handleChange}
            onFocus={handleFocus}
            onClick={handleClick}
            onBlur={() => {
              handleBlur();
              onBlur && onBlur();
            }}
            maxLength={maxlength ? maxlength : "500"}
            readOnly={
              withButton === "주소찾기" || withButton === "지역선택"
                ? true
                : readOnly
            }
            value={value}
          ></textarea>
        </div>

        {error && !f && <p className="input_error">{error}</p>}
        {success && !f && <p className="input_success">{success}</p>}
        {msg && <p className="input_msg">{msg}</p>}
        <p className="input_count">
          <span>{value ? value.length : 0}</span>/
          {maxlength ? maxlength : "500"}
        </p>
      </div>
    </>
  );
}
