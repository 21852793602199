const homeUrl = window.location.protocol + "//" + window.location.host;

const frontReport = "report/";
const frontTheme = "theme/";
const frontPartner = "report-partner/";
const frontEvent = "event/";

/* 개발 전용 라이브 빌드시 주석 */
// BaseURLAdmin = "https://dev_api.layhome.net:88";
// BaseURLPartner = "https://dev_api.layhome.net:88";
// BaseURLFront ="https://dev_api.layhome.net:88";

let varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
if (
  varUA.indexOf("iphone") > -1 ||
  varUA.indexOf("ipad") > -1 ||
  varUA.indexOf("ipod") > -1
) {
  //IOS
  varUA = "ios";
} else {
  varUA = "and";
}

const exportData = {
  apiBaseUrl: process.env.REACT_APP_BASEURL,
  apiBaseUrlAdmin: process.env.REACT_APP_BASEURL_ADMIN,
  apiBaseUrlPartner: process.env.REACT_APP_BASEURL_PARTNER,
  apiBaseUrlFront: process.env.REACT_APP_BASEURL_FRONT,
  apiPGURL: process.env.REACT_APP_PGURL,
  frontUrl: process.env.REACT_APP_BASEURL,
  frontReport: frontReport,
  frontTheme: frontTheme,
  frontPartner: frontPartner,
  frontEvent: frontEvent,
  homeUrl: homeUrl,
  platform: varUA,
  kakaoKey: "431a075aaa57e6866c44fd75f134e3fc",
  pagerows: 15,
  stateoption: [
    { idx: "1", title: "정상" },
    { idx: "2", title: "대기" },
    { idx: "3", title: "중지" },
  ],
  workoption: [
    { idx: 1, title: "아파트" },
    { idx: 2, title: "빌라" },
    { idx: 3, title: "주택" },
    { idx: 4, title: "카페/식당" },
    { idx: 5, title: "상가/매장" },
    { idx: 6, title: "학원/교육" },
    { idx: 7, title: "사무실" },
    { idx: 8, title: "숙박/병원" },
    { idx: 9, title: "기타" },
  ],
  partsoption: [
    { idx: "0", title: "전체" },
    { idx: "1", title: "거실" },
    { idx: "2", title: "주방" },
    { idx: "3", title: "안방" },
    { idx: "4", title: "방1" },
    { idx: "5", title: "방2" },
    { idx: "6", title: "방3" },
    { idx: "7", title: "방4" },
    { idx: "8", title: "현관" },
    { idx: "9", title: "욕실1" },
    { idx: "10", title: "욕실2" },
    { idx: "11", title: "욕실3" },
    { idx: "12", title: "펜트리룸" },
    { idx: "13", title: "베란다" },
    { idx: "14", title: "다용도실" },
    { idx: "15", title: "기타공간" },
  ],
  buildsoption: [
    { idx: 1, title: "도배" },
    { idx: 2, title: "장판" },
    { idx: 3, title: "마루" },
    { idx: 4, title: "타일" },
    { idx: 5, title: "탄성코드" },
    { idx: 6, title: "주방" },
    { idx: 7, title: "욕실" },
    { idx: 8, title: "문" },
    { idx: 9, title: "붙박이장" },
    { idx: 10, title: "중문" },
    { idx: 11, title: "현관장" },
    { idx: 12, title: "몰딩" },
    { idx: 13, title: "필름" },
    { idx: 14, title: "조명" },
    { idx: 15, title: "목공" },
    { idx: 16, title: "전기" },
    { idx: 17, title: "창호" },
    { idx: 18, title: "철거" },
    { idx: 19, title: "설비/미장" },
    { idx: 20, title: "확장" },
    { idx: 21, title: "걸레받이" },
    { idx: 22, title: "공과잡비" },
    { idx: 23, title: "기타" },
    { idx: 24, title: "가구" },
    { idx: 25, title: "기초공사" },
  ],
  tagoption: [
    { idx: "1", title: "모던" },
    { idx: "2", title: "심플" },
    { idx: "3", title: "미니멀" },
    { idx: "4", title: "화이트" },
    { idx: "5", title: "그레이" },
    { idx: "6", title: "베이지" },
    { idx: "7", title: "아이보리" },
    { idx: "8", title: "시크" },
    { idx: "9", title: "럭셔리" },
    { idx: "10", title: "앤틱" },
    { idx: "11", title: "프로방스" },
    { idx: "12", title: "내추럴" },
    { idx: "13", title: "네오클래식" },
    { idx: "14", title: "빈티지" },
    { idx: "15", title: "친환경" },
    { idx: "16", title: "로맨틱" },
    { idx: "17", title: "정크" },
    { idx: "18", title: "북유럽" },
    { idx: "19", title: "그린" },
    { idx: "20", title: "네이비" },
    { idx: "21", title: "핑크" },
    { idx: "22", title: "퍼플" },
  ],
  serveoption: [
    { idx: "1", title: "실내건축업면허" },
    { idx: "2", title: "하자보증보험" },
    { idx: "3", title: "3D디자인" },
    { idx: "4", title: "전문현장감리" },
    { idx: "5", title: "현금영수증" },
    { idx: "6", title: "설계도면" },
    { idx: "7", title: "숙소제공" },
    { idx: "8", title: "이삿짐센터" },
    { idx: "9", title: "공정표" },
    { idx: "10", title: "계약서" },
    { idx: "11", title: "그린리모델링" },

    { idx: "", title: "해당없음" },
  ],
  sellerIntroduceOption: [
    {
      label: "구축 아파트 전용인",
      value: "구축 아파트 전용인",
    },
    {
      label: "평당 130만원 이하인",
      value: "평당 130만원 이하인",
    },
    {
      label: "직영 시공팀을 보유한",
      value: "직영 시공팀을 보유한",
    },
    {
      label: "100건 이상 시공한",
      value: "100건 이상 시공한",
    },
    {
      label: "욕실 대리점으로 시작한",
      value: "욕실 대리점으로 시작한",
    },
    { label: "하자율이 낮은", value: "하자율이 낮은" },
    { label: "직접 입력", value: "text" },
  ],
  instanceStateoption: [
    { idx: "0", title: "작성중" },
    { idx: "1", title: "요청중" },
    { idx: "2", title: "반려" },
    { idx: "3", title: "대기" },
    { idx: "4", title: "사용중" },
    { idx: "5", title: "재요청" },
  ],
  asoption: [
    { idx: "없음", title: "보증없음" },
    { idx: "1년", title: "1년" },
    { idx: "2년", title: "2년" },
    { idx: "3년", title: "3년" },
    { idx: "4년", title: "4년" },
    { idx: "5년", title: "5년" },
    { idx: "6년", title: "6년" },
    { idx: "7년", title: "7년" },
    { idx: "8년", title: "8년" },
    { idx: "9년", title: "9년" },
    { idx: "10년", title: "10년" },
  ],
  styleoption: [
    { idx: "1", title: "전체 구매" },
    { idx: "2", title: "스타일1" },
    { idx: "3", title: "스타일2" },
    { idx: "4", title: "스타일3" },
    { idx: "5", title: "스타일4" },
    { idx: "6", title: "스타일5" },
    { idx: "7", title: "스타일6" },
    { idx: "8", title: "스타일7" },
  ],

  acreageoption: [
    { idx: "1", title: "24평" },
    { idx: "2", title: "26평" },
    { idx: "3", title: "28평" },
    { idx: "4", title: "30평" },
    { idx: "5", title: "32평" },
    { idx: "6", title: "34평" },
    { idx: "7", title: "36평" },
    { idx: "8", title: "38평" },
  ],

  wishdayoption: [
    { idx: "1", title: "test1" },
    { idx: "2", title: "test2" },
    { idx: "3", title: "test3" },
    { idx: "4", title: "test4" },
    { idx: "5", title: "test5" },
    { idx: "6", title: "test6" },
    { idx: "7", title: "test7" },
    { idx: "8", title: "test8" },
  ],

  demolitiondayoption: [
    { idx: "1", title: "test11" },
    { idx: "2", title: "test22" },
    { idx: "3", title: "test33" },
    { idx: "4", title: "test44" },
    { idx: "5", title: "test55" },
    { idx: "6", title: "test66" },
    { idx: "7", title: "test77" },
    { idx: "8", title: "test88" },
  ],
  authoption: [
    { idx: "", title: "선택" },
    { idx: 3, title: "사용자" },
    { idx: 2, title: "관리자" },
  ],
  partnerUserStateoption: [
    { idx: "0", title: "대기" },
    { idx: "1", title: "정상" },
    { idx: "2", title: "중지" },
  ],
  settingTalkoption: [
    { idx: "1", title: "즉시" },
    { idx: "2", title: "지연" },
  ],
  settingFixoption: [
    { idx: "0", title: "전체" },
    { idx: "1", title: "일반" },
    { idx: "2", title: "우수" },
    { idx: "3", title: "브랜드" },
  ],
  settingPriceoption: [
    { idx: "0", title: "전체" },
    { idx: "1", title: "평당" },
    { idx: "2", title: "총금액" },
  ],
  estimateTypeoption: [
    { idx: "1", title: "일반" },
    { idx: "2", title: "시공사례" },
    { idx: "3", title: "테마" },
  ],
  homeDetailPopupOption: [
    { idx: 1, title: "전체" },
    { idx: 2, title: "거실" },
    { idx: 3, title: "방/침실" },
    { idx: 4, title: "주방" },
    { idx: 5, title: "욕실" },
    { idx: 6, title: "발코니" },
    { idx: 7, title: "현관/중문" },
    { idx: 8, title: "그 외" },
  ],
  sellerUserServiceState: [
    { idx: 1, title: "초대중", cl: "cl_5" },
    { idx: 2, title: "시간초과", cl: "cl_4" },
    { idx: 3, title: "중지", cl: "cl_7" },
    { idx: 4, title: "정상", cl: "cl_6" },
    { idx: 5, title: "대기", cl: "cl_4" },
  ],
  sellerOrderState: [
    { idx: 1, title: "견적요청", cl: "cl_6" },
    { idx: 2, title: "파트너연락중", cl: "cl_6" },
    { idx: 3, title: "파트너상담예정", cl: "cl_6" },
    { idx: 4, title: "파트너상담완료", cl: "cl_6" },
    { idx: 5, title: "계약전", cl: "cl_6" },
    { idx: 6, title: "계약완료", cl: "cl_6" },
    { idx: 7, title: "시공전", cl: "cl_5" },
    { idx: 8, title: "시공중", cl: "cl_5" },
    { idx: 9, title: "시공완료", cl: "cl_5" },
    { idx: 10, title: "접수거절", cl: "cl_5" },
    { idx: 11, title: "의뢰취소", cl: "cl_5" },
    { idx: 12, title: "의뢰중단", cl: "cl_1" },
  ],
  sellerOrderType: [
    { idx: "0", title: "일반" },
    { idx: "1", title: "시공사례" },
    { idx: "2", title: "테마" },
    { idx: "3", title: "파트너" },
    { idx: "4", title: "이벤트" },
  ],
  sellerOrderRespState: [
    { idx: "0", title: "대기중" },
    { idx: "1", title: "거절" },
    { idx: "2", title: "접수" },
    { idx: "3", title: "의사없음" },
    { idx: "4", title: "고객미연결" },
    { idx: "5", title: "상담예정" },
    { idx: "6", title: "계약의사없음" },
    { idx: "7", title: "계약진행" },
  ],
  orderSaveType: [
    { idx: "0", title: "일반" },
    { idx: "1", title: "아파트너" },
    { idx: "2", title: "아파트 스토리" },
  ],
  tag_id: [
    { idx: 1, title: "도배" },
    { idx: 2, title: "장판" },
    { idx: 3, title: "마루" },
    { idx: 4, title: "포쉐린/폴리싱" },
    { idx: 5, title: "탄성코드" },
    { idx: 6, title: "주방" },
    { idx: 7, title: "욕실" },
    { idx: 8, title: "문" },
    { idx: 9, title: "붙박이장" },
    { idx: 10, title: "중문" },
    { idx: 11, title: "신발장" },
    { idx: 12, title: "몰딩" },
    { idx: 13, title: "걸레받이" },
    { idx: 14, title: "필름" },
    { idx: 15, title: "조명/콘센트" },
    { idx: 16, title: "목공" },
    { idx: 17, title: "전기" },
    { idx: 18, title: "설비/미장" },
    { idx: 19, title: "철거" },
    { idx: 20, title: "창호" },
    { idx: 21, title: "확장" },
  ],
  themeState: [
    { idx: "0", title: "대기" },
    { idx: "1", title: "사용중" },
  ],
  reviewCode: [
    { idx: "3", title: "보통", num: 3 },
    { idx: "5", title: "만족", num: 5 },
    { idx: "1", title: "불만", num: 1 },
    // {idx : '-2', title:"표기없음"},
  ],
  themeEqCode: [
    { title: "침대", idx: 1 },
    { title: "매트리스/토퍼", idx: 2 },
    { title: "소파", idx: 3 },
    { title: "테이블/책상", idx: 4 },
    { title: "거실장/TV수납장", idx: 5 },
    { title: "서랍/수납장", idx: 6 },
    { title: "책장/진열장", idx: 7 },
    { title: "선반", idx: 8 },
    { title: "행거/옷장", idx: 9 },
    { title: "의자", idx: 10 },
    { title: "화장대/콘솔", idx: 11 },
    { title: "거울", idx: 12 },
    { title: "가벽/파티션", idx: 13 },
    { title: "야외 가구", idx: 14 },
    { title: "유아동 가구", idx: 15 },
    { title: "기타(가구)", idx: 16 },
    { title: "침구세트", idx: 17 },
    { title: "이불/이불솜", idx: 18 },
    { title: "베개/베개커버", idx: 19 },
    { title: "토퍼/패드", idx: 20 },
    { title: "매트리스커버", idx: 21 },
    { title: "블라인드/롤스크린", idx: 22 },
    { title: "커튼/부자재", idx: 23 },
    { title: "러그/카페트", idx: 24 },
    { title: "매트", idx: 25 },
    { title: "쿠션/방석", idx: 26 },
    { title: "소파/생활커버", idx: 27 },
    { title: "홈패브릭", idx: 28 },
    { title: "주방패브릭", idx: 29 },
    { title: "욕실패브릭", idx: 30 },
    { title: "유아용패브릭", idx: 31 },
    { title: "기타(패브릭)", idx: 32 },
    { title: "공간별조명", idx: 33 },
    { title: "LED평판등", idx: 34 },
    { title: "천장등", idx: 35 },
    { title: "장스탠드", idx: 36 },
    { title: "단스탠드", idx: 37 },
    { title: "데스크스탠드", idx: 38 },
    { title: "무드등/장식조명", idx: 39 },
    { title: "벽조명", idx: 40 },
    { title: "센서등", idx: 41 },
    { title: "기타(조명)", idx: 42 },
    { title: "냉장고", idx: 43 },
    { title: "TV", idx: 44 },
    { title: "세탁기/건조기", idx: 45 },
    { title: "에어컨", idx: 46 },
    { title: "청소기", idx: 47 },
    { title: "컴퓨터/노트북", idx: 48 },
    { title: "디지털가전", idx: 49 },
    { title: "주방가전", idx: 50 },
    { title: "계절가전", idx: 51 },
    { title: "음향가전", idx: 52 },
    { title: "영상가전", idx: 53 },
    { title: "생활/건강가전", idx: 54 },
    { title: "이미용가전", idx: 55 },
    { title: "소형가전", idx: 56 },
    { title: "기타(가전)", idx: 57 },
    { title: "그릇/홈세트", idx: 58 },
    { title: "국내도자기그릇", idx: 59 },
    { title: "수입주방용품", idx: 60 },
    { title: "냄비/프라이팬/솥", idx: 61 },
    { title: "보관/용기/도시락", idx: 62 },
    { title: "주방수납/정리", idx: 63 },
    { title: "식기건조대", idx: 64 },
    { title: "주방잡화", idx: 65 },
    { title: "수저/커트러리", idx: 66 },
    { title: "컵/잔/텀블러", idx: 67 },
    { title: "조리도구/도마", idx: 68 },
    { title: "칼/커팅기구", idx: 69 },
    { title: "이유/유아식기", idx: 70 },
    { title: "주방패브릭", idx: 71 },
    { title: "와인/칵테일용품", idx: 72 },
    { title: "커피/티용품", idx: 73 },
    { title: "베이킹용품", idx: 74 },
    { title: "기타(주방용품)", idx: 75 },
    { title: "캔들/디퓨저", idx: 76 },
    { title: "갤러리/액자", idx: 77 },
    { title: "플라워/식물", idx: 78 },
    { title: "시계", idx: 79 },
    { title: "인테리어소품/장식", idx: 80 },
    { title: "기타(데코/식물)", idx: 81 },
    { title: "수납장/서랍장", idx: 82 },
    { title: "리빙박스/수납함", idx: 83 },
    { title: "바구니/바스켓", idx: 84 },
    { title: "행거", idx: 85 },
    { title: "선반", idx: 86 },
    { title: "옷걸이/옷정리", idx: 87 },
    { title: "화장대/테이블정리", idx: 88 },
    { title: "현관/신발정리", idx: 89 },
    { title: "후크/수납걸이", idx: 90 },
    { title: "기타(수납/정리)", idx: 91 },
    { title: "욕실용품", idx: 92 },
    { title: "수건/타월", idx: 93 },
    { title: "청소용품", idx: 94 },
    { title: "세탁용품", idx: 95 },
    { title: "생활잡화", idx: 96 },
    { title: "기타(생활용품)", idx: 97 },
    { title: "욕실 용품", idx: 98 },
    { title: "욕실 텍스타일", idx: 99 },
    { title: "세면대 세트", idx: 100 },
    { title: "기타(욕실)", idx: 101 },
  ],
  payStateOption: [
    { idx: "1", title: "입금전" },
    { idx: "2", title: "입금확인요청" },
    { idx: "3", title: "결제완료" },
    { idx: "4", title: "CMS사용" },
  ],
  asStateOption: [
    { idx: "1", title: "접수중" },
    { idx: "2", title: "접수완료" },
    { idx: "3", title: "처리중" },
    { idx: "4", title: "처리완료" },
  ],
  asTypeOption: [
    { idx: "1", title: "무상" },
    { idx: "2", title: "유상" },
    { idx: "3", title: "검토" },
  ],
  asTypeOption1: [
    { idx: "1", title: "무상" },
    { idx: "2", title: "유상" },
    { idx: "3", title: "홈스타일" },
  ],
  noticeTypeOption: [
    { idx: "1", title: "일반" },
    { idx: "2", title: "시스템" },
    { idx: "3", title: "이벤트" },
  ],
  style_buy: [
    { idx: "0", title: "없음" },
    { idx: "1", title: "전체" },
    { idx: "2", title: "부분" },
  ],
  day_option: [
    { idx: "1", title: "일" },
    { idx: "2", title: "월" },
  ],
  day_option_view: [
    { idx: "1", title: "일" },
    { idx: "2", title: "개월" },
  ],
  day_option_text: [
    { idx: "1", title: "개월" },
    { idx: "2", title: "일" },
  ],
  period_option: [
    { idx: "1", title: "1개월이내" },
    { idx: "2", title: "2개월이내" },
    { idx: "3", title: "3개월이내" },
    { idx: "4", title: "4개월이내" },
    { idx: "5", title: "4개월이후" },
    { idx: "0", title: "미정" },
  ],
  noticeState: [
    { idx: "0", title: "대기", cl: "cl_7" },
    { idx: "1", title: "정상", cl: "cl_5" },
  ],
  noticeTypePartner: [
    { idx: "0", title: "일반" },
    { idx: "1", title: "시스템" },
    { idx: "2", title: "이벤트" },
  ],
  noticeTypeUser: [
    { idx: "0", title: "팝업" },
    { idx: "1", title: "배너1" },
    { idx: "2", title: "배너2" },
    { idx: "3", title: "배너3" },
  ],
  notice_avt_state: [
    { idx: "1", title: "검수요청" },
    { idx: "2", title: "대기" },
    { idx: "3", title: "운영중" },
    { idx: "4", title: "종료" },
  ],
  work_type_option: [
    { idx: "0", title: "없음" },
    { idx: "1", title: "전체시공" },
    { idx: "2", title: "부분시공" },
  ],
  work_type_option1: [
    { idx: "1", title: "전체시공" },
    { idx: "2", title: "공간시공" },
  ],
  work_type_option2: [
    { idx: "1", title: "전체" },
    { idx: "2", title: "부분" },
  ],
  rangeoption: [
    { idx: "1", title: "전체" },
    { idx: "2", title: "부분" },
    { idx: "3", title: "전체/부분" },
  ],
  paymentoption: [
    { idx: "1", title: "계약 수수료" },
    { idx: "2", title: "정액제" },
    { idx: "3", title: "정량제" },
  ],
  contstateoption: [
    { idx: "1", title: "대기" },
    { idx: "2", title: "완료" },
  ],
  event_state: [
    { idx: 1, title: "도배" },
    { idx: 2, title: "바닥" },
    { idx: 3, title: "도배, 바닥" },
  ],
  event_process: [
    { idx: 1, title: "대기" },
    { idx: 2, title: "접수완료" },
    { idx: 3, title: "접수취소" },
    { idx: 4, title: "고객부재" },
  ],
  supply_options: [
    { idx: 1, title: "차감" },
    { idx: 2, title: "환불" },
  ],
  life_monthly_application_options: [
    { idx: "0", title: "종료" },
    { idx: "1", title: "진행중" },
  ],
  typeOption: [
    { idx: "", title: "전체" },
    { idx: "1", title: "이름" },
    { idx: "2", title: "전화번호" },
    { idx: "3", title: "아이디" },
  ],
  userTypeOption: [
    { idx: "", title: "전체" },
    { idx: "1", title: "카카오" },
  ],
  userStateOption: [
    { idx: "", title: "전체" },
    { idx: "1", title: "정상" },
    { idx: "0", title: "탈퇴" },
  ],
};

export default exportData;
