import React from "react";

export default function PageNation(props) {
  const { pageing, setPage, curpage, onSearch } = props;

  const curpageGr = Math.floor(curpage / 5);

  const pageingFunc = (num) => {
    if (onSearch) {
      onSearch("page", num);
    } else {
      setPage(num);
    }
  };

  return pageing ? (
    <>
      <div className="pagenation">
        <button
          type="button"
          className="page_prev"
          onClick={() => pageingFunc(curpage > 0 ? curpage - 1 : curpage)}
        ></button>

        {[...Array(5)].map((n, i) => {
          let ii = curpageGr * 5 + i;
          if (pageing.totalPages < 5 && i + 1 > pageing.totalPages) {
            return false;
          } else if (ii + 1 > pageing.totalPages) {
            return false;
          }
          return (
            <button
              type="buttom"
              onClick={() => pageingFunc(ii)}
              className={ii === curpage ? "active" : ""}
              key={i}
            >
              {ii + 1}
            </button>
          );
        })}

        <button
          type="button"
          className="page_next"
          onClick={() =>
            pageingFunc(
              pageing.totalPages - 1 > curpage ? curpage + 1 : curpage
            )
          }
        ></button>
      </div>
    </>
  ) : (
    <>
      <div className="pagenation">
        <button type="button" className="page_prev"></button>

        <button type="button" className="active">
          1
        </button>
        <button type="button">2</button>
        <button type="button">3</button>
        <button type="button">4</button>
        <button type="button">5</button>
        <button type="button">6</button>
        <button type="button">7</button>
        <button type="button">8</button>
        <button type="button">9</button>
        <button type="button">10</button>

        <button type="button" className="page_next"></button>
      </div>
    </>
  );
}
