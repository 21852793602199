/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as APIS from "../../utils/service";
import { findJson, numFormat } from "../../utils/utils";
import moment from "moment";
import Input from "../../components/Input";
import InputDate from "../../components/InputDate";
import InputSelect from "../../components/InputSelect";
import Pagenation from "../../components/Pagenation";
import AsAdd from "./AsAdd";
import consts from "../../libs/consts";
import { open } from "../../redux/popupSlice";
import { setData } from "../../redux/searchSlice";
import AdminLayout from "../../components/Layout/admin";
import Table from "../../components/Common/Table/Table";
import { css } from "@emotion/react";

export default function As() {
  const dispatch = useDispatch();
  const userData = useSelector((s) => s.usersReducer);
  const search = useSelector((s) => s.searchReducer);
  const popup = useSelector((s) => s.popupReducer);

  /* search form */
  const [pageing, setPageing] = useState("");

  const [list, setList] = useState([]);

  /* search form End */

  useEffect(() => {
    dataFunc();
  }, [
    search.city,
    search.range,
    search.membership,
    search.state,
    search.license,
    search.sdate,
    search.edate,
    search.page,
    popup.open,
  ]);

  const dataFunc = () => {
    setPageing({ totalPages: 2 });

    const sender = {
      admin_table_id: userData.admin_table_id,
      // order_estimate_type: search.range,
      type: search.state,
      state: search.license,
      cont_table_id: search.stx,
      start_date: search.sdate,
      end_date: search.edate,
      page: search.page + 1,
    };

    APIS.postData("/AdminAS/ASList", sender, 1)
      .then((res) => {
        if (res.data.code === "OK") {
          setList(res.data.as_list);
          setPageing({
            totalPages: Math.ceil(res.data.total_cnt / consts.pagerows),
          });
        } else {
          setList([]);
          setPageing({ totalPages: 1 });
        }
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };

  const searchFunc = (key, value) => {
    dispatch(
      setData({
        key: key,
        value: value,
      })
    );
  };
  const addFunc = (x) => {
    dispatch(
      open({
        title: "A/S 요청 정보 등록",
        component: <AsAdd x={""} w={"i"} />,
      })
    );
  };
  const updateFunc = (x) => {
    dispatch(
      open({
        title: "A/S 요청 정보",
        component: <AsAdd x={x} w={"u"} id={x.as_table_id} />,
      })
    );
  };

  const col = [
    { key: "as_table_id", title: "요청번호" },
    { key: "cont_table_id", title: "계약번호" },
    {
      key: "real_end_date",
      title: "시공종료일",
      render: (x) => (
        <span>{moment(x.real_end_date).format("YYYY.MM.DD")}</span>
      ),
    },
    { key: "seller_biz_name", title: "파트너명" },
    {
      key: "order_price",
      title: "시공가격",
      render: (x) => <span>{numFormat(x.order_price)}</span>,
    },
    {
      key: "type",
      title: "A/S유형",
      render: (x) => <span>{findJson(consts.asTypeOption1, x.type)}</span>,
    },
    {
      key: "state",
      title: "처리상태",
      render: (x) => <span>{findJson(consts.asStateOption, x.state)}</span>,
    },
    {
      key: "reg_date",
      title: "요청일시",
      render: (x) => <span>{moment(x.reg_date).format("YYYY.MM.DD")}</span>,
    },
    {
      key: "update_date",
      title: "최종일시",
      render: (x) => <span>{moment(x.update_date).format("YYYY.MM.DD")}</span>,
    },
  ];

  return (
    <AdminLayout title="A/S 관리">
      <div>
        <div className="search_form search_form_long">
          <div className="search_form_left">
            {/* <InputSelect name="range" label="견적유형" value={search.range} onSearch={searchFunc} option={[{idx: "", title: "전체"}, ...consts.themeState]} /> */}
            <InputSelect
              name="state"
              label="A/S유형"
              value={search.state}
              onSearch={searchFunc}
              option={[{ idx: "", title: "전체" }, ...consts.asTypeOption1]}
            />
            <InputSelect
              name="license"
              label="처리상태"
              value={search.license}
              onSearch={searchFunc}
              option={[{ idx: "", title: "전체" }, ...consts.asStateOption]}
            />

            <Input
              className="input_text"
              type="text"
              placeholder={"계약번호 입력"}
              name="stx"
              value={search.stx}
              onSearch={searchFunc}
              label={"검색"}
            />

            <InputDate
              type="text"
              placeholder="시작날짜"
              placeholderEnd="종료날짜"
              name="ipd1"
              label="날짜선택"
              dateValue={search.sdate}
              onSearch={searchFunc}
              dateValueEnd={search.edate}
              multiple={true}
            />

            <button
              type="button"
              className="btn btn3"
              onClick={() => dataFunc()}
            >
              검색
            </button>
          </div>
          <div className="search_form_right">
            <button
              type="button"
              className="btn btn1"
              onClick={() => addFunc()}
            >
              등록
            </button>
          </div>
        </div>

        <div
          css={css`
            margin: 30px 0 20px 0;
          `}
        >
          <Table
            columns={col}
            datas={list}
            rowCallback={(x) => updateFunc(x)}
          />
        </div>

        <Pagenation
          pageing={pageing}
          onSearch={searchFunc}
          curpage={search.page}
        />
      </div>
    </AdminLayout>
  );
}
