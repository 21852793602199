import axios from "axios";
import consts from "../libs/consts";
import queryString from "query-string";

axios.defaults.headers.common["Content-Type"] = "application/json";

export const setAuthorization = (authToken) => {
  axios.defaults.headers.common["Authorization"] = ``;
};

export const initAuthorization = () => {
  axios.defaults.headers.common["Authorization"] = "";
};

export const getData = (url, params = "") => {
  Object.keys(params).forEach((key) => {
    if (params[key] === undefined || params[key] === "") {
      delete params[key];
    }
  });

  const querys = "?" + queryString.stringify(params || {});

  return axios.get(`${url}${querys}`);
};

export const postData = (url, sender = {}, type) => {
  let dm = "";
  if (type === 1) {
    dm = consts.apiBaseUrlAdmin;
  } else if (type === 2) {
    dm = consts.apiBaseUrlPartner;
  } else {
    dm = consts.apiBaseUrlFront;
  }

  return axios.post(dm + `${url}`, JSON.stringify(sender));
};

export const postDataForm = (url, sender, type) => {
  let dm = "";
  if (type === 1) {
    dm = consts.apiBaseUrlAdmin;
  } else if (type === 2) {
    dm = consts.apiBaseUrlPartner;
  } else {
    dm = consts.apiBaseUrlFront;
  }

  // 테스트 다 완료되고 본서버에 올릴때 주석
  dm = "https://dev_api.layhome.net:88";

  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  return axios.post(dm + `${url}`, sender, config);
};
