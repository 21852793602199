/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import * as APIS from "../../utils/service";
import { numFormat } from "../../utils/utils";

import moment from "moment";

import ShadowBox from "../../components/ShadowBox";
import InfoBox from "../../components/InfoBox";
import MemberShip from "../../components/MemberShip";
import Notice from "../../components/Notice";

import colors from "../../libs/colors";
import consts from "../../libs/consts";
import routes from "../../libs/routes";

import { open } from "../../redux/popupSlice";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-flip";
import "swiper/css/effect-fade";
import "swiper/css/effect-cube";
import "swiper/css/effect-creative";
import { Link } from "react-router-dom";
import PartnerLayout from "../../components/Layout/partner";
import { css } from "@emotion/react";

export default function Main() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((s) => s.usersReducer);

  const [estimate_cnt, setEstimate_cnt] = useState("");
  const [order_cnt, setOrder_cnt] = useState("");
  const [schedule, setSchedule] = useState([]);
  const [notice, setNotice] = useState("");

  const [available_count, setavailable_count] = useState("");
  const [fixed_term_end, setfixed_term_end] = useState("");
  const [fixed_term_grade, setfixed_term_grade] = useState("");
  const [fixed_term_start, setfixed_term_start] = useState("");
  const [max_count, setmax_count] = useState("");
  const [period_term_end, setperiod_term_end] = useState("");
  const [period_term_start, setperiod_term_start] = useState("");
  const [period_cnt, setperiod_cnt] = useState(0);

  useEffect(() => {
    const sender = {
      seller_table_id: userData.seller_table_id,
    };
    APIS.postData("/SellerInfo/GetLobby", sender, 2)
      .then((res) => {
        setEstimate_cnt(res.data.estimate_cnt);
        setOrder_cnt(res.data.order_cnt);
        setSchedule([res.data.schedule][0]);
        setNotice(res.data.notice);
      })
      .catch((e) => {
        console.log("catch", e);
      });

    APIS.postData("/Membership/MemInfo", sender)
      .then((res) => {
        setavailable_count(res.data.available_count);
        setfixed_term_end(res.data.fixed_term_end);
        setfixed_term_grade(res.data.fixed_term_grade);
        setfixed_term_start(res.data.fixed_term_start);
        setmax_count(res.data.max_count);
        setperiod_term_end(res.data.period_term_end);
        setperiod_term_start(res.data.period_term_start);
        setperiod_cnt(res.data.period_cnt * 1);
      })
      .catch((e) => {
        console.log("catch", e);
      });
  }, []);

  const noticeFunc = (selected) => {
    dispatch(
      open({
        title: "공지사항",
        wide: true,
        component: <Notice selected={selected} />,
      })
    );
    return false;
  };

  const membershipFunc = () => {
    navigate(routes.membership);
  };
  return (
    <PartnerLayout>
      <div
        css={css`
          padding: 52px 30px 156px 26px;
          height: auto;
          min-height: 79vh;
          background: #f7f9fc !important;
          margin: 0 -30px -30px -30px;

          @media screen and (max-width: 767px) {
            padding: 100px 30px 156px 26px;
          }
        `}
      >
        <p className="page_title">{userData.seller_biz_name}</p>

        <div className="dashboard">
          <ShadowBox link={true} className="dashboard_count">
            <Link to={routes.estimate}>
              <p className="dashboard_title">이번달 견적요청 건</p>
              <p className="dashboard_num">{numFormat(estimate_cnt)}건</p>
            </Link>
          </ShadowBox>
          <ShadowBox link={true} className="dashboard_count">
            <Link to={routes.estimate}>
              <p className="dashboard_title">이번달 계약 건</p>
              <p className="dashboard_num">{numFormat(order_cnt)}건</p>
            </Link>
          </ShadowBox>

          <ShadowBox className="m_dashboard_count">
            <ul>
              <li>
                <Link to={routes.estimate}>
                  <p className="dashboard_title">이번달 견적요청 건</p>
                  <p className="dashboard_num">{numFormat(estimate_cnt)}건</p>
                </Link>
              </li>
              <li>
                <Link to={routes.estimate}>
                  <p className="dashboard_title">이번달 계약 건</p>
                  <p className="dashboard_num">{numFormat(order_cnt)}건</p>
                </Link>
              </li>
            </ul>
          </ShadowBox>

          <ShadowBox link={true}>
            <Link to={routes.day}>
              <p className="dashboard_title alignl">다가올 주요 일정</p>
              {schedule.length > 0 ? (
                schedule.map((x, i) => {
                  if (
                    x.order_state_word !== "완료" &&
                    x.order_state_word !== "미팅 완료"
                  ) {
                    return (
                      <Fragment key={i}>
                        {Number(x.state) < 6 ? (
                          <p className="dashboard_date">
                            {moment(x.meet_date).format("YYYY-MM-DD")}
                          </p>
                        ) : (
                          <p className="dashboard_date">
                            {x.work_start_date === "0000-00-00 00:00:00" ||
                            x.work_start_date === null
                              ? "날짜 미기입"
                              : moment(x.work_start_date).format("YYYY-MM-DD")}
                            ~
                            {x.work_end_date === "0000-00-00 00:00:00" ||
                            x.work_end_date === null
                              ? "날짜 미기입"
                              : moment(x.work_end_date).format("YYYY-MM-DD")}
                          </p>
                        )}

                        <div className="dashboard_date_info">
                          <p className="dot_flex">
                            <span
                              className="dot"
                              style={{ backgroundColor: colors.cl_5 }}
                            />
                            <span className="cl_5 dashboard_date_info_tag">
                              {x.area}({x.pyung}평)
                            </span>
                          </p>
                          <button type="button" className="btn_s btn_s2">
                            {x.order_state_word}
                          </button>
                        </div>
                      </Fragment>
                    );
                  } else {
                    return null;
                  }
                })
              ) : (
                <p className="null_msg">주요 일정이 없습니다.</p>
              )}
            </Link>
          </ShadowBox>

          <ShadowBox
            link={true}
            width="calc(50% - 10px)"
            onPress={() => membershipFunc()}
          >
            <p className="dashboard_title alignl">내 멤버십</p>

            <div className="sw_section">
              <Swiper
                className="mainSectionSwiper"
                effect={consts.platform === "ios" ? "fade" : "flip"}
                loop={true}
                spaceBetween={20}
                slidesPerView={1}
                pagination={{
                  el: ".swiper-pagination",
                  clickable: true,
                }}
              >
                {!fixed_term_grade && !period_term_start && !period_term_end ? (
                  <SwiperSlide>
                    <InfoBox>
                      <p className="null_msg">
                        현재 이용중이신 멤버십이 없습니다.
                      </p>
                    </InfoBox>
                  </SwiperSlide>
                ) : (
                  <>
                    {fixed_term_grade && (
                      <SwiperSlide>
                        <InfoBox
                          help={true}
                          msg={
                            "기간 내 사용하지 않은 수량은 다음달에 이월되지 않습니다."
                          }
                        >
                          <p className="dashboard_title alignl">정액제</p>

                          <ul className="list_style1">
                            <li className="alignitemc">
                              <p className="title">등급</p>
                              <MemberShip type={fixed_term_grade} />
                            </li>
                            <li>
                              <p className="title">수량</p>
                              <p className="content">
                                {numFormat(available_count)}/
                                {numFormat(max_count)} 건
                              </p>
                            </li>
                            <li>
                              <p className="title">기간</p>
                              <p
                                className={
                                  "content " +
                                  (moment().isAfter(fixed_term_end)
                                    ? "cl_3"
                                    : "")
                                }
                              >
                                {moment(fixed_term_start).format("YYYY.MM.DD")}{" "}
                                ~ {moment(fixed_term_end).format("YYYY.MM.DD")}{" "}
                                {moment().isAfter(fixed_term_end) &&
                                  "(기간만료)"}
                              </p>
                            </li>
                          </ul>
                        </InfoBox>
                      </SwiperSlide>
                    )}

                    <SwiperSlide>
                      <InfoBox
                        help={true}
                        msg={
                          "서비스 해지 전까지  남은 수량을 이용하실 수 있습니다."
                        }
                      >
                        <p className="dashboard_title alignl">정량제</p>

                        <ul className="list_style1">
                          <li>
                            <p className="title">수량</p>
                            <p className="content">
                              {numFormat(period_cnt)} 건
                            </p>
                          </li>
                          <li>
                            <p className="title">기간</p>
                            {/* <p className={"content " + (moment().isAfter(period_term_end) ? "cl_3" : "")}>{moment(period_term_start).format('YYYY.MM.DD')} ~ {moment(period_term_end).format('YYYY.MM.DD')} {moment().isAfter(period_term_end) && '(기간만료)'}</p> */}
                            <p className={"content"}>(기간제한없음)</p>
                          </li>
                        </ul>
                      </InfoBox>
                    </SwiperSlide>
                  </>
                )}

                <div className="swiper-pagination main_swiper_pagenation"></div>
              </Swiper>
            </div>
          </ShadowBox>
          <ShadowBox
            link={true}
            linkText={"전체보기"}
            width="calc(50% - 10px)"
            onPress={() => noticeFunc()}
          >
            <p className="dashboard_title alignl">공지사항</p>

            <ul className="list_style2">
              {notice && notice.length > 0 ? (
                notice.map((x, i) => {
                  return (
                    <li key={i} onClick={() => noticeFunc("1")}>
                      <p>{x.notice_title}</p>
                    </li>
                  );
                })
              ) : (
                <li className="null_msg">공지사항이 없습니다.</li>
              )}
            </ul>
          </ShadowBox>
        </div>
      </div>
    </PartnerLayout>
  );
}
