import React from "react";

export default function ShadowBox(props) {
  const {
    children,
    link,
    linkText,
    width = "calc(33.33% - 10px)",
    className,
    onPress,
  } = props;

  return (
    <div
      className={"shadowBox " + className}
      style={{
        cursor: link && !linkText ? "pointer" : "default",
        width: width,
      }}
    >
      {link && (
        <button
          type="button"
          className="right_btn"
          onClick={() => onPress && onPress()}
        >
          {linkText && linkText}
        </button>
      )}
      {children}
    </div>
  );
}
