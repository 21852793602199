import React from "react";
import AdminLayout from "../../../components/Layout/admin";
import { List } from "../../../components/SafeNumbers";

const SafeNumbersPage = () => {
  return (
    <AdminLayout title="안심번호 관리">
      <List />
    </AdminLayout>
  );
};

export default SafeNumbersPage;
