import { useNavigate } from "react-router-dom";
import routes from "../../../libs/routes";

const useHome = () => {
  const navigate = useNavigate();
  const { pathname } = window.location;

  const tabs = [
    {
      id: 1,
      title: "히어로 배너",
      isActive: pathname === routes.home_manage_hero,
      callback: () => {
        navigate(routes.home_manage_hero);
      },
    },
    {
      id: 2,
      title: "픽 메뉴",
      isActive: pathname === routes.home_manage_pick,
      callback: () => {
        navigate(routes.home_manage_pick);
      },
    },
    {
      id: 3,
      title: "추천 컨텐츠",
      isActive: pathname === routes.home_manage_recommend,
      callback: () => {
        navigate(routes.home_manage_recommend);
      },
    },
    {
      id: 4,
      title: "월간 인기 시공사례",
      isActive: pathname === routes.home_manage_monthly,
      callback: () => {
        navigate(routes.home_manage_monthly);
      },
    },
    {
      id: 5,
      title: "띠배너",
      isActive: pathname === routes.home_manage_line_banner,
      callback: () => {
        navigate(routes.home_manage_line_banner);
      },
    },
    {
      id: 6,
      title: "중앙배너",
      isActive: pathname === routes.home_manage_center_banner,
      callback: () => {
        navigate(routes.home_manage_center_banner);
      },
    },
    {
      id: 7,
      title: "팝업",
      isActive: pathname === routes.home_manage_popup,
      callback: () => {
        navigate(routes.home_manage_popup);
      },
    },
    {
      id: 8,
      title: "이달의 공구",
      isActive: pathname === routes.home_manage_life_monthly,
      callback: () => {
        navigate(routes.home_manage_life_monthly);
      },
    },
  ];

  return { tabs };
};

export default useHome;
