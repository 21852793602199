const useConvertFileHooks = () => {
  const convertURLtoFile = async (url) => {
    const response = await fetch(url);
    const data = await response.blob();
    const ext = url.split(".").pop();
    const metadata = { type: `image/${ext}` };
    return new File([data], metadata);
  };

  const getFileUrlList = (data) => {
    return data
      .map((item) => item.imgs)
      .flatMap((item) => item)
      .map((item) => item?.case_zone_img || item);
  };

  return {
    convertURLtoFile,
    getFileUrlList,
  };
};

export default useConvertFileHooks;
