import React, { useEffect, useState } from "react";

import DaumPostcode from "react-daum-postcode";

import Timer from "../components/Timer";
import {
  patternEngUpper,
  patternSpcId,
  patternSpcPw,
  patternSpcEmail,
  patternKor,
  numFormat,
} from "../utils/utils";

export default function Input(props) {
  const {
    className,
    placeholder,
    type,
    name,
    maxlength,
    value,
    setValue,
    valid,
    label,
    subLabel,
    error,
    setError = "",
    success,
    readOnly,
    onBlur,
    onChange,
    onChangeAuth,
    onKeyDown,
    index,
    onSearch,
    withButton,
    withButtonPress,
    withButtonLong,
    withText,
    timer = false,
    timerState,
    msg,
    full,
    autoComplete,
    onLink,
    onLinkText,
  } = props;

  const [t, setT] = useState(timer);
  const [f, setF] = useState(false);

  const [isOpenPost, setIsOpenPost] = useState(false);

  useEffect(() => {
    setT(timer);
  }, [timer]);

  useEffect(() => {
    if (readOnly && valid === "price" && setValue) {
      setValue(numFormat(value));
    }
  }, [readOnly, setValue, valid, value]);

  const handleChange = (e) => {
    if (valid === "num" || valid === "price") {
      const num = e.target.value.replace(/\D/g, "");
      if (onChange) {
        onChange(index, e, num);
      } else if (onSearch) {
        onSearch(name, num);
      } else {
        setValue(num);
      }
    } else if (valid === "id") {
      //let val = e.target.value.replace(patternKor, ""); // 한글제거
      let val = e.target.value.replace(patternEngUpper, ""); // 대문자제거
      val = val.replace(patternSpcId, ""); // 특수문자제거
      if (onChange) {
        onChange(index, e, val);
      } else if (onSearch) {
        onSearch(name, val);
      } else {
        setValue(val);
      }
    } else if (valid === "pw") {
      let val = e.target.value.replace(patternKor, ""); // 한글제거
      val = val.replace(patternSpcPw, ""); // 특수문자제거
      val = val.toLowerCase(); // 대문자 소문자로 변환
      if (onChange) {
        onChange(index, e, val);
      } else if (onSearch) {
        onSearch(name, val);
      } else {
        setValue(val);
      }
    } else if (valid === "email") {
      let val = e.target.value.replace(patternKor, ""); // 한글제거
      val = val.replace(patternSpcEmail, ""); // 특수문자제거
      val = val.toLowerCase(); // 대문자 소문자로 변환
      if (onChange) {
        onChange(index, e, val);
      } else if (onSearch) {
        onSearch(name, val);
      } else {
        setValue(val);
      }
    } else {
      if (onChange) {
        onChange(index, e, e.target.value);
      } else if (onSearch) {
        onSearch(name, e.target.value);
      } else if (onChangeAuth) {
        onChangeAuth(e.target.value);
      } else {
        setValue(e.target.value);
      }
    }
  };

  const handleBlur = (e) => {
    setF(false);

    if (valid === "price") {
      if (onChange) {
        onChange(index, e, numFormat(value));
      } else {
        setValue(numFormat(value));
      }
    } else if (valid === "id") {
      if (patternKor.test(value)) {
        setError(
          "영문 소문자, 숫자와 특수기호(@),(.),(-),(_) 만 사용 가능합니다."
        );
      }
    }
  };
  const handleFocus = () => {
    setError && valid !== "pw" && setError("");
    setF(true);

    if (withButton === "주소찾기") {
      setIsOpenPost(true);
    }

    if (!readOnly && valid === "price" && setValue) {
      setValue(value.replaceAll(",", ""));
    }

    if (!readOnly && valid === "totalPrice" && setValue) {
      let tempValue = value.toString();
      let rValue = tempValue.replaceAll(",", "");
      setValue(rValue);
    }
  };
  const handleClick = () => {
    if (withButton === "주소찾기") {
      setIsOpenPost(true);
    } else if (withButton === "지역선택" || withButton === "관리") {
      withButtonPress();
    }
  };

  const withButtonFunc = () => {
    if (withButton === "복사") {
      navigator.clipboard.writeText(value);
    } else if (withButton === "주소찾기") {
      setIsOpenPost(true);
    }
  };

  const onCompletePost = (data) => {
    setValue(data.address);

    setIsOpenPost(false);
  };

  const postBoxStyle = {
    display: "block",
    position: "relative",
    width: "100%",
    border: "1px solid #000",
  };
  const postCodeStyle = {
    width: "100%",
    borderTop: "1px solid #000",
  };

  return (
    <>
      <div className={full ? "input_box input_box_full" : "input_box"}>
        <label className="input_label" htmlFor={name}>
          {label}
        </label>
        {subLabel && (
          <label className="input_label_sub" htmlFor={name}>
            {subLabel}
          </label>
        )}
        <div className="input_section">
          <input
            type={valid === "num" ? "tel" : type}
            name={name}
            id={name}
            className={
              className +
              (readOnly ? " disable" : "") +
              (withButton
                ? withButtonLong
                  ? " withButtonLong"
                  : " withButton"
                : "") +
              (withText ? " withText" : "")
            }
            placeholder={placeholder}
            onChange={handleChange}
            onFocus={handleFocus}
            onClick={handleClick}
            onKeyDown={onKeyDown}
            onBlur={(e) => {
              handleBlur(e);
              onBlur && onBlur();
            }}
            value={value}
            maxLength={maxlength ? maxlength : "255"}
            readOnly={
              withButton === "주소찾기" || withButton === "지역선택"
                ? true
                : readOnly
            }
            autoComplete={autoComplete}
          />
          {withButton && (
            <button
              type="button"
              className={
                withButtonLong
                  ? "input_with_button input_with_button_long"
                  : "input_with_button"
              }
              onClick={() => {
                withButtonFunc();
                withButtonPress && withButtonPress();
              }}
            >
              {withButton}
            </button>
          )}
          {withText && <span className="input_with_text">{withText}</span>}
          {onLink && (
            <span className="input_with_text_link" onClick={() => onLink()}>
              {onLinkText ? onLinkText : "보기"}
            </span>
          )}
          {t && (
            <Timer
              defaultTime={180}
              defaultMin={"03"}
              defaultSec={"00"}
              timeOut={timerState}
            />
          )}
        </div>

        {error && !f && <p className="input_error">{error}</p>}
        {success && !f && <p className="input_success">{success}</p>}
        {msg && <p className="input_msg">{msg}</p>}

        {isOpenPost ? (
          <div style={postBoxStyle}>
            <button
              type="button"
              className="addrbtn exit_btn"
              onClick={() => setIsOpenPost(false)}
            />
            <DaumPostcode
              style={postCodeStyle}
              autoClose
              onComplete={onCompletePost}
            />
          </div>
        ) : null}
      </div>
    </>
  );
}
