import React, { Fragment, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import * as APIS from "../../utils/service";

import { open, close } from "../../redux/popupSlice";

import Input from "../../components/Input";
import InputDate from "../../components/InputDate";
import InputSelect from "../../components/InputSelect";
import InputSelectArea from "../../components/InputSelectArea";
import InputFileMulti from "../../components/InputFileMulti";
import TextArea from "../../components/TextArea";

import consts from "../../libs/consts";

export default function EstimateAdd() {
  const dispatch = useDispatch();
  const userData = useSelector((s) => s.usersReducer);

  /* estimate add state */

  const [estimate_num, setEstimate_num] = useState("신규");
  const [estimate_type, setEstimate_type] = useState("오프라인");

  const [estimate_cate, setEstimate_cate] = useState("0");
  const [estimate_state, setEstimate_state] = useState("접수완료");

  const [cateDisable, setCateDisable] = useState(false);

  const [estimate_theme_id, setEstimate_theme_id] = useState("");

  const [estimate_name, setEstimate_name] = useState("");
  const [estimate_hp, setEstimate_hp] = useState("");
  const [estimate_apt, setEstimate_apt] = useState("");
  const [estimate_pyung, setEstimate_pyung] = useState("");

  const [addr, setAddr] = useState("");
  const [addrDetail, setAddrDetail] = useState("");

  const [city, setCity] = useState("");
  const [area, setArea] = useState("");

  const [order_desired_price, setorder_desired_price] = useState("");
  const [sdate, setSdate] = useState("");

  const [order_desired_period, setorder_desired_period] = useState("1");

  const [range, setRange] = useState("");

  const [estimate_msg, setEstimate_msg] = useState("");
  const [style, setStyle] = useState("");
  const [order_msg, setOrder_msg] = useState("");

  const [photoList, setPhotoList] = useState("");
  const [detail_img, setDetail_img] = useState("");

  const [order_demolition_yn, setOrder_demolition_yn] = useState("");

  const [error, setError] = useState("");

  useEffect(() => {
    setError("");

    if (estimate_cate === "0") {
      setCateDisable(true);
      setEstimate_theme_id("");
    } else {
      setCateDisable(false);
    }
  }, [estimate_cate]);

  /* estimate add state end */
  const closeFunc = () => {
    dispatch(close());
  };

  const submitFunc = () => {
    if (!cateDisable && !estimate_theme_id) {
      setError("시공사례 혹은 테마번호를 입력해주세요.");
      return;
    }

    let arr = [];

    const checkboxes = document.getElementsByClassName("info");
    for (let cb of checkboxes) {
      if (cb.checked) {
        arr.push({ tag_id: cb.value, tag_text: cb.dataset.text });
      }
    }

    const sender = {
      admin_table_id: userData.admin_table_id,
      order_save_type: "0",
      order_estimate_type: estimate_cate,
      order_fk_id: estimate_theme_id,
      order_user_name: estimate_name,
      order_phone: estimate_hp,
      order_area_1st: city,
      order_area_2nd: area,
      order_addr: addr,
      order_addr_detail: addrDetail,
      order_home_name: estimate_apt,
      order_size: estimate_pyung,
      order_desired_period: order_desired_period,
      order_desired_date: moment(sdate).format("YYYY-MM-DD"),
      order_desired_price: order_desired_price,
      work_type: range,
      work_list_adm: arr,
      order_msg: order_msg,
      order_estimate_msg: estimate_msg,
      style_buy: style,
      order_demolition_yn: order_demolition_yn,
      imgs: detail_img,
      order_state: "0",
    };

    APIS.postData("/AdminOrder/OrderCreate", sender, 1)
      .then((res) => {
        if (res.data.code === "OK") {
          dispatch(close());
          setTimeout(() => {
            dispatch(
              open({
                message: "견적관리 등록이 완료 되었습니다.",
              })
            );
          }, 50);
        }
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };

  return (
    <div className="popup_list">
      <div className="popup_list_ul popup_instance_step popup_admin_estimate_add">
        <p class="title">기본정보</p>
        <div className="instance_form_list">
          <Input
            className="input_text"
            type="text"
            name="estimate_num"
            value={estimate_num}
            setValue={setEstimate_num}
            label={"요청번호"}
            readOnly={true}
          />
          <Input
            className="input_text"
            type="text"
            name="estimate_type"
            value={estimate_type}
            setValue={setEstimate_type}
            label={"요청구분"}
            readOnly={true}
          />

          <InputSelect
            name="work"
            label="견적유형"
            value={estimate_cate}
            setValue={setEstimate_cate}
            option={consts.sellerOrderType}
          />
          {/* <InputSelect name="order_state" label="견적상태" value={order_state} setValue={setorder_state} option={consts.sellerOrderState} /> */}
          <Input
            className="input_text"
            type="text"
            placeholder={"내용을 입력해주세요."}
            name="estimate_theme_id"
            value={estimate_theme_id}
            setValue={setEstimate_theme_id}
            label={"시공사례 | 테마번호"}
            maxlength={20}
            readOnly={cateDisable}
            error={error}
            setError={setError}
          />

          <Input
            className="input_text"
            type="text"
            name="estimate_state"
            value={estimate_state}
            setValue={setEstimate_state}
            label={"진행상태"}
            readOnly={true}
          />
        </div>

        <p class="title">고객정보</p>
        <div className="instance_form_list">
          <Input
            className="input_text"
            type="text"
            name="estimate_name"
            value={estimate_name}
            setValue={setEstimate_name}
            label={"성명"}
            maxlength={10}
          />
          <Input
            className="input_text"
            type="text"
            name="estimate_hp"
            value={estimate_hp}
            setValue={setEstimate_hp}
            label={"휴대폰번호"}
            valid={"num"}
            maxlength={13}
          />

          <InputSelectArea
            name="work"
            label="시공지역"
            value={city}
            setValue={setCity}
            area={area}
            setArea={setArea}
          />
          <Input
            className="input_text"
            type="text"
            name="estimate_apt"
            value={estimate_apt}
            setValue={setEstimate_apt}
            label={"아파트명"}
          />
          <Input
            className="input_text"
            type="text"
            name="estimate_pyung"
            value={estimate_pyung}
            setValue={setEstimate_pyung}
            label={"평형"}
            valid={"num"}
            withText={"평"}
          />

          <Input
            className="input_text"
            type="text"
            placeholder="주소 찾기를 해 주세요."
            name="addr"
            value={addr}
            setValue={setAddr}
            label="공사지 주소"
            withButton={"주소찾기"}
          />
          <Input
            className="input_text"
            type="text"
            placeholder="상세주소를 입력해 주세요."
            name="addrDetail"
            value={addrDetail}
            setValue={setAddrDetail}
            label="상세주소"
          />
        </div>

        <p class="title">견적의뢰 정보</p>
        <div className="instance_form_list">
          <Input
            className="input_text"
            type="text"
            name="estimate_name"
            value={order_desired_price}
            setValue={setorder_desired_price}
            label={"희망예산"}
            maxlength={11}
            withText={"만원"}
            valid={"num"}
          />
          <InputDate
            type="text"
            placeholder="공사예정일"
            name="ipd1"
            label="공사예정일"
            dateValue={sdate}
            setDateValue={setSdate}
          />

          <InputSelect
            name="order_desired_period"
            label="공사예정기간"
            value={order_desired_period}
            setValue={setorder_desired_period}
            option={consts.period_option}
          />

          <div
            className="checkbox_section"
            style={{ width: "calc(50% - 12px)" }}
          >
            <p className="input_label">시공범위</p>
            <div
              className="radio_box"
              style={{ width: "100%", marginTop: "4px" }}
            >
              <input
                type="radio"
                id="rd1"
                name="range"
                className="radio2"
                value="1"
                defaultChecked={range === "1"}
                onChange={(e) => setRange(e.target.value)}
              />
              <label htmlFor="rd1">전체</label>
              <input
                type="radio"
                id="rd2"
                name="range"
                className="radio2"
                value="2"
                defaultChecked={range === "2"}
                onChange={(e) => setRange(e.target.value)}
              />
              <label htmlFor="rd2">부분</label>
            </div>
          </div>

          <InputSelect
            name="style"
            label="스타일링 제품"
            value={style}
            setValue={setStyle}
            option={consts.style_buy}
          />
          <InputSelect
            name="style"
            label="철거"
            value={order_demolition_yn}
            setValue={setOrder_demolition_yn}
            option={[
              { idx: "", title: "미선택" },
              { idx: "y", title: "필요" },
              { idx: "n", title: "필요없음" },
            ]}
          />

          <div className="input_check_box input_check_box_long">
            <p className="input_label">시공정보</p>
            <div>
              {consts.tag_id.map((x, i) => {
                return (
                  <Fragment key={i}>
                    <input
                      type="checkbox"
                      id={"servechk" + i}
                      className="checkbox2 info"
                      value={x.idx}
                      data-text={x.title}
                    />
                    <label htmlFor={"servechk" + i}>{x.title}</label>
                  </Fragment>
                );
              })}
            </div>
          </div>

          <TextArea
            className="input_textarea"
            placeholder="내용을 입력해 주세요. (500자 이내)"
            name="estimate_msg"
            value={estimate_msg}
            setValue={setEstimate_msg}
            label="상담내용"
          />

          <TextArea
            className="input_textarea"
            placeholder="내용을 입력해 주세요. (500자 이내)"
            name="order_msg"
            value={order_msg}
            setValue={setOrder_msg}
            label="요청사항"
          />

          <InputFileMulti
            type="file"
            multiple={true}
            placeholder="입력해주세요."
            name="photoList"
            label="참고사진 (10개까지 등록 가능)"
            value={photoList}
            setValue={setPhotoList}
            defaulteImgvalue={detail_img}
            setImgValue={setDetail_img}
            valid="image"
            full={true}
          />
        </div>
      </div>

      <div className="popup_list_btn_box popup_pay_step">
        <button type="button" className="btn btn3" onClick={() => closeFunc()}>
          취소
        </button>
        <button type="button" className="btn btn1" onClick={() => submitFunc()}>
          확인
        </button>
      </div>
    </div>
  );
}
