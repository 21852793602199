import React, { useState } from "react";
import { css } from "@emotion/react";
import Table from "../../Common/Table/Table";
import Pagination from "../../Common/Pagination/Pagination";
import moment from "moment";
import Button from "../../Common/Button/Button";
import CreateHomeManagePick from "./Create";
import UpdateHomeManagePick from "./Update";
import useHomeManagePick, { List } from "./homeManagePick.hook";
import consts from "../../../libs/consts";
import Select from "../../Common/Select/Select";
import Input from "../../Common/Input/Input";
import useSeq from "../seq.hook";
import { getEventState, handlerImgError } from "../../../utils/home-manage";
import { CSVLink } from "react-csv";
import useCsv from "../../../hooks/useCsv";

const HomeManagePick = () => {
  const [state, setState] = useState("");
  const { list, page, total, search, updateSeq } = useHomeManagePick({
    isList: true,
    state,
  });
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [id, setId] = useState<string | undefined>();

  const { seqList, isUpdateSeq, updateSeqHandler, updateTableInputHandler } =
    useSeq<List>({
      list: list,
      key_id: "pick_table_id",
      key_seq: "display_seq",
    });

  const { csvLinkRef, getCsvHeader, downloadHandler } = useCsv();

  const changeSelectHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setState(value);
  };

  const onClickUpdateSeq = async (): Promise<void> => {
    const changedList = seqList.filter((item) => item.isChanged);

    if (changedList.length === 0) {
      alert("노출순위를 변경하는 데이터가 존재하지 않습니다.");
      return;
    }

    const params = changedList.map((item) => ({
      pick_table_id: item.id,
      display_seq: item.seq,
    }));

    const res = await updateSeq(params);

    if (res) {
      alert("변경이 완료 되었습니다.");
      updateSeqHandler(false);
      search();
    } else {
      alert("노출 순위 변경 중 오류가 발생하였습니다.");
    }
  };

  const col = [
    { key: "pick_table_id", title: "번호" },
    { key: "pick_name", title: "메뉴 명" },
    {
      key: "pick_img",
      title: "이미지",
      render: (data: any) => (
        <div
          css={css`
            width: 40px;
            height: 40px;
          `}
        >
          {data.pick_img && (
            <img
              src={data.pick_img}
              onError={handlerImgError}
              alt="pick_img"
              css={css`
                width: 100%;
                height: 100%;
              `}
            />
          )}
        </div>
      ),
    },
    {
      key: "update_date",
      title: "최종 수정 일시",
      render: (data: any) => (
        <span>{moment(data.update_date).format("YYYY.MM.DD HH:mm")}</span>
      ),
    },
    {
      key: "state",
      title: "상태",
      render: (data: any) => <span>{getEventState(data.state)}</span>,
    },
    {
      key: "display_seq",
      title: "노출 순위",
      className: "table-td-seq",
      render: (data: any) => (
        <div
          onClick={(e) => e.stopPropagation()}
          css={css`
            width: 100px;
          `}
        >
          {isUpdateSeq ? (
            <Input
              value={seqList.find((seq) => seq.id === data.pick_table_id)?.seq}
              onChange={(e) => updateTableInputHandler(data.pick_table_id, e)}
              size="full"
              placeholder="입력"
            />
          ) : (
            <span>{data.display_seq}</span>
          )}
        </div>
      ),
    },
  ];

  const callback = () => {
    setIsOpenCreate(false);
    setId(undefined);
    search();
  };

  return (
    <div>
      <div
        css={css`
          margin-top: 24px;
          display: flex;
          gap: 20px;
        `}
      >
        <div
          css={css`
            width: 200px;
          `}
        >
          <Select
            label="상태"
            defaultValue="전체"
            options={consts.notice_avt_state.map((item) => ({
              label: item.title,
              value: item.idx,
            }))}
            size="full"
            onChange={changeSelectHandler}
          />
        </div>
        <div
          css={css`
            margin: auto 0 0 0;
          `}
        >
          <Button theme="tertiary" onClick={search}>
            검색
          </Button>
        </div>
        <div
          css={css`
            margin: auto 0 0 0;
          `}
        >
          <Button theme="tertiary" onClick={() => downloadHandler()}>
            엑셀 다운로드
          </Button>
          <CSVLink
            data={list.map((l) => ({
              ...l,
              state: getEventState(l.state),
            }))}
            ref={csvLinkRef}
            headers={getCsvHeader(col)}
            filename={
              "홈화면관리_픽메뉴_" +
              moment(new Date()).format("YYYY_MM_DD(HH)") +
              ".csv"
            }
          />
        </div>
        <div
          css={css`
            margin: auto 0 0 auto;
          `}
        >
          <Button onClick={() => setIsOpenCreate(true)}>등록</Button>
        </div>
      </div>
      <div
        css={css`
          margin: 30px 0;

          .table-td-seq {
            width: 140px;
          }
        `}
      >
        <div
          css={css`
            margin-bottom: 10px;
            display: flex;
            justify-content: end;
          `}
        >
          {isUpdateSeq ? (
            <div
              css={css`
                display: flex;
                gap: 20px;
              `}
            >
              <Button
                theme="tertiary"
                size="small"
                onClick={() => updateSeqHandler(false)}
              >
                취소
              </Button>
              <Button size="small" onClick={onClickUpdateSeq}>
                저장
              </Button>
            </div>
          ) : (
            <Button size="small" onClick={() => updateSeqHandler(true)}>
              노출순위 변경
            </Button>
          )}
        </div>
        <Table
          columns={col}
          datas={list}
          rowCallback={(data) => setId(data.pick_table_id)}
        />
      </div>
      <Pagination curPage={page} total={total} pageSize={20} />
      {isOpenCreate && (
        <CreateHomeManagePick
          close={() => setIsOpenCreate(false)}
          callback={callback}
        />
      )}
      {id && (
        <UpdateHomeManagePick
          id={id}
          close={() => setId(undefined)}
          callback={callback}
        />
      )}
    </div>
  );
};

export default HomeManagePick;
