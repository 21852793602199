/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getDetailItem } from "../../../apis/adminLifeOrder";

const useDetail = (id) => {
  const [item, setItem] = useState(null);

  const userData = useSelector((s) => s.usersReducer);

  useEffect(() => {
    getDetail();
  }, [id]);

  const getDetail = async () => {
    try {
      const params = {
        admin_table_id: userData.admin_table_id,
        life_order_table_id: id,
      };

      const res = await getDetailItem(params);
      setItem(res);
    } catch (err) {
      console.log(err);
    }
  };

  return {
    item,
  };
};

export default useDetail;
