import React, { useState } from "react";
import styled from "styled-components";
import Modal from "../../Common/ModalPortal";
import images from "../../../libs/images";
import FindPartnerModal from "./FindPartnerModal";
import InputDate from "../../InputDate";
import Input from "../../Input";
import * as APIS from "../../../utils/service";

const Container = styled.div`
  width: 760px;
  background-color: #ffffff;
  margin: auto;
`;

const Header = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;

  & > h3 {
    margin: auto 0;
  }
`;

const Content = styled.div`
  padding: 20px;

  .mt {
    margin-top: 20px;
  }
`;

const InputWithButton = styled.div`
  margin-bottom: 20px;

  & > p {
    font-size: 12px;
    font-weight: bold;
    line-height: 1.5;
    color: #8f9bb3;
    display: inline-block;
    width: 100%;
  }

  & > div {
    height: 48px;
    margin-top: 6px;
    position: relative;

    & > input {
      border-right: none !important;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      width: calc(100% - 103px);
      display: inline-block;
      height: 100%;
      padding: 0 14px;
      border: solid 1px #e4e9f2;
      font-size: 16px;

      &:disabled {
        background-color: #fafafa;
        color: #909090;
      }
    }

    & > button {
      width: 103px;
      border: 1px solid #e4e9f2;
      background-color: #fff;
      height: 100%;
      font-size: 16px;
      color: #718096;
    }
  }
`;

const Footer = styled.div`
  padding: 20px;
  border-top: 1px solid #e1e1e1;
  text-align: center;

  .footer_modal {
    width: 145px !important;
    height: 48px;
    line-height: 48px;

    &.exit {
      background-color: #fff;
      color: #718096;
      font-size: 14px;
      font-weight: bold;
      border: solid 1px #e4e9f2;
      transition: border 0.3s, color 0.3s;
    }
    &.submit {
      background-color: #066e4d;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      transition: background-color 0.3s;
      margin-left: 15px;
    }
  }
`;

const CreateSafeNumberModal = (props) => {
  const { close, submitCallback } = props;
  const [seller_info, setSeller_info] = useState({
    seller_table_id: "",
    seller_biz_name: "",
    seller_phone: "",
  });
  const [isOpenFind, setIsOpenFind] = useState(false);
  const [date, setDate] = useState("");
  const [memo, setMemo] = useState("");

  const updateSeller = (data) => {
    setSeller_info(data);
  };

  const submitHandler = async () => {
    const { seller_phone, seller_table_id } = seller_info;

    if (!seller_phone || !seller_table_id || !date) {
      alert("필수 정보를 입력해주세요.");
      return;
    }

    try {
      const params = {
        seller_table_id: seller_table_id, // 판매자 고유키
        tel_num1: seller_phone, // 매칭번호 1 (판매자 전화번호)
        expire_date: `${date} 23:59:59`, // 유효기간
        s_memo: memo, // 메모
      };
      const res = await APIS.postData("/AdminSafety/SafetyUpdate", params, 1);

      if (res.data.code === "OK") {
        alert("안심번호가 등록되었습니다.");
        submitCallback();
        close();
      } else {
        alert("알수 없는 문제가 발생하였습니다. 다시 시도 해주세요.");
        return;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const now = new Date();

  const sixMonthLater = new Date(now.setMonth(now.getMonth() + 6));

  return (
    <Modal>
      <Container>
        <Header>
          <h3>안심번호 등록</h3>
          <button onClick={close}>
            <img src={images.exit} alt="exit_btn" />
          </button>
        </Header>
        <Content>
          <InputWithButton>
            <p>* 파트너ID</p>
            <div>
              <input
                type="text"
                value={seller_info.seller_biz_name}
                disabled
                placeholder="파트너 찾기를 클릭해 주세요."
              />
              <button onClick={() => setIsOpenFind(true)}>파트너 찾기</button>
            </div>
          </InputWithButton>
          <InputDate
            type="text"
            placeholder="유효기간"
            name="ipd1"
            label="* 유효기간"
            dateValue={date}
            setDateValue={setDate}
            maxDate={sixMonthLater}
          />
          <div className="mt">
            <Input
              type="text"
              label="메모"
              value={memo}
              setValue={setMemo}
              placeholder="메모를 입력해주세요. (최대 32자까지)"
              maxlength={32}
            />
          </div>
        </Content>
        <Footer>
          <button className="footer_modal exit" onClick={close}>
            취소
          </button>
          <button className="footer_modal submit" onClick={submitHandler}>
            확인
          </button>
        </Footer>
      </Container>

      {isOpenFind && (
        <FindPartnerModal
          close={() => setIsOpenFind(false)}
          submit={updateSeller}
        />
      )}
    </Modal>
  );
};

export default CreateSafeNumberModal;
