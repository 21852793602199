const exportData = {
  /* 공용 */
  clause: "/clause",
  PGCallBack: "/PGCallBack",

  /* 파트너 */
  main: "/dashboard",
  no: "/no",
  instance: "/instance",
  estimate: "/estimate",
  pay: "/pay",
  payManage: "/payManage",
  day: "/day",
  schedule: "/schedule",
  user: "/user",
  login: "/login",
  resetPw: "/resetPw",
  register: "/register",
  my: "/my",
  membership: "/membership",
  acc: "/acc",

  /* 관리자 */
  admin: "/admin",
  partner: "/partner",
  partnerDetailRoute: "/partnerDetail",
  partnerDetail: "/partnerDetail/:vidx",
  theme: "/theme",
  admEstimate: "/admEstimate",
  estimateDetailRoute: "/estimateDetail",
  estimateDetail: "/estimateDetail/:vidx",
  setting: "/setting",
  order: "/order",
  admPay: "/admPay",
  real: "/real",
  as: "/as",
  notice: "/notice",
  notice_partner: "/notice/partner",
  notice_user: "/notice/user",
  notice_event: "/notice/event",
  notice_ad: "/notice/ad",
  notice_url: "/notice/url",
  logs: "/logs",
  life_construction: "/life-construction", // 생활시공 관리
  safe_numbers: "/safe-numbers",
  home_manage_hero: "/home-manage/hero", // 히어로 배너
  home_manage_pick: "/home-manage/pick", // 픽 메뉴
  home_manage_recommend: "/home-manage/recommend", // 추천 컨텐츠
  home_manage_monthly: "/home-manage/monthly", // 월간 인기 시공사례
  home_manage_line_banner: "/home-manage/line-banner", //띠 배너
  home_manage_center_banner: "/home-manage/center-banner", // 중앙 배너
  home_manage_popup: "/home-manage/popup", // 팝업
  home_manage_life_monthly: '/home-manage/life_monthly', // 생활시공 / 이달의 공구
  /* 이벤트 */
  event: "/event",
  event_home: "/event/home",
  event_aptn: "/event/aptn",
  event_hanssem: "/event/hanssem",
  event_aptn_counsel: "/event/aptn-counsel",
  event_db: "/event/db",
  /** 회원 관리 */
  member: "/member",
};

export default exportData;
