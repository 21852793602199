/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as APIS from "../../utils/service";
import { open, close } from "../../redux/popupSlice";
import InputSelect from "../../components/InputSelect";
import PaySuccess from "../../components/PaySuccess";
import consts from "../../libs/consts";
import { findJson, numFormat } from "../../utils/utils";

export default function PayMembership(props) {
  const { idx } = props;
  const dispatch = useDispatch();
  const userData = useSelector((s) => s.usersReducer);
  const seller_table_id = idx ? idx : userData.seller_table_id;

  const [sid, setSid] = useState("1");
  const [grade, setGrade] = useState("");
  const [day, setDay] = useState("");
  const [set, setSet] = useState("");
  const [optionLabel, setOptionLabel] = useState("");
  const [optionPrice, setOptionPrice] = useState("");
  const [optionPriceSet, setOptionPriceSet] = useState("");

  const [type, setType] = useState("2");
  const [payType, setPayType] = useState("1");

  const [gradeoption, setGradeOption] = useState("");
  const [gradeoptionori, setGradeOptionori] = useState("");

  const [dayoption, setDayOption] = useState("");
  const [setoption, setSetOption] = useState("");

  useEffect(() => {
    const sender = {
      admin_table_id: userData.admin_table_id,
      seller_table_id: seller_table_id,
    };

    APIS.postData("/SetAdmin/GetSub", sender, 2)
      .then((res) => {
        if (res.data.fix && res.data.fix.length > 0) {
          setSetOption(
            res.data.fix.map((x, i) => {
              return { ...x, idx: x.fix_table_id, title: x.count };
            })
          );
        }
        if (res.data.sub && res.data.sub.length > 0) {
          setGradeOptionori(
            res.data.sub.map((x, i) => {
              return { ...x, idx: x.sub_table_id, title: x.sub_type };
            })
          );
          setGradeOption([
            ...new Set(
              res.data.sub.map((x, i) => {
                return x.sub_type;
              })
            ),
          ]);
        } else {
          setType("3");
        }
      })
      .catch((e) => {
        console.log("catch", e);
      });
  }, []);

  useEffect(() => {
    if (gradeoption) {
      setGrade(gradeoption[0]);
    }
  }, [gradeoption]);

  useEffect(() => {
    if (setoption) {
      setSet(setoption[0].idx);
    }
  }, [setoption]);

  useEffect(() => {
    if (grade) {
      setDayOption(
        gradeoptionori
          .filter((item) => item.title === grade)
          .map((x, i) => {
            return { ...x, idx: x.sub_table_id, title: x.period_name };
          })
      );
    }
  }, [grade]);

  useEffect(() => {
    if (dayoption) {
      setDay(dayoption[0].idx);
    }
  }, [dayoption]);

  useEffect(() => {
    if (day) {
      setOptionLabel(
        gradeoptionori
          .filter((item) => item.idx === day)
          .map((x, i) => {
            return `${x.unit}${findJson(
              consts.day_option_view,
              x.unit_type
            )} /${numFormat(x.ea)}회 제공`;
          })
      );
      setOptionPrice(
        gradeoptionori
          .filter((item) => item.idx === day)
          .map((x, i) => {
            return `${numFormat(x.price)}만원`;
          })
      );
      //setDayOption(gradeoptionori.filter(item => item.title === grade).map((x, i) => { return x.period_name } ) );
    }
  }, [day]);

  useEffect(() => {
    if (set) {
      setOptionPriceSet(
        setoption
          .filter((item) => item.idx === set)
          .map((x, i) => {
            return `${numFormat(x.price)}만원`;
          })
      );
    }
  }, [set]);

  const setIdFunc = (id) => {
    if (sid === id) setSid("");
    else setSid(id);
  };

  const paySucessFunc = () => {
    let sender;

    if (type === "2") {
      const sel = gradeoptionori.filter((item) => item.idx === day)[0];
      sender = {
        seller_table_id: seller_table_id,
        payment_type: type,
        cnt: sel.period_name === "분기" ? sel.ea * 3 : sel.ea,
        price: sel.price,
        grade: sel.sub_type,
        period: sel.unit,
        period_type: sel.unit_type,
        income_type: payType,
      };
    } else {
      const sel = setoption.filter((item) => item.idx === set)[0];

      sender = {
        seller_table_id: seller_table_id,
        payment_type: type,
        cnt: sel.count,
        price: sel.price,
        income_type: payType,
      };
    }

    APIS.postData("/Membership/BuyMember", sender)
      .then(() => {
        dispatch(close());
        setTimeout(() => {
          dispatch(
            open({
              title: " ",
              suc: true,
              component: (
                <PaySuccess
                  title={"구매가 완료되었습니다."}
                  msg={"안내된 계좌로 입금하시면 서비스\n이용이 가능합니다."}
                />
              ),
              button: "확인",
            })
          );
        }, 50);
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };

  return (
    <div className="popup_list">
      <div className="popup_list_ul popup_pay_step">
        <p className="sub_msg">
          파트너님 시공 환경에 맞게 다양한 상품을 제공합니다.
          <br />
          레이홈은 타사 대비 최저 시공 수수료율을 보장해 드립니다.
        </p>
        <li className={sid === "1" ? "active" : ""}>
          <div className="title_box" onClick={() => setIdFunc("1")}>
            <p className="dy_title">
              <span>STEP1.</span> 요금제 선택
            </p>
            <p className="sub_title">
              {type === "2"
                ? "월 단위로 정해진 수량만큼 요금제를 선택할 수 있습니다."
                : "기간 제한없이 구매한 수량만큼 결제할 수 있습니다."}
            </p>
          </div>
          <div className="content_box">
            <div className="radio_box">
              {consts.paymentoption
                .filter((item) => item.idx !== "1")
                .map((x, i) => {
                  return (
                    <Fragment key={i}>
                      <input
                        type="radio"
                        id={"rd" + i}
                        name="type"
                        className="radio"
                        value={x.idx}
                        checked={type === x.idx}
                        onChange={(e) => setType(e.target.value)}
                      />
                      <label htmlFor={"rd" + i}>{x.title}</label>
                    </Fragment>
                  );
                })}
            </div>
            <button
              type="button"
              className="btn arrow_btn"
              onClick={() => setSid("2")}
            >
              다음 단계
            </button>
          </div>
        </li>

        {type === "2" ? (
          <li className={sid === "2" ? "active" : ""}>
            <div className="title_box" onClick={() => setIdFunc("2")}>
              <p className="dy_title">
                <span>STEP2.</span> 요금등급 선택
              </p>
              <p className="sub_title">
                월 고객 상담요청정보 제공 건수와 기간을 선택해 주세요.
              </p>
            </div>
            <div className="content_box">
              <InputSelect
                name="grade"
                label="등급"
                value={grade}
                setValue={setGrade}
                optionNotKey={gradeoption}
                optionLabel={optionLabel}
              />
              {dayoption && (
                <InputSelect
                  name="grade"
                  label="사용기간"
                  value={day}
                  setValue={setDay}
                  option={dayoption}
                />
              )}
              <div className="popup_pay_total">
                <p className="title">총 금액</p>
                <p className="price">
                  <span>{optionPrice}</span> (부가세포함)
                </p>
              </div>
              <button
                type="button"
                className="btn arrow_btn"
                onClick={() => setSid("3")}
              >
                다음 단계
              </button>
            </div>
          </li>
        ) : (
          <li className={sid === "2" ? "active" : ""}>
            <div className="title_box" onClick={() => setIdFunc("2")}>
              <p className="dy_title">
                <span>STEP2.</span> 결제수량 선택
              </p>
              <p className="sub_title">
                고객 상담요청정보 제공 건수를 선택해 주세요.
              </p>
            </div>
            <div className="content_box">
              <InputSelect
                name="set"
                label="수량"
                value={set}
                setValue={setSet}
                option={setoption}
              />
              <p className="set_read">
                사용기간<span>유효기간 1년</span>
              </p>
              <div className="popup_pay_total">
                <p className="title">총 금액</p>
                <p className="price">
                  <span>{optionPriceSet}</span> (부가세포함)
                </p>
              </div>
              <button
                type="button"
                className="btn arrow_btn"
                onClick={() => setSid("3")}
              >
                다음 단계
              </button>
            </div>
          </li>
        )}

        <li className={sid === "3" ? "step3 active" : "step3"}>
          <div className="title_box" onClick={() => setIdFunc("3")}>
            <p className="dy_title">
              <span>STEP3.</span> 결제방법 선택
            </p>
            <p className="sub_title">
              {payType === "1"
                ? "지정된 입금계좌로 결제금액을 이체해 주시면 구매처리가 완료됩니다."
                : "CMS 자동결제를 신청하시면 번거로운 절차 없이\n매월 자동으로 입력하신 통장에서 결제가 진행됩니다."}
            </p>
          </div>
          <div
            className={payType === "1" ? "content_box" : "content_box top_more"}
          >
            <div className="radio_box">
              <input
                type="radio"
                id="rd113"
                name="payType"
                className="radio"
                value="1"
                defaultChecked={payType === "1"}
                onChange={(e) => setPayType(e.target.value)}
              />
              <label htmlFor="rd113">계좌이체</label>
              <input
                type="radio"
                id="rd114"
                name="payType"
                className="radio"
                value="4"
                defaultChecked={payType === "4"}
                onChange={(e) => setPayType(e.target.value)}
              />
              <label htmlFor="rd114">CMS자동결제</label>
            </div>

            {payType === "1" ? (
              <>
                <ul className="list_style3">
                  <li>
                    <p className="title">입금금액</p>
                    <p className="content">
                      {type === "2" ? optionPrice : optionPriceSet} (부가세포함)
                    </p>
                  </li>
                  <li>
                    <p className="title">입금계좌</p>
                    <p className="content">
                      하나은행 374-910029-16904 / ㈜두꺼비세상
                    </p>
                  </li>
                </ul>
                <div className="step3_msg_box">
                  <p className="step3_msg">
                    *영업일 10시~17시에 입금 확인 후 일괄 반영됩니다.
                    <br />
                    (ex. 금요일 18시에 입금 하신 경우 월요일 10시에 반영)
                  </p>
                  {type === "2" ? (
                    <p className="step3_msg cl_3">
                      [주의사항] 30일동안 미사용 한 횟수는 이월되지 않습니다.
                    </p>
                  ) : (
                    <p className="step3_msg cl_3">
                      [주의사항] 입금확인 후 1년 동안 유효합니다.
                    </p>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="step3_msg_box">
                  <p className="step3_msg cl_3">
                    신청서 작성을 위해 담당자가 방문드릴 예정입니다.
                  </p>
                </div>
              </>
            )}
          </div>
        </li>
      </div>

      <div className="popup_list_btn_box popup_pay_step">
        <button
          type="button"
          className="btn btn3"
          onClick={() => dispatch(close())}
        >
          취소
        </button>
        <button
          type="button"
          className="btn btn1"
          onClick={() => {
            sid * 1 < 3 ? setIdFunc(sid * 1 + 1 + "") : paySucessFunc();
          }}
        >
          구매
        </button>
      </div>
    </div>
  );
}
