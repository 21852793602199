import { createSlice } from "@reduxjs/toolkit";

const joinSlice = createSlice({
  name: "join",
  initialState: {
    pwStack: 0,
    pwTimer: false,
    pwTime: 0,
    kakaoId: "",
    name: "",
    phone: "",
  },
  reducers: {
    stackUpdate(state, action) {
      state.pwStack = action.payload.pwStack;
    },
    pwTimerOn(state, action) {
      state.pwTime = action.payload.pwTime;
      state.pwTimer = true;
    },
    pwTimerOff(state, action) {
      state.pwTimer = false;
      state.pwStack = 0;
      state.pwTime = 0;
    },
    setKakao(state, action) {
      state.kakaoId = action.payload.kakaoId;
    },
    setAuth(state, action) {
      state.name = action.payload.name;
      state.phone = action.payload.phone;
    },
  },
});

export const { stackUpdate, pwTimerOn, pwTimerOff, setKakao, setAuth } =
  joinSlice.actions;
export default joinSlice.reducer;
