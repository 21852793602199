import React from "react";
import AdminLayout from "../../../components/Layout/admin";
import useEventTabs from "./event.hook";
import EventDb from "../../../components/Event/Db";

const EventDbPage = () => {
  const { tabs } = useEventTabs();

  return (
    <AdminLayout title="이벤트 접수 현황" tabs={tabs}>
      <EventDb />
    </AdminLayout>
  );
};

export default EventDbPage;
