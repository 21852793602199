/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import * as APIS from "../../../utils/service";
import { useSelector } from "react-redux";
import debounce from "lodash/debounce";
import { useNavigate } from "react-router-dom";
import { InfoType, Item } from "./noticeAd.type";

interface AreaType {
  area_id: string;
  area_name: string;
  area_2nd: {
    area_detail_id: string;
    area_detail_name: string;
  }[];
}

interface List {
  ad_table_id: string;
  seller_biz_name: string;
  area1: string;
  area2: string;
  ad_days: string;
  remain_day: string;
  update_date: string;
  ad_state: string;
}

interface ParamType extends InfoType {
  area_list: {
    area1: string;
    area2: string;
    area_picture: string;
    id?: string | number;
  }[];
}

interface Props {
  isList?: boolean;
  searchData?: {
    ad_state?: string;
    start_date?: string;
    end_date?: string;
    area1?: string;
    area2?: string;
    seller_biz_name?: string;
  };
  id?: string;
}

const useNoticeAd = (props?: Props) => {
  const { isList, searchData, id } = props ?? {};

  const userData = useSelector((s: any) => s.usersReducer);
  const navigate = useNavigate();
  const { pathname } = window.location;

  const [area, setArea] = useState<AreaType[]>([]);

  const [list, setList] = useState<List[]>([]);
  const [total, setTotal] = useState(0);

  const params = new URLSearchParams(window.location.search);
  const page = params.get("page") || "1";

  const [detail, setDetail] = useState<Item>();

  useEffect(() => {
    getArea();
  }, []);

  const getList = async (page: string) => {
    const params = {
      admin_table_id: userData.admin_table_id,
      page: page,
      ...searchData,
    };

    try {
      const res = await APIS.postData("/AdminNotice/AdList", params, 1);

      if (res.data.code === "OK") {
        setList(res.data.ad_list);
        setTotal(res.data.total_cnt);
      } else {
        setList([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (isList) {
      getList(page);
    }
  }, [page]);

  const getArea = async () => {
    try {
      const res = await APIS.postData("/AreaInfo/GetAreaOne", "", 2);

      const { area } = res.data;
      const data = Object.values(area);

      setArea(data as AreaType[]);
    } catch (err) {
      console.log(err);
    }
  };

  const search = debounce(() => {
    if (page === "1") {
      getList("1");
    } else {
      navigate({
        pathname: pathname,
        search: `?page=${1}`,
      });
    }
  }, 200);

  const getTarget = async (): Promise<void> => {
    try {
      const res = await APIS.postData("/AdminNotice/AdTarget", {
        admin_table_id: userData.admin_table_id,
        ad_table_id: id,
      });

      if (res.data.code === "OK") {
        const { data } = res;
        delete data.code;

        setDetail(data);
      } else {
        setDetail(undefined);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (id) {
      getTarget();
    }
  }, [id]);

  const create = async (data: ParamType): Promise<boolean | undefined> => {
    const { seller_table_id, ad_start_date, ad_end_date, area_list } = data;

    if (!seller_table_id || !ad_start_date || !ad_end_date) {
      alert("기본 정보를 입력해주세요.");
      return;
    }

    // 각 지역 섹션에서 모두 입력 된 리스트
    const fillArea = area_list
      .filter((item) => item.area1 && item.area2 && item.area_picture)
      .map((item) => ({
        area1: item.area1,
        area2: item.area2,
        area_picture: item.area_picture,
      }));

    if (fillArea.length < 1) {
      alert("지역 노출 정보를 입력해주세요.");
      return;
    }

    // 각 섹션에서, 모두 입력이 안되어 있고, (도/시, 시/군/구, 이미지) 중 비어 있는 리스트
    const notFillArea = area_list.filter(
      (item) =>
        (item.area1 || item.area2 || item.area_picture) &&
        !(item.area1 && item.area2 && item.area_picture)
    );

    if (notFillArea.length > 0) {
      alert(
        `${notFillArea
          .map((item) => {
            return `지역 ${item.id}`;
          })
          .join(",")} 의 정보를 입력해주세요.`
      );
      return;
    }

    try {
      const params = {
        admin_table_id: userData.admin_table_id,
        ...data,
        area_list: fillArea,
        ad_state: "1",
      };

      const res = await APIS.postData("/AdminNotice/AdCreate", params, 1);

      if (res.data.code === "OK") {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const getImage = (img?: string): string => {
    if (!img) {
      return "";
    }

    const isImage = img.includes("data:image");
    return isImage ? img : "";
  };

  const update = async (data: Item): Promise<boolean | undefined> => {
    const { ad_start_date, ad_end_date, area_list } = data;

    if (!ad_start_date || !ad_end_date) {
      alert("기본 정보를 입력해주세요.");
      return;
    }

    // 각 지역 섹션에서 모두 입력 된 리스트
    const fillArea = area_list.filter(
      (item) => item.area1 && item.area2 && item.area_picture
    );

    if (fillArea.length < 1) {
      alert("지역 노출 정보를 입력해주세요.");
      return;
    }

    // 각 섹션에서, 모두 입력이 안되어 있고, (도/시, 시/군/구, 이미지) 중 비어 있는 리스트
    const notFillArea = area_list.filter(
      (item) =>
        (item.area1 || item.area2 || item.area_picture) &&
        !(item.area1 && item.area2 && item.area_picture)
    );

    if (notFillArea.length > 0) {
      alert(
        `${notFillArea
          .map((item) => {
            return `지역 ${item.ad_area_seq}`;
          })
          .join(",")} 의 정보를 입력해주세요.`
      );
      return;
    }

    const deleteArea = area_list.filter(
      (item) =>
        item.ad_area_seq &&
        !item.area1 &&
        !item.area2 &&
        !item.area_picture &&
        !item.isCreate
    );

    try {
      const params = {
        admin_table_id: userData.admin_table_id,
        ...data,
        area_list: fillArea.map((item) => {
          const data = {
            area1: item.area1,
            area2: item.area2,
            area_picture: getImage(item.area_picture),
            ...(!item.isCreate && { ad_area_seq: item.ad_area_seq }),
          };
          return data;
        }),
        del_area_seqs: deleteArea.map((item) => item.ad_area_seq),
      };

      const res = await APIS.postData("/AdminNotice/AdUpdate", params, 1);

      if (res.data.code === "OK") {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  return {
    area: area,
    list,
    total,
    page,
    search,
    create,
    update,
    detail,
  };
};

export default useNoticeAd;
