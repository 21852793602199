import { css } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import routes from "../../../libs/routes";
import { open, close } from "../../../redux/popupSlice";
import { logOut } from "../../../redux/usersSlice";
import logo from "../../../assets/images/logo5.svg";
import right_white from "../../../assets/images/right_white.svg";
import * as Colors from "../../../constants/color";

const headerStyles = css`
  background-color: ${Colors.primary};
  width: 100%;
  height: 52px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  z-index: 999;
`;

const logoStyles = css`
  margin: auto 0 auto 18px;

  & > img {
    width: 74px;
    height: 24px;
  }
`;

const buttonStyles = css`
  background-color: #01543a;
  color: #fff;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 700;
  height: 24px;
  letter-spacing: normal;
  line-height: 24px;
  padding: 0 8px;
  text-align: center;
  margin: auto 20px;
`;

const AdminHeader = () => {
  const dispatch = useDispatch();
  const userData = useSelector((s: any) => s.usersReducer);
  const navigate = useNavigate();

  const logOutFunc = () => {
    dispatch(
      open({
        message: "로그아웃 하시겠습니까?",
        button: "확인",
        buttonCencle: "취소",
        onCancelPress: dispatch(close()),
        onPress: () => {
          dispatch(logOut());
          navigate(routes.admin);
        },
      })
    );
  };

  return (
    <div css={[headerStyles]}>
      <Link to="#" css={[logoStyles]}>
        <img src={logo} alt="logo" />
      </Link>
      <div
        css={css`
          display: flex;
        `}
      >
        <Link
          to="#"
          css={css`
            margin: auto 0;
          `}
        >
          <div
            css={css`
              display: flex;
            `}
          >
            <p
              css={css`
                margin: auto 0;
                color: ${Colors.white};
              `}
            >
              {userData.mbName}
            </p>
            <img src={right_white} alt="right_white" />
          </div>
        </Link>
        <button css={[buttonStyles]} onClick={() => logOutFunc()}>
          로그아웃
        </button>
      </div>
    </div>
  );
};

export default AdminHeader;
