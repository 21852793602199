import { useNavigate } from "react-router-dom";
import routes from "../../../libs/routes";

const useEventTabs = () => {
  const navigate = useNavigate();
  const { pathname } = window.location;

  const tabs = [
    {
      id: 1,
      title: "레이홈",
      isActive: pathname === routes.event_home,
      callback: () => {
        navigate(routes.event_home);
      },
    },
    {
      id: 2,
      title: "아파트너",
      isActive: pathname === routes.event_aptn,
      callback: () => {
        navigate(routes.event_aptn);
      },
    },
    {
      id: 3,
      title: "한샘기획전",
      isActive: pathname === routes.event_hanssem,
      callback: () => {
        navigate(routes.event_hanssem);
      },
    },
    {
      id: 4,
      title: "아파트너 상담",
      isActive: pathname === routes.event_aptn_counsel,
      callback: () => {
        navigate(routes.event_aptn_counsel);
      },
    },
    {
      id: 5,
      title: "이벤트/컨텐츠 DB 모음",
      isActive: pathname === routes.event_db,
      callback: () => {
        navigate(routes.event_db);
      },
    },
  ];

  return {
    tabs,
  };
};

export default useEventTabs;
