import React from "react";
import { findJson } from "../../utils/utils";
import consts from "../../libs/consts";

export default function CounselDetail(props) {
  const { rows } = props;

  return (
    <div
      className="form_list"
      style={{
        overflow: "auto",
        height: "50vh",
        width: "100%",
        wordBreak: "break-word",
        padding: "10px",
      }}
    >
      <table className="tableStyle1">
        <thead>
          <tr>
            <th className="wd80">시공 항목</th>
            <th className="wdFull">내용</th>
          </tr>
        </thead>
        <tbody>
          {rows.length > 0 ? (
            rows.map((x, i) => {
              return (
                <tr key={i}>
                  <td>{findJson(consts.tag_id, x.counsel)}</td>
                  <td>{x.counsel_desc}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td className="null_td" colSpan={3}>
                데이터가 없습니다.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
