import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "users",
  initialState: {
    isReload: false,
    isLoggedIn: false,
    mbId: "",
    mbName: "",
    mbProfile: "",
    admin_table_id: "",
    grade: "",
    kakao_auth_key: "",
    seller_addr: "",
    seller_addr_detail: "",
    seller_area: "",
    seller_area_text: "",
    seller_as: "",
    seller_biz_no: "",
    seller_biz_name: "",
    seller_career: "",
    seller_desc: "",
    seller_email: "",
    seller_grade: "",
    seller_icon: "",
    seller_id: "",
    seller_license_yn: "",
    seller_name: "",
    seller_passwd: "",
    seller_phone: "",
    seller_service: "",
    seller_state: "",
    seller_table_id: "",
    update_date: "",
    work_kind: "",
    market_agree: "",
    market_agree_date: "",
    reg_date: "",
    service_state: "",
    alarm_yn: "",
    seller_intro: "",
  },
  reducers: {
    logIn(state, action) {
      state.isLoggedIn = true;
      state.mbId = action.payload.mbId;
      state.mbName = action.payload.mbName;
      state.mbProfile = action.payload.mbProfile;

      if (action.payload.admin_table_id)
        state.admin_table_id = action.payload.admin_table_id;
      if (action.payload.grade) state.grade = action.payload.grade; // 파트너 or 관리자 확인용

      if (action.payload.kakao_auth_key)
        state.kakao_auth_key = action.payload.kakao_auth_key;
      if (action.payload.seller_addr_detail)
        state.seller_addr_detail = action.payload.seller_addr_detail;
      if (action.payload.seller_addr)
        state.seller_addr = action.payload.seller_addr;
      if (action.payload.seller_area)
        state.seller_area = action.payload.seller_area;
      if (action.payload.seller_area_text)
        state.seller_area_text = action.payload.seller_area_text;
      if (action.payload.seller_as) state.seller_as = action.payload.seller_as;
      if (action.payload.seller_biz_no)
        state.seller_biz_no = action.payload.seller_biz_no;
      if (action.payload.seller_biz_name)
        state.seller_biz_name = action.payload.seller_biz_name;

      if (action.payload.seller_career)
        state.seller_career = action.payload.seller_career;
      if (action.payload.seller_desc)
        state.seller_desc = action.payload.seller_desc;
      if (action.payload.seller_email)
        state.seller_email = action.payload.seller_email;
      if (action.payload.seller_grade)
        state.seller_grade = action.payload.seller_grade;
      if (action.payload.seller_icon)
        state.seller_icon = action.payload.seller_icon;
      if (action.payload.seller_id) state.seller_id = action.payload.seller_id;
      if (action.payload.seller_license_yn)
        state.seller_license_yn = action.payload.seller_license_yn;
      if (action.payload.seller_name)
        state.seller_name = action.payload.seller_name;
      if (action.payload.seller_passwd)
        state.seller_passwd = action.payload.seller_passwd;
      if (action.payload.seller_phone)
        state.seller_phone = action.payload.seller_phone;
      if (action.payload.seller_service)
        state.seller_service = action.payload.seller_service;
      if (action.payload.seller_state)
        state.seller_state = action.payload.seller_state;
      if (action.payload.seller_table_id)
        state.seller_table_id = action.payload.seller_table_id;
      if (action.payload.update_date)
        state.update_date = action.payload.update_date;
      if (action.payload.work_kind) state.work_kind = action.payload.work_kind;

      if (action.payload.market_agree)
        state.market_agree = action.payload.market_agree;
      if (action.payload.market_agree_date)
        state.market_agree_date = action.payload.market_agree_date;
      if (action.payload.reg_date) state.reg_date = action.payload.reg_date;
      if (action.payload.service_state)
        state.service_state = action.payload.service_state;
      if (action.payload.alarm_yn) {
        state.alarm_yn = action.payload.alarm_yn;
      }
      if (action.payload.seller_intro)
        state.seller_intro = action.payload.seller_intro;
    },
    logOut(state) {
      state.isLoggedIn = false;
      state.mbId = "";
      state.mbName = "";
      state.mbProfile = "";
      state.grade = "";
      state.admin_table_id = "";

      state.kakao_auth_key = "";
      state.seller_addr = "";
      state.seller_addr_detail = "";
      state.seller_area = "";
      state.seller_area_text = "";
      state.seller_as = "";
      state.seller_biz_no = "";
      state.seller_biz_name = "";

      state.seller_career = "";
      state.seller_desc = "";
      state.seller_email = "";
      state.seller_grade = "";
      state.seller_icon = "";
      state.seller_id = "";
      state.seller_license_yn = "";
      state.seller_name = "";
      state.seller_passwd = "";
      state.seller_phone = "";
      state.seller_service = "";
      state.seller_state = "";
      state.seller_table_id = "";
      state.update_date = "";
      state.work_kind = "";

      state.market_agree = "";
      state.market_agree_date = "";
      state.reg_date = "";
      state.service_state = "";
      state.alarm_yn = "";
      state.seller_intro = "";
    },

    setData(state, action) {
      state[action.payload.key] = action.payload.value;
    },

    reload(state, action) {
      state.isReload = false;

      if (action.payload.data.seller_career)
        state.mbProfile = action.payload.data.seller_icon;
      if (action.payload.data.seller_addr)
        state.seller_addr = action.payload.data.seller_addr;
      if (action.payload.data.seller_addr_detail)
        state.seller_addr_detail = action.payload.data.seller_addr_detail;
      if (action.payload.data.seller_area)
        state.seller_area = action.payload.data.seller_area;
      if (action.payload.data.seller_area_text)
        state.seller_area_text = action.payload.data.seller_area_text;
      if (action.payload.data.seller_as)
        state.seller_as = action.payload.data.seller_as;
      if (action.payload.data.seller_biz_name)
        state.seller_biz_name = action.payload.data.seller_biz_name;
      if (action.payload.data.seller_biz_no)
        state.seller_biz_no = action.payload.data.seller_biz_no;
      if (action.payload.data.seller_career)
        state.seller_career = action.payload.data.seller_career;
      if (action.payload.data.seller_desc)
        state.seller_desc = action.payload.data.seller_desc;
      if (action.payload.data.seller_email)
        state.seller_email = action.payload.data.seller_email;
      if (action.payload.data.seller_icon)
        state.seller_icon = action.payload.data.seller_icon;

      if (action.payload.data.seller_id)
        state.seller_id = action.payload.data.seller_id;
      if (action.payload.data.seller_license_yn)
        state.seller_license_yn = action.payload.data.seller_license_yn;
      if (action.payload.data.seller_name)
        state.seller_name = action.payload.data.seller_name;
      if (action.payload.data.seller_phone)
        state.seller_phone = action.payload.data.seller_phone;
      if (action.payload.data.seller_service)
        state.seller_service = action.payload.data.seller_service;

      if (action.payload.data.seller_state)
        state.seller_state = action.payload.data.seller_state;
      if (action.payload.data.work_kind)
        state.work_kind = action.payload.data.work_kind;
      if (action.payload.data.service_state)
        state.service_state = action.payload.data.service_state;

      if (action.payload.data.alarm_yn) {
        state.alarm_yn = action.payload.data.alarm_yn;
      }
      if (action.payload.data.seller_intro) {
        state.seller_intro = action.payload.data.seller_intro;
      }
    },
  },
});

export const { logIn, logOut, setData, reload } = userSlice.actions;
export default userSlice.reducer;
