import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { closeSub } from "../redux/subPopupSlice";
import { imgOpen } from "../redux/imgSlice";
import useConvertFileHooks from "../pages/instance/priceAndInfo/useConvertFileHooks";
import imageCompression from "browser-image-compression";

export default function Files(props) {
  const {
    value,
    valuePhoto,
    setValue,
    onSearch,
    deleteList,
    setDeleteList,
    minlength = false,
    maxlength = 10,
    index,
    name,
    imgName,
    valid,
    msg,
    imgKey,
    imgUrl,
    maxSize,
  } = props;
  const { convertURLtoFile } = useConvertFileHooks();

  const dispatch = useDispatch();

  const fileInput = useRef();
  const [valueFile, setValueFile] = useState(value ? value : []);
  const [detailImageUrl, setDetailImageUrl] = useState(
    valuePhoto ? valuePhoto : []
  );
  const [valueDelete, setValueDelete] = useState(deleteList ? deleteList : []);

  const [img_list, setimg_list] = useState([]);
  const [addFileSize, setAddFileSize] = useState(0);

  useEffect(() => {
    setimg_list(
      detailImageUrl.map((x, i) => {
        return {
          src: x.case_zone_table_id
            ? x.case_zone_img
            : x.case_detail_table_id
            ? x.case_detail_img
            : x,
        };
      })
    );
  }, [detailImageUrl, imgUrl]);

  const handleChange = async (e) => {
    let files = e.target.files;

    let handleFileSize = 0;

    for (let i = 0; i < files.length; i++) {
      handleFileSize += files[i].size;
    }

    if (addFileSize + handleFileSize > maxSize) {
      alert("파일 사이즈가 초과되었습니다.");
      handleFileSize = 0;
      return;
    }

    if (files.length < 1) return;
    if (files.length + valueFile.length > maxlength) {
      alert("최대 " + maxlength + "개까지 등록 가능합니다.");

      return;
    }

    let reg = "";
    let msg = "";
    let cker = true;

    if (valid === "image") {
      reg = /(.*?)\.(jpg|jpeg|png|gif|bmp)$/;
      msg = "이미지";
    } else if (valid === "doc") {
      reg = /(.*?)\.(jpg|jpeg|png|gif|bmp|pdf)$/;
      msg = "문서";
    }

    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      if (!file.name.toLowerCase().match(reg)) {
        alert(msg + " 파일만 등록 가능합니다.");

        cker = false;
        return false;
      }
    }

    if (cker) {
      setAddFileSize(addFileSize + handleFileSize);

      let files_url = Array.from(files).map(async (file) => {
        const compressedFile = await imageCompression(file, {
          maxSizeMB: 1,
          fileType: "image/jpeg",
          useWebWorker: true,
        });

        const promise = imageCompression.getDataUrlFromFile(compressedFile);

        return new Promise((resolve) => {
          promise.then((result) => {
            resolve(result);
          });
        });
      });

      const res = await Promise.all(files_url);

      setValueFile((valueFile) => [...valueFile, ...files]);
      setDetailImageUrl((detailImageUrl) => [...detailImageUrl, ...res]);

      e.target.value = "";
      return;
    }
  };

  // set file reader function
  const fileDelete = (i, id) => {
    let values = Array.from(valueFile);
    let detailImageUrls = detailImageUrl;

    setValueFile([
      ...values.slice(0, i),
      ...values.slice(i + 1, values.length),
    ]);
    setDetailImageUrl([
      ...detailImageUrls.slice(0, i),
      ...detailImageUrls.slice(i + 1, detailImageUrls.length),
    ]);
    if (id) {
      setValueDelete((valueDelete) => [...valueDelete, id]);
    }

    const deleteFileSize = detailImageUrls.map(
      (item) => item.case_zone_img || item.case_detail_img || item
    )[i];

    convertURLtoFile(deleteFileSize).then((file) => {
      if (addFileSize >= file.size) {
        setAddFileSize(addFileSize - file.size);
      }
    });
    return;
  };

  const submitFunc = () => {
    if (minlength && minlength > detailImageUrl.length) {
      alert("최소 " + minlength + "개부터 등록 가능합니다.");
      return;
    }

    if (onSearch) {
      let nm = { target: { name: name } };
      let nm2 = { target: { name: imgName } };
      onSearch(index, nm, nm2, valueFile, detailImageUrl);
    } else {
      let nm = { target: { name: name } };
      setValue(index, nm, valueFile);

      nm = { target: { name: imgName } };
      setValue(index, nm, detailImageUrl);
    }

    setDeleteList(valueDelete);
    dispatch(closeSub());
  };

  // const formatBytes = (bytes, decimals = 2) => {
  //   if (!+bytes) return "0 Bytes";

  //   const k = 1024;
  //   const dm = decimals < 0 ? 0 : decimals;
  //   const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  //   const i = Math.floor(Math.log(bytes) / Math.log(k));

  //   return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  // };

  return (
    <form className="popup_tag">
      <div className="popup_files">
        <label className="btn btn3" htmlFor={"file_pop"}>
          파일찾기
        </label>
        <input
          ref={fileInput}
          type={"file"}
          name={name}
          id={"file_pop"}
          onChange={handleChange}
          multiple
        />
        {msg && <p className="sub_title">{msg}</p>}
      </div>

      <ul className="popup_files_photo">
        {detailImageUrl &&
          detailImageUrl.map((x, i) => {
            return (
              <li key={i} className="input_multi_preview2">
                <img
                  src={
                    imgKey
                      ? x[imgKey]
                        ? x[imgUrl]
                        : x
                      : x.case_zone_table_id
                      ? x.case_zone_img
                      : x.case_detail_table_id
                      ? x.case_detail_img
                      : x
                  }
                  alt="case_img"
                  onClick={() =>
                    dispatch(imgOpen({ imgs: img_list, active: i }))
                  }
                />
                <button
                  type="button"
                  className="img_delete_btn"
                  onClick={() =>
                    fileDelete(
                      i,
                      imgKey
                        ? x[imgKey]
                        : x.case_zone_table_id
                        ? x.case_zone_table_id
                        : x.case_detail_table_id
                        ? x.case_detail_table_id
                        : ""
                    )
                  }
                />
              </li>
            );
          })}
      </ul>

      {/* {maxSize && (
        <div
          css={css`
            padding: 20px;
            display: flex;
          `}
        >
          <p>{formatBytes(fileSize + addFileSize)}</p>
          <p
            css={css`
              margin: 0 5px;
            `}
          >
            /
          </p>
          <p
            css={css`
              font-weight: bold;
            `}
          >
            {formatBytes(maxSize)}
          </p>
        </div>
      )} */}
      <div className="popup_btn">
        <button
          type="button"
          className="popbtn cencle_btn"
          onClick={() => {
            dispatch(closeSub());
          }}
          style={{ width: "100%", marginRight: "10px" }}
        >
          취소
        </button>
        <button
          type="button"
          className="popbtn ok_btn"
          onClick={() => {
            submitFunc();
          }}
          style={{ width: "100%" }}
        >
          확인
        </button>
      </div>
    </form>
  );
}
