import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Input from "../../components/Input";
import { closeSub } from "../../redux/subPopupSlice";

export default function ThemePyung(props) {
  const { value, setValue, rep_size, setrep_size, setTheme_size_select } =
    props;
  const dispatch = useDispatch();

  const [list, setList] = useState(value ? value : []);

  const [stx, setStx] = useState("");
  const [popTheme_size_select, setPopTheme_size_select] = useState("");

  const addFunc = () => {
    if (stx) setList([...list, stx * 1]);
  };

  const submitFunc = () => {
    setValue(list);
    dispatch(closeSub());
    setTheme_size_select(popTheme_size_select);
  };

  const deleteFunc = (id) => {
    setList(list.filter((x) => x !== id));
  };

  return (
    <form className="popup_form" style={{ marginTop: "10px" }}>
      <div className="popup_form_list_input_withbutton">
        <Input
          className="input_text"
          type="text"
          placeholder="평형을 입력해 주세요."
          name="stx"
          value={stx}
          setValue={setStx}
          label="평형"
          withText={"평"}
          valid={"num"}
          maxlength={3}
        />
        <button
          type="button"
          className="input_with_button input_with_button_long"
          onClick={() => addFunc()}
        >
          추가
        </button>
      </div>

      <div className="theme_pyung_list">
        {list.length > 0 && <label className="input_label">대표지정</label>}
        {list.length > 0 &&
          list.map((x, i) => {
            return (
              <p>
                <div>
                  <input
                    type="radio"
                    id={"pyungradio" + i}
                    name="style_main_check"
                    className="radio"
                    value={x}
                    defaultChecked={rep_size === x}
                    onChange={(e) => {
                      setPopTheme_size_select(x);
                      setrep_size(x);
                    }}
                  />
                  <label htmlFor={"pyungradio" + i}>{x}평</label>
                </div>

                <button
                  type="button"
                  className="theme_partner_delete"
                  onClick={() => deleteFunc(x)}
                />
              </p>
            );
          })}
      </div>

      <div className="popup_btn">
        <button
          type="button"
          className="popbtn cencle_btn"
          onClick={() => {
            dispatch(closeSub());
          }}
          style={{ width: "100%", marginRight: "10px" }}
        >
          취소
        </button>
        <button
          type="button"
          className="popbtn ok_btn"
          onClick={() => {
            submitFunc();
          }}
          style={{ width: "100%" }}
        >
          확인
        </button>
      </div>
    </form>
  );
}
