/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { css } from "@emotion/react";
import PartnerMenu from "./Menu";
import PartnerHeader from "./Header";
import Footer from "../Footer";
import { Header as TitleHeader } from "../../Common/Typography";
import * as Colors from "../../../constants/color";
import Tabs from "../../Common/Tabs/Tabs";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

interface PartnerLayoutProps {
  children: React.ReactNode;
  title?: string;
  tabs?: {
    id: number;
    title: string;
    as?: string;
    callback?: () => void;
    isActive?: boolean;
  }[];
}

const PartnerLayout = (props: PartnerLayoutProps) => {
  const { children, title, tabs } = props;
  const userData = useSelector((s: any) => s.usersReducer);
  const navigate = useNavigate();

  useEffect(() => {
    if (userData && userData.grade) {
      navigate("/");
    }
  }, []);

  return (
    <div css={css``}>
      <div>
        <PartnerMenu />
      </div>
      <div
        css={css`
          margin-left: 220px;
          min-height: 79vh;

          @media screen and (max-width: 767px) {
            margin-left: 0;
          }
        `}
      >
        <PartnerHeader />
        {title && (
          <div
            css={css`
              padding: 28px 0 0 26px;
              margin-top: 52px;
              border-top: 1px solid ${Colors.gray_200};
              border-bottom: 1px solid ${Colors.gray_200};
            `}
          >
            <div
              css={css`
                margin-bottom: 25px;
              `}
            >
              <TitleHeader strong>{title}</TitleHeader>
            </div>
            {tabs && tabs.length > 0 && (
              <div>
                <Tabs
                  datas={tabs}
                  type="horizontal"
                  activeText={String(tabs.find((tab) => tab.isActive)?.title)}
                  underline
                  activeColor="primary"
                />
              </div>
            )}
          </div>
        )}
        <div
          css={css`
            padding: 0 30px 30px 30px;
          `}
        >
          {children}
        </div>
      </div>
      <footer
        css={css`
          padding: 0 0 0 220px;
          position: relative;

          @media screen and (max-width: 767px) {
            padding: 0;
          }
        `}
      >
        <Footer style={{ transform: "none" }} />
      </footer>
    </div>
  );
};

export default PartnerLayout;
