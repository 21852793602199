/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as APIS from "../../utils/service";
import { regEmail, findJson } from "../../utils/utils";
import SubNav from "../../components/SubNav";
import Input from "../../components/Input";
import InputSelect from "../../components/InputSelect";
import TextArea from "../../components/TextArea";
import InputFile from "../../components/InputFile";
import PGForm from "../../components/PGForm";
import Area from "../register/Area";
import routes from "../../libs/routes";
import consts from "../../libs/consts";
import { open } from "../../redux/popupSlice";
import { setData } from "../../redux/usersSlice";
import PartnerLayout from "../../components/Layout/partner";
import { css } from "@emotion/react";
import Select from "../../components/Common/Select/Select";
import NewInput from "../../components/Common/Input/Input";
import Preview from "../../components/Preview";

export default function My() {
  const dispatch = useDispatch();
  const userData = useSelector((s) => s.usersReducer);

  const [cker, setCker] = useState("");

  const [sellerState, setSellerState] = useState(userData.seller_state);
  const [sellerStateSelect, setSellerStateSelect] = useState(
    userData.seller_state
  );

  const [comp, setComp] = useState(userData.seller_biz_name);
  const [bn, setBn] = useState(userData.seller_biz_no);
  const [hp, setHp] = useState("");
  const [name, setName] = useState("");
  const [addr, setAddr] = useState(userData.seller_addr);
  const [addrDetail, setAddrDetail] = useState(userData.seller_addr_detail);
  const [email, setEmail] = useState(userData.seller_email);
  const [logo, setLogo] = useState("");
  const [logoImg, setLogoImg] = useState(userData.seller_icon);
  const [is_icon_del, setis_icon_del] = useState(0);

  const [range] = useState("전체");

  const [range1, setRange1] = useState(userData.work_kind[0]);
  const [range2, setRange2] = useState(userData.work_kind[1]);

  const [area, setArea] = useState(userData.seller_area);
  const [areaText, setAreaText] = useState(
    userData.seller_area_text ? userData.seller_area_text.join(", ") : ""
  );
  const [spec, setSpec] = useState(userData.seller_career);
  const [serve, setServe] = useState(userData?.seller_service || []);
  const [as, setAs] = useState(userData.seller_as);
  const [license, setLicense] = useState(
    userData.seller_license_yn === "0" ? false : true
  );
  const [comment, setComment] = useState(userData.seller_desc);

  const [error, setError] = useState("");
  const [error2, setError2] = useState("");
  const [error3, setError3] = useState("");
  const [error4, setError4] = useState("");
  const [error5, setError5] = useState("");
  const [error6, setError6] = useState("");

  const [isPartnerIntroduce, setIsPartnerIntroduce] = useState(
    userData.seller_intro
      ? !consts.sellerIntroduceOption.find(
          (item) => item.value === userData.seller_intro
        )
      : false
  );
  const [sellerIntroduceText, setSellerIntroduceText] = useState(
    userData?.seller_intro || ""
  );

  const nav = [
    { routes: routes.my, title: "파트너 정보" },
    { routes: routes.membership, title: "멤버십 정보" },
    { routes: routes.acc, title: "계정 정보" },
  ];

  useEffect(() => {
    if (cker === "1") {
      const sender = {
        seller_table_id: userData.seller_table_id,
        seller_state: sellerStateSelect,
      };

      APIS.postData("/Admin/ChgState", sender, 1)
        .then(() => {
          setSellerState(sellerStateSelect);
          dispatch(setData({ key: "isReload", value: true }));
          dispatch(
            open({
              message: "상태가 변경되었습니다.",
              button: "확인",
            })
          );
        })
        .catch((e) => {
          console.log("catch", e);
        });
    }

    setCker("");
  }, [cker]);

  useEffect(() => {
    if (range === "전체") {
      const checkboxes = document.getElementsByClassName("rangeDetail");
      for (let cb of checkboxes) {
        cb.checked = false;
      }
    }
  }, [range]);

  useEffect(() => {
    dispatch(setData({ key: "isReload", value: true }));
  }, [area]);

  const areaFunc = () => {
    dispatch(
      open({
        title: "시공가능지역선택",
        component: (
          <Area value={area} setValue={setArea} setValueText={setAreaText} />
        ),
      })
    );
  };

  const submitFunc = () => {
    /* 필수정보 미입력 확인 */
    if (!addr || !area || !spec || !serve || !sellerIntroduceText) {
      dispatch(
        open({
          message: "필수 정보를 입력 해 주시기 바랍니다.",
          messageAuto: true,
          button: "확인",
        })
      );
      return;
    }

    if (email && !regEmail.test(email)) {
      dispatch(
        open({
          message: "이메일 형식을 확인해 주세요.",
          button: "확인",
        })
      );
      return;
    }

    const sender = {
      seller_table_id: userData.seller_table_id,
      seller_phone: hp ? hp : "",
      seller_name: name ? name : "",
      seller_license_yn: license,
      seller_email: email,
      seller_addr: addr,
      seller_addr_detail: addrDetail,
      seller_icon: userData.seller_icon === logoImg ? "" : logoImg,
      seller_career: spec,
      seller_service: serve,
      seller_as: as,
      seller_desc: comment,
      work_kind: [range1 ? 1 : 0, range2 ? 1 : 0],
      seller_area: area,
      seller_area_text: areaText.split(", "),
      is_icon_del: is_icon_del,
      seller_intro: sellerIntroduceText,
    };

    APIS.postData("/SellerInfo/Update", sender, 2)
      .then((res) => {
        if (res.data.code === "OK") {
          dispatch(setData({ key: "isReload", value: true }));
          dispatch(
            open({
              message: "수정이 완료되었습니다.",
              button: "확인",
            })
          );
        } else {
          dispatch(
            open({
              message: "잠시 후 다시 시도해주세요.",
              button: "확인",
            })
          );
        }
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };

  const setServeFunc = () => {
    let sv = [];

    const checkboxes = document.getElementsByClassName("serve");

    for (let cb of checkboxes) {
      if (cb.checked) {
        const findItem = consts.serveoption.find(
          (item) => item.title === cb.value
        ).idx;

        sv.push(findItem);
      }
    }
    setServe(sv);
  };

  const pgFunc = () => {
    let PG_POP = window.payRequest();

    const interval = window.setInterval(function () {
      try {
        if (PG_POP == null || PG_POP.closed) {
          window.clearInterval(interval);
          pgSucFunc();
        }
      } catch (e) {}
    }, 1000);
  };

  const pgSucFunc = () => {
    const code = document.getElementById("PG_CODE").value;

    if (code === "OK") {
      setName(document.getElementById("PG_NAME").value);
      setHp(document.getElementById("PG_HP").value);
    } else if (code === "ERR_306") {
      dispatch(
        open({
          message: "해당 휴대전화번호로 가입된 계정이 있습니다.",
          messageAuto: true,
          button: "확인",
        })
      );
    } else {
      dispatch(
        open({
          message: "잠시 후 다시 시도해주세요.",
          button: "확인",
        })
      );
    }
  };

  const sellerStateAlert = () => {
    dispatch(
      open({
        title: "업체 상태",
        message: "업체의 상태에 따라 서비스 제공이 제한 될 수 있습니다.",
        component: (
          <InputSelect
            name="sellerStateSelect"
            label="상태"
            value={sellerStateSelect}
            setValue={setSellerStateSelect}
            option={consts.stateoption.filter((item) => item.idx !== "3")}
            errorFix2={true}
          />
        ),
        one: true,
        button: "변경",
        buttonCencle: "취소",
        onPress: () => setCker("1"),
      })
    );
  };

  return (
    <PartnerLayout>
      <div
        css={css`
          padding: 52px 0 0 0;
          margin: 0 -30px -30px -30px;
        `}
      >
        <div className="page_title_box page_title_box1">
          <p className="title">{userData.seller_biz_name}</p>
          <SubNav nav={nav} />
        </div>

        <div className="content_section">
          <div className="form_section">
            <p className="title">기본 정보</p>
            <div className="form_list">
              <input type="hidden" name="PG_CODE" id="PG_CODE" />
              <input type="hidden" name="PG_NAME" id="PG_NAME" />
              <input type="hidden" name="PG_HP" id="PG_HP" />

              <Input
                className="input_text"
                type="text"
                placeholder="업체명을 입력해 주세요."
                name="comp"
                value={comp}
                setValue={setComp}
                label="*업체명"
                error={error}
                setError={setError}
                readOnly={true}
              />
              <Input
                className="input_text"
                type="text"
                placeholder="정산"
                name=""
                value={findJson(consts.stateoption, sellerState)}
                label="*업체상태"
                readOnly={true}
                withButton={sellerState !== "3" ? "변경" : ""}
                withButtonPress={() => {
                  sellerState !== "3" && sellerStateAlert();
                }}
              />

              <Input
                className="input_text"
                type="text"
                placeholder="숫자만 입력해 주세요."
                name="bn"
                value={bn}
                setValue={setBn}
                label="*사업자등록번호"
                error={error2}
                valid={"num"}
                setError={setError2}
                readOnly={true}
              />

              <Input
                className="input_text"
                type="text"
                placeholder="영문/숫자만 입력해 주세요."
                name="hp"
                value={hp ? hp : userData.seller_phone}
                label="*휴대폰번호"
                readOnly={true}
                withButton={"본인확인"}
                withButtonPress={() => pgFunc()}
              />
              <Input
                className="input_text"
                type="text"
                placeholder="영문/숫자만 입력해 주세요."
                name="name"
                value={name ? name : userData.seller_name}
                label="*대표자명"
                readOnly={true}
              />

              <Input
                className="input_text"
                type="text"
                placeholder="주소 찾기를 해 주세요."
                name="addr"
                value={addr}
                setValue={setAddr}
                label="*주소"
                withButton={"주소찾기"}
              />
              <Input
                className="input_text"
                type="text"
                placeholder="상세주소를 입력해 주세요."
                name="addrDetail"
                value={addrDetail}
                setValue={setAddrDetail}
                label="*상세주소"
              />

              <Input
                className="input_text"
                type="text"
                placeholder="이메일을 입력해 주세요."
                name="email"
                value={email}
                setValue={setEmail}
                label="이메일"
                error={error3}
                setError={setError3}
                maxlength={50}
                valid={"email"}
              />
              <InputFile
                type="file"
                placeholder="사진을 첨부해 주세요."
                name="logo"
                label="업체로고"
                value={logo}
                setValue={setLogo}
                imgvalue={logoImg}
                setImgValue={setLogoImg}
                valid="image"
                deleteFunc={() => setis_icon_del(1)}
              />
            </div>
          </div>

          <div
            className="form_section"
            css={css`
              padding-bottom: 130px;
            `}
          >
            <p className="title">시공 정보</p>
            <div className="form_list">
              <div className="checkbox_section">
                <label className="input_label">시공범위</label>
                <div>
                  <input
                    type="checkbox"
                    id="chk4"
                    className="checkbox"
                    checked={range1}
                    onChange={(e) => setRange1(e.target.checked)}
                  />
                  <label htmlFor="chk4">전체</label>
                  <input
                    type="checkbox"
                    id="chk5"
                    className="checkbox"
                    checked={range2}
                    onChange={(e) => setRange2(e.target.checked)}
                  />
                  <label htmlFor="chk5">부분</label>
                </div>
              </div>
              <Input
                className="input_text"
                type="text"
                placeholder="지역 선택을 해 주세요."
                name="area"
                value={areaText}
                setValue={setAreaText}
                label="시공가능지역"
                error={error4}
                withButton={"지역선택"}
                withButtonPress={areaFunc}
                setError={setError4}
                msg={
                  "시공가능한 모든지역 선택. 서울시, 경기도는 시구 단위까지 선택"
                }
                maxlength={5000}
              />
              <Input
                className="input_text"
                type="text"
                placeholder="경력 입력"
                name="spec"
                value={spec}
                setValue={setSpec}
                label="경력"
                withText={"년"}
                maxlength={2}
                valid={"num"}
                error={error5}
                setError={setError5}
              />
              <TextArea
                className="input_textarea"
                placeholder="파트너사의 업력 및 시공 전문분야를 잘표현할 수 있는 내용을 입력해 주세요. (500자 이내)"
                name="comment"
                value={comment}
                setValue={setComment}
                label="설명"
                error={error6}
                setError={setError6}
              />
              <div className="input_check_box input_check_box_long">
                <p className="input_label">제공서비스</p>
                <div>
                  {consts.serveoption.map((x, i) => {
                    return (
                      <Fragment key={i}>
                        <input
                          type="checkbox"
                          id={"servechk" + i}
                          className="checkbox2 serve"
                          value={x.title}
                          defaultChecked={serve.includes(x.idx)}
                          onChange={() => setServeFunc()}
                        />
                        <label htmlFor={"servechk" + i}>{x.title}</label>
                      </Fragment>
                    );
                  })}
                </div>
              </div>
              <InputSelect
                name="as"
                label="A/S보증"
                value={as}
                setValue={setAs}
                option={consts.asoption}
              />
              <div className="checkbox_section">
                <label className="input_label">실내건축업면허</label>
                <div>
                  <input
                    type="radio"
                    id="rd3"
                    name="license"
                    className="radio"
                    value="1"
                    defaultChecked={license}
                    onChange={(e) => setLicense(e.target.value)}
                  />
                  <label htmlFor="rd3">보유</label>
                  <input
                    type="radio"
                    id="rd4"
                    name="license"
                    className="radio"
                    value="0"
                    defaultChecked={!license}
                    onChange={(e) => setLicense(e.target.value)}
                  />
                  <label htmlFor="rd4">미보유</label>
                </div>
              </div>

              <div>
                <Select
                  label="* 파트너 소개 문장"
                  defaultValue="선택"
                  value={
                    sellerIntroduceText === ""
                      ? ""
                      : consts.sellerIntroduceOption.find(
                          (option) => option.value === sellerIntroduceText
                        )
                      ? sellerIntroduceText
                      : "text"
                  }
                  options={consts.sellerIntroduceOption}
                  onChange={(e) => {
                    if (e.target.value === "text") {
                      setIsPartnerIntroduce(true);
                      setSellerIntroduceText("");
                    } else {
                      setIsPartnerIntroduce(false);
                      setSellerIntroduceText(e.target.value);
                    }
                  }}
                />
                {isPartnerIntroduce && (
                  <div
                    css={css`
                      margin-top: 10px;
                    `}
                  >
                    <NewInput
                      placeholder="직접 입력(공백 포함 15자 이내)"
                      maxLength={15}
                      value={sellerIntroduceText}
                      onChange={(e) => setSellerIntroduceText(e.target.value)}
                    />
                  </div>
                )}
                <p
                  css={css`
                    display: flex;
                    justify-content: end;
                    margin-top: 15px;
                  `}
                >
                  어디에 노출되나요
                  <p
                    css={css`
                      cursor: pointer;
                      margin-left: 10px;
                      border: 1px solid;
                      width: 18px;
                      height: 18px;
                      line-height: 18px;
                      text-align: center;
                      border-radius: 50%;
                      font-size: 12px;
                      position: relative;

                      & > div {
                        display: none;
                      }

                      &:hover {
                        & > div {
                          display: block;
                        }
                      }
                    `}
                  >
                    ?
                    <Preview />
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="my_btn_box">
          <button type="button" className="btn btn3">
            취소
          </button>
          <button
            type="button"
            className="btn btn1"
            onClick={() => submitFunc()}
          >
            수정
          </button>
        </div>
      </div>
      <PGForm />
    </PartnerLayout>
  );
}
