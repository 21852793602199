/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import * as APIS from "../../utils/service";
import moment from "moment";
import { open } from "../../redux/popupSlice";
import Add from "../estimate/Add";
import ScheduleList from "./ScheduleList";
import ScheduleCal from "./ScheduleCal";
import PartnerLayout from "../../components/Layout/partner";

export default function Schedule() {
  const dispatch = useDispatch();
  const userData = useSelector((s) => s.usersReducer);
  const popup = useSelector((s) => s.popupReducer);

  const [majorScheduleList, setMajorScheduleList] = useState([]); // 다가올 주요일정 리스트
  const [calList, setCalList] = useState([]); // 캘린터 리스트
  const [listDetail, setListDetail] = useState([]); // 캘린터 하루 상세리스트

  const [date, setDate] = useState(new Date());
  const [year, setYear] = useState(moment(date).format("YYYY"));
  const [month, setMonth] = useState(moment(date).format("M"));

  useEffect(() => {
    getListDetail();
  }, [date, calList]);

  const getListDetail = () => {
    let filteredCalList = calList.filter((x) =>
      moment(x.meet_date).format("YYYY-MM-DD") ===
      moment(date).format("YYYY-MM-DD")
        ? moment(x.meet_date).format("YYYY-MM-DD") ===
          moment(date).format("YYYY-MM-DD")
        : moment(x.work_start_date).format("YYYY-MM-DD") <=
            moment(date).format("YYYY-MM-DD") &&
          moment(x.work_end_date).format("YYYY-MM-DD") >=
            moment(date).format("YYYY-MM-DD")
        ? moment(x.work_start_date).format("YYYY-MM-DD") <=
            moment(date).format("YYYY-MM-DD") &&
          moment(x.work_end_date).format("YYYY-MM-DD") >=
            moment(date).format("YYYY-MM-DD")
        : moment(x.reg_date).format("YYYY-MM-DD") ===
          moment(date).format("YYYY-MM-DD")
    );
    let tempList = [...filteredCalList];
    setListDetail(() => tempList);
  };

  const scheduleDataFunc = () => {
    let urls = "/OrderInfo/Schedule";
    let sender = {
      seller_table_id: userData.seller_table_id,
      year: year,
      month: month,
    };
    let types = 2;

    APIS.postData(urls, sender, types)
      .then((res) => {
        if (res.data.code === "OK") {
          let majorSche = [];
          let calSche = [];
          if (Array.isArray(res.data.schedule_list)) {
            calSche = [...res.data.schedule_list];
            calSche.forEach((item) => {
              if (
                item["order_state_word"] !== "완료" &&
                item["order_state_word"] !== "미팅 완료"
              ) {
                majorSche.push(item);
              }
            });
          }
          setMajorScheduleList(majorSche);
          setCalList(calSche);
        }
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };
  useEffect(() => {
    scheduleDataFunc();
  }, [popup.open, userData, year, month]);

  const updateFunc = (id) => {
    if (!id) return;

    let urls = "/OrderInfo/OrderTarget";
    let types = 2;
    let sender = {
      seller_table_id: userData.seller_table_id,
      order_table_id: id,
    };

    APIS.postData(urls, sender, types)
      .then((res) => {
        if (res.data.code === "OK" && res.data.order_list.length > 0) {
          dispatch(
            open({
              title: "견적관리",
              wide: true,
              component: <Add x={res.data.order_list[0]} />,
            })
          );
        } else {
          dispatch(
            open({
              message: "잘못된 접근입니다.",
            })
          );
        }
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };

  return (
    <PartnerLayout title="일정관리">
      <div>
        <ScheduleList
          updateFunc={updateFunc}
          list={majorScheduleList}
        ></ScheduleList>
        <ScheduleCal
          updateFunc={updateFunc}
          list={calList}
          listDetail={listDetail}
          date={date}
          setDate={setDate}
          setMonth={setMonth}
          setYear={setYear}
          callback={scheduleDataFunc}
        ></ScheduleCal>
      </div>
    </PartnerLayout>
  );
}
