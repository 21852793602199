/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const usePagination = (props) => {
  const { total, currentPage, pageSize } = props;
  const [pageArr, setPageArr] = useState([]);
  const navigate = useNavigate();
  const { pathname } = window.location;

  useEffect(() => {
    if (currentPage < 1) {
      navigate({
        pathname: pathname,
      });
    }
    paging();
  }, [currentPage, total]);

  const paging = () => {
    const pageArr = [];
    const pageNavSize = 5;
    const startPage =
      Math.ceil(currentPage / pageNavSize - 1) * pageNavSize + 1;
    const endPage = Math.ceil(currentPage / pageNavSize) * pageNavSize;

    for (let i = startPage; i <= endPage; i++) {
      if (i <= Math.ceil(total / pageSize)) {
        pageArr.push(i);
      }
    }
    setPageArr(pageArr);
  };

  const movePage = (num) => {
    navigate({
      pathname: pathname,
      search: `?page=${num}`,
    });
  };

  return {
    pageArr,
    movePage,
  };
};

export default usePagination;
