import React from "react";
import AdminLayout from "../../../components/Layout/admin";
import NoticeUser from "../../../components/Notice/User";
import useNotice from "./notice.hook";

const NoticeUserPage = () => {
  const { tabs } = useNotice();

  return (
    <AdminLayout title="광고 관리" tabs={tabs}>
      <NoticeUser />
    </AdminLayout>
  );
};

export default NoticeUserPage;
