/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import * as APIS from "../../utils/service";
import { findJson } from "../../utils/utils";
import moment from "moment";
import Input from "../../components/Input";
import InputDate from "../../components/InputDate";
import InputSelect from "../../components/InputSelect";
import Pagenation from "../../components/Pagenation";
import MemberShip from "../../components/MemberShip";
import consts from "../../libs/consts";
import { setData } from "../../redux/searchSlice";
import AdminLayout from "../../components/Layout/admin";
import Table from "../../components/Common/Table/Table";
import { css } from "@emotion/react";

export default function Partner() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((s) => s.usersReducer);
  const search = useSelector((s) => s.searchReducer);
  /* search form */
  const [cityList, setCityList] = useState("");
  const [pageing, setPageing] = useState("");

  const [list, setList] = useState([
    { cnt: 1, receipt: 13 },
    { cnt: 24, receipt: 22 },
    { cnt: 3, receipt: 11 },
    { cnt: 4, receipt: 15 },
    { cnt: 4, receipt: 9 },
    { cnt: 6, receipt: 56 },
    { cnt: 7, receipt: 7 },
    { cnt: 8, receipt: 6 },
    { cnt: 6, receipt: 2 },
    { cnt: 1, receipt: 4 },
    { cnt: 2, receipt: 1 },
    { cnt: 12, receipt: 234 },
    { cnt: 31, receipt: 12 },
  ]);
  /* search form End */

  const [stateoption] = useState([
    { idx: "", title: "전체" },
    { idx: "1", title: "정상" },
    { idx: "2", title: "대기" },
    { idx: "3", title: "중지" },
  ]);

  const [membershipoption] = useState([
    { idx: "", title: "전체" },
    { idx: "1", title: "LAY Basic" },
    { idx: "2", title: "LAY Plus" },
    { idx: "3", title: "LAY Special" },
    { idx: "4", title: "미사용" },
  ]);

  const [licenseoption] = useState([
    { idx: "", title: "전체" },
    { idx: "1", title: "보유" },
    { idx: "0", title: "미보유" },
  ]);

  useEffect(() => {
    APIS.postData("/AreaInfo/GetAreaOne", "", 1)
      .then((res) => {
        setCityList(res.data.area);
      })
      .catch((e) => {
        console.log("catch", e);
      });
  }, []);

  useEffect(() => {
    dataFunc();
  }, [
    search.city,
    search.range,
    search.membership,
    search.state,
    search.license,
    search.sdate,
    search.edate,
    search.page,
  ]);

  const dataFunc = () => {
    const sender = {
      admin_table_id: userData.admin_table_id,
      seller_area: search.city,
      work_kind: search.range,
      membership_type: search.membership,
      seller_state: search.state,
      license: search.license,
      seller_biz_name: search.stx,
      start_date: search.sdate,
      end_date: search.edate,
      page: search.page + 1,
    };

    APIS.postData("/Admin/PartnerList", sender, 1)
      .then((res) => {
        if (res.data.code === "OK") {
          setList(res.data.partner_list);
          setPageing({
            totalPages: Math.ceil(res.data.total_cnt / consts.pagerows),
          });
        }
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };

  const searchFunc = (key, value) => {
    dispatch(
      setData({
        key: key,
        value: value,
      })
    );
  };
  const keyPressFunc = (e) => {
    if (e.key === "Enter") {
      dataFunc();
    }
  };

  const col = [
    { key: "seller_id", title: "파트너 ID" },
    { key: "seller_biz_name", title: "파트너명" },
    {
      key: "seller_area",
      title: "시공가능 지역",
      render: (data) => (
        <span>{data.seller_area && data.seller_area.join(", ")}</span>
      ),
    },
    {
      key: "work_kind",
      title: "시공범위",
      render: (data) => (
        <span>
          {" "}
          {data.work_kind === "1"
            ? "전체"
            : data.work_kind === "2"
            ? "부분"
            : "전체, 부분"}
        </span>
      ),
    },
    { key: "case_cnt", title: "사례등록수" },
    { key: "supply_cnt", title: "제공수" },
    { key: "cont_cnt", title: "계약수" },
    {
      key: "membership_type",
      title: "요금제",
      render: (data) => (
        <span>
          {data.membership_type === "미사용" ? (
            "미보유"
          ) : (
            <MemberShip type={data.membership_type} small={true} />
          )}
        </span>
      ),
    },
    {
      key: "seller_state",
      title: "상태",
      render: (data) => (
        <span>{findJson(consts.stateoption, data.seller_state)}</span>
      ),
    },
    {
      key: "seller_license_yn",
      title: "면허보유",
      render: (data) => (
        <span>{data.seller_license_yn === "0" ? "미보유" : "보유"}</span>
      ),
    },
    {
      key: "reg_date",
      title: "등록일자",
      render: (data) => (
        <span>{moment(data.reg_date).format("YYYY.MM.DD")}</span>
      ),
    },
    {
      key: "update_date",
      title: "수정일자",
      render: (data) => (
        <span>{moment(data.update_date).format("YYYY.MM.DD")}</span>
      ),
    },
  ];

  return (
    <AdminLayout title="파트너 관리">
      <div>
        <div className="search_form search_form_long">
          <div className="search_form_left">
            <div className="search_form_long_box">
              <InputSelect
                name="city"
                label="시공가능지역"
                value={search.city}
                onSearch={searchFunc}
                option={[{ area_id: "", area_name: "전체" }, ...cityList]}
                city={true}
              />
              <InputSelect
                name="range"
                label="시공범위"
                value={search.range}
                onSearch={searchFunc}
                option={[{ idx: "", title: "선택" }, ...consts.rangeoption]}
              />
              <InputSelect
                name="membership"
                label="요금제"
                value={search.membership}
                onSearch={searchFunc}
                option={membershipoption}
              />
              <InputSelect
                name="state"
                label="상태"
                value={search.state}
                onSearch={searchFunc}
                option={stateoption}
              />
              <InputSelect
                name="license"
                label="면허보유"
                value={search.license}
                onSearch={searchFunc}
                option={licenseoption}
              />

              <Input
                className="input_text"
                type="text"
                placeholder={"파트너명 입력"}
                name="stx"
                value={search.stx}
                onSearch={searchFunc}
                label={"검색"}
                onKeyDown={keyPressFunc}
              />

              <InputDate
                type="text"
                placeholder="시작날짜"
                placeholderEnd="종료날짜"
                name="ipd1"
                label="날짜선택"
                dateValue={search.sdate}
                onSearch={searchFunc}
                dateValueEnd={search.edate}
                multiple={true}
              />
            </div>
            <button
              type="button"
              className="btn btn3"
              onClick={() => dataFunc()}
            >
              검색
            </button>
          </div>
          <div
            className="search_form_right"
            style={{ marginBottom: "14px" }}
          ></div>
        </div>

        <div
          css={css`
            margin: 30px 0 20px 0;
          `}
        >
          <Table
            columns={col}
            datas={list}
            rowCallback={(data) =>
              navigate("/partnerDetail/" + data.seller_table_id)
            }
          />
        </div>

        <Pagenation
          pageing={pageing}
          onSearch={searchFunc}
          curpage={search.page}
        />
      </div>
    </AdminLayout>
  );
}
