import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { open } from "../../redux/popupSlice";
import { setData } from "../../redux/searchSlice";
import { numFormat, findJson } from "../../utils/utils";
import useWindowScreen from "../../utils/useWindowScreen";
import moment from "moment";
import consts from "../../libs/consts";
import routes from "../../libs/routes";
import InputSelect from "../../components/InputSelect";
import PayDetail from "./PayDetail";

export default function PayContCommission({ list }) {
  const dispatch = useDispatch();
  const search = useSelector((s) => s.searchReducer);
  const popup = useSelector((s) => s.popupReducer);

  const [step] = useState(1);

  const windowSize = useWindowScreen();
  const navigte = useNavigate();
  if (windowSize.width <= 720) {
    navigte(routes.membership);
  }
  useEffect(() => {}, [step]);

  useEffect(() => {}, [
    search.state,
    search.sdate,
    search.edate,
    search.page,
    popup.open,
  ]);

  const searchFunc = (key, value) => {
    dispatch(
      setData({
        key: key,
        value: value,
      })
    );
  };

  const payDetailFunc = (x, type) => {
    dispatch(
      open({
        title: type === 1 ? "상품구매 내역" : "계약수수료 입금안내",
        wide: true,
        component: <PayDetail x={x} type={type} />,
      })
    );
  };

  return (
    <>
      <div className="search_form">
        <div className="search_form_left">
          <InputSelect
            name="state"
            label="상태"
            value={search.state}
            onSearch={searchFunc}
            option={[{ idx: "", title: "전체" }, ...consts.contstateoption]}
          />
        </div>
      </div>
      <table className="tableStyle1">
        <thead>
          <tr>
            <th className="wd130">계약번호</th>
            <th className="wd110">견적유형</th>
            <th className="wd150">계약금액</th>
            <th className="wd110">수수료율</th>
            <th className="wd150">수수료</th>
            <th className="wd80">상태</th>
            <th className="wd130">등록일자</th>
            <th className="wd130">수정일자</th>
            <th className="wdFull">비고</th>
          </tr>
        </thead>
        <tbody>
          {list.length > 0 ? (
            list.map((x, i) => {
              return (
                <tr
                  key={i}
                  className={"pointer"}
                  onClick={() => payDetailFunc(x, 2)}
                >
                  <td>{x.cont_table_id}</td>
                  <td>{findJson(consts.sellerOrderType, x.type)}</td>
                  <td>{numFormat(x.price)}</td>
                  <td>-</td>
                  <td>-</td>
                  {/*                                <td>{ x.pay_per }%</td>
                                <td>{ numFormat(x.pay) }</td>*/}
                  <td>{findJson(consts.contstateoption, x.state)}</td>
                  <td>{moment(x.reg_date).format("YYYY.MM.DD")}</td>
                  <td>{moment(x.update_date).format("YYYY.MM.DD")}</td>
                  <td>{x.desc}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td className="null_td" colSpan={9}>
                데이터가 없습니다.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
}
