import { useRef } from "react";

const useCsv = () => {
  const csvLinkRef = useRef<any>();

  const getCsvHeader = (
    col: {
      key: string;
      title: string;
    }[]
  ) => {
    return col.map((item) => ({
      key: item.key,
      label: item.title,
    }));
  };

  const downloadHandler = () => {
    csvLinkRef.current.link.click();
  };
  return {
    getCsvHeader,
    csvLinkRef,
    downloadHandler,
  };
};

export default useCsv;
