import React from "react";
import AdminLayout from "../../../components/Layout/admin";
import useNotice from "./notice.hook";
import NoticeEvent from "../../../components/Notice/Event";

const NoticeEventPage = () => {
  const { tabs } = useNotice();

  return (
    <AdminLayout title="광고 관리" tabs={tabs}>
      <NoticeEvent />
    </AdminLayout>
  );
};

export default NoticeEventPage;
