import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import SwiperCore, {
  EffectFlip,
  EffectFade,
  EffectCube,
  EffectCreative,
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { imgClose } from "../redux/imgSlice";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-flip";
import "swiper/css/effect-fade";
import "swiper/css/effect-cube";
import "swiper/css/effect-creative";

SwiperCore.use([
  EffectFlip,
  EffectFade,
  EffectCube,
  EffectCreative,
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
]);

export default function Imgs() {
  const dispatch = useDispatch();
  const { open, imgs, active } = useSelector((state) => state.imgReducer);
  const swiperRef = useRef(null);

  useEffect(() => {
    if (open) {
      swiperRef.current.swiper.slideTo(active);
    }
  }, [active, open]);

  const swChk = () => {};

  return (
    <>
      <div className={open ? "imgview show" : "imgview"}>
        <div className="img_section">
          <button
            className="exit_btn"
            onClick={() => dispatch(imgClose())}
          ></button>
          <button className="prev_btn"></button>
          <button className="next_btn"></button>

          <div className="img_sw_section">
            <Swiper
              ref={swiperRef}
              className="img_sw_section_swiper"
              spaceBetween={10}
              slidesPerView={1}
              navigation={{
                prevEl: ".img_section .prev_btn",
                nextEl: ".img_section .next_btn",
              }}
              onSwiper={() => swChk()}
            >
              {imgs &&
                imgs.map((x, i) => {
                  return (
                    <SwiperSlide
                      key={i}
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      <img src={x.src} alt="src_img" />
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
}
