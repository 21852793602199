/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import * as APIS from "../../../utils/service";
import { useSelector } from "react-redux";
import debounce from "lodash/debounce";
import { useNavigate } from "react-router-dom";
import { CreateParams, Item } from "./noticeUrl.type";

interface List {
  url_table_id: string;
  url_type: string;
  url_name: string;
  update_date: string;
  url: string;
}

interface Props {
  isList?: boolean;
  searchData?: {
    url_type?: string;
    seller_biz_name?: string;
    url_title?: string;
  };
  id?: string;
}

const useNoticeUrl = (props?: Props) => {
  const { isList, searchData, id } = props ?? {};

  const userData = useSelector((s: any) => s.usersReducer);
  const navigate = useNavigate();
  const { pathname } = window.location;

  const [list, setList] = useState<List[]>([]);
  const [total, setTotal] = useState(0);

  const params = new URLSearchParams(window.location.search);
  const page = params.get("page") || "1";

  const [detail, setDetail] = useState<Item>();

  const getList = async (page: string) => {
    const params = {
      admin_table_id: userData.admin_table_id,
      page: page,
      ...searchData,
    };

    try {
      const res = await APIS.postData("/AdminNotice/AdmUrlList", params, 1);

      if (res.data.code === "OK") {
        setList(res.data.url_list);
        setTotal(res.data.total_cnt);
      } else {
        setList([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (isList) {
      getList(page);
    }
  }, [page]);

  const search = debounce(() => {
    if (page === "1") {
      getList("1");
    } else {
      navigate({
        pathname: pathname,
        search: `?page=${1}`,
      });
    }
  }, 200);

  const getTarget = async (): Promise<void> => {
    try {
      const res = await APIS.postData("/AdminNotice/AdmUrlTarget", {
        admin_table_id: userData.admin_table_id,
        url_table_id: id,
      });

      if (res.data.code === "OK") {
        const { data } = res;
        delete data.code;

        setDetail(data);
      } else {
        setDetail(undefined);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (id) {
      getTarget();
    }
  }, [id]);

  const create = async (params: CreateParams): Promise<boolean | undefined> => {
    const {
      seller_table_id,
      url_title,
      form_types,
      detail_page,
      btn_yn,
      btn_name,
    } = params;

    if (!seller_table_id || !url_title || !detail_page || !form_types.length) {
      alert("기본 정보 필수값을 입력해주세요.");
      return;
    }

    if (form_types.length === 0) {
      alert("항목이 선택되지 않았습니다.");
      return;
    }

    if (btn_yn && !btn_name) {
      alert("버튼 정보 필수값을 입력해주세요.");
      return;
    }

    try {
      const res = await APIS.postData(
        "/AdminNotice/AdmUrlCreate",
        {
          ...params,
          admin_table_id: userData.admin_table_id,
        },
        1
      );

      if (res.data.code === "OK") {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const getImage = (img?: string): string => {
    if (!img) {
      return "";
    }

    const isImage = img.includes("data:image");
    return isImage ? img : "";
  };

  const update = async (params: Item): Promise<boolean | undefined> => {
    const {
      seller_table_id,
      url_title,
      url_type,
      form_types,
      detail_page,
      btn_yn,
      btn_name,
    } = params;

    if (!seller_table_id || !url_title) {
      alert("기본 정보 필수값을 입력해주세요.");
      return;
    }

    if (url_type === "1" && form_types.length === 0) {
      alert("항목이 선택되지 않았습니다.");
      return;
    }

    if (url_type === "2" && !detail_page) {
      alert("상세 페이지가 등록되지 않았습니다.");
      return;
    }

    if (btn_yn && !btn_name) {
      alert("버튼 정보 필수값을 입력해주세요.");
      return;
    }

    try {
      const res = await APIS.postData(
        "/AdminNotice/AdmUrlUpdate",
        {
          ...params,
          admin_table_id: userData.admin_table_id,
          detail_page: getImage(detail_page),
        },
        1
      );

      if (res.data.code === "OK") {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  return {
    list,
    total,
    page,
    search,
    create,
    detail,
    update,
  };
};

export default useNoticeUrl;
