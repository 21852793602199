import React from "react";
import AdminLayout from "../../components/Layout/admin";
import { LifeConstructionList } from "../../components/LifeConstruction";

const LifeConstructionPage = () => {
  return (
    <AdminLayout title="생활시공 관리">
      <LifeConstructionList />
    </AdminLayout>
  );
};

export default LifeConstructionPage;
