import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as APIS from "../../utils/service";
import { open, close } from "../../redux/popupSlice";
import Input from "../../components/Input";
import FindIdSuc from "./FindIdSuc";

export default function FindId() {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [bn, setBn] = useState("");
  const [hp, setHp] = useState("");

  const [error, setError] = useState("");
  const [error2, setError2] = useState("");
  const [error3, setError3] = useState("");

  /* 아이디 찾기 */
  const findIdFunc = () => {
    /* 에러메시지 */
    if (!name) {
      setError("이름을 입력해주세요.");
      return;
    }
    if (!bn) {
      setError2("사업자등록번호를 입력해주세요.");
      return;
    }
    if (!hp) {
      setError3("휴대폰번호를 입력해주세요.");
      return;
    }

    const sender = {
      seller_name: name,
      seller_biz_no: bn,
      seller_phone: hp,
    };

    APIS.postData("/SellerInfo/FindID", sender, 2)
      .then((res) => {
        dispatch(close());
        if (res.data.code === "OK") {
          dispatch(
            open({
              title: "아이디 찾기",
              titleLong: false,
              message: "찾으시는 아이디 정보를 확인하세요!",
              component: <FindIdSuc fid={res.data.seller_id} />,
            })
          );
        } else {
          dispatch(
            open({
              title: "아이디 찾기",
              titleLong: false,
              message:
                "일치하는 정보가 존재 하지 않습니다.\n입력 하신 정보를 확인 후 인증 요청 부탁드립니다.",
            })
          );
        }
      })
      .catch((e) => {
        console.log("catch", e);
      });
    return;
  };

  return (
    <form className="popup_form">
      <Input
        className="input_text"
        type="text"
        placeholder="이름을 입력해 주세요."
        name="name"
        value={name}
        setValue={setName}
        label="*이름"
        error={error}
        setError={setError}
      />
      <Input
        className="input_text"
        type="text"
        placeholder="숫자만 입력해 주세요."
        name="bn"
        value={bn}
        setValue={setBn}
        label="*사업자등록번호"
        error={error2}
        valid={"num"}
        setError={setError2}
      />
      <Input
        className="input_text"
        type="text"
        placeholder="숫자만 입력해 주세요."
        name="hp"
        maxlength={11}
        value={hp}
        setValue={setHp}
        label="*휴대폰번호"
        error={error3}
        valid={"num"}
        setError={setError3}
      />
      <div className="popup_btn">
        <button
          type="button"
          className="popbtn ok_btn"
          onClick={() => {
            findIdFunc();
          }}
          style={{ width: "100%" }}
        >
          아이디 찾기
        </button>
      </div>
    </form>
  );
}
