import React, { Fragment, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import * as APIS from "../../utils/service";

import Input from "../../components/Input";
import InputSelect from "../../components/InputSelect";
import InputDate from "../../components/InputDate";

import { close } from "../../redux/popupSlice";

import consts from "../../libs/consts";

export default function EstimatePartnerState(props) {
  const { value, order_table_id } = props;
  const dispatch = useDispatch();
  const userData = useSelector((s) => s.usersReducer);

  const [choice, setChoice] = useState(value ? value[0].seller_table_id : "");

  const [cont_table_id, setcont_table_id] = useState(
    value && value[0].cont_table_id ? value[0].cont_table_id : ""
  );

  const [reg_date, setReg_date] = useState(
    value && value[0].reg_date
      ? moment(value[0].reg_date).format("YYYY/MM/DD HH:mm")
      : ""
  );
  const [end_date, setEnd_date] = useState(
    value && value[0].update_date
      ? moment(value[0].update_date).format("YYYY/MM/DD HH:mm")
      : ""
  );
  const [state, setState] = useState(value ? value[0].order_state : "");
  const [res_state, setRes_state] = useState(
    value ? value[0].order_resp_state : ""
  );
  const [meet_date, setMeet_date] = useState(
    value && value[0].meet_date && value[0].meet_date !== "0000-00-00 00:00:00"
      ? moment(value[0].meet_date).format("YYYY.MM.DD")
      : ""
  );
  const [meet_hour, setMeet_hour] = useState(
    value && value[0].meet_date && value[0].meet_date !== "0000-00-00 00:00:00"
      ? moment(value[0].meet_date).format("HH")
      : ""
  );
  const [meet_min, setMeet_min] = useState(
    value && value[0].meet_date && value[0].meet_date !== "0000-00-00 00:00:00"
      ? moment(value[0].meet_date).format("mm")
      : ""
  );

  const sellerOrderState = consts.sellerOrderState;
  const sellerOrderRespState = consts.sellerOrderRespState;
  const meetDisable = false;

  const [error, setError] = useState("");

  const chagneFunc = (x) => {
    setcont_table_id(x.cont_table_id ? x.cont_table_id : "");
    setReg_date(
      x.reg_date ? moment(x.reg_date).format("YYYY/MM/DD HH:mm") : ""
    );
    setEnd_date(
      x.update_date ? moment(x.update_date).format("YYYY/MM/DD HH:mm") : ""
    );
    setState(x.order_state);
    setRes_state(x.order_resp_state);
    setMeet_date(
      x.meet_date && x.meet_date !== "0000-00-00 00:00:00"
        ? moment(x.meet_date).format("YYYY.MM.DD")
        : ""
    );
    setMeet_hour(
      x.meet_date && x.meet_date !== "0000-00-00 00:00:00"
        ? moment(x.meet_date).format("HH")
        : ""
    );
    setMeet_min(
      x.meet_date && x.meet_date !== "0000-00-00 00:00:00"
        ? moment(x.meet_date).format("mm")
        : ""
    );
  };

  const submitFunc = () => {
    if (
      (state === "2" || state === "3" || state === "11") &&
      res_state !== "3" &&
      res_state !== "4" &&
      res_state !== "5"
    ) {
      setError("회신상태를 선택해주세요.");
      return;
    } else if (
      (state === "4" || state === "5" || state === "12") &&
      res_state !== "6" &&
      res_state !== "7"
    ) {
      setError("회신상태를 선택해주세요.");
      return;
    }

    const sender = {
      admin_table_id: userData.admin_table_id,
      order_table_id: order_table_id,
      seller_table_id: choice,
      meet_date: moment(meet_date + " " + meet_hour + ":" + meet_min).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      order_resp_state: res_state,
      order_state: state,
    };

    APIS.postData("/AdminOrder/OrderSellerChg", sender, 1)
      .then((res) => {
        if (res.data.code === "OK") {
          dispatch(close());
        }
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };

  return (
    <form className="popup_search">
      <div className="popup_search_box popup_estimate_partner_state">
        <div className="checkbox_section_partner_state">
          <label className="input_label">파트너 선택</label>
          <div>
            {value &&
              value.map((x, i) => {
                return (
                  <Fragment key={i}>
                    <input
                      type="radio"
                      id={"rdPState" + i}
                      name="rdPState"
                      className="radio"
                      value={x.seller_table_id}
                      checked={choice === x.seller_table_id}
                      onChange={(e) => {
                        setChoice(e.target.value);
                        chagneFunc(x);
                      }}
                    />
                    <label htmlFor={"rdPState" + i}>{x.seller_biz_name}</label>
                  </Fragment>
                );
              })}
          </div>
        </div>
        <div className={"one_line"} style={{ marginBottom: "0" }}>
          <Input
            className="input_text"
            type="text"
            name="cont_table_id"
            value={cont_table_id}
            setValue={setcont_table_id}
            label="계약번호"
            readOnly={true}
            full={true}
          />
        </div>

        <Input
          className="input_text"
          type="text"
          name="reg_date"
          value={reg_date}
          setValue={setReg_date}
          label="요청일시"
          readOnly={true}
        />
        <Input
          className="input_text"
          type="text"
          name="end_date"
          value={end_date}
          setValue={setEnd_date}
          label="최종일시"
          readOnly={true}
        />

        <InputSelect
          name="state"
          label="진행상태"
          value={state}
          setValue={setState}
          option={sellerOrderState}
        />
        <InputSelect
          name="res_state"
          label="회신상태"
          value={res_state}
          setValue={setRes_state}
          option={sellerOrderRespState}
          error={error}
          setError={setError}
        />

        <InputDate
          type="text"
          placeholder="날짜를 선택해주세요."
          name="meet_date"
          label="미팅일자"
          dateValue={meet_date}
          setDateValue={setMeet_date}
          readOnly={meetDisable}
        />
        <div
          className="input_select_mulit_box"
          style={{ width: "calc(50% - 12px)" }}
        >
          <InputSelect
            name="hour"
            placeholder="시"
            label="&emsp;"
            value={meet_hour}
            setValue={setMeet_hour}
            optionHour={true}
            readOnly={meetDisable}
          />
          <InputSelect
            name="min"
            placeholder="분"
            label=""
            value={meet_min}
            setValue={setMeet_min}
            optionMin={true}
            readOnly={meetDisable}
          />
        </div>
      </div>

      <div className="popup_btn">
        <button
          type="button"
          className="popbtn cencle_btn"
          onClick={() => {
            dispatch(close());
          }}
          style={{ width: "100%", marginRight: "10px" }}
        >
          취소
        </button>
        <button
          type="button"
          className="popbtn ok_btn"
          onClick={() => {
            submitFunc();
          }}
          style={{ width: "100%" }}
        >
          확인
        </button>
      </div>
    </form>
  );
}
