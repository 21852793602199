import React from "react";

import { useDispatch } from "react-redux";
import { numFormat, findJson } from "../../utils/utils";

import { closeSub } from "../../redux/subPopupSlice";
import consts from "../../libs/consts";

export default function ReceiptPopup(props) {
  const { data } = props;
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeSub());
  };

  return (
    <div className="receipt_popup">
      <div className="receipt_popup_top">
        <p>- 아파트 {data.case_size}평 기준으로 작석된 견적금액 입니다.</p>
        <p>- 평형별 또는 시공할 집 상태에 따라 견적 금액이 다를 수 있습니다.</p>
        <p>- 스타일링 제품 구매를 원치 않으시면 견적받기에서 뺄 수 있습니다.</p>
      </div>
      <div className="receipt_popup_total">
        <p className="receipt_popup_total_title">총 비용</p>
        <div className="receipt_popup_total_box">
          <p className="receipt_popup_total_subtitle1">
            {numFormat(data.case_work_price * 1)} 만원
          </p>
          <p className="receipt_popup_total_subtitle2">
            (시공 + 스타일링 제품 비용 포함)
          </p>
        </div>
      </div>
      <div className="receipt_popup_main">
        <div className="receipt_popup_main_title_box">
          <p>시공 / {data.case_size}평 기준</p>
          <p>
            {data.zone_parts.length > 0 &&
              numFormat(
                data.zone_parts
                  .map((x, i) => {
                    return x.case_price * 1;
                  })
                  .reduce((a, b) => {
                    return a + b;
                  }, 0)
              )}{" "}
            만원
          </p>
        </div>
        <ul className="receipt_popup_list1">
          {data.zone_parts.length > 0 &&
            data.zone_parts.map((x, i) => {
              return (
                <li key={i} className="receipt_popup_list1_box">
                  <div className="receipt_popup_list1_box1">
                    <p className="receipt_popup_list1_title1">
                      {findJson(consts.buildsoption, x.case_parts_no)}
                    </p>
                    <p className="receipt_popup_list1_title2">{x.case_desc}</p>
                  </div>
                  <p className="receipt_popup_list1_title3">
                    {numFormat(x.case_price)} 만원
                  </p>
                </li>
              );
            })}
        </ul>
        <ul className="receipt_popup_list2">
          <li className="receipt_popup_list2_title_box">
            <p className="receipt_popup_list2_title">스타일링 제품</p>
            <p className="receipt_popup_list2_title">
              {data.style_buy_chk === "1"
                ? numFormat(data.style_price) + " 만원"
                : "없음"}{" "}
            </p>
          </li>
          {data.style_buy_chk === "1" &&
            data.style_list.length > 0 &&
            data.style_list.map((x, i) => {
              return (
                <li key={i} className="receipt_popup_list2_box">
                  <p className="receipt_popup_list2_subtitle">{x.model_name}</p>
                  <p className="receipt_popup_list2_subtitle">
                    {numFormat(x.price)} 만원
                  </p>
                </li>
              );
            })}
        </ul>
      </div>
      <div className="receipt_popup_bottom">
        <buttion
          type="button"
          className="receipt_popup_cencle"
          onClick={() => handleClose()}
        >
          {" "}
          닫기
        </buttion>
      </div>
    </div>
  );
}
