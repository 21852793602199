/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import * as APIS from "../../utils/service";
import { numFormat, findJson, groupBy } from "../../utils/utils";

import { openSub } from "../../redux/subPopupSlice";
import { imgOpen } from "../../redux/imgSlice";

import InputSelect from "../../components/InputSelect";

import ReceiptPopup from "./ReceiptPopup";
import HomeStyleDetailPopup from "./HomeStyleDetailPopup";

import consts from "../../libs/consts";

export default function RealAdd(props) {
  const { data } = props;
  const dispatch = useDispatch();
  const userData = useSelector((s) => s.usersReducer);

  const [active, setActive] = useState("");

  const [list1, setList1] = useState();
  const [groupZone, setGroupZone] = useState();
  const [list2, setList2] = useState();
  const [cker, setCker] = useState("");
  const [state, setState] = useState("");
  const [stateSelect, setStateSelect] = useState("");

  useEffect(() => {
    if (data) {
      const sender = {
        admin_table_id: userData.admin_table_id,
        case_table_id: data.case_table_id,
        cont_table_id: data.cont_table_id,
      };

      APIS.postData("/AdminReal/RealTarget", sender)
        .then((res) => {
          setList1(res.data);
          setState(res.data.case_state);
          setStateSelect(res.data.case_state);

          const groupByZone = groupBy(res.data.case_parts, "case_parts_type");
          setGroupZone(groupByZone);
          setActive(
            groupByZone[Object.keys(groupByZone)[0]][0].case_parts_type
          );
        })
        .catch((e) => {
          console.log("catch", e);
        });
    }
  }, []);
  useEffect(() => {
    if (active && groupZone) {
      setList2(groupZone[active]);
    }
  }, [groupZone, active]);

  useEffect(() => {
    if (cker === "1") {
      setState(stateSelect);

      const sender = {
        admin_table_id: userData.admin_table_id,
        case_table_id: data.case_table_id,
        case_state: stateSelect,
      };

      APIS.postData("/AdminReal/RealChg", sender)
        .catch((e) => {
          console.log("catch", e);
        })
        .finally(() => {
          setCker("");
        });
    }
  }, [cker]);

  const stateAlert = () => {
    dispatch(
      openSub({
        title: "상태 변경",
        component: (
          <InputSelect
            name="paySelect"
            label="상태"
            value={stateSelect}
            setValue={setStateSelect}
            option={consts.instanceStateoption}
          />
        ),
        one: true,
        button: "변경",
        buttonCencle: "취소",
        onPress: () => setCker("1"),
      })
    );
  };

  const receipt = () => {
    dispatch(
      openSub({
        title: "영수증",
        mideum: true,
        component: <ReceiptPopup data={list1} />,
        buttonCencle: "취소",
      })
    );
  };

  const home_style_detail = () => {
    dispatch(
      openSub({
        title: "홈스타일링 제품보기",
        wideOne: true,
        component: <HomeStyleDetailPopup data={list1} />,
      })
    );
  };

  return (
    <div className="popup_list">
      <div className="popup_list_ul popup_real">
        {list1 && (
          <ul className="list_style4 real_list">
            <li>
              <p className="title">상태</p>
              <p className="content">
                {findJson(consts.instanceStateoption, state)}
                <button type="button" onClick={() => stateAlert()}>
                  변경
                </button>
              </p>
              <p className="title">요청번호</p>
              <p className="content link">{data.order_table_id}</p>
            </li>
            <li>
              <p className="title">계약번호</p>
              <p className="content link">{data.cont_table_id}</p>
              <p className="title">견적유형</p>
              <p
                className={
                  "content" + (data.order_estimate_type !== "0" ? " link" : "")
                }
              >
                {findJson(consts.sellerOrderType, data.order_estimate_type)}{" "}
                {data.order_estimate_type !== "0" &&
                  "(" + list1.order_fk_id + ")"}
              </p>
            </li>
            <li>
              <p className="title">주거유형</p>
              <p className="content">
                {findJson(consts.workoption, list1.case_home_type)}
              </p>
            </li>

            <li>
              <p className="title">시공지역</p>
              <p className="content">
                {list1.area_1st_text} {list1.area_2nd_text}
              </p>
              <p className="title">아파트명</p>
              <p className="content">{list1.case_name}</p>
            </li>
            {/* <li className="full">
                        <p className="title">공사지 주소</p>
                        <p className="content">서울시 구로구 행복아파트 1001호</p>
                    </li> */}

            <li className="list_style_long">
              <p className="title">시공범위</p>
              <p className="content">
                {findJson(consts.rangeoption, data.work_type)}
              </p>
              <p className="title">시공정보</p>
              <p className="content">{list1.case_work_type}</p>
            </li>

            <li>
              <p className="title">시공시작일</p>
              <p className="content">{list1.case_work_start.substr(0, 10)}</p>
              <p className="title">시공종료일</p>
              <p className="content">{list1.case_work_end.substr(0, 10)}</p>
            </li>

            <li>
              <p className="title">시공비용</p>
              <p className="content">
                {numFormat(list1.case_work_price)}만원
                <button type="button" onClick={() => receipt()}>
                  영수증보기
                </button>
              </p>
              <p className="title">홈스타일비용</p>
              <p className="content">
                {list1.style_buy_chk === "1" ? (
                  <>
                    {numFormat(list1.style_price)}만원
                    <button type="button" onClick={() => home_style_detail()}>
                      정보보기
                    </button>
                  </>
                ) : (
                  "없음"
                )}
              </p>
            </li>

            <li className="full">
              <p className="title">공간구성</p>
              <p className="content">{list1.case_work_parts}</p>
            </li>

            <li>
              <p className="title imgTitle">도면</p>
              <p className="content imgContent">
                {list1.case_drawing && (
                  <img
                    src={list1.case_drawing}
                    alt="case_drawing"
                    onClick={() =>
                      dispatch(
                        imgOpen({
                          imgs: [{ src: list1.case_drawing }],
                          active: 0,
                        })
                      )
                    }
                  />
                )}
              </p>
              <p className="title imgTitle">메인사진</p>
              <p className="content imgContent">
                {list1.case_photo && (
                  <img
                    src={list1.case_photo}
                    alt="case_photo"
                    onClick={() =>
                      dispatch(
                        imgOpen({
                          imgs: [{ src: list1.case_photo }],
                          active: 0,
                        })
                      )
                    }
                  />
                )}
              </p>
            </li>
          </ul>
        )}

        <div className="bar" />

        <div className="pop_progress">
          {groupZone &&
            consts.partsoption.map((x) => {
              if (!groupZone[x.idx]) return null;
              return (
                <p
                  className={active === x.idx ? "active" : ""}
                  onClick={() => setActive(x.idx)}
                >
                  {x.title}
                </p>
              );
            })}
        </div>

        <div className="popup_real_bottom">
          <div className="instance_form_list estimate_form_list">
            {list2 &&
              list2.map((x, i) => {
                return (
                  <div className="real_img_box" key={i}>
                    <div className="real_img_multi_box">
                      <img
                        className="real_img_multi"
                        alt="real_img_multi"
                        src={x.case_zone_img[0]}
                        onClick={() =>
                          dispatch(
                            imgOpen({
                              imgs: [{ src: x.case_zone_img[0] }],
                              active: 0,
                            })
                          )
                        }
                      />
                      <p className="title">시공전</p>
                    </div>
                    <div className="real_img_multi_box">
                      <img
                        className="real_img_multi"
                        alt="real_img_multi"
                        src={x.case_zone_img_2[0]}
                        onClick={() =>
                          dispatch(
                            imgOpen({
                              imgs: [{ src: x.case_zone_img_2[0] }],
                              active: 0,
                            })
                          )
                        }
                      />
                      <p className="title">시공후</p>
                    </div>

                    <p className="sub_title">{x.case_desc}</p>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}
