/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import * as APIS from "../../../utils/service";
import { useSelector } from "react-redux";
import debounce from "lodash/debounce";
import { useNavigate } from "react-router-dom";
import {
  ListTypes,
  BasicInfoType,
  AddInfoType,
  Item,
  URLTypes,
} from "./noticeEvent.type";

interface Props {
  isList?: boolean;
  searchData?: {
    avt_state?: string;
    start_date?: string;
    end_date?: string;
    avt_type?: string;
    seller_biz_name?: string;
  };
  id?: string;
}

const useNoticeEvent = (props?: Props) => {
  const { isList, searchData, id } = props ?? {};
  const userData = useSelector((s: any) => s.usersReducer);
  const navigate = useNavigate();
  const { pathname } = window.location;

  const [list, setList] = useState<ListTypes[]>([]);
  const [total, setTotal] = useState(0);

  const params = new URLSearchParams(window.location.search);
  const page = params.get("page") || "1";

  const [detail, setDetail] = useState<Item>();
  const [urlList, setUrlList] = useState<URLTypes[]>([]);

  const getList = async (page: string) => {
    const params = {
      admin_table_id: userData.admin_table_id,
      page: page,
      ...searchData,
    };

    try {
      const res = await APIS.postData("/AdminNotice/AdmEventList", params, 1);

      if (res.data.code === "OK") {
        setList(res.data.avt_list);
        setTotal(res.data.total_cnt);
      } else {
        setList([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (isList) {
      getList(page);
    }
  }, [page]);

  const getUrlList = async () => {
    try {
      const res = await APIS.postData(
        "/AdminNotice/AdmUrlListTotal",
        {
          admin_table_id: userData.admin_table_id,
        },
        1
      );

      if (res.data.code === "OK") {
        setUrlList(res.data.url_list);
      } else {
        setUrlList([]);
      }
    } catch (err) {
      console.log(err);
    }
  };
  /**
   * 광고 관리 > URL 리스트를 전체 불러온다.
   * 조건 = isList 값이 없을 때 (create, update) 호출
   */

  useEffect(() => {
    if (!isList) {
      getUrlList();
    }
  }, [isList]);

  const search = debounce(() => {
    if (page === "1") {
      getList("1");
    } else {
      navigate({
        pathname: pathname,
        search: `?page=${1}`,
      });
    }
  }, 200);

  const getTarget = async (): Promise<void> => {
    try {
      const res = await APIS.postData("/AdminNotice/AdmEventTarget", {
        admin_table_id: userData.admin_table_id,
        avt_table_id: id,
      });

      if (res.data.code === "OK") {
        const { data } = res;
        delete data.code;

        setDetail(data);
      } else {
        setDetail(undefined);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (id) {
      getTarget();
    }
  }, [id]);

  const create = async (params: {
    basicInfo: BasicInfoType;
    addInfo: AddInfoType;
    avt_type: "1" | "2";
  }): Promise<boolean | undefined> => {
    const { basicInfo, addInfo, avt_type } = params;

    const {
      avt_title,
      avt_thumbnail,
      avt_start_date,
      avt_end_date,
      url_table_id,
    } = basicInfo;

    if (
      !avt_title ||
      !avt_thumbnail ||
      !avt_start_date ||
      !avt_end_date ||
      !url_table_id
    ) {
      alert("기본 정보 필수값을 입력해주세요.");
      return;
    }

    const { hero_banner, line_banner, center_banner, pop_banner } = addInfo;

    if (
      hero_banner &&
      (!hero_banner.hero_thumbnail_pc ||
        !hero_banner.hero_thumbnail_mo ||
        !hero_banner.hero_url)
    ) {
      alert("히어로 배너 필수값을 입력해주세요.");
      return;
    }

    if (line_banner && (!line_banner.line_title || !line_banner.line_url)) {
      alert("띠 배너 필수값을 입력해주세요.");
      return;
    }

    if (
      center_banner &&
      (!center_banner.center_state ||
        !center_banner.center_title ||
        !center_banner.center_thumbnail ||
        !center_banner.center_url)
    ) {
      alert("중앙 배너 필수값을 입력해주세요.");
      return;
    }

    if (
      pop_banner &&
      (!pop_banner.pop_thumbnail_pc ||
        !pop_banner.pop_thumbnail_mo ||
        !pop_banner.pop_url)
    ) {
      alert("팝업 필수값을 입력해주세요.");
      return;
    }

    try {
      const params = {
        ...basicInfo,
        ...addInfo,
        avt_type: avt_type,
        seller_table_id: basicInfo.seller_table_id,
        seller_biz_name: basicInfo.seller_biz_name,
        ...(addInfo.hero_banner && { hero_banner: addInfo.hero_banner }),
        ...(addInfo.line_banner && { line_banner: addInfo.line_banner }),
        ...(addInfo.center_banner && { center_banner: addInfo.center_banner }),
        ...(addInfo.pop_banner && { pop_banner: addInfo.pop_banner }),
      };

      const res = await APIS.postData(
        "/AdminNotice/AdmEventCreate",
        {
          ...params,
          admin_table_id: userData.admin_table_id,
        },
        1
      );

      if (res.data.code === "OK") {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const getImage = (img?: string): string => {
    if (!img) {
      return "";
    }

    const isImage = img.includes("data:image");
    return isImage ? img : "";
  };

  const update = async (data: Item): Promise<boolean | undefined> => {
    const {
      hero_banner,
      line_banner,
      center_banner,
      pop_banner,
      avt_title,
      avt_thumbnail,
      avt_start_date,
      avt_end_date,
      url_table_id,
    } = data;

    if (
      !avt_title ||
      !avt_thumbnail ||
      !avt_start_date ||
      !avt_end_date ||
      !url_table_id
    ) {
      alert("기본 정보 필수값을 입력해주세요.");
      return;
    }

    if (
      hero_banner &&
      (!hero_banner.hero_thumbnail_pc ||
        !hero_banner.hero_thumbnail_mo ||
        !hero_banner.hero_url)
    ) {
      alert("히어로 배너 필수값을 입력해주세요.");
      return;
    }

    if (line_banner && (!line_banner.line_title || !line_banner.line_url)) {
      alert("띠 배너 필수값을 입력해주세요.");
      return;
    }

    if (
      center_banner &&
      (!center_banner.center_state ||
        !center_banner.center_title ||
        !center_banner.center_thumbnail ||
        !center_banner.center_url)
    ) {
      alert("중앙 배너 필수값을 입력해주세요.");
      return;
    }

    if (
      pop_banner &&
      (!pop_banner.pop_thumbnail_pc ||
        !pop_banner.pop_thumbnail_mo ||
        !pop_banner.pop_url)
    ) {
      alert("팝업 필수값을 입력해주세요.");
      return;
    }

    try {
      const res = await APIS.postData(
        "/AdminNotice/AdmEventUpdate",
        {
          ...data,
          seller_biz_name: undefined,
          seller_table_id: undefined,
          admin_table_id: userData.admin_table_id,
          avt_thumbnail: getImage(data.avt_thumbnail),
          ...(hero_banner
            ? {
                hero_banner: {
                  ...hero_banner,
                  hero_thumbnail_pc: getImage(hero_banner?.hero_thumbnail_pc),
                  hero_thumbnail_mo: getImage(hero_banner?.hero_thumbnail_mo),
                },
              }
            : {
                hero_banner: undefined,
              }),
          line_banner: line_banner || undefined,
          ...(center_banner
            ? {
                center_banner: {
                  ...center_banner,
                  center_thumbnail: getImage(center_banner?.center_thumbnail),
                },
              }
            : { center_banner: undefined }),
          ...(pop_banner
            ? {
                pop_banner: {
                  ...pop_banner,
                  pop_thumbnail_pc: getImage(pop_banner?.pop_thumbnail_pc),
                  pop_thumbnail_mo: getImage(pop_banner?.pop_thumbnail_mo),
                },
              }
            : { pop_banner: undefined }),
        },
        1
      );

      if (res.data.code === "OK") {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  return {
    list,
    total,
    page,
    search,
    create,
    update,
    detail,
    urlList,
  };
};

export default useNoticeEvent;
