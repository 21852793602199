import React from "react";
import AdminLayout from "../../../components/Layout/admin";
import useHome from "./home.hook";
import HomeManagePick from "../../../components/HomeManage/Pick";

const HomeManagePickPage = () => {
  const { tabs } = useHome();

  return (
    <AdminLayout title="홈 화면 관리" tabs={tabs}>
      <HomeManagePick />
    </AdminLayout>
  );
};

export default HomeManagePickPage;
