import React from "react";
import AdminLayout from "../../../components/Layout/admin";
import useHome from "./home.hook";
import HomeManagePopup from "../../../components/HomeManage/Popup";

const HomeManagePopupPage = () => {
  const { tabs } = useHome();

  return (
    <AdminLayout title="홈 화면 관리" tabs={tabs}>
      <HomeManagePopup />
    </AdminLayout>
  );
};

export default HomeManagePopupPage;
