import React from "react";

import { useSelector } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import routes from "./libs/routes";

import Popup from "./redux-components/Popup";
import SubPopup from "./redux-components/SubPopup";
import User from "./redux-components/User";
import Loading from "./redux-components/Loading";
import Imgs from "./redux-components/Imgs";

import Layout from "./Layout";

import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
import Clause from "./pages/clause/Clause";
import Admin from "./pages/admin/Admin";
import PGCallBack from "./pages/pg/PGCallback";

function Router() {
  const userData = useSelector((s) => s.usersReducer);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route exact path={routes.clause} element={<Clause />} />
          <Route exact path={routes.PGCallBack} element={<PGCallBack />} />

          {/* 로그인 유무 확인*/}
          {!userData.isLoggedIn ? (
            <>
              <Route exact path={routes.login} element={<Login />} />{" "}
              {/* 파트너 로그인 페이지 */}
              <Route exact path={routes.register} element={<Register />} />{" "}
              {/* 파트너 회원가입 페이지 */}
              <Route exact path={routes.admin} element={<Admin />} />{" "}
              {/* 관리자 로그인 페이지 */}
              {/* 빌드시 Login으로 연결 */}
              <Route exact path="*" element={<Login />} />
              {/* <Route exact path="*" element={ <FrontHome /> } /> */}
            </>
          ) : (
            <Route exact path="*" element={<Layout />} />
          )}
        </Routes>

        <Popup />
        <SubPopup />
        <User />
        <Loading />
        <Imgs />
      </BrowserRouter>
    </>
  );
}

export default Router;
