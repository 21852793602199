import React, { useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import Modal from "../../../Common/ModalPortal";
import images from "../../../../libs/images";
import Button from "../../../Common/Button/Button";
import * as Colors from "../../../../constants/color";
import Input from "../../../Common/Input/Input";
import Select from "../../../Common/Select/Select";
import Upload, { FileData } from "../../../Common/Upload/Upload";
import useHomeManageLifeMonthly from "../homeManageLifeMonthly.hook";
import consts from "../../../../libs/consts";

const Container = styled.div`
  width: 560px;
  background-color: #ffffff;
  margin: auto;
`;

const Header = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${Colors.gray_200};

  & > h3 {
    margin: auto 0;
  }
`;

const Content = styled.div`
  padding: 20px 20px 20px 20px;
`;

const Footer = styled.div`
  padding: 20px;
  border-top: 1px solid ${Colors.gray_200};
  display: flex;
  justify-content: center;
  gap: 15px;
`;

interface Props {
  close: () => void;
  callback: () => void;
}

const CreateHomeManageLifeMonthly = (props: Props) => {
  const { create } = useHomeManageLifeMonthly();

  const { close, callback } = props;

  const [data, setData] = useState({
    title: "",
    image: "",
    url: "",
    isApplication: ""
  })

  const createHandler = async () => {
    const params = {
      title: data.title,
      isApplication: data.isApplication,
      url: data.url,
      image: data.image,
    };

    const res = await create(params);

    if (typeof res === "boolean") {
      if (res) {
        callback();
      } else {
        alert("등록중 오류가 발생하였습니다.");
      }
    }
  };

  const changeFileHandler = ({ fileImage }: FileData) => {
    setData((prevData) => ({
      ...prevData,
      image: fileImage,
    }));
  };

  const setDataHandler = (name: string, value: string) => {
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <Modal>
      <Container>
        <Header>
          <h3>이달의 공구 등록</h3>
          <button onClick={close}>
            <img src={images.exit} alt="exit_btn" />
          </button>
        </Header>
        <Content>
          <Input
            label="* 타이틀 명"
            placeholder="타이틀 작성"
            onChange={(e) => setDataHandler('title', e.target.value)}
          />
        </Content>
        <Content>
          <div
            css={css`
            width: 130px;
          `}
          >
            <Select
              label="구분"
              defaultValue="전체"
              options={consts.life_monthly_application_options.map((item) => ({
                value: item.idx,
                label: item.title,
              }))}
              size="full"
              onChange={(e) => setDataHandler("isApplication", e.target.value)}
            />
          </div>
        </Content>
        <Content>
          <Input
            label="* URL 예시)/event/11"
            onChange={e => setDataHandler('url', e.target.value)}
          />
        </Content>
        <Content>
          <Upload
            id="upload"
            label="* 이미지 등록"
            placeholder="이미지를 첨부해주세요."
            callback={(file) => changeFileHandler(file)}
          />
        </Content>
        <Footer>
          <Button theme="tertiary" onClick={close}>
            취소
          </Button>
          <Button onClick={createHandler}>등록</Button>
        </Footer>
      </Container>
    </Modal>
  );
};

export default CreateHomeManageLifeMonthly;
