import React, { useState } from "react";
import consts from "../../../libs/consts";
import Table from "../../Common/Table/Table";
import { css } from "@emotion/react";
import { findJson, findJsonCl } from "../../../utils/utils";
import moment from "moment";
import Pagination from "../../Common/Pagination/Pagination";
import useNotice from "../notice.hook";
import Button from "../../Common/Button/Button";
import InputWithButton from "../../Common/Input/InputWithButton";
import Select from "../../Common/Select/Select";

const NoticeUser = () => {
  const { list, viewFunc, addFunc, total, page } = useNotice(2);

  const [search, setSearch] = useState("");

  const col = [
    { key: "notice_table_id", title: "No" },
    {
      key: "noticeType",
      title: "구분",
      render: (x: any) => (
        <span>{findJson(consts.noticeTypeUser, x.n_type)}</span>
      ),
    },
    { key: "thumbnail", title: "썸네일", render: () => <span>--</span> },
    { key: "title", title: "이벤트명" },
    {
      key: "duration",
      title: "운영기간",
      render: () => <span>--</span>,
    },
    {
      key: "available",
      title: "잔여 운영일",
      render: () => <span>--</span>,
    },
    {
      key: "update_date",
      title: "최종 수정 일시",
      render: (x: any) => (
        <span>{moment(x.update_date).format("YYYY.MM.DD")}</span>
      ),
    },
    {
      key: "state",
      title: "상태",
      render: (x: any) => (
        <span className={findJsonCl(consts.noticeState, x.state)}>
          {findJson(consts.noticeState, x.state)}
        </span>
      ),
    },
    {
      key: "delete",
      title: "삭제",
      render: (data: any) => (
        <button
          onClick={(e) => {
            e.stopPropagation();
            if (window.confirm("삭제하시겠습니까?")) {
              console.log("!");
            }
          }}
        >
          삭제
        </button>
      ),
    },
  ];
  return (
    <div>
      <div
        css={css`
          margin-top: 24px;
          display: flex;
          flex-wrap: wrap;
        `}
      >
        <div
          css={css`
            margin-right: 30px;
          `}
        >
          <InputWithButton
            label="검색"
            buttonText="검색"
            placeholder="파트너 명"
            onClick={() => console.log(search)}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div>
          <Select
            size="small"
            label="등록 상태"
            defaultValue="선택"
            options={[]}
          />
        </div>
        <div
          css={css`
            margin: auto 0 0 auto;
          `}
        >
          <Button onClick={() => addFunc()}>등록</Button>
        </div>
      </div>

      <div
        css={css`
          margin: 30px 0 20px 0;
        `}
      >
        <Table
          columns={col}
          datas={list}
          rowCallback={(x) => viewFunc(x.notice_table_id)}
        />
      </div>
      <Pagination curPage={page} total={total} pageSize={15} />
    </div>
  );
};

export default NoticeUser;
