// import axios from 'axios';
import axios from "../utils/axios";

export const getList = async (params) => {
  const res = await axios.post(`/AdminLifeOrder/LifeOrderList`, params);

  return res.data;
};

export const getDetailItem = async (params) => {
  const res = await axios.post(`/AdminLifeOrder/LifeOrderTarget`, params);

  return res.data;
};
