import React from "react";

import Help from "../components/Help";

export default function InfoBox(props) {
  const { children, help, msg } = props;

  return (
    <div className="infoBox">
      {help && <Help msg={msg} />}
      {children}
    </div>
  );
}
