import React, { useState } from "react";
import { css } from "@emotion/react";
import Table from "../../Common/Table/Table";
import Select from "../../Common/Select/Select";
import Button from "../../Common/Button/Button";
import Pagination from "../../Common/Pagination/Pagination";
import moment from "moment";
import CreateNoticeEvent from "./Create";
import UpdateNoticeEvent from "./Update";
import useNoticeEvent from "./noticeEvent.hook";
import DateRange from "../../Common/Date/DateRange";
import Input from "../../Common/Input/Input";
import * as Colors from "../../../constants/color";
import {
  getEventType,
  handlerImgError,
  getEventState,
} from "../../../utils/home-manage";
import { eventTypeList, eventStateList } from "../../../constants/home-manage";
import { CSVLink } from "react-csv";
import useCsv from "../../../hooks/useCsv";

const exposureAreaStyles = css`
  & > p {
    border: 1px solid ${Colors.gray_300};
    background-color: ${Colors.gray_100};

    font-size: 12px;
    margin-bottom: 5px;
    text-align: center;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const NoticeEvent = () => {
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [id, setId] = useState<string | undefined>();

  const [dateData, setDateData] = useState({
    start_date: "",
    end_date: "",
  });

  const [searchData, setSearchData] = useState({
    avt_state: "",
    avt_type: "",
    seller_biz_name: "",
  });

  const { list, total, page, search } = useNoticeEvent({
    isList: true,
    searchData: {
      start_date: dateData.start_date,
      end_date: dateData.end_date,
      ...searchData,
    },
  });

  const { csvLinkRef, getCsvHeader, downloadHandler } = useCsv();

  const changeDateHandler = (type: string, date?: Date) => {
    if (date) {
      setDateData((prevData) => ({
        ...prevData,
        ...(type === "start" && {
          start_date: moment(date).format("YYYY-MM-DD 00:00:00"),
        }),
        ...(type === "end" && {
          end_date: moment(date).format("YYYY-MM-DD 23:59:59"),
        }),
      }));
    }
  };

  const changeSelectHandler = (
    e: React.ChangeEvent<HTMLSelectElement>,
    filed: string
  ) => {
    const { value } = e.target;

    setSearchData((prevData) => ({
      ...prevData,
      [filed]: value,
    }));
  };

  const changeInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setSearchData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const col = [
    { key: "avt_table_id", title: "번호" },
    {
      key: "avt_type",
      title: "구분",
      render: (data: any) => <span>{getEventType(data.avt_type)}</span>,
    },
    { key: "seller_biz_name", title: "파트너 명" },
    {
      key: "avt_thumbnail",
      title: "썸네일",
      render: (data: any) => (
        <div
          css={css`
            width: 40px;
            height: 40px;
          `}
        >
          {data.avt_thumbnail && (
            <img
              src={data.avt_thumbnail}
              onError={handlerImgError}
              alt="pick_img"
              css={css`
                width: 100%;
                height: 100%;
              `}
            />
          )}
        </div>
      ),
    },
    { key: "avt_title", title: "이벤트명" },
    {
      key: "avt_days",
      title: "운영기간",
    },
    {
      key: "remain_day",
      title: "잔여 운영일",
    },
    {
      key: "update_date",
      title: "최종 수정 일시",
      render: (x: any) => (
        <span>{moment(x.update_date).format("YYYY.MM.DD")}</span>
      ),
    },
    {
      key: "state",
      title: "상태",
      render: (data: any) => <span>{getEventState(data.avt_state)}</span>,
    },
    {
      key: "show_location",
      title: "노출 영역",
      render: (data: any) => (
        <div css={[exposureAreaStyles]}>
          {data.is_hero_banner === "1" && <p>히어로 배너</p>}
          {data.is_line_banner === "1" && <p>띠배너</p>}
          {data.is_center_banner === "1" && <p>중앙배너</p>}
          {data.is_pop_banner === "1" && <p>팝업</p>}
        </div>
      ),
    },
  ];

  const callback = () => {
    setIsOpenCreate(false);
    setId(undefined);
    search();
  };

  return (
    <div>
      <div
        css={css`
          margin-top: 24px;
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
        `}
      >
        <div>
          <DateRange
            label="날짜 선택"
            startPlaceholder="시작날짜"
            startValue={
              dateData.start_date ? new Date(dateData.start_date) : ""
            }
            endPlaceholder="종료날짜"
            endValue={dateData.end_date ? new Date(dateData.end_date) : ""}
            onChange={changeDateHandler}
          />
        </div>
        <div
          css={css`
            width: 130px;
          `}
        >
          <Select
            label="구분"
            defaultValue="전체"
            options={eventTypeList}
            size="full"
            onChange={(e) => changeSelectHandler(e, "avt_type")}
          />
        </div>
        <div
          css={css`
            width: 130px;
          `}
        >
          <Select
            size="full"
            label="상태"
            defaultValue="전체"
            options={eventStateList}
            onChange={(e) => changeSelectHandler(e, "avt_state")}
          />
        </div>
        <div>
          <Input
            label="파트너 명"
            placeholder="입력"
            size="small"
            name="seller_biz_name"
            onChange={changeInputHandler}
          />
        </div>
        <div
          css={css`
            width: 130px;
            margin: auto 10px 0 0;
          `}
        >
          <Button theme="tertiary" onClick={search}>
            검색
          </Button>
        </div>
        <div
          css={css`
            width: 130px;
            margin: auto 0 0 0;
          `}
        >
          <Button theme="tertiary" onClick={() => downloadHandler()}>
            엑셀 다운로드
          </Button>
          <CSVLink
            data={list.map((l: any) => {
              const bannerTypes = {
                is_center_banner: "중앙 배너",
                is_hero_banner: "히어로 배너",
                is_line_banner: "띠 배너",
                is_pop_banner: "팝업 배너",
              };

              const getShowLocation = Object.entries(bannerTypes)
                .filter(([prop]) => l[prop] === "1")
                .map(([, value]) => value);

              return {
                ...l,
                avt_type: getEventType(l.avt_type),
                state: getEventState(l.avt_state),
                show_location: getShowLocation,
              };
            })}
            ref={csvLinkRef}
            headers={getCsvHeader(col)}
            filename={
              "광고관리_이벤트" +
              moment(new Date()).format("YYYY_MM_DD(HH)") +
              ".csv"
            }
          />
        </div>
        <div
          css={css`
            margin: auto 0 0 auto;
          `}
        >
          <Button onClick={() => setIsOpenCreate(true)}>등록</Button>
        </div>
      </div>
      <div
        css={css`
          margin: 30px 0;
        `}
      >
        <Table
          columns={col}
          datas={list}
          rowCallback={(data) => setId(data.avt_table_id)}
        />
      </div>

      <Pagination curPage={page} total={total} pageSize={20} />

      {isOpenCreate && (
        <CreateNoticeEvent
          close={() => setIsOpenCreate(false)}
          callback={callback}
        />
      )}
      {id && (
        <UpdateNoticeEvent
          close={() => setId(undefined)}
          callback={callback}
          id={id}
        />
      )}
    </div>
  );
};

export default NoticeEvent;
