import React from "react";
import { css } from "@emotion/react";

export default function TabTitle(props) {
  const { num, color } = props;

  const constructionTitles = [
    "시공정보",
    "시공 가격 및 시공 사진 등록",
    "추가정보(선택사항)",
  ];
  const constructionSubTitles = [
    "시공사례의 정보를 입력해주세요.",
    `[영수증 정보] 입력 시 "별도의 배지" 형태로 노출되어 소비자의 시공 의뢰율이 증가합니다. 업로드 한 이미지는 승인 절차를 거쳐 사이트에 반영됩니다.`,
    "기준 시공가격 외의 다른 평당의 평당 가격을 입력할 수 있습니다.",
  ];

  return (
    <>
      <p className="require_msg">(*)표시는 필수항목 입니다.</p>
      <p className="title">
        <span>{num}</span>
        {constructionTitles[num - 1]}
      </p>
      <p
        className="sub_title"
        css={css`
          color: ${color} !important;
        `}
      >
        {constructionSubTitles[num - 1]}
      </p>
    </>
  );
}
