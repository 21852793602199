/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";

const useSeq = <T extends Partial<T>>({
  list,
  key_id,
  key_seq,
}: {
  list: T[];
  key_id: keyof T;
  key_seq: keyof T;
}) => {
  const [isUpdateSeq, setIsUpdateSeq] = useState(false);

  const [seqList, setSeqList] = useState<
    {
      id: string;
      seq: string;
      isChanged?: boolean;
    }[]
  >([]);

  useEffect(() => {
    if (list.length > 0 && !isUpdateSeq) {
      setSeqList(
        list.map((item) => ({
          id: item[key_id] as string,
          seq: item[key_seq] as string,
          isChanged: false,
        }))
      );
    }
  }, [list, isUpdateSeq]);

  const updateSeqHandler = (value: boolean) => {
    setIsUpdateSeq(value);
  };

  const updateTableInputHandler = (
    id: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSeqList((prev) =>
      prev.map((item) =>
        item.id === id
          ? { ...item, seq: e.target.value, isChanged: true }
          : item
      )
    );
  };

  return {
    seqList,
    isUpdateSeq,
    updateSeqHandler,
    updateTableInputHandler,
  };
};

export default useSeq;
