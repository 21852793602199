import React, {useEffect}  from 'react';

import { Provider } from 'react-redux';
import store, { persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';

import Router from './Router';

import "./assets/css/common.css";
import "./assets/css/layout.css";
import "./assets/css/media.css";

function App() {
    function setScreenSize() {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
    }
    useEffect(() => {
        setScreenSize();
    });
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
          <Router />

      </PersistGate>
    </Provider>

  );
}

export default App;
