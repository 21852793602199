import React, { useEffect, useState } from "react";

import * as APIS from "../utils/service";

export default function InputSelectArea(props) {
  const {
    name,
    value,
    setValue,
    area,
    setArea,
    setValueText,
    setCityText,
    label,
    error,
    success,
    readOnly,
    optionLabel,
  } = props;

  const [cityList, setCityList] = useState("");
  const [areaList, setAreaList] = useState("");

  useEffect(() => {
    APIS.postData("/AreaInfo/GetAreaOne", "", 2)
      .then((res) => {
        setCityList(res.data.area);

        if (area) {
          const sender = {
            area_id: value,
          };
          APIS.postData("/AreaInfo/GetAreaTwo", sender, 2)
            .then((res) => {
              setAreaList(res.data.area);
            })
            .catch((e) => {
              console.log("catch", e);
            });
        }
      })
      .catch((e) => {
        console.log("catch", e);
      });
  }, [area, value]);

  useEffect(() => {
    if (area && area.substr(0, 2) !== value && setArea) setArea("");

    const sender = {
      area_id: value,
    };

    APIS.postData("/AreaInfo/GetAreaTwo", sender, 2)
      .then((res) => {
        setAreaList(res.data.area);
      })
      .catch((e) => {
        console.log("catch", e);
      });
  }, [area, setArea, value]);

  const handleChange = (e) => {
    if (setValue) {
      setValue(e.target.value);
    }
    if (setCityText) setCityText(e.target.options[e.target.selectedIndex].text);

    if (setArea) {
      setArea("");
    }

    if (setValueText) setValueText("");
  };
  const handleChangeArea = (e) => {
    if (setArea) {
      setArea(e.target.value);
    }
    if (setValueText)
      setValueText(e.target.options[e.target.selectedIndex].text);
  };

  return (
    <div className="input_box">
      <label className="input_label" htmlFor={name}>
        {label}
      </label>
      {optionLabel && <span className="input_option_label">{optionLabel}</span>}
      <div className="input_section input_section_area">
        <select
          onChange={handleChange}
          value={value}
          className={readOnly && "disable"}
          disabled={readOnly}
        >
          <option value="">도&#183;시</option>
          {cityList &&
            cityList.map((x, i) => {
              return (
                <option key={i} value={x.area_id}>
                  {x.area_name}
                </option>
              );
            })}
        </select>
        <select
          onChange={handleChangeArea}
          value={area}
          className={readOnly && "disable"}
          disabled={readOnly}
        >
          <option value="">시&#183;구&#183;군</option>
          {areaList &&
            areaList.map((x, i) => {
              return (
                <option key={i} value={x.area_detail_id}>
                  {x.area_detail_name}
                </option>
              );
            })}
        </select>
      </div>

      {error && <p className="input_error">{error}</p>}
      {success && <p className="input_success">{success}</p>}
    </div>
  );
}
