import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import * as APIS from "../../utils/service";

import { close } from "../../redux/popupSlice";
import { openSub } from "../../redux/subPopupSlice";

import Input from "../../components/Input";
import InputSelect from "../../components/InputSelect";

export default function PartnerMembershipPop(props) {
  const { idx, type, ck } = props;
  const dispatch = useDispatch();
  const userData = useSelector((s) => s.usersReducer);

  const [memberType, setMemberType] = useState("minus");
  const [memberSelect, setMemberSelect] = useState("start");
  const [memberInput, setMemberInput] = useState("");

  const submitFunc = () => {
    let sender = "";
    let url = "";

    if (type === 1) {
      url = "/AdminMembership/ChgPeriodPay";
      sender = {
        admin_table_id: userData.admin_table_id,
        seller_table_id: idx,
        day_type: memberSelect,
        type: memberType,
        cnt: memberInput,
      };
    } else {
      if (ck === 1) {
        url = "/AdminMembership/ChgFixPay";
      } else {
        url = "/AdminMembership/ChgPeriodCnt";
      }
      sender = {
        admin_table_id: userData.admin_table_id,
        seller_table_id: idx,
        type: memberType,
        cnt: memberInput,
      };
    }

    APIS.postData(url, sender, 1)
      .then(() => {
        dispatch(close());
        setTimeout(() => {
          dispatch(
            openSub({
              message: "변경 되었습니다.",
              button: "확인",
            })
          );
        }, 50);
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };

  return (
    <form className="popup_form">
      {type === 1 ? (
        <>
          <InputSelect
            name="memberSelect"
            label="기간"
            value={memberSelect}
            setValue={setMemberSelect}
            option={[
              { idx: "start", title: "시작일" },
              { idx: "end", title: "종료일" },
            ]}
          />
          <div className="checkbox_section" style={{ marginTop: "24px" }}>
            <label className="input_label">타입</label>
            <div>
              <input
                type="radio"
                id="memberType1"
                name="memberType"
                className="radio"
                value="minus"
                checked={memberType === "minus"}
                onChange={(e) => setMemberType(e.target.value)}
              />
              <label htmlFor="memberType1">감소</label>
              <input
                type="radio"
                id="memberType2"
                name="memberType"
                className="radio"
                value="plus"
                checked={memberType === "plus"}
                onChange={(e) => setMemberType(e.target.value)}
              />
              <label htmlFor="memberType2">증가</label>
            </div>
          </div>
          <Input
            className="input_text"
            type="text"
            placeholder="일수를 입력해 주세요."
            name="memberInput"
            value={memberInput}
            setValue={setMemberInput}
            label="일수"
            valid={"num"}
            maxlength={5}
          />
        </>
      ) : (
        <>
          <div className="checkbox_section">
            <label className="input_label">타입</label>
            <div>
              <input
                type="radio"
                id="memberType1"
                name="memberType"
                className="radio"
                value="minus"
                checked={memberType === "minus"}
                onChange={(e) => setMemberType(e.target.value)}
              />
              <label htmlFor="memberType1">감소</label>
              <input
                type="radio"
                id="memberType2"
                name="memberType"
                className="radio"
                value="plus"
                checked={memberType === "plus"}
                onChange={(e) => setMemberType(e.target.value)}
              />
              <label htmlFor="memberType2">증가</label>
            </div>
          </div>
          <Input
            className="input_text"
            type="text"
            placeholder="수량을 입력해 주세요."
            name="memberInput"
            value={memberInput}
            setValue={setMemberInput}
            label="수량"
            valid={"num"}
            maxlength={5}
          />
        </>
      )}

      <div className="popup_btn">
        <button
          type="button"
          className="popbtn cencle_btn"
          onClick={() => {
            dispatch(close());
          }}
          style={{ width: "100%", marginRight: "10px" }}
        >
          취소
        </button>
        <button
          type="button"
          className="popbtn ok_btn"
          onClick={() => {
            submitFunc();
          }}
          style={{ width: "100%" }}
        >
          변경
        </button>
      </div>
    </form>
  );
}
