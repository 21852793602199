import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as APIS from "../../utils/service";
import { open, close } from "../../redux/popupSlice";
import { setData } from "../../redux/usersSlice";
import moment from "moment";
import SubNav from "../../components/SubNav";
import Input from "../../components/Input";
import InputSelect from "../../components/InputSelect";
import PwReset from "./PwReset";
import routes from "../../libs/routes";
import PartnerLayout from "../../components/Layout/partner";
import { css } from "@emotion/react";
import SelectWithButton from "../../components/Common/Select/SelectWithButton";

const { Kakao } = window;

export default function Acc() {
  const dispatch = useDispatch();
  const userData = useSelector((s) => s.usersReducer);

  const [num, setNum] = useState(userData.seller_table_id);
  const [hp, setHp] = useState(userData.seller_phone);
  const [id] = useState(userData.seller_id);
  const [kakao, setKakao] = useState(userData.kakao_auth_key);
  const [state, setState] = useState(userData.service_state);
  const [agree, setAgree] = useState(
    userData.market_agree === "1"
      ? "동의 (" + moment(userData.market_agree_date).format("YYYY.MM.DD") + ")"
      : "미동의"
  );

  const [error, setError] = useState("");
  const [error2, setError2] = useState("");

  const [clause, setClause] = useState("");

  const nav = [
    { routes: routes.my, title: "파트너 정보" },
    { routes: routes.membership, title: "멤버십 정보" },
    { routes: routes.acc, title: "계정 정보" },
  ];
  const [stateoption] = useState([
    { idx: "1", title: "정상" },
    { idx: "2", title: "대기" },
  ]);

  const [isTalkReceive, setIsTalkReceive] = useState(userData?.alarm_yn || "0");

  useEffect(() => {
    const sender = {
      tid: "4",
    };

    APIS.postData("/Login/GetTerms", sender, 2)
      .then((res) => {
        if (res.data.code === "OK") {
          setClause(res.data.terms);
        } else {
          setClause("");
        }
      })
      .catch((e) => {
        console.log("catch", e);
      });
  }, []);

  useEffect(() => {
    if (state === "2") {
      setError2("고객상담콜을 받을 수 없습니다.");
    } else {
      setError2("");
    }
  }, [state]);

  useEffect(() => {
    setHp(userData.seller_phone);
  }, [userData.seller_phone]);

  const stateChange = (v) => {
    const sender = {
      seller_table_id: userData.seller_table_id,
      service_state: v,
    };
    APIS.postData("/SellerInfo/ChgServiceState", sender, 2)
      .then((res) => {
        dispatch(
          setData({
            key: "service_state",
            value: v,
          })
        );
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };

  const pwPop = () => {
    dispatch(
      open({
        title: "비밀번호 변경",
        message: "변경하실 비밀번호를 입력하세요.",
        component: <PwReset />,
      })
    );
  };
  const agreePop = (title, comp, msg) => {
    dispatch(
      open({
        title: title,
        message: msg,
        component: comp,
      })
    );
  };

  const kakaoFunc = () => {
    Kakao.Auth.loginForm({
      success: function (authObj) {
        Kakao.Auth.setAccessToken(authObj.access_token);
        Kakao.API.request({
          url: "/v2/user/me",
          success: function (response) {
            let sender = {
              kakao_auth_key: response.id,
            };
            APIS.postData("/SellerInfo/CheckKakao", sender, 2)
              .then((res) => {
                if (res.data.code === "OK") {
                  dispatch(
                    open({
                      message: "이미 카카오 계정으로 등록된 정보가 있습니다.",
                      button: "확인",
                    })
                  );
                } else {
                  sender = {
                    seller_table_id: userData.seller_table_id,
                    kakao_auth_key: response.id,
                  };
                  APIS.postData("/SellerInfo/ChgKakao", sender, 2)
                    .then((res) => {
                      if (res.data.code === "OK") {
                        dispatch(
                          setData({ key: "kakao_auth_key", value: response.id })
                        );
                        setKakao(response.id);
                        dispatch(
                          open({
                            message: "카카오 계정이 변경되었습니다.",
                            button: "확인",
                          })
                        );
                      } else {
                        alert("잠시 후 다시 시도해주세요.");
                      }
                    })
                    .catch((e) => {
                      console.log("catch", e);
                      alert("잠시 후 다시 시도해주세요.");
                    });
                }
              })
              .catch((e) => {
                console.log("catch", e);
                alert("잠시 후 다시 시도해주세요.");
              });
            return;
          },
          fail: function (error) {
            console.log(error);
            alert("잠시 후 다시 시도해주세요.");
          },
        });
      },
      fail: function () {
        alert("잠시 후 다시 시도해주세요.");
      },
    });
  };
  const agreeFunc = () => {
    const chk = document.getElementById("chk3").checked;

    const sender = {
      seller_table_id: userData.seller_table_id,
      market_agree: chk ? 1 : 0,
    };

    APIS.postData("/SellerInfo/MarketAgree", sender, 2)
      .then((res) => {
        setAgree(
          chk ? "동의 (" + moment().format("YYYY.MM.DD") + ")" : "미동의"
        );
        dispatch(
          setData({
            key: "market_agree",
            value: chk ? "1" : "0",
          })
        );
        dispatch(
          setData({
            key: "market_agree_date",
            value: moment().format("YYYY-MM-DD HH:mm:ss"),
          })
        );
        dispatch(close());
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };

  const changeAlarmHandler = async () => {
    const { seller_table_id, seller_phone } = userData;

    const params = {
      seller_table_id,
      phone: seller_phone,
      alarm_yn: isTalkReceive,
    };

    try {
      const res = await APIS.postData("/SellerInfo/KakaoAlarmState", params, 1);

      if (res.data.code === "OK") {
        dispatch(
          setData({
            key: "alarm_yn",
            value: isTalkReceive,
          })
        );
        alert("알림톡 수신여부가 변경되었습니다.");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const AgreeRender = () => {
    return (
      <div className="register_body popup_register_body">
        <div
          className="clause_box"
          dangerouslySetInnerHTML={{ __html: clause }}
        ></div>
        <input
          type="checkbox"
          id="chk3"
          className="checkbox"
          defaultChecked={agree !== "미동의"}
        />
        <label htmlFor="chk3">마케팅 정보 수신 동의</label>
        <div className="register_btn_box">
          <button
            type="button"
            className="btn btn3"
            onClick={() => dispatch(close())}
          >
            취소
          </button>
          <button
            type="button"
            className="btn btn1"
            onClick={() => agreeFunc()}
          >
            변경
          </button>
        </div>
      </div>
    );
  };

  return (
    <PartnerLayout>
      <div
        css={css`
          padding: 52px 0 0 0;
          margin: 0 -30px -30px -30px;
        `}
      >
        <div className="page_title_box page_title_box1">
          <p className="title">{userData.seller_biz_name}</p>
          <SubNav nav={nav} />
        </div>

        <div className="content_section">
          <div className="form_section no_padding">
            <div className="form_list">
              <Input
                className="input_text"
                type="text"
                placeholder="업체명을 입력해 주세요."
                name="num"
                value={num}
                setValue={setNum}
                label="파트너번호"
                error={error}
                setError={setError}
                readOnly={true}
              />
              <Input
                className="input_text"
                type="text"
                placeholder="영문/숫자만 입력해 주세요."
                name="kakao"
                value={kakao}
                label="카카오연동"
                readOnly={true}
                withButton={"변경"}
                withButtonPress={() => kakaoFunc()}
              />

              <Input
                className="input_text"
                type="text"
                placeholder="영문/숫자만 입력해 주세요."
                name="hp"
                value={hp}
                label="휴대폰번호"
                readOnly={true}
              />

              <Input
                className="input_text"
                type="text"
                placeholder="영문/숫자만 입력해 주세요."
                name="id"
                value={id}
                label="아이디"
                readOnly={true}
              />

              <div className="input_box">
                <label className="input_label">비밀번호</label>
                <div className="input_section">
                  <button
                    type="button"
                    className="btn btn3 btn_100"
                    onClick={() => pwPop()}
                  >
                    변경
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="form_section no_padding no_border">
            <p className="sub_title">
              고객상담콜을 받을 수 있는 상태 여부를 설정할 수 있습니다.
            </p>
            <div className="form_list">
              <InputSelect
                name="state"
                label="상태"
                value={state}
                setValue={setState}
                option={stateoption}
                error={error2}
                setError={setError2}
                onChangeFunc={stateChange}
              />
              <Input
                className="input_text"
                type="text"
                placeholder="영문/숫자만 입력해 주세요."
                name="agree"
                value={agree}
                label="마케팅 정보수신 동의"
                readOnly={true}
                withButton={
                  userData.market_agree !== "1" ? "동의하기" : "변경하기"
                }
                withButtonPress={() =>
                  agreePop("마케팅 정보수신 동의", <AgreeRender />)
                }
              />
              <SelectWithButton
                label="알림톡 수신여부"
                size="medium"
                value={isTalkReceive}
                onChange={(e) => setIsTalkReceive(e.target.value)}
                options={[
                  { value: "0", label: "대기" },
                  { value: "1", label: "정상" },
                ]}
                buttonText="저장"
                buttonStyles="tertiary"
                onClick={changeAlarmHandler}
              />
            </div>
          </div>
        </div>
      </div>
    </PartnerLayout>
  );
}
