import React, { useState, useRef } from "react";
import { css } from "@emotion/react";
import Table from "../../Common/Table/Table";
import Pagination from "../../Common/Pagination/Pagination";
import moment from "moment";
import { findJson } from "../../../utils/utils";
import consts from "../../../libs/consts";
import useEvent from "../event.hook";
import Select from "../../Common/Select/Select";
import DateRange from "../../Common/Date/DateRange";
import Button from "../../Common/Button/Button";
import { CSVLink } from "react-csv";
import { aptnCounselCsvHeaders } from "../constants";
import { useDispatch } from "react-redux";
import { open } from "../../../redux/popupSlice";
import EventAdd from "../EventAdd";

interface CSVTypes {
  ev_table_id: string;
  reg: string;
  size: string;
  sigongTpye: string;
  product_name: string;
  cost: string;
  event_state: string;
  event_description: string;
  phone: string;
  reg_date: string;
}

const EventAptnCounsel = () => {
  const dispatch = useDispatch();
  const [constructionType, setConstructionType] = useState("");
  const [date, setDate] = useState<{
    start: string | Date;
    end: string | Date;
  }>({
    start: "",
    end: "",
  });
  const csvLinkRef = useRef<any>();

  const { list, page, total, search, getDownloadList } = useEvent({
    step: 4,
    construction_type: constructionType,
    start_date: date.start ? moment(date.start).format("YYYY.MM.DD") : "",
    end_date: date.end ? moment(date.end).format("YYYY.MM.DD") : "",
  });

  const [csvData, setCsvData] = useState<CSVTypes[]>([]);

  const downloadHandler = async () => {
    try {
      const res = await getDownloadList();

      setCsvData(
        res.map((item: any) => {
          const data = {
            ev_table_id: item.ev_table_id,
            apart_name: item.apart_name,
            size: item.size,
            event_state:
              item.event_state !== ""
                ? findJson(consts.event_process, item.event_state)
                : "대기",
            phone: item.phone.replace(
              /^(\d{0,3})(\d{0,4})(\d{0,4})$/g,
              "$1-$2-$3"
            ),
            reg_date: item.reg_date,
          };
          return data;
        })
      );
    } catch (err) {
      console.log(err);
    }

    csvLinkRef.current.link.click();
  };

  const updateFunc = (x: any) => {
    dispatch(
      open({
        title: "Event 관리 정보",
        component: <EventAdd x={x} w={"u"} e={4} />,
      })
    );
  };

  const col = [
    { key: "ev_table_id", title: "요청번호" },
    { key: "apart_name", title: "아파트명" },
    { key: "size", title: "평형" },
    {
      key: "event_state",
      title: "처리상태",
      render: (x: any) => (
        <span>
          {x.event_state !== ""
            ? findJson(consts.event_process, x.event_state)
            : "대기"}
        </span>
      ),
    },
    {
      key: "phone",
      title: "연락처",
      render: (x: any) => (
        <span>
          {x.phone.replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")}
        </span>
      ),
    },
    {
      key: "reg_date",
      title: "요청일시",
      render: (x: any) => (
        <span>{moment(x.reg_date).format("YYYY.MM.DD HH:mm")}</span>
      ),
    },
  ];

  return (
    <div>
      <div
        css={css`
          margin-top: 24px;
          display: flex;
          justify-content: space-between;
        `}
      >
        <div
          css={css`
            display: flex;
            gap: 15px;
          `}
        >
          <Select
            label="유형"
            defaultValue="전체"
            options={consts.event_state.map((item) => {
              return {
                value: String(item.idx),
                label: item.title,
              };
            })}
            size="small"
            onChange={(e) => setConstructionType(e.target.value)}
            value={constructionType}
          />

          <DateRange
            label="날짜선택"
            startPlaceholder="시작날짜"
            endPlaceholder="종료날짜"
            onChange={(t, v) =>
              setDate({
                ...date,
                ...(t === "start" && { start: v }),
                ...(t === "end" && { end: v }),
              })
            }
            startValue={date.start}
            endValue={date.end}
          />
          <div
            css={css`
              margin-top: auto;
            `}
          >
            <Button theme="tertiary" onClick={search}>
              검색
            </Button>
          </div>
        </div>
        <div
          css={css`
            margin-top: auto;
          `}
        >
          <Button theme="tertiary" onClick={() => downloadHandler()}>
            전체 DB 다운로드
          </Button>
          <CSVLink
            ref={csvLinkRef}
            data={csvData}
            headers={aptnCounselCsvHeaders}
            filename={
              "아파트너_상담_" +
              moment(new Date()).format("YYYY_MM_DD(HH)") +
              ".csv"
            }
            target="_blank"
            asyncOnClick={true}
          />
        </div>
      </div>
      <div
        css={css`
          margin: 30px 0 20px 0;
        `}
      >
        <Table columns={col} datas={list} rowCallback={(x) => updateFunc(x)} />
        <Pagination curPage={page} total={total} pageSize={15} />
      </div>
    </div>
  );
};

export default EventAptnCounsel;
