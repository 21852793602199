/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as APIS from "../../utils/service";
import Pagenation from "../../components/Pagenation";
import consts from "../../libs/consts";
import { setData, reset } from "../../redux/searchSlice";
import useWindowScreen from "../../utils/useWindowScreen";
import { useNavigate } from "react-router";
import routes from "../../libs/routes";
import PayMembership from "./PayMembership";
import PayContCommission from "./PayContCommission";
import PayInfoList from "./PayInfoList";
import PartnerLayout from "../../components/Layout/partner";

export default function PayManage() {
  const dispatch = useDispatch();
  const userData = useSelector((s) => s.usersReducer);
  const search = useSelector((s) => s.searchReducer);
  const popup = useSelector((s) => s.popupReducer);
  const [step, setStep] = useState(1);
  const [list, setList] = useState([]);
  const [membership, setMemberShip] = useState([]);
  const [pageing, setPageing] = useState("");
  const windowSize = useWindowScreen();
  const navigte = useNavigate();

  if (windowSize.width <= 720) {
    navigte(routes.membership);
  }
  useEffect(() => {
    dispatch(reset());
    dataFunc();
  }, [step]);

  useEffect(() => {
    dataFunc();
  }, [search.state, search.sdate, search.edate, search.page, popup.open]);

  const dataFunc = () => {
    let urls = "";
    let sender = {
      seller_table_id: userData.seller_table_id,
      page: search.page + 1,
    };

    if (step === 1) {
      urls = "/Membership/MemList";
      sender.payment_state = search.state;
    }

    if (step === 2) {
      urls = "/Membership/ContList";
      sender.state = search.state;
    }

    if (step === 3) {
      urls = "/Membership/InfoList";
      sender.state = search.state;
      sender.start_date = search.sdate;
      sender.end_date = search.edate;

      // 결제관리 > 정보제공내역 > 정액제, 정량제 수량 표시
      APIS.postData("/Membership/MemInfo", {
        seller_table_id: userData.seller_table_id,
      })
        .then((res) => {
          setMemberShip(res.data);
        })
        .catch((e) => {
          console.log("catch", e);
        });
    }

    APIS.postData(urls, sender)
      .then((res) => {
        setList(res.data.pay_list);
        setPageing({
          totalPages: Math.ceil(res.data.total_cnt / consts.pagerows),
        });
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };

  const searchFunc = (key, value) => {
    dispatch(
      setData({
        key: key,
        value: value,
      })
    );
  };

  return (
    <PartnerLayout
      title="결제관리"
      tabs={[
        {
          id: 1,
          title: "멤버십",
          callback: () => {
            setStep(1);
          },
          isActive: step === 1,
        },
        {
          id: 2,
          title: "계약수수료",
          callback: () => {
            setStep(2);
          },
          isActive: step === 2,
        },
        {
          id: 3,
          title: "정보제공내역",
          callback: () => {
            setStep(3);
          },
          isActive: step === 3,
        },
      ]}
    >
      <div>
        {step === 1 && <PayMembership list={list} />}
        {step === 2 && <PayContCommission list={list} />}
        {step === 3 && <PayInfoList list={list} membership={membership} />}
        <Pagenation
          pageing={pageing}
          onSearch={searchFunc}
          curpage={search.page}
        />
      </div>
    </PartnerLayout>
  );
}
