/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import * as APIS from "../../utils/service";
import { numFormat, findJson } from "../../utils/utils";
import { close } from "../../redux/popupSlice";
import { openSub } from "../../redux/subPopupSlice";
import { imgOpen } from "../../redux/imgSlice";
import { loadingStart, loadingEnd } from "../../redux/loadingSlice";
import Input from "../../components/Input";
import InputSelect from "../../components/InputSelect";
import InputSelectArea from "../../components/InputSelectArea";
import InputFile from "../../components/InputFile";
import TextArea from "../../components/TextArea";
import InputDate from "../../components/InputDate";
import Meet from "./Meet";
import History from "./History";
import CounselDetail from "./CounselDetail";
import images from "../../libs/images";
import consts from "../../libs/consts";
import PartnerRespInputSelect from "../../components/PartnerRespInputSelect";
import styled from "styled-components";

const PhoneText = styled.p`
  &.mobile {
    display: none;
  }

  &.web {
    display: block;
  }

  @media screen and (max-width: 720px) {
    &.mobile {
      display: block;
      color: #1e90ff !important;
      text-decoration: underline;
      cursor: pointer;
    }

    &.web {
      display: none;
    }
  }
`;

export default function Add(props) {
  const { x } = props;
  const dispatch = useDispatch();
  const userData = useSelector((s) => s.usersReducer);

  const [step, setStep] = useState(1);
  const [load, setLoad] = useState(true);

  const [disable, setDisable] = useState(
    x && x.order_state * 1 > 4 && x.order_state * 1 < 10 ? true : true
  ); // 계약관리 활성화 여부
  const [state, setState] = useState(x ? x.order_resp_state : ""); // 회신상태
  const [meet, setMeet] = useState(
    x && x.meet_date !== "0000-00-00 00:00:00" ? x.meet_date : ""
  ); // 미팅일자
  const [imgs] = useState(
    x && x.img_list.length > 0
      ? x.img_list.map((item) => {
          return {
            src: item.order_img,
          };
        })
      : []
  ); // 참고사진 리스트

  /* step2 */
  const [date1, setDate1] = useState(); // 요청일자
  const [date2, setDate2] = useState(); // 최종일지

  const [cont_num, setCont_num] = useState(""); // 계약번호

  const [case_name, setcase_name] = useState(""); // 아파트명
  const [case_size, setcase_size] = useState(""); // 평형

  const [price, setPrice] = useState(""); // 시공가격
  const [price1, setPrice1] = useState(""); // 계약금
  const [price2, setPrice2] = useState(""); // 중도금
  const [price3, setPrice3] = useState(""); // 잔금

  const [reqDesc, setReqDesc] = useState(""); // 요청사항
  const [desc, setDesc] = useState(""); // 비고

  const [case_work_area, setcase_work_area] = useState(""); // 시공지역 1depth
  const [case_work_area2, setcase_work_area2] = useState(""); // 시공지역 2depth

  const [recpPhoto, setRecpPhoto] = useState(""); // 도면사진
  const [recp_img, setRecp_img] = useState(""); // 도면사진 Base64 엔코딩한거
  const [is_recp_del, setis_recp_del] = useState(x ? 0 : 1); // 도면사진 삭제여부

  const [mainPhoto, setmainPhoto] = useState(""); // 메인사진
  const [main_img, setmain_img] = useState(""); // 메인사진 Base64 엔코딩한거
  const [is_main_del, setis_main_del] = useState(x ? 0 : 1); // 메인사진 삭제여부

  const [sdate, setSdate] = useState(""); // 시공시작 예정일
  const [edate, setEdate] = useState(""); // 시공종료 예정일

  const [info, setInfo] = useState([]); // 시공정보
  const [case_work_parts, setcase_work_parts] = useState([]); // 공간구성

  const [material_list, setMaterial_list] = useState([]); // 시공별 상세정보 배열
  const [material_del, setMaterial_del] = useState([]); // 시공별 가격정보 삭제 고유번호 리스트

  const [sellerOrderRespState, setSellerOrderRespState] = useState(
    consts.sellerOrderRespState
  );

  useEffect(() => {
    if (
      x.order_state === "2" ||
      x.order_state === "3" ||
      x.order_state === "11"
    ) {
      setSellerOrderRespState([
        { idx: "", title: "회신상태를 선택해주세요." },
        ...consts.sellerOrderRespState.filter(function (item) {
          return item.idx === "3" || item.idx === "4" || item.idx === "5";
        }),
      ]);
    } else if (
      x.order_state === "4" ||
      x.order_state === "5" ||
      x.order_state === "12"
    ) {
      setSellerOrderRespState([
        { idx: "", title: "회신상태를 선택해주세요." },
        ...consts.sellerOrderRespState.filter(function (item) {
          return item.idx === "6" || item.idx === "7";
        }),
      ]);
    } else {
      setSellerOrderRespState([{ idx: "", title: "회신상태 없음" }]);
    }
  }, [x]);

  useEffect(() => {
    let urls = "/OrderInfo/OrderContract";
    let types = 2;
    let sender = {
      order_table_id: x.order_table_id,
      seller_table_id: userData.seller_table_id,
    };

    APIS.postData(urls, sender, types)
      .then((res) => {
        if (res.data.code === "OK" && res.data.cont_info) {
          const one = res.data.cont_info;
          const two = res.data.case_info;
          const three = two.zone_parts;

          setcase_name(two.case_name);
          setcase_size(two.case_size);

          setcase_work_area(two.case_work_area);
          setcase_work_area2(two.case_work_area2);

          setRecpPhoto("");
          setmainPhoto("");

          setmain_img(two.main_img);
          setRecp_img(two.recp_img);

          setis_main_del(two.main_img ? 0 : 1);
          setis_recp_del(two.recp_img ? 0 : 1);

          setCont_num(one.cont_table_id);
          setPrice(one.order_price);
          setPrice1(one.cont_price);
          setPrice2(one.m_price);
          setPrice3(one.last_price);

          setSdate(
            one.work_start_date && one.work_start_date !== "0000-00-00 00:00:00"
              ? moment(one.work_start_date).format("YYYY.MM.DD")
              : ""
          );
          setEdate(
            one.work_end_date && one.work_end_date !== "0000-00-00 00:00:00"
              ? moment(one.work_end_date).format("YYYY.MM.DD")
              : ""
          );

          setReqDesc(one.style_desc);
          setDesc(one.base_desc);
          setInfo(two.case_work_type ? two.case_work_type.split(",") : []);
          setcase_work_parts(
            two.case_work_parts ? two.case_work_parts.split(",") : []
          );

          setMaterial_list(three || []);
        } else {
          setDisable(false);
        }
      })
      .catch((e) => {
        console.log("catch", e);
      })
      .finally(() => {
        setLoad(false);
      });
  }, [step]);

  useEffect(() => {
    document.querySelector(".popup_list_ul").scrollTo(0, 0);
  }, [step]);

  const submitFuncStep1 = () => {
    if (state && x) {
      let urls = "/OrderInfo/OrderChgState";
      let types = 2;
      let sender = {
        order_table_id: x.order_table_id,
        seller_table_id: userData.seller_table_id,
        order_resp_state: state,
      };

      APIS.postData(urls, sender, types).catch((e) => {
        console.log("catch", e);
      });
    }

    if (meet) {
      let urls = "/OrderInfo/OrderMeetDate";
      let types = 2;
      let sender = {
        order_table_id: x.order_table_id,
        seller_table_id: userData.seller_table_id,
        meet_date: meet,
      };

      APIS.postData(urls, sender, types).catch((e) => {
        console.log("catch", e);
      });
    }
    dispatch(close());
  };

  const closeFunc = () => {
    dispatch(
      openSub({
        message: "견적관리 등록을 중단하시겠습니까?",
        buttonCencle: "취소",
        onPress: () => {
          dispatch(close());
        },
      })
    );
  };

  const topNavFunc = (num) => {
    if (!disable) return;

    setStep(num);
  };

  const showMeet = () => {
    dispatch(
      openSub({
        title: "고객미팅일자 등록",
        message: "고객과 미팅할 일자를 선택해 주세요.",
        overFlow: true,
        component: (
          <Meet
            id={x.order_table_id}
            date={meet ? meet : ""}
            setDate={setMeet}
          />
        ),
      })
    );
  };

  const historyFunc = () => {
    dispatch(
      openSub({
        title: "요청관리이력",
        component: <History rows={x.request_list} id={x.order_table_id} />,
      })
    );
  };
  const callFunc = () => {
    // 전화걸기 함수
    window.location.href = "tel:" + x.order_phone;
  };

  const submitFunc = () => {
    dispatch(loadingStart());

    const sender = {
      seller_table_id: userData.seller_table_id,
      order_table_id: x.order_table_id,
      case_name: case_name,
      case_size: case_size,

      order_price: price /* 시공가격 */,
      cont_price: price1 /* 계약금 */,
      m_price: price2 /* 중도금 */,
      last_price: price3 /* 잔금 */,

      main_img: is_main_del === 1 && mainPhoto ? main_img : "",
      is_main_del: case_name ? is_main_del : 0,

      recp_img: is_recp_del === 1 && recpPhoto ? recp_img : "",
      is_recp_del: case_name ? is_recp_del : 0,

      base_desc: desc /* 비고 */,
      style_desc: reqDesc,

      case_work_type: info.join(",") /* 시공정보 */,
      case_work_parts: case_work_parts.join(",") /* 공간구성 */,

      case_work_area: case_work_area,
      case_work_area2: case_work_area2,

      work_start_date: moment(sdate).format("YYYY-MM-DD HH:mm:ss"),
      work_end_date: moment(edate).format("YYYY-MM-DD HH:mm:ss"),

      zone_parts: material_list,
      zone_delete_ids: material_del,
    };

    APIS.postData("/OrderInfo/OrderContUpdate", sender, 1)
      .catch((e) => {
        console.log("catch", e);
      })
      .finally(() => {
        submitCloseFunc();
      });
  };

  const submitCloseFunc = () => {
    dispatch(loadingEnd());
    dispatch(
      openSub({
        message: "저장되었습니다.",
        button: "확인",
      })
    );
  };

  const zoneAppend = () => {
    setMaterial_list([
      ...material_list,
      { zone_type: "1", zone_price: "", zone_desc: "" },
    ]);
  };

  const zoneDelete = (i, id) => {
    let newFormValues = [...material_list];
    newFormValues.splice(i, 1);
    setMaterial_list(newFormValues);

    if (id) setMaterial_del([...material_del, id]);
  };

  const allSetValue = (i, e, val, list) => {
    let newFormValues = [...list];

    newFormValues[i][e.target.name] = val;

    return newFormValues;
  };

  const materialSetValue = (i, e, val) => {
    setMaterial_list(allSetValue(i, e, val, material_list));
  };

  const goTheme = (type, id) => {
    window.open(
      consts.frontUrl +
        (type === "1"
          ? consts.frontReport
          : type === "2"
          ? consts.frontTheme
          : consts.frontPartner) +
        id,
      "_blank"
    );
    return;
  };

  const goCouncel = () => {
    return dispatch(
      openSub({
        title: "상담 내용",
        component: (
          <CounselDetail rows={x.estimate_msg_list} id={x.order_table_id} />
        ),
      })
    );
  };

  return (
    <div className="popup_list">
      <div className="pop_progress">
        <p
          className={"cl_000 " + (step === 1 ? "active" : "")}
          onClick={() => topNavFunc(1)}
        >
          요청정보
        </p>
        {(x.order_state <= 5 || x.order_state >= 11) && x.order_state ? (
          <></>
        ) : (
          <p
            className={
              (disable ? "cl_000 " : "") + (step === 2 ? "active" : "")
            }
            onClick={() => topNavFunc(2)}
          >
            계약관리
          </p>
        )}
      </div>

      {step === 1 ? (
        <>
          <div className="popup_list_ul popup_instance_step popup_estimate_step">
            <div className="popup_estimate_section">
              <p className="title">{"기본정보"}</p>
              <ul className="list_style4">
                <li>
                  <p className="title pTitle">요청번호</p>
                  <p className="content">
                    {x.order_table_id}
                    <button type="button" onClick={() => historyFunc()}>
                      요청관리 이력
                    </button>
                  </p>
                </li>
                <li>
                  <p className="title pTitle">요청일시</p>
                  <p className="content">
                    {moment(x.reg_date).format("YYYY/MM/DD HH:mm")}
                  </p>
                </li>
                <li>
                  <p className="title pTitle">최종일시</p>
                  <p className="content">
                    {moment(x.update_date).format("YYYY/MM/DD HH:mm")}
                  </p>
                </li>
                <li>
                  <p className="title pTitle">견적유형</p>
                  {x.order_estimate_type === "0" ? (
                    <p className="content">
                      {findJson(consts.sellerOrderType, x.order_estimate_type)}
                    </p>
                  ) : (
                    <p
                      className="content link"
                      onClick={() =>
                        goTheme(x.order_estimate_type, x.order_fk_id)
                      }
                    >
                      {findJson(consts.sellerOrderType, x.order_estimate_type)}{" "}
                      ({x.order_fk_id})
                    </p>
                  )}
                </li>
                <li>
                  <p className="title pTitle">진행상태</p>
                  <p className="content">
                    {findJson(consts.sellerOrderState, x.order_state)}
                  </p>
                </li>
              </ul>

              <div className={"instance_form_list"}>
                <PartnerRespInputSelect
                  name="state"
                  label="회신상태"
                  value={state}
                  setValue={setState}
                  option={sellerOrderRespState}
                />
                <InputDate
                  type="text"
                  placeholder="날짜선택"
                  name="meet"
                  label="고객미팅일시"
                  dateValue={meet ? meet.substr(0, 10) : ""}
                  readOnly={
                    x.order_state !== "3" &&
                    x.order_state !== "4" &&
                    x.order_state !== "5" &&
                    state !== "5"
                  }
                  onClickFunc={() =>
                    x.order_state === "3" ||
                    x.order_state === "4" ||
                    x.order_state === "5" ||
                    state === "5"
                      ? showMeet()
                      : null
                  }
                />
              </div>
            </div>
            <div className="popup_estimate_section">
              <p className="title pTitle">{"의뢰정보"}</p>

              <ul className="list_style4">
                <li>
                  <p className="title pTitle">아파트명</p>
                  <p className="content">{x.order_home_name}</p>
                </li>
                <li>
                  <p className="title pTitle">평형</p>
                  <p className="content">{x.order_size}평형</p>
                </li>
                <li>
                  <p className="title pTitle">지역</p>
                  <p className="content">
                    {x.order_area_1st_text} {x.order_area_2nd_text}
                  </p>
                </li>
                <li>
                  <p className="title pTitle">희망가격</p>
                  <p className="content">
                    {numFormat(x.order_desired_price)}만원
                  </p>
                </li>
                <li>
                  <p className="title pTitle">희망일자</p>
                  <p className="content">
                    {x.order_desired_date === "" ||
                    x.order_desired_date === null
                      ? "미정"
                      : moment(x.order_desired_date).format("YYYY/MM/DD HH:mm")}
                  </p>
                </li>
                <li>
                  <p className="title pTitle">시공범위</p>
                  <p className="content">
                    {x.work_type === "1" ? "전체" : "부분"}
                  </p>
                </li>
                <li>
                  <p className="title pTitle">시공정보</p>
                  <p className="content">
                    {x.work_list_adm.length > 0 &&
                      x.work_list_adm.map((one, i) => {
                        return (
                          one.tag_text +
                          (i + 1 >= x.work_list_adm.length ? "" : ", ")
                        );
                      })}
                  </p>
                </li>
                <li>
                  <p className="title pTitle">상담내용</p>
                  {x.estimate_msg_list ? (
                    <p
                      className="content link"
                      onClick={() => goCouncel(x.estimate_msg_list)}
                    >
                      상담 내용보기
                    </p>
                  ) : (
                    <></>
                  )}
                </li>
                {imgs.length > 0 && (
                  <li className="list_style_img">
                    <p className="title imgTitle">참고사진</p>
                    <p className="content imgContent">
                      {imgs.map((one, i) => {
                        return (
                          <Fragment key={i}>
                            <img
                              src={one.src}
                              alt="img"
                              onClick={() =>
                                dispatch(imgOpen({ imgs: imgs, active: i }))
                              }
                            />
                          </Fragment>
                        );
                      })}
                    </p>
                  </li>
                )}
              </ul>
            </div>
            <div className="popup_estimate_section">
              <p className="title">{"고객정보"}</p>

              <ul className="list_style4">
                <li>
                  <p className="title">연락처</p>
                  <PhoneText className="content mobile" onClick={callFunc}>
                    {x.order_phone}
                  </PhoneText>
                  <PhoneText className="content web">{x.order_phone}</PhoneText>
                </li>
                <li className="list_style_long">
                  <p className="title">상세주소</p>
                  <p className="content">
                    {x.order_addr == null
                      ? "미기입"
                      : x.order_addr + " " + x.order_addr_detail}
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="popup_list_btn_box popup_pay_step">
            <button
              type="button"
              className="btn btn3"
              onClick={() => closeFunc()}
            >
              취소
            </button>
            <button
              type="button"
              className="btn btn1"
              onClick={() => submitFuncStep1()}
            >
              확인
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="popup_list_ul popup_instance_step popup_estimate_step popup_estimate_step2">
            <div className="instance_form_list estimate_form_list">
              <Input
                className="input_text"
                type="text"
                placeholder={"내용을 입력해주세요."}
                name="date1"
                value={moment(date1).format("YYYY.MM.DD HH:mm")}
                setValue={setDate1}
                label={"등록일시"}
                readOnly={true}
              />
              <Input
                className="input_text"
                type="text"
                placeholder={"내용을 입력해주세요."}
                name="date2"
                value={moment(date2).format("YYYY.MM.DD HH:mm")}
                setValue={setDate2}
                label={"최종일시"}
                readOnly={true}
              />

              <Input
                className="input_text"
                type="text"
                placeholder={"내용을 입력해주세요."}
                name="cont_table_id"
                value={x.order_table_id}
                label={"요청번호"}
                readOnly={true}
              />
              <Input
                className="input_text"
                type="text"
                placeholder={"내용을 입력해주세요."}
                name="cont_num"
                value={cont_num}
                setValue={setCont_num}
                label={"계약번호"}
                readOnly={true}
              />

              <Input
                className="input_text"
                type="text"
                placeholder="내용을 입력해주세요."
                name="case_name"
                value={case_name}
                setValue={setcase_name}
                label="아파트명/시공사"
              />
              <Input
                className="input_text"
                type="text"
                placeholder="숫자만 입력해주세요."
                name="case_size"
                value={case_size}
                setValue={setcase_size}
                label="평형"
                maxlength={3}
                valid={"num"}
                withText={"평"}
              />

              <Input
                className="input_text"
                type="text"
                placeholder="숫자만 입력해주세요."
                name="price"
                value={price}
                setValue={setPrice}
                label="시공가격"
                valid={"num"}
                maxlength={5}
                withText={"만원"}
              />

              <Input
                className="input_text"
                type="text"
                placeholder="숫자만 입력해주세요."
                name="price1"
                value={price1}
                setValue={setPrice1}
                label="계약금"
                valid={"num"}
                maxlength={5}
                withText={"만원"}
              />
              <Input
                className="input_text"
                type="text"
                placeholder="숫자만 입력해주세요."
                name="price2"
                value={price2}
                setValue={setPrice2}
                label="중도금"
                valid={"num"}
                maxlength={5}
                withText={"만원"}
              />
              <Input
                className="input_text"
                type="text"
                placeholder="숫자만 입력해주세요."
                name="price3"
                value={price3}
                setValue={setPrice3}
                label="잔금"
                valid={"num"}
                maxlength={5}
                withText={"만원"}
              />

              <InputFile
                type="file"
                placeholder="파일을 업로드해주세요."
                name="recpPhoto"
                label="도면"
                valid={"image"}
                value={recpPhoto}
                setValue={setRecpPhoto}
                imgvalue={recp_img}
                setImgValue={setRecp_img}
                deleteFunc={() => setis_recp_del(1)}
              />
              <InputFile
                type="file"
                placeholder="파일을 업로드해주세요."
                name="mainPhoto"
                label="메인사진"
                valid={"image"}
                value={mainPhoto}
                setValue={setmainPhoto}
                imgvalue={main_img}
                setImgValue={setmain_img}
                deleteFunc={() => setis_main_del(1)}
              />

              {!load && (
                <InputSelectArea
                  name="work"
                  label="시공지역"
                  value={case_work_area}
                  setValue={setcase_work_area}
                  area={case_work_area2}
                  setArea={setcase_work_area2}
                />
              )}

              {/* <Input className="input_text" type="text" placeholder={"내용을 입력해주세요."}  name="cont_num" value={cont_num} setValue={setCont_num} label={"계약번호"} readOnly={true}/> */}

              <div className="input_date_section_multi_half">
                <InputDate
                  type="text"
                  placeholder="날짜선택"
                  name="sdate"
                  label="시공시작 예정일"
                  dateValue={sdate}
                  setDateValue={setSdate}
                />
                <InputDate
                  type="text"
                  placeholder="날짜선택"
                  name="sdate"
                  label="시공종료 예정일"
                  dateValue={edate}
                  setDateValue={setEdate}
                />
              </div>

              <div className="input_check_box">
                <p className="input_label">시공정보</p>
                <div>
                  {!load &&
                    consts.tag_id.map((x, i) => {
                      return (
                        <Fragment key={i}>
                          <input
                            type="checkbox"
                            id={"chk" + i}
                            className="checkbox2 info"
                            checked={info.includes(x.title)}
                            data-text={x.title}
                            onChange={() => {
                              if (info.includes(x.title)) {
                                setInfo(
                                  info.filter((item) => item !== x.title)
                                );
                              } else {
                                setInfo([...info, x.title]);
                              }
                            }}
                          />
                          <label htmlFor={"chk" + i}>{x.title}</label>
                        </Fragment>
                      );
                    })}
                </div>
              </div>

              <div className="input_check_box">
                <p className="input_label">공간구성</p>
                <div>
                  {!load &&
                    consts.partsoption.map((x, i) => {
                      return (
                        <Fragment key={i}>
                          <input
                            type="checkbox"
                            id={"chk2_" + i}
                            className="checkbox2 parts"
                            checked={case_work_parts.includes(x.title)}
                            data-text={x.title}
                            onChange={() => {
                              if (case_work_parts.includes(x.title)) {
                                setcase_work_parts(
                                  case_work_parts.filter(
                                    (item) => item !== x.title
                                  )
                                );
                              } else {
                                setcase_work_parts([
                                  ...case_work_parts,
                                  x.title,
                                ]);
                              }
                            }}
                          />
                          <label htmlFor={"chk2_" + i}>{x.title}</label>
                        </Fragment>
                      );
                    })}
                </div>
              </div>

              <TextArea
                className="input_textarea"
                placeholder=""
                name="reqDesc"
                label="요청사항"
                maxlength={1000}
                value={reqDesc}
                setValue={setReqDesc}
              />
              <TextArea
                className="input_textarea"
                placeholder=""
                name="desc"
                label="비고"
                maxlength={1000}
                value={desc}
                setValue={setDesc}
              />
            </div>

            <div className="theme_multi_info_box">
              <p className="title">시공별 상세정보</p>
              {material_list?.map((x, i) => {
                return (
                  <div className="casezone_box" key={i}>
                    <button
                      type="button"
                      className="trash_btn_red"
                      onClick={() =>
                        zoneDelete(
                          i,
                          x.case_price_table_id ? x.case_price_table_id : "",
                          2
                        )
                      }
                    />
                    <div className="one_line half_three">
                      <InputSelect
                        name="case_parts_no"
                        label="시공"
                        value={x.case_parts_no}
                        onChange={materialSetValue}
                        index={i}
                        option={consts.buildsoption}
                      />
                      <Input
                        className="input_text"
                        type="text"
                        placeholder="숫자만 입력해주세요."
                        name="case_price"
                        value={x.case_price}
                        onChange={materialSetValue}
                        index={i}
                        label="가격"
                        valid={"num"}
                        maxlength={5}
                        withText={"만원"}
                      />
                    </div>
                    <div className="one_line full">
                      <Input
                        className="input_text"
                        type="text"
                        placeholder="내용을 입력해 주세요."
                        name="case_desc"
                        value={x.case_desc}
                        onChange={materialSetValue}
                        index={i}
                        label="시공설명"
                        full={true}
                      />
                    </div>
                  </div>
                );
              })}
              <button
                type="button"
                className="case_add_btn"
                onClick={() => zoneAppend(2)}
              >
                <img
                  src={images.plus}
                  alt="자재 추가하기"
                  className="plus_img"
                />
                추가하기
              </button>
            </div>
          </div>
          <div className="popup_list_btn_box popup_pay_step">
            {step > 1 ? (
              <>
                <button
                  type="button"
                  className="btn btn3"
                  onClick={() => closeFunc()}
                >
                  취소
                </button>
                <button
                  type="button"
                  className="btn btn1"
                  onClick={() => submitFunc()}
                >
                  등록
                </button>
              </>
            ) : (
              <>
                <button
                  type="button"
                  className="btn btn3 mobile_btn"
                  onClick={() => topNavFunc(2)}
                >
                  계약관리
                </button>
                <button
                  type="button"
                  className="btn btn1 mobile_btn"
                  onClick={() => callFunc()}
                >
                  전화하기
                </button>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}
