import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import Modal from "../../../Common/ModalPortal";
import images from "../../../../libs/images";
import Button from "../../../Common/Button/Button";
import * as Colors from "../../../../constants/color";
import { marginTopStyles } from "../../home.style";
import Input from "../../../Common/Input/Input";
import Select from "../../../Common/Select/Select";
import consts from "../../../../libs/consts";
import useHomeManageMonthly, { Item } from "../homeManageMonthly.hook";

const Container = styled.div`
  width: 560px;
  background-color: #ffffff;
  margin: auto;
`;

const Header = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${Colors.gray_200};

  & > h3 {
    margin: auto 0;
  }
`;

const Content = styled.div`
  padding: 10px 20px 30px 20px;
`;

const Footer = styled.div`
  padding: 20px;
  border-top: 1px solid ${Colors.gray_200};
  display: flex;
  justify-content: center;
  gap: 15px;
`;

interface Props {
  close: () => void;
  callback: () => void;
  id: string;
}

const UpdateHomeManageMonthly = (props: Props) => {
  const { close, callback, id } = props;

  const { detail, update } = useHomeManageMonthly({
    id,
  });

  const [item, setItem] = useState<Omit<Item, "update_date">>({
    hot_case_id: "",
    hot_case_name: "",
    state: "",
    case_table_id_1: "",
    case_table_id_2: "",
    case_table_id_3: "",
  });

  useEffect(() => {
    if (detail) {
      setItem({ ...detail });
    }
  }, [detail]);

  const changeSelectHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setItem((prevData) => ({
      ...prevData,
      state: e.target.value,
    }));
  };

  const changeInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setItem((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const updateHandler = async (): Promise<void> => {
    const {
      state,
      hot_case_name,
      case_table_id_1,
      case_table_id_2,
      case_table_id_3,
    } = item;

    const params = {
      hot_case_id: id,
      hot_case_name,
      case_table_id_1,
      case_table_id_2,
      case_table_id_3,
      state,
    };

    const res = await update(params);

    if (res) {
      callback();
    }
  };

  if (!detail) {
    return <></>;
  }

  return (
    <Modal>
      <Container>
        <Header>
          <h3>월간 인기 시공사례 변경</h3>
          <button onClick={close}>
            <img src={images.exit} alt="exit_btn" />
          </button>
        </Header>
        <Content>
          <div css={[marginTopStyles]}>
            <Select
              label="* 등록상태"
              defaultValue="운영 중"
              options={consts.notice_avt_state.map((notice) => ({
                value: notice.idx,
                label: notice.title,
              }))}
              value={item?.state}
              onChange={changeSelectHandler}
            />
          </div>
          <div css={[marginTopStyles]}>
            <Input
              label="* 키워드 명"
              placeholder="키워드 작성"
              name="hot_case_name"
              value={item.hot_case_name}
              onChange={changeInputHandler}
            />
          </div>
          <div css={[marginTopStyles]}>
            <Input
              label="* 시공사례 번호 1"
              placeholder="101"
              name="case_table_id_1"
              value={item.case_table_id_1}
              onChange={changeInputHandler}
            />
          </div>
          <div css={[marginTopStyles]}>
            <Input
              label="* 시공사례 번호 2"
              placeholder="102"
              name="case_table_id_2"
              value={item.case_table_id_2}
              onChange={changeInputHandler}
            />
          </div>
          <div css={[marginTopStyles]}>
            <Input
              label="* 시공사례 번호 3"
              placeholder="103"
              name="case_table_id_3"
              value={item.case_table_id_3}
              onChange={changeInputHandler}
            />
          </div>
        </Content>
        <Footer>
          <Button theme="tertiary" onClick={close}>
            취소
          </Button>
          <Button onClick={updateHandler}>변경</Button>
        </Footer>
      </Container>
    </Modal>
  );
};

export default UpdateHomeManageMonthly;
