import {createSlice} from '@reduxjs/toolkit';

const searchSlice = createSlice({
  name: 'search',
  initialState: {
    city: '',
    range: '',
    membership: '',
    license: '',
    stx: '',
    stx1: '',
    stx2: '',
    stx3: '',
    stxId: '',
    sdate: '',
    edate: '',
    state: '',
    od: '',
    page: 0,
  },
  reducers: {
    setData(state, action) {
        state[action.payload.key] = action.payload.value;
        if(action.payload.key !== 'stx' && action.payload.key !== 'page') {
          state.page = 0;
        }
    },
    reset(state, action) {
        state.city = '';
        state.range = '';
        state.membership = '';
        state.license = '';
        state.stx = '';
        state.stx1 = '';
        state.stx2 = '';
        state.stx3 = '';
        state.stxId = '';
        state.sdate = '';
        state.edate = '';
        state.state = '';
        state.od = '';
        state.page = 0;
    }
  },
});

export const {
    setData,
    reset
} = searchSlice.actions;
export default searchSlice.reducer;
