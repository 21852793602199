/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import * as APIS from "../../../utils/service";
import Pagenation from "../../../components/Pagenation";
import consts from "../../../libs/consts";
import { setData, reset } from "../../../redux/searchSlice";
import LogsSupplyInfo from "./LogsSupplyInfo";
import LogsAlarmTalk from "./LogsAlarmTalk";
import LogsOrderManage from "./LogsOrderManage";
import LogsSupplyManage from "./LogsSupplyManage";
import LogsSearch from "./LogsSearch";
import AdminLayout from "../../../components/Layout/admin";

export default function Logs() {
  const dispatch = useDispatch();
  const userData = useSelector((s) => s.usersReducer);
  const search = useSelector((s) => s.searchReducer);
  const [tabNum, setTabNum] = useState(0);
  const [pageing, setPageing] = useState("");
  const [list, setList] = useState([]);
  const [refundState, setRefundState] = useState("");

  const dataFunc = () => {
    const sender = {
      admin_table_id: userData.admin_table_id,
      state: search.state,
      search_contents: search.stx,
      start_date: search.sdate,
      end_date: search.edate,
      page: search.page + 1,
    };

    let urlArr = [
      "/Log/KakaoAlarm",
      "/Log/Order",
      "/Log/SupplyInfo",
      "/Log/PaymentInfo",
    ];
    let url = urlArr[tabNum];
    APIS.postData(url, sender, 1)
      .then((res) => {
        if (res.data.code === "OK") {
          setList(res.data.info_list);
          setPageing({
            totalPages: Math.ceil(res.data.total_cnt / consts.pagerows),
          });
        } else {
          setList([]);
          setPageing({ totalPages: 1 });
        }
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };

  useEffect(() => {
    dataFunc();
  }, [
    tabNum,
    refundState,
    search.state,
    search.sdate,
    search.edate,
    search.page,
  ]);

  const searchFunc = (key, value) => {
    dispatch(
      setData({
        key: key,
        value: value,
      })
    );
  };
  return (
    <AdminLayout
      title="로그 정보"
      tabs={[
        {
          id: 1,
          title: "알림톡",
          callback: () => {
            setTabNum(0);
            dispatch(reset());
          },
          isActive: tabNum === 0,
        },
        {
          id: 2,
          title: "견적관리",
          callback: () => {
            setTabNum(1);
            dispatch(reset());
          },
          isActive: tabNum === 1,
        },
        {
          id: 3,
          title: "정보제공내역",
          callback: () => {
            setTabNum(2);
            dispatch(reset());
          },
          isActive: tabNum === 2,
        },
        {
          id: 4,
          title: "정보제공관리",
          callback: () => {
            setTabNum(3);
            dispatch(reset());
          },
          isActive: tabNum === 3,
        },
      ]}
    >
      <div>
        <LogsSearch
          tabNum={tabNum}
          search={search}
          searchFunc={searchFunc}
          dataFunc={dataFunc}
        />
        {tabNum === 0 && <LogsAlarmTalk list={list} />}
        {tabNum === 1 && <LogsOrderManage list={list} />}
        {tabNum === 2 && (
          <LogsSupplyInfo list={list} setRefundState={setRefundState} />
        )}
        {tabNum === 3 && <LogsSupplyManage list={list} />}
        <Pagenation
          pageing={pageing}
          onSearch={searchFunc}
          curpage={search.page}
        />
      </div>
    </AdminLayout>
  );
}
