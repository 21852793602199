import exit from "../assets/images/exit.svg";
import exit_white from "../assets/images/exit_white.svg";
import checkbox_off from "../assets/images/checkbox_off.svg";
import checkbox_on from "../assets/images/checkbox_on.svg";
import calendar from "../assets/images/calendar.svg";
import camera from "../assets/images/camera.svg";
import down from "../assets/images/down.svg";
import up from "../assets/images/up.svg";
import left_icon from "../assets/images/left_icon.svg";
import right_icon from "../assets/images/right_icon.svg";
import trash from "../assets/images/trash.svg";
import warning from "../assets/images/warning.svg";
import warning_icon from "../assets/images/warning_icon.svg";
import checkmark from "../assets/images/checkmark.svg";
import radio_on from "../assets/images/radio_on.svg";
import radio_off from "../assets/images/radio_off.svg";
import logo from "../assets/images/logo.svg";
import info from "../assets/images/info.svg";
import plus_black from "../assets/images/plus_black.svg";
import front_menu_white from "../assets/images/front_menu_white.svg";
import front_menu_black_m from "../assets/images/front_menu_black_m.svg";
import list_icon from "../assets/images/list_icon.svg";
import call from "../assets/images/call.svg";
import back_icon from "../assets/images/back.svg";
import clipboard_icon from "../assets/images/clipboard-icon.svg";
import popup_img1 from "../assets/images/popup_img1.png";
import popup_img1_m from "../assets/images/popup_img1_m.png";

import titleTop10 from "../assets/images/title_top10.svg";
import titleReview from "../assets/images/title_review.svg";

import star from "../assets/images/star.svg";
import star_off from "../assets/images/star-off.svg";

import wrench from "../assets/images/wrench.svg";
import bell_ringing from "../assets/images/bell-ringing.svg";

import profile from "../assets/images/profile.png";
import login_bg from "../assets/images/login_bg.png";
import mark from "../assets/images/mark.png";
import plus from "../assets/images/plus.png";
import section1_img1 from "../assets/images/section1_img1.png";
import section1_img2 from "../assets/images/section1_img2.png";
import section1_img3 from "../assets/images/section1_img3.png";

import mask1 from "../assets/images/mask1.png";
import mask2 from "../assets/images/mask2.png";
import mask3 from "../assets/images/mask3.png";
import mask4 from "../assets/images/mask4.png";
import mask5 from "../assets/images/mask5.png";
import mask6 from "../assets/images/mask6.png";
import mask7 from "../assets/images/mask7.png";
import mask8 from "../assets/images/mask8.png";
import mask9 from "../assets/images/mask9.png";
import mask10 from "../assets/images/mask10.png";
import mask11 from "../assets/images/mask11.png";
import mask12 from "../assets/images/mask12.png";
import mask13 from "../assets/images/mask13.png";
import mask14 from "../assets/images/mask14.png";
import mask15 from "../assets/images/mask15.png";
import mask16 from "../assets/images/mask16.png";
import mask17 from "../assets/images/mask17.png";
import mask18 from "../assets/images/mask18.png";
import mask19 from "../assets/images/mask19.png";
import main_banner from "../assets/images/main_banner.png";
import main_banner_m from "../assets/images/main_banner_m.png";
import options_icon from "../assets/images/options_icon.svg";

import normal_icon from "../assets/images/normal_icon.svg";
import good_icon from "../assets/images/good_icon.svg";
import bad_icon from "../assets/images/bad_icon.svg";

import slider_img1 from "../assets/images/slider_img1.png";
import slider_img2 from "../assets/images/slider_img2.png";
import slider_img3 from "../assets/images/slider_img3.png";
import slider_img4 from "../assets/images/slider_img4.png";

import front_door_off from "../assets/images/theme/front_door_off.svg";
import front_door_on from "../assets/images/theme/front_door_on.svg";
import kitchen_off from "../assets/images/theme/kitchen_off.svg";
import kitchen_on from "../assets/images/theme/kitchen_on.svg";
import room_off from "../assets/images/theme/room_off.svg";
import room_on from "../assets/images/theme/room_on.svg";
import terrace_off from "../assets/images/theme/terrace_off.svg";
import terrace_on from "../assets/images/theme/terrace_on.svg";
import bathroom_off from "../assets/images/theme/bathroom_off.svg";
import bathroom_on from "../assets/images/theme/bathroom_on.svg";
import livingroom_off from "../assets/images/theme/livingroom_off.svg";
import livingroom_on from "../assets/images/theme/livingroom_on.svg";

import theme_main1 from "../assets/images/theme/theme_img1.png";
import theme_main2 from "../assets/images/theme/theme_img2.png";
import theme_main3 from "../assets/images/theme/theme_img3.png";
import theme_main4 from "../assets/images/theme/living_room_img1.png";
import theme_main5 from "../assets/images/theme/living_room_img2.png";
import theme_main6 from "../assets/images/theme/living_room_img3.png";
import theme_main7 from "../assets/images/theme/living_room_img4.png";
import theme_img1 from "../assets/images/theme/theme_img4.png";
import theme_img2 from "../assets/images/theme/theme_img5.png";
import theme_img3 from "../assets/images/theme/theme_img6.png";
import theme_img4 from "../assets/images/theme/theme_img7.png";
import theme_img5 from "../assets/images/theme/theme_img8.png";

import theme_detail_main from "../assets/images/theme/theme_detail_main.png";

import main_theme1 from "../assets/images/theme/front_door_main.svg";
import main_theme2 from "../assets/images/theme/kitchin_main.svg";
import main_theme3 from "../assets/images/theme/room_main.svg";
import main_theme4 from "../assets/images/theme/terrace_main.svg";
import main_theme5 from "../assets/images/theme/bathroom_main.svg";
import main_theme6 from "../assets/images/theme/living_room_main.svg";
import howto from "../assets/images/theme/how_to.svg";
import right_arrow from "../assets/images/theme/right_arrow.png";
import receipt from "../assets/images/theme/receipt.svg";
import more_btn from "../assets/images/theme/more_btn.svg";
import bitmap from "../assets/images/theme/bitmap.png";
import right_arrow1 from "../assets/images/theme/right_arrow1.svg";
import home_icon1 from "../assets/images/theme/home_icon1.svg";
import home_icon2 from "../assets/images/theme/home_icon2.svg";
import sub_logo from "../assets/images/theme/sub_logo.svg";
import path_8 from "../assets/images/theme/path-8.svg";
import list_img1 from "../assets/images/theme/list_img1.png";
import list_img2 from "../assets/images/theme/list_img2.png";
import list_img3 from "../assets/images/theme/list_img3.png";
import list_img4 from "../assets/images/theme/list_img4.png";
import list_img5 from "../assets/images/theme/list_img5.png";
import list_img6 from "../assets/images/theme/list_img6.png";
import list_img7 from "../assets/images/theme/list_img7.png";
import list_img8 from "../assets/images/theme/list_img8.png";
import list_img9 from "../assets/images/theme/list_img9.png";
import list_img10 from "../assets/images/theme/list_img10.png";
import list_img11 from "../assets/images/theme/list_img11.png";
import certification_icon from "../assets/images/theme/certification_icon.svg";
import receipt_icon from "../assets/images/theme/receipt_icon.svg";

import front_report_main from "../assets/images/theme/front_report_main.png";
import front_report_main1 from "../assets/images/theme/front_report_main1.png";
import search_icon from "../assets/images/theme/search_icon.svg";
import down_arrow from "../assets/images/theme/down_arrow.svg";
import sliders_horizontal from "../assets/images/theme/sliders-horizontal.svg";

import living_room_list_icon from "../assets/images/theme/living_room_list_icon.svg";
import kitchen_list_icon from "../assets/images/theme/kitchen_list_icon.svg";
import room_list_icon from "../assets/images/theme/room_list_icon.svg";
import front_door_list_icon from "../assets/images/theme/front_door_list_icon.svg";
import bath_room_list_icon from "../assets/images/theme/bath_room_list_icon.svg";
import terrace_list_icon from "../assets/images/theme/terrace_list_icon.svg";

import living_room3 from "../assets/images/theme/living_room3.svg";
import living_room4 from "../assets/images/theme/living_room4.svg";
import kitchen3 from "../assets/images/theme/kitchen3.svg";
import kitchen4 from "../assets/images/theme/kitchen4.svg";
import room3 from "../assets/images/theme/room3.svg";
import room4 from "../assets/images/theme/room4.svg";
import front_door3 from "../assets/images/theme/front_door3.svg";
import front_door4 from "../assets/images/theme/front_door4.svg";
import bath_room3 from "../assets/images/theme/bath_room3.svg";
import bath_room4 from "../assets/images/theme/bath_room4.svg";
import terrace3 from "../assets/images/theme/terrace3.svg";
import terrace4 from "../assets/images/theme/terrace4.svg";

import footer_icon1_m from "../assets/images/footer_icon1_m.svg";
import footer_icon1_1_m from "../assets/images/footer_icon1_1_m.svg";
import footer_icon2_m from "../assets/images/footer_icon2_m.svg";
import footer_icon2_1_m from "../assets/images/footer_icon2_1_m.svg";
import footer_icon3_m from "../assets/images/footer_icon3_m.svg";
import footer_icon3_1_m from "../assets/images/footer_icon3_1_m.svg";
import footer_icon4_m from "../assets/images/footer_icon4_m.svg";
import footer_icon4_1_m from "../assets/images/footer_icon4_1_m.svg";

import report_detail_main from "../assets/images/report/front_report_detail_main.png";
import comp_logo from "../assets/images/report/comp_logo.png";

import partner_main from "../assets/images/partner/partner_main.png";
import partner_main1 from "../assets/images/partner/partner_main1.png";
import comp_img1 from "../assets/images/partner/comp_img1.png";
import comp_img2 from "../assets/images/partner/comp_img2.png";
import comp_img3 from "../assets/images/partner/comp_img3.png";
import comp_img4 from "../assets/images/partner/comp_img4.png";
import comp_img5 from "../assets/images/partner/comp_img5.png";
import comp_img6 from "../assets/images/partner/comp_img6.png";
import comp_img7 from "../assets/images/partner/comp_img7.png";
import comp_img8 from "../assets/images/partner/comp_img8.png";
import right_arrow2 from "../assets/images/partner/right_arrow.svg";

import slide_btn_left from "../assets/images/slide_btn_left.svg";
import slide_btn_right from "../assets/images/slide_btn_right.svg";
import numbering1 from "../assets/images/numbering1.svg";
import numbering2 from "../assets/images/numbering2.svg";
import check_icon from "../assets/images/check_icon.svg";

import partner_introduce_img from "../assets/images/admin/partner_introduce_img.png";
import fallback from "../assets/images/admin/fallback.png";

const exportData = {
  exit,
  exit_white,
  popup_img1,
  popup_img1_m,
  checkbox_off,
  checkbox_on,
  calendar,
  camera,
  down,
  up,
  trash,
  profile,
  warning,
  warning_icon,
  checkmark,
  radio_on,
  radio_off,
  logo,
  info,
  login_bg,
  mark,
  plus,
  section1_img1,
  section1_img2,
  section1_img3,
  titleTop10,
  titleReview,
  mask1,
  mask2,
  mask3,
  mask4,
  mask5,
  mask6,
  mask7,
  mask8,
  mask9,
  mask10,
  mask11,
  mask12,
  mask13,
  mask14,
  mask15,
  mask16,
  mask17,
  mask18,
  mask19,
  main_banner,
  main_banner_m,
  slider_img1,
  slider_img2,
  slider_img3,
  slider_img4,
  plus_black,
  left_icon,
  right_icon,
  front_menu_white,
  front_menu_black_m,
  footer_icon1_m,
  footer_icon1_1_m,
  footer_icon2_m,
  footer_icon2_1_m,
  footer_icon3_m,
  footer_icon3_1_m,
  footer_icon4_m,
  footer_icon4_1_m,
  normal_icon,
  good_icon,
  bad_icon,
  list_icon,
  star,
  star_off,
  call,
  back_icon,
  front_door_off,
  front_door_on,
  kitchen_off,
  kitchen_on,
  room_off,
  room_on,
  terrace_off,
  terrace_on,
  bathroom_off,
  bathroom_on,
  livingroom_off,
  livingroom_on,
  main_theme1,
  main_theme2,
  main_theme3,
  main_theme4,
  main_theme5,
  main_theme6,
  howto,
  right_arrow,
  theme_img1,
  theme_img2,
  theme_img3,
  theme_img4,
  theme_img5,
  theme_main1,
  theme_main2,
  theme_main3,
  theme_main4,
  theme_main5,
  theme_main6,
  theme_main7,
  theme_detail_main,
  receipt,
  more_btn,
  bitmap,
  right_arrow1,
  living_room_list_icon,
  kitchen_list_icon,
  room_list_icon,
  front_door_list_icon,
  bath_room_list_icon,
  terrace_list_icon,
  home_icon1,
  home_icon2,
  front_report_main,
  front_report_main1,
  search_icon,
  down_arrow,
  sliders_horizontal,
  sub_logo,
  path_8,
  list_img1,
  list_img2,
  list_img3,
  list_img4,
  list_img5,
  list_img6,
  list_img7,
  list_img8,
  list_img9,
  list_img10,
  list_img11,
  certification_icon,
  receipt_icon,
  report_detail_main,
  comp_logo,
  partner_main,
  partner_main1,
  comp_img1,
  comp_img2,
  comp_img3,
  comp_img4,
  comp_img5,
  comp_img6,
  comp_img7,
  comp_img8,
  right_arrow2,
  slide_btn_left,
  slide_btn_right,
  clipboard_icon,
  options_icon,
  wrench,
  bell_ringing,
  numbering1,
  numbering2,
  living_room3,
  living_room4,
  kitchen3,
  kitchen4,
  room3,
  room4,
  front_door3,
  front_door4,
  bath_room3,
  bath_room4,
  terrace3,
  terrace4,
  check_icon,
  partner_introduce_img,
  fallback,
};

export default exportData;
