/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import * as APIS from "../../utils/service";

import Input from "../../components/Input";
import InputSelect from "../../components/InputSelect";

import images from "../../libs/images";
import consts from "../../libs/consts";

import { open } from "../../redux/popupSlice";
import AdminLayout from "../../components/Layout/admin";

export default function Setting() {
  const dispatch = useDispatch();
  const userData = useSelector((s) => s.usersReducer);

  /* search form */
  const [step, setStep] = useState(1);

  const [talk_list, setTalk_list] = useState([]); // 알림톡 자동발송 배열

  const [fix_list, setFix_list] = useState([]); // 정량제요금 배열

  const [sub_list, setSub_list] = useState([]); // 정액제요금 배열

  const [esti_list, setEsti_list] = useState([]); // 계약수수료 배열

  const [free_list, setFree_list] = useState([]); // 견적제공 배열

  const [sub_types, setsub_types] = useState([]); // 정액제요금 등급명 배열

  useEffect(() => {
    const sender = {
      admin_table_id: userData.admin_table_id,
      type: "0",
    };

    APIS.postData("/SetAdmin/GetAdminSetting", sender, 1)
      .then((res) => {
        setTalk_list(res.data.talk);
        setFix_list(res.data.fix);
        setSub_list(res.data.sub);
        setEsti_list(res.data.comm);
        setFree_list(res.data.esti);

        setsub_types(
          res.data.sub_types.map((x, i) => {
            return { title: x };
          })
        );
      })
      .catch((e) => {
        console.log("catch", e);
      });
  }, [step]);

  const zoneAppend = (type) => {
    if (type === 2) {
      setFix_list([
        ...fix_list,
        { count: "", price: "", type: "0", admin_use: false },
      ]);
    } else if (type === 3) {
      setSub_list([
        ...sub_list,
        {
          sub_type: sub_types[0].title,
          unit: "",
          unit_type: "1",
          ea: "",
          price: "",
          partner_type: "0",
          period_name: "",
          admin_use: false,
        },
      ]);
    } else if (type === 4) {
      setEsti_list([
        ...esti_list,
        {
          seq: "",
          type: "1",
          partner_type: "1",
          price: "",
          price_type: "0",
          rate: "",
        },
      ]);
    } else if (type === 5) {
      setFree_list([
        ...free_list,
        {
          partner_type: "0",
          type: "0",
          work_type: "0",
          price: "",
          price_type: "1",
        },
      ]);
    } else if (type === 6) {
      setsub_types([...sub_types, { title: "" }]);
    } else {
      setTalk_list([
        ...talk_list,
        { progress: "1", target: "1", type: "1", late: "" },
      ]);
    }
    return;
  };

  const zoneDelete = (i, id, type) => {
    if (type === 2) {
      let newFormValues = [...fix_list];
      newFormValues.splice(i, 1);
      setFix_list(newFormValues);
    } else if (type === 3) {
      let newFormValues = [...sub_list];
      newFormValues.splice(i, 1);
      setSub_list(newFormValues);
    } else if (type === 4) {
      let newFormValues = [...esti_list];
      newFormValues.splice(i, 1);
      setEsti_list(newFormValues);
    } else if (type === 5) {
      let newFormValues = [...free_list];
      newFormValues.splice(i, 1);
      setFree_list(newFormValues);
    } else if (type === 6) {
      let newFormValues = [...sub_types];
      newFormValues.splice(i, 1);

      let title_chk = sub_types[i].title;
      if (sub_list.filter((item) => item.sub_type === title_chk).length > 0) {
        dispatch(
          open({
            message: "등급명이 사용중입니다.",
          })
        );
      } else {
        setsub_types(newFormValues);
      }
    } else {
      let newFormValues = [...talk_list];
      newFormValues.splice(i, 1);
      setTalk_list(newFormValues);
    }
    return;
  };

  const allSetValue = (i, e, val, list) => {
    let newFormValues = [...list];

    newFormValues[i][e.target.name] = val;

    return newFormValues;
  };

  const talkSetValue = (i, e, val) => {
    setTalk_list(allSetValue(i, e, val, talk_list));
  };
  const fixSetValue = (i, e, val) => {
    setFix_list(allSetValue(i, e, val, fix_list));
  };
  const subSetValue = (i, e, val) => {
    setSub_list(allSetValue(i, e, val, sub_list));
  };
  const estiSetValue = (i, e, val) => {
    setEsti_list(allSetValue(i, e, val, esti_list));
  };
  const freeSetValue = (i, e, val) => {
    setFree_list(allSetValue(i, e, val, free_list));
  };
  const subTypeSetValue = (i, e, val) => {
    setsub_types(allSetValue(i, e, val, sub_types));
  };

  const submitFunc = () => {
    if (step === 1) {
      const sender = {
        admin_table_id: userData.admin_table_id,
        list: talk_list,
      };

      APIS.postData("/SetAdmin/SetTalk", sender, 1).catch((e) => {
        console.log("catch", e);
      });
    } else if (step === 2) {
      if (sub_types.filter((item) => !item.title).length > 0) {
        dispatch(
          open({
            message: "정액제 등급명을 입력해주세요.",
          })
        );
        return;
      }

      const sender = {
        admin_table_id: userData.admin_table_id,
        fix_list: fix_list,
        sub_list: sub_list,
        esti_list: esti_list,
        sub_types: sub_types.map((x, i) => {
          return x.title;
        }),
      };

      APIS.postData("/SetAdmin/SetPay", sender, 1).catch((e) => {
        console.log("catch", e);
      });
    } else if (step === 3) {
      const sender = {
        admin_table_id: userData.admin_table_id,
        free_list: free_list,
      };

      APIS.postData("/SetAdmin/SetFreeEsti", sender, 1).catch((e) => {
        console.log("catch", e);
      });
    }

    dispatch(
      open({
        message: "저장되었습니다.",
      })
    );
  };
  return (
    <AdminLayout
      title="설정"
      tabs={[
        {
          id: 1,
          title: "알림톡",
          callback: () => {
            setStep(1);
          },
          isActive: step === 1,
        },
        {
          id: 2,
          title: "결제관리",
          callback: () => {
            setStep(2);
          },
          isActive: step === 2,
        },
        {
          id: 3,
          title: "견적제공",
          callback: () => {
            setStep(3);
          },
          isActive: step === 3,
        },
      ]}
    >
      <div>
        {step === 1 ? (
          <div className="content_section setting_content_section">
            <div className="theme_multi_info_box">
              <p className="title">알림톡 자동발송</p>

              {talk_list.length > 0 &&
                talk_list.map((x, i) => {
                  return (
                    <div className="casezone_box setting_talk_box" key={i}>
                      <button
                        type="button"
                        className="trash_btn_red"
                        onClick={() =>
                          zoneDelete(i, x.zone_table_id ? x.zone_table_id : "")
                        }
                      />
                      <div className="one_line small_select_three">
                        <InputSelect
                          name="progress"
                          label={"단계"}
                          value={x.progress}
                          onChange={talkSetValue}
                          index={i}
                          option={consts.sellerOrderState}
                        />
                        <InputSelect
                          name="target"
                          label="대상"
                          value={x.target}
                          onChange={talkSetValue}
                          index={i}
                          option={[
                            { idx: "1", title: "사용자" },
                            { idx: "2", title: "파트너" },
                          ]}
                        />
                        <InputSelect
                          name="type"
                          label="시점"
                          value={x.type}
                          onChange={talkSetValue}
                          index={i}
                          option={consts.settingTalkoption}
                        />
                        <Input
                          className="input_text"
                          type="text"
                          placeholder="숫자만 입력해주세요."
                          name="late"
                          value={x.late}
                          onChange={talkSetValue}
                          index={i}
                          label="지연시간"
                          withText={"분후"}
                          valid={"num"}
                          readOnly={x.type === "1"}
                        />
                      </div>
                    </div>
                  );
                })}
              <button
                type="button"
                className="case_add_btn"
                onClick={() => zoneAppend()}
              >
                <img src={images.plus} alt="추가하기" className="plus_img" />
                추가하기
              </button>
            </div>
          </div>
        ) : step === 2 ? (
          <div className="content_section setting_content_section">
            <div className="theme_multi_info_box">
              <p className="title">정량제요금</p>

              {fix_list.map((x, i) => {
                return (
                  <div className="casezone_box setting_esti_box" key={i}>
                    <button
                      type="button"
                      className="trash_btn_red"
                      onClick={() =>
                        zoneDelete(i, x.zone_table_id ? x.zone_table_id : "", 2)
                      }
                    />
                    <div className="one_line full">
                      <Input
                        className="input_text"
                        type="text"
                        placeholder="숫자만 입력해주세요."
                        name="count"
                        value={x.count}
                        onChange={fixSetValue}
                        index={i}
                        label="수량"
                        valid={"num"}
                      />
                      <Input
                        className="input_text"
                        type="text"
                        placeholder="숫자만 입력해주세요."
                        name="price"
                        value={x.price}
                        onChange={fixSetValue}
                        index={i}
                        label="단가"
                        withText={"만원"}
                        valid={"num"}
                      />
                      <InputSelect
                        name="type"
                        label={"파트너유형"}
                        value={x.type}
                        onChange={fixSetValue}
                        index={i}
                        option={consts.settingFixoption}
                        notKey={true}
                      />
                    </div>
                    <div className="checkbox_section2">
                      <label className="input_label">관리자전용</label>
                      <div>
                        <input
                          type="checkbox"
                          id={"chkfix" + i}
                          className="checkbox"
                          name="admin_use"
                          checked={x.admin_use}
                          onChange={(e) => fixSetValue(i, e, e.target.checked)}
                        />
                        <label htmlFor={"chkfix" + i}></label>
                      </div>
                    </div>
                  </div>
                );
              })}

              <button
                type="button"
                className="case_add_btn"
                onClick={() => zoneAppend(2)}
              >
                <img src={images.plus} alt="추가하기" className="plus_img" />
                추가하기
              </button>
            </div>

            <div className="theme_multi_info_box">
              <p className="title">정액제 등급명</p>

              {sub_types.map((x, i) => {
                return (
                  <div className="casezone_box setting_esti_box" key={i}>
                    <button
                      type="button"
                      className="trash_btn_red"
                      onClick={() => zoneDelete(i, "", 6)}
                    />

                    <div className="one_line">
                      <Input
                        className="input_text"
                        type="text"
                        placeholder="등급명 입력"
                        name="title"
                        value={x.title}
                        onChange={subTypeSetValue}
                        index={i}
                        label="등급명"
                      />
                    </div>
                  </div>
                );
              })}
              <button
                type="button"
                className="case_add_btn"
                onClick={() => zoneAppend(6)}
              >
                <img src={images.plus} alt="추가하기" className="plus_img" />
                추가하기
              </button>
            </div>

            <div className="theme_multi_info_box">
              <p className="title">정액제요금</p>

              {sub_list.map((x, i) => {
                return (
                  <div className="casezone_box setting_esti_box" key={i}>
                    <button
                      type="button"
                      className="trash_btn_red"
                      onClick={() =>
                        zoneDelete(i, x.zone_table_id ? x.zone_table_id : "", 3)
                      }
                    />

                    <div className="one_line full">
                      <InputSelect
                        name="sub_type"
                        label={"등급명"}
                        value={x.sub_type}
                        onChange={subSetValue}
                        index={i}
                        optionNotKey={sub_types.map((x, i) => {
                          return x.title;
                        })}
                      />
                      {/* <Input className="input_text" type="text" placeholder="명칭 입력" name="sub_type" value={x.sub_type} onChange={subSetValue} index={i} label="명칭"/>   */}
                      <Input
                        className="input_text"
                        type="text"
                        placeholder="기간명 입력"
                        name="period_name"
                        value={x.period_name}
                        onChange={subSetValue}
                        index={i}
                        label="기간명"
                      />

                      <InputSelect
                        name="unit_type"
                        label="단위"
                        value={x.unit_type}
                        onChange={subSetValue}
                        index={i}
                        option={consts.day_option}
                      />
                      <Input
                        className="input_text"
                        type="text"
                        placeholder="단위 입력"
                        name="unit"
                        value={x.unit}
                        onChange={subSetValue}
                        index={i}
                        label="&emsp;"
                        valid={"num"}
                      />
                    </div>

                    <div className="one_line full">
                      <Input
                        className="input_text"
                        type="text"
                        placeholder="수량 입력"
                        name="ea"
                        value={x.ea}
                        onChange={subSetValue}
                        index={i}
                        label="수량"
                        valid={"num"}
                      />
                      <Input
                        className="input_text"
                        type="text"
                        placeholder="가격 입력"
                        name="price"
                        value={x.price}
                        onChange={subSetValue}
                        index={i}
                        label="단가"
                        withText={"만원"}
                        valid={"num"}
                      />
                      <InputSelect
                        name="partner_type"
                        label="파트너유형"
                        value={x.partner_type}
                        onChange={subSetValue}
                        index={i}
                        option={consts.settingFixoption}
                      />
                    </div>
                    <div className="checkbox_section2">
                      <label className="input_label">관리자전용</label>
                      <div>
                        <input
                          type="checkbox"
                          id={"chksub" + i}
                          className="checkbox"
                          name="admin_use"
                          checked={x.admin_use}
                          onChange={(e) => subSetValue(i, e, e.target.checked)}
                        />
                        <label htmlFor={"chksub" + i}></label>
                      </div>
                    </div>
                  </div>
                );
              })}
              <button
                type="button"
                className="case_add_btn"
                onClick={() => zoneAppend(3)}
              >
                <img src={images.plus} alt="추가하기" className="plus_img" />
                추가하기
              </button>
            </div>

            <div className="theme_multi_info_box">
              <p className="title">계약수수료</p>

              {esti_list.map((x, i) => {
                return (
                  <div className="casezone_box setting_esti_box" key={i}>
                    <button
                      type="button"
                      className="trash_btn_red"
                      onClick={() =>
                        zoneDelete(i, x.zone_table_id ? x.zone_table_id : "", 4)
                      }
                    />
                    <div className="one_line full">
                      <InputSelect
                        name="seq"
                        label="우선순위"
                        value={x.seq}
                        onChange={estiSetValue}
                        index={i}
                        optionNotKey={[
                          "1",
                          "2",
                          "3",
                          "4",
                          "5",
                          "6",
                          "7",
                          "8",
                          "9",
                          "10",
                        ]}
                      />
                      <InputSelect
                        name="type"
                        label="견적유형"
                        value={x.type}
                        onChange={estiSetValue}
                        index={i}
                        option={consts.estimateTypeoption}
                      />

                      <InputSelect
                        name="partner_type"
                        label="파트너유형"
                        value={x.partner_type}
                        onChange={estiSetValue}
                        index={i}
                        option={[
                          { idx: "1", title: "일반" },
                          { idx: "2", title: "우수" },
                        ]}
                      />
                    </div>

                    <div className="one_line full">
                      <InputSelect
                        name="price_type"
                        label={"시공금액"}
                        value={x.price_type}
                        onChange={estiSetValue}
                        index={i}
                        option={consts.settingPriceoption}
                      />
                      <Input
                        className="input_text"
                        type="text"
                        placeholder="가격 입력"
                        name="price"
                        value={x.price}
                        onChange={estiSetValue}
                        index={i}
                        label="단가"
                        withText={"만원 이하"}
                        valid={"num"}
                      />
                      <Input
                        className="input_text"
                        type="text"
                        placeholder="수수료율 입력"
                        name="rate"
                        value={x.rate}
                        onChange={estiSetValue}
                        index={i}
                        label="수수료율"
                        valid={"num"}
                      />
                    </div>
                  </div>
                );
              })}
              <button
                type="button"
                className="case_add_btn"
                onClick={() => zoneAppend(4)}
              >
                <img src={images.plus} alt="추가하기" className="plus_img" />
                추가하기
              </button>
            </div>
          </div>
        ) : (
          <div className="content_section setting_content_section">
            <div className="theme_multi_info_box">
              <p className="title">견적무료제공</p>

              {free_list.map((x, i) => {
                return (
                  <div className="casezone_box setting_talk_box" key={i}>
                    <button
                      type="button"
                      className="trash_btn_red"
                      onClick={() =>
                        zoneDelete(i, x.zone_table_id ? x.zone_table_id : "", 5)
                      }
                    />
                    <div className="one_line small_select_three">
                      <InputSelect
                        name="partner_type"
                        label="파트너유형"
                        value={x.partner_type}
                        onChange={freeSetValue}
                        index={i}
                        option={consts.settingFixoption}
                      />
                      <InputSelect
                        name="type"
                        label="견적유형"
                        value={x.type}
                        onChange={freeSetValue}
                        index={i}
                        option={consts.sellerOrderType}
                      />
                      <InputSelect
                        name="work_type"
                        label="시공범위"
                        value={x.work_type}
                        onChange={freeSetValue}
                        index={i}
                        option={[
                          { idx: "0", title: "전체" },
                          { idx: "1", title: "범위" },
                        ]}
                      />

                      <Input
                        className="input_text"
                        type="text"
                        placeholder="숫자만 입력해주세요."
                        name="price"
                        value={x.price}
                        onChange={freeSetValue}
                        index={i}
                        label="시공가격"
                        withText={"만원"}
                        valid={"num"}
                      />
                      <InputSelect
                        name="price_type"
                        label="&emsp;"
                        value={x.price_type}
                        onChange={freeSetValue}
                        index={i}
                        option={[
                          { idx: "1", title: "이상" },
                          { idx: "2", title: "이하" },
                        ]}
                      />
                    </div>
                  </div>
                );
              })}

              <button
                type="button"
                className="case_add_btn"
                onClick={() => zoneAppend(5)}
              >
                <img src={images.plus} alt="추가하기" className="plus_img" />
                추가하기
              </button>
            </div>
          </div>
        )}
        <div
          className="popup_list_btn_box popup_pay_step"
          style={{ marginBottom: "30px", marginTop: "50px" }}
        >
          <button type="button" className="btn btn3">
            취소
          </button>
          <button
            type="button"
            className="btn btn1"
            onClick={() => submitFunc()}
          >
            적용
          </button>
        </div>
      </div>
    </AdminLayout>
  );
}
