/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import * as APIS from "../../utils/service";
import { findJson, numFormat } from "../../utils/utils";

import moment from "moment";

import Input from "../../components/Input";
import InputDate from "../../components/InputDate";
import InputSelect from "../../components/InputSelect";
import Pagenation from "../../components/Pagenation";

import PayAdd from "./PayAdd";

import consts from "../../libs/consts";

import { open } from "../../redux/popupSlice";
import { setData } from "../../redux/searchSlice";
import AdminLayout from "../../components/Layout/admin";
import Table from "../../components/Common/Table/Table";
import { css } from "@emotion/react";

export default function Pay() {
  const dispatch = useDispatch();
  const userData = useSelector((s) => s.usersReducer);
  const search = useSelector((s) => s.searchReducer);
  const popup = useSelector((s) => s.popupReducer);

  /* search form */
  const [pageing, setPageing] = useState("");

  const [list, setList] = useState([]);
  /* search form End */

  useEffect(() => {
    dataFunc();
  }, [
    search.city,
    search.range,
    search.membership,
    search.state,
    search.license,
    search.sdate,
    search.edate,
    search.page,
    popup.open,
  ]);

  const dataFunc = () => {
    const sender = {
      admin_table_id: userData.admin_table_id,

      payment_state: search.state,
      payment_type: search.range,
      payment_table_id: search.stx,
      start_date: search.sdate,
      end_date: search.edate,

      page: search.page + 1,
    };

    APIS.postData("/AdminPay/PayList", sender, 1)
      .then((res) => {
        if (res.data.code === "OK") {
          setList(res.data.payment_list);
          setPageing({
            totalPages: Math.ceil(res.data.total_cnt / consts.pagerows),
          });
        } else {
          setList([]);
          setPageing({ totalPages: 1 });
        }
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };

  const searchFunc = (key, value) => {
    dispatch(
      setData({
        key: key,
        value: value,
      })
    );
  };

  const updateFunc = (x) => {
    dispatch(
      open({
        title: "결제관리",
        component: <PayAdd x={x} w={"u"} />,
      })
    );
  };
  const keyPressFunc = (e) => {
    if (e.key === "Enter") {
      dataFunc();
    }
  };

  const col = [
    { key: "payment_table_id", title: "결제번호" },
    {
      key: "payment_type",
      title: "결제구분",
      render: (x) => (
        <span>{findJson(consts.paymentoption, x.payment_type)}</span>
      ),
    },
    {
      key: "cnt",
      title: "건수",
      render: (x) => <span>{numFormat(x.cnt)}</span>,
    },
    { key: "seller_biz_name", title: "파트너명" },
    {
      key: "price",
      title: "금액",
      render: (x) => <span>{numFormat(x.price)}만원</span>,
    },
    {
      key: "payment_state",
      title: "결제상태",
      render: (x) => (
        <span>{findJson(consts.payStateOption, x.payment_state)}</span>
      ),
    },
    {
      key: "reg_date",
      title: "등록일자",
      render: (x) => <span>{moment(x.reg_date).format("YYYY.MM.DD")}</span>,
    },
    {
      key: "payment_date",
      title: "결제일자",
      render: (x) => (
        <span>
          {x.payment_date !== "0000-00-00 00:00:00"
            ? moment(x.payment_date).format("YYYY.MM.DD")
            : "-"}
        </span>
      ),
    },
    {
      key: "update_date",
      title: "수정일자",
      render: (x) => <span>{moment(x.update_date).format("YYYY.MM.DD")}</span>,
    },
  ];

  return (
    <AdminLayout title="결제 관리">
      <div>
        <div className="search_form search_form_long">
          <div className="search_form_left">
            <InputSelect
              name="range"
              label="결제구분"
              value={search.range}
              onSearch={searchFunc}
              option={[{ idx: "", title: "전체" }, ...consts.paymentoption]}
            />
            <InputSelect
              name="state"
              label="결제상태"
              value={search.state}
              onSearch={searchFunc}
              option={[{ idx: "", title: "전체" }, ...consts.payStateOption]}
            />
            <Input
              className="input_text"
              type="text"
              placeholder={"결제번호 입력"}
              name="stx"
              value={search.stx}
              onSearch={searchFunc}
              onKeyDown={keyPressFunc}
              label={"검색"}
            />

            <InputDate
              type="text"
              placeholder="시작날짜"
              placeholderEnd="종료날짜"
              name="ipd1"
              label="날짜선택"
              dateValue={search.sdate}
              onSearch={searchFunc}
              dateValueEnd={search.edate}
              multiple={true}
            />

            <button
              type="button"
              className="btn btn3"
              onClick={() => dataFunc()}
            >
              검색
            </button>
          </div>
          <div className="search_form_right">
            {/* <button type="button" className="btn btn1" onClick={() => addFunc()}>등록</button> */}
          </div>
        </div>

        <div
          css={css`
            margin: 30px 0 20px 0;
          `}
        >
          <Table
            columns={col}
            datas={list}
            rowCallback={(x) => updateFunc(x)}
          />
        </div>

        <Pagenation
          pageing={pageing}
          onSearch={searchFunc}
          curpage={search.page}
        />
      </div>
    </AdminLayout>
  );
}
