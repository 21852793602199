import React, { Fragment, useState } from "react";
import Input from "../../../components/Input";
import InputSelect from "../../../components/InputSelect";
import InputSelectArea from "../../../components/InputSelectArea";
import Upload from "../../../components/Common/Upload/Upload";

import consts from "../../../libs/consts";
import { openSub } from "../../../redux/subPopupSlice";
import Seller from "../../../components/Seller";
import { useDispatch } from "react-redux";
import { close } from "../../../redux/popupSlice";
import TabTitle from "../TabTitle";
import { loadingEnd, loadingStart } from "../../../redux/loadingSlice";
import * as APIS from "../../../utils/service";
import debounce from "lodash/debounce";
import { css } from "@emotion/react";

export default function ConstructionInfo(props) {
  const dispatch = useDispatch();

  const {
    data,
    caseTableId,
    setCaseTableId,
    seller,
    setSeller,
    setTabNum,
    tabNum,
    userData,
  } = props;
  /*시공정보 내역*/
  const [sellerText, setSellerText] = useState(
    data?.case_info ? data.case_info.seller_biz_name : ""
  );
  const [caseState, setCaseState] = useState(
    data?.case_info ? data.case_info.case_state : "0"
  );
  const [houseType, setHouseType] = useState(
    data?.case_info ? data.case_info.case_home_type : "1"
  );
  const [caseName, setCaseName] = useState(
    data?.case_info ? data.case_info.case_name : ""
  );
  const [city, setCity] = useState(
    data?.case_info ? data.case_info.case_work_area : ""
  );
  const [area, setArea] = useState(
    data?.case_info ? data.case_info.case_work_area2 : ""
  );

  const [pyung, setPyung] = useState(
    data?.case_info ? data.case_info.case_size : ""
  );
  const [constructionPeriod, setConstructionPeriod] = useState(
    data?.case_info ? data.case_info.case_work_period : ""
  );
  const [base_work_type, setbase_work_type] = useState(
    data?.case_info ? data.case_info.base_work_type : "1"
  );
  const [write, setWrite] = useState(false);
  const [is_photo_del, setis_photo_del] = useState(0);
  const [is_draw_del, setis_draw_del] = useState(0);

  const [mainPhotoImg, setMainPhotoImg] = useState(
    data?.case_info ? data.case_info.case_photo : ""
  );

  const [drawingImg, setDrawingImg] = useState(
    data?.case_info ? data.case_info.case_drawing : ""
  );
  /*시공정보 에러*/
  const [nameError, setNameError] = useState("");
  const [pyungError, setPyungError] = useState("");
  const [constructionPeriodError, setConstructionPeriodError] = useState("");
  const [gradeError, setGradeError] = useState("");

  const imgCheck = (list, msg) => {
    if (list.length < 1) {
      dispatch(
        openSub({
          message: msg,
          button: "확인",
        })
      );
      return true;
    }

    return false;
  };

  const stepChange = () => {
    const casePhotoChk = imgCheck(
      mainPhotoImg,
      "메인사진(대표)을 업로드 해주세요."
    );
    if (casePhotoChk) {
      return;
    }

    // 시공지역 입력 필수

    if (!city || !area) {
      dispatch(
        openSub({
          message: "시공지역을 입력해주세요.",
          button: "확인",
        })
      );
      return;
    }

    dispatch(loadingStart());

    const sender = {
      admin_table_id: userData.admin_table_id,
      seller_table_id:
        userData.grade === 10 ? seller : userData.seller_table_id,
      case_table_id: caseTableId ? caseTableId : "",
      case_home_type: houseType,
      case_name: caseName,
      case_work_area: city,
      case_work_area2: area,
      case_size: pyung,
      case_work_period: constructionPeriod,
      case_photo:
        data && data.case_info.case_photo === mainPhotoImg ? "" : mainPhotoImg,
      case_drawing:
        data && data.case_info.case_drawing === drawingImg ? "" : drawingImg,
      case_state: caseState,
      is_photo_del: is_photo_del,
      is_draw_del: is_draw_del,
      base_work_type: base_work_type, // 시공유형 추가
    };

    const url = userData.admin_table_id
      ? "/Admin/CaseCreate1st"
      : "/WorkCase/CaseCreate1st";

    /* 1step 임시저장 시키기 */
    APIS.postData(url, sender, 1)
      .then((res) => {
        setTimeout(() => {
          dispatch(loadingEnd());
          if (["number", "string"].includes(typeof res.data.case_table_id)) {
            setTabNum(2);
            setCaseTableId(res.data.case_table_id);
          } else {
            dispatch(
              openSub({
                message: "잠시 후 다시 시도해주세요.",
                button: "확인",
              })
            );
            return;
          }
        }, 1000);
      })
      .catch((e) => {
        console.log("catch", e);
      });
    return;
  };

  const sellerFind = () => {
    dispatch(
      openSub({
        title: "파트너찾기",
        wide: true,
        component: (
          <Seller
            value={seller}
            setValue={setSeller}
            setValueText={setSellerText}
          />
        ),
      })
    );
  };

  const closeFunc = () => {
    dispatch(
      openSub({
        message: "시공사례 등록을 중단 하시겠습니까?",
        buttonCencle: "취소",
        onPress: () => {
          dispatch(close());
        },
      })
    );
  };

  const changeFileHandler = debounce((file, type) => {
    if (type === "draw") {
      if (data && data.case_info.case_drawing !== file.fileImage) {
        setis_draw_del(1);
      }

      setDrawingImg(file.fileImage);
    } else if (type === "photo") {
      if (data && data.case_info.case_photo !== file.fileImage) {
        setis_photo_del(1);
      }

      setMainPhotoImg(file.fileImage);
    }
  }, 200);

  const deletePreviewHandler = (type) => {
    if (type === "draw") {
      if (
        data &&
        data.case_info.case_drawing &&
        data.case_info.case_drawing === drawingImg
      ) {
        setis_draw_del(1);
      }

      setDrawingImg("");
    } else if (type === "photo") {
      if (
        data &&
        data.case_info.case_photo &&
        data.case_info.case_photo === mainPhotoImg
      ) {
        setis_photo_del(1);
      }

      setMainPhotoImg("");
    }
  };

  return (
    <>
      <div className="popup_list_ul popup_instance_step">
        <TabTitle num={tabNum} />
        <div className="instance_form_list">
          {userData.grade === 10 && (
            <>
              <Input
                className="input_text"
                type="text"
                placeholder="파트너 찾기를 클릭해 주세요."
                name="seller"
                value={sellerText}
                setValue={setSellerText}
                readOnly={true}
                label="*파트너명"
                withButton={"파트너 찾기"}
                withButtonLong={true}
                withButtonPress={sellerFind}
                error={gradeError}
                setError={setGradeError}
              />
              <div
                className="radio_box"
                style={{
                  width: "calc(50% - 12px)",
                  padding: 0,
                  backgroundColor: "transparent",
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <InputSelect
                  name="state"
                  label="등록상태"
                  value={caseState}
                  setValue={setCaseState}
                  option={consts.instanceStateoption}
                  readOnly={!write}
                />
                {!write ? (
                  <button
                    type="button"
                    className="btn btn3"
                    onClick={() => setWrite(true)}
                    style={{ width: "100px" }}
                  >
                    수정
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn3"
                    onClick={() => setWrite(false)}
                    style={{ width: "100px" }}
                  >
                    저장
                  </button>
                )}
              </div>
            </>
          )}
          <InputSelect
            name="work"
            label="*주거유형"
            value={houseType}
            setValue={setHouseType}
            option={consts.workoption}
          />
          <Input
            className="input_text"
            type="text"
            placeholder={
              houseType === "1"
                ? "아파트명을 입력해주세요."
                : "사례명을 입력해주세요."
            }
            name="name"
            value={caseName}
            setValue={setCaseName}
            label={houseType === "1" ? "*아파트명" : "*사례명"}
            error={nameError}
            setError={setNameError}
            maxlength={20}
          />
          <InputSelectArea
            name="work"
            label="*시공지역"
            value={city}
            setValue={setCity}
            area={area}
            setArea={setArea}
          />
          <Input
            className="input_text"
            type="text"
            placeholder="숫자만 입력해주세요."
            name="size"
            value={pyung}
            setValue={setPyung}
            label="*평형"
            withText={"평"}
            maxlength={3}
            valid={"num"}
            error={pyungError}
            setError={setPyungError}
          />
          <Input
            className="input_text"
            type="text"
            placeholder="숫자만 입력해주세요."
            name="period"
            value={constructionPeriod}
            setValue={setConstructionPeriod}
            label="*시공기간"
            withText={"일"}
            maxlength={3}
            valid={"num"}
            error={constructionPeriodError}
            setError={setConstructionPeriodError}
          />

          <div
            css={css`
              margin-top: 24px;
              width: calc(50% - 12px);
            `}
          >
            <Upload
              name="photo"
              label="*메인사진(대표)"
              id="main_photo_img"
              preview={mainPhotoImg || ""}
              callback={(file) => changeFileHandler(file, "photo")}
              deletePreivew={() => deletePreviewHandler("photo")}
              size="full"
              resize={1}
            />
          </div>
          <div
            css={css`
              margin-top: 24px;
              width: calc(50% - 12px);
            `}
          >
            <Upload
              name="drawing"
              label="도면사진"
              id="drawing_img"
              preview={drawingImg || ""}
              callback={(file) => changeFileHandler(file, "draw")}
              deletePreivew={() => deletePreviewHandler("draw")}
              size="full"
              resize={1}
            />
          </div>
          <div
            className="checkbox_section"
            style={{
              width: "calc(50% - 12px)",
              padding: 0,
              backgroundColor: "transparent",
            }}
          >
            <p className="input_label">시공유형</p>
            <div
              className="radio_box"
              style={{ width: "100%", marginTop: "4px" }}
            >
              <input
                type="radio"
                id="rangerd1"
                name="range"
                className="radio2"
                value="1"
                defaultChecked={base_work_type === "1"}
                onChange={(e) => setbase_work_type(e.target.value)}
              />
              <label htmlFor="rangerd1">전체시공</label>
              <input
                type="radio"
                id="rangerd2"
                name="range"
                className="radio2"
                value="2"
                defaultChecked={base_work_type === "2"}
                onChange={(e) => setbase_work_type(e.target.value)}
              />
              <label htmlFor="rangerd2">부분시공</label>
            </div>
          </div>
        </div>
      </div>
      <div className="popup_list_btn_box popup_pay_step">
        <button type="button" className="btn btn3" onClick={() => closeFunc()}>
          취소
        </button>
        <button type="button" className="btn btn1" onClick={() => stepChange()}>
          다음
        </button>
      </div>
    </>
  );
}
