/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";

import * as APIS from "../../utils/service";
import { findJson } from "../../utils/utils";

import moment from "moment";

import Input from "../../components/Input";
import InputDate from "../../components/InputDate";
import InputSelect from "../../components/InputSelect";
import Pagenation from "../../components/Pagenation";

import ThemeAdd from "./ThemeAdd";

import consts from "../../libs/consts";

import { open, close } from "../../redux/popupSlice";
import { openSub } from "../../redux/subPopupSlice";
import { setData } from "../../redux/searchSlice";
import AdminLayout from "../../components/Layout/admin";
import Table from "../../components/Common/Table/Table";
import { css } from "@emotion/react";

export default function Theme() {
  const dispatch = useDispatch();
  const location = useLocation();
  const userData = useSelector((s) => s.usersReducer);
  const search = useSelector((s) => s.searchReducer);
  const popup = useSelector((s) => s.popupReducer);

  /* search form */
  const [pageing, setPageing] = useState("");

  const [list, setList] = useState([]);
  /* search form End */
  useEffect(() => {
    if (location.state && location.state.vid) {
      updateFunc(location.state.vid);
    }
  }, [location]);

  useEffect(() => {
    dataFunc();
  }, [
    search.city,
    search.range,
    search.membership,
    search.state,
    search.license,
    search.sdate,
    search.edate,
    search.page,
    popup.open,
  ]);

  const dataFunc = () => {
    const sender = {
      admin_table_id: userData.admin_table_id,
      state: search.state,
      theme_table_id: search.stx,
      start_date: search.sdate,
      end_date: search.edate,
      page: search.page + 1,
    };

    APIS.postData("/AdminTheme/ThemeList", sender, 1)
      .then((res) => {
        if (res.data.code === "OK") {
          setList(res.data.theme_list);
          setPageing({
            totalPages: Math.ceil(res.data.total_cnt / consts.pagerows),
          });
        } else {
          setList([]);
          setPageing({ totalPages: 1 });
        }
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };

  const searchFunc = (key, value) => {
    dispatch(
      setData({
        key: key,
        value: value,
      })
    );
  };
  const addFunc = () => {
    dispatch(
      open({
        title: "테마등록",
        wide: true,
        component: <ThemeAdd data={""} w={"i"} />,
        onCancelPressAlert: () => {
          dispatch(
            openSub({
              message: "테마 등록을 중단 하시겠습니까?",
              buttonCencle: "취소",
              onPress: () => {
                dispatch(close());
              },
            })
          );
        },
      })
    );
  };
  const updateFunc = (id) => {
    dispatch(
      open({
        title: "테마수정",
        wide: true,
        component: <ThemeAdd id={id} w={"u"} />,
        onCancelPressAlert: () => {
          dispatch(
            openSub({
              message: "테마 수정을 중단 하시겠습니까?",
              buttonCencle: "취소",
              onPress: () => {
                dispatch(close());
              },
            })
          );
        },
      })
    );
  };
  const keyPressFunc = (e) => {
    if (e.key === "Enter") {
      dataFunc();
    }
  };

  const col = [
    { key: "theme_table_id", title: "테마등록번호" },
    { key: "theme_title", title: "테마명", className: "th-theme-title" },
    {
      key: "theme_area_text",
      title: "시공가능 지역",
      render: (x) => (
        <span>{x.theme_area_text && x.theme_area_text.join(", ")}</span>
      ),
      className: "th-available-area",
    },
    { key: "view_cnt", title: "조회수" },
    { key: "supply_cnt", title: "제공수" },
    { key: "receipt", title: "계약수" },
    { key: "partner_cnt", title: "파트너수" },
    {
      key: "theme_state",
      title: "상태",
      render: (x) => <span>{findJson(consts.themeState, x.theme_state)}</span>,
    },
    {
      key: "reg_date",
      title: "등록일자",
      render: (x) => <span>{moment(x.reg_date).format("YYYY.MM.DD")}</span>,
    },
    {
      key: "update_date",
      title: "수정일자",
      render: (x) => <span>{moment(x.update_date).format("YYYY.MM.DD")}</span>,
    },
    {
      key: "theme_size",
      title: "비고",
      render: (x) => <span>{x.theme_size ? x.theme_size.join(", ") : ""}</span>,
    },
  ];

  return (
    <AdminLayout title="테마 관리">
      <div>
        <div className="search_form search_form_long">
          <div className="search_form_left">
            <InputSelect
              name="state"
              label="상태"
              value={search.state}
              onSearch={searchFunc}
              option={[{ idx: "", title: "전체" }, ...consts.themeState]}
            />
            <Input
              className="input_text"
              type="text"
              placeholder={"테마등록번호 입력"}
              name="stx"
              value={search.stx}
              onSearch={searchFunc}
              onKeyDown={keyPressFunc}
              label={"검색"}
            />
            <InputDate
              type="text"
              placeholder="시작날짜"
              placeholderEnd="종료날짜"
              name="ipd1"
              label="날짜선택"
              dateValue={search.sdate}
              onSearch={searchFunc}
              dateValueEnd={search.edate}
              multiple={true}
            />

            <button
              type="button"
              className="btn btn3"
              onClick={() => dataFunc()}
            >
              검색
            </button>
          </div>
          <div className="search_form_right">
            <button
              type="button"
              className="btn btn1"
              onClick={() => addFunc()}
            >
              등록
            </button>
          </div>
        </div>

        <div
          css={css`
            margin: 30px 0 20px 0;

            .th-theme-title {
              width: 150px;
            }

            .th-available-area {
              width: 20%;
              max-width: 150px;
            }
          `}
        >
          <Table
            columns={col}
            datas={list}
            rowCallback={(x) => updateFunc(x.theme_table_id)}
          />
        </div>

        <Pagenation
          pageing={pageing}
          onSearch={searchFunc}
          curpage={search.page}
        />
      </div>
    </AdminLayout>
  );
}
