import { createSlice } from '@reduxjs/toolkit';

const subPopupSlice = createSlice({
  name: 'subpopup',
  initialState: {
    open: false,
    title: "알림",
    titleLong: "",
    button: "확인",
    buttonCencle: "",
    onCancelPress: "",
    onCancelPressAlert: "",
    onPress: "",
    message: "",
    component: "",
    subMessage: "",
    subComponent: "",
    messageAuto: "",
    wide: false,
    wideOne: false,
    mideum: false,
    one: false,
    suc: false,
    overFlow: false,
  },
  reducers: {
    openSub(state, action) {
        state.open = true;
        state.onCancelPress = action.payload.onCancelPress;
        state.onPress = action.payload.onPress;
        state.message = action.payload.message;

        if(action.payload.onCancelPressAlert) state.onCancelPressAlert = action.payload.onCancelPressAlert;

        if(action.payload.title) state.title = action.payload.title;
        if(action.payload.button) state.button = action.payload.button;
        if(action.payload.buttonCencle) state.buttonCencle = action.payload.buttonCencle;

        if(action.payload.component) state.component = action.payload.component;
        if(action.payload.titleLong) state.titleLong = action.payload.titleLong;
        if(action.payload.subMessage) state.subMessage = action.payload.subMessage;
        if(action.payload.subComponent) state.subComponent = action.payload.subComponent;
        if(action.payload.messageAuto) state.messageAuto = action.payload.messageAuto;
        if(action.payload.wide) state.wide = action.payload.wide;
        if(action.payload.wideOne) state.wideOne = action.payload.wideOne;
        if(action.payload.mideum) state.mideum = action.payload.mideum;
        if(action.payload.suc) state.suc = action.payload.suc;
        if(action.payload.one) state.one = action.payload.one;
        
        if(action.payload.overFlow) state.overFlow = action.payload.overFlow;
    },
    closeSub(state) {
        state.open = false;
        state.title = "알림";
        state.onCancelPress = "";
        state.onPress = "";
        state.button = "확인";
        state.buttonCencle = "";
        state.message = "";
        state.component = "";
        state.titleLong = "";
        state.subMessage = "";
        state.subComponent = "";
        state.messageAuto = "";
        state.wide = false;
        state.wideOne = false;
        state.mideum = false;
        state.suc = false;
        
        state.overFlow = false;
    },
  },
});

export const {
    openSub,
    closeSub,
} = subPopupSlice.actions;

export default subPopupSlice.reducer;
