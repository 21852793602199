/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import * as APIS from "../../utils/service";
import { numFormat } from "../../utils/utils";
import { close } from "../../redux/popupSlice";
import { openSub } from "../../redux/subPopupSlice";
import Input from "../../components/Input";
import InputSelect from "../../components/InputSelect";
import TextArea from "../../components/TextArea";
import InputFile from "../../components/InputFile";
import consts from "../../libs/consts";

export default function AsAdd(props) {
  const { x, w, id } = props;
  const dispatch = useDispatch();
  const userData = useSelector((s) => s.usersReducer);

  const [as_table_id] = useState(id ? id : "");

  const [cker, setCker] = useState(false); //계약번호 조회결과
  const [cont_table_id, setCont_table_id] = useState(""); //계약번호
  const [seller_biz_name, setseller_biz_name] = useState(""); //파트너명
  const [order_price, setorder_price] = useState(""); //시공가격
  const [work_end_date, setwork_end_date] = useState(""); //시공종료일

  const [state, setState] = useState("1");
  const [type, setType] = useState("1");

  const [estimate_msg, setEstimate_msg] = useState("");

  const [photoList, setPhotoList] = useState("");
  const [detail_img, setDetail_img] = useState("");

  const [error1, setError1] = useState("");

  useEffect(() => {
    if (w === "u") {
      const sender = {
        admin_table_id: userData.admin_table_id,
        as_table_id: as_table_id,
      };

      APIS.postData("/AdminAS/ASTarget", sender)
        .then((res) => {
          if (res.data.code === "OK") {
            const one = res.data.as_list[0];

            setCont_table_id(one.cont_table_id); // 계약번호
            setState(one.state); // 처리상태
            setType(one.type); // A/S유형
            setEstimate_msg(one.desc); // 요청내역
            setDetail_img(one.img_url);

            setseller_biz_name(x.seller_biz_name);
            setorder_price(one.order_price);
            setwork_end_date(one.real_end_date);
            setCker(true);
          }
        })
        .catch((e) => {
          console.log("catch", e);
        });
    }
  }, []);

  const deleteFunc = () => {
    dispatch(
      openSub({
        message: "A/S 요청정보를 삭제 하시겠습니까?",
        buttonCencle: "취소",
        onPress: () => {
          const sender = {
            admin_table_id: userData.admin_table_id, // 관리자 고유키
            as_table_id: as_table_id, // 고유키
          };

          APIS.postData("/AdminAS/ASDel", sender)
            .then((res) => {
              if (res.data.code === "OK") {
                dispatch(close());

                setTimeout(() => {
                  dispatch(
                    openSub({
                      message: "삭제 되었습니다.",
                    })
                  );
                }, 50);
              }
            })
            .catch((e) => {
              console.log("catch", e);
            });
        },
      })
    );
  };

  const regFunc = () => {
    if (!cker) {
      dispatch(
        openSub({
          message: "계약번호를 확인해주세요.",
        })
      );
      return;
    }

    const sender = {
      admin_table_id: userData.admin_table_id, // 관리자 고유키
      as_table_id: as_table_id, // 고유키 (요청)
      cont_table_id: cont_table_id, // 계약번호
      type: type, // A/S 유형
      state: state, // 처리상태
      desc: estimate_msg, // 요청내역
      img_url: photoList ? detail_img : "", // 참고사진
    };

    APIS.postData("/AdminAS/create", sender)
      .then((res) => {
        if (res.data.code === "OK") {
          dispatch(close());
          setTimeout(() => {
            dispatch(
              openSub({
                message: (w === "i" ? "등록" : "수정") + " 되었습니다.",
              })
            );
          }, 50);
        }
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };

  /* 요청번호 확인 */
  const findFunc = () => {
    if (cont_table_id === "") {
      setError1("계약번호를 입력해 주세요.");
      return;
    }

    const sender = {
      admin_table_id: userData.admin_table_id,
      cont_table_id: cont_table_id,
    };

    APIS.postData("/AdminAS/ASChk", sender)
      .then((res) => {
        if (res.data.code === "OK" && res.data.cont_table_id) {
          setCont_table_id(res.data.cont_table_id);
          setseller_biz_name(res.data.seller_biz_name);
          setorder_price(res.data.order_price);
          setwork_end_date(res.data.work_end_date);

          setCker(true);
        } else {
          dispatch(
            openSub({
              message: "해당 계약정보가 없습니다.",
            })
          );
        }
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };

  return (
    <form className="popup_form">
      <div className="admin_pay_form_list">
        {w === "u" && (
          <button
            type="button"
            className="btn btn2 popup_form_delete_button"
            onClick={() => deleteFunc()}
          >
            삭제
          </button>
        )}

        <Input
          className="input_text"
          type="text"
          placeholder="내용을 입력해 주세요."
          name="cont_table_id"
          value={cont_table_id}
          setValue={setCont_table_id}
          label="계약번호"
          withButton={w === "i" ? "확인" : false}
          withButtonPress={cker ? "" : findFunc}
          error={error1}
          setError={setError1}
          readOnly={w === "u" || cker}
          autoComplete="off"
        />

        {cker && (
          <>
            <Input
              className="input_text"
              type="text"
              placeholder="내용을 입력해 주세요."
              name="seller_biz_name"
              value={seller_biz_name}
              setValue={setseller_biz_name}
              label="파트너명"
              readOnly={true}
            />
            <Input
              className="input_text"
              type="text"
              placeholder="내용을 입력해 주세요."
              name="work_end_date"
              value={moment(work_end_date).format("YYYY.MM.DD")}
              setValue={setwork_end_date}
              label="시공종료일"
              readOnly={true}
            />
            <Input
              className="input_text"
              type="text"
              placeholder="내용을 입력해 주세요."
              name="order_price"
              value={numFormat(order_price)}
              setValue={setorder_price}
              label="시공가격"
              readOnly={true}
              withText={"만원"}
            />
          </>
        )}

        <InputSelect
          name="type"
          label="처리상태"
          value={state}
          setValue={setState}
          option={consts.asStateOption}
        />
        <InputSelect
          name="type"
          label="A/S 유형"
          value={type}
          setValue={setType}
          option={consts.asTypeOption1}
        />

        <TextArea
          className="input_textarea"
          placeholder="내용을 입력해 주세요. (500자 이내)"
          name="estimate_msg"
          value={estimate_msg}
          setValue={setEstimate_msg}
          label="요청내역"
        />

        <InputFile
          type="file"
          placeholder="사진을 첨부해 주세요."
          name="photo"
          label="참고사진"
          value={photoList}
          setValue={setPhotoList}
          imgvalue={detail_img}
          setImgValue={setDetail_img}
          deleteFunc={() => null}
          valid="image"
          full={true}
        />
      </div>

      <div className="popup_btn">
        <button
          type="button"
          className="popbtn cencle_btn"
          onClick={() => {
            dispatch(close());
          }}
          style={{ width: "100%", marginRight: "10px" }}
        >
          취소
        </button>
        <button
          type="button"
          className="popbtn ok_btn"
          onClick={() => {
            regFunc();
          }}
          style={{ width: "100%" }}
        >
          {w === "i" ? "확인" : "수정"}
        </button>
      </div>
    </form>
  );
}
