/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import * as APIS from "../utils/service";
import styled from "styled-components";

const NoticeTitleWrap = styled.div`
  & > span {
    max-width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export default function Notice(props) {
  const { selected } = props;
  const [list, setList] = useState([]);
  const [sid, setSid] = useState(selected ? selected : "");
  const [page, setPage] = useState(0);

  useEffect(() => {
    dataFunc();
  }, [page]);

  const setIdFunc = (id) => {
    if (sid === id) setSid("");
    else setSid(id);
  };

  const dataFunc = () => {
    const sender = {
      user_type: "2",
      page: page,
    };
    APIS.postData("/SellerNotice/SellerNoticeList", sender, 2)
      .then((res) => {
        setList(res.data.notice_list);
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };

  return (
    <div className="popup_list">
      <ul className="popup_list_ul">
        {list &&
          list.map((x, i) => {
            return (
              <li
                className={x.notice_table_id === sid ? "active" : ""}
                key={i}
                onClick={() => setIdFunc(x.notice_table_id)}
              >
                <NoticeTitleWrap className="title_box">
                  <p className="title">{x.title}</p>
                  <span>{x.contents}</span>
                </NoticeTitleWrap>
                <div className="content_box">
                  <p>{x.contents}</p>
                </div>
              </li>
            );
          })}
      </ul>

      <div className="popup_list_btn_box">
        <button type="button" className="btn btn3" onClick={() => setPage(1)}>
          더보기
        </button>
      </div>
    </div>
  );
}
