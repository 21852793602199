export const eventTypeList = [
  { label: "이벤트", value: "1" },
  { label: "컨텐츠", value: "2" },
];

export const eventStateList = [
  { value: "1", label: "검수요청" },
  { value: "2", label: "대기" },
  { value: "3", label: "운영중" },
  { value: "4", label: "종료" },
];

export const eventDbStateList = [
  { value: "1", label: "대기" },
  { value: "2", label: "접수 완료" },
  { value: "3", label: "접수 취소" },
  { value: "4", label: "고객 부재" },
];

export const noticeUrlTypeList = [
  { label: "이벤트", value: "1" },
  { label: "컨텐츠", value: "2" },
];
