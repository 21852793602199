import React, { useEffect, useState, useRef } from "react";

import { useDispatch } from "react-redux";
import { openSub } from "../redux/subPopupSlice";

import { getFileName, clickImg } from "../utils/utils";

export default function InputFile(props) {
  const dispatch = useDispatch();

  const {
    placeholder,
    type,
    name,
    value,
    setValue,
    imgvalue,
    setImgValue,
    valid,
    label,
    error,
    setError = () => console.log(""),
    success,
    readOnly,
    withButton,
    deleteFunc,
    full,
  } = props;

  const fileInput = useRef();
  const [cker, setCker] = useState(false);
  const [detailImageUrl, setDetailImageUrl] = useState("");

  useEffect(() => {
    setCker(imgvalue ? true : false);
    setDetailImageUrl(imgvalue ? imgvalue : "");
  }, [imgvalue]);

  const handleChange = ({ file }) => {
    setError("");

    let reg = "";
    let msg = "";
    let maxSize = 10 * 1024 * 1024;

    if (valid === "image") {
      reg = /(.*?)\.(jpg|jpeg|png|gif|bmp)$/;
      msg = "이미지";
    } else if (valid === "doc") {
      reg = /(.*?)\.(jpg|jpeg|png|gif|bmp|pdf)$/;
      msg = "문서";
    } else if (valid === "video") {
      reg = /(.*?)\.(avi|mp4)$/;
      msg = "동영상";
    }

    if (file.name.toLowerCase().match(reg)) {
      if (file.size > maxSize) {
        fileInput.current.value = "";
        setCker(false);
        setValue("");
        setImgValue("");
        setDetailImageUrl("");

        dispatch(
          openSub({
            message: "10MB 이하 이미지만 업로드 가능합니다.",
          })
        );
      } else {
        setValue(file);

        let reader = new FileReader();
        reader.onload = function () {
          setDetailImageUrl(reader.result);
          setImgValue(reader.result);

          setCker(true);
        };
        reader.readAsDataURL(file);
      }
    } else {
      fileInput.current.value = "";
      setCker(false);
      setValue("");
      setImgValue("");
      setDetailImageUrl("");

      dispatch(
        openSub({
          message: msg + " 파일만 업로드 가능합니다.",
        })
      );
    }
  };

  // set file reader function
  const fileDelete = () => {
    if (!readOnly) {
      fileInput.current.value = "";
      setCker(false);
      setValue("");
      setImgValue("");
      setDetailImageUrl("");

      if (deleteFunc) deleteFunc();
    }
  };

  return (
    <div className={full ? "input_box input_box_full" : "input_box"}>
      <p className="input_label">{label}</p>

      <div className="input_section">
        {withButton ? (
          <div
            className={readOnly ? "input_file_info disable" : "input_file_info"}
          >
            <p className="input_file_info_name with_button">
              {value ? value.name : "파일찾기"}
            </p>
            <div className="input_file_info_img" style={{ display: "flex" }}>
              {cker && (
                <button
                  type="button"
                  className="trash_btn"
                  onClick={() => fileDelete()}
                />
              )}
              <label
                className="input_file_with_button"
                htmlFor={!readOnly ? name : ""}
              >
                {withButton}
              </label>
            </div>
          </div>
        ) : !cker ? (
          <label
            className={
              readOnly ? "input_file_label disable" : "input_file_label"
            }
            htmlFor={!readOnly ? name : ""}
          >
            <span>{placeholder ? placeholder : "파일찾기"}</span>
          </label>
        ) : (
          <div className="input_file_info">
            <p className="input_file_info_name">
              {value ? value.name : imgvalue ? getFileName(imgvalue) : ""}
            </p>
            <div className="input_file_info_img">
              <img
                src={detailImageUrl}
                onClick={() => clickImg(detailImageUrl)}
                style={{ cursor: "pointer" }}
                alt="detail_img"
              />
              <button
                type="button"
                className="trash_btn"
                onClick={() => fileDelete()}
              />
            </div>
          </div>
        )}

        <input
          ref={fileInput}
          type={type}
          name={name}
          id={name}
          onChange={({ target: { files } }) => {
            if (files.length) {
              handleChange({
                file: files[0],
              });
            }
          }}
        />
      </div>

      {error && <p className="input_error">{error}</p>}
      {success && <p className="input_success">{success}</p>}
    </div>
  );
}
