import React from "react";
import AdminLayout from "../../../components/Layout/admin";
import useHome from "./home.hook";
import HomeManageMonthly from "../../../components/HomeManage/Monthly";

const HomeManageMonthlyPage = () => {
  const { tabs } = useHome();

  return (
    <AdminLayout title="홈 화면 관리" tabs={tabs}>
      <HomeManageMonthly />
    </AdminLayout>
  );
};

export default HomeManageMonthlyPage;
