const exportData = {
    'cl_1' : '#066E4D',
    'cl_2' : '#F1C0C0',
    'cl_3' : '#FB3B3B',
    'cl_4' : '#FB3B3B',
    'cl_5' : '#1E90FF',
    'cl_6' : '#e3b3b3'
}

export default exportData;
  