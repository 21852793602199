/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import * as APIS from "../../utils/service";
import SubNav from "../../components/SubNav";
import MemberShip from "../../components/MemberShip";
import PayMembership from "./PayMembership";
import routes from "../../libs/routes";
import { open } from "../../redux/popupSlice";
import { numFormat } from "../../utils/utils";
import PartnerLayout from "../../components/Layout/partner";
import { css } from "@emotion/react";

export default function Membership() {
  const dispatch = useDispatch();
  const userData = useSelector((s) => s.usersReducer);
  const popup = useSelector((s) => s.popupReducer);

  const [available_count, setavailable_count] = useState("");
  const [fixed_term_end, setfixed_term_end] = useState("");
  const [fixed_term_grade, setfixed_term_grade] = useState("");
  const [fixed_term_start, setfixed_term_start] = useState("");
  const [max_count, setmax_count] = useState("");
  const [pay_type, setpay_type] = useState("");
  const [period_term_end, setperiod_term_end] = useState("");
  const [period_term_start, setperiod_term_start] = useState("");
  const [period_cnt, setperiod_cnt] = useState(0);

  const nav = [
    { routes: routes.my, title: "파트너 정보" },
    { routes: routes.membership, title: "멤버십 정보" },
    { routes: routes.acc, title: "계정 정보" },
  ];

  useEffect(() => {
    const urls = "/Membership/MemInfo";
    const sender = {
      seller_table_id: userData.seller_table_id,
    };

    APIS.postData(urls, sender)
      .then((res) => {
        setavailable_count(res.data.available_count);
        setfixed_term_end(res.data.fixed_term_end);
        setfixed_term_grade(res.data.fixed_term_grade);
        setfixed_term_start(res.data.fixed_term_start);
        setmax_count(res.data.max_count);
        setpay_type(res.data.pay_type);
        setperiod_term_end(res.data.period_term_end);
        setperiod_term_start(res.data.period_term_start);
        setperiod_cnt(res.data.period_cnt * 1);
      })
      .catch((e) => {
        console.log("catch", e);
      });
  }, [popup.open]);

  const payFunc = () => {
    dispatch(
      open({
        title: "멤버십 구매",
        mideum: true,
        component: <PayMembership />,
      })
    );
  };

  const cmsFunc = () => {
    const sender = {
      seller_table_id: userData.seller_table_id,
    };

    APIS.postData("/Membership/CmsJoin", sender, 1)
      .then((res) => {
        dispatch(
          open({
            message:
              "CMS신청을 요청하였습니다.\n신청을 위해 담당자가 파트너분께\n연락드릴 예정입니다.",
          })
        );
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };

  const outFunc = () => {
    const sender = {
      seller_table_id: userData.seller_table_id,
    };

    APIS.postData("/Membership/CmsCancel", sender, 1)
      .then(() => {
        dispatch(
          open({
            message:
              "해지요청을 하였습니다.\n해지를 위해 담당자가 연락을 드릴 예정입니다.",
          })
        );
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };

  return (
    <PartnerLayout>
      <div
        css={css`
          padding: 52px 0 0 0;
          margin: 0 -30px -30px -30px;
        `}
      >
        <div className="page_title_box page_title_box1">
          <p className="title">{userData.seller_biz_name}</p>
          <SubNav nav={nav} />
        </div>

        <div className="content_section">
          <div className="membership_section">
            <p className="sub_title">
              멤버십을 구매하시면 고객 상담예약 정보를 제공받을 수 있습니다.
            </p>
            <p className="title">내 멤버십</p>
            <div className="membership_list">
              {!fixed_term_grade && !period_term_start && !period_term_end ? (
                <div className="no_membership">
                  <p className="title">아직 이용중이신 멤버십이 없습니다.</p>
                  <button
                    type="button"
                    className="btn btn1"
                    onClick={() => payFunc()}
                  >
                    멤버십 구매
                  </button>
                </div>
              ) : (
                <ul>
                  {fixed_term_grade && (
                    <li>
                      <div>
                        <p className="lay_membership_box">
                          <span className="title">정액제</span>
                          <MemberShip type={fixed_term_grade} title={true} />
                        </p>
                        <ul className="list_style1">
                          <li>
                            <p className="title">수량</p>
                            <p className="content">
                              {numFormat(available_count)}/
                              {numFormat(max_count)} 건
                            </p>
                          </li>
                          <li>
                            <p className="title">기간</p>
                            <p
                              className={
                                "content " +
                                (moment().isAfter(fixed_term_end) ? "cl_3" : "")
                              }
                            >
                              {moment(fixed_term_start).format("YYYY.MM.DD")} ~{" "}
                              {moment(fixed_term_end).format("YYYY.MM.DD")}{" "}
                              {moment().isAfter(fixed_term_end) && "(기간만료)"}
                            </p>
                          </li>
                        </ul>
                      </div>
                      <div className="btn_box">
                        {moment().isAfter(fixed_term_end) && (
                          <button
                            type="button"
                            className="btn btn4"
                            onClick={() => payFunc()}
                          >
                            구매
                          </button>
                        )}
                        {pay_type === "1" && (
                          <button
                            type="button"
                            className="btn btn4"
                            onClick={() => cmsFunc()}
                          >
                            CMS신청
                          </button>
                        )}
                        {pay_type === "4" && (
                          <button
                            type="button"
                            className="btn btn4 cl_3"
                            onClick={() => outFunc()}
                          >
                            해지요청
                          </button>
                        )}
                      </div>
                    </li>
                  )}

                  <li>
                    <div>
                      <p className="lay_membership_box">
                        <span className="title">정량제</span>
                      </p>
                      <ul className="list_style1">
                        <li>
                          <p className="title">수량</p>
                          <p className="content">{numFormat(period_cnt)} 건</p>
                        </li>
                        <li>
                          <p className="title">기간</p>
                          {/* <p className={"content " + (moment().isAfter(period_term_end) ? "cl_3" : "")}>{moment(period_term_start).format('YYYY.MM.DD')} ~ {moment(period_term_end).format('YYYY.MM.DD')} {moment().isAfter(period_term_end) && '(기간만료)'}</p> */}
                          <p className={"content"}>(기간제한없음)</p>
                        </li>
                      </ul>
                    </div>
                    <div className="btn_box">
                      <button
                        type="button"
                        className="btn btn4"
                        onClick={() => payFunc()}
                      >
                        구매
                      </button>
                    </div>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    </PartnerLayout>
  );
}
