import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { openSub } from "../redux/subPopupSlice";

import { clickImg } from "../utils/utils";

export default function InputFileMulti(props) {
  const dispatch = useDispatch();

  const {
    type,
    name,
    maxlength = 10,
    value,
    setValue,
    defaulteImgvalue,
    setImgValue,
    valid,
    label,
    error,
    setError = () => console.log(""),
    success,
    onChange,
    onDelete,
    onDeleteKey,
    index,
    imgName,
    imgValue,
    full,
  } = props;

  const fileInput = useRef();
  const [detailImageUrl, setDetailImageUrl] = useState(
    defaulteImgvalue ? defaulteImgvalue : ""
  );

  const handleChange = async (e) => {
    setError("");

    const files = e.target.files;
    if (files.length < 1) return;
    if (files.length + value.length > maxlength) {
      dispatch(
        openSub({
          message: "최대 " + maxlength + "개까지 등록 가능합니다.",
        })
      );
      return;
    }

    let reg = "";
    let msg = "";
    let cker = true;
    const maxSize = 10 * 1024 * 1024;

    if (valid === "image") {
      reg = /(.*?)\.(jpg|jpeg|png|gif|bmp)$/;
      msg = "이미지";
    } else if (valid === "doc") {
      reg = /(.*?)\.(jpg|jpeg|png|gif|bmp|pdf)$/;
      msg = "문서";
    }

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (!file.name.toLowerCase().match(reg)) {
        dispatch(
          openSub({
            message: msg + " 파일만 등록 가능합니다.",
          })
        );
        cker = false;
        return false;
      } else if (file.size > maxSize) {
        dispatch(
          openSub({
            message: "10MB 이하 이미지만 업로드 가능합니다.",
          })
        );
        cker = false;
        return false;
      }
    }

    if (cker) {
      let files_url = Array.from(files).map((file) => {
        let reader = new FileReader();

        // Create a new promise
        return new Promise((resolve) => {
          // Resolve the promise after reading file
          reader.onload = () => {
            resolve(reader.result);
          };

          // Reade the file as a text
          reader.readAsDataURL(file);
        });
      });

      let res = await Promise.all(files_url);
      if (onChange) {
        onChange(index, e, files);
        let nm = { target: { name: imgName } };
        onChange(index, nm, res);
      } else {
        let detailImageUrls = [...detailImageUrl, ...res];
        setValue((value) => [...value, ...files]);
        setDetailImageUrl(detailImageUrls);
        setImgValue(detailImageUrls);
      }
    }
  };

  // set file reader function
  const fileDelete = (i, id) => {
    let values = Array.from(value);
    let detailImageUrls = imgValue ? imgValue : detailImageUrl;

    if (onDelete) {
      let nm = { target: { name: name } };
      onDelete(index, nm, [
        ...values.slice(0, i),
        ...values.slice(i + 1, values.length),
      ]);
      nm = { target: { name: imgName } };
      onDelete(index, nm, [
        ...detailImageUrls.slice(0, i),
        ...detailImageUrls.slice(i + 1, detailImageUrls.length),
      ]);
    } else {
      setValue([...values.slice(0, i), ...values.slice(i + 1, values.length)]);
      setDetailImageUrl([
        ...detailImageUrls.slice(0, i),
        ...detailImageUrls.slice(i + 1, detailImageUrls.length),
      ]);
      setImgValue([
        ...detailImageUrls.slice(0, i),
        ...detailImageUrls.slice(i + 1, detailImageUrls.length),
      ]);
      if (onDeleteKey) {
        onDeleteKey((deleteKey) => [...deleteKey, id]);
      }
    }
  };

  return (
    <div className={full ? "input_box input_box_full" : "input_box"}>
      <p className="input_label">{label}</p>
      <p className="input_label_help">
        아래의 [카메라] 버튼을 눌러 추가해 주세요.
      </p>

      <div className="input_section_multi">
        {imgValue &&
          imgValue.map((x, i) => {
            return (
              <div key={i} className="input_multi_preview">
                <img
                  src={x}
                  onClick={(e) => clickImg(e.target.src)}
                  style={{ cursor: "pointer" }}
                  alt="multi_img"
                />
                <button
                  type="button"
                  className="img_delete_btn"
                  onClick={() =>
                    fileDelete(
                      i,
                      x.case_detail_table_id ? x.case_detail_table_id : ""
                    )
                  }
                />
              </div>
            );
          })}

        {detailImageUrl.length > 0 &&
          detailImageUrl.map((x, i) => {
            return (
              <div key={i} className="input_multi_preview">
                <img
                  src={x.case_detail_table_id ? x.case_detail_img : x}
                  onClick={(e) => clickImg(e.target.src)}
                  style={{ cursor: "pointer" }}
                  alt="multi_preview_img"
                />
                <button
                  type="button"
                  className="img_delete_btn"
                  onClick={() =>
                    fileDelete(
                      i,
                      x.case_detail_table_id ? x.case_detail_table_id : ""
                    )
                  }
                />
              </div>
            );
          })}

        <label
          className="input_file_label_multi"
          htmlFor={name + (index && index)}
        ></label>
        <input
          ref={fileInput}
          type={type}
          name={name}
          id={name + (index && index)}
          onChange={handleChange}
          multiple
        />
      </div>

      {error && <p className="input_error">{error}</p>}
      {success && <p className="input_success">{success}</p>}
    </div>
  );
}
