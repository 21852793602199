import React from "react";

export default function MemberShip(props) {
  const { type, small } = props;

  return (
    <>
      <span
        className={
          "fontMont lay_membership " +
          "lay_membership" +
          (small ? " small" : "")
        }
      >
        {type}
      </span>
    </>
  );
}
