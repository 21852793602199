import React, { useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import Modal from "../../../Common/ModalPortal";
import images from "../../../../libs/images";
import Button from "../../../Common/Button/Button";
import * as Colors from "../../../../constants/color";
import { marginTopStyles } from "../../home.style";
import Input from "../../../Common/Input/Input";
import Upload, { FileData } from "../../../Common/Upload/Upload";
import debounce from "lodash/debounce";
import useHomeManageRecommend from "../homeManageRecommend.hook";

const Container = styled.div`
  width: 1160px;
  background-color: #ffffff;
  margin: auto;
`;

const Header = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${Colors.gray_200};

  & > h3 {
    margin: auto 0;
  }
`;

const Content = styled.div`
  padding: 10px 20px 30px 20px;
`;

const Footer = styled.div`
  padding: 20px;
  border-top: 1px solid ${Colors.gray_200};
  display: flex;
  justify-content: center;
  gap: 15px;
`;

interface Props {
  close: () => void;
  callback: () => void;
}

interface ItemType {
  id: number;
  title: string;
  description: string;
  url: string;
  upload: FileData;
}

const CreateHomeManageRecommend = (props: Props) => {
  const { close, callback } = props;

  const { create } = useHomeManageRecommend();

  const [contentName, setContentName] = useState("");

  const [items, setItems] = useState<ItemType[]>([
    {
      id: 1,
      title: "",
      description: "",
      url: "",
      upload: { file: undefined, fileImage: "", fileName: "" },
    },
    {
      id: 2,
      title: "",
      description: "",
      url: "",
      upload: { file: undefined, fileImage: "", fileName: "" },
    },
    {
      id: 3,
      title: "",
      description: "",
      url: "",
      upload: { file: undefined, fileImage: "", fileName: "" },
    },
  ]);

  const updateInputHandler = debounce(
    (id: number, e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      setItems(
        items.map((item) =>
          item.id === id
            ? {
                ...item,
                [name]: value,
              }
            : item
        )
      );
    },
    200
  );

  const updateFileUploadHandler = debounce((id: number, file: FileData) => {
    setItems(
      items.map((item) =>
        item.id === id
          ? {
              ...item,
              upload: file,
            }
          : item
      )
    );
  }, 200);

  const createHandler = async () => {
    const params = {
      content_name: contentName,
      detail_list: items.map((item) => ({
        content_detail_title: item.title,
        content_detail_desc: item.description,
        content_detail_url: item.url,
        content_detail_img: item.upload.fileImage,
      })),
    };

    const res = await create(params);

    if (res) {
      callback();
    }
  };

  return (
    <Modal>
      <Container>
        <Header>
          <h3>추천 컨텐츠 등록</h3>
          <button onClick={close}>
            <img src={images.exit} alt="exit_btn" />
          </button>
        </Header>
        <Content>
          <div css={[marginTopStyles]}>
            <Input
              label="* 키워드 명"
              placeholder="키워드 작성"
              onChange={(e) => setContentName(e.target.value)}
            />
          </div>
          <div
            css={css`
              margin-top: 20px;
              display: flex;
              justify-content: space-between;
            `}
          >
            {items.map((item, i) => (
              <div
                key={item.id}
                css={css`
                  width: 32%;
                  padding-right: 2%;
                  border-right: 1px solid ${Colors.gray_200};

                  &:last-of-type {
                    border: none;
                  }
                `}
              >
                <div css={[marginTopStyles]}>
                  <Upload
                    id={`file_${item.id}`}
                    label={`* 대표 이미지 ${i + 1} 등록`}
                    size="full"
                    callback={(file) => updateFileUploadHandler(item.id, file)}
                  />
                </div>
                <div css={[marginTopStyles]}>
                  <Input
                    label="* 타이틀 (25자 이내)"
                    maxLength={25}
                    placeholder="타이틀을 입력해주세요."
                    size="full"
                    name="title"
                    onChange={(e) => updateInputHandler(item.id, e)}
                  />
                </div>
                <div css={[marginTopStyles]}>
                  <Input
                    label="* 설명 (50자 이내)"
                    maxLength={50}
                    placeholder="보조 문구를 입력해주세요."
                    size="full"
                    name="description"
                    onChange={(e) => updateInputHandler(item.id, e)}
                  />
                </div>
                <div css={[marginTopStyles]}>
                  <Input
                    label="* 연결 URL"
                    placeholder="URL을 입력해주세요."
                    size="full"
                    name="url"
                    onChange={(e) => updateInputHandler(item.id, e)}
                  />
                </div>
              </div>
            ))}
          </div>
        </Content>
        <Footer>
          <Button theme="tertiary" onClick={close}>
            취소
          </Button>
          <Button onClick={createHandler}>등록</Button>
        </Footer>
      </Container>
    </Modal>
  );
};

export default CreateHomeManageRecommend;
