import React from "react";
import { css } from "@emotion/react";
import Tabs from "../../Common/Tabs/Tabs";
import * as Colors from "../../../constants/color";
import routes from "../../../libs/routes";

const menuStyles = css`
  background-color: ${Colors.blue_100};
  border-right: 1px solid ${Colors.blue_400};
  position: fixed;
  top: 52px;
  left: 0;
  width: 220px;
  height: calc(100vh - 52px);
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${Colors.gray_200};
  }

  & > ul {
    width: 100%;
    padding: 15px 0 0 24px;

    & > li {
      text-align: left;
      height: 60px;
      line-height: 60px;
      padding-bottom: 0;
      margin-bottom: 0;
      display: flex;
      cursor: auto;

      & > a {
        font-size: 16px;

        &:hover {
          font-weight: bold;
        }
      }
    }
  }
`;

const AdminMenu = () => {
  const { pathname } = window.location;

  const menus = [
    {
      id: 1,
      title: "홈화면 관리",
      as: routes.home_manage_hero,
      isActive: [
        routes.home_manage_hero,
        routes.home_manage_pick,
        routes.home_manage_recommend,
        routes.home_manage_monthly,
        routes.home_manage_line_banner,
        routes.home_manage_center_banner,
        routes.home_manage_popup,
      ].includes(pathname),
    },
    {
      id: 2,
      title: "회원 관리",
      as: routes.member,
      isActive: pathname === routes.member,
    },
    {
      id: 3,
      title: "파트너 관리",
      as: routes.partner,
      isActive:
        pathname === routes.partner || pathname.includes("/partnerDetail/"),
    },
    {
      id: 4,
      title: "안심번호 관리",
      as: routes.safe_numbers,
      isActive: pathname === routes.safe_numbers,
    },
    {
      id: 5,
      title: "시공사례 관리",
      as: routes.instance,
      isActive: pathname === routes.instance,
    },
    {
      id: 6,
      title: "생활시공 관리",
      as: routes.life_construction,
      isActive: pathname === routes.life_construction,
    },
    {
      id: 7,
      title: "테마 관리",
      as: routes.theme,
      isActive: pathname === routes.theme,
    },
    {
      id: 8,
      title: "견적 관리",
      as: routes.admEstimate,
      isActive:
        pathname === routes.admEstimate ||
        pathname.includes("/estimateDetail/"),
    },
    {
      id: 9,
      title: "계약 관리",
      as: routes.order,
      isActive: pathname === routes.order,
    },
    {
      id: 10,
      title: "결제 관리",
      as: routes.admPay,
      isActive: pathname === routes.admPay,
    },
    {
      id: 11,
      title: "실시공 관리",
      as: routes.real,
      isActive: pathname === routes.real,
    },
    {
      id: 12,
      title: "A/S 관리",
      as: routes.as,
      isActive: pathname === routes.as,
    },
    {
      id: 13,
      title: "광고 관리",
      as: routes.notice_event,
      isActive: [
        routes.notice_partner,
        routes.notice_user,
        routes.notice_event,
        routes.notice_ad,
        routes.notice_url,
      ].includes(pathname),
    },
    {
      id: 14,
      title: "이벤트 접수 현황",
      as: routes.event_home,
      isActive: [
        routes.event_home,
        routes.event_aptn,
        routes.event_hanssem,
        routes.event_aptn_counsel,
        routes.event_db,
      ].includes(pathname),
    },
    {
      id: 15,
      title: "로그 정보",
      as: routes.logs,
      isActive: pathname === routes.logs,
    },
    {
      id: 16,
      title: "설정",
      as: routes.setting,
      isActive: pathname === routes.setting,
    },
  ];

  return (
    <div css={[menuStyles]}>
      <Tabs
        datas={menus}
        activeText={""}
        underline={false}
        type="vertical"
        size="big"
        activeColor="black"
      />
    </div>
  );
};

export default AdminMenu;
