import React from "react";
import AdminLayout from "../../../components/Layout/admin";
import useEventTabs from "./event.hook";
import EventAptn from "../../../components/Event/Aptn";

const EventAptnPage = () => {
  const { tabs } = useEventTabs();

  return (
    <AdminLayout title="이벤트 접수 현황" tabs={tabs}>
      <EventAptn />
    </AdminLayout>
  );
};

export default EventAptnPage;
