import React, { useState } from "react";
import { css } from "@emotion/react";
import Table from "../../Common/Table/Table";
import Pagination from "../../Common/Pagination/Pagination";
import moment from "moment";
import Button from "../../Common/Button/Button";
import { CSVLink } from "react-csv";
import UpdateEventDb from "./Update";
import Select from "../../Common/Select/Select";
import {
  eventDbStateList,
  eventTypeList,
} from "../../../constants/home-manage";
import Input from "../../Common/Input/Input";
import useEventDb from "./eventDb.hook";
import useNoticeAd from "../../Notice/Ad/noticeAd.hook";
import { getEventDbState, getEventType } from "../../../utils/home-manage";
import BasicModal from "../../Common/Modal/BasicModal";
import * as Colors from "../../../constants/color";
import useCsv from "../../../hooks/useCsv";

const tableButtonStyles = css`
  border: 1px solid ${Colors.gray_300};
  background-color: ${Colors.gray_100};
  font-size: 12px;
  padding: 5px;
`;

const EventDb = () => {
  const [id, setId] = useState<string | undefined>();

  const [searchData, setSearchData] = useState({
    evt_type: "",
    state: "",
    evt_name: "",
    seller_biz_name: "",
    user_name: "",
    user_phone: "",
  });

  const { list, total, page, search, postEsitimate } = useEventDb({
    isList: true,
    searchData,
  });

  const { area } = useNoticeAd();
  const { csvLinkRef, getCsvHeader, downloadHandler } = useCsv();

  const [detailDesc, setDetailDesc] = useState("");

  const changeSelectHandler = (
    e: React.ChangeEvent<HTMLSelectElement>,
    filed: string
  ) => {
    const { value } = e.target;

    setSearchData((prevData) => ({
      ...prevData,
      [filed]: value,
    }));
  };

  const changeInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setSearchData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // 견적 넘기기
  const postEstimateHandler = (id: string) => {
    if (window.confirm("견적 넘기기를 하시겠습니까?")) {
      postEsitimate(id);
    }
  };

  const col = [
    { key: "evt_table_id", title: "이벤트 번호" },
    {
      key: "evt_type",
      title: "구분",
      render: (data: any) => <span>{getEventType(data.evt_type)}</span>,
    },
    { key: "seller_biz_name", title: "파트너 명" },
    {
      key: "evt_name",
      title: "이벤트 명",
    },
    {
      key: "user_name",
      title: "이름",
    },
    {
      key: "user_phone",
      title: "연락처",
    },
    {
      key: "size",
      title: "평형",
    },
    {
      key: "dong",
      title: "공사 주소",
      render: (x: any) => (
        <span>
          {`${
            area.find((item) => item.area_id === x.area_id)?.area_name || "-"
          } ${
            area
              .find((item) => item.area_id === x.area_id)
              ?.area_2nd.find(
                (item) => item.area_detail_id === x.area_detail_id
              )?.area_detail_name || "-"
          }`}
        </span>
      ),
    },
    {
      key: "work_date",
      title: "공사 예정일",
    },
    {
      key: "request_desc",
      title: "요청 사항",
      render: (data: any) =>
        data.request_desc ? (
          <p
            onClick={(e) => {
              e.stopPropagation();
              setDetailDesc(data.request_desc);
            }}
          >
            [상세]
          </p>
        ) : (
          <span>-</span>
        ),
    },
    {
      key: "state",
      title: "상태",
      render: (data: any) => <span>{getEventDbState(data.state)}</span>,
    },
    {
      key: "reg_date",
      title: "요청일시",
    },
    {
      key: "etc",
      title: "비고",
      className: "table_etc",
      render: (data: any) => (
        <div>
          <button
            css={tableButtonStyles}
            onClick={(e) => {
              e.stopPropagation();
              postEstimateHandler(data.evt_table_id);
            }}
          >
            견적 넘기기
          </button>
        </div>
      ),
    },
  ];

  const callback = () => {
    setId(undefined);
    search();
  };

  return (
    <div>
      <div
        css={css`
          margin-top: 24px;
          display: flex;
          justify-content: space-between;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-wrap: wrap;
            gap: 15px;
          `}
        >
          <div
            css={css`
              width: 130px;
            `}
          >
            <Select
              label="구분"
              defaultValue="전체"
              options={eventTypeList}
              size="full"
              onChange={(e) => changeSelectHandler(e, "evt_type")}
            />
          </div>

          <div
            css={css`
              width: 130px;
            `}
          >
            <Select
              size="full"
              label="처리 상태"
              defaultValue="전체"
              options={eventDbStateList}
              onChange={(e) => changeSelectHandler(e, "state")}
            />
          </div>

          <div>
            <Input
              label="이벤트 명"
              placeholder="입력"
              size="small"
              name="evt_name"
              onChange={changeInputHandler}
            />
          </div>
          <div>
            <Input
              label="파트너 명"
              placeholder="입력"
              size="small"
              name="seller_biz_name"
              onChange={changeInputHandler}
            />
          </div>
          <div>
            <Input
              label="이름 (고객명)"
              placeholder="입력"
              size="small"
              name="user_name"
              onChange={changeInputHandler}
            />
          </div>
          <div>
            <Input
              label="연락처"
              placeholder="입력"
              size="small"
              name="user_phone"
              onChange={changeInputHandler}
            />
          </div>
          <div
            css={css`
              width: 130px;
              margin-top: auto;
            `}
          >
            <Button theme="tertiary" onClick={search}>
              검색
            </Button>
          </div>
        </div>
        <div
          css={css`
            margin-top: auto;
          `}
        >
          <Button theme="tertiary" onClick={() => downloadHandler()}>
            엑셀 다운로드
          </Button>
          <CSVLink
            ref={csvLinkRef}
            data={list.map((l) => ({
              ...l,
              evt_type: getEventType(l.evt_type),
              state: getEventDbState(l.state),
              dong: `${
                area.find((item) => l.area_id === item.area_id)?.area_name ||
                "-"
              } ${
                area
                  .find((item) => l.area_id === item.area_id)
                  ?.area_2nd.find(
                    (item) => l.area_detail_id === item.area_detail_id
                  )?.area_detail_name || "-"
              }`,
            }))}
            headers={getCsvHeader(col)}
            filename={
              "이벤트_DB_모음_" +
              moment(new Date()).format("YYYY_MM_DD(HH)") +
              ".csv"
            }
            target="_blank"
            asyncOnClick={true}
          />
        </div>
      </div>
      <div
        css={css`
          margin: 30px 0 20px 0;

          .table_etc {
            width: 110px;
          }
        `}
      >
        <Table
          columns={col}
          datas={list}
          rowCallback={(data) => setId(data.evt_table_id)}
        />
        <Pagination curPage={page} total={total} pageSize={20} />
      </div>

      {id && (
        <UpdateEventDb
          close={() => setId(undefined)}
          id={id}
          callback={callback}
        />
      )}
      <BasicModal
        open={!!detailDesc}
        onClose={() => setDetailDesc("")}
        title="요청사항 보기"
        confirmText="닫기"
        confirm={() => setDetailDesc("")}
      >
        <p
          css={css`
            word-wrap: break-word;
          `}
        >
          {detailDesc}
        </p>
      </BasicModal>
    </div>
  );
};

export default EventDb;
