import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as APIS from "../../utils/service";
import Input from "../../components/Input";
import { open, close } from "../../redux/popupSlice";

export default function PwReset() {
  const dispatch = useDispatch();
  const userData = useSelector((s) => s.usersReducer);

  const [pw, setPw] = useState("");
  const [repw, setRePw] = useState("");

  const [error, setError] = useState("");
  const [error2, setError2] = useState("");
  const [success2, setSuccess2] = useState("");

  useEffect(() => {
    if (!pw || !repw) {
      setError2("");
      setSuccess2("");
      return;
    }

    if (pw !== repw) {
      setError2("비밀번호가 일치하지 않습니다.");
      setSuccess2("");
    } else {
      setError2("");
      setSuccess2("올바른 비밀번호 입니다.");
    }
  }, [pw, repw]);

  const pwFunc = () => {
    if (pw !== repw || pw.length < 8) {
      setError2("비밀번호를 확인해 주세요.");
      return;
    }

    dispatch(close());
    const sender = {
      seller_table_id: userData.seller_table_id,
      seller_passwd: pw,
    };
    APIS.postData("/SellerInfo/ChgPwd", sender, 2)
      .then((res) => {
        if (res.data.code === "OK") {
          dispatch(
            open({
              message: "비밀번호가 변경 되었습니다.",
              button: "확인",
            })
          );
        } else {
          dispatch(
            open({
              message: "잠시 후 다시 시도해주세요.",
              button: "확인",
            })
          );
        }
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };
  return (
    <>
      <div className="register_body popup_register_body">
        <Input
          className="input_text"
          type="password"
          placeholder="영문+숫자+특수문자 조합 (최소 8자~15자)"
          name="pw"
          value={pw}
          setValue={setPw}
          label="비밀번호"
          valid={"pw"}
          error={error}
          setError={setError}
          maxlength={15}
        />
        <Input
          className="input_text"
          type="password"
          placeholder="영문+숫자+특수문자 조합 (최소 8자~15자)"
          name="repw"
          value={repw}
          setValue={setRePw}
          label="비밀번호 확인"
          error={error2}
          success={success2}
          setError={setError2}
          valid={"pw"}
          maxlength={15}
        />
        <div className="register_btn_box">
          <button
            type="button"
            className="btn btn3"
            onClick={() => dispatch(close())}
          >
            취소
          </button>
          <button type="button" className="btn btn1" onClick={() => pwFunc()}>
            변경
          </button>
        </div>
      </div>
    </>
  );
}
