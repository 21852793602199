import React from "react";
import AdminLayout from "../../../components/Layout/admin";
import useHome from "./home.hook";
import HomeManageCenterBanner from "../../../components/HomeManage/CenterBanner";

const HomeManageCenterBannerPage = () => {
  const { tabs } = useHome();

  return (
    <AdminLayout title="홈 화면 관리" tabs={tabs}>
      <HomeManageCenterBanner />
    </AdminLayout>
  );
};

export default HomeManageCenterBannerPage;
