import Axios from "axios";
import consts from "../libs/consts";

const baseURL = consts.apiBaseUrlAdmin;

const axiosInstance = Axios.create({
  baseURL: baseURL,
  timeout: 10000,
  headers: {
    "Content-Type": "text/plain",
  },
});

export default axiosInstance;
