import React, { useState } from "react";
import styled from "@emotion/styled";
import SubModal from "../../Common/SubModal";
import images from "../../../libs/images";
import Table from "../../Common/Table/Table";
import * as APIS from "../../../utils/service";

const Container = styled.div`
  width: 930px;
  background-color: #ffffff;
  margin: auto;
`;

const Header = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e1e1e1;

  & > h3 {
    margin: auto 0;
  }
`;

const Content = styled.div`
  padding: 20px;
`;

const InputWithButton = styled.div`
  margin-bottom: 20px;

  & > p {
    font-size: 12px;
    font-weight: bold;
    line-height: 1.5;
    color: #8f9bb3;
    display: inline-block;
    width: 100%;
  }

  & > div {
    height: 48px;
    margin-top: 6px;
    position: relative;

    & > input {
      border-right: none !important;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      width: calc(100% - 103px);
      display: inline-block;
      height: 100%;
      padding: 0 14px;
      border: solid 1px #e4e9f2;
      font-size: 16px;
    }

    & > button {
      width: 103px;
      border: 1px solid #e4e9f2;
      background-color: #fff;
      height: 100%;
      font-size: 16px;
      color: #718096;
    }
  }
`;

const Footer = styled.div`
  padding: 20px;
  border-top: 1px solid #e1e1e1;
  text-align: center;

  .footer_modal {
    width: 145px !important;
    height: 48px;
    line-height: 48px;

    &.exit {
      background-color: #fff;
      color: #718096;
      font-size: 14px;
      font-weight: bold;
      border: solid 1px #e4e9f2;
      transition: border 0.3s, color 0.3s;
    }
    &.submit {
      background-color: #066e4d;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      transition: background-color 0.3s;
      margin-left: 15px;
    }
  }
`;

const FindPartnerModal = (props: any) => {
  const { close, submit } = props;
  const [partnerId, setPartnerId] = useState("");
  const [partnerList, setPartnerList] = useState<any>([]);
  const [cehcekdId, setChecekdId] = useState("");

  const searchHandler = async () => {
    if (partnerId.length < 2) {
      alert("2글자 이상 입력해주세요.");
      return;
    }
    try {
      const params = {
        srch: partnerId,
      };

      const res = await APIS.postData("/AdminSafety/SellerSrch", params, 1);

      if (res.data.code === "OK") {
        setPartnerList(res.data.seller_list);
      } else {
        alert("알수 없는 문제가 발생하였습니다. 다시 시도 해주세요.");
        return;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const submitHandler = () => {
    if (!cehcekdId) {
      alert("파트너를 선택해 주세요.");
      return;
    }
    const partner = partnerList.find(
      (item: any) => item.seller_table_id === cehcekdId
    );

    submit(partner);
    close();
  };

  const col = [
    {
      key: "select",
      title: "선택",
      render: (item: any) => (
        <>
          <input
            type="radio"
            id={`${item.seller_table_id}`}
            className="radio"
            checked={cehcekdId === `${item.seller_table_id}`}
            onChange={(e) => setChecekdId(e.target.id)}
          />
          <label htmlFor={`${item.seller_table_id}`} />
        </>
      ),
    },
    { key: "seller_table_id", title: "파트너 ID" },
    { key: "seller_biz_name", title: "파트너명" },
    { key: "seller_phone", title: "전화번호" },
  ];

  return (
    <SubModal>
      <Container>
        <Header>
          <h3>파트너 찾기</h3>
          <button onClick={close}>
            <img src={images.exit} alt="exit_btn" />
          </button>
        </Header>
        <Content>
          <InputWithButton>
            <p>파트너ID</p>
            <div>
              <input
                type="text"
                value={partnerId}
                onChange={(e) => setPartnerId(e.target.value)}
                placeholder="파트너 ID를 입력해 주세요."
              />
              <button onClick={searchHandler}>찾기</button>
            </div>
          </InputWithButton>
          <Table columns={col} datas={partnerList} />
        </Content>
        <Footer>
          <button className="footer_modal exit" onClick={close}>
            취소
          </button>
          <button className="footer_modal submit" onClick={submitHandler}>
            확인
          </button>
        </Footer>
      </Container>
    </SubModal>
  );
};

export default FindPartnerModal;
