import React from 'react';
import Modal from '../../Common/ModalPortal';
import styled from 'styled-components';
import exit_btn from '../../../assets/images/exit.svg';
import useDetail from './detail.hooks';
import InputSelectArea from '../../InputSelectArea';
import Input from '../../Input';
import { numFormat } from '../../../utils/utils';

const Container = styled.div`
    width: 430px;
    min-height: 430px;
    background-color: #ffffff;
    margin: auto;
`;

const ModalHeader = styled.div`
    padding: 20px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e1e1e1;

    & > h3 {
        margin-top: 4px;
    }
`;

const ModalContent = styled.div`
    padding: 20px;
    border-bottom: 1px solid #e1e1e1;

    .total_price {
        margin-top: 25px;
        display: flex;
        justify-content: space-between;

        & > p {
            font-size: 18px;

            &:first-child {
                font-weight: bold;
            }
        }
    }
`;

const InfoWrap = styled.div`
    .life_order_reg_date {
        margin-bottom: 20px;
        font-size: 14px;
    }

    .input_box {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const ItemWrap = styled.div`
    border: 1px solid #e1e1e1;
    margin-top: 10px;
    padding: 10px;

    & > div {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0;
        }

        & > p {
            font-size: 14px;
            
            &:last-child {
                font-weight: bold;
            }
        }
    }
`;

const ModalFooter = styled.div`
    padding: 20px;
    text-align: right;

    & > button {
        background-color: #066e4d;
        color: #ffffff;
        width: 100%;
        height: 48px;
        font-size: 16px;
        font-weight: bold;
    }
`;

const DetailModal = (props) => {
    const { close, id } = props;
    const { item } = useDetail(id);

    return (
        item &&
        <Modal>
            <Container>
                <ModalHeader>
                    <h3>견적요청 보기 (번호: {item.life_order.life_order_table_id})</h3>
                    <button onClick={close}>
                        <img src={exit_btn} alt='exit_btn' />
                    </button>
                </ModalHeader>
                <ModalContent>
                    <InfoWrap>
                        <p className='life_order_reg_date'>견적 요청일: {item.life_order.life_order_reg_date}</p>
                        <InputSelectArea 
                            label='지역' 
                            value={item.life_order.life_order_area_1st} 
                            setValue={null}
                            area={item.life_order.life_order_area_2nd} 
                            setArea={null}
                            readOnly/>

                            <Input
                                className="input_text"
                                type="text"
                                value={item.life_order.life_order_phone}
                                setValue={ null }
                                label="휴대폰번호"
                                readOnly
                            />
                    </InfoWrap>
                </ModalContent>
                <ModalContent>
                    <p className='item_title'>시공항목</p>
                    <ItemWrap>
                        {item.life_order_items.map(item => 
                            <div key={item.life_order_item_id}>
                                <p>{`[${item.life_order_item_category === '0' ? '욕실' : item.life_order_item_category === '1' ? '주방' : '일반'}] ${item.life_order_item_title}`}</p>
                                <p>{numFormat(item.life_order_item_price)}원</p>
                            </div>
                        )}
                    </ItemWrap>
                    <div className='total_price'>
                        <p>총 견적금액</p>
                        <p>{numFormat(item.life_order.life_order_total_price)}원</p>
                    </div>
                </ModalContent>
                <ModalFooter>
                    <button onClick={close}>닫기</button>
                </ModalFooter>
            </Container>
        </Modal>
    );    
};

export default DetailModal;