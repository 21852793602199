import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import * as APIS from "../../utils/service";
import { numFormat, findJson } from "../../utils/utils";
import { open, close } from "../../redux/popupSlice";
import { openSub } from "../../redux/subPopupSlice";
import MemberShip from "../../components/MemberShip";
import consts from "../../libs/consts";

export default function PayDetail(props) {
  const { x, type } = props;
  const dispatch = useDispatch();
  const userData = useSelector((s) => s.usersReducer);

  const closeFunc = () => {
    dispatch(close());
    return;
  };

  const cencleAlert = () => {
    dispatch(
      openSub({
        message: "멤버십 구매를 취소하시겠습니까?",
        button: "확인",
        buttonCencle: "취소",
        onPress: () => cencleFunc(),
      })
    );
  };

  const cencleFunc = () => {
    const sender = {
      seller_table_id: userData.seller_table_id,
      payment_table_id: x.payment_table_id,
    };

    APIS.postData("/Membership/BuyCancel", sender, 1)
      .then((res) => {
        dispatch(close());
        setTimeout(() => {
          dispatch(
            open({
              message: "멤버십 구매가 취소 되었습니다.",
            })
          );
        }, 50);
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };

  const incomeFunc = () => {
    const sender = {
      seller_table_id: userData.seller_table_id,
      payment_table_id: x.payment_table_id,
    };

    APIS.postData("/Membership/IncomeChk", sender, 1)
      .then((res) => {
        dispatch(close());
        setTimeout(() => {
          dispatch(
            open({
              message: "입금확인요청 되었습니다.",
            })
          );
        }, 50);
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };

  return (
    <div className="popup_list">
      <div className="popup_list_ul popup_instance_step popup_estimate_step">
        {type === 1 ? (
          <div className="popup_estimate_section">
            <p className="title">
              {x.payment_type === "2" ? "정액제 " : "정량제 "}
              {x.payment_type === "2" && (
                <MemberShip type={x.grade} title={true} />
              )}
            </p>
            <ul className="list_style4">
              <li>
                <p className="title">구매상태</p>
                <p className="content">
                  {findJson(consts.payStateOption, x.payment_state)}
                  {x.payment_state * 1 < 3 && (
                    <button
                      type="button"
                      className="warning"
                      onClick={() => cencleAlert()}
                    >
                      주문취소
                    </button>
                  )}
                </p>
              </li>
              <li>
                <p className="title">횟수</p>
                {x.payment_type === "2" ? (
                  <p className="content">
                    {x.period}
                    {findJson(consts.day_option_view, x.period_type)}동안{" "}
                    {numFormat(x.cnt)}회 제공
                  </p>
                ) : (
                  <p className="content">{numFormat(x.cnt)}회 제공</p>
                )}
              </li>
              {x.payment_type === "2" && (
                <li>
                  <p className="title">시작일시</p>
                  <p className="content">
                    {x.start_date
                      ? moment(x.start_date).format("YYYY.MM.DD")
                      : "-"}
                  </p>
                </li>
              )}
              <li>
                <p className="title">사용기간</p>
                <p className="content">{x.period_date}</p>
              </li>
              <li>
                <p className="title">주의사항</p>
                {x.payment_type === "2" ? (
                  <p className="content">
                    {x.period}
                    {findJson(consts.day_option_view, x.period_type)}동안 미사용
                    한 횟수는 이월되지 않습니다.
                  </p>
                ) : (
                  <p className="content">
                    사용기간동안 미사용 한 횟수는 이월되지 않습니다.
                  </p>
                )}
              </li>
            </ul>
          </div>
        ) : (
          <div className="popup_estimate_section">
            <p className="title">{"계약정보"}</p>
            <ul className="list_style4">
              <li>
                <p className="title">구매상태</p>
                <p className="content">
                  {findJson(consts.payStateOption, x.state)}
                </p>
              </li>
              <li>
                <p className="title">계약번호</p>
                <p className="content link">{x.cont_table_id}</p>
              </li>
              <li>
                <p className="title">견적유형</p>
                <p className="content">
                  {findJson(consts.sellerOrderType, x.type)}
                </p>
              </li>
              <li>
                <p className="title">계약금액</p>
                <p className="content">{numFormat(x.price)}만원</p>
              </li>
              <li>
                <p className="title">수수료율</p>
                <p className="content">{x.pay_per}%</p>
              </li>
              <li>
                <p className="title">수수료</p>
                <p className="content">{numFormat(x.pay)}만원</p>
              </li>
            </ul>
          </div>
        )}

        <div className="popup_estimate_section">
          <p className="title">{"입금정보"}</p>
          <ul className="list_style4">
            <li>
              <p className="title">입금금액</p>
              <p className="content">
                {numFormat(type === 1 ? x.price : x.pay)}만원
              </p>
            </li>
            <li>
              <p className="title">입금계좌</p>
              <p className="content">
                하나은행 374-910029-16904 (계좌주 : 두꺼비세상)
              </p>
            </li>
          </ul>
          <p className="step3_msg" style={{ marginTop: "10px" }}>
            *영업일 10시~17시에 입금 확인 후 일괄 반영됩니다.
            <br />
            (ex. 금요일 18시에 입금 하신 경우 월요일 10시에 반영)
          </p>
        </div>
      </div>

      <div className="popup_list_btn_box popup_pay_step">
        <button type="button" className="btn btn3" onClick={() => closeFunc()}>
          취소
        </button>
        {x.payment_state === "1" && (
          <button
            type="button"
            className="btn btn1"
            onClick={() => incomeFunc()}
          >
            입금확인요청
          </button>
        )}
      </div>
    </div>
  );
}
