import React from "react";
import moment from "moment";
import Table from "../../../components/Common/Table/Table";
import { css } from "@emotion/react";

export default function LogsAlarmTalk({ list }) {
  const alarmType = [
    "파트너 견적 할당",
    "사용자 견적 할당",
    "파트너 생성",
    "핸드폰 인증",
    "비밀번호 인증",
    "멤버쉽 구매",
    "입금 완료",
    "미팅일자 요청",
    "이벤트",
  ];
  const targets = ["관리자", "파트너", "사용자"];
  const alarmState = ["미확인", "전송됨"];

  const col = [
    { key: "talk_log_id", title: "로그 번호" },
    {
      key: "talk_log_type",
      title: "알림톡 유형",
      render: (x) => <span>{alarmType[x.talk_log_type]}</span>,
    },
    {
      key: "talk_log_target",
      title: "대상",
      render: (x) => <span>{targets[x.talk_log_target]}</span>,
    },
    { key: "talk_log_request_id", title: "알림톡 요청번호" },
    { key: "talk_log_phone", title: "전화번호" },
    {
      key: "talk_log_state",
      title: "진행상태",
      render: (x) => <span>{alarmState[x.talk_log_state]}</span>,
    },
    {
      key: "reg_date",
      title: "발송일시",
      render: (x) => <span>{moment(x.reg_date).format("YYYY.MM.DD")}</span>,
    },
  ];

  return (
    <div
      css={css`
        margin: 30px 0 20px 0;
      `}
    >
      <Table columns={col} datas={list} />
    </div>
  );
}
