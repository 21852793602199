/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import Modal from "../../../Common/ModalPortal";
import images from "../../../../libs/images";
import * as Colors from "../../../../constants/color";
import Button from "../../../Common/Button/Button";
import {
  stepStyles,
  stepTitleStyles,
  labelStyles,
  marginTopStyles,
} from "../../notice.style";

import { InfoType } from "../noticeUrl.type";
import useNoticeUrl from "../noticeUrl.hook";
import InputWithButton from "../../../Common/Input/InputWithButton";
import FindPartner, { Partner } from "../../Event/FindPartner";
import Input from "../../../Common/Input/Input";
import Upload, { FileData } from "../../../Common/Upload/Upload";
import Checkbox from "../../../Common/Checkbox/Checkbox";

const Container = styled.div`
  width: 560px;
  background-color: #ffffff;
  margin: auto;
`;

const Header = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${Colors.gray_200};

  & > h3 {
    margin: auto 0;
  }
`;

const Content = styled.div`
  padding: 20px;
  max-height: 550px;
  overflow-y: auto;
`;

const Footer = styled.div`
  padding: 20px;
  border-top: 1px solid ${Colors.gray_200};
  display: flex;
  justify-content: center;
  gap: 15px;
`;

interface Props {
  close: () => void;
  callback: () => void;
}

const CreateNoticeUrl = (props: Props) => {
  const { close, callback } = props;
  const { create } = useNoticeUrl();
  const [isFindPartner, setIsFindPartner] = useState(false);

  const [infoStatus, setInfoStatus] = useState<"event" | "contents">("event");
  const defaultData = {
    seller_biz_name: "",
    seller_table_id: "",
    url_title: "",
    form_types: [
      { id: 1, name: "이름", isChecked: false },
      { id: 2, name: "연락처", isChecked: false },
      { id: 3, name: "평형", isChecked: false },
      { id: 4, name: "공사 주소", isChecked: false },
      { id: 5, name: "공사 희망일", isChecked: false },
      { id: 6, name: "요청 사항", isChecked: false },
    ],
    detail_page: "",
    btn_yn: 0,
    btn_name: "",
  };

  const [infoData, setInfoData] = useState<InfoType>(defaultData);

  useEffect(() => {
    setInfoData({
      ...defaultData,
    });
  }, [infoStatus]);

  const createHandler = async () => {
    const url_type = infoStatus === "event" ? "1" : "2";
    const params = {
      url_type,
      ...infoData,
      form_types: infoData.form_types
        .filter((item) => item.isChecked)
        .map((item) => String(item.id)),
    };

    const res = await create(params);

    if (typeof res === "boolean") {
      if (res) {
        callback();
      } else {
        alert("등록중 오류가 발생하였습니다.");
      }
    }
  };

  const submitPartner = (partner: Partner) => {
    setInfoData((prevData) => ({
      ...prevData,
      seller_table_id: partner.seller_table_id,
      seller_biz_name: partner.seller_biz_name,
    }));
  };

  const changeInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setInfoData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const changeFileHandler = (file: FileData) => {
    setInfoData((prevData) => ({
      ...prevData,
      detail_page: file.fileImage,
    }));
  };

  const changeCheckboxHandler = (
    type: "btn_yn" | "form_types",
    id?: number
  ) => {
    setInfoData((prevData) => {
      if (type === "btn_yn") {
        return {
          ...prevData,
          btn_yn: prevData.btn_yn === 1 ? 0 : 1,
        };
      } else {
        return {
          ...prevData,
          form_types: prevData.form_types.map((i) =>
            i.id === id ? { ...i, isChecked: !i.isChecked } : i
          ),
        };
      }
    });
  };

  return (
    <Modal>
      <Container>
        <Header>
          <h3>URL 생성</h3>
          <button onClick={close}>
            <img src={images.exit} alt="exit_btn" />
          </button>
        </Header>
        <Content>
          <div>
            <div
              css={css`
                display: flex;
              `}
            >
              <p css={stepStyles}>1</p>
              <h3 css={stepTitleStyles}>기본 정보</h3>
            </div>
            <div css={[marginTopStyles]}>
              <p css={[labelStyles]}>* 구분</p>
              <div
                css={css`
                  margin-top: 12px;
                  display: flex;
                  gap: 40px;
                `}
              >
                <div>
                  <input
                    type="radio"
                    className="radio"
                    id="event"
                    value="event"
                    onChange={(e) => setInfoStatus(e.target.value as "event")}
                    checked={infoStatus === "event"}
                  />
                  <label htmlFor="event">이벤트</label>
                </div>
                <div>
                  <input
                    type="radio"
                    className="radio"
                    id="contents"
                    value="contents"
                    onChange={(e) =>
                      setInfoStatus(e.target.value as "contents")
                    }
                    checked={infoStatus === "contents"}
                  />
                  <label htmlFor="contents">컨텐츠</label>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div css={[marginTopStyles]}>
              <InputWithButton
                label="* 파트너 명"
                buttonText="검색"
                onClick={() => setIsFindPartner(true)}
                value={infoData.seller_biz_name}
                disabled
              />
            </div>
            <div css={[marginTopStyles]}>
              <Input
                label="* 타이틀 명"
                placeholder="타이틀 명을 입력해주세요."
                name="url_title"
                onChange={changeInputHandler}
              />
            </div>
            <div css={[marginTopStyles]}>
              <Upload
                label="* 상세 페이지 등록"
                id="upload_detail"
                callback={changeFileHandler}
              />
            </div>
            <div
              css={[
                css`
                  margin-top: 30px;
                  display: flex;
                `,
              ]}
            >
              <p css={stepStyles}>2</p>
              <h3 css={stepTitleStyles}>선택 정보</h3>
            </div>
            <div css={[marginTopStyles]}>
              <Checkbox
                labelText="버튼(신청폼) 사용"
                value={infoData.btn_yn === 1}
                onChange={() => changeCheckboxHandler("btn_yn")}
              />
            </div>
            {infoData.btn_yn === 1 && (
              <>
                <div css={[marginTopStyles]}>
                  <Input
                    label="* 버튼 명 (최대 10자)"
                    placeholder="버튼명을 입력해주세요."
                    size="full"
                    name="btn_name"
                    onChange={changeInputHandler}
                  />
                </div>
              </>
            )}
            <div css={[marginTopStyles]}>
              <p css={[labelStyles]}>* 신청폼 항목</p>
              <div
                css={css`
                  margin-top: 12px;
                  display: flex;
                  flex-wrap: wrap;
                  gap: 20px 40px;
                `}
              >
                {infoData.form_types.map((item) => (
                  <React.Fragment key={item.id}>
                    <Checkbox
                      labelText={item.name}
                      value={item.isChecked}
                      onChange={() =>
                        changeCheckboxHandler("form_types", item.id)
                      }
                    />
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </Content>
        <Footer>
          <Button theme="tertiary" onClick={close}>
            취소
          </Button>
          <Button onClick={createHandler}>등록</Button>
        </Footer>
      </Container>
      {isFindPartner && (
        <FindPartner
          close={() => setIsFindPartner(false)}
          submit={submitPartner}
        />
      )}
    </Modal>
  );
};

export default CreateNoticeUrl;
