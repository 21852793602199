import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import Input from "../../../components/Input";
import TextArea from "../../../components/TextArea";
import styled from "styled-components";
import TabTitle from "../TabTitle";
import PlaceAdd from "./PlaceAdd";
import { openSub } from "../../../redux/subPopupSlice";
import { loadingEnd, loadingStart } from "../../../redux/loadingSlice";
import * as APIS from "../../../utils/service";
import { useDispatch } from "react-redux";
import * as Colors from "../../../constants/color";
import Select from "../../../components/Common/Select/Select";
import Checkbox from "../../../components/Common/Checkbox/Checkbox";
import Preview from "../../../components/Preview";

const ReceiptInfoWrap = styled.div`
  margin-top: 24px;
  width: 50%;

  & > div {
    margin-top: 6px;
    height: 48px;

    input[type="text"],
    button {
      height: 100%;
      border: 1px solid #e4e9f2;
    }

    input[type="text"] {
      padding: 0 15px;
      width: 70%;
    }

    button {
      width: 30%;
    }
  }
`;
export default function PriceInfo(props) {
  const dispatch = useDispatch();
  const {
    data,
    setData,
    caseTableId,
    userData,
    seller,
    receiptDeleteIds,
    tabNum,
    caseReceipt,
    setCaseReceipt,
    openReceiptModal,
    setTabNum,
  } = props;
  const [caseAll, setCaseAll] = useState(
    data && data.detail_list && data.detail_list.length > 0
      ? data.detail_list
      : [
          {
            case_tag: "",
            case_tag_text: "",
            case_desc: "",
            photoList: [],
            case_img_before: "",
            case_img_after: "",
            case_img_check: 0,
            imgs: [],
          },
        ]
  );
  /*시공사례 -> 공간*/
  const [caseZone, setCaseZone] = useState(
    data && data.case_parts && data.case_parts.length > 0
      ? data.case_parts
      : [
          {
            case_parts_type: "1",
            case_tag: "",
            case_tag_text: "",
            case_desc: "",
            photoList: [],
            case_img_before: "",
            case_img_after: "",
            case_img_check: 0,
            imgs: [],
          },
        ]
  );
  const [deleteIds, setDeleteIds] = useState([]);
  const [caseZoneDeleteIds, setCaseZoneDeleteIds] = useState([]);
  const [totalPrice, setTotalPrice] = useState(
    data.case_info && data.case_info.case_work_price
      ? data.case_info.case_work_price
      : 0
  );

  const [desc, setDesc] = useState(
    data.case_info && data.case_info.case_desc ? data.case_info.case_desc : ""
  );

  const [sellerClassic, setSellerClassic] = useState(
    data?.case_info?.seller_classic === "1"
  );

  const [interiorStyle, setInteriorStyle] = useState(
    data?.case_info?.interior_style
  );
  /*가격 및 정보 에러*/

  const [totalPriceError, setTotalPriceError] = useState("");
  const [conceptError, setConceptError] = useState("");

  /*총 시공가격 반영*/
  useEffect(() => {
    let totalPriceDisplay = 0;
    let lengthOne = false;
    let firstPrice = "";
    let casePriceArr = caseReceipt.map((item, index) => {
      return item.case_price;
    });
    if (casePriceArr.length === 1) {
      lengthOne = true;
      firstPrice = casePriceArr[0];
    }
    if (lengthOne && typeof firstPrice === "undefined") {
      return;
    }
    if (lengthOne && firstPrice !== "" && firstPrice !== "0") {
      setTotalPrice(firstPrice);
    }
    if (casePriceArr.length > 1) {
      for (let price of casePriceArr) {
        totalPriceDisplay += price ? price.replace(/,/g, "") * 1 : 0;
      }
      setTotalPrice(totalPriceDisplay);
    }
  }, [caseReceipt]);
  const caseAppend = (caseType) => {
    if (caseType === 3) {
      setCaseAll([
        ...caseAll,
        {
          case_tag: "",
          case_tag_text: "",
          case_desc: "",
          photoList: [],
          case_img_before: "",
          case_img_after: "",
          case_img_check: 0,
          imgs: [],
        },
      ]);
    } else if (caseType === 2) {
      setCaseReceipt([
        ...caseReceipt,
        { case_parts_no: "1", case_price: "", case_desc: "" },
      ]);
    } else {
      setCaseZone([
        ...caseZone,
        {
          case_parts_type: "1",
          case_tag: "",
          case_tag_text: "",
          case_desc: "",
          photoList: [],
          case_img_before: "",
          case_img_after: "",
          case_img_check: 0,
          imgs: [],
        },
      ]);
    }
  };
  const wrongAccessCheck = () => {
    if (!caseTableId) {
      dispatch(
        openSub({
          message: "잘못된 접근입니다.",
          button: "확인",
        })
      );
      return true;
    }
  };
  const wrongWriteCheck = () => {
    /* 시공 가격 + 시공 컨셉 미작성시 에러 표시 */
    let price_desc_exist = true;
    if (totalPrice === "0" || !totalPrice) {
      setTotalPriceError("총 시공가격을 입력해 주세요.");
      price_desc_exist = false;
    }
    if (desc === "" || !desc) {
      setConceptError("시공컨셉을 입력해 주세요.");
      price_desc_exist = false;
    }
    if (!price_desc_exist) {
      dispatch(
        openSub({
          message: "모든 정보를 입력 후 다음 단계로 이동해주시기 바랍니다.",
          messageAuto: true,
          button: "확인",
        })
      );
      return true;
    }
  };

  const imgCheck = (list, msg) => {
    let cnt = 0;
    list.forEach((x) => {
      if (x.case_img_check === "1") {
        if (x.case_img_before === "" || x.case_img_after === "") {
          cnt++;
        }
      } else {
        if (x.imgs.length < 1) {
          cnt++;
        }
      }
    });

    if (cnt > 0) {
      dispatch(
        openSub({
          message: msg,
          button: "확인",
        })
      );
      cnt = 0;
      return true;
    }
    return false;
  };

  const listCountCheck = (list, count, msg) => {
    /*상세사진 카운트 5개 미만일시 에러 표시*/

    if (list.length < count) {
      dispatch(
        openSub({
          message: msg,
          messageAuto: true,
          button: "확인",
        })
      );
      return true;
    }
    return false;
  };

  const errResponse = () => {
    dispatch(
      openSub({
        message: "잠시 후 다시 시도해주세요.",
        button: "확인",
      })
    );
    return;
  };

  const detailOrPlaceChange = async () => {
    try {
      const caseReceiptTotal = caseReceipt.reduce(
        (acc, cur) => acc + Number(cur.case_price),
        0
      );

      const params = {
        admin_table_id: userData.admin_table_id,
        seller_table_id:
          userData.grade === 10 ? seller : userData.seller_table_id,
        case_table_id: caseTableId,
        case_desc: desc,
        seller_classic: sellerClassic ? "1" : "0",
        interior_style: interiorStyle,
        case_parts: caseZone,
        case_delete_ids: caseZoneDeleteIds,
        cz_delete_ids: deleteIds,
        zone_parts: caseReceipt.filter((part) => part.case_price),
        zone_delete_ids: receiptDeleteIds,
        case_work_price: caseReceiptTotal > 0 ? caseReceiptTotal : totalPrice,
      };

      const url = userData.admin_table_id
        ? "/Admin/CaseCreate2nd"
        : "/WorkCase/CaseCreate2nd";

      dispatch(loadingStart());
      const res = await APIS.postData(url, params, 1);

      if (res.data.code === "OK") {
        setTabNum(3);
      } else {
        errResponse();
      }
    } catch (err) {
      alert("오류가 발생하였습니다.");
    } finally {
      dispatch(loadingEnd());
    }
  };

  const stepChange = () => {
    /*오류 체크, 상세 사진 or 공간별 상세 정보 선택 후 다음*/
    const infoErrorCheck = wrongAccessCheck() || wrongWriteCheck();
    if (infoErrorCheck) {
      return;
    }

    if (interiorStyle == 0) {
      dispatch(
        openSub({
          message: "인테리어 스타일을 선택 바랍니다.",
          messageAuto: true,
          button: "확인",
        })
      );
      return false;
    }

    /* jdk 
    const placeErrorCheck =
      imgCheck(caseZone, "공간별 사진을 업로드해 주세요.") ||
      listCountCheck(
        caseZone,
        5,
        "공간별 정보를 5개이상 등록 후 다음 단계로 이동해주시기 바랍니다."
      );
    if (placeErrorCheck) {
      return;
    }*/
    detailOrPlaceChange();
  };

  const caseOneDelete = (i, id, list, setList, setDeleteIds, deleteCaseIds) => {
    let newFormValues = [...list];
    newFormValues.splice(i, 1);
    setList(newFormValues);
    if (id) setDeleteIds([...deleteCaseIds, id]);
  };

  const BeforeAfterPicDel = (i, beforeAfter, detailPlace, list, setList) => {
    let beforeState = beforeAfter === "before";
    let afterState = beforeAfter === "after";
    let detailState = detailPlace === "detail";
    let placeState = detailPlace === "place";
    let newList = [...list];
    if (beforeState) {
      list[i].case_img_before = "";
      if (detailState) {
        list[i].case_detail_img = "";
      }
      if (placeState) {
        list[i].case_zone_img = "";
      }
    }
    if (afterState) {
      list[i].case_img_after = "";
      if (detailState) {
        list[i].case_detail_img_2 = "";
      }
      if (placeState) {
        list[i].case_zone_img_2 = "";
      }
    }
    setList(newList);
  };

  return (
    <>
      <div className="popup_list_ul popup_instance_step">
        <TabTitle num={tabNum} color={Colors.error} />
        <div className="instance_form_list">
          <Input
            className="input_text"
            type="tel"
            placeholder="숫자만 입력해 주세요."
            name="totalPrice"
            valid={"totalPrice"}
            maxlength={11}
            value={totalPrice}
            setValue={setTotalPrice}
            label="*총 시공가격"
            withText={"만원"}
            error={totalPriceError}
            setError={setTotalPriceError}
            msg={"영수증 정보에 입력 한 가격이 합산하여 표시됩니다."}
          />
          <ReceiptInfoWrap>
            <label>영수증 정보</label>
            <div>
              <input
                type="text"
                disabled
                placeholder={
                  caseReceipt.filter((item) => item.case_price).length > 0
                    ? "영수증 등록완료"
                    : "영수증 정보입력을 클릭해주세요."
                }
                value=""
              />
              <button onClick={openReceiptModal}>영수증 정보입력</button>
            </div>
          </ReceiptInfoWrap>
          <div
            css={css`
              margin-top: 20px;
              display: flex;
              gap: 20px;
              width: 100%;
            `}
          >
            <Select
              size="small"
              label="* 인테리어 스타일"
              defaultValue="선택"
              options={[
                { label: "우드&화이트", value: 1 },
                { label: "모던", value: 2 },
                { label: "젠스타일", value: 3 },
                { label: "모던미드센츄리", value: 4 },
                { label: "내츄럴", value: 5 },
                { label: "클래식", value: 6 },
                { label: "북유럽", value: 7 },
                { label: "기타", value: 8 },
              ]}
              value={interiorStyle}
              onChange={(e) => setInteriorStyle(e.target.value)}
            />
            <div
              css={css`
                width: 55%;
                .input_box {
                  margin-top: 0 !important;
                }
              `}
            >
              <TextArea
                className="input_textarea"
                placeholder="예시 1) 4인가족이 사는 행복한 32평 아파트 &#13;&#10;예시 2) 세 식구의 도심 속 작은 숲"
                name="desc"
                value={desc}
                setValue={setDesc}
                label="*시공컨셉"
                error={conceptError}
                setError={setConceptError}
                maxlength={17}
              />
            </div>
            <div>
              <p
                css={css`
                  font-size: 12px;
                  font-weight: bold;
                  line-height: 1.5;
                  color: ${Colors.blue_600};
                `}
              >
                * 대표 시공 사례 설정
              </p>
              <div
                css={css`
                  margin-top: 15px;
                `}
              >
                <Checkbox
                  labelText="네. 대표로 노출 할게요."
                  value={sellerClassic}
                  onChange={() => {
                    setSellerClassic(!sellerClassic);
                  }}
                />
                <p
                  css={css`
                    display: flex;
                    margin-top: 15px;
                  `}
                >
                  어디에 노출되나요
                  <p
                    css={css`
                      cursor: pointer;
                      margin-left: 10px;
                      border: 1px solid;
                      width: 18px;
                      height: 18px;
                      line-height: 18px;
                      text-align: center;
                      border-radius: 50%;
                      font-size: 12px;
                      position: relative;

                      & > div {
                        display: none;
                        left: -130px;
                      }

                      &:hover {
                        & > div {
                          display: block;
                        }
                      }
                    `}
                  >
                    ?
                    <Preview />
                  </p>
                </p>
              </div>
            </div>
          </div>

          <PlaceAdd
            data={data}
            setData={setData}
            userData={userData}
            seller={seller}
            caseTableId={caseTableId}
            caseZone={caseZone}
            setCaseZone={setCaseZone}
            caseAppend={caseAppend}
            caseOneDelete={caseOneDelete}
            deleteIds={deleteIds}
            setDeleteIds={setDeleteIds}
            caseZoneDeleteIds={caseZoneDeleteIds}
            setCaseZoneDeleteIds={setCaseZoneDeleteIds}
            BeforeAfterPicDel={BeforeAfterPicDel}
          />
        </div>
      </div>
      <div className="popup_list_btn_box popup_pay_step">
        <button type="button" className="btn btn3" onClick={() => setTabNum(1)}>
          이전
        </button>
        <button type="button" className="btn btn1" onClick={() => stepChange()}>
          다음
        </button>
      </div>
    </>
  );
}
