import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import * as APIS from "../../utils/service";
import { open, close } from "../../redux/popupSlice";
import Input from "../../components/Input";
import InputSelect from "../../components/InputSelect";
import TextArea from "../../components/TextArea";
import consts from "../../libs/consts";

export default function EventAdd(props) {
  const { x, w, e } = props;
  const dispatch = useDispatch();

  const [state, setState] = useState(x.event_state ? x.event_state : 1);
  const [estimate_msg, setEstimate_msg] = useState(
    x.event_description ? x.event_description : ""
  );

  const sendData = () => {
    const urls = "/EventAp/ApManage";
    const types = 1;
    const sender = {
      ev_table_id: x.ev_table_id,
      event_state: state,
      event_description: estimate_msg,
    };

    APIS.postData(urls, sender, types)
      .then(() => {
        setTimeout(() => {
          dispatch(
            open({
              message: "저장되었습니다.",
            })
          );
        }, 50);
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };

  useEffect(() => {}, [state, estimate_msg]);

  return (
    <form className="popup_form">
      <div className="admin_pay_form_list">
        {e === 1 ? <></> : <></>}
        <Input
          className="input_text"
          type="text"
          placeholder="내용을 입력해 주세요."
          name="id"
          value={x.ev_table_id}
          label="요청번호"
          withButton={w === "u" ? false : "확인"}
          readOnly={w === "u"}
        />
        {e === 1 && (
          <>
            <Input
              className="input_text"
              type="text"
              placeholder="내용을 입력해 주세요."
              name="recId"
              value={x.city + " " + x.area}
              label="시공지역"
              withButton={w === "u" ? false : "확인"}
              readOnly={w === "u"}
            />
            <Input
              className="input_text"
              type="text"
              placeholder="내용을 입력해 주세요."
              name="recId"
              value={x.size}
              label="평형"
              withButton={w === "u" ? false : "확인"}
              readOnly={w === "u"}
            />
            <Input
              className="input_text"
              type="text"
              placeholder="내용을 입력해 주세요."
              name="recId"
              value={x.construction_type}
              label="시공유형"
              withButton={w === "u" ? false : "확인"}
              readOnly={w === "u"}
            />
            <Input
              className="input_text"
              type="text"
              placeholder="내용을 입력해 주세요."
              name="recId"
              value={
                x.pad.map((x) => x.model_name).join(" / ") +
                " " +
                x.paper.map((x) => x.model_name).join(" / ")
              }
              label="제품명"
              withButton={w === "u" ? false : "확인"}
              readOnly={w === "u"}
            />
            <Input
              className="input_text"
              type="text"
              placeholder="내용을 입력해 주세요."
              name="recId"
              value={x.desired_price + " 만원"}
              label="예상견적가"
              withButton={w === "u" ? false : "확인"}
              readOnly={w === "u"}
            />
          </>
        )}
        {e === 2 && (
          <>
            <Input
              className="input_text"
              type="text"
              placeholder="내용을 입력해 주세요."
              name="recId"
              value={x.apart_name}
              label="시공지역"
              withButton={w === "u" ? false : "확인"}
              readOnly={w === "u"}
            />
            <Input
              className="input_text"
              type="text"
              placeholder="내용을 입력해 주세요."
              name="recId"
              value={`${x.dong}동 ${x.hosu}호`}
              label="평형"
              withButton={w === "u" ? false : "확인"}
              readOnly={w === "u"}
            />
            <Input
              className="input_text"
              type="text"
              placeholder="내용을 입력해 주세요."
              name="recId"
              value={x.size}
              label="시공유형"
              withButton={w === "u" ? false : "확인"}
              readOnly={w === "u"}
            />
          </>
        )}
        {e === 3 && (
          <>
            <Input
              className="input_text"
              type="text"
              placeholder="내용을 입력해 주세요."
              name="recId"
              value={`${x.city} ${x.area}`}
              label="시공지역"
              withButton={w === "u" ? false : "확인"}
              readOnly={w === "u"}
            />
            <Input
              className="input_text"
              type="text"
              placeholder="내용을 입력해 주세요."
              name="recId"
              value={x.size}
              label="시공유형"
              withButton={w === "u" ? false : "확인"}
              readOnly={w === "u"}
            />
            <Input
              className="input_text"
              type="text"
              placeholder="내용을 입력해 주세요."
              name="recId"
              value={x.desired_price}
              label="평형"
              withButton={w === "u" ? false : "확인"}
              readOnly={w === "u"}
            />
            <Input
              className="input_text"
              type="text"
              placeholder="내용을 입력해 주세요."
              name="recId"
              value={x.demolition_yn}
              label="평형"
              withButton={w === "u" ? false : "확인"}
              readOnly={w === "u"}
            />
          </>
        )}
        <Input
          className="input_text"
          type="text"
          placeholder="내용을 입력해 주세요."
          name="recId"
          value={x.phone.replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")}
          label="연락처"
          withButton={w === "u" ? false : "확인"}
          readOnly={w === "u"}
        />
        <Input
          className="input_text"
          type="text"
          placeholder="내용을 입력해 주세요."
          name="recId"
          value={moment(x.reg_date).format("YYYY.MM.DD HH:mm")}
          label="요청일시"
          withButton={w === "u" ? false : "확인"}
          readOnly={w === "u"}
        />

        <InputSelect
          name="type"
          label="처리상태"
          value={state}
          setValue={setState}
          option={consts.event_process}
        />
        <TextArea
          className="input_textarea"
          placeholder="내용을 입력해 주세요. (500자 이내)"
          name="estimate_msg"
          value={estimate_msg}
          setValue={setEstimate_msg}
          label="요청내역"
        />
      </div>

      <div className="popup_btn">
        <button
          type="button"
          className="popbtn cencle_btn"
          onClick={() => {
            dispatch(close());
          }}
          style={{ width: "100%", marginRight: "10px" }}
        >
          취소
        </button>
        <button
          type="button"
          className="popbtn ok_btn"
          onClick={() => {
            sendData();
            dispatch(close());
          }}
          style={{ width: "100%" }}
        >
          {w === "i" ? "확인" : "수정"}
        </button>
      </div>
    </form>
  );
}
