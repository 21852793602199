import React, { useState, useEffect } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import Modal from "../../../Common/ModalPortal";
import images from "../../../../libs/images";
import Button from "../../../Common/Button/Button";
import * as Colors from "../../../../constants/color";
import Input from "../../../Common/Input/Input";
import Select from "../../../Common/Select/Select";
import Upload, { FileData } from "../../../Common/Upload/Upload";
import { handlerImgError } from "../../../../utils/home-manage";
import consts from "../../../../libs/consts";
import useHomeManageLifeMonthly, { List } from "../homeManageLifeMonthly.hook";

const Container = styled.div`
  width: 560px;
  background-color: #ffffff;
  margin: auto;
`;

const Header = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${Colors.gray_200};

  & > h3 {
    margin: auto 0;
  }
`;

const Content = styled.div`
  padding: 10px 20px 30px 20px;
`;

const Footer = styled.div`
  padding: 20px;
  border-top: 1px solid ${Colors.gray_200};
  display: flex;
  justify-content: center;
  gap: 15px;
`;

interface Props {
  close: () => void;
  callback: () => void;
  id: string;
}

const UpdateHomeManageLifeMonthly = (props: Props) => {
  const { close, callback, id } = props;

  const { detail, update } = useHomeManageLifeMonthly({
    id,
  });

  const [data, setData] = useState<Omit<List, "update_date">>({
    monthly_table_id: "",
    title: "",
    image: "",
    isApplication: "",
    url: "",
  });

  useEffect(() => {
    if (detail) {
      setData({ ...detail });
    }
  }, [detail]);

  const deletePreviewHandler = () => {
    setData((prevData) => ({
      ...prevData,
      image: "",
    }));
  };

  const changeFileHandler = ({ fileImage }: FileData) => {
    setData((prevData) => ({
      ...prevData,
      image: fileImage,
    }));
  };

  const changeDataHandler = (name: string, value: string) => {
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const updateHandler = async (): Promise<void> => {
    const {
      isApplication,
      title,
      image,
      url,
    } = data;

    const params = {
      monthly_table_id: id,
      title,
      isApplication,
      image,
      url,
    };

    const res = await update(params);

    if (res) {
      callback();
    }
  };

  if (!data) {
    return <></>;
  }

  return (
    <Modal>
      <Container>
        <Header>
          <h3>이달의 공구 변경</h3>
          <button onClick={close}>
            <img src={images.exit} alt="exit_btn" />
          </button>
        </Header>
        <Content>
          <Input
            label="* 타이틀 명"
            placeholder="타이틀 작성"
            value={data.title}
            onChange={(e) => changeDataHandler('title', e.target.value)}
          />
        </Content>
        <Content>
          <div
            css={css`
            width: 130px;
          `}
          >
            <Select
              label="구분"
              value={data.isApplication}
              options={consts.life_monthly_application_options.map((item) => ({
                value: item.idx,
                label: item.title,
              }))}
              size="full"
              onChange={(e) => changeDataHandler("isApplication", e.target.value)}
            />
          </div>
        </Content>
        <Content>
          <Input
            value={data.url}
            label="* URL 예시)/event/11"
            onChange={e => changeDataHandler('url', e.target.value)}
          />
        </Content>
        <Content>
          <Upload
            id="upload"
            label="* 이미지 등록 (gif 등록 불가)"
            onError={handlerImgError}
            preview={data.image || ""}
            deletePreivew={deletePreviewHandler}
            callback={(file) => changeFileHandler(file)}
          />
        </Content>
        <Footer>
          <Button theme="tertiary" onClick={close}>
            취소
          </Button>
          <Button onClick={updateHandler}>변경</Button>
        </Footer>
      </Container>
    </Modal>
  );
};

export default UpdateHomeManageLifeMonthly;
