import React from "react";
import ReactDom from "react-dom";

import styled from "styled-components";

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 40000;
  display: flex;
  background-color: rgb(144, 144, 144, 0.8);
`;

const ModalPortal = (props) => {
  const { children } = props;

  const el = document.getElementById("sub-modal");

  return ReactDom.createPortal(children, el);
};

const SubModal = (props) => {
  const { children } = props;

  return (
    <ModalPortal>
      <ModalContainer>{children}</ModalContainer>
    </ModalPortal>
  );
};
export default SubModal;
