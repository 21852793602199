import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import routes from "../../../libs/routes";
import { open, close } from "../../../redux/popupSlice";
import { logOut } from "../../../redux/usersSlice";

import images from "../../../libs/images";

const PartnerHeader = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const userData = useSelector((s: any) => s.usersReducer);

  const logOutFunc = () => {
    dispatch(
      open({
        message: "로그아웃 하시겠습니까?",
        button: "확인",
        buttonCencle: "취소",
        onCancelPress: dispatch(close()),
        onPress: () => {
          dispatch(logOut());
          navigate(routes.login);
        },
      })
    );
  };

  return (
    <div className="topBar">
      <div className="topBarPc">
        <Link to={routes.my} className="profile_info">
          <img
            className="profile"
            src={1 ? images.profile : userData.mbProfile}
            alt="프로필"
          />
          <p className="profile_name">{userData.seller_biz_name}</p>
        </Link>

        <button
          type="button"
          className="btn_s btn_s1"
          onClick={() => logOutFunc()}
        >
          로그아웃
        </button>
      </div>
    </div>
  );
};

export default PartnerHeader;
