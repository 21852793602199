import React from "react";
import { css } from "@emotion/react";
import { getFileName } from "../../../utils/utils";
import InputSelect from "../../../components/InputSelect";
import TextArea from "../../../components/TextArea";
import consts from "../../../libs/consts";
import { openSub } from "../../../redux/subPopupSlice";
import Files from "../../../components/Files";
import { useDispatch } from "react-redux";
import * as APIS from "../../../utils/service";
import { loadingEnd, loadingStart } from "../../../redux/loadingSlice";
import * as Colors from "../../../constants/color";
import images from "../../../libs/images";
import Upload from "../../../components/Common/Upload/Upload";
import imageCompression from "browser-image-compression";

const labelStyles = css`
  display: block;
  width: 100%;
  height: 100%;
  border: 1px solid ${Colors.gray_200};
  border-radius: 8px;
  display: flex;
  cursor: pointer;

  & > img {
    margin: auto;
  }
`;

export default function PlaceAdd(props) {
  const dispatch = useDispatch();
  const {
    seller,
    caseTableId,
    setData,
    caseAppend,
    caseZone,
    setCaseZone,
    caseOneDelete,
    deleteIds,
    setDeleteIds,
    caseZoneDeleteIds,
    setCaseZoneDeleteIds,
  } = props;

  const caseSetValueZone = (i, e, val) => {
    /*공간별 상세 정보 값 변경시 적용*/
    const newFormValues = caseZone.map((item, index) => {
      if (index !== i) {
        return item;
      }
      return { ...item, [e.target.name]: val };
    });
    setCaseZone(newFormValues);
  };

  const caseSetPhotoValue = (i, e, ee, val, val2) => {
    const newFormValues = caseZone.map((item, index) => {
      if (index !== i) {
        return item;
      }
      return {
        ...item,
        case_img_before: "",
        case_img_after: "",
        [e.target.name]: val,
        [ee.target.name]: val2,
      };
    });
    setCaseZone(newFormValues);
  };

  const placePhoto = (value, valuePhoto, setValue, index, maxlength, msg) => {
    dispatch(
      openSub({
        title: "사진관리",
        component: (
          <Files
            value={value}
            valuePhoto={valuePhoto}
            onSearch={setValue}
            setValue={setValue}
            deleteList={deleteIds}
            setDeleteList={setDeleteIds}
            maxlength={maxlength}
            index={index}
            caseDeletePhoto={caseSetValueZone}
            name="photoList"
            imgName="imgs"
            valid="image"
            msg={msg ? msg : "시공사진은 공간별 10개 까지 등록 가능합니다."}
            maxSize={500 * 1024 * 1024}
          />
        ),
      })
    );
  };
  const photoPlaceCheck = (x, e, i) => {
    if (e.target.checked) {
      x.case_img_check = "1";
    } else {
      x.imgs = [];
      x.case_img_check = "0";
    }
    const newFormValues = caseZone.map((item, index) => {
      if (index !== i) {
        return item;
      }
      return {
        ...item,
        case_img_check: x.case_img_check,
        imgs: x.imgs,
      };
    });
    setCaseZone(newFormValues);
  };

  const placeInfoAdd = (placeInfo) => {
    dispatch(loadingStart());
    const sender = {
      seller_table_id: seller,
      case_table_id: caseTableId,
      case_desc: placeInfo.case_desc,
      case_parts_type: placeInfo.case_parts_type,
      case_img_table_id: placeInfo.case_img_table_id
        ? placeInfo.case_img_table_id
        : 0,
      case_tag: placeInfo.case_tag,
      case_tag_text: placeInfo.case_tag_text,
      imgs: placeInfo.imgs,
      case_img_check: placeInfo.case_img_check,
      case_img_before: placeInfo.case_img_before,
      case_img_after: placeInfo.case_img_after,
      cz_delete_ids: deleteIds,
    };

    /* 2step 임시저장 시키기 */
    APIS.postData("/Admin/Create3rdZoneAdd", sender, 1)
      .then((res) => {
        setTimeout(() => {
          dispatch(loadingEnd());
          if (res.data.code === "OK") {
            APIS.postData("/Admin/CaseTarget", sender, 1)
              .then((res) => {
                dispatch(loadingEnd());
                setData(res.data);
                setCaseZone(res.data.case_parts);
              })
              .catch((e) => {
                console.log("catch", e);
              });
          } else {
            dispatch(
              openSub({
                message: "잠시 후 다시 시도해주세요.",
                button: "확인",
              })
            );
            return;
          }
        }, 1000);
      })
      .catch((e) => {
        console.log("catch", e);
      });

    return;
  };

  const removeImage = (table_id, img_index) => {
    const findCaseId = caseZone
      .find((item) => item.case_img_table_id === table_id)
      ?.imgs.find((_, i) => img_index === i)?.case_zone_table_id;

    setDeleteIds((prevData) => [...prevData, findCaseId]);

    setCaseZone((prevData) =>
      prevData.map((item, index) => {
        if (
          (typeof table_id === "string" &&
            item.case_img_table_id === table_id) ||
          (typeof table_id === "number" && index === table_id)
        ) {
          return {
            ...item,
            imgs: item.imgs?.filter((_, i) => i !== img_index),
            photoList: item?.photoList?.filter(
              (_, i) => i !== item.imgs.length - 1 - Number(img_index)
            ),
          };
        } else {
          return item;
        }
      })
    );
  };

  const uploadImageHandler = async (e, table_id) => {
    const { files } = e.target;

    if (!files) {
      return;
    }

    const fileList = Object.values(files);

    const includeImages = ["jpeg", "jpg", "png", "gif"];

    if (
      fileList.filter(
        (item) => !includeImages.includes(item.type.split("/")[1])
      ).length > 0
    ) {
      alert("jpeg, jpg, png, gif 확장자만 첨부가능합니다.");
      return;
    }

    try {
      const files_data = Array.from(files).map(async (file) => {
        const compressedFile = await imageCompression(file, {
          maxSizeMB: 1,
          fileType: "image/jpeg",
          useWebWorker: true,
        });

        const promise = imageCompression.getDataUrlFromFile(compressedFile);

        return new Promise((resolve) => {
          promise.then((result) => {
            resolve(result);
          });
        });
      });

      const resolve = await Promise.all(files_data);

      setCaseZone((prevData) =>
        prevData.map((item, index) => {
          if (
            (typeof table_id === "string" &&
              item.case_img_table_id === table_id) ||
            (typeof table_id === "number" && index === table_id)
          ) {
            return {
              ...item,
              imgs: [...item.imgs, ...resolve],
              photoList: item.photoList
                ? [...item.photoList, ...files]
                : [...files],
            };
          } else {
            return item;
          }
        })
      );

      e.target.value = "";
    } catch (err) {
      console.log(err);
    }
  };

  const updateImageBeforeAndAfterHandler = (index, type, file) => {
    setCaseZone((prevData) =>
      prevData.map((item, i) =>
        i === index
          ? {
              ...item,
              [type]: file,
            }
          : item
      )
    );
  };

  return (
    <>
      <h3
        css={css`
          font-size: 18px;
          margin: 40px 0 10px 0;
        `}
      >
        *공간별 상세 정보 (5장 이상 필수)
      </h3>
      <div className="theme_multi_info_box">
        {caseZone &&
          caseZone.map((x, i) => {
            return (
              <div className="casezone_box" key={i}>
                <button
                  type="button"
                  className="trash_btn_red"
                  onClick={() =>
                    caseOneDelete(
                      i,
                      x.case_img_table_id ? x.case_img_table_id : "",
                      caseZone,
                      setCaseZone,
                      setCaseZoneDeleteIds,
                      caseZoneDeleteIds
                    )
                  }
                />
                <div
                  css={css`
                    margin-bottom: 20px;
                    display: flex;
                    width: 100%;
                  `}
                >
                  <InputSelect
                    name="case_parts_type"
                    label="공간"
                    value={x.case_parts_type || ""}
                    index={i}
                    option={consts.partsoption}
                    onChange={caseSetValueZone}
                  />
                  <div>
                    <button
                      type="button"
                      id="edit"
                      className="btn btn3"
                      onClick={() =>
                        placePhoto(
                          x.photoList,
                          x.imgs,
                          caseSetPhotoValue,
                          i,
                          10000,
                          " "
                        )
                      }
                      css={css`
                        width: 106px;
                        margin: 24px 0 0 0;
                        color: ${x.case_img_check === "1"
                          ? "darkgray"
                          : "black"};

                        &:disabled {
                          cursor: not-allowed;
                        }
                      `}
                      disabled={x.case_img_check === "1"}
                    >
                      편집
                    </button>
                    {/*사진 편집은 체크시 표시 x */}
                    {x.case_img_check !== "1" && x.imgs.length > 0 && (
                      <p className="label_compact">
                        <span>
                          {x.imgs[0].case_zone_table_id
                            ? getFileName(x.imgs[0].case_zone_img)
                            : x.photoList[0] && x.photoList[0].name}
                        </span>
                        {x.imgs.length > 1 && (
                          <span> 외 {x.imgs.length - 1}개</span>
                        )}
                      </p>
                    )}
                    {x.case_img_check === "1" && (
                      <p style={{ color: "red" }}>시공 전/후 사진</p>
                    )}
                  </div>
                </div>
                {/* 이미지 리스트 영역 */}
                <div>
                  <ul
                    css={css`
                      display: flex;
                      flex-wrap: wrap;
                      gap: 10px;
                    `}
                  >
                    {String(x.case_img_check) === "0" &&
                      x.imgs &&
                      x.imgs.length > 0 &&
                      x?.imgs.map((img, imgIndex) => {
                        const image = img.case_zone_img || img;

                        return (
                          <li
                            key={imgIndex}
                            css={css`
                              width: 107px;
                              height: 107px;
                              position: relative;
                            `}
                          >
                            <img
                              src={image}
                              alt="case_img"
                              css={css`
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                border-radius: 4px;
                              `}
                            />
                            <button
                              type="button"
                              className="img_delete_btn"
                              onClick={() => {
                                removeImage(x.case_img_table_id || i, imgIndex);
                              }}
                            />
                          </li>
                        );
                      })}
                    <div
                      css={css`
                        width: 107px;
                        height: 107px;

                        & > input {
                          display: none;

                          &:disabled ~ label {
                            cursor: not-allowed;
                          }
                        }
                      `}
                    >
                      <input
                        type={"file"}
                        id={`file_upload_${x.case_img_table_id || i}`}
                        multiple
                        disabled={x.case_img_check === "1"}
                        onChange={(e) =>
                          uploadImageHandler(e, x.case_img_table_id || i)
                        }
                      />
                      <label
                        htmlFor={`file_upload_${x.case_img_table_id || i}`}
                        css={[labelStyles]}
                      >
                        <img src={images.camera} alt="images_camera" />
                      </label>
                    </div>
                  </ul>
                </div>
                <div
                  css={css`
                    width: 100%;
                  `}
                >
                  <TextArea
                    className="input_box1 textarea"
                    placeholder="예) 샤워부스 시공 및 양변기, 수조 전면 교체, 줄눈이 시공 포함 (1000자 이내로 입력해 주세요.)"
                    name="case_desc"
                    value={x.case_desc}
                    onChange={caseSetValueZone}
                    index={i}
                    maxlength={1000}
                  />
                </div>
                <div style={{ marginBottom: "20px" }}>
                  <input
                    type="checkbox"
                    id={"check_photo2" + i}
                    className="checkbox"
                    css={css`
                      &:disabled ~ label {
                        background-color: ${Colors.gray_100};
                        cursor: not-allowed;
                        color: ${Colors.gray_400};
                      }
                    `}
                    value={x.case_img_check || ""}
                    name="checkPhoto"
                    onChange={(e) => photoPlaceCheck(x, e, i)}
                    checked={x.case_img_check === "1"}
                    disabled={
                      ((x.case_img_check === "0" ||
                        x.case_img_check === null) &&
                        x.imgs.length > 0) ||
                      (x.case_img_before !== "" &&
                        x.case_img_before !== null) ||
                      (x.case_img_after !== "" && x.case_img_after !== null)
                    }
                  />
                  <label htmlFor={"check_photo2" + i}>
                    시공 전/후 사진 등록
                  </label>
                </div>
                {x.case_img_check === "1" ? (
                  <>
                    <div
                      css={css`
                        width: 100%;
                        margin: 40px 0 20px 0;
                        display: flex;
                        justify-content: space-between;
                        gap: 20px;
                      `}
                    >
                      <div
                        css={css`
                          width: 50%;

                          .input_box {
                            width: 100% !important;
                          }

                          & > div > div:last-of-type {
                            button {
                              background: none;
                            }
                          }
                        `}
                      >
                        <Upload
                          label="시공전"
                          size="full"
                          id={`file_before_${i}_before`}
                          callback={(data) =>
                            updateImageBeforeAndAfterHandler(
                              i,
                              "case_img_before",
                              data.fileImage
                            )
                          }
                          preview={x.case_img_before}
                          deletePreivew={() =>
                            updateImageBeforeAndAfterHandler(
                              i,
                              "case_img_before",
                              ""
                            )
                          }
                          resize={1}
                        />
                      </div>
                      <div
                        css={css`
                          width: 50%;

                          .input_box {
                            width: 100% !important;
                          }

                          & > div > div:last-of-type {
                            button {
                              background: none;
                            }
                          }
                        `}
                      >
                        <Upload
                          label="시공후"
                          size="full"
                          id={`file_before_${i}_after`}
                          callback={(data) =>
                            updateImageBeforeAndAfterHandler(
                              i,
                              "case_img_after",
                              data.fileImage
                            )
                          }
                          preview={x.case_img_after}
                          deletePreivew={() =>
                            updateImageBeforeAndAfterHandler(
                              i,
                              "case_img_after",
                              ""
                            )
                          }
                          resize={1}
                        />
                      </div>
                    </div>
                    <div
                      css={css`
                        display: flex;
                        gap: 30px;
                      `}
                    >
                      {x.case_img_before && (
                        <div
                          css={css`
                            position: relative;
                          `}
                        >
                          <img
                            src={x.case_img_before}
                            alt="case_img"
                            css={css`
                              width: 107px;
                              height: 107px;
                              border-radius: 4px;
                            `}
                          />
                          <button
                            type="button"
                            className="img_delete_btn"
                            onClick={() => {
                              const el = document.getElementById(
                                `file_before_${i}_before_delete_btn`
                              );
                              el.click();
                            }}
                          />
                          <div
                            css={css`
                              background-color: ${Colors.gray_100};
                              position: absolute;
                              bottom: 2px;
                              left: 2px;
                              padding: 2px 10px;
                            `}
                          >
                            <p
                              css={css`
                                font-size: 12px;
                                font-weight: 600;
                              `}
                            >
                              시공 전
                            </p>
                          </div>
                        </div>
                      )}

                      {x.case_img_after && (
                        <div
                          css={css`
                            position: relative;
                          `}
                        >
                          <img
                            src={x.case_img_after}
                            alt="case_img"
                            css={css`
                              width: 107px;
                              height: 107px;
                              border-radius: 4px;
                            `}
                          />
                          <button
                            type="button"
                            className="img_delete_btn"
                            onClick={() => {
                              const el = document.getElementById(
                                `file_before_${i}_after_delete_btn`
                              );

                              el.click();
                            }}
                          />
                          <div
                            css={css`
                              background-color: ${Colors.gray_100};
                              position: absolute;
                              bottom: 2px;
                              left: 2px;
                              padding: 2px 10px;
                            `}
                          >
                            <p
                              css={css`
                                font-size: 12px;
                                font-weight: 600;
                              `}
                            >
                              시공 후
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  ""
                )}

                <div style={{ width: "100%", textAlign: "right" }}>
                  <button
                    style={{ width: "100px" }}
                    type="button"
                    class="btn btn1"
                    onClick={() => {
                      placeInfoAdd(x);
                    }}
                  >
                    저장
                  </button>
                </div>
              </div>
            );
          })}
        <div
          css={css`
            margin: 20px 0;
            text-align: center;
          `}
        >
          <button
            type="button"
            className="btn btn1"
            onClick={() => caseAppend()}
          >
            추가
          </button>
        </div>
      </div>
    </>
  );
}
