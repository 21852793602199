import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as APIS from "../../utils/service";
import { patternKor } from "../../utils/utils";
import { open, close } from "../../redux/popupSlice";
import { openSub } from "../../redux/subPopupSlice";
import Input from "../../components/Input";
import InputSelect from "../../components/InputSelect";
import consts from "../../libs/consts";

export default function Insert(props) {
  const { x } = props;
  const dispatch = useDispatch();
  const userData = useSelector((s) => s.usersReducer);

  const [id, setId] = useState("");
  const [idReal, setIdReal] = useState("");
  const [pw, setPw] = useState("");
  const [repw, setRePw] = useState("");
  const [name, setName] = useState("");
  const [hp, setHp] = useState("");
  const [hpFront, setHpFront] = useState("010");
  const [fullHp, setFullHp] = useState(x ? x.seller_phone : "");

  const [auth, setAuth] = useState(x ? x.seller_grade : "");

  const [error2, setError2] = useState("");
  const [error3, setError3] = useState("");
  const [error4] = useState("");
  const [success4] = useState("");

  useEffect(() => {
    setFullHp(hpFront + hp);
  }, [hp, hpFront]);

  const closeFunc = () => {
    dispatch(close());
    return;
  };

  const submitFunc = () => {
    // 이미 초대보냈으면 그냥 닫기
    if (x) {
      dispatch(close());
      return;
    }

    if (!idReal || id !== idReal) {
      dispatch(
        openSub({
          message: "아이디를 확인해 주세요.",
        })
      );
      return;
    }

    if (pw && (pw !== repw || pw.length < 8)) {
      dispatch(
        openSub({
          message: "비밀번호를 확인해 주세요.",
        })
      );
      return;
    }

    if (!name) {
      dispatch(
        openSub({
          message: "사용자명을 확인해 주세요.",
        })
      );
      return;
    }

    if (fullHp.length < 11) {
      dispatch(
        openSub({
          message: "연락처를 확인해 주세요.",
        })
      );
      return;
    }

    if (!auth) {
      dispatch(
        openSub({
          message: "등급을 선택해 주세요.",
        })
      );
      return;
    }

    const urls = "/UserCntl/UserCreate";
    const types = 2;
    const sender = {
      seller_table_id: userData.seller_table_id,
      seller_id: idReal,
      seller_passwd: pw,
      seller_name: name,
      seller_phone: fullHp,
      seller_grade: auth,
    };

    APIS.postData(urls, sender, types)
      .then((res) => {
        if (res.data.code === "OK") {
          dispatch(close());
          setTimeout(() => {
            dispatch(
              open({
                message: "등록되었습니다.",
              })
            );
          }, 50);
        } else {
          dispatch(
            openSub({
              message: "잘못된 접근입니다.",
            })
          );
        }
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };

  const idChk = () => {
    if (!id) {
      setError2("아이디를 입력해주세요.");
      return;
    }

    if (patternKor.test(id)) {
      setError2(
        "영문 소문자, 숫자와 특수기호(@),(.),(-),(_) 만 사용 가능합니다."
      );
      return;
    }

    // 아이디 중복검사후
    const sender = {
      seller_id: id,
    };

    APIS.postData("/UserCntl/IdCheck", sender, 2)
      .then((res) => {
        let msg = "";
        if (res.data.code === "OK") {
          setIdReal(id);
          msg = "사용 가능한 아이디 입니다.";
        } else {
          setIdReal("");
          msg = "이미 사용중인 아이디 입니다.";
        }
        dispatch(
          openSub({
            message: msg,
            button: "확인",
          })
        );
      })
      .catch((e) => {
        console.log("catch", e);
      });
    return;
  };

  return (
    <>
      <form className="popup_form" style={{ marginTop: "10px" }}>
        <p className="require_msg">(*)표시는 필수항목 입니다.</p>
        <Input
          className="input_text"
          type="text"
          placeholder="영문/숫자만 입력해 주세요."
          name="id"
          value={id}
          setValue={setId}
          label="*아이디"
          error={error2}
          valid={"id"}
          withButton={"중복확인"}
          withButtonPress={idChk}
          setError={setError2}
        />
        <Input
          className="input_text"
          type="password"
          placeholder="영문+숫자+특수문자 조합 (최소 8자~15자)"
          name="pw"
          value={pw}
          setValue={setPw}
          label="*비밀번호"
          error={error3}
          setError={setError3}
          valid={"pw"}
          maxlength={15}
        />
        <Input
          className="input_text"
          type="password"
          placeholder="영문+숫자+특수문자 조합 (최소 8자~15자)"
          name="repw"
          value={repw}
          setValue={setRePw}
          label="*비밀번호 확인"
          error={error4}
          success={success4}
          valid={"pw"}
          maxlength={15}
        />

        <Input
          className="input_text"
          type="text"
          placeholder="사용자명을 입력해 주세요."
          id="name"
          name="name"
          value={name}
          setValue={setName}
          label="*사용자명"
        />

        <div
          className="hp_multi_input noButton"
          style={{ marginBottom: "24px" }}
        >
          <InputSelect
            name="hpFront"
            label="*연락처"
            value={hpFront}
            setValue={setHpFront}
            optionHp={true}
          />
          <Input
            className="input_text"
            type="text"
            name="hp"
            value={hp}
            setValue={setHp}
            label=""
            valid={"num"}
            maxlength={8}
          />
        </div>

        <InputSelect
          name="auth"
          label="*등급"
          value={auth}
          setValue={setAuth}
          option={consts.authoption}
        />
      </form>

      <div className="popup_full_infomsg">
        <p className="title">안내사항</p>
        <p className="content">
          등급에 따라 접근 가능한 메뉴는 다음 과 같습니다.
          <br />
          - 사용자 : 견적관리 , 일정관리 <br />- 관리자 : 견적관리 , 일정관리 ,
          결제관리, 사례관리
        </p>
      </div>
      <form className="popup_form" style={{ marginTop: "24px" }}>
        <div className="register_btn_box">
          <button
            type="button"
            className="btn btn3"
            onClick={() => closeFunc()}
          >
            취소
          </button>
          <button
            type="button"
            className="btn btn1"
            onClick={() => submitFunc()}
          >
            등록
          </button>
        </div>
      </form>
    </>
  );
}
