/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import * as APIS from "../../utils/service";

import { open, close } from "../../redux/popupSlice";
import { openSub } from "../../redux/subPopupSlice";
import { loadingStart, loadingEnd } from "../../redux/loadingSlice";

import Input from "../../components/Input";
import InputSelect from "../../components/InputSelect";
import InputFileMulti2 from "../../components/InputFileMulti2";
import TextArea from "../../components/TextArea";
import Tags from "../../components/Tags";
import ThemePartner from "./ThemePartner";
import ThemePyung from "./ThemePyung";

import Area from "../register/Area";

import images from "../../libs/images";
import consts from "../../libs/consts";
import Upload from "../../components/Common/Upload/Upload";
import debounce from "lodash/debounce";
import { css } from "@emotion/react";

export default function ThemeAdd(props) {
  const { data, w, id } = props;
  const dispatch = useDispatch();
  const userData = useSelector((s) => s.usersReducer);

  const [step, setStep] = useState(1);
  const [load, setLoad] = useState(true);
  const [detail, setDetail] = useState();
  const [theme_table_id, setTheme_table_id] = useState(id ? id : "");

  /* step1 state */

  const [theme_state, setTheme_state] = useState(data ? data.theme_state : "0"); // 테마상태

  const [partners, setPartners] = useState(data ? data.partners : []); // 시공 파트너들

  const [sellerText, setSellerText] = useState(""); // 시공 파트너들 한글

  const [theme_type, setTheme_type] = useState(data ? data.theme_type : "1"); // 주거유형
  const [theme_title, setTheme_title] = useState(data ? data.theme_title : ""); // 테마명
  const [theme_desc, setTheme_desc] = useState(data ? data.theme_desc : ""); // 시공컨셉

  const [main_img, setMain_img] = useState(data ? data.main_img : ""); // 메인사진
  const [is_photo_del, setis_photo_del] = useState(id ? 0 : 1); // 메인사진 삭제여부

  const [area, setArea] = useState(data ? data.theme_work_area2 : ""); // 시공지역
  const [areaText, setAreaText] = useState(
    data ? data.theme_work_area_text : ""
  ); // 시공지역 텍스트

  const [theme_size, setTheme_size] = useState(data ? data.theme_size : []); // 평형 배열
  const [theme_size_select, setTheme_size_select] = useState(
    data && data.rep_size !== "0" ? data.rep_size : ""
  ); // 평형 선택
  const [rep_size, setrep_size] = useState(
    data && data.rep_size !== "0" ? data.rep_size : ""
  ); // 대표 평형

  const [info, setInfo] = useState([]); // 시공정보

  const [minPrice, setMinPrice] = useState(""); // 평당최소가격
  const [maxPrice, setMaxPrice] = useState(""); // 평당최대가격

  const [error, setError] = useState("");
  const [error3, setError3] = useState("");

  const [error3_2, setError3_2] = useState("");

  const [error6_1, setError6_1] = useState("");
  const [error6_2, setError6_2] = useState("");

  const [theme_period, setTheme_period] = useState(""); // 시공기간

  const [recp_img, setRecp_img] = useState(""); // 도면사진

  const [view_img, setview_img] = useState(""); // 전체사진

  const [videoUrl, setVideoUrl] = useState(""); // 동영상URL

  const [zone_list, setZone_list] = useState([]); // 공간별 상세 정보 배열

  const [zone_del, setZone_del] = useState([]); // 공간별 상세 정보 삭제 고유번호 리스트
  const [zone_del_img, setZone_del_img] = useState([]); // 공간별 상세 정보 이미지 삭제 고유번호 리스트

  const [material_list, setMaterial_list] = useState([]); // 시공별 가격정보 배열
  const [material_del, setMaterial_del] = useState([]); // 시공별 가격정보 삭제 고유번호 리스트

  const [style_list, setStyle_list] = useState([]); // 홈스타일링 배열
  const [style_del, setStyle_del] = useState([]); // 홈스타일링 삭제 고유번호 리스트
  const [style_del_img, setStyle_del_img] = useState([]); // 홈스타일링 이미지 삭제 고유번호 리스트
  const [style_del_main_img, setStyle_del_main_img] = useState([]); // 홈스타일링 메인 이미지 삭제 고유번호 리스트

  /* step2 state end*/

  useEffect(() => {
    document.querySelector(".popup_list_ul").scrollTo(0, 0);

    if (theme_table_id) {
      setLoad(true);

      if (step === 1) {
        const sender = {
          admin_table_id: userData.admin_table_id,
          theme_table_id: theme_table_id,
        };

        APIS.postData("/AdminTheme/ThemeDetail1st", sender, 1)
          .then((res) => {
            if (res.data.code === "OK" && res.data.theme_table_id) {
              //setTheme_table_id(res.data.theme_table_id);

              setTheme_state(res.data.theme_state);
              setPartners(res.data.partners);
              setTheme_type(res.data.theme_type);
              setTheme_title(res.data.theme_title);
              setTheme_desc(res.data.theme_desc);
              setMain_img(res.data.main_img);
              setis_photo_del(res.data.main_img ? 0 : 1);
              setTheme_size(res.data.theme_size);
              setTheme_size_select(
                res.data.rep_size !== "0" ? res.data.rep_size : ""
              );
              setrep_size(res.data.rep_size !== "0" ? res.data.rep_size : "");

              setMinPrice(res.data.price_min);
              setMaxPrice(res.data.price_max);

              setInfo(res.data.work_info);

              setArea(res.data.theme_area_code);
              setAreaText(
                res.data.theme_area_text
                  ? res.data.theme_area_text.join(", ")
                  : ""
              );
              setDetail((prevData) => ({
                ...prevData,
                ...res.data,
              }));
            } else {
              dispatch(
                openSub({
                  message: "해당 테마가 존재하지 않습니다.",
                  onPress: () => {
                    dispatch(close());
                  },
                })
              );
            }

            setLoad(false);
          })
          .catch((e) => {
            console.log("catch", e);
          });
      } else {
        const sender = {
          admin_table_id: userData.admin_table_id,
          theme_table_id: theme_table_id,
          theme_size: theme_size_select,
        };

        APIS.postData("/AdminTheme/ThemeDetail2nd", sender, 1)
          .then((res) => {
            if (res.data.code === "OK") {
              setTheme_period(
                res.data.theme_period ? res.data.theme_period : ""
              );
              setRecp_img(res.data.recp_img ? res.data.recp_img : "");

              setview_img(res.data.view_img ? res.data.view_img : "");

              setVideoUrl(res.data.theme_url ? res.data.theme_url : "");

              setZone_list(
                res.data.zone_list.map((x, i) => {
                  return { ...x, photoList: x.zone_imgs };
                })
              ); // 공간별 상세정보 배열

              setMaterial_list(res.data.work_price_list); // 시공별 상세정보 배열

              setStyle_list(
                res.data.style_list.map((x, i) => {
                  return {
                    ...x,
                    photoList: x.style_imgs,
                    mainphotoList: x.style_main_img,
                  };
                })
              ); // 홈스타일링 상세정보 배열
              setDetail((prevData) => ({
                ...prevData,
                ...res.data,
              }));
            }

            setLoad(false);
          })
          .catch((e) => {
            console.log("catch", e);
          });
      }
    } else {
      setLoad(false);
    }
  }, [step]);

  useEffect(() => {
    const newFormValues = partners.map((x, i) => {
      return x.seller_biz_name;
    });
    setSellerText(newFormValues.join(","));
  }, [partners]);

  useEffect(() => {
    setError3("");
  }, [theme_size_select]);

  const stepChange = () => {
    if (step === 1) {
      if (
        theme_size.length < 1 ||
        !theme_size_select ||
        !theme_title ||
        !main_img
      ) {
        if (!theme_size_select || theme_size.length < 1)
          setError3("평형을 선택해 주세요.");
        if (!theme_title) setError("정보를 입력해 주세요.");

        dispatch(
          openSub({
            message: "모든 정보를 입력 후 다음 단계로 이동해주시기 바랍니다.",
            messageAuto: true,
            button: "확인",
          })
        );
        return;
      }

      let arr = [];

      const checkboxes = document.getElementsByClassName("info");
      for (let cb of checkboxes) {
        if (cb.checked) {
          arr.push(cb.value);
        }
      }

      const sender = {
        admin_table_id: userData.admin_table_id,
        theme_table_id: theme_table_id,
        theme_state: theme_state,
        theme_type: theme_type,
        theme_title: theme_title,
        theme_desc: theme_desc,
        theme_size: theme_size,
        rep_size: theme_size_select,
        price_min: minPrice ? minPrice.replaceAll(",", "") : 0,
        price_max: maxPrice ? maxPrice.replaceAll(",", "") : 0,

        partners: partners,

        theme_area_code: area,
        theme_area_text: areaText.split(", "),

        main_img: detail.main_img === main_img ? "" : main_img,
        is_main_del: theme_table_id ? is_photo_del : 0,
        work_info: arr,
      };

      if (
        sender.price_min &&
        sender.price_max &&
        sender.price_min * 1 > sender.price_max * 1
      ) {
        dispatch(
          openSub({
            message: "최소가격과 최대가격을 확인해 주세요.",
            button: "확인",
          })
        );
        return;
      }
      dispatch(loadingStart());

      APIS.postData("/AdminTheme/ThemeCreate1st", sender, 1)
        .then((res) => {
          setTimeout(() => {
            dispatch(loadingEnd());

            if (res.data.code === "OK" && res.data.theme_table_id) {
              setTheme_table_id(res.data.theme_table_id);
              setStep(2);
            } else {
              dispatch(
                openSub({
                  message: "잠시 후 다시 시도해주세요.",
                  button: "확인",
                })
              );
              return;
            }
          }, 1000);
        })
        .catch((e) => {
          console.log("catch", e);
        });
    } else if (step === 2) {
      const findData = zone_list.find(
        (item) => item.zone_tag_text === "" || item.zone_tag_text.length === 0
      );

      if (zone_list.length > 0 && findData) {
        alert("공간별 상세정보의 필수값을 입력해주세요.");
        return;
      }

      const sender = {
        admin_table_id: userData.admin_table_id,
        theme_table_id: theme_table_id,
        theme_size: theme_size_select,
        theme_period: theme_period,
        recp_img: detail.recp_img === recp_img ? "" : recp_img,
        view_img: detail.view_img === view_img ? "" : view_img,
        theme_avi: "",
        theme_url: videoUrl,
        zone_list: zone_list,
        work_price_list: material_list,
        style_list: style_list,
        zone_del: zone_del,
        work_del: material_del,
        style_del: style_del,
        zone_del_img: zone_del_img,
        style_del_img: style_del_img,
        style_del_main_img: style_del_main_img,
      };

      dispatch(loadingStart());

      APIS.postData("/AdminTheme/ThemeCreate2nd", sender, 1)
        .then((res) => {
          setTimeout(() => {
            dispatch(loadingEnd());
            if (res.data.code === "OK") {
              dispatch(close());
              setTimeout(() => {
                dispatch(
                  open({
                    message: "저장되었습니다.",
                  })
                );
              }, 50);
            } else {
              dispatch(
                openSub({
                  message: "잠시 후 다시 시도해주세요.",
                  button: "확인",
                })
              );
              return;
            }
          }, 1000);
        })
        .catch((e) => {
          console.log("catch", e);
        });

      return;
    } else {
      dispatch(
        openSub({
          message: "잠시 후 다시 시도해주세요.",
          button: "확인",
        })
      );
    }
  };

  const closeFunc = () => {
    if (step === 1) {
      dispatch(
        openSub({
          message: "테마등록을 중단 하시겠습니까?",
          buttonCencle: "취소",
          onPress: () => {
            dispatch(close());
          },
        })
      );
    } else {
      setStep(step - 1);
    }
  };
  const sellerFind = () => {
    dispatch(
      openSub({
        title: "시공파트너",
        component: <ThemePartner value={partners} setValue={setPartners} />,
      })
    );
    return;
  };

  const pyungFind = () => {
    dispatch(
      openSub({
        title: "평형관리",
        component: (
          <ThemePyung
            value={theme_size}
            setValue={setTheme_size}
            rep_size={rep_size}
            setrep_size={setrep_size}
            setTheme_size_select={setTheme_size_select}
          />
        ),
      })
    );
    return;
  };

  const zoneAppend = (type) => {
    if (type === 3) {
      setStyle_list([
        ...style_list,
        {
          zone_type: "1",
          type: "1",
          model_name: "",
          price: "",
          cnt: "",
          total_price: "",
          url: "",
          style_tags: "",
          style_tags_text: "",
          photoList: [],
          style_imgs: [],
          mainphotoList: [],
          style_main_img: [],
          style_main_check: "0",
        },
      ]);
    } else if (type === 2) {
      setMaterial_list([
        ...material_list,
        { zone_type: "1", work_type: "1", price: "", desc: "" },
      ]);
    } else {
      setZone_list([
        ...zone_list,
        {
          zone_type: "1",
          zone_tags: "",
          zone_tag_text: "",
          zone_desc: "",
          photoList: [],
          zone_imgs: [],
        },
      ]);
    }
  };

  const zoneDelete = (i, id, type) => {
    if (type === 3) {
      setStyle_list(style_list.filter((item) => item.zs_table_id !== id));
      setStyle_del([...style_del, id]);
    } else if (type === 2) {
      let newFormValues = [...material_list];
      newFormValues.splice(i, 1);
      setMaterial_list(newFormValues);

      if (id) setMaterial_del([...material_del, id]);
    } else {
      let newFormValues = [...zone_list];
      newFormValues.splice(i, 1);
      setZone_list(newFormValues);

      if (id) setZone_del([...zone_del, id]);
    }
  };

  const allSetValue = (i, e, val, list) => {
    const newFormValues = list.map((item, index) => {
      if (index !== i) {
        return item;
      }
      return {
        ...item,
        [e.target.name]: val,
      };
    });

    return newFormValues;
  };
  const allSetValueAppend = (i, e, val, list) => {
    const newFormValues = list.map((item, index) => {
      if (index !== i) {
        return item;
      }
      return {
        ...item,
        [e.target.name]: [...item[e.target.name], ...val],
      };
    });

    return newFormValues;
  };

  const allSetValueMulti = (i, e, ee, val, val2, list) => {
    const newFormValues = list.map((item, index) => {
      if (index !== i) {
        return item;
      }
      return {
        ...item,
        [e.target.name]: val,
        [ee.target.name]: val2,
      };
    });
    return newFormValues;
  };

  const allSetDeletePhoto = (i, e, val, list) => {
    let newFormValues = [...list];
    newFormValues[i][e.target.name] = val;

    return newFormValues;
  };

  const tagFunc = (
    value,
    setValue,
    setValueText,
    onChange,
    maxlength,
    index,
    name,
    textname
  ) => {
    dispatch(
      openSub({
        title: "태그 정보 선택",
        message: "최대 10개까지 태그 정보 선택 가능합니다.",
        component: (
          <Tags
            value={value}
            setValue={setValue}
            setValueText={setValueText}
            onChange2={onChange}
            maxlength={maxlength}
            index={index}
            name={name}
            textname={textname}
          />
        ),
      })
    );
  };

  const zoneSetValue = (i, e, val) => {
    setZone_list(allSetValue(i, e, val, zone_list));
  };
  const zoneSetValueAppend = (i, e, val) => {
    setZone_list(allSetValueAppend(i, e, val, zone_list));
  };
  const zoneDeletePhoto = (i, e, val) => {
    setZone_list(allSetDeletePhoto(i, e, val, zone_list));
  };
  const zoneSetValueMulti = (i, e, ee, val, val2) => {
    setZone_list(allSetValueMulti(i, e, ee, val, val2, zone_list));
  };

  const materialSetValue = (i, e, val) => {
    setMaterial_list(allSetValue(i, e, val, material_list));
  };

  const styleSetValue = (i, e, val) => {
    setStyle_list(allSetValue(i, e, val, style_list));
  };
  const styleSetValueAppend = (i, e, val) => {
    setStyle_list(allSetValueAppend(i, e, val, style_list));
  };
  const styleDeletePhoto = (i, e, val) => {
    setStyle_list(allSetDeletePhoto(i, e, val, style_list));
  };
  const styleSetValueMulti = (i, e, ee, val, val2) => {
    setStyle_list(allSetValueMulti(i, e, ee, val, val2, style_list));
  };

  const areaFunc = () => {
    dispatch(
      openSub({
        title: "시공가능지역선택",
        component: (
          <Area value={area} setValue={setArea} setValueText={setAreaText} />
        ),
      })
    );
  };

  const changeFileHandler = debounce((file, type) => {
    if (type === "photo") {
      if (detail.main_img !== file.fileImage) {
        setis_photo_del(1);
      }

      setMain_img(file.fileImage);
    } else if (type === "recp_img") {
      setRecp_img(file.fileImage);
    } else if (type === "view_img") {
      setview_img(file.fileImage);
    }
  }, 200);

  const deletePreviewHandler = (type) => {
    if (type === "photo") {
      if (detail?.main_img && detail.main_img === main_img) {
        setis_photo_del(1);
      }
      setMain_img("");
    } else if (type === "recp_img") {
      setRecp_img("");
    } else if (type === "view_img") {
      setview_img("");
    }
  };

  return (
    <div className="popup_list">
      <div className="popup_list_ul popup_instance_step">
        {step === 1 ? (
          <>
            <p className="require_msg">(*)표시는 필수항목 입니다.</p>
            <div className="instance_form_list theme_form_list">
              {!load && (
                <InputSelect
                  name="work"
                  label="등록상태"
                  value={theme_state}
                  setValue={setTheme_state}
                  option={consts.themeState}
                />
              )}
              {!load && (
                <InputSelect
                  name="work"
                  label="주거유형"
                  value={theme_type}
                  setValue={setTheme_type}
                  option={consts.workoption}
                />
              )}
              <Input
                className="input_text"
                type="text"
                placeholder={"테마명을 입력해주세요."}
                name="theme_title"
                value={theme_title}
                setValue={setTheme_title}
                label={"*테마명"}
                error={error}
                setError={setError}
                maxlength={30}
                full={true}
              />

              <div className="instance_form_list_full">
                <TextArea
                  className="input_textarea"
                  placeholder="내용을 입력해 주세요. (500자 이내)"
                  name="theme_desc"
                  value={theme_desc}
                  setValue={setTheme_desc}
                  label="시공컨셉"
                  maxlength={500}
                />
                {/* <Input className="input_text" type="text" placeholder={"시공컨셉을 입력해주세요."} name="theme_desc" value={theme_desc} setValue={setTheme_desc} label={"시공컨셉"} maxlength={500}/> */}
              </div>
              <div className="input_range_box">
                <Input
                  className="input_text"
                  type="text"
                  placeholder="숫자만 입력해 주세요."
                  name="minPrice"
                  maxlength={4}
                  value={minPrice}
                  setValue={setMinPrice}
                  label="평당 예상 가격"
                  withText={"만원"}
                  valid={"price"}
                  error={error6_1}
                  setError={setError6_1}
                />
                <span>~</span>
                <Input
                  className="input_text"
                  type="text"
                  placeholder="숫자만 입력해 주세요."
                  name="maxPrice"
                  maxlength={4}
                  value={maxPrice}
                  setValue={setMaxPrice}
                  withText={"만원"}
                  valid={"price"}
                  error={error6_2}
                  setError={setError6_2}
                />
              </div>
              <div
                css={css`
                  margin-top: 24px;
                  width: calc(50% - 12px);
                `}
              >
                <Upload
                  name="photo"
                  label="*메인사진"
                  id="main_photo"
                  preview={main_img || ""}
                  callback={(file) => changeFileHandler(file, "photo")}
                  deletePreivew={() => deletePreviewHandler("photo")}
                  size="full"
                  resize={2}
                />
              </div>

              <Input
                className="input_text"
                type="text"
                placeholder="지역 선택을 해 주세요."
                name="area"
                value={areaText}
                setValue={setAreaText}
                label="시공지역"
                withButton={"지역선택"}
                withButtonPress={areaFunc}
              />

              <div className="input_check_box">
                <p className="input_label">시공정보</p>
                <div>
                  {!load &&
                    consts.tag_id.map((x, i) => {
                      return (
                        <Fragment key={i}>
                          <input
                            type="checkbox"
                            id={"chk" + i}
                            className="checkbox2 info"
                            value={x.idx}
                            defaultChecked={info.includes(x.idx + "")}
                            data-text={x.title}
                          />
                          <label htmlFor={"chk" + i}>{x.title}</label>
                        </Fragment>
                      );
                    })}
                </div>
              </div>

              <div className="instance_form_list_half">
                <div className="instance_form_list_select_withbutton full">
                  {!load && (
                    <InputSelect
                      name="work"
                      label="*평형"
                      value={theme_size_select}
                      setValue={setTheme_size_select}
                      optionNotKey={theme_size}
                      placeholder={"평형 선택"}
                      error={error3}
                      setError={setError3}
                    />
                  )}
                  <button
                    type="button"
                    className="input_with_button input_with_button_long"
                    onClick={() => pyungFind()}
                  >
                    관리
                  </button>
                </div>
              </div>

              <Input
                className="input_text"
                type="text"
                placeholder="파트너 찾기를 클릭해 주세요."
                name="seller"
                value={sellerText}
                setValue={setSellerText}
                readOnly={true}
                label="시공파트너"
                withButton={"관리"}
                withButtonLong={true}
                withButtonPress={sellerFind}
                error={error3_2}
                setError={setError3_2}
              />
            </div>
          </>
        ) : (
          <>
            <p class="title">{theme_size_select}평 테마정보관리</p>
            <div className="instance_form_list">
              <Input
                className="input_text"
                type="text"
                placeholder="숫자만 입력해 주세요."
                name="theme_period"
                value={theme_period}
                setValue={setTheme_period}
                label="시공기간"
                valid={"num"}
                maxlength={2}
                withText={"일"}
              />
              <Input
                className="input_text"
                type="text"
                placeholder="URL을 입력해 주세요."
                name="videoUrl"
                value={videoUrl}
                setValue={setVideoUrl}
                label="동영상URL"
              />

              <div
                css={css`
                  margin-top: 24px;
                  width: calc(50% - 12px);
                `}
              >
                <Upload
                  name="recp_img"
                  label="도면"
                  id="recp_img"
                  preview={recp_img || ""}
                  callback={(file) => changeFileHandler(file, "recp_img")}
                  deletePreivew={() => deletePreviewHandler("recp_img")}
                  size="full"
                  resize={2}
                />
              </div>

              <div
                css={css`
                  margin-top: 24px;
                  width: calc(50% - 12px);
                `}
              >
                <Upload
                  name="view_img"
                  label="전체사진"
                  id="view_img"
                  preview={view_img || ""}
                  callback={(file) => changeFileHandler(file, "view_img")}
                  deletePreivew={() => deletePreviewHandler("view_img")}
                  size="full"
                  resize={2}
                />
              </div>
            </div>

            <div className="theme_multi_info_box">
              <p className="title">공간별 상세 정보</p>
              {zone_list.map((x, i) => {
                return (
                  <div className="casezone_box" key={i}>
                    <button
                      type="button"
                      className="trash_btn_red"
                      onClick={() =>
                        zoneDelete(i, x.zone_table_id ? x.zone_table_id : "")
                      }
                    />
                    <div className="one_line small_select_two">
                      <InputSelect
                        name="zone_type"
                        label="공간"
                        value={x.zone_type}
                        onChange={zoneSetValue}
                        index={i}
                        option={consts.partsoption}
                      />
                      <Input
                        className="input_text"
                        type="text"
                        placeholder="설명을 입력해 주세요."
                        name="zone_desc"
                        value={x.zone_desc}
                        onChange={zoneSetValue}
                        index={i}
                        label="설명"
                      />
                    </div>
                    <div className="one_line full">
                      <Input
                        className="input_text"
                        type="text"
                        placeholder="관리버튼을 눌러 태그를 선택하세요."
                        name="zone_tags"
                        value={x.zone_tag_text}
                        onChange={zoneSetValue}
                        label="* 태그정보 (최대10개)"
                        withButton={"관리"}
                        withButtonPress={() =>
                          tagFunc(
                            x.zone_tags,
                            zoneSetValueMulti,
                            "",
                            zoneSetValueMulti,
                            10,
                            i,
                            "zone_tags",
                            "zone_tag_text"
                          )
                        }
                        maxlength={5000}
                        full={true}
                      />
                    </div>
                    <div className="one_line">
                      <InputFileMulti2
                        type="file"
                        multiple={true}
                        placeholder="입력해주세요."
                        name="photoList"
                        imgName="zone_imgs"
                        label="상세사진"
                        value={x.photoList}
                        imgValue={x.zone_imgs}
                        onChange={zoneSetValueAppend}
                        onDelete={zoneDeletePhoto}
                        onDeleteAddKey={"zi_table_id"}
                        onDeleteAddFunc={setZone_del_img}
                        index={i}
                        valid="image"
                        full={true}
                        maxlength={1}
                      />
                    </div>
                  </div>
                );
              })}
              <button
                type="button"
                className="case_add_btn"
                onClick={() => zoneAppend()}
              >
                <img
                  src={images.plus}
                  alt="공간 추가하기"
                  className="plus_img"
                />
                공간 추가하기
              </button>
            </div>

            <div className="theme_multi_info_box">
              <p className="title">시공별 가격정보</p>
              {material_list.map((x, i) => {
                return (
                  <div className="casezone_box" key={i}>
                    <button
                      type="button"
                      className="trash_btn_red"
                      onClick={() =>
                        zoneDelete(
                          i,
                          x.material_table_id ? x.material_table_id : "",
                          2
                        )
                      }
                    />
                    <div className="one_line half_three">
                      <InputSelect
                        name="zone_type"
                        label="공간"
                        value={x.zone_type}
                        onChange={materialSetValue}
                        index={i}
                        option={consts.partsoption}
                      />
                      <InputSelect
                        name="work_type"
                        label="시공"
                        value={x.work_type}
                        onChange={materialSetValue}
                        index={i}
                        option={consts.buildsoption}
                      />
                      <Input
                        className="input_text"
                        type="text"
                        placeholder="숫자만 입력해주세요."
                        name="price"
                        value={x.price}
                        onChange={materialSetValue}
                        index={i}
                        label="가격"
                        valid={"num"}
                        maxlength={5}
                        withText={"만원"}
                      />
                    </div>

                    <div className="one_line full">
                      <Input
                        className="input_text"
                        type="text"
                        placeholder="내용을 입력해 주세요."
                        name="desc"
                        value={x.desc}
                        onChange={materialSetValue}
                        index={i}
                        label="항목"
                        full={true}
                      />
                    </div>
                  </div>
                );
              })}
              <button
                type="button"
                className="case_add_btn"
                onClick={() => zoneAppend(2)}
              >
                <img
                  src={images.plus}
                  alt="자재 추가하기"
                  className="plus_img"
                />
                자재 추가하기
              </button>
            </div>

            <div className="theme_multi_info_box">
              <p className="title">홈스타일링 등록</p>
              {style_list.map((x, i) => {
                return (
                  <div className="casezone_box" key={i}>
                    <button
                      type="button"
                      className="trash_btn_red"
                      onClick={() => zoneDelete(i, x.zs_table_id, 3)}
                    />
                    <div className="one_line half_three">
                      <div className="checkbox_section">
                        <label className="input_label">대표등록</label>
                        <div>
                          <input
                            type="checkbox"
                            id={"style_main_check" + i}
                            name="style_main_check"
                            className="checkbox"
                            defaultChecked={x.style_main_check === "1"}
                            onChange={(e) =>
                              styleSetValue(i, e, e.target.checked ? "1" : "0")
                            }
                          />
                          <label htmlFor={"style_main_check" + i}> </label>
                        </div>
                      </div>
                      <InputSelect
                        name="zone_type"
                        label="공간"
                        value={x.zone_type}
                        onChange={styleSetValue}
                        index={i}
                        option={consts.partsoption}
                      />
                      <InputSelect
                        name="type"
                        label="종류"
                        value={x.type}
                        onChange={styleSetValue}
                        index={i}
                        option={consts.themeEqCode}
                      />
                    </div>

                    <div className="one_line half_three">
                      <Input
                        className="input_text"
                        type="text"
                        placeholder="내용을 입력해 주세요."
                        name="model_name"
                        value={x.model_name}
                        onChange={styleSetValue}
                        index={i}
                        label="제품명"
                      />
                      <Input
                        className="input_text"
                        type="text"
                        placeholder="내용을 입력해 주세요."
                        name="price"
                        value={x.price}
                        onChange={styleSetValue}
                        index={i}
                        label="단가"
                        valid={"num"}
                        maxlength={5}
                        withText={"만원"}
                      />
                      <Input
                        className="input_text"
                        type="text"
                        placeholder="숫자만 입력해주세요."
                        name="cnt"
                        value={x.cnt}
                        onChange={styleSetValue}
                        index={i}
                        label="수량"
                        valid={"num"}
                        maxlength={5}
                      />
                    </div>

                    <div className="one_line full">
                      <Input
                        className="input_text"
                        type="text"
                        placeholder="URL주소를 입력해 주세요."
                        name="url"
                        value={x.url}
                        onChange={styleSetValue}
                        index={i}
                        label="URL"
                      />
                    </div>
                    <div className="one_line full">
                      <Input
                        className="input_text"
                        type="text"
                        placeholder="관리버튼을 눌러 태그를 선택하세요."
                        name="style_tags"
                        value={x.style_tag_text}
                        onChange={styleSetValue}
                        label="태그정보 (최대10개)"
                        withButton={"관리"}
                        withButtonPress={() =>
                          tagFunc(
                            x.style_tags,
                            styleSetValueMulti,
                            "",
                            styleSetValueMulti,
                            10,
                            i,
                            "style_tags",
                            "style_tag_text"
                          )
                        }
                        maxlength={5000}
                        full={true}
                      />
                    </div>

                    {x.style_main_check === "1" && (
                      <div className="one_line">
                        <InputFileMulti2
                          type="file"
                          placeholder="입력해주세요."
                          name="mainphotoList"
                          imgName="style_main_img"
                          label="*대표사진"
                          value={x.mainphotoList}
                          imgValue={x.style_main_img}
                          onChange={styleSetValueAppend}
                          onDelete={styleDeletePhoto}
                          onDeleteAddKey={"si_table_id"}
                          onDeleteAddFunc={setStyle_del_main_img}
                          index={i}
                          valid="image"
                          full={true}
                          maxlength={1}
                          readOnly={x.style_main_check !== "1"}
                        />
                      </div>
                    )}

                    <div className="one_line">
                      <InputFileMulti2
                        type="file"
                        multiple={true}
                        placeholder="입력해주세요."
                        name="photoList"
                        imgName="style_imgs"
                        label="*상세사진 (10개까지 등록 가능)"
                        value={x.photoList}
                        imgValue={x.style_imgs}
                        onChange={styleSetValueAppend}
                        onDelete={styleDeletePhoto}
                        onDeleteAddKey={"si_table_id"}
                        onDeleteAddFunc={setStyle_del_img}
                        index={i}
                        valid="image"
                        full={true}
                      />
                    </div>
                  </div>
                );
              })}
              <button
                type="button"
                className="case_add_btn"
                onClick={() => zoneAppend(3)}
              >
                <img
                  src={images.plus}
                  alt="홈스타일링 추가하기"
                  className="plus_img"
                />
                홈스타일링 추가하기
              </button>
            </div>
          </>
        )}
      </div>

      <div className="popup_list_btn_box popup_pay_step">
        <button type="button" className="btn btn3" onClick={() => closeFunc()}>
          {step === 2 ? "이전" : "취소"}
        </button>
        <button type="button" className="btn btn1" onClick={() => stepChange()}>
          {step === 2 ? (w === "u" ? "저장" : "등록") : "다음"}
        </button>
      </div>
    </div>
  );
}
