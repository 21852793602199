import React, { useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import Modal from "../../../Common/ModalPortal";
import images from "../../../../libs/images";
import Button from "../../../Common/Button/Button";
import {
  stepStyles,
  stepTitleStyles,
  marginTopStyles,
  labelStyles,
} from "../../notice.style";
import * as Colors from "../../../../constants/color";
import InputWithButton from "../../../Common/Input/InputWithButton";
import DateRange from "../../../Common/Date/DateRange";
import FindPartner, { Partner } from "../FindPartner";
import moment from "moment";
import { InfoType, PartnerType } from "../noticeAd.type";
import useNoticeAd from "../noticeAd.hook";
import Select from "../../../Common/Select/Select";
import Upload, { FileData } from "../../../Common/Upload/Upload";

const Container = styled.div`
  width: 1128px;
  background-color: #ffffff;
  margin: auto;
`;

const Header = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${Colors.gray_200};

  & > h3 {
    margin: auto 0;
  }
`;

const Footer = styled.div`
  padding: 20px;
  border-top: 1px solid ${Colors.gray_200};
  display: flex;
  justify-content: center;
  gap: 15px;
`;

interface Props {
  close: () => void;
  callback: () => void;
}

const CreateNoticeAd = (props: Props) => {
  const { close, callback } = props;
  const { area, create } = useNoticeAd();

  const [info, setInfo] = useState<InfoType>({
    seller_table_id: "",
    seller_biz_name: "",
    ad_start_date: "",
    ad_end_date: "",
  });

  const [isOpenFindPartner, setIsOpenFindPartner] = useState(false);

  const [areaData, setAreaData] = useState<PartnerType[]>(
    Array.from({ length: 3 }, (_, i) => ({
      id: i + 1,
      area: "",
      city: "",
      upload: {
        file: undefined,
        fileImage: "",
        fileName: "",
      },
      isFill: false,
    }))
  );

  const submitPartner = (partner: Partner) => {
    setInfo({
      ...info,
      seller_table_id: partner.seller_table_id,
      seller_biz_name: partner.seller_biz_name,
    });
  };

  const changeDateHandler = (type: string, date?: Date) => {
    if (date) {
      setInfo({
        ...info,
        ...(type === "start" && {
          ad_start_date: moment(date).format("YYYY-MM-DD 00:00:00"),
        }),
        ...(type === "end" && {
          ad_end_date: moment(date).format("YYYY-MM-DD 23:59:59"),
        }),
      });
    }
  };

  const createHandler = async (): Promise<void> => {
    const params = {
      ...info,
      area_list: areaData.map((item) => ({
        area1: item.area,
        area2: item.city,
        area_picture: item.upload.fileImage,
        id: item.id,
      })),
    };

    const res = await create(params);

    if (typeof res === "boolean") {
      if (res) {
        callback();
      } else {
        alert("등록중 오류가 발생하였습니다.");
      }
    }
  };

  const changeSelectHandler = (
    type: "area" | "city",
    id: number,
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { value } = e.target;

    setAreaData((prevDara) =>
      prevDara.map((item) =>
        item.id === id
          ? {
              ...item,
              [type]: value,
              ...(type === "area" && { city: "" }),
            }
          : item
      )
    );
  };

  const uploadHandler = (id: number, file: FileData) => {
    setAreaData((prevData) =>
      prevData.map((item) =>
        item.id === id
          ? {
              ...item,
              upload: file,
            }
          : item
      )
    );
  };

  return (
    <Modal>
      <Container>
        <Header>
          <h3>노출 광고 등록</h3>
          <button onClick={close}>
            <img src={images.exit} alt="exit_btn" />
          </button>
        </Header>
        <div>
          <div
            css={css`
              padding: 30px 20px;
              border-bottom: 1px solid ${Colors.gray_200};
            `}
          >
            <div
              css={css`
                display: flex;
              `}
            >
              <p css={stepStyles}>1</p>
              <h3 css={stepTitleStyles}>기본 정보</h3>
            </div>

            <div css={[marginTopStyles]}>
              <InputWithButton
                label="* 파트너 명"
                buttonText="검색"
                onClick={() => setIsOpenFindPartner(true)}
                value={info.seller_biz_name}
                size="big"
                disabled
              />
            </div>
            <div css={[marginTopStyles]}>
              <DateRange
                label="* 운영 기간"
                onChange={changeDateHandler}
                startValue={
                  info.ad_start_date ? new Date(info.ad_start_date) : ""
                }
                endValue={info.ad_end_date ? new Date(info.ad_end_date) : ""}
                startPlaceholder="시작일"
                endPlaceholder="종료일"
                size="big"
              />
            </div>
          </div>
          <div
            css={css`
              padding: 30px 20px;
            `}
          >
            <div
              css={css`
                display: flex;
              `}
            >
              <p css={stepStyles}>2</p>
              <h3 css={stepTitleStyles}>지역 노출 정보</h3>
            </div>
            <div
              css={[
                marginTopStyles,
                css`
                  display: flex;
                  justify-content: space-between;
                `,
              ]}
            >
              {areaData.map((item) => (
                <div
                  key={item.id}
                  css={css`
                    width: 30%;
                  `}
                >
                  <p css={[labelStyles]}>* 지역 {item.id}</p>
                  <div
                    css={[
                      marginTopStyles,
                      css`
                        display: flex;
                        justify-content: space-between;
                      `,
                    ]}
                  >
                    <div
                      css={css`
                        width: 40%;
                      `}
                    >
                      <Select
                        defaultValue="도/시"
                        options={area.map((item) => {
                          return {
                            value: item.area_id,
                            label: item.area_name,
                          };
                        })}
                        onChange={(e) =>
                          changeSelectHandler("area", item.id, e)
                        }
                        value={item.area}
                        size="full"
                      />
                    </div>

                    <div
                      css={css`
                        width: 55%;
                      `}
                    >
                      <Select
                        defaultValue="시/구/군"
                        options={
                          area
                            .find((it) => it.area_id === item.area)
                            ?.area_2nd.map((item) => {
                              return {
                                value: item.area_detail_id,
                                label: item.area_detail_name,
                              };
                            }) || []
                        }
                        onChange={(e) =>
                          changeSelectHandler("city", item.id, e)
                        }
                        value={item.city}
                        size="full"
                      />
                    </div>
                  </div>
                  <div
                    css={[
                      marginTopStyles,
                      css`
                        & > div > div {
                          height: 68px;
                        }
                      `,
                    ]}
                  >
                    <Upload
                      id={`file_${item.id}`}
                      callback={(cb) => {
                        uploadHandler(item.id, cb);
                      }}
                      size="full"
                      placeholder="사진을 첨부해주세요."
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <Footer>
          <Button theme="tertiary" onClick={close}>
            취소
          </Button>
          <Button onClick={createHandler}>등록</Button>
        </Footer>
      </Container>
      {isOpenFindPartner && (
        <FindPartner
          close={() => setIsOpenFindPartner(false)}
          submit={submitPartner}
        />
      )}
    </Modal>
  );
};

export default CreateNoticeAd;
