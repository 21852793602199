import React, { useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import SubModal from "../../Common/SubModal";
import images from "../../../libs/images";
import Table from "../../Common/Table/Table";
import * as APIS from "../../../utils/service";
import InputWithButton from "../../Common/Input/InputWithButton";

const Container = styled.div`
  width: 930px;
  background-color: #ffffff;
  margin: auto;
`;

const Header = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e1e1e1;

  & > h3 {
    margin: auto 0;
  }
`;

const Content = styled.div`
  padding: 20px;
`;

const Footer = styled.div`
  padding: 20px;
  border-top: 1px solid #e1e1e1;
  text-align: center;

  .footer_modal {
    width: 145px !important;
    height: 48px;
    line-height: 48px;

    &.exit {
      background-color: #fff;
      color: #718096;
      font-size: 14px;
      font-weight: bold;
      border: solid 1px #e4e9f2;
      transition: border 0.3s, color 0.3s;
    }
    &.submit {
      background-color: #066e4d;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      transition: background-color 0.3s;
      margin-left: 15px;
    }
  }
`;

export interface Partner {
  seller_biz_name: string;
  seller_id: string;
  seller_phone: string;
  seller_table_id: string;
}

interface Props {
  close: () => void;
  submit: (partner: Partner) => void;
}
const FindPartner = (props: Props) => {
  const { close, submit } = props;
  const [partnerId, setPartnerId] = useState("");
  const [partnerList, setPartnerList] = useState<Partner[]>([]);
  const [cehcekdId, setChecekdId] = useState("");

  const searchHandler = async () => {
    if (partnerId.length < 2) {
      alert("2글자 이상 입력해주세요.");
      return;
    }
    try {
      const params = {
        srch: partnerId,
      };

      const res = await APIS.postData("/AdminSafety/SellerSrch", params, 1);

      if (res.data.code === "OK") {
        setPartnerList(res.data.seller_list);
      } else {
        alert("알수 없는 문제가 발생하였습니다. 다시 시도 해주세요.");
        return;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const submitHandler = () => {
    if (!cehcekdId) {
      alert("파트너를 선택해 주세요.");
      return;
    }
    const partner = partnerList.find(
      (item: any) => item.seller_table_id === cehcekdId
    );

    if (partner) {
      submit(partner);
    }
    close();
  };

  const col = [
    {
      key: "select",
      title: "선택",
      render: (item: any) => (
        <>
          <input
            type="radio"
            id={`${item.seller_table_id}`}
            className="radio"
            checked={cehcekdId === `${item.seller_table_id}`}
            readOnly
          />
          <label />
        </>
      ),
    },
    { key: "seller_table_id", title: "파트너 ID" },
    { key: "seller_biz_name", title: "파트너명" },
    { key: "seller_phone", title: "전화번호" },
  ];

  return (
    <SubModal>
      <Container>
        <Header>
          <h3>파트너 찾기</h3>
          <button onClick={close}>
            <img src={images.exit} alt="exit_btn" />
          </button>
        </Header>
        <Content>
          <InputWithButton
            label="파트너 ID"
            value={partnerId}
            onChange={(e) => setPartnerId(e.target.value)}
            placeholder="파트너 ID를 입력해 주세요."
            buttonText="찾기"
            onClick={searchHandler}
          />

          <div
            css={css`
              margin-top: 20px;
            `}
          >
            <Table
              columns={col}
              datas={partnerList}
              rowCallback={(e) => setChecekdId(e.seller_table_id)}
            />
          </div>
        </Content>
        <Footer>
          <button className="footer_modal exit" onClick={close}>
            취소
          </button>
          <button className="footer_modal submit" onClick={submitHandler}>
            확인
          </button>
        </Footer>
      </Container>
    </SubModal>
  );
};

export default FindPartner;
