import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as APIS from "../../utils/service";
import { open, close } from "../../redux/popupSlice";
import { regPhone } from "../../utils/utils";
import Input from "../../components/Input";
import LoginId from "./LoginId";

export default function ResetPw() {
  const dispatch = useDispatch();
  const [sellerId, setSellerId] = useState("");
  const [id, setId] = useState("");
  const [hp, setHp] = useState("");
  const [sms, setSms] = useState("");
  const [timer, setTimer] = useState(false);
  const [cker, setCker] = useState(false);

  const [pw, setPw] = useState("");
  const [repw, setRePw] = useState("");
  const [disable, setDisable] = useState(true);

  const [error, setError] = useState("");
  const [error2, setError2] = useState("");
  const [error3, setError3] = useState("");
  const [error4, setError4] = useState("");
  const [error5, setError5] = useState("");

  useEffect(() => {
    if (!pw || !repw) {
      setError5("");
      setDisable(true);
      return;
    }

    if (pw !== repw) {
      setError5("비밀번호가 다릅니다.");
      setDisable(true);
    } else {
      setError5("");
      setDisable(false);
    }
  }, [pw, repw]);

  const resetFunc = () => {
    if (disable) return;

    const sender = {
      seller_table_id: sellerId,
      seller_passwd: pw,
    };

    APIS.postData("/SellerInfo/ChgPwd", sender, 2)
      .then((res) => {
        dispatch(close());
        if (res.data.code === "OK") {
          dispatch(
            open({
              message: "비밀번호가 재설정 되었습니다.",
              onPress: goLogin,
            })
          );
        } else {
          dispatch(
            open({
              message: "잠시 후 다시 시도해주세요.",
            })
          );
        }
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };

  const smsSend = () => {
    if (timer || cker) return;

    if (!id) {
      setError("아이디를 입력해 주세요.");
      return;
    }
    if (!hp || !regPhone.test(hp)) {
      setError2("휴대폰번호를 확인해 주세요.");
      return;
    }

    const sender = {
      seller_id: id,
      seller_phone: hp,
    };

    /* 아이디, 비밀번호 검사 */
    APIS.postData("/SellerInfo/PasswdAuth", sender, 2)
      .then((res) => {
        if (res.data.code === "OK") {
          setSellerId(res.data.seller_table_id);
          setTimer(true);
        } else {
          setSellerId("");
          setError2("회원정보가 일치하지 않습니다.");
        }
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };

  const smsFunc = () => {
    if (!sellerId) return;

    if (!sms) {
      setError3("인증번호를 입력해 주세요.");
      return;
    }

    const sender = {
      seller_table_id: sellerId,
      talk_auth_no: sms,
    };

    APIS.postData("/SellerInfo/PasswdCheck", sender, 2)
      .then((res) => {
        if (res.data.code === "OK") {
          setCker(true);
          setTimer(false);
        } else {
          setError3("잘못된 인증번호입니다.");
        }
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };

  const timeOut = () => {
    setTimeout(() => {
      setTimer(false);
    }, 1000);
  };

  const goLogin = () => {
    dispatch(close());

    setTimeout(() => {
      dispatch(
        open({
          title: "아이디와 비밀번호 입력 후\n로그인 요청드립니다.",
          titleLong: true,
          component: <LoginId />,
        })
      );
    }, 50);
  };

  return (
    <form className="popup_form">
      <Input
        className="input_text"
        type="text"
        placeholder="아이디를 입력해 주세요."
        name="id"
        value={id}
        setValue={setId}
        label="*아이디"
        error={error}
        readOnly={timer || cker}
        setError={setError}
      />
      <Input
        className="input_text"
        type="text"
        placeholder="숫자만 입력해 주세요."
        name="hp"
        maxlength={11}
        value={hp}
        setValue={setHp}
        label="*휴대폰번호"
        error={error2}
        valid={"num"}
        withButton={cker ? "인증완료" : "인증요청"}
        withButtonPress={smsSend}
        readOnly={timer || cker}
        setError={setError2}
      />
      {timer && (
        <Input
          className="input_text"
          type="text"
          placeholder="숫자만 입력해 주세요."
          name="sms"
          maxlength={6}
          value={sms}
          setValue={setSms}
          label="*인증번호"
          error={error3}
          valid={"num"}
          withButton="인증"
          withButtonPress={smsFunc}
          timer={timer}
          timerState={timeOut}
          setError={setError3}
        />
      )}

      {cker && (
        <>
          <p className="reset_pw_msg">재설정하실 비밀번호를 입력해주세요.</p>
          <Input
            className="input_text"
            type="password"
            placeholder="영문+숫자+특수문자 조합 (최소 8자~15자)"
            name="pw"
            value={pw}
            setValue={setPw}
            label="비밀번호"
            error={error4}
            setError={setError4}
          />
          <Input
            className="input_text"
            type="password"
            placeholder="영문+숫자+특수문자 조합 (최소 8자~15자)"
            name="repw"
            value={repw}
            setValue={setRePw}
            label="비밀번호 확인"
            error={error5}
          />

          <div className="popup_btn">
            <button
              type="button"
              className={disable ? "popbtn ok_btn disable" : "popbtn ok_btn"}
              onClick={() => {
                resetFunc();
              }}
              style={{ width: "100%" }}
            >
              비밀번호 재설정
            </button>
          </div>
        </>
      )}
    </form>
  );
}
