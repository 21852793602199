/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState, useRef } from "react";

import { groupBy } from "../../utils/utils";
import Receipt_Left from "../../components/Receipt_Left";
import Receipt_Right from "../../components/Receipt_Right";
import consts from "../../libs/consts";

import SwiperCore, {
  EffectFlip,
  EffectFade,
  EffectCube,
  EffectCreative,
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-flip";
import "swiper/css/effect-fade";
import "swiper/css/effect-cube";
import "swiper/css/effect-creative";

SwiperCore.use([
  EffectFlip,
  EffectFade,
  EffectCube,
  EffectCreative,
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
]);

export default function HomeStyleDetailPopup(props) {
  const { data } = props;

  const swiperRef = useRef(null);

  const [active, setActive] = useState("");
  const [groupZone, setGroupZone] = useState("");

  const [list1, setList1] = useState("");
  const [list2, setList2] = useState("");

  useEffect(() => {
    if (data) {
      const groupByZone = groupBy(data.style_list, "zone_type");
      setGroupZone(groupByZone);
      setActive(groupByZone[Object.keys(groupByZone)[0]][0].zone_type);
    }
  }, [data]);

  useEffect(() => {
    if (active) {
      setList1(groupZone[active]);
    }
  }, [active]);

  useEffect(() => {
    if (list1) {
      setList2(list1[0]);
    }
  }, [list1]);

  useEffect(() => {
    swiperRef.current.swiper.slideTo(0, 300);
  }, [list2]);

  const subListSet = (x) => {
    setList2(x);
  };

  const listClickfunc = (x) => {
    subListSet(x);
    swiperRef.current.swiper.slideTo(0, 300);

    window.open(`${x.url}`, "_blank");
  };

  return (
    <div className="popup_list">
      <div className="home_detail_popup">
        <div className="home_detail_popup_top">
          {groupZone &&
            consts.partsoption.map((x, i) => {
              if (!groupZone[x.idx]) return null;
              return (
                <Fragment>
                  <input
                    type="radio"
                    id={"chk" + i}
                    className="checkbox2 info"
                    value={x.idx}
                    name="btn"
                    defaultChecked={active === x.idx}
                    onChange={() => setActive(x.idx)}
                  />
                  <label htmlFor={"chk" + i}>{x.title}</label>
                </Fragment>
              );
            })}
        </div>
        <div className="home_detail_popup_box">
          <div className="home_detail_popup_left">
            <Swiper
              ref={swiperRef}
              className="home_detail_popup_left_slide"
              delay={3000}
              navigation={{
                prevEl: ".front_swiper_prev",
                nextEl: ".front_swiper_next",
              }}
              breakpoints={{
                375: {
                  slidesPerView: 1,
                  spaceBetween: 0,
                },
              }}
            >
              {list2 &&
                list2.style_imgs.length > 0 &&
                list2.style_imgs.map((x, i) => {
                  return (
                    <SwiperSlide key={i}>
                      <Receipt_Left rows={x} data={list2} />
                    </SwiperSlide>
                  );
                })}
            </Swiper>
            <button className="front_swiper_prev" type="button" />
            <button className="front_swiper_next" type="button" />
          </div>
          <div className="home_detail_popup_right">
            <ul>
              {list1 &&
                list1.length > 0 &&
                list1.map((x, i) => {
                  return (
                    <Receipt_Right
                      key={i}
                      rows={x}
                      listClickfunc={listClickfunc}
                    />
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
