/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import * as APIS from "../../../utils/service";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export interface List {
  hot_case_id: string;
  hot_case_name: string;
  update_date: string;
  state: string;
  display_seq?: string;
}

export interface Item extends List {
  case_table_id_1: string;
  case_table_id_2: string;
  case_table_id_3: string;
}
interface Props {
  isList?: boolean;
  id?: string;
  searchData?: {
    state?: string;
    hot_case_name?: string;
  };
}

const useHomeManageMonthly = (props?: Props) => {
  const { isList, id, searchData } = props ?? {};
  const userData = useSelector((s: any) => s.usersReducer);

  const [list, setList] = useState<List[]>([]);
  const [total, setTotal] = useState(0);

  const [detail, setDetail] = useState<Item>();

  const params = new URLSearchParams(window.location.search);
  const page = params.get("page") || "1";

  const navigate = useNavigate();
  const { pathname } = window.location;

  const getList = async (page: string) => {
    const params = {
      admin_table_id: userData.admin_table_id,
      page: page,
      ...searchData,
    };

    try {
      const res = await APIS.postData("/AdminHotCase/HotCaseList", params, 1);

      if (res.data.code === "OK") {
        setList(res.data.hot_case_list);
        setTotal(res.data.total_cnt);
      } else {
        setList([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (isList) {
      getList(page);
    }
  }, [page]);

  const getTarget = async (): Promise<void> => {
    try {
      const res = await APIS.postData("/AdminHotCase/HotCaseTarget", {
        admin_table_id: userData.admin_table_id,
        hot_case_id: id,
      });

      if (res.data.code === "OK") {
        setDetail(res.data.hot_case_list[0]);
      } else {
        setDetail(undefined);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (id) {
      getTarget();
    }
  }, [id]);

  const search = () => {
    if (page === "1") {
      getList("1");
    } else {
      navigate({
        pathname: pathname,
        search: `?page=${1}`,
      });
    }
  };

  const create = async (data: {
    hot_case_name: string;
    case_table_id_1: string;
    case_table_id_2: string;
    case_table_id_3: string;
  }): Promise<boolean | undefined> => {
    if (
      !data.hot_case_name ||
      !data.case_table_id_1 ||
      !data.case_table_id_2 ||
      !data.case_table_id_3
    ) {
      alert("정보를 모두 입력해주세요.");
      return;
    }

    try {
      const res = await APIS.postData(
        "/AdminHotCase/HotCaseCreate",
        {
          ...data,
          admin_table_id: userData.admin_table_id,
        },
        1
      );

      if (res.data.code === "OK") {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const update = async (
    data: Omit<Item, "update_date">
  ): Promise<boolean | undefined> => {
    if (
      !data.state ||
      !data.hot_case_name ||
      !data.case_table_id_1 ||
      !data.case_table_id_2 ||
      !data.case_table_id_3
    ) {
      alert("모든 항목을 입력해주세요.");
      return;
    }

    try {
      const res = await APIS.postData(
        "/AdminHotCase/HotCaseUpdate",
        {
          ...data,
          admin_table_id: userData.admin_table_id,
        },
        1
      );

      if (res.data.code === "OK") {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const remove = async (ids: string[]): Promise<boolean | undefined> => {
    try {
      if (ids.length > 0) {
        if (window.confirm("해당 월간 인기 시공사례를 삭제 하시겠습니까?")) {
          const res = await APIS.postData(
            "/AdminHotCase/HotCaseDel",
            {
              admin_table_id: userData.admin_table_id,
              hot_case_ids: ids,
            },
            1
          );

          if (res.data.code === "OK") {
            getList(page);

            return true;
          } else {
            return false;
          }
        }
      } else {
        alert("삭제 할 데이터를 선택해주세요.");
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const updateSeq = async (
    data: {
      hot_case_id: string;
      display_seq: string;
    }[]
  ): Promise<boolean | undefined> => {
    if (window.confirm("노출순위를 변경하시겠습니까?")) {
      try {
        const res = await APIS.postData(
          "/AdminHotCase/HotCaseSeqUpdate",
          {
            admin_table_id: userData.admin_table_id,
            hot_case_data: data,
          },
          1
        );

        if (res.data.code === "OK") {
          return true;
        } else {
          return false;
        }
      } catch (err) {
        console.log(err);
        return false;
      }
    }
  };

  return {
    list,
    total,
    page,
    search,
    create,
    update,
    remove,
    updateSeq,
    detail,
  };
};

export default useHomeManageMonthly;
