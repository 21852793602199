import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import * as APIS from "../../utils/service";
import Input from "../../components/Input";
import { openSub, closeSub } from "../../redux/subPopupSlice";

export default function ThemePartner(props) {
  const { value, setValue } = props;
  const dispatch = useDispatch();
  const userData = useSelector((s) => s.usersReducer);

  const [list, setList] = useState(value ? value : []);

  const [addList, setAddList] = useState([]);
  const [step, setStep] = useState(1);

  const [stxId, setStxId] = useState("");
  const [stx, setStx] = useState("");

  const findFunc = () => {
    dispatch(
      openSub({
        title: "파트너 추가",
      })
    );
    setStep(2);
  };
  const searchFunc = () => {
    const sender = {
      admin_table_id: userData.admin_table_id,
      seller_id: stxId,
      seller_biz_name: stx,
    };

    APIS.postData("/AdminTheme/PartnerSrch", sender, 1)
      .then((res) => {
        if (res.data.code === "OK" && res.data.seller_list) {
          setAddList(res.data.seller_list);
        } else {
          setAddList([]);
        }
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };

  const searchCencleFunc = () => {
    dispatch(
      openSub({
        title: "시공파트너",
      })
    );
    setStep(1);
  };
  const searchSucFunc = () => {
    let arr = [];

    const checkboxes = document.getElementsByClassName("seller_list");
    for (let cb of checkboxes) {
      if (cb.checked) {
        arr.push({
          seller_id: cb.dataset.id,
          seller_table_id: cb.value,
          seller_biz_name: cb.dataset.text,
        });
      }
    }
    if (arr.length > 0) setList([...list, ...arr]);
    searchCencleFunc();
  };

  const submitFunc = () => {
    setValue(list);
    dispatch(closeSub());
  };

  const deleteFunc = (id) => {
    setList(list.filter((x) => x.seller_table_id !== id));
  };

  return step === 1 ? (
    <form className="popup_form" style={{ marginTop: "10px" }}>
      <button type="button" className="btn btn3" onClick={() => findFunc()}>
        추가
      </button>

      <div className="theme_partner_list">
        {list.length > 0 &&
          list.map((x, i) => {
            return (
              <p>
                {x.seller_biz_name}
                <button
                  type="button"
                  className="theme_partner_delete"
                  onClick={() => deleteFunc(x.seller_table_id)}
                />
              </p>
            );
          })}
      </div>

      <div className="popup_btn">
        <button
          type="button"
          className="popbtn cencle_btn"
          onClick={() => {
            dispatch(closeSub());
          }}
          style={{ width: "100%", marginRight: "10px" }}
        >
          취소
        </button>
        <button
          type="button"
          className="popbtn ok_btn"
          onClick={() => {
            submitFunc();
          }}
          style={{ width: "100%" }}
        >
          확인
        </button>
      </div>
    </form>
  ) : (
    <form className="popup_form" style={{ marginTop: "10px" }}>
      <Input
        className="input_text"
        type="text"
        placeholder="파트너 ID를 입력해 주세요."
        name="stxId"
        value={stxId}
        setValue={setStxId}
        label="파트너 ID"
      />
      <Input
        className="input_text"
        type="text"
        placeholder="파트너명을 입력해 주세요."
        name="stx"
        value={stx}
        setValue={setStx}
        label="파트너명"
        withButton={"찾기"}
        withButtonPress={searchFunc}
      />

      <div className="theme_partner_find_list">
        {addList.length > 0 &&
          addList.map((x, i) => {
            return (
              <>
                <input
                  type="checkbox"
                  id={"sellerrd" + i}
                  name="sellerrd"
                  className="checkbox seller_list"
                  value={x.seller_table_id}
                  data-text={x.seller_biz_name}
                  data-id={x.seller_id}
                />
                <label htmlFor={"sellerrd" + i}>{x.seller_biz_name}</label>
              </>
            );
          })}
      </div>

      <div className="popup_btn">
        <button
          type="button"
          className="popbtn cencle_btn"
          onClick={() => {
            searchCencleFunc();
          }}
          style={{ width: "100%", marginRight: "10px" }}
        >
          취소
        </button>
        <button
          type="button"
          className="popbtn ok_btn"
          onClick={() => {
            searchSucFunc();
          }}
          style={{ width: "100%" }}
        >
          확인
        </button>
      </div>
    </form>
  );
}
