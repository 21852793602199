/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";

import * as APIS from "../../utils/service";
import { regEmail, findJson } from "../../utils/utils";

import moment from "moment";

import Input from "../../components/Input";
import InputSelect from "../../components/InputSelect";
import TextArea from "../../components/TextArea";
import InputFile from "../../components/Common/Upload/Upload";
import Select from "../../components/Common/Select/Select";
import Area from "../register/Area";
import PartnerMembershipPop from "./PartnerMembershipPop";
import PayMembership from "../my/PayMembership";
import NewInput from "../../components/Common/Input/Input";
import consts from "../../libs/consts";

import { open, close } from "../../redux/popupSlice";
import { openSub } from "../../redux/subPopupSlice";
import AdminLayout from "../../components/Layout/admin";
import { css } from "@emotion/react";
import Preview from "../../components/Preview";
import debounce from "lodash/debounce";

export default function PartnerDetail() {
  let { vidx } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((s) => s.usersReducer);
  const popup = useSelector((s) => s.popupReducer);

  const [cker, setCker] = useState("");

  /* 파트너 정보 */
  const [type, setType] = useState("");
  const [typeSelect, setTypeSelect] = useState("");
  const [sellerState, setSellerState] = useState("");
  const [sellerStateSelect, setSellerStateSelect] = useState("");

  const [comp, setComp] = useState("");
  const [bn, setBn] = useState("");
  const [hp, setHp] = useState("");
  const [name, setName] = useState("");
  const [addr, setAddr] = useState("");
  const [addrDetail, setAddrDetail] = useState("");
  const [email, setEmail] = useState("");
  const [logo, setLogo] = useState("");
  const [is_icon_del, setis_icon_del] = useState(0);

  const range = "전체";

  const [range1, setRange1] = useState("");
  const [range2, setRange2] = useState("");

  const [area, setArea] = useState("");
  const [areaText, setAreaText] = useState("");
  const [spec, setSpec] = useState("");
  const [as, setAs] = useState("");
  const [license, setLicense] = useState("");
  const [comment, setComment] = useState("");

  /* 멤버십 정보 */
  const [membership, setMembership] = useState("");
  const [paySelect, setPaySelect] = useState("1");

  /* 계정 정보 */
  const [id, setId] = useState("");
  const [kakao, setKakao] = useState("");
  const [hpAcc, setHpAcc] = useState("");
  const [pw, setPw] = useState("");
  const [repw, setRePw] = useState("");
  const [disable, setDisable] = useState(true);
  const [state, setState] = useState("");
  const [stateSelect, setStateSelect] = useState("");

  const [agree, setAgree] = useState(
    "미동의 (" + moment().format("YYYY.MM.DD") + ")"
  );
  const [serve, setServe] = useState([]);

  const [error, setError] = useState("");
  const [error2, setError2] = useState("");
  const [error3, setError3] = useState("");
  const [error4, setError4] = useState("");
  const [error5, setError5] = useState("");
  const [error6, setError6] = useState("");
  const [error7] = useState("");
  const [error8, setError8] = useState("");
  const [error9, setError9] = useState("");
  const [success9, setSuccess9] = useState("");
  const [isPartnerIntroduce, setIsPartnerIntroduce] = useState(false);
  const [sellerIntroduceText, setSellerIntroduceText] = useState("");

  const [payoption] = useState([
    { idx: "1", title: "계좌이체" },
    { idx: "4", title: "CMS결제" },
  ]);
  const [stateoption] = useState([
    { idx: "1", title: "정상" },
    { idx: "2", title: "대기" },
  ]);

  useEffect(() => {
    dataFunc();
  }, [popup.open]);

  useEffect(() => {
    if (cker === "1") {
      const sender = {
        admin_table_id: userData.admin_table_id,
        seller_table_id: vidx,
        vip_grade: typeSelect,
      };
      APIS.postData("/Admin/ChgVipGrade", sender, 1)
        .then((res) => {
          setType(findJson(consts.settingFixoption, typeSelect));
        })
        .catch((e) => {
          console.log("catch", e);
        });
    } else if (cker === "2") {
      const sender = {
        admin_table_id: userData.admin_table_id,
        seller_table_id: vidx,
        type: paySelect,
      };

      APIS.postData("/AdminMembership/ChgPayType", sender, 1)
        .then(() => {
          dataFunc();
        })
        .catch((e) => {
          console.log("catch", e);
        });
    } else if (cker === "3") {
      const sender = {
        admin_table_id: userData.admin_table_id,
        seller_table_id: vidx,
        seller_state: sellerStateSelect,
      };

      APIS.postData("/Admin/ChgState", sender, 1)
        .then(() => {
          setSellerState(findJson(consts.stateoption, sellerStateSelect));
        })
        .catch((e) => {
          console.log("catch", e);
        });
    } else if (cker === "4") {
      const sender = {
        admin_table_id: userData.admin_table_id,
        seller_table_id: vidx,
        service_state: stateSelect,
      };

      APIS.postData("/Admin/ChgServiceState", sender, 1)
        .then(() => {
          setState(stateSelect === "1" ? "정상" : "대기");
        })
        .catch((e) => {
          console.log("catch", e);
        });
    }

    setCker("");
  }, [cker]);

  useEffect(() => {
    if (range === "전체") {
      const checkboxes = document.getElementsByClassName("rangeDetail");
      for (let cb of checkboxes) {
        cb.checked = false;
      }
    }
  }, [range]);

  useEffect(() => {
    if (!pw || !repw) {
      setError9("");
      setSuccess9("");
      setDisable(true);
      return;
    }

    if (pw !== repw) {
      setError9("비밀번호가 일치하지 않습니다.");
      setSuccess9("");
      setDisable(true);
    } else {
      setError9("");
      setSuccess9("올바른 비밀번호 입니다.");
      setDisable(false);
    }
  }, [pw, repw]);

  const dataFunc = () => {
    const sender = {
      admin_table_id: userData.admin_table_id,
      seller_table_id: vidx,
    };

    APIS.postData("/Admin/SellerInfo", sender, 1)
      .then((res) => {
        if (res.data.seller_table_id) {
          setSellerState(findJson(consts.stateoption, res.data.seller_state));
          setSellerStateSelect(res.data.seller_state);

          setId(res.data.seller_id);
          setKakao(res.data.kakao_auth_key);

          setComp(res.data.seller_biz_name);
          setBn(res.data.seller_biz_no);
          setHp(res.data.seller_phone);
          setName(res.data.seller_name);
          setAddr(res.data.seller_addr);
          setAddrDetail(res.data.seller_addr_detail);
          setEmail(res.data.seller_email);
          setLogo(res.data.seller_icon);
          setRange1(res.data.work_kind[0]);
          setRange2(res.data.work_kind[1]);
          setArea(res.data.seller_area);
          setAreaText(
            res.data.seller_area_text
              ? res.data.seller_area_text.join(", ")
              : ""
          );
          setSpec(res.data.seller_career);
          setServe(res.data.seller_service);
          setAs(res.data.seller_as);
          setLicense(res.data.seller_license_yn);
          setComment(res.data.seller_desc);
          setAgree(
            res.data.market_agree === "1"
              ? "동의 (" +
                  moment(res.data.market_agree_date).format("YYYY.MM.DD") +
                  ")"
              : "미동의"
          );
          setState(res.data.service_state === "1" ? "정상" : "대기");
          setStateSelect(res.data.service_state);
          setHpAcc(res.data.seller_phone);

          setType(findJson(consts.settingFixoption, res.data.vip_grade));
          setTypeSelect(res.data.vip_grade);

          setMembership(res.data.member);
          setPaySelect(
            res.data.member.pay_type ? res.data.member.pay_type : "1"
          );
          setSellerIntroduceText(res.data.seller_intro || "");

          if (res.data.seller_intro) {
            setIsPartnerIntroduce(
              !consts.sellerIntroduceOption.find(
                (item) => item.value === res.data.seller_intro
              )
            );
          }
        } else {
          dispatch(
            open({
              message: "파트너 정보가 없습니다.",
              button: "확인",
              onPress: () => navigate(-1),
            })
          );
        }
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };

  const areaFunc = () => {
    dispatch(
      openSub({
        title: "시공가능지역선택",
        component: (
          <Area value={area} setValue={setArea} setValueText={setAreaText} />
        ),
      })
    );
  };

  const pwAlert = () => {
    if (disable) return;

    if (pw !== repw || pw.length < 8) {
      setError9("비밀번호를 확인해 주세요.");
      return;
    }

    dispatch(
      open({
        message: "저장 하시겠습니까?",
        button: "확인",
        buttonCencle: "취소",
        onPress: () => pwFunc(),
      })
    );
  };

  const pwFunc = () => {
    /* 비밀번호 저장처리 */
    const sender = {
      admin_table_id: userData.admin_table_id,
      seller_table_id: vidx,
      seller_passwd: pw,
    };

    APIS.postData("/Admin/ChgPwd", sender, 1)
      .then(() => {
        dispatch(
          open({
            message: "비밀번호가 변경 되었습니다.",
            button: "확인",
          })
        );
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };

  const typeAlert = () => {
    dispatch(
      open({
        title: "파트너 유형",
        message:
          "유형에 따라 결제수수료,  정보제공 무료 제공 등 각종 혜택\n이 제공됩니다.(유형에 따른 혜택은 설정 메뉴에서 관리)",
        component: (
          <InputSelect
            name="typeSelect"
            label="유형"
            value={typeSelect}
            setValue={setTypeSelect}
            option={consts.settingFixoption.filter((item) => item.idx !== "0")}
          />
        ),
        one: true,
        button: "변경",
        buttonCencle: "취소",
        onPress: () => setCker("1"),
      })
    );
  };

  const sellerStateAlert = () => {
    dispatch(
      open({
        title: "파트너 상태",
        message: "파트너의 상태에 따라 서비스 제공이 제한 될 수 있습니다.",
        component: (
          <InputSelect
            name="sellerStateSelect"
            label="상태"
            value={sellerStateSelect}
            setValue={setSellerStateSelect}
            option={consts.stateoption}
            errorFix2={true}
          />
        ),
        one: true,
        button: "변경",
        buttonCencle: "취소",
        onPress: () => setCker("3"),
      })
    );
  };

  const payAlert = () => {
    dispatch(
      open({
        title: "결제방식 변경",
        component: (
          <InputSelect
            name="paySelect"
            label="결제방식"
            value={paySelect}
            setValue={setPaySelect}
            option={payoption}
          />
        ),
        one: true,
        button: "변경",
        buttonCencle: "취소",
        onPress: () => setCker("2"),
      })
    );
  };

  const memberAlert = (type, ck) => {
    if (type === 1) {
      dispatch(
        open({
          title: "정액제 기간 변경",
          component: <PartnerMembershipPop idx={vidx} type={type} />,
        })
      );
    } else {
      dispatch(
        open({
          title: `${ck === 1 ? "정액제" : "정량제"} 수량 변경`,
          component: <PartnerMembershipPop idx={vidx} Ztype={type} ck={ck} />,
        })
      );
    }
  };

  const stateAlert = () => {
    dispatch(
      open({
        title: "상태 변경",
        message: "고객상담콜을 받을 수 있는 상태 여부를 설정할 수 있습니다.",
        component: (
          <InputSelect
            name="stateSelect"
            label="상태"
            value={stateSelect}
            setValue={setStateSelect}
            option={stateoption}
            errorFix={true}
          />
        ),
        one: true,
        button: "변경",
        buttonCencle: "취소",
        onPress: () => setCker("4"),
      })
    );
  };

  const membershipAlert = () => {
    dispatch(
      open({
        title: "멤버십 구매",
        mideum: true,
        component: <PayMembership idx={vidx} />,
      })
    );
  };

  const agreePop = (title, comp, msg) => {
    dispatch(
      open({
        title: title,
        message: msg,
        component: comp,
      })
    );
  };

  const setServeFunc = () => {
    let sv = [];
    // consts.serveoption
    const checkboxes = document.getElementsByClassName("serve");
    for (let cb of checkboxes) {
      if (cb.checked) {
        const findItem = consts.serveoption.find(
          (item) => item.title === cb.value
        ).idx;

        sv.push(findItem);
      }
    }
    setServe(sv);
  };

  const submitAlert = () => {
    /* 필수정보 미입력 확인 */
    if (
      !addr ||
      !area ||
      !spec ||
      !serve ||
      !as ||
      sellerIntroduceText === ""
    ) {
      dispatch(
        open({
          message: "필수 정보를 입력 해 주시기 바랍니다.",
          messageAuto: true,
          button: "확인",
        })
      );
      return;
    }

    if (email && !regEmail.test(email)) {
      dispatch(
        open({
          message: "이메일 형식을 확인해 주세요.",
          button: "확인",
        })
      );
      return;
    }

    dispatch(
      open({
        message: "저장 하시겠습니까?",
        button: "저장",
        buttonCencle: "취소",
        onPress: submitFunc,
      })
    );
  };
  const getImage = (img) => {
    if (!img) {
      return "";
    }

    const isImage = img.includes("data:image");
    return isImage ? img : "";
  };

  const submitFunc = () => {
    /* 회원정보 저장후 새로고침 */

    const sender = {
      admin_table_id: userData.admin_table_id,
      seller_table_id: vidx,
      seller_license_yn: license,
      seller_email: email,
      seller_addr: addr,
      seller_addr_detail: addrDetail,
      seller_icon: getImage(logo),
      seller_career: spec,
      seller_service: serve,
      seller_as: as,
      seller_desc: comment,
      work_kind: [range1 ? 1 : 0, range2 ? 1 : 0],
      seller_area: area,
      seller_area_text: areaText.split(", "),
      is_icon_del: is_icon_del,
      seller_intro: sellerIntroduceText,
    };

    APIS.postData("/Admin/SellerUpdate", sender, 1)
      .then((res) => {
        if (res.data.code === "OK") {
          dataFunc();
          dispatch(
            open({
              message: "수정이 완료되었습니다.",
              button: "확인",
              onPress: () => navigate(-1),
            })
          );
        } else {
          dispatch(
            open({
              message: "잠시 후 다시 시도해주세요.",
              button: "확인",
            })
          );
        }
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };

  const AgreeRender = () => {
    return (
      <div className="register_body popup_register_body">
        <div
          className="clause_box"
          dangerouslySetInnerHTML={{ __html: "마케팅 정보 수신 동의" }}
        ></div>
        <input
          type="checkbox"
          id="chk3"
          className="checkbox"
          defaultChecked={agree !== "미동의"}
        />
        <label htmlFor="chk3">마케팅 정보 수신 동의</label>
        <div className="register_btn_box">
          <button
            type="button"
            className="btn btn3"
            onClick={() => dispatch(close())}
          >
            취소
          </button>
          <button
            type="button"
            className="btn btn1"
            onClick={() => agreeFunc()}
          >
            변경
          </button>
        </div>
      </div>
    );
  };

  const agreeFunc = () => {
    /* 마케팅 정보수신 동의처리 하기 */
    const chk = document.getElementById("chk3").checked;

    const sender = {
      seller_table_id: vidx,
      market_agree: chk ? 1 : 0,
    };

    APIS.postData("/SellerInfo/MarketAgree", sender, 2)
      .then((res) => {
        setAgree(
          chk ? "동의 (" + moment().format("YYYY.MM.DD") + ")" : "미동의"
        );
        dispatch(close());
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };

  const checkIconDelete = () => {
    if (logo) {
      const isLogo = getImage(logo);

      if (!isLogo) {
        setis_icon_del(1);
      }
    }
  };

  const changeFileHandler = debounce((file) => {
    checkIconDelete();

    setLogo(file.fileImage);
  }, 200);

  const deletePreviewHandler = () => {
    checkIconDelete();

    setLogo("");
  };

  return (
    <AdminLayout>
      <div
        css={css`
          margin: 0 -30px -30px;
        `}
      >
        <div className="page_title_box page_title_box1">
          <p className="title">
            <button
              type="button"
              className="goBackBtn"
              onClick={() => navigate(-1)}
            ></button>
            파트너번호 {vidx} {error7}
          </p>
        </div>

        <div className="content_section admin_content_section">
          <div className="form_section ">
            <p className="title">
              파트너 정보<span className="require_msg">* 필수정보 입니다.</span>
            </p>

            <div className="form_list">
              <Input
                className="input_text"
                type="text"
                placeholder="변경을 클릭 해 주세요."
                name=""
                value={type}
                label="*파트너유형"
                readOnly={true}
                withButton={"변경"}
                withButtonPress={() => typeAlert()}
              />
              <Input
                className="input_text"
                type="text"
                placeholder="변경을 클릭 해 주세요."
                name=""
                value={sellerState}
                label="*파트너상태"
                readOnly={true}
                withButton={"변경"}
                withButtonPress={() => sellerStateAlert()}
              />

              <Input
                className="input_text"
                type="text"
                placeholder="업체명을 입력해 주세요."
                name="comp"
                value={comp}
                setValue={setComp}
                label="*업체명"
                error={error}
                setError={setError}
                readOnly={true}
              />

              <Input
                className="input_text"
                type="text"
                placeholder="숫자만 입력해 주세요."
                name="bn"
                value={bn}
                setValue={setBn}
                label="*사업자등록번호"
                error={error2}
                valid={"num"}
                setError={setError2}
                readOnly={true}
              />
              <Input
                className="input_text"
                type="text"
                placeholder="영문/숫자만 입력해 주세요."
                name="hp"
                value={hp}
                label="*휴대폰번호"
                readOnly={true}
              />

              <Input
                className="input_text"
                type="text"
                placeholder="영문/숫자만 입력해 주세요."
                name="name"
                value={name}
                label="*대표자명"
                readOnly={true}
              />
              <Input
                className="input_text"
                type="text"
                placeholder="이메일을 입력해 주세요."
                name="email"
                value={email}
                setValue={setEmail}
                label="이메일"
                error={error3}
                setError={setError3}
                maxlength={50}
                valid={"email"}
              />

              <Input
                className="input_text"
                type="text"
                placeholder="주소 찾기를 해 주세요."
                name="addr"
                value={addr}
                setValue={setAddr}
                label="*주소"
                withButton={"주소찾기"}
              />
              <Input
                className="input_text"
                type="text"
                placeholder="상세주소를 입력해 주세요."
                name="addrDetail"
                value={addrDetail}
                setValue={setAddrDetail}
                label="*상세주소"
              />
            </div>
          </div>

          <div className="form_section">
            <p className="title">시공 정보</p>
            <div className="form_list">
              <div className="checkbox_section">
                <label className="input_label">*시공범위</label>
                <div>
                  <input
                    type="checkbox"
                    id="rachk4"
                    className="checkbox"
                    checked={range1}
                    onChange={(e) => setRange1(e.target.checked)}
                  />
                  <label htmlFor="rachk4">전체</label>
                  <input
                    type="checkbox"
                    id="rachk5"
                    className="checkbox"
                    checked={range2}
                    onChange={(e) => setRange2(e.target.checked)}
                  />
                  <label htmlFor="rachk5">부분</label>
                </div>
              </div>

              <Input
                className="input_text"
                type="text"
                placeholder="지역 선택을 해 주세요."
                name="area"
                value={areaText}
                setValue={setAreaText}
                label="*시공가능지역"
                error={error4}
                withButton={"지역선택"}
                withButtonPress={areaFunc}
                setError={setError4}
                maxlength={5000}
              />
              <Input
                className="input_text"
                type="text"
                placeholder="경력 입력"
                name="spec"
                value={spec}
                setValue={setSpec}
                label="경력"
                withText={"년"}
                maxlength={2}
                valid={"num"}
                error={error5}
                setError={setError5}
              />

              <TextArea
                className="input_textarea"
                placeholder="파트너사의 업력 및 시공 전문분야를 잘표현할 수 있는 내용을 입력해 주세요. (500자 이내)"
                name="comment"
                value={comment}
                setValue={setComment}
                label="설명"
                error={error6}
                setError={setError6}
              />

              <div className="input_check_box input_check_box_long">
                <p className="input_label">제공서비스</p>
                <div>
                  {consts.serveoption.map((x, i) => {
                    return (
                      <Fragment key={i}>
                        <input
                          type="checkbox"
                          id={"servechk" + i}
                          className="checkbox2 serve"
                          value={x.title}
                          checked={serve.includes(x.idx)}
                          onChange={() => setServeFunc()}
                        />
                        <label htmlFor={"servechk" + i}>{x.title}</label>
                      </Fragment>
                    );
                  })}
                </div>
              </div>

              <InputSelect
                name="as"
                label="*A/S보증"
                value={as}
                setValue={setAs}
                option={consts.asoption}
              />

              <div className="checkbox_section">
                <label className="input_label">실내건축업면허</label>
                <div>
                  <input
                    type="radio"
                    id="rd3"
                    name="license"
                    className="radio"
                    value="1"
                    checked={license === "1"}
                    onChange={(e) => setLicense(e.target.value)}
                  />
                  <label htmlFor="rd3">보유</label>
                  <input
                    type="radio"
                    id="rd4"
                    name="license"
                    className="radio"
                    value="0"
                    checked={license === "0"}
                    onChange={(e) => setLicense(e.target.value)}
                  />
                  <label htmlFor="rd4">미보유</label>
                </div>
              </div>
              <div
                css={css`
                  display: flex;
                  gap: 23px;
                `}
              >
                <InputFile
                  label="판매자 로고"
                  id="seller_icon"
                  preview={logo || ""}
                  deletePreivew={deletePreviewHandler}
                  callback={changeFileHandler}
                  resize={1}
                />
                <div>
                  <Select
                    label="* 파트너 소개 문장"
                    defaultValue="선택"
                    value={
                      sellerIntroduceText === ""
                        ? ""
                        : consts.sellerIntroduceOption.find(
                            (option) => option.value === sellerIntroduceText
                          )
                        ? sellerIntroduceText
                        : "text"
                    }
                    options={consts.sellerIntroduceOption}
                    onChange={(e) => {
                      if (e.target.value === "text") {
                        setIsPartnerIntroduce(true);
                        setSellerIntroduceText("");
                      } else {
                        setIsPartnerIntroduce(false);
                        setSellerIntroduceText(e.target.value);
                      }
                    }}
                  />
                  {isPartnerIntroduce && (
                    <div
                      css={css`
                        margin-top: 10px;
                      `}
                    >
                      <NewInput
                        placeholder="직접 입력(공백 포함 15자 이내)"
                        maxLength={15}
                        value={sellerIntroduceText}
                        onChange={(e) => setSellerIntroduceText(e.target.value)}
                      />
                    </div>
                  )}
                  <p
                    css={css`
                      display: flex;
                      justify-content: end;
                      margin-top: 15px;
                    `}
                  >
                    어디에 노출되나요
                    <p
                      css={css`
                        cursor: pointer;
                        margin-left: 10px;
                        border: 1px solid;
                        width: 18px;
                        height: 18px;
                        line-height: 18px;
                        text-align: center;
                        border-radius: 50%;
                        font-size: 12px;
                        position: relative;

                        & > div {
                          display: none;
                        }

                        &:hover {
                          & > div {
                            display: block;
                          }
                        }
                      `}
                    >
                      ?
                      <Preview />
                    </p>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="form_section">
            <p className="title">멤버십 정보</p>
            <div className="form_list">
              <ul className="list_style4">
                <li>
                  <p className="title">멤버십 구매상태</p>
                  <p className="content">
                    결제완료
                    <button type="button" onClick={() => membershipAlert()}>
                      멤버십 구매
                    </button>
                  </p>
                </li>
                <li>
                  <p className="title">정액제 등급</p>
                  <p className="content">
                    {membership.fixed_term_grade
                      ? membership.fixed_term_grade
                      : "-"}
                  </p>
                </li>
                <li>
                  <p className="title">정액제 수량</p>
                  <p className="content">
                    {membership.available_count}/{membership.max_count}
                    <button type="button" onClick={() => memberAlert(2, 1)}>
                      변경
                    </button>
                  </p>
                </li>
                <li>
                  <p className="title">정액제 기간</p>
                  {/* 기간만료시 cl_4 클래스 추가 */}
                  <p className="content">
                    {moment(membership.fixed_term_start).format("YYYY.MM.DD")}~
                    {moment(membership.fixed_term_end).format("YYYY.MM.DD")}
                    <button type="button" onClick={() => memberAlert(1)}>
                      변경
                    </button>
                  </p>
                </li>
                <li>
                  <p className="title">정량제 수량</p>
                  <p className="content">
                    {membership.period_cnt}
                    <button type="button" onClick={() => memberAlert(2, 2)}>
                      변경
                    </button>
                  </p>
                </li>
                <li>
                  <p className="title">결제 방식</p>
                  <p className="content">
                    {membership.pay_type === "1" ? "계좌이체" : "CMS결제"}
                    {membership.pay_type_chg_date
                      ? ` (${moment(membership.pay_type_chg_date).format(
                          "YYYY.MM.DD"
                        )}, ${
                          membership.pay_type_chg_owner !== "0" ? "관리자" : id
                        })`
                      : ""}
                    <button type="button" onClick={() => payAlert()}>
                      변경
                    </button>
                  </p>
                </li>
              </ul>
            </div>
          </div>

          <div className="form_section">
            <p className="title">계정 정보</p>
            <div className="form_list">
              <Input
                className="input_text"
                type="text"
                placeholder="영문/숫자만 입력해 주세요."
                name="kakao"
                value={kakao}
                label="카카오연동"
                readOnly={true}
              />
              <Input
                className="input_text"
                type="text"
                placeholder="영문/숫자만 입력해 주세요."
                name="hpAcc"
                value={hpAcc}
                label="휴대폰번호"
                readOnly={true}
              />

              <Input
                className="input_text"
                type="text"
                placeholder="영문/숫자만 입력해 주세요."
                name="id"
                value={id}
                label="아이디"
                readOnly={true}
              />
              <Input
                className="input_text"
                type="text"
                placeholder="변경을 클릭 해 주세요."
                name="state"
                value={state}
                label="상태"
                readOnly={true}
                withButton={"변경"}
                withButtonPress={stateAlert}
              />

              <Input
                className="input_text"
                type="password"
                placeholder="영문+숫자+특수문자 조합 (최소 8자~15자)"
                name="pw"
                value={pw}
                setValue={setPw}
                label="비밀번호"
                error={error8}
                setError={setError8}
                valid={"pw"}
                maxlength={15}
                withButton={"변경"}
                withButtonPress={() => pwAlert()}
              />
              <Input
                className="input_text"
                type="password"
                placeholder="영문+숫자+특수문자 조합 (최소 8자~15자)"
                name="repw"
                value={repw}
                setValue={setRePw}
                label="비밀번호 확인"
                error={error9}
                success={success9}
                valid={"pw"}
                maxlength={15}
              />

              <Input
                className="input_text"
                type="text"
                placeholder="영문/숫자만 입력해 주세요."
                name="agree"
                value={agree}
                label="마케팅 정보수신 동의"
                readOnly={true}
                withButton={"변경하기"}
                withButtonPress={() =>
                  agreePop("마케팅 정보수신 동의", <AgreeRender />)
                }
              />
            </div>
          </div>
        </div>

        <div className="my_btn_box">
          <button
            type="button"
            className="btn btn3"
            onClick={() => navigate(-1)}
          >
            취소
          </button>
          <button
            type="button"
            className="btn btn1"
            onClick={() => submitAlert()}
          >
            수정
          </button>
        </div>
      </div>
    </AdminLayout>
  );
}
