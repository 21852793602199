import React from "react";
import { css } from "@emotion/react";
import Input from "../../../Common/Input/Input";
import Upload from "../../../Common/Upload/Upload";
import DateRange from "../../../Common/Date/DateRange";
import Select from "../../../Common/Select/Select";
import { BasicInfoType, URLTypes } from "../noticeEvent.type";
import debounce from "lodash/debounce";
import moment from "moment";
import consts from "../../../../libs/consts";

const marginTopStyles = css`
  margin-top: 20px;
`;

interface Props {
  data: BasicInfoType;
  setData: React.Dispatch<React.SetStateAction<BasicInfoType>>;
  urlList: URLTypes[];
}

const BasicInfo = (props: Props) => {
  const { data, setData, urlList } = props;

  const changeDateHandler = (type: string, date?: Date) => {
    if (date) {
      setData({
        ...data,
        ...(type === "start" && {
          avt_start_date: moment(date).format("YYYY-MM-DD 00:00:00"),
        }),
        ...(type === "end" && {
          avt_end_date: moment(date).format("YYYY-MM-DD 23:59:59"),
        }),
      });
    }
  };

  const changeFileHandler = debounce(({ fileImage }) => {
    setData({
      ...data,
      avt_thumbnail: fileImage,
    });
  }, 200);

  const changeSelectHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    const findItem = urlList.find((item) => item.url_table_id === value);

    if (findItem) {
      setData((prevData) => ({
        ...prevData,
        seller_table_id: findItem.seller_table_id,
        seller_biz_name: findItem.seller_biz_name,
        url_table_id: findItem.url_table_id,
        avt_title: findItem.url_title,
      }));
    }
  };

  return (
    <div>
      <div
        css={css`
          margin-top: 20px;
          display: flex;
          justify-content: space-between;
        `}
      >
        <div>
          <div>
            <Select
              label="* 타이틀 (최대 20자 공백포함)"
              size="big"
              defaultValue="타이틀을 선택하세요."
              options={urlList.map((item) => ({
                value: item.url_table_id,
                label: item.url_title,
              }))}
              onChange={changeSelectHandler}
            />
          </div>
          <div css={[marginTopStyles]}>
            <DateRange
              label="* 운영 기간"
              onChange={changeDateHandler}
              startValue={
                data.avt_start_date ? new Date(data.avt_start_date) : ""
              }
              endValue={data.avt_end_date ? new Date(data.avt_end_date) : ""}
              startPlaceholder="시작일"
              endPlaceholder="종료일"
              size="full"
            />
          </div>
          <div css={[marginTopStyles]}>
            <Input
              label="* 파트너 명"
              value={data.seller_biz_name}
              disabled
              size="big"
            />
          </div>
        </div>
        <div>
          <div>
            <Upload
              label="* 썸네일"
              id="thumb"
              placeholder="사진을 첨부해주세요."
              size="big"
              callback={changeFileHandler}
            />
          </div>
          <div css={[marginTopStyles]}>
            <Input
              label="* 상세 페이지 URL"
              placeholder="https://www.layhome.co.kr/event/28"
              size="big"
              value={`${consts.apiBaseUrl}/event/${data.url_table_id}`}
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicInfo;
