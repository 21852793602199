import { createSlice } from '@reduxjs/toolkit';

const popupSlice = createSlice({
  name: 'popup',
  initialState: {
    open: false,
    title: "알림",
    subtitle: "",
    title_estimate: "이 스타일 견적 받기",
    titleLong: "",
    button: "확인",
    buttonCencle: "",
    onCancelPress: "",
    onCancelPressAlert: "",
    onPress: "",
    onSubmit: "",
    message: "",
    component: "",
    subMessage: "",
    subComponent: "",
    messageAuto: "",
    wide: false,
    wideOne: false,
    slideWide: false,
    certification: false,
    today: false,
    filter: false,
    constInfo: false,
    mideum: false,
    suc: false,
    one: false,
    overFlow: false,
    isNum : false,
    reduxNumPage:1,
  },
  
  reducers: {
    open(state, action) {
        state.open = true;
        state.onCancelPress = action.payload.onCancelPress;
        state.onPress = action.payload.onPress;
        state.message = action.payload.message;

        if(action.payload.onCancelPressAlert) state.onCancelPressAlert = action.payload.onCancelPressAlert;

        if(action.payload.title) state.title = action.payload.title;
        if(action.payload.button) state.button = action.payload.button;
        if(action.payload.buttonCencle) state.buttonCencle = action.payload.buttonCencle;

        if(action.payload.component) state.component = action.payload.component;
        if(action.payload.titleLong) state.titleLong = action.payload.titleLong;
        if(action.payload.subMessage) state.subMessage = action.payload.subMessage;
        if(action.payload.subComponent) state.subComponent = action.payload.subComponent;
        if(action.payload.messageAuto) state.messageAuto = action.payload.messageAuto;
        if(action.payload.wide) state.wide = action.payload.wide;
        if(action.payload.wideOne) state.wideOne = action.payload.wideOne;
        if(action.payload.slideWide) state.slideWide = action.payload.slideWide;
        if(action.payload.certification) state.certification = action.payload.certification;
        if(action.payload.filter) state.filter = action.payload.filter;
        if(action.payload.constInfo) state.constInfo = action.payload.constInfo;
        if(action.payload.today) state.today = action.payload.today;
        if(action.payload.mideum) state.mideum = action.payload.mideum;
        if(action.payload.suc) state.suc = action.payload.suc;
        if(action.payload.one) state.one = action.payload.one;
        if(action.payload.onSubmit) state.onSubmit = action.payload.onSubmit;
        
        if(action.payload.overFlow) state.overFlow = action.payload.overFlow;
        if(action.payload.isNum) state.isNum = action.payload.isNum;
       
    },
    close(state) {
        state.open = false;
        state.title = "알림";
        state.subtitle = "";
        state.onCancelPress = "";
        state.onPress = "";
        state.onCancelPressAlert = "";
        state.button = "확인";
        state.buttonCencle = "";
        state.message = "";
        state.component = "";
        state.titleLong = "";
        state.subMessage = "";
        state.subComponent = "";
        state.messageAuto = "";
        state.wide = false;
        state.wideOne = false;
        state.slideWide = false;
        state.certification = false;
        state.filter = false;
        state.constInfo = false;
        state.today = false;
        state.mideum = false;
        state.suc = false;
        state.one = false;
        state.onSubmit = "";
        state.overFlow = false;
        state.isNum = false;
        state.reduxNumPage = 1;
        
    },
    handleReduxPage(state, action) {
     
      state.reduxNumPage = action.payload.reduxNumPage;
    },
  },
});

export const {
    open,
    close,
    handleReduxPage,
} = popupSlice.actions;

export default popupSlice.reducer;
