import React from "react";

import moment from "moment";
import { numFormat, findJson, findJsonCl } from "../../utils/utils";
import consts from "../../libs/consts";

export default function ListM(props) {
  const { x, onPress } = props;

  return (
    <div
      className={"shadowBox estimate_shadowBox"}
      style={{ cursor: "pointer" }}
      onClick={() => onPress && onPress()}
    >
      <button type="button" className="right_btn" />

      <div className="badge_box">
        <span className={x.lange === "전체" ? "cl_8" : "cl_9"}>
          {x.work_type}
        </span>
        <span className={findJsonCl(consts.sellerOrderState, x.order_state)}>
          {findJson(consts.sellerOrderState, x.order_state)}
        </span>
      </div>

      <p className="title">
        {x.order_home_name}({x.order_size}평)
      </p>

      <ul className="list_style1">
        <li>
          <p className="title">시공지역</p>
          <p className="content">{x.area_name}</p>
        </li>
        <li>
          <p className="title">희망가격</p>
          <p className="content">{numFormat(x.order_desired_price)}만원</p>
        </li>
        <li>
          <p className="title">희망시공일</p>

          <p className="content">
            {x.order_desired_date === "0000-00-00 00:00:00" ||
            x.order_desired_date === null
              ? "미정"
              : moment(x.order_desired_date).format("YYYY.MM.DD")}
          </p>
        </li>
      </ul>
    </div>
  );
}
