import React from "react";
import AdminLayout from "../../components/Layout/admin";
import Member from "../../components/Member/List";

const MemberPage = () => {
  return (
    <AdminLayout title="회원 관리">
      < Member />
    </AdminLayout>
  );
};

export default MemberPage;
