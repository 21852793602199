/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import * as APIS from "../../utils/service";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export interface List {
  user_table_id: string;
  user_id: string;
  kakao_auth_key: string;
  user_name: string;
  user_type: string;
  user_phone: string;
  user_state: string;
  reg_date: string;
}

export interface Target {
  user_table_id: string;
  user_id: string;
  kakao_auth_key: string;
  user_name: string;
  user_type: string;
  user_phone: string;
  user_state: string;
  gender: string;
  market_agree: string;
  user_birth: string;
}

interface Props {
  searchData?: {
    start_date?: string;
    end_date?: string;
    user_type?: string;
    user_state?: string;
    type?: string;
    text?: string;
  }
  id?: string;
}

const useMember = (props: Props) => {
  const { id, searchData } = props ?? {};
  const userData = useSelector((s: any) => s.usersReducer);
  const navigate = useNavigate();

  const [list, setList] = useState<List[]>([]);
  const [target, setTarget] = useState<Target>();
  const [total, setTotal] = useState(0);

  const params = new URLSearchParams(window.location.search);
  const page = params.get("page") || "1";

  const { pathname } = window.location;

  const getList = async (page: string) => {
    const params = {
      admin_table_id: userData.admin_table_id,
      page: page,
      ...searchData,
    };

    try {
      const res = await APIS.postData("/UserInfo/MemberList", params, 1);
      if (res.data.code === "OK") {
        setList(res.data.member_list);
        setTotal(res.data.total_cnt);
      } else {
        setList([]);
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const search = () => {
    if (page === "1") {
      getList("1");
    } else {
      navigate({
        pathname: pathname,
        search: `?page=${1}`,
      });
    }
  };

  useEffect(() => {
    getList(page);
  }, [page]);


  const getTarget = async (): Promise<void> => {
    try {
      const res = await APIS.postData("/UserInfo/MemberTarget", {
        admin_table_id: userData.admin_table_id,
        user_table_id: id,
      });

      if (res.data.code === "OK") {
        res.data.user_birth =
          res.data.birth_year + '-' +
          res.data.birth_month + '-' +
          res.data.birth_day
        setTarget(res.data);
      } else {
        setTarget(undefined);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (id) {
      getTarget();
    }
  }, [id]);


  const update = async (
    data: Omit<Target, "user_id" | "kakao_auth_key" | "user_type">
  ): Promise<boolean | undefined> => {
    if (
      !data.user_name ||
      !data.user_phone ||
      !data.user_state ||
      !data.user_birth ||
      !data.gender ||
      !data.market_agree
    ) {
      alert("모든 항목을 입력해주세요.");
      return;
    }

    /** 예외사항 추가 */

    let birthArr: Array<string> = [];
    birthArr = data.user_birth.split('-');

    try {
      const res = await APIS.postData(
        "/UserInfo/MemberUpdate",
        {
          ...data,
          admin_table_id: userData.admin_table_id,
          birth_year: birthArr[0],
          birth_month: birthArr[1],
          birth_day: birthArr[2],
        },
        1
      );

      if (res.data.code === "OK") {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };


  interface Obj {
    idx: string,
    title: string
  }

  const optionFunc = (options: Obj[]) => options.map((obj) => ({
    value: obj.idx,
    label: obj.title
  }))

  const getUserType = (kakao_auth_key: string) => {
    return kakao_auth_key === "" ? "일반" : "카카오";
  }

  return {
    list,
    total,
    page,
    update,
    target,
    search,
    optionFunc,
    getUserType
  };
};

export default useMember;
