import React, { useState, forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

export default function InputDate(props) {
  const {
    placeholder,
    placeholderEnd,
    name,
    dateValue,
    setDateValue,
    dateValueEnd,
    setDateValueEnd,
    onSearch,
    label,
    error,
    success,
    readOnly,
    multiple,
    onClickFunc,
    maxDate,
  } = props;

  const [startDate] = useState(
    dateValue ? new Date(moment(dateValue, "YYYY/MM/DD")) : ""
  );
  const [endDate] = useState(dateValueEnd ? new Date(dateValueEnd) : "");

  const [openState1, setOpenState1] = useState(false);
  const [openState2, setOpenState2] = useState(false);

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <label
      className={
        readOnly
          ? "input_date_label disable"
          : openState1
          ? "input_date_label focus"
          : "input_date_label"
      }
      onClick={
        onClickFunc ? onClickFunc : !readOnly ? onClick : console.log("")
      }
      ref={ref}
    >
      <span>{dateValue ? dateValue : placeholder}</span>
    </label>
  ));

  const CustomInputEnd = forwardRef(({ value, onClick }, ref) => (
    <label
      className={
        readOnly
          ? "input_date_label disable"
          : openState2
          ? "input_date_label focus"
          : "input_date_label"
      }
      onClick={!readOnly ? onClick : console.log("")}
      ref={ref}
    >
      <span>{dateValueEnd ? dateValueEnd : placeholderEnd}</span>
    </label>
  ));

  return (
    <div className="input_box">
      <label className="input_label" htmlFor={name}>
        {label}
      </label>

      <div className="input_section">
        {!multiple ? (
          <DatePicker
            selected={startDate}
            dateFormat="yyyy-MM-dd"
            onChange={(date) => {
              onSearch
                ? onSearch("sdate", moment(date).format("YYYY.MM.DD"))
                : setDateValue(moment(date).format("YYYY.MM.DD"));
            }}
            customInput={<CustomInput />}
            onCalendarClose={() => setOpenState1(false)}
            onCalendarOpen={() => setOpenState1(true)}
            maxDate={maxDate}
            minDate={!!maxDate && new Date()}
          />
        ) : (
          <div className="input_date_section_multi">
            <DatePicker
              selected={startDate}
              dateFormat="yyyy-MM-dd"
              onChange={(date) =>
                onSearch
                  ? onSearch("sdate", moment(date).format("YYYY.MM.DD"))
                  : setDateValue(moment(date).format("YYYY.MM.DD"))
              }
              customInput={<CustomInput />}
              onCalendarClose={() => setOpenState1(false)}
              onCalendarOpen={() => setOpenState1(true)}
            />
            <span>~</span>
            <DatePicker
              selected={endDate}
              dateFormat="yyyy-MM-dd"
              onChange={(date) =>
                onSearch
                  ? onSearch("edate", moment(date).format("YYYY.MM.DD"))
                  : setDateValueEnd(moment(date).format("YYYY.MM.DD"))
              }
              customInput={<CustomInputEnd />}
              onCalendarClose={() => setOpenState2(false)}
              onCalendarOpen={() => setOpenState2(true)}
            />
          </div>
        )}
      </div>

      {error && <p className="input_error">{error}</p>}
      {success && <p className="input_success">{success}</p>}
    </div>
  );
}
