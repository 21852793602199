import React from "react";
import Input from '../../../components/Input';
import InputDate from '../../../components/InputDate';
import InputSelect from '../../../components/InputSelect';
import consts from "../../../libs/consts";

export default function LogsSearch({tabNum, search, searchFunc, dataFunc}) {
    const searchPlaceholders = ["요청번호 입력", "요청번호, 파트너명 입력", "요청번호, 파트너명 입력", "결제번호, 파트너명 입력"];

    return (
            <div className="search_form search_form_long">
                <div className="search_form_left">
                    {tabNum === 2 &&
                        <InputSelect name="state" label="구분" value={search.state} onSearch={searchFunc} option={[{idx: "", title: "전체"}, ...consts.supply_options]}/>
                    }
                    <Input className="input_text" type="text" placeholder={searchPlaceholders[tabNum]} name="stx" value={search.stx} onSearch={searchFunc} label={"검색"}/>
                    <InputDate type="text" placeholder="시작날짜" placeholderEnd="종료날짜" name="ipd1" label="날짜선택" dateValue={search.sdate} onSearch={searchFunc} dateValueEnd={search.edate} multiple={true}/>
                    <button type="button" className="btn btn3" onClick={() => dataFunc()}>검색</button>
                </div>
                <div className="search_form_right">
                </div>
            </div>
    );
}
