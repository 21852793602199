/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import * as APIS from "../../../utils/service";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export interface List {
  monthly_table_id: string;
  title: string;
  image: string;
  isApplication: string;
  url: string;
}

interface Props {
  isList?: boolean;
  id?: string;
  searchData?: {
    isApplication?: string;
    title?: string;
  };
}

const useHomeManageLifeMonthly = (props?: Props) => {
  const { isList, id, searchData } = props ?? {};
  const userData = useSelector((s: any) => s.usersReducer);

  const [list, setList] = useState<List[]>([]);
  const [total, setTotal] = useState(0);

  const [detail, setDetail] = useState<List>();

  const params = new URLSearchParams(window.location.search);
  const page = params.get("page") || "1";

  const navigate = useNavigate();
  const { pathname } = window.location;

  const getList = async (page: string) => {
    const params = {
      admin_table_id: userData.admin_table_id,
      page: page,
      ...searchData,
    };

    try {
      const res = await APIS.postData("/AdminHome/LifeMonthlyList", params, 1);

      if (res.data.code === "OK") {
        setList(res.data.life_monthly_list);
        setTotal(res.data.total_cnt);
      } else {
        setList([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (isList) {
      getList(page);
    }
  }, [page]);

  const getTarget = async (): Promise<void> => {
    try {
      const res = await APIS.postData("/AdminHome/LifeMonthlyTarget", {
        admin_table_id: userData.admin_table_id,
        monthly_table_id: id,
      });

      if (res.data.code === "OK") {
        setDetail(res.data.life_monthly_list[0]);
      } else {
        setDetail(undefined);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (id) {
      getTarget();
    }
  }, [id]);

  const search = () => {
    if (page === "1") {
      getList("1");
    } else {
      navigate({
        pathname: pathname,
        search: `?page=${1}`,
      });
    }
  };

  const getImage = (img?: string): string => {
    if (!img) {
      return "";
    }

    const isImage = img.includes("data:image");
    return isImage ? img : "";
  };

  const create = async (data: {
    title: string;
    isApplication: string;
    url: string;
    image: string;
  }): Promise<boolean | undefined> => {
    if (
      !data.title ||
      !data.isApplication ||
      !data.url ||
      !data.image
    ) {
      alert("기본 정보 필수값을 입력해주세요.");
      return;
    }

    if (data.url.indexOf('http') >= 0 || data.url.indexOf('/') !== 0) {
      alert("URL 입력값이 잘못되었습니다. path 값만 입력해주세요.");
      return;
    }
    try {
      const res = await APIS.postData(
        "/AdminHome/LifeMonthlyCreate",
        {
          ...data,
          admin_table_id: userData.admin_table_id,
        },
        1
      );

      if (res.data.code === "OK") {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const update = async (
    data: Omit<List, "update_date">
  ): Promise<boolean | undefined> => {
    if (
      !data.title ||
      !data.isApplication ||
      !data.url ||
      !data.image
    ) {
      alert("기본 정보 필수값을 입력해주세요.");
      return;
    }
    try {
      const res = await APIS.postData(
        "/AdminHome/LifeMonthlyUpdate",
        {
          ...data,
          admin_table_id: userData.admin_table_id,
          image: getImage(data.image)
        },
        1
      );

      if (res.data.code === "OK") {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  return {
    list,
    total,
    page,
    search,
    create,
    update,
    detail,
  };
};

export default useHomeManageLifeMonthly;
