/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { css } from "@emotion/react";
import Checkbox from "../../../Common/Checkbox/Checkbox";
import Input from "../../../Common/Input/Input";
import Upload from "../../../Common/Upload/Upload";
import { labelStyles, marginTopStyles } from "../../notice.style";
import { AddInfoType, BasicInfoType } from "../noticeEvent.type";
import debounce from "lodash/debounce";
import consts from "../../../../libs/consts";

interface Props {
  data: AddInfoType;
  info: BasicInfoType;
  setData: React.Dispatch<React.SetStateAction<AddInfoType>>;
}

type types = "hero_banner" | "line_banner" | "center_banner" | "pop_banner";

const AddInfo = (props: Props) => {
  const { data, info, setData } = props;
  const [banners, setBanners] = useState<{ [field: string]: boolean }>({
    hero: false,
    belt: false,
    center: false,
    popup: false,
  });

  const [centerState, setCenterState] = useState<1 | 2 | 3>(1);

  useEffect(() => {
    const { hero, belt, center, popup } = banners;

    const initUrl = `${consts.apiBaseUrl}/event/${info.url_table_id}`;

    if (hero) {
      setData({
        ...data,
        hero_banner: {
          hero_thumbnail_pc: "",
          hero_thumbnail_mo: "",
          hero_url: initUrl,
        },
      });
    } else if (belt) {
      setData({
        ...data,
        line_banner: {
          line_title: "",
          line_url: initUrl,
        },
      });
    } else if (center) {
      setData({
        ...data,
        center_banner: {
          center_state: "1",
          center_title: "",
          center_thumbnail: "",
          center_url: initUrl,
        },
      });
    } else if (popup) {
      setData({
        ...data,
        pop_banner: {
          pop_thumbnail_pc: "",
          pop_thumbnail_mo: "",
          pop_url: initUrl,
        },
      });
    } else if (!hero) {
      setData({
        ...data,
        hero_banner: undefined,
      });
    } else if (!belt) {
      setData({
        ...data,
        line_banner: undefined,
      });
    } else if (!center) {
      setData({
        ...data,
        center_banner: undefined,
      });
    } else if (!popup) {
      setData({
        ...data,
        pop_banner: undefined,
      });
    }

    if (!center) {
      setCenterState(1);
    }
  }, [banners]);

  const onChangeCheckbox = (e?: React.ChangeEvent<HTMLInputElement>) => {
    if (!e) {
      return;
    }

    const { name } = e.target;

    setBanners({
      ...banners,
      [name]: !banners[name],
    });
  };

  const changeInputHandler = (
    type: types,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = e.target;

    setData({
      ...data,
      [type]: {
        ...data[type],
        [name]: value,
      },
    });
  };

  const changeFileHandler = debounce(
    (type: types, name: string, { fileImage }) => {
      setData({
        ...data,
        [type]: {
          ...data[type],
          [name]: fileImage,
        },
      });
    },
    200
  );

  const changeCenterState = (type: types, value: 1 | 2 | 3) => {
    setCenterState(value);

    setData({
      ...data,
      [type]: {
        ...data[type],
        center_state: String(value),
      },
    });
  };

  return (
    <div>
      <div
        css={css`
          margin-top: 20px;
          display: flex;
          gap: 40px;
        `}
      >
        <p
          css={[
            labelStyles,
            css`
              margin: auto 0;
            `,
          ]}
        >
          * 노출 영역
        </p>
        <Checkbox
          name="hero"
          labelText="히어로배너"
          value={banners.hero}
          onChange={onChangeCheckbox}
        />
        <Checkbox
          name="belt"
          labelText="띠 배너"
          value={banners.belt}
          onChange={onChangeCheckbox}
        />
        <Checkbox
          name="center"
          labelText="중앙 배너"
          value={banners.center}
          onChange={onChangeCheckbox}
        />
        <Checkbox
          name="popup"
          labelText="팝업"
          value={banners.popup}
          onChange={onChangeCheckbox}
        />
      </div>
      {(banners.hero || banners.belt || banners.center || banners.popup) && (
        <div
          css={css`
            margin-top: 30px;
          `}
        >
          {banners.hero && (
            <div
              css={css`
                margin-top: 20px;
              `}
            >
              <h3>히어로 배너 등록</h3>
              <div css={[marginTopStyles]}>
                <Upload
                  label="* 썸네일 pc (1900 X 552)"
                  placeholder="사진을 첨부해주세요."
                  id="upload_pc"
                  size="big"
                  callback={(file) =>
                    changeFileHandler("hero_banner", "hero_thumbnail_pc", file)
                  }
                />
              </div>
              <div css={[marginTopStyles]}>
                <Upload
                  label="* 썸네일 mobile (375 X 400)"
                  placeholder="사진을 첨부해주세요."
                  id="upload_mobile"
                  size="big"
                  callback={(file) =>
                    changeFileHandler("hero_banner", "hero_thumbnail_mo", file)
                  }
                />
              </div>
              <div css={[marginTopStyles]}>
                <Input
                  label="* 연결 URL"
                  placeholder="기존 작성한 URL이 노출 됩니다."
                  size="big"
                  name="hero_url"
                  value={data.hero_banner?.hero_url || ""}
                  onChange={(e) => changeInputHandler("hero_banner", e)}
                />
              </div>
            </div>
          )}
          {banners.belt && (
            <div
              css={css`
                margin-top: 20px;
              `}
            >
              <h3>띠 배너 등록</h3>
              <div css={[marginTopStyles]}>
                <Input
                  label="* 타이틀명"
                  placeholder={`<font color="#808080">14%할인</font>`}
                  size="big"
                  name="line_title"
                  onChange={(e) => changeInputHandler("line_banner", e)}
                />
              </div>
              <div css={[marginTopStyles]}>
                <Input
                  label="* 연결 URL"
                  placeholder="기존 작성한 URL이 노출 됩니다."
                  size="big"
                  name="line_url"
                  value={data.line_banner?.line_url || ""}
                  onChange={(e) => changeInputHandler("line_banner", e)}
                />
              </div>
            </div>
          )}

          {banners.center && (
            <div
              css={css`
                margin-top: 20px;
              `}
            >
              <h3>중앙 배너 등록</h3>
              <div css={[marginTopStyles]}>
                <p css={[labelStyles]}>* 상태</p>
                <div
                  css={css`
                    margin-top: 6px;
                    display: flex;
                    gap: 30px;
                  `}
                >
                  <Checkbox
                    name="hot"
                    labelText="HOT"
                    value={centerState === 1}
                    onChange={() => changeCenterState("center_banner", 1)}
                  />
                  <Checkbox
                    name="new"
                    labelText="NEW"
                    value={centerState === 2}
                    onChange={() => changeCenterState("center_banner", 2)}
                  />
                  <Checkbox
                    name="best"
                    labelText="BEST"
                    value={centerState === 3}
                    onChange={() => changeCenterState("center_banner", 3)}
                  />
                </div>
                <div css={[marginTopStyles]}>
                  <Input
                    label="* 타이틀명 (최대 20자 공백 포함)"
                    maxLength={20}
                    placeholder="기존 작성한 타이틀이 노출됩니다."
                    size="big"
                    name="center_title"
                    onChange={(e) => changeInputHandler("center_banner", e)}
                  />
                </div>
                <div css={[marginTopStyles]}>
                  <Upload
                    label="* 썸네일"
                    id="center_banner_thumb"
                    size="big"
                    callback={(file) =>
                      changeFileHandler(
                        "center_banner",
                        "center_thumbnail",
                        file
                      )
                    }
                    keepOriginal
                  />
                </div>
                <div css={[marginTopStyles]}>
                  <Input
                    label="* 연결 URL"
                    placeholder="기존 작성한 URL이 노출 됩니다."
                    size="big"
                    name="center_url"
                    value={data.center_banner?.center_url || ""}
                    onChange={(e) => changeInputHandler("center_banner", e)}
                  />
                </div>
              </div>
            </div>
          )}
          {banners.popup && (
            <div
              css={css`
                margin-top: 20px;
              `}
            >
              <h3>팝업 등록</h3>
              <div css={[marginTopStyles]}>
                <Upload
                  label="* 썸네일 pc (335 X 335)"
                  placeholder="사진을 첨부해주세요."
                  id="popup_pc_thumb"
                  size="big"
                  callback={(file) =>
                    changeFileHandler("pop_banner", "pop_thumbnail_pc", file)
                  }
                />
              </div>
              <div css={[marginTopStyles]}>
                <Upload
                  label="* 썸네일 mobile (375 X 250)"
                  placeholder="사진을 첨부해주세요."
                  id="popup_mobile_thumb"
                  size="big"
                  callback={(file) =>
                    changeFileHandler("pop_banner", "pop_thumbnail_mo", file)
                  }
                />
              </div>
              <div css={[marginTopStyles]}>
                <Input
                  label="* 연결 URL"
                  placeholder="기존 작성한 URL이 노출 됩니다."
                  size="big"
                  name="pop_url"
                  value={data.pop_banner?.pop_url || ""}
                  onChange={(e) => changeInputHandler("pop_banner", e)}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AddInfo;
