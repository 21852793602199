import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { close } from "../redux/popupSlice";
import useWindowScreen from "../utils/useWindowScreen";
import { pwTimerOff } from "../redux/joinSlice";
import images from "../libs/images";

export default function Popup() {
  const dispatch = useDispatch(); // 리덕스를 연결하는 문

  const {
    open,
    title,
    subtitle,
    message,
    button,
    buttonCencle,
    onCancelPress,
    onCancelPressAlert,
    onPress,
    component,
    titleLong,
    subMessage,
    subComponent,
    messageAuto,
    wide,
    wideOne,
    slideWide,
    certification,
    filter,
    today,
    constInfo,
    mideum,
    suc,
    one,
    overFlow,
    isNum,
    reduxNumPage,
  } = useSelector((state) => state.popupReducer);

  const [isMobile, setIsMobile] = useState(false);

  const { width } = useWindowScreen();

  const joinData = useSelector((s) => s.joinReducer); // useSelector = 하나의 리덕스를 선택하는 함수

  const [min, setMin] = useState("05");

  const [sec, setSec] = useState("00");

  const timerId = useRef(null);

  useEffect(() => {
    if (width > 720) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, [width]);

  const handleClose = () => {
    dispatch(close());
  };

  useEffect(() => {
    if (subMessage === "pwTimer") {
      let diffDate = 300 - (moment().unix() - joinData.pwTime);

      setMin(
        parseInt(diffDate / 60) < 10
          ? "0" + parseInt(diffDate / 60)
          : parseInt(diffDate / 60)
      );
      setSec(diffDate % 60 < 10 ? "0" + (diffDate % 60) : diffDate % 60);

      timerId.current = setInterval(() => {
        setMin(
          parseInt(diffDate / 60) < 10
            ? "0" + parseInt(diffDate / 60)
            : parseInt(diffDate / 60)
        );
        setSec(diffDate % 60 < 10 ? "0" + (diffDate % 60) : diffDate % 60);
        diffDate -= 1;
      }, 1000);

      return () => clearInterval(timerId.current);
    }
  }, [joinData.pwTime, open, subMessage]);

  useEffect(() => {
    const diffDate = 300 - (moment().unix() - joinData.pwTime);

    if (diffDate <= 0 && joinData.pwTimer) {
      setTimeout(() => {
        setMin("00");
        setSec("00");
      }, 1000);

      clearInterval(timerId.current);
      dispatch(pwTimerOff());
    }
  }, [dispatch, joinData.pwTime, joinData.pwTimer, sec]);

  return (
    <>
      <div className={open ? "popup show" : "popup"}>
        <div
          className={
            (component && !suc
              ? wide
                ? "popup_container wide full"
                : wideOne
                ? "popup_container wide1 full"
                : certification
                ? "popup_container certification full"
                : constInfo
                ? "popup_container constinfo full"
                : slideWide
                ? "popup_container slidewide full"
                : filter
                ? "popup_container filter full"
                : today
                ? "popup_container today full"
                : mideum
                ? "popup_container medium full"
                : "popup_container medium"
              : "popup_container") + (overFlow ? " overFlowFalse" : "")
          }
        >
          <div className="popup_top">
            <div
              className="popup_title_section"
              style={{ marginBottom: titleLong ? "10px" : "0" }}
            >
              {subtitle ? (
                <div className="popup_title_section_box">
                  <p
                    className="popup_title"
                    style={{ margin: titleLong ? "20px 0 0 0" : "0" }}
                  >
                    {title}
                  </p>
                  <p className="popup_title_sub">{subtitle}</p>
                </div>
              ) : (
                <p
                  className="popup_title"
                  style={{ margin: titleLong ? "20px 0 0 0" : "0" }}
                >
                  {title}
                </p>
              )}

              <div>
                {isMobile ? (
                  <></>
                ) : (
                  <div
                    className="popup_title_sub2"
                    style={{ display: isNum ? "contents" : "none" }}
                  >
                    {reduxNumPage !== 3 && (
                      <img
                        src={
                          reduxNumPage === 1
                            ? images.numbering1
                            : images.numbering2
                        }
                        alt="numbering_image"
                      />
                    )}
                  </div>
                )}
                <button
                  type="button"
                  className="exit_btn"
                  onClick={() => {
                    onCancelPressAlert ? onCancelPressAlert() : handleClose();
                    onCancelPress && onCancelPress();
                  }}
                ></button>
              </div>
            </div>

            {(message || subMessage || subComponent) && (
              <p
                className="popup_msg"
                style={{
                  minHeight: component ? "auto" : "60px",
                  whiteSpace: messageAuto ? "pre-wrap" : "pre",
                }}
              >
                {message}
                {subMessage && subMessage === "pwTimer" ? (
                  <span>
                    남은 시간 : {min}:{sec}
                  </span>
                ) : (
                  <span>{subMessage}</span>
                )}
                {subComponent && subComponent}
              </p>
            )}
          </div>

          {component && one ? (
            <div className="one_component">{component}</div>
          ) : (
            component
          )}

          {(!component || (component && one)) && (
            <div className={one ? "popup_btn popup_btn_full" : "popup_btn"}>
              {buttonCencle && (
                <button
                  type="button"
                  className="popbtn cencle_btn"
                  onClick={() => {
                    onCancelPressAlert ? onCancelPressAlert() : handleClose();
                    onCancelPress && onCancelPress();
                  }}
                >
                  {buttonCencle}
                </button>
              )}
              <button
                type="button"
                className="popbtn ok_btn"
                onClick={() => {
                  handleClose();
                  onPress && onPress();
                }}
                style={{ width: buttonCencle ? "" : "100%" }}
              >
                {button}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
