/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as APIS from "../../utils/service";
import { findJson, findJsonCl } from "../../utils/utils";
import moment from "moment";
import Pagenation from "../../components/Pagenation";
import Insert from "./Insert";
import Update from "./Update";
import consts from "../../libs/consts";
import { open } from "../../redux/popupSlice";
import { setData } from "../../redux/searchSlice";
import PartnerLayout from "../../components/Layout/partner";

export default function Estimate() {
  const dispatch = useDispatch();
  const userData = useSelector((s) => s.usersReducer);
  const search = useSelector((s) => s.searchReducer);
  const [list, setList] = useState([]); // PC용 리스트

  const [pageing] = useState("");

  const dataFunc = () => {
    const urls = "/UserCntl/UserList";
    const types = 2;
    const sender = {
      seller_table_id: userData.seller_table_id,
    };

    APIS.postData(urls, sender, types)
      .then((res) => {
        if (res.data.code === "OK") {
          setList(res.data.user_list);
        } else {
          setList([]);
        }
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };

  useEffect(() => {
    dataFunc();
  }, []);

  const addFunc = () => {
    if (list.length > 4) {
      dispatch(
        open({
          message:
            "초대 중인 인원 포함해\n최대 5명만 사용자 등록이 가능합니다.",
        })
      );
    } else {
      dispatch(
        open({
          title: "사용자초대",
          medium: true,
          component: <Insert />,
        })
      );
    }
  };

  const updateFunc = (x) => {
    dispatch(
      open({
        title: "사용자수정",
        medium: true,
        component: <Update x={x} />,
      })
    );
    return;
  };

  const searchFunc = (key, value) => {
    dispatch(
      setData({
        key: key,
        value: value,
      })
    );
  };

  return (
    <PartnerLayout title="사용자관리">
      <div>
        <div className="search_form">
          <div className="search_form_left"></div>
          <div className="search_form_right">
            <button
              type="button"
              className="btn btn1"
              onClick={() => addFunc()}
            >
              사용자 등록
            </button>
          </div>
        </div>

        <table className="tableStyle1">
          <thead>
            <tr>
              <th className="wd150">등급</th>
              <th className="wd150">ID</th>
              <th className="wd150">이름</th>
              <th className="wd150">상태</th>
              <th className="wd220">등록일</th>
              <th className="wd220">최종로그인</th>
            </tr>
          </thead>
          <tbody>
            {list.length > 0 ? (
              list.map((x, i) => {
                return (
                  <tr
                    key={i}
                    className={"pointer"}
                    onClick={() => updateFunc(x)}
                  >
                    <td>
                      {x.seller_grade === "1"
                        ? "메인파트너"
                        : findJson(consts.authoption, x.seller_grade)}
                    </td>
                    <td>{x.seller_id}</td>
                    <td>{x.seller_name}</td>
                    <td
                      className={findJsonCl(
                        consts.sellerUserServiceState,
                        x.service_state
                      )}
                    >
                      {findJson(consts.sellerUserServiceState, x.service_state)}
                    </td>
                    <td>{moment(x.reg_date).format("YYYY.MM.DD")}</td>
                    <td>
                      {x.last_login !== "0000-00-00 00:00:00"
                        ? moment(x.last_login).format("YYYY.MM.DD HH:mm")
                        : ""}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="null_td" colSpan={6}>
                  데이터가 없습니다.
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {pageing && (
          <Pagenation
            pageing={pageing}
            onSearch={searchFunc}
            curpage={search.page}
          />
        )}
      </div>
    </PartnerLayout>
  );
}
