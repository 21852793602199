import React, { useState } from "react";
import { css } from "@emotion/react";
import Table from "../Common/Table/Table";
import { findJson } from "../../utils/utils";
import Pagination from "../Common/Pagination/Pagination";
import moment from "moment";
import Button from "../Common/Button/Button";
import DateRange from "../Common/Date/DateRange";
import UpdateMember from "./Update";
import useMember from "./member.hook";
import Select from "../Common/Select/Select";
import Input from "../Common/Input/Input";
import { CSVLink } from "react-csv";
import useCsv from "../../hooks/useCsv";
import consts from "../../libs/consts";

const Member = () => {
  const [dateData, setDateData] = useState({
    start_date: "",
    end_date: "",
  });

  const [id, setId] = useState<string | undefined>();

  const [searchData, setSearchData] = useState({
    start_date: "",
    end_date: "",
    user_type: "",
    user_state: "",
    type: "",
    text: "",
  });

  const { list, page, total, search, optionFunc, getUserType } = useMember({
    searchData
  });

  const { csvLinkRef, getCsvHeader, downloadHandler } = useCsv();
  const changeSelectHandler = (
    e: React.ChangeEvent<HTMLSelectElement>,
    field: string) => {
    const { value } = e.target;
    setSearchData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const col = [
    { key: "user_table_id", title: "회원번호" },
    { key: "user_id", title: "회원아이디" },
    {
      key: "user_type",
      title: "회원구분",
      render: (data: any) => (
        <span>{getUserType(data.kakao_auth_key)}</span>
      )
    },
    { key: "user_name", title: "이름" },
    { key: "user_phone", title: "전화번호" },
    {
      key: "user_state",
      title: "회원상태",
      render: (data: any) => <span>{findJson(consts.userStateOption, data.user_state)}</span>
    },
    {
      key: "reg_date",
      title: "가입일",
      render: (data: any) => (
        <span>{moment(data.reg_date).format("YYYY.MM.DD HH:mm")}</span>
      ),
    },
  ];

  const changeDateHandler = (type: string, date?: Date) => {
    if (date) {
      setDateData((prevData) => ({
        ...prevData,
        ...(type === "start" && {
          start_date: moment(date).format("YYYY-MM-DD 00:00:00"),
        }),
        ...(type === "end" && {
          end_date: moment(date).format("YYYY-MM-DD 23:59:59"),
        }),
      }));
    }
  };

  const changeInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setSearchData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const callback = () => {
    setId(undefined);
    search();
  };

  return (
    <div>
      <div
        css={css`
          margin-top: 24px;
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
        `}
      >
        <div
          css={css`
            width: 130px;
          `}
        >
          <Select
            label="회원구분"
            defaultValue="전체"
            options={optionFunc(consts.userTypeOption)}
            size="full"
            onChange={(e) => changeSelectHandler(e, "user_type")}
          />
        </div>
        <div
          css={css`
            width: 130px;
          `}
        >
          <Select
            label="회원상태"
            defaultValue="전체"
            options={optionFunc(consts.userStateOption)}
            onChange={(e) => changeSelectHandler(e, "user_state")}
            size="full"
          />
        </div>
        <div>
          <DateRange
            label="가입일"
            startPlaceholder="시작날짜"
            startValue={
              dateData.start_date ? new Date(dateData.start_date) : ""
            }
            endPlaceholder="종료날짜"
            endValue={dateData.end_date ? new Date(dateData.end_date) : ""}
            onChange={changeDateHandler}
          />
        </div>
        <div
          css={css`
            width: 130px;
          `}
        >
          <Select
            label="검색"
            defaultValue="전체"
            options={optionFunc(consts.typeOption)}
            onChange={(e) => changeSelectHandler(e, "type")}
            size="full"
          />
        </div>
        <div>
          <Input
            label="검색어"
            placeholder="입력"
            size="small"
            onChange={changeInputHandler}
          />
        </div>
        <div
          css={css`
            margin: auto 0 0 0;
          `}
        >
          <Button theme="tertiary" onClick={search}>
            검색
          </Button>
        </div>
        <div
          css={css`
            margin: auto 0 0 0;
          `}
        >
          <Button theme="tertiary" onClick={() => downloadHandler()}>
            엑셀 다운로드
          </Button>
          <CSVLink
            data={list.map((l) => ({
              ...l,
              user_type: getUserType(l.kakao_auth_key),
              user_state: findJson(consts.userStateOption, l.user_state),
            }))}
            ref={csvLinkRef}
            headers={getCsvHeader(col)}
            filename={
              "회원관리_" +
              moment(new Date()).format("YYYY_MM_DD(HH)") +
              ".csv"
            }
          />
        </div>
      </div>
      <div
        css={css`
          margin: 30px 0;
        `}
      >
        <div
          css={css`
            margin-bottom: 10px;
            display: flex;
            justify-content: end;
          `}
        >
        </div>
        <Table columns={col} datas={list} rowCallback={(data) => setId(data.user_table_id)} />
      </div>
      <Pagination curPage={page} total={total} pageSize={20} />
      {id && (
        <UpdateMember
          close={() => setId(undefined)}
          id={id}
          callback={callback}
        />
      )}
    </div>
  );
};

export default Member;
