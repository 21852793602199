import { Link } from "react-router-dom";
import routes from "../../libs/routes";

interface Props {
  type?: string;
  style?: React.CSSProperties;
}

export default function Footer(props: Props) {
  const { type, style } = props;

  return (
    <div
      style={style}
      className={
        type === "login"
          ? "footer_login"
          : type === "admin"
          ? "footer_admin"
          : "footer"
      }
    >
      <div className="fotter_clause">
        <Link to={routes.clause + "?t=3"} target="_blank">
          이용약관 |{" "}
        </Link>
        <Link to={routes.clause + "?t=2"} target="_blank">
          개인정보 처리방침
        </Link>
      </div>

      <p>
        대표 : 유광연 | 서울시 구로구 디지털로 300 지밸리비즈플라자 15층
        <br />
        사업자등록번호 : 204-86-40665 | 통신판매업번호 : 제2019-서울구로-0881호
        | Tel : 1577-6549
        <br />
        (주) 두꺼비세상 ALL RIGHTS RESERVED
      </p>
    </div>
  );
}
