import { useNavigate } from "react-router-dom";
import routes from "../../../libs/routes";

const useNotice = () => {
  const navigate = useNavigate();
  const { pathname } = window.location;

  const tabs = [
    {
      id: 1,
      title: "이벤트",
      callback: () => {
        navigate(routes.notice_event);
      },
      isActive: pathname === routes.notice_event,
    },
    {
      id: 2,
      title: "노출 광고",
      callback: () => {
        navigate(routes.notice_ad);
      },
      isActive: pathname === routes.notice_ad,
    },
    {
      id: 3,
      title: "URL 생성",
      callback: () => {
        navigate(routes.notice_url);
      },
      isActive: pathname === routes.notice_url,
    },
    {
      id: 4,
      title: "파트너",
      callback: () => {
        navigate(routes.notice_partner);
      },
      isActive: pathname === routes.notice_partner,
    },
    {
      id: 5,
      title: "사용자",
      callback: () => {
        navigate(routes.notice_user);
      },
      isActive: pathname === routes.notice_user,
    },
  ];

  return {
    tabs,
  };
};

export default useNotice;
