/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import * as APIS from "../../utils/service";
import { open } from "../../redux/popupSlice";
import { logIn } from "../../redux/usersSlice";
import { setKakao, setAuth } from "../../redux/joinSlice";
import routes from "../../libs/routes";
import Footer from "../../components/Layout/Footer";
import LoginId from "./LoginId";

const { Kakao } = window;

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((s) => s.usersReducer);

  useEffect(() => {
    dispatch(
      setAuth({
        name: "",
        phone: "",
      })
    );

    if (userData.isLoggedIn) {
      navigate(routes.main);
      return;
    }

    APIS.postData("/AreaInfo/GetAreaOne", "", 2).catch((e) => {
      console.log("catch", e);
    });
  }, []);

  const kakaoLogin = (type) => {
    Kakao.Auth.login({
      success: function (authObj) {
        Kakao.Auth.setAccessToken(authObj.access_token);
        Kakao.API.request({
          url: "/v2/user/me",
          success: function (response) {
            const sender = {
              kakao_auth_key: response.id,
            };

            APIS.postData("/SellerInfo/CheckKakao", sender, 2)
              .then((res) => {
                dispatch(
                  setKakao({
                    kakaoId: response.id,
                  })
                );

                if (res.data.code === "OK") {
                  dispatch(
                    logIn({
                      mbId: res.data.seller_id,
                      mbName: res.data.seller_name,
                      mbProfile: res.data.seller_icon,
                      kakao_auth_key: res.data.kakao_auth_key,
                      reg_date: res.data.reg_date,
                      seller_addr: res.data.seller_addr,
                      seller_addr_detail: res.data.seller_addr_detail,
                      seller_area: res.data.seller_area,
                      seller_area_text: res.data.seller_area_text,
                      seller_as: res.data.seller_as,
                      seller_biz_no: res.data.seller_biz_no,
                      seller_biz_name: res.data.seller_biz_name,
                      seller_career: res.data.seller_career,
                      seller_desc: res.data.seller_desc,
                      seller_email: res.data.seller_email,
                      seller_grade: res.data.seller_grade,
                      seller_icon: res.data.seller_icon,
                      seller_id: res.data.seller_id,
                      seller_license_yn: res.data.seller_license_yn,
                      seller_name: res.data.seller_name,
                      seller_passwd: res.data.seller_passwd,
                      seller_phone: res.data.seller_phone,
                      seller_service: res.data.seller_service,
                      seller_state: res.data.seller_state,
                      service_state: res.data.service_state,
                      seller_table_id: res.data.seller_table_id,
                      update_date: res.data.update_date,
                      work_kind: res.data.work_kind,
                      market_agree: res.data.market_agree,
                      market_agree_date: res.data.market_agree_date,
                      alarm_yn: res.data.alarm_yn,
                    })
                  );
                  navigate(routes.main);
                } else {
                  if (type === "1") {
                    goRegister();
                  } else {
                    dispatch(
                      open({
                        message:
                          "해당 카카오 계정으로 등록된 정보가 없습니다. \n회원가입 후 이용해주세요.",
                        button: "회원가입 하러가기",
                        onPress: goRegister,
                      })
                    );
                  }
                }
              })
              .catch((e) => {
                console.log("catch", e);
                alert("잠시 후 다시 시도해주세요.");
              });
            return;
          },
          fail: function (error) {
            console.log(error);
            alert("잠시 후 다시 시도해주세요.");
          },
        });
      },
      fail: function (err) {
        alert("잠시 후 다시 시도해주세요.");
      },
    });
  };

  const registerFunc = () => {
    dispatch(
      open({
        message: "회원가입을 위해 카카오톡 \n로그인 요청 드립니다.",
        button: "카카오톡 로그인",
        onPress: () => kakaoLogin("1"),
      })
    );
    return false;
  };

  const goRegister = () => {
    navigate(routes.register);
  };

  const idLogin = () => {
    dispatch(
      open({
        title: "아이디와 비밀번호 입력 후\n로그인 요청드립니다.",
        titleLong: true,
        titleLong1: 1,
        component: <LoginId />,
      })
    );
    return false;
  };

  return (
    <div className="login">
      <div className="login_bg"></div>

      <div className="login_section">
        <Link to={routes.login} className="login_logo indent999">
          LOGO
        </Link>

        <p className="title fontNexon">레이홈 파트너 사이트</p>

        <div className="login_btn_box">
          <button
            type="button"
            className="login_btn kakao_btn"
            onClick={() => kakaoLogin()}
          >
            <span>카카오톡 로그인</span>
          </button>

          <p className="login_comment">
            카카오톡으로 로그인이 어려운 경우 ID/PW 로그인을 이용해 주세요.
          </p>

          <button type="button" className="login_btn" onClick={() => idLogin()}>
            <span>ID/PW 로그인</span>
          </button>

          <button
            type="button"
            className="register_btn"
            onClick={() => registerFunc()}
          >
            회원가입
          </button>
        </div>

        <Footer type="login" />

        {/* <button type="button" onClick={() => testLogin()}>테스트 로그인</button> */}
      </div>
    </div>
  );
}
