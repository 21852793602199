import React, { useEffect } from "react";
import ReactDom from "react-dom";

import styled from "styled-components";

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 30000;
  display: flex;
  background-color: rgb(144, 144, 144, 0.8);
`;

const ModalPortal = ({ children }) => {
  const el = document.getElementById("react-portal");

  return ReactDom.createPortal(children, el);
};

const Modal = ({ children }) => {
  useEffect(() => {
    document.body.style.cssText = `
            position: fixed;
            top: -${window.scrollY}px;
            width: 100%;
        `;

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = "";
      window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
    };
  }, []);

  return (
    <ModalPortal>
      <ModalContainer>{children}</ModalContainer>
    </ModalPortal>
  );
};
export default Modal;
