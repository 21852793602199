import React, { useState, useEffect } from "react";
import Modal from "../../Common/ModalPortal";
import styled from "styled-components";
import Input from "../../Input";
import InputSelect from "../../InputSelect";
import consts from "../../../libs/consts";
import TextArea from "../../TextArea";
import trash_image from "../../../assets/images/trash.svg";

const ModalContent = styled.div`
  margin: auto;
  background-color: #fff;
  width: 350px;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e4e9f2;
  height: 60px;

  & > h3 {
    margin: auto 0 auto 20px;
  }
`;

const ModalBanner = styled.div`
  background-color: #f2f2f2;
  & > p {
    padding: 10px 0;
    margin: 0 30px;
    font-size: 14px;
  }
`;

const TotalPriceWrap = styled.div`
  margin: 20px;
`;

const InfoWrap = styled.div`
  border-top: 1px solid #e1e1e1;
  padding: 20px;
  max-height: 460px;
  overflow-y: auto;
`;

const InfoCard = styled.div`
  .info {
    display: flex;

    .input_box {
      .input_label {
        display: none;
      }

      &:first-child {
        margin-right: 10px;
      }

      input[type="text"] {
        &::placeholder {
          font-size: 12px;
        }
      }
    }
  }

  .description {
    .textarea_box {
      margin-bottom: 10px;

      textarea {
        height: 48px;
        overflow-y: hidden;

        &::placeholder {
          font-size: 14px;
        }
      }
      .input_label,
      .input_count {
        display: none;
      }
    }
  }
`;

const DeleteBtn = styled.button`
  background: url(${trash_image});
  background-size: 20px;
  width: 25px;
  height: 20px;
  margin: auto 0 auto 10px;
`;

const AddBtn = styled.button`
  border: 1px dashed #d8dbe1;
  background-color: #fff;
  font-size: 14px;
  font-weight: bold;
  color: #718096;
  height: 48px;
  width: 100%;
`;

const ModalFooter = styled.div`
  border-top: 1px solid #e1e1e1;
  padding: 25px;
  display: flex;
  justify-content: space-around;

  button {
    width: 135px;
    height: 38px;
    line-height: 38px;
    transition: border 0.3s, color 0.3s;
    font-size: 16px;
    font-weight: bold;
  }

  .cancel-btn {
    border: solid 1px #bdbdbd;
    background-color: #fff;
    color: #718096;
  }

  .save-btn {
    background-color: #066e4d;
    color: #fff;
  }
`;

const InstanceReceiptModal = (props) => {
  const {
    close,
    caseReceipt,
    setReceiptDeleteIds,
    receiptDeleteIds,
    saveCallback,
  } = props;

  const [receipts, setReceipts] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (caseReceipt.length > 0) {
      setReceipts([...caseReceipt]);
    }
  }, [caseReceipt]);

  useEffect(() => {
    if (receipts.length > 0) {
      setTotal(receipts.reduce((acc, cur) => acc + Number(cur.case_price), 0));
    } else {
      setTotal(0);
    }
  }, [receipts]);

  const addReceiptHandler = () => {
    setReceipts([
      ...receipts,
      { case_parts_no: "1", case_price: "", case_desc: "" },
    ]);
  };

  const deleteReceiptHandler = (index, id) => {
    setReceipts(receipts.filter((_, i) => i !== index));

    if (id) {
      setReceiptDeleteIds([...receiptDeleteIds, id]);
    }
  };

  const updateReceiptHandler = (i, e, val) => {
    setReceipts(
      receipts.map((recepit, index) =>
        i === index
          ? {
              ...recepit,
              [e.target.name]: val,
            }
          : recepit
      )
    );
  };

  const saveHandler = () => {
    if (receipts.find((recepit) => recepit.case_price === "")) {
      alert("숫자를 입력해주세요.");
      return;
    }
    saveCallback(receipts);
    close();
  };

  return (
    <Modal>
      <ModalContent>
        <ModalHeader>
          <h3>영수증</h3>
          <button className="exit_btn" onClick={close} />
        </ModalHeader>
        <ModalBanner>
          <p>시공별로 공정과 금액을 입력하시고 하단에 설명을 입력해 주세요.</p>
        </ModalBanner>
        <TotalPriceWrap>
          <Input
            type="text"
            label="*총 시공가격"
            value={total}
            withText={"만원"}
            readOnly
          />
        </TotalPriceWrap>
        <InfoWrap>
          {receipts.map((item, i) => (
            <InfoCard key={i}>
              <div className="info">
                <InputSelect
                  name="case_parts_no"
                  value={item.case_parts_no || ""}
                  onChange={updateReceiptHandler}
                  index={i}
                  option={consts.buildsoption}
                />
                <Input
                  type="text"
                  value={item.case_price}
                  withText={"만원"}
                  placeholder="숫자만 입력해 주세요."
                  onChange={updateReceiptHandler}
                  name="case_price"
                  index={i}
                />
                <DeleteBtn
                  onClick={() =>
                    deleteReceiptHandler(
                      i,
                      item.case_price_table_id || undefined
                    )
                  }
                />
              </div>
              <div className="description">
                <TextArea
                  placeholder="예) 실크, 합지 도배, 제품명 등 추가설명 표기"
                  name="case_desc"
                  value={item.case_desc || ""}
                  onChange={updateReceiptHandler}
                  index={i}
                />
              </div>
            </InfoCard>
          ))}
          <AddBtn onClick={addReceiptHandler}>+영수증 추가</AddBtn>
        </InfoWrap>
        <ModalFooter>
          <button className="cancel-btn" onClick={close}>
            취소
          </button>
          <button className="save-btn" onClick={saveHandler}>
            저장
          </button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default InstanceReceiptModal;
