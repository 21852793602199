import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import * as APIS from "../utils/service";

import Input from "../components/Input";

import { closeSub } from "../redux/subPopupSlice";

export default function Seller(props) {
  const { setValue, setValueText } = props;
  const dispatch = useDispatch();
  const userData = useSelector((s) => s.usersReducer);

  const [stxId, setStxId] = useState("");
  const [stx, setStx] = useState("");

  const [list, setList] = useState([]);

  const searchFunc = () => {
    const sender = {
      admin_table_id: userData.admin_table_id,
      seller_id: stxId,
      seller_biz_name: stx,
    };

    APIS.postData("/Admin/SearchPartner", sender, 1)
      .then((res) => {
        if (res.data.code === "OK" && res.data.seller_list) {
          setList(res.data.seller_list);
        } else {
          setList([]);
        }
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };

  const submitFunc = () => {
    let selectedId = "";
    let selectedText = "";

    const checkboxes = document.getElementsByClassName("seller_list");
    for (let cb of checkboxes) {
      if (cb.checked) {
        selectedId = cb.value;
        selectedText = cb.dataset.text;
      }
    }
    setValue(selectedId);
    setValueText(selectedText);

    if (!selectedId) {
      alert("파트너를 선택해 주세요.");
      return;
    }

    dispatch(closeSub());
  };

  const checkFunc = (i) => {
    document.getElementById("sellerrd" + i).checked = true;
  };

  return (
    <form className="popup_search">
      <div className="popup_search_box">
        <Input
          className="input_text"
          type="text"
          placeholder="파트너 ID를 입력해 주세요."
          name="stxId"
          value={stxId}
          setValue={setStxId}
          label="파트너 ID"
        />
        <Input
          className="input_text"
          type="text"
          placeholder="파트너명을 입력해 주세요."
          name="stx"
          value={stx}
          setValue={setStx}
          label="파트너명"
          withButton={"찾기"}
          withButtonPress={searchFunc}
        />

        <div className="table_section">
          <table className="tableStyle1">
            <thead>
              <tr>
                <th className="wd80">선택</th>
                <th className="wd130">파트너명</th>
                <th className="wd130">파트너 ID</th>
                <th className="wd110">대표자명</th>
                <th className="wdFull">주소</th>
              </tr>
            </thead>
            <tbody>
              {list.length > 0 ? (
                list.map((x, i) => {
                  return (
                    <tr
                      key={i}
                      onClick={() => checkFunc(i)}
                      className={"pointer"}
                    >
                      <td>
                        <input
                          type="radio"
                          id={"sellerrd" + i}
                          name="sellerrd"
                          className="radio seller_list"
                          value={x.seller_table_id}
                          data-text={x.seller_biz_name}
                        />
                        <label htmlFor={"sellerrd" + i}></label>
                      </td>
                      <td>{x.seller_biz_name}</td>
                      <td>{x.seller_id}</td>
                      <td>{x.seller_name}</td>
                      <td>
                        {x.seller_addr} {x.seller_addr_detail}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className="null_td" colSpan={5}>
                    파트너를 검색 해 주세요.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="popup_btn">
        <button
          type="button"
          className="popbtn cencle_btn"
          onClick={() => {
            dispatch(closeSub());
          }}
          style={{ width: "100%", marginRight: "10px" }}
        >
          취소
        </button>
        <button
          type="button"
          className="popbtn ok_btn"
          onClick={() => {
            submitFunc();
          }}
          style={{ width: "100%" }}
        >
          확인
        </button>
      </div>
    </form>
  );
}
