/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import * as APIS from "../../../utils/service";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export interface List {
  content_table_id: string;
  content_name: string;
  update_date: string;
  state: string;
  display_seq?: string;
}

interface Detail {
  content_seq?: string;
  content_table_id?: string;
  content_detail_img: string;
  content_detail_title: string;
  content_detail_desc: string;
  content_detail_url: string;
}
export interface Item {
  content_table_id: string;
  content_name: string;
  state: string;
  detail_list: Detail[];
}

interface Props {
  isList?: boolean;
  id?: string;
  searchData?: {
    state?: string;
    content_name?: string;
  };
}

const useHomeManageRecommend = (props?: Props) => {
  const { isList, id, searchData } = props ?? {};
  const userData = useSelector((s: any) => s.usersReducer);

  const [list, setList] = useState<List[]>([]);
  const [total, setTotal] = useState(0);

  const [detail, setDetail] = useState<Item>();

  const params = new URLSearchParams(window.location.search);
  const page = params.get("page") || "1";

  const navigate = useNavigate();
  const { pathname } = window.location;

  const getList = async (page: string) => {
    const params = {
      admin_table_id: userData.admin_table_id,
      page: page,
      ...searchData,
    };

    try {
      const res = await APIS.postData("/AdminContent/ContentList", params, 1);

      if (res.data.code === "OK") {
        setList(res.data.content_list);
        setTotal(res.data.total_cnt);
      } else {
        setList([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (isList) {
      getList(page);
    }
  }, [page]);

  const getTarget = async (): Promise<void> => {
    try {
      const res = await APIS.postData("/AdminContent/ContentTarget", {
        admin_table_id: userData.admin_table_id,
        content_table_id: id,
      });

      if (res.data.code === "OK") {
        const newData = { ...res.data };
        delete newData.code;

        setDetail(newData);
      } else {
        setDetail(undefined);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (id) {
      getTarget();
    }
  }, [id]);

  const search = () => {
    if (page === "1") {
      getList("1");
    } else {
      navigate({
        pathname: pathname,
        search: `?page=${1}`,
      });
    }
  };

  const create = async (
    data: Omit<Item, "state" | "content_table_id">
  ): Promise<boolean | undefined> => {
    const { content_name, detail_list } = data;

    if (
      !content_name ||
      detail_list.find((item) => !item.content_detail_title) ||
      detail_list.find((item) => !item.content_detail_desc) ||
      detail_list.find((item) => !item.content_detail_url) ||
      detail_list.find((item) => !item.content_detail_img)
    ) {
      alert("모든 정보를 입력해주세요.");
      return;
    }

    try {
      const res = await APIS.postData(
        "/AdminContent/ContentCreate",
        {
          ...data,
          admin_table_id: userData.admin_table_id,
        },
        1
      );

      if (res.data.code === "OK") {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const update = async (data: Item): Promise<boolean | undefined> => {
    const { content_name, detail_list } = data;

    if (
      !content_name ||
      detail_list.find((item) => !item.content_detail_title) ||
      detail_list.find((item) => !item.content_detail_desc) ||
      detail_list.find((item) => !item.content_detail_url) ||
      detail_list.find((item) => {
        const isNewImage = item.content_detail_img.includes("data:image");
        return !item.content_detail_img && !isNewImage;
      })
    ) {
      alert("모든 정보를 입력해주세요.");
      return;
    }

    try {
      const res = await APIS.postData(
        "/AdminContent/ContentUpdate",
        {
          ...data,
          admin_table_id: userData.admin_table_id,
          detail_list: detail_list.map((item) => {
            const isNewImage = item.content_detail_img.includes("data:image");

            return {
              ...item,
              content_detail_img: isNewImage
                ? item.content_detail_img
                : undefined,
            };
          }),
        },
        1
      );

      if (res.data.code === "OK") {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const remove = async (ids: string[]): Promise<boolean | undefined> => {
    try {
      if (ids.length > 0) {
        if (window.confirm("해당 추천 컨텐츠를 삭제 하시겠습니까?")) {
          const res = await APIS.postData(
            "/AdminContent/ContentDel",
            {
              admin_table_id: userData.admin_table_id,
              content_table_ids: ids,
            },
            1
          );

          if (res.data.code === "OK") {
            getList(page);

            return true;
          } else {
            return false;
          }
        }
      } else {
        alert("삭제 할 데이터를 선택해주세요.");
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const updateSeq = async (
    data: {
      content_table_id: string;
      display_seq: string;
    }[]
  ): Promise<boolean | undefined> => {
    if (window.confirm("노출순위를 변경하시겠습니까?")) {
      try {
        const res = await APIS.postData(
          "/AdminContent/ContentSeqUpdate",
          {
            admin_table_id: userData.admin_table_id,
            content_data: data,
          },
          1
        );

        if (res.data.code === "OK") {
          return true;
        } else {
          return false;
        }
      } catch (err) {
        console.log(err);
        return false;
      }
    }
  };

  return {
    list,
    total,
    page,
    create,
    update,
    search,
    remove,
    updateSeq,
    detail,
  };
};

export default useHomeManageRecommend;
