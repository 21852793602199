import React, { useState, useEffect } from "react";
import Modal from "../../Common/ModalPortal";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Datas } from "../List";
import images from "../../../libs/images";
import Input from "../../Common/Input/Input";
import InputWithButton from "../../Common/Input/InputWithButton";
import DateComponent from "../../Common/Date/Date";
import Button from "../../Common/Button/Button";
import moment from "moment";
import * as APIS from "../../../utils/service";

const Container = styled.div`
  width: 760px;
  background-color: #ffffff;
  margin: auto;
`;

const Header = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;

  & > h3 {
    margin: auto 0;
  }
`;

const Content = styled.div`
  padding: 20px;
`;

const Footer = styled.div`
  padding: 20px;
  border-top: 1px solid #e1e1e1;
  display: flex;
  justify-content: center;
  gap: 15px;
`;

interface Props {
  data: Datas;
  close: () => void;
  submitCallback: () => void;
}

const UpdateSafeNumberModal = (props: Props) => {
  const { data, close, submitCallback } = props;

  const [expire, setExpire] = useState<string>("");
  const [memo, setMemo] = useState("");

  useEffect(() => {
    if (data) {
      setExpire(data.expire_date);
      setMemo(data.s_memo);
    }
  }, [data]);

  const updateHandler = async () => {
    if (window.confirm("안심번호를 변경하시겠습니까?")) {
      try {
        const params = {
          safe_table_id: data.safe_table_id,
          seller_table_id: data.seller_table_id,
          tel_num1: data.tel_num1,
          expire_date: expire,
          s_memo: memo,
        };

        const res = await APIS.postData("/AdminSafety/SafetyUpdate", params, 1);

        if (res.data.code === "OK") {
          alert("안심번호가 변경되었습니다.");
          submitCallback();
          close();
        } else {
          alert("알수 없는 문제가 발생하였습니다. 다시 시도 해주세요.");
          return;
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <Modal>
      <Container>
        <Header>
          <h3>안심번호 변경</h3>
          <button onClick={close}>
            <img src={images.exit} alt="exit_btn" />
          </button>
        </Header>
        <Content>
          <div>
            <InputWithButton
              label="* 파트너ID"
              value={data.seller_biz_name}
              disabled
              size="full"
              onClick={() => null}
              buttonText="파트너 찾기"
              disabled_button
            />
          </div>
          <div
            css={css`
              margin: 20px 0 0 0;
            `}
          >
            <DateComponent
              label="* 유효기간"
              placeholder="유효기간"
              size="half"
              value={new Date(expire || data.expire_date)}
              onChange={(date) => {
                setExpire(moment(date).format("YYYY-MM-DD 23:59:59"));
              }}
            />
          </div>
          <div
            css={css`
              margin: 20px 0 0 0;
            `}
          >
            <Input
              label="메모"
              value={memo}
              size="full"
              onChange={(e) => setMemo(e.target.value)}
            />
          </div>
        </Content>
        <Footer>
          <Button theme="tertiary" onClick={close}>
            취소
          </Button>
          <Button onClick={updateHandler}>확인</Button>
        </Footer>
      </Container>
    </Modal>
  );
};

export default UpdateSafeNumberModal;
