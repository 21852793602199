/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import * as APIS from "../../utils/service";
import { findJson } from "../../utils/utils";

import { open, close } from "../../redux/popupSlice";

import Input from "../../components/Input";
import InputSelect from "../../components/InputSelect";
import InputDate from "../../components/InputDate";

import consts from "../../libs/consts";

export default function PayAdd(props) {
  const { x } = props;
  const dispatch = useDispatch();
  const userData = useSelector((s) => s.usersReducer);

  const [payment_table_id, setpayment_table_id] = useState("");
  const [partner, setPartner] = useState("파트너명");
  const [sdate, setSdate] = useState("");
  const [grade, setGrade] = useState("1");
  const [day_type, setday_type] = useState("1");

  const [payment_type, setpayment_type] = useState("");
  const [cnt, setcnt] = useState("");
  const [price, setprice] = useState("");
  const [payment_state, setpayment_state] = useState("");
  const [period, setperiod] = useState("");
  const [cont_table_id, setcont_table_id] = useState("");
  const [sub_types, setsub_types] = useState([]); // 정액제요금 등급명 배열

  useEffect(() => {
    if (x) {
      const sender1 = {
        admin_table_id: userData.admin_table_id,
        type: "0",
      };

      APIS.postData("/SetAdmin/GetAdminSetting", sender1, 1)
        .then((res) => {
          setsub_types(res.data.sub_types);
        })
        .catch((e) => {
          console.log("catch", e);
        });

      const sender2 = {
        admin_table_id: userData.admin_table_id,
        payment_table_id: x.payment_table_id,
      };

      APIS.postData("/AdminPay/PayTarget", sender2)
        .then((res) => {
          let data = res.data.pay_list[0];

          setpayment_table_id(data.payment_table_id);
          setcont_table_id(data.cont_table_id);

          setpayment_type(data.payment_type);
          setGrade(data.grade);
          setcnt(data.cnt);
          setprice(data.price);
          setpayment_state(data.payment_state);
          setperiod(data.period);
          setday_type(data.period_type);
          setPartner(x.seller_biz_name);
          setSdate(moment(data.start_date).format("YYYY.MM.DD"));
        })
        .catch((e) => {
          console.log("catch", e);
        });
    }
  }, [x]);

  const submitFunc = () => {
    let sender = null;

    if (payment_type === "1") {
      sender = {
        admin_table_id: userData.admin_table_id,
        payment_table_id: payment_table_id,
        payment_type: payment_type,
        price: price,
        payment_state: payment_state,
      };
    } else if (payment_type === "2") {
      sender = {
        admin_table_id: userData.admin_table_id,
        payment_table_id: payment_table_id,
        payment_type: payment_type,
        price: price,
        payment_state: payment_state,
        grade: grade,
        cnt: cnt,
        period: period,
        period_type: sdate ? day_type : "",
        start_date: sdate,
      };
    } else {
      sender = {
        admin_table_id: userData.admin_table_id,
        payment_table_id: payment_table_id,
        payment_type: payment_type,
        price: price,
        payment_state: payment_state,
        cnt: cnt,
      };
    }

    APIS.postData("/AdminPay/PayUpdate", sender, 1)
      .then(() => {
        dispatch(close());
        setTimeout(() => {
          dispatch(
            open({
              message: "저장되었습니다.",
            })
          );
        }, 50);
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };

  return (
    <form className="popup_form">
      <div className="admin_pay_form_list">
        {payment_type === "1" && (
          <Input
            className="input_text"
            type="text"
            placeholder="숫자만 입력해 주세요."
            name="cont_table_id"
            value={cont_table_id}
            setValue={setcont_table_id}
            label="계약번호"
            readOnly={true}
            onLinkText={"자세히보기"}
          />
        )}

        <Input
          className="input_text"
          type="text"
          placeholder="숫자만 입력해 주세요."
          name="payment_table_id"
          value={payment_table_id}
          setValue={setpayment_table_id}
          label="결제번호"
          readOnly={true}
        />

        {(payment_type === "2" || payment_type === "3") && (
          <Input
            className="input_text"
            type="text"
            placeholder="내용을 입력해 주세요."
            name="partner"
            value={partner}
            setValue={setPartner}
            label="파트너명"
            readOnly={true}
          />
        )}

        <InputSelect
          name="paySelect"
          label="결제상태"
          value={payment_state}
          setValue={setpayment_state}
          option={consts.payStateOption}
        />

        <Input
          className="input_text"
          type="text"
          placeholder="내용을 입력해 주세요."
          name="pay_type"
          value={findJson(consts.paymentoption, payment_type)}
          setValue={setpayment_type}
          label="결제구분"
          readOnly={true}
        />

        <Input
          className="input_text"
          type="text"
          placeholder="숫자만 입력해 주세요."
          name="price"
          value={price}
          setValue={setprice}
          label="결제금액"
          valid={"num"}
          withText={"만원"}
        />

        {payment_type === "2" && (
          <InputSelect
            name="paySelect"
            label="등급"
            value={grade}
            setValue={setGrade}
            optionNotKey={[...new Set([grade, ...sub_types])]}
          />
        )}

        {(payment_type === "2" || payment_type === "3") && (
          <Input
            className="input_text"
            type="text"
            placeholder="숫자만 입력해 주세요."
            name="cnt"
            value={cnt}
            setValue={setcnt}
            label="수량"
            valid={"num"}
          />
        )}

        {payment_type === "2" && (
          <div className="popup_form_one_line">
            <Input
              className="input_text"
              type="text"
              placeholder="숫자만 입력해 주세요."
              name="period"
              value={period}
              setValue={setperiod}
              label="기간"
              valid={"num"}
            />
            <InputSelect
              name="paySelect"
              label=" "
              value={day_type}
              setValue={setday_type}
              option={consts.day_option}
            />
          </div>
        )}

        {payment_type === "2" && (
          <InputDate
            type="text"
            placeholder="시작날짜 선택"
            name="sdate"
            label="시작일"
            dateValue={sdate}
            setDateValue={setSdate}
          />
        )}
      </div>

      <div className="popup_btn">
        <button
          type="button"
          className="popbtn cencle_btn"
          onClick={() => {
            dispatch(close());
          }}
          style={{ width: "100%", marginRight: "10px" }}
        >
          취소
        </button>
        <button
          type="button"
          className="popbtn ok_btn"
          onClick={() => {
            submitFunc();
          }}
          style={{ width: "100%" }}
        >
          수정
        </button>
      </div>
    </form>
  );
}
