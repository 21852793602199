import { css } from "@emotion/react";
import * as Colors from "../../../constants/color";
import Checkbox from "../Checkbox/Checkbox";
import { useCallback } from "react";

const tableStyles = css`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
`;

const headStyles = css`
  font-size: 12px;
  font-weight: bold;
  color: ${Colors.blue_700};
  background-color: ${Colors.blue_100};
  border-top: 1px solid ${Colors.blue_300};
  border-bottom: 1px solid ${Colors.blue_300};
  height: 30px;
`;

const bodyStyles = css`
  font-size: 14px;
  padding: 13px 12px;
  border-bottom: 1px solid ${Colors.blue_200};
`;

const alignStyles = {
  left: css`
    text-align: left;
    padding-left: 12px;
  `,
  center: css`
    text-align: center;
  `,
  right: css`
    text-align: right;
    padding-right: 12px;
  `,
};

const labelStyles = css`
  & > label {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
`;
interface Data {
  [fields: string]: string | number;
}

interface Column {
  title: string;
  key: string;
  algin?: "left" | "center" | "right";
  render?: (data: Data | any) => React.ReactNode;
  className?: string;
}

interface TableProps {
  columns: Column[];
  datas: Data[] | any;
  rowCallback?: (data: any) => void;
  checkboxSelection?: boolean;
  selectionIds?: string[];
  onSelectionChange?: React.Dispatch<React.SetStateAction<string[]>>;
}

const Table = (props: TableProps) => {
  const {
    columns,
    datas,
    rowCallback,
    checkboxSelection,
    selectionIds,
    onSelectionChange,
  } = props;

  const allCheckHandler = () => {
    if (!selectionIds || !onSelectionChange) {
      return;
    }

    const { key } = columns[0];

    if (selectionIds.length === 0) {
      onSelectionChange(datas.map((data: Data) => data[key]));
    } else {
      onSelectionChange([]);
    }
  };

  const checkboxHandler = useCallback(
    (id: string) => {
      if (!selectionIds || !onSelectionChange) {
        return;
      }

      if (selectionIds.find((select) => select === id)) {
        onSelectionChange((prevData) => prevData.filter((prev) => prev !== id));
      } else {
        onSelectionChange((prevData) => [...prevData, id]);
      }
    },
    [selectionIds, onSelectionChange]
  );

  return (
    <div>
      <table css={[tableStyles]}>
        <thead>
          <tr>
            {checkboxSelection && (
              <th
                css={[
                  headStyles,
                  labelStyles,
                  css`
                    width: 40px;
                  `,
                ]}
              >
                <Checkbox
                  labelText=""
                  value={selectionIds && selectionIds.length > 0 ? true : false}
                  onChange={allCheckHandler}
                />
              </th>
            )}
            {columns.map((col) => (
              <th
                key={col.key}
                css={[headStyles, alignStyles[col.algin || "left"]]}
                className={col.className}
              >
                {col.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {datas.length > 0 ? (
            datas.map((data: Data, i: number) => (
              <tr
                key={i}
                css={css`
                  cursor: pointer;
                  &:hover {
                    background-color: ${Colors.blue_100};
                  }
                `}
                onClick={() => {
                  if (rowCallback) {
                    rowCallback(data);
                  }
                }}
              >
                {checkboxSelection && (
                  <td css={[bodyStyles]}>
                    <div onClick={(e) => e.stopPropagation()} css={labelStyles}>
                      <Checkbox
                        value={
                          !!selectionIds?.find(
                            (id) => id === data[columns[0].key]
                          )
                        }
                        onChange={() => {
                          checkboxHandler(data[columns[0].key] as string);
                        }}
                        labelText=""
                      />
                    </div>
                  </td>
                )}
                {columns.map((col) => {
                  const { render } = col;

                  return (
                    <td
                      key={col.key}
                      css={[bodyStyles, alignStyles[col.algin || "left"]]}
                    >
                      {(render && render(data)) || data[col.key]}
                    </td>
                  );
                })}
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan={
                  checkboxSelection ? columns.length + 1 : columns.length
                }
              >
                <div
                  css={css`
                    padding: 30px 0;
                    font-size: 14px;
                    color: ${Colors.gray_400};
                    border-bottom: 1px solid ${Colors.blue_300};
                    text-align: center;
                  `}
                >
                  데이터가 없습니다.
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
