import React from "react";

import consts from "../libs/consts";

export default function PGForm() {
  return (
    <>
      <form name="payForm" accept-charset="euc-kr" className="pgForm">
        <input type="text" name="CASH_GB" id="CASH_GB" size="50" value="CI" />
        <input
          type="text"
          name="CI_SVCID"
          id="CI_SVCID"
          size="50"
          value="220210111486"
        />
        <input
          type="text"
          name="Tradeid"
          id="Tradeid"
          size="50"
          value="Tradeid123456789"
        />
        <input type="text" name="PAY_MODE" id="PAY_MODE" value="10" />{" "}
        {/* 10 = 실서버, 00 = 테스트서버 */}
        <input
          type="text"
          name="Siteurl"
          id="Siteurl"
          size="50"
          value="test.co.kr"
        />
        <input
          type="text"
          name="DI_CODE"
          id="DI_CODE"
          size="50"
          value=""
          maxlength="12"
        />
        <input type="text" name="Cryptyn" id="Cryptyn" size="1" value="Y" />
        <input type="text" name="Keygb" id="Keygb" value="1" />{" "}
        {/* 암호화키 번호 10 = 실서버, 00 = 테스트서버 */}
        <input
          type="text"
          name="Okurl"
          id="Okurl"
          size="50"
          value={consts.apiBaseUrlPartner + "/Certi/MobileCheck"}
        />
        <input type="text" name="Closeurl" id="Closeurl" size="50" value="" />
        <input type="text" name="CALL_TYPE" id="CALL_TYPE" value="P" />{" "}
        {/* 인증창종류 P = 팝업, SELF = 페이지전환, I = 아이프레임 */}
        <input
          type="text"
          name="IFRAME_NAME"
          id="IFRAME_NAME"
          size="30"
          value=""
        />
        <input
          type="text"
          name="MSTR"
          id="MSTR"
          size="50"
          value={consts.homeUrl}
        />
        <input type="text" name="CI_Mode" id="CI_Mode" value="61" />
        <input type="text" name="Callback" id="Callback" size="50" value="" />
        <input
          type="text"
          name="Smstext"
          id="Smstext"
          size="50"
          value="SMS문구입니다."
        />
        <input type="text" name="Lmstitle" id="Lmstitle" size="50" value="" />
        <input
          type="text"
          name="Lmstext"
          id="Lmstext"
          size="50"
          value="LMS문구입니다"
        />
      </form>
    </>
  );
}
