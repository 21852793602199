import React from "react";

import { numFormat } from "../utils/utils";

export default function FrontTop10(props) {
  const { rows, listClickfunc } = props;

  return (
    <>
      {rows && (
        <li className="">
          <img
            src={rows.style_imgs[0].img_url}
            onClick={() => listClickfunc(rows)}
            alt="row_img"
          />
          <p className="home_detail_popup_right_title1">{rows.model_name}</p>
          <p className="home_detail_popup_right_title2">
            {numFormat(rows.price)}원
          </p>
        </li>
      )}
    </>
  );
}
