import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as APIS from "../../utils/service";
import { close } from "../../redux/popupSlice";
import { closeSub } from "../../redux/subPopupSlice";

export default function Area(props) {
  const { value, setValue, setValueText } = props;
  const dispatch = useDispatch();
  const subpopup = useSelector((s) => s.subPopupReducer);
  const [cityList, setCityList] = useState("");
  const [city, setCity] = useState("");

  useEffect(() => {
    APIS.postData("/AreaInfo/GetAreaOne", "", 2)
      .then((res) => {
        setCityList(res.data.area);
        setCity(res.data.area[0].area_id);
      })
      .catch((e) => {
        console.log("catch", e);
      });
  }, []);

  function allchk(id) {
    const ev = document.getElementById("allck" + id).checked;
    const checkboxes = document.getElementsByClassName("checkbox_list" + id);

    for (let cb of checkboxes) {
      cb.checked = ev;
    }
  }

  function onechk(id) {
    const checkboxes = document.getElementsByClassName("checkbox_list" + id);

    let chk = true;

    for (let cb of checkboxes) {
      if (!cb.checked) {
        chk = false;
        break;
      }
    }

    document.getElementById("allck" + id).checked = chk;
  }

  const submitFunc = () => {
    let arr = [];
    let arrText = [];

    for (let x of cityList) {
      const allchk = document.getElementById("allck" + x.area_id);
      if (allchk.checked) {
        arr.push(allchk.value);
        arrText.push(allchk.dataset.text);
      } else {
        const checkboxes = document.getElementsByClassName(
          "checkbox_list" + x.area_id
        );
        for (let cb of checkboxes) {
          if (cb.checked) {
            arr.push(cb.value);
            arrText.push(cb.dataset.text);
          }
        }
      }
    }

    setValue(arr);
    setValueText(arrText.join(", "));

    if (subpopup.open) {
      dispatch(closeSub());
    } else {
      dispatch(close());
    }
  };

  return (
    <form className="popup_form">
      <div className="area_box">
        <ul className="city_box">
          {cityList &&
            cityList.map((x, i) => {
              return (
                <li
                  key={i}
                  className={x.area_id === city ? "active" : ""}
                  onClick={() => setCity(x.area_id)}
                >
                  {x.area_name}
                </li>
              );
            })}
        </ul>

        {cityList &&
          cityList.map((x, i) => {
            return (
              <ul
                key={i}
                className={"area_list " + (x.area_id === city ? "active" : "")}
              >
                <li>
                  <input
                    type="checkbox"
                    name={"allck" + x.area_id}
                    id={"allck" + x.area_id}
                    className={
                      "checkbox3 " + (!x.area_2nd ? "checkbox_list" : "")
                    }
                    defaultChecked={value && value.includes(x.area_id)}
                    onChange={() => allchk(x.area_id)}
                    value={x.area_id}
                    data-text={x.area_name}
                  />
                  <label htmlFor={"allck" + x.area_id}>전체</label>
                </li>
                {x.area_2nd &&
                  x.area_2nd.map((xx, ii) => {
                    return (
                      <li key={ii}>
                        <input
                          type="checkbox"
                          name={"areachk" + x.area_id + "" + ii}
                          id={"areachk" + x.area_id + "" + ii}
                          className={
                            "checkbox3 checkbox_list checkbox_list" + x.area_id
                          }
                          defaultChecked={
                            value &&
                            (value.includes(x.area_id) ||
                              value.includes(xx.area_detail_id))
                          }
                          onChange={() => onechk(x.area_id)}
                          data-key={i}
                          value={xx.area_detail_id}
                          data-text={xx.area_detail_name}
                        />
                        <label htmlFor={"areachk" + x.area_id + "" + ii}>
                          {xx.area_detail_name}
                        </label>
                      </li>
                    );
                  })}
              </ul>
            );
          })}
      </div>

      <div className="popup_btn">
        <button
          type="button"
          className="popbtn cencle_btn"
          onClick={() => {
            subpopup.open ? dispatch(closeSub()) : dispatch(close());
          }}
          style={{ width: "100%", marginRight: "10px" }}
        >
          취소
        </button>
        <button
          type="button"
          className="popbtn ok_btn"
          onClick={() => {
            submitFunc();
          }}
          style={{ width: "100%" }}
        >
          확인
        </button>
      </div>
    </form>
  );
}
