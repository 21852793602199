import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import moment from "moment";
import * as Colors from "../../constants/color";
import { css } from "@emotion/react";
import { CiEdit } from "react-icons/ci";
import { VscClose } from "react-icons/vsc";
import * as APIS from "../../utils/service";
import { useSelector } from "react-redux";
import { TbClipboardText } from "react-icons/tb";

const memoStyles = css`
  margin-left: 8px;
  color: ${Colors.black};

  & > svg {
    display: block;
    width: 15px;
    height: 15px;
  }
`;

export default function ScheduleCal(props) {
  const { listDetail, date, callback } = props;
  const [isMemoEdits, setIsMemoEdits] = useState([]);
  const [memos, setMemos] = useState([]);
  const userData = useSelector((s) => s.usersReducer);

  useEffect(() => {
    if (listDetail.length > 0) {
      setIsMemoEdits(Array.from({ length: listDetail.length }, () => false));
      setMemos(listDetail.map((list) => list.memo));
    }
  }, [listDetail]);

  const saveMemoHandler = async (x, index) => {
    const memo = memos.find((_, i) => i === index);

    const params = {
      memo,
      order_table_id: x.order_table_id,
      order_seller_id: x.order_seller_id,
      seller_table_id: userData.seller_table_id,
    };

    try {
      const res = await APIS.postData("/OrderInfo/ScheduleMemoEdit", params, 2);

      if (res.data.code === "OK") {
        callback();
        setIsMemoEdits(
          isMemoEdits.map((item, i) => (i === index ? false : item))
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="day_calender">
      <p className="title">캘린더</p>

      <Calendar
        onChange={props.setDate}
        value={date}
        onActiveStartDateChange={(date) => {
          props.setMonth(moment(date.activeStartDate).format("M"));
          props.setYear(moment(date.activeStartDate).format("YYYY"));
        }}
        calendarType={"Hebrew"}
        defaultView={"month"}
        formatDay={(locale, date) => moment(date).format("DD")}
        className="day_calender_view"
        tileContent={({ date, activeStartDate, view }) => {
          let meetingS = []; // 고객미팅
          let workS = []; // 시공
          let orderS = []; // 견적접수

          props.list.map((x, i) => {
            let startDateMonth = moment(activeStartDate).format("M");
            let tileMonth = moment(date).format("M");
            if (startDateMonth === tileMonth) {
              if (
                moment(x.meet_date).format("YYYY-MM-DD") ===
                moment(date).format("YYYY-MM-DD")
              ) {
                meetingS.push({ id: x.id, isMemo: !!x.memo });
              } else if (
                moment(x.work_start_date).format("YYYY-MM-DD") <=
                  moment(date).format("YYYY-MM-DD") &&
                moment(x.work_end_date).format("YYYY-MM-DD") >=
                  moment(date).format("YYYY-MM-DD")
              ) {
                workS.push({ id: x.id, isMemo: !!x.memo });
              } else if (
                moment(x.reg_date).format("YYYY-MM-DD") ===
                moment(date).format("YYYY-MM-DD")
              ) {
                orderS.push({ id: x.id, isMemo: !!x.memo });
              }
            }

            return true;
          });

          return (
            <ul
              className={
                meetingS.length > 0 || workS.length > 0 || orderS.length > 0
                  ? "calender_day_ul active"
                  : "calender_day_ul"
              }
            >
              {meetingS.length > 0 && (
                <li className="cl_01">
                  <span className="li_dot">•</span>
                  <span>고객미팅</span>
                  {meetingS.length}
                  {meetingS.find((meeting) => meeting.isMemo) && (
                    <div css={[memoStyles]}>
                      <TbClipboardText />
                    </div>
                  )}
                </li>
              )}
              {workS.length > 0 && (
                <li className="cl_02">
                  <span className="li_dot">•</span>
                  <span>시공</span>
                  {workS.length}
                  {workS.find((work) => work.isMemo) && (
                    <div css={[memoStyles]}>
                      <TbClipboardText />
                    </div>
                  )}
                </li>
              )}
              {orderS.length > 0 && (
                <li className="cl_03">
                  <span className="li_dot">•</span>
                  <span>견적접수</span>
                  {orderS.length}
                  {orderS.find((order) => order.isMemo) && (
                    <div css={[memoStyles]}>
                      <TbClipboardText />
                    </div>
                  )}
                </li>
              )}
            </ul>
          );
        }}
      />

      <div className="day_calender_detail">
        <div className="title_box">
          <p className="title">{moment(date).format("YYYY년 MM월 DD일")} </p>
          <p className="msg">
            요청번호를 누르면 상세 내용을 확인할 수 있습니다.
          </p>
        </div>

        <ul className="day_calender_detail_ul">
          {listDetail.length > 0 ? (
            listDetail.map((x, i) => {
              let cl = "cl_7";
              let text = "";

              if (
                moment(x.meet_date).format("YYYY-MM-DD") ===
                moment(date).format("YYYY-MM-DD")
              ) {
                cl = "cl_01";
                text = "고객미팅";
              } else if (
                moment(x.work_start_date).format("YYYY-MM-DD") <=
                  moment(date).format("YYYY-MM-DD") &&
                moment(x.work_end_date).format("YYYY-MM-DD") >=
                  moment(date).format("YYYY-MM-DD")
              ) {
                cl = "cl_02";
                text = "시공";
              } else if (
                moment(x.reg_date).format("YYYY-MM-DD") ===
                moment(date).format("YYYY-MM-DD")
              ) {
                cl = "cl_03";
                text = "견적접수";
              }

              return (
                <React.Fragment key={i}>
                  <li
                    key={i}
                    onClick={() => props.updateFunc(x.order_table_id)}
                    css={css`
                      padding: 35px 20px !important;
                      & > div {
                        height: auto !important;
                        padding: 5px 0;
                      }
                    `}
                  >
                    <div>
                      <p className="title">업무</p>
                      <p className={"content " + cl}>
                        <span>•</span>
                        {text}
                      </p>
                    </div>
                    {moment(x.meet_date).format("YYYY-MM-DD") ===
                    moment(date).format("YYYY-MM-DD") ? (
                      <div>
                        <p className="title">미팅일시</p>
                        <p className="content">
                          {x.meet_date === "0000-00-00 00:00:00" || null
                            ? "미기입"
                            : moment(x.meet_date).format("YYYY.MM.DD HH:mm")}
                        </p>
                      </div>
                    ) : moment(x.work_start_date).format("YYYY-MM-DD") <=
                        moment(date).format("YYYY-MM-DD") &&
                      moment(x.work_end_date).format("YYYY-MM-DD") >=
                        moment(date).format("YYYY-MM-DD") ? (
                      <>
                        <div>
                          <p className="title">시작일</p>
                          <p className="content">
                            {x.work_start_date === "0000-00-00 00:00:00" || null
                              ? "미기입"
                              : moment(x.work_start_date).format("YYYY.MM.DD")}
                          </p>
                        </div>
                        <div>
                          <p className="title">종료일</p>
                          <p className="content">
                            {x.work_end_date === "0000-00-00 00:00:00" || null
                              ? "미기입"
                              : moment(x.work_end_date).format("YYYY.MM.DD")}
                          </p>
                        </div>
                      </>
                    ) : (
                      <>
                        <div>
                          <p className="title">제공일</p>
                          <p className="content">
                            {moment(x.reg_date).format("YYYY.MM.DD")}
                          </p>
                        </div>
                        <div>
                          <p className="title">수정일</p>
                          <p className="content">
                            {moment(x.update_date).format("YYYY.MM.DD")}
                          </p>
                        </div>
                      </>
                    )}

                    <div>
                      <p className="title">상태</p>
                      <p className="content">{x.order_state_word}</p>
                    </div>
                    <div>
                      <p className="title">의뢰정보</p>
                      <p className="content link">
                        {x.apt === null ? "아파트 이름x" : x.apt}({x.pyung})/
                        {x.area}
                      </p>
                    </div>
                    <div>
                      <p className="title">요청번호</p>
                      <p className="content link">{x.order_table_id}</p>
                    </div>
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <p className="title">메모</p>
                      <button
                        onClick={() => {
                          setIsMemoEdits(
                            isMemoEdits.map((memoEdit, index) =>
                              i === index ? !memoEdit : memoEdit
                            )
                          );
                        }}
                        css={css`
                          & > svg {
                            display: block;
                            width: 20px;
                            height: 20px;
                          }
                        `}
                      >
                        {isMemoEdits[i] ? <VscClose /> : <CiEdit />}
                      </button>
                    </div>
                    <div>
                      {isMemoEdits[i] ? (
                        <div
                          css={css`
                            width: 100%;
                            display: flex;
                            flex-wrap: wrap;
                          `}
                        >
                          <textarea
                            css={css`
                              width: 100%;
                              border: 1px solid ${Colors.blue_300};
                              padding: 5px 10px;
                              resize: none;
                              height: 45px;
                            `}
                            onClick={(e) => e.stopPropagation()}
                            onChange={(e) =>
                              setMemos(
                                memos.map((memo, index) =>
                                  i === index ? e.target.value : memo
                                )
                              )
                            }
                            value={memos[i]}
                            id={`memo_${x.order_table_id}`}
                            maxLength={500}
                          />
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              saveMemoHandler(x, i);
                            }}
                            css={css`
                              width: 100px;
                              margin: 10px 0 0 auto;
                              height: 28px;
                              cursor: pointer;
                              background: none;
                              border: 1px solid ${Colors.blue_300};
                              color: ${Colors.blue_700};

                              &:hover {
                                border: 1px solid ${Colors.primary};
                                color: ${Colors.black};
                              }
                            `}
                          >
                            저장
                          </button>
                        </div>
                      ) : (
                        <p
                          css={css`
                            color: ${Colors.blue_600};
                          `}
                        >
                          {x.memo ||
                            "미팅, 시공 등 특이사항에 대해 메모할 수 있습니다."}
                        </p>
                      )}
                    </div>
                  </li>
                </React.Fragment>
              );
            })
          ) : (
            <li key="null" className="null">
              <p>주요일정이 없습니다.</p>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}
