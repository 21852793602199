import { useState } from "react";
import styled from "@emotion/styled";
import { numFormat } from "../../utils/utils";
import Pagination from "../Common/Pagination/Pagination";
import useList from "./List.hook";
import Table from "../Common/Table/Table";
import DetailModal from "./Modal/DetailModal";

const Container = styled.div`
  margin: 30px 0 20px 0;
`;

const LifeConstructionList = () => {
  const [detailId, setDetailId] = useState(null);
  const params = new URLSearchParams(window.location.search);
  const page = params.get("page") || "1";

  const { list, total } = useList(Number(page));

  const col = [
    {
      key: "life_order_table_id",
      title: "번호",
    },
    {
      key: "area",
      title: "지역",
      render: (data: any) => (
        <span>{`${data.city || "-"} ${data.area || "-"}`}</span>
      ),
    },
    {
      key: "life_order_phone",
      title: "번호",
    },
    {
      key: "price",
      title: "가격",
      render: (data: any) => (
        <span>{numFormat(data.life_order_total_price)}원</span>
      ),
    },
    {
      key: "life_order_reg_date",
      title: "견적 요청 날짜",
    },
  ];

  return (
    <Container>
      <Table
        columns={col}
        datas={list}
        rowCallback={(data) => setDetailId(data.life_order_table_id)}
      />
      <Pagination curPage={page} total={total} pageSize={15} />
      {detailId && (
        <DetailModal close={() => setDetailId(null)} id={detailId} />
      )}
    </Container>
  );
};

export default LifeConstructionList;
