import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import * as APIS from "../../utils/service";
import { closeSub } from "../../redux/subPopupSlice";
import InputSelect from "../../components/InputSelect";
import InputDate from "../../components/InputDate";

export default function Meet(props) {
  const { id, date, setDate } = props;
  const dispatch = useDispatch();
  const userData = useSelector((s) => s.usersReducer);

  const [meetDate, setMeetDate] = useState(
    date ? moment(date, "YYYYMMDD HH:mm:ss").format("YYYY.MM.DD") : ""
  ); // 날짜
  const [meetHour, setMeetHour] = useState(
    date ? moment(date, "YYYYMMDD HH:mm:ss").format("HH") : ""
  ); // 시
  const [meetMin, setMeetMin] = useState(
    date ? moment(date, "YYYYMMDD HH:mm:ss").format("mm") : ""
  ); // 분

  const submitFunc = () => {
    if (!meetDate) {
      alert("날짜를 선택해 주세요.");
      return;
    }
    if (!meetHour) {
      alert("시간을 선택해 주세요.");
      return;
    }
    if (!meetMin) {
      alert("분을 선택해 주세요.");
      return;
    }

    let full_date = meetDate + " " + meetHour + ":" + meetMin + ": 00";
    setDate(full_date);

    if (id) {
      let urls = "/OrderInfo/OrderMeetDate";
      let types = 2;
      let sender = {
        order_table_id: id,
        seller_table_id: userData.seller_table_id,
        meet_date: full_date,
      };

      APIS.postData(urls, sender, types).catch((e) => {
        console.log("catch", e);
      });
    }
    dispatch(closeSub());
  };

  return (
    <form className="popup_form" style={{ marginTop: "10px" }}>
      <InputDate
        type="text"
        placeholder="날짜선택"
        name="meet"
        label={"미팅날짜"}
        dateValue={meetDate}
        setDateValue={setMeetDate}
      />

      <div className="input_select_mulit_box">
        <InputSelect
          name="hour"
          placeholder="시"
          label="시간"
          value={meetHour}
          setValue={setMeetHour}
          optionHour={true}
        />
        <InputSelect
          name="min"
          placeholder="분"
          label=""
          value={meetMin}
          setValue={setMeetMin}
          optionMin={true}
        />
      </div>

      <div className="popup_btn">
        <button
          type="button"
          className="popbtn cencle_btn"
          onClick={() => {
            dispatch(closeSub());
          }}
          style={{ width: "100%", marginRight: "10px" }}
        >
          취소
        </button>
        <button
          type="button"
          className="popbtn ok_btn"
          onClick={() => {
            submitFunc();
          }}
          style={{ width: "100%" }}
        >
          확인
        </button>
      </div>
    </form>
  );
}
