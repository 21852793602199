import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as APIS from "../../utils/service";
import { close } from "../../redux/popupSlice";
import { openSub } from "../../redux/subPopupSlice";
import Input from "../../components/Input";
import InputSelect from "../../components/InputSelect";
import consts from "../../libs/consts";

export default function Update(props) {
  const { x } = props;
  const dispatch = useDispatch();
  const userData = useSelector((s) => s.usersReducer);

  const [seller_user_id] = useState(x ? x.seller_user_id : "");

  const [mbId, setMbId] = useState(x.seller_id);
  const [hp, setHp] = useState(x ? x.seller_phone.substr(3) : "");
  const [hpFront, setHpFront] = useState(
    x ? x.seller_phone.substr(0, 3) : "010"
  );
  const [fullHp, setFullHp] = useState(x ? x.seller_phone : "");

  const [pw, setPw] = useState("");
  const [repw, setRePw] = useState("");

  const [name, setName] = useState(x.seller_name);
  const [auth, setAuth] = useState(x.seller_grade);
  const [state, setState] = useState(x.service_state);
  const [kakao, setKakao] = useState(x.kakao_auth_key);

  const [error, setError] = useState("");
  const [error2] = useState("");
  const [error3, setError3] = useState("");
  const [error4] = useState("");
  const [error5, setError5] = useState("");

  const [success4] = useState("");

  useEffect(() => {
    setFullHp(hpFront + hp);
  }, [hp, hpFront]);

  const closeFunc = () => {
    dispatch(close());
    return;
  };

  const submitFunc = () => {
    if (!name) {
      dispatch(
        openSub({
          message: "사용자명을 확인해 주세요.",
        })
      );
      return;
    }

    if (pw && (pw !== repw || pw.length < 8)) {
      dispatch(
        openSub({
          message: "비밀번호를 확인해 주세요.",
        })
      );
      return;
    }

    if (fullHp.length < 11) {
      dispatch(
        openSub({
          message: "연락처를 확인해 주세요.",
        })
      );
      return;
    }

    if (!auth) {
      dispatch(
        openSub({
          message: "등급을 선택해 주세요.",
        })
      );
      return;
    }

    const urls = "/UserCntl/UserUpdate";
    const types = 2;
    const sender = {
      seller_table_id: userData.seller_table_id,
      seller_user_id: seller_user_id,
      seller_phone: fullHp,
      seller_name: name,
      seller_passwd: pw,
      seller_grade: auth,
      service_state: state,
    };

    APIS.postData(urls, sender, types)
      .then((res) => {
        if (res.data.code === "OK") {
          dispatch(
            openSub({
              message: "저장되었습니다.",
              onPress: () => {
                dispatch(close());
              },
            })
          );
        } else {
          dispatch(
            openSub({
              message: "잘못된 접근입니다.",
            })
          );
        }
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };

  const deleteFunc = () => {
    // 사용자 삭제
    dispatch(
      openSub({
        message: "사용자를 삭제 하시겠습니까?",
        buttonCencle: "취소",
        onPress: () => {
          const urls = "/UserCntl/UserDel";
          const sender = {
            seller_table_id: userData.seller_table_id,
            seller_user_id: seller_user_id,
          };

          APIS.postData(urls, sender, 2)
            .catch((e) => {
              console.log("catch", e);
            })
            .finally(() => {
              dispatch(close());
            });
        },
      })
    );
  };

  const kakaoAlert = () => {
    if (kakao) {
      dispatch(
        openSub({
          message: "카카오톡 인증정보를 해제 하시겠습니까?",
          buttonCencle: "취소",
          onPress: () => {
            kakaoFunc();
          },
        })
      );
    }
  };
  const kakaoFunc = () => {
    let urls = "/UserCntl/UserKakaoDel";
    let types = 2;
    let sender = {
      seller_table_id: userData.seller_table_id,
      seller_user_id: seller_user_id,
    };

    APIS.postData(urls, sender, types)
      .then((res) => {
        if (res.data.code === "OK") {
          setKakao("");
        } else {
          dispatch(
            openSub({
              message: "잘못된 접근입니다.",
            })
          );
        }
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };
  return (
    <>
      <form className="popup_form" style={{ marginTop: "10px" }}>
        <div className="user_delete_box">
          <button
            type="button"
            className="user_delete_btn"
            onClick={() => deleteFunc()}
          >
            사용자 삭제
          </button>
          <p className="msg">(*)표시는 필수항목 입니다. {fullHp}</p>
        </div>

        <div className="partner_user_form">
          <Input
            className="input_text"
            type="text"
            name="mbId"
            value={mbId}
            setValue={setMbId}
            label="*아이디"
            valid={"id"}
            error={error}
            setError={setError}
            maxlength={12}
            readOnly={true}
          />
          <Input
            className="input_text"
            type="text"
            placeholder="사용자명을 입력해 주세요."
            id="name"
            name="name"
            value={name}
            setValue={setName}
            label="*사용자명"
          />

          <Input
            className="input_text"
            type="password"
            placeholder="비밀번호 최소 8자~15자"
            name="pw"
            value={pw}
            setValue={setPw}
            label="*비밀번호"
            error={error3}
            setError={setError3}
            valid={"pw"}
            maxlength={15}
            autoComplete={false}
          />
          <Input
            className="input_text"
            type="password"
            placeholder="비밀번호 최소 8자~15자"
            name="repw"
            value={repw}
            setValue={setRePw}
            label="*비밀번호 확인"
            error={error4}
            success={success4}
            valid={"pw"}
            maxlength={15}
            autoComplete={false}
          />

          <div className="hp_multi_input noButton">
            <InputSelect
              name="hpFront"
              label="*연락처"
              value={hpFront}
              setValue={setHpFront}
              optionHp={true}
            />
            <Input
              className="input_text"
              type="text"
              name="hp"
              value={hp}
              setValue={setHp}
              label=""
              valid={"num"}
              maxlength={8}
            />
          </div>
          <p className="input_error">{error2}</p>

          <InputSelect
            name="auth"
            label="*등급"
            value={auth}
            setValue={setAuth}
            option={consts.authoption}
            error={error5}
            setError={setError5}
          />
          <InputSelect
            name="state"
            label={"*상태"}
            value={state * 1}
            setValue={setState}
            option={consts.sellerUserServiceState.filter(
              (item) => item.idx > 2
            )}
          />
          {kakao && (
            <Input
              className="input_text"
              type="text"
              placeholder="영문/숫자만 입력해 주세요."
              name="kakao"
              value={kakao}
              label="카카오연동"
              readOnly={true}
              withButton={"해제"}
              withButtonPress={() => kakaoAlert()}
            />
          )}
        </div>

        <div className="register_btn_box">
          <button
            type="button"
            className="btn btn3"
            onClick={() => closeFunc()}
          >
            취소
          </button>
          <button
            type="button"
            className="btn btn1"
            onClick={() => submitFunc()}
          >
            저장
          </button>
        </div>
      </form>
    </>
  );
}
