import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import moment from "moment";
import * as APIS from "../../utils/service";
import { open, close } from "../../redux/popupSlice";
import { logIn } from "../../redux/usersSlice";
import { stackUpdate, pwTimerOn } from "../../redux/joinSlice";
import Input from "../../components/Input";
import routes from "../../libs/routes";
import FindId from "./FindId";
import ResetPw from "./ResetPw";

export default function LoginId(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const joinData = useSelector((s) => s.joinReducer);

  const [id, setId] = useState("");
  const [pw, setPw] = useState("");

  const [error, setError] = useState("");
  const [error2, setError2] = useState("");

  const loginFunc = () => {
    /* 에러메시지 */
    if (!id) {
      setError("아이디를 입력해주세요.");
      return false;
    }
    if (!pw) {
      setError2("비밀번호를 입력해주세요.");
      return false;
    }

    // 이미 5회이상 틀린경우
    if (joinData.pwTimer) {
      stackOver();
      return false;
    }

    const sender = {
      seller_id: id,
      seller_passwd: pw,
    };

    APIS.postData("/Login/SiteLogin", sender, 2)
      .then((res) => {
        if (res.data.code === "OK") {
          dispatch(close());
          dispatch(
            stackUpdate({
              pwStack: 0,
            })
          );
          dispatch(
            logIn({
              mbId: res.data.seller_id,
              mbName: res.data.seller_name,
              mbProfile: res.data.seller_icon,

              kakao_auth_key: res.data.kakao_auth_key,
              reg_date: res.data.reg_date,
              seller_addr: res.data.seller_addr,
              seller_addr_detail: res.data.seller_addr_detail,
              seller_area: res.data.seller_area,
              seller_area_text: res.data.seller_area_text,
              seller_as: res.data.seller_as,
              seller_biz_no: res.data.seller_biz_no,
              seller_biz_name: res.data.seller_biz_name,
              seller_career: res.data.seller_career,
              seller_desc: res.data.seller_desc,
              seller_email: res.data.seller_email,
              seller_grade: res.data.seller_grade,
              seller_icon: res.data.seller_icon,
              seller_id: res.data.seller_id,
              seller_license_yn: res.data.seller_license_yn,
              seller_name: res.data.seller_name,
              seller_passwd: res.data.seller_passwd,
              seller_phone: res.data.seller_phone,
              seller_service: res.data.seller_service,
              seller_state: res.data.seller_state,
              service_state: res.data.service_state,
              seller_table_id: res.data.seller_table_id,
              update_date: res.data.update_date,
              work_kind: res.data.work_kind,
              alarm_yn: res.data.alarm_yn,
            })
          );
          navigate(routes.main);
        } else if (res.data.code === "ERR_102") {
          setError("해당 아이디가 존재 하지 않습니다.");
        } else if (res.data.code === "ERR_105") {
          dispatch(
            stackUpdate({
              pwStack: joinData.pwStack + 1,
            })
          );

          // 5회이상 틀린경우
          if (joinData.pwStack + 1 >= 5 && !joinData.pwTimer) {
            const currentDate = moment().unix();
            dispatch(
              pwTimerOn({
                pwTimer: true,
                pwTime: currentDate,
              })
            );
            stackOver();
          } else {
            setError2("비밀번호를 확인해주세요.");
          }
          return;
        }
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };

  /* 비밀번호 5회이상 틀린경우 */
  const stackOver = () => {
    dispatch(close());
    setTimeout(() => {
      dispatch(
        open({
          message:
            "5회 비밀번호 입력이 실패하였습니다. \n5분 이후 시도해 주세요.\n",
          subMessage: "pwTimer",
          button: "확인",
        })
      );
    }, 50);
  };

  /* 아이디 찾기 이동 */
  const findId = () => {
    dispatch(close());
    dispatch(
      open({
        title: "아이디 찾기",
        titleLong: false,
        message: "아이디 찾기를 위해 필수정보를 입력해 주세요.",
        component: <FindId />,
      })
    );
  };

  /* 비밀번호 재설정 이동 */
  const resetPw = () => {
    dispatch(close());
    dispatch(
      open({
        title: "비밀번호 재설정",
        titleLong: false,
        message: "아이디와 휴대전화번호 인증 후\n비밀번호를 재설정해주세요.",
        component: <ResetPw />,
      })
    );
  };
  const handleLoginFunc = (e) => {
    if (e.key === "Enter") {
      loginFunc();
    }
  };

  return (
    <form className="popup_form" style={{ marginTop: "10px" }}>
      <Input
        className="input_text"
        type="text"
        placeholder="아이디를 입력해 주세요."
        name="id"
        value={id}
        setValue={setId}
        label="아이디"
        error={error}
        setError={setError}
      />
      <Input
        className="input_text"
        type="password"
        placeholder="비밀번호를 입력해 주세요."
        name="pw"
        value={pw}
        maxlength={15}
        setValue={setPw}
        label="비밀번호"
        error={error2}
        setError={setError2}
        onKeyDown={handleLoginFunc}
      />
      <div className="popup_btn">
        <button
          type="button"
          className="popbtn ok_btn"
          onClick={() => loginFunc()}
          style={{ width: "100%" }}
        >
          로그인
        </button>
      </div>
      <div className="popup_link_btn">
        <button
          type="button"
          onClick={() => {
            findId();
          }}
        >
          아이디찾기
        </button>
        <button
          type="button"
          onClick={() => {
            resetPw();
          }}
        >
          비밀번호 재설정
        </button>
      </div>
    </form>
  );
}
