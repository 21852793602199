import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { closeSub } from "../redux/subPopupSlice";
import { pwTimerOff } from "../redux/joinSlice";

export default function SubPopup() {
  const dispatch = useDispatch();
  const {
    open,
    title,
    message,
    button,
    buttonCencle,
    onCancelPress,
    onCancelPressAlert,
    onPress,
    component,
    titleLong,
    subMessage,
    subComponent,
    messageAuto,
    wide,
    wideOne,
    mideum,
    suc,
    one,
    overFlow,
  } = useSelector((state) => state.subPopupReducer);

  const joinData = useSelector((s) => s.joinReducer);

  const [min, setMin] = useState("05");
  const [sec, setSec] = useState("00");
  const timerId = useRef(null);

  const handleClose = () => {
    dispatch(closeSub());
  };

  useEffect(() => {
    if (subMessage === "pwTimer") {
      let diffDate = 300 - (moment().unix() - joinData.pwTime);

      setMin(
        parseInt(diffDate / 60) < 10
          ? "0" + parseInt(diffDate / 60)
          : parseInt(diffDate / 60)
      );
      setSec(diffDate % 60 < 10 ? "0" + (diffDate % 60) : diffDate % 60);

      timerId.current = setInterval(() => {
        setMin(
          parseInt(diffDate / 60) < 10
            ? "0" + parseInt(diffDate / 60)
            : parseInt(diffDate / 60)
        );
        setSec(diffDate % 60 < 10 ? "0" + (diffDate % 60) : diffDate % 60);
        diffDate -= 1;
      }, 1000);

      return () => clearInterval(timerId.current);
    }
  }, [joinData.pwTime, open, subMessage]);

  useEffect(() => {
    // 만약 타임 아웃이 발생했을 경우
    const diffDate = 300 - (moment().unix() - joinData.pwTime);

    if (diffDate <= 0 && joinData.pwTimer) {
      setTimeout(() => {
        setMin("00");
        setSec("00");
      }, 1000);

      clearInterval(timerId.current);
      dispatch(pwTimerOff());
    }
  }, [dispatch, joinData.pwTime, joinData.pwTimer, sec]);

  return (
    <>
      <div className={open ? "popup show subpopup" : "popup subpopup"}>
        <div
          className={
            (component && !suc
              ? wide
                ? "popup_container wide full"
                : wideOne
                ? "popup_container wide1 full"
                : mideum
                ? "popup_container medium full"
                : "popup_container medium"
              : "popup_container") + (overFlow ? " overFlowFalse" : "")
          }
        >
          <div className="popup_top">
            <div
              className="popup_title_section"
              style={{ marginBottom: titleLong ? "10px" : "0" }}
            >
              <p
                className="popup_title"
                style={{ margin: titleLong ? "20px 0 0 0" : "0" }}
              >
                {title}
              </p>
              <button
                type="button"
                className="exit_btn"
                onClick={() => {
                  handleClose();
                  onCancelPress && onCancelPress();
                }}
              ></button>
            </div>

            {(message || subMessage || subComponent) && (
              <p
                className="popup_msg"
                style={{
                  minHeight: component ? "auto" : "60px",
                  whiteSpace: messageAuto ? "pre-wrap" : "pre",
                }}
              >
                {message}
                {subMessage && subMessage === "pwTimer" ? (
                  <span>
                    남은 시간 : {min}:{sec}
                  </span>
                ) : (
                  <span>{subMessage}</span>
                )}
                {subComponent && subComponent}
              </p>
            )}
          </div>

          {component &&
            (one ? (
              <div className="one_component">{component}</div>
            ) : (
              component
            ))}

          {(!component || (component && one)) && (
            <div className={one ? "popup_btn popup_btn_full" : "popup_btn"}>
              {buttonCencle && (
                <button
                  type="button"
                  className="popbtn cencle_btn"
                  onClick={() => {
                    onCancelPressAlert ? onCancelPressAlert() : handleClose();
                    onCancelPress && onCancelPress();
                  }}
                >
                  {buttonCencle}
                </button>
              )}
              <button
                type="button"
                className="popbtn ok_btn"
                onClick={() => {
                  handleClose();
                  onPress && onPress();
                }}
                style={{ width: buttonCencle ? "" : "100%" }}
              >
                {button}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
