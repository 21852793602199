import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import * as APIS from "../../utils/service";
import { open, close } from "../../redux/popupSlice";
import { openSub } from "../../redux/subPopupSlice";
import { loadingStart, loadingEnd } from "../../redux/loadingSlice";
import Input from "../../components/Input";
import InputSelect from "../../components/InputSelect";
import InputDate from "../../components/InputDate";
import TextArea from "../../components/TextArea";
import InputFile from "../../components/InputFile";
import InputFileMulti from "../../components/InputFileMulti";
import consts from "../../libs/consts";

export default function NoticeAdd(props) {
  const { x, w, type } = props;
  const dispatch = useDispatch();
  const userData = useSelector((s) => s.usersReducer);
  const [id, setId] = useState(x ? x.notice_table_id : "");
  const [state, setState] = useState(x ? x.state : "0");
  const [noticetype, setNoticeType] = useState(
    x ? x.n_type : type === 1 ? "0" : "0"
  );
  const [title, setTitle] = useState(x ? x.title : "");
  const [content, setContent] = useState(x ? x.contents : "");
  const [url, setUrl] = useState(x ? x.link : "");
  const [btn, setBtn] = useState(x ? x.btn * 1 : 0);
  const [btn_name, setBtn_name] = useState(x ? x.btn_name : "");
  const [license, setLicense] = useState("0");
  const [noticetypeText, setNoticeTypeText] = useState("배너");
  const [talkMsg, setTalkMsg] = useState("2회 / 2022.02.02 13:13");
  const [main_photo, setMain_photo] = useState("");
  const [main_img, setMain_img] = useState(x ? x.main_img : ""); // 메인사진
  const [is_main_del, setis_main_del] = useState(x && x.main_img ? 0 : 1); // 메인사진 삭제여부

  const [detail_photo, setDetail_photo] = useState("");
  const [detail_img, setDetail_img] = useState(x ? x.detail_img : ""); // 상세사진
  const [is_detail_del, setis_detail_del] = useState(x && x.detail_img ? 0 : 1); // 상세사진 삭제여부

  const [main_photoM, setMain_photoM] = useState("");
  const [main_imgM, setMain_imgM] = useState(x ? x.main_mobile : ""); // 메인사진
  const [is_main_delM, setis_main_delM] = useState(x && x.main_mobile ? 0 : 1); // 메인사진 삭제여부

  const [detail_photoM, setDetail_photoM] = useState("");
  const [detail_imgM, setDetail_imgM] = useState(x ? x.detail_mobile : ""); // 상세사진
  const [is_detail_delM, setis_detail_delM] = useState(
    x && x.detail_mobile ? 0 : 1
  ); // 상세사진 삭제여부

  const [photoList, setPhotoList] = useState("");

  const [error, setError] = useState("");
  const [sdate, setSdate] = useState(
    x.start_date === "0000-00-00 00:00:00" || x.start_date === null
      ? ""
      : moment(x.start_date).format("YYYY.MM.DD")
  ); // 시공시작 예정일
  const [edate, setEdate] = useState(
    x.end_date === "0000-00-00 00:00:00" || x.end_date === null
      ? ""
      : moment(x.end_date).format("YYYY.MM.DD")
  ); // 시공종료 예정일

  const submitFunc = () => {
    const apiurl = "/AdminNotice/NoticeUpdate";
    const sender = {
      notice_table_id: id,
      admin_table_id: userData.admin_table_id,
      user_type: type,
      n_type: noticetype,
      title: title,
      link: url,
      main_img: is_main_del === 1 && main_photo ? main_img : "",
      detail_img: is_detail_del === 1 && detail_photo ? detail_img : "",
      main_mobile: is_main_delM === 1 && main_photoM ? main_imgM : "",
      detail_mobile: is_detail_delM === 1 && detail_photoM ? detail_imgM : "",
      btn: btn,
      btn_name: btn_name,
      state: state,
      contents: content,
      start_date: sdate,
      end_date: edate,
    };

    dispatch(loadingStart());
    APIS.postData(apiurl, sender, 1)
      .then((res) => {
        dispatch(loadingEnd());

        if (res.data.code === "OK") {
          dispatch(close());
          setTimeout(() => {
            dispatch(
              open({
                message: "저장되었습니다.",
              })
            );
          }, 50);
        } else {
          dispatch(
            openSub({
              message: "잠시 후 다시 시도해주세요.",
              button: "확인",
            })
          );
          return;
        }
      })
      .catch((e) => {
        console.log("catch", e);
      });
    dispatch(close());
    setTimeout(() => {
      dispatch(
        open({
          message: "저장되었습니다.",
        })
      );
    }, 50);
  };

  return (
    <form className="popup_form">
      {type === 1 ? (
        <div className="admin_pay_form_list">
          {w === "u" && (
            <Input
              className="input_text"
              type="text"
              placeholder="내용을 입력해 주세요."
              name="id"
              value={id}
              setValue={setId}
              label="공지번호"
              readOnly={true}
            />
          )}

          <InputSelect
            name="type"
            label="공지유형"
            value={noticetype}
            setValue={setNoticeType}
            option={consts.noticeTypePartner}
          />
          <InputSelect
            name="type"
            label="공지상태"
            value={state}
            setValue={setState}
            option={consts.themeState}
          />
          <div
            className=" popup_instance_step "
            style={{ padding: 0, borderBottom: 0 }}
          >
            <div className="instance_form_list estimate_form_list">
              <InputDate
                type="text"
                placeholder="날짜선택"
                name="sdate"
                label="공지 시작일"
                dateValue={sdate}
                setDateValue={setSdate}
              />
              <InputDate
                type="text"
                placeholder="날짜선택"
                name="edate"
                label="공지 종료일"
                dateValue={edate}
                setDateValue={setEdate}
              />
            </div>
          </div>

          {w === "i" ? (
            <div className="checkbox_section">
              <label className="input_label">알림톡 발송여부</label>
              <div>
                <input
                  type="radio"
                  id="rd3"
                  name="license"
                  className="radio"
                  value="1"
                  checked={license === "1"}
                  onChange={(e) => setLicense(e.target.value)}
                />
                <label htmlFor="rd3">발송</label>
                <input
                  type="radio"
                  id="rd4"
                  name="license"
                  className="radio"
                  value="0"
                  checked={license === "0"}
                  onChange={(e) => setLicense(e.target.value)}
                />
                <label htmlFor="rd4">미발송</label>
              </div>
              <p className="input_msg">
                마케팅 수신동의를 한 파트너에게만 알림톡이 발송됩니다.
              </p>
            </div>
          ) : (
            <Input
              className="input_text"
              type="text"
              placeholder="내용을 입력해 주세요."
              name="talkMsg"
              value={talkMsg}
              setValue={setTalkMsg}
              label="알림톡 발송여부"
              readOnly={true}
            />
          )}

          <Input
            className="input_text"
            type="text"
            placeholder="내용을 입력해 주세요."
            name="title"
            value={title}
            setValue={setTitle}
            label="제목"
            error={error}
            setError={setError}
          />
          <TextArea
            className="input_textarea"
            placeholder="내용을 입력해 주세요. (2000자 이내)"
            name="content"
            value={content}
            setValue={setContent}
            label="내용"
            maxlength={2000}
          />

          <InputFileMulti
            type="file"
            multiple={true}
            placeholder="입력해주세요."
            name="photoList"
            label="사진 (5개까지 등록 가능)"
            value={photoList}
            setValue={setPhotoList}
            defaulteImgvalue={detail_img}
            setImgValue={setDetail_img}
            valid="image"
            full={true}
            maxlength={5}
          />
        </div>
      ) : (
        <div className="admin_pay_form_list">
          {w === "u" && (
            <Input
              className="input_text"
              type="text"
              placeholder="내용을 입력해 주세요."
              name="id"
              value={id}
              setValue={setId}
              label="공지번호"
              readOnly={true}
            />
          )}

          {w === "i" ? (
            <div className="checkbox_section">
              <label className="input_label">유형</label>
              <div>
                {consts.noticeTypeUser.map((nx, ni) => {
                  return (
                    <>
                      <input
                        type="radio"
                        id={"ntrd" + ni}
                        name="noticetype"
                        className="radio"
                        value={nx.idx}
                        checked={noticetype === nx.idx}
                        onChange={(e) => setNoticeType(e.target.value)}
                      />
                      <label htmlFor={"ntrd" + ni}>{nx.title}</label>
                    </>
                  );
                })}
              </div>
            </div>
          ) : (
            <Input
              className="input_text"
              type="text"
              placeholder="내용을 입력해 주세요."
              name="noticetypeText"
              value={noticetypeText}
              setValue={setNoticeTypeText}
              label="공지유형"
              readOnly={true}
            />
          )}

          <InputSelect
            name="type"
            label="공지상태"
            value={state}
            setValue={setState}
            option={consts.themeState}
          />

          <div
            className=" popup_instance_step "
            style={{ padding: 0, borderBottom: 0 }}
          >
            <div className="instance_form_list estimate_form_list">
              <InputDate
                type="text"
                placeholder="날짜선택"
                name="sdate"
                label="공지 시작일"
                dateValue={sdate}
                setDateValue={setSdate}
              />
              <InputDate
                type="text"
                placeholder="날짜선택"
                name="edate"
                label="공지 종료일"
                dateValue={edate}
                setDateValue={setEdate}
              />
            </div>
          </div>

          <Input
            className="input_text"
            type="text"
            placeholder="30자 이내로 입력하세요."
            name="title"
            value={title}
            setValue={setTitle}
            label="제목"
            error={error}
            setError={setError}
            maxlength={30}
          />

          {/*noticetype !== '0' && 
                        <TextArea className="input_textarea" placeholder="내용을 입력해 주세요. (2000자 이내)" name="content" value={content} setValue={setContent} label="내용" maxlength={2000}/>
                    */}

          <Input
            className="input_text"
            type="text"
            placeholder="링크 또는 타겟 URL을 입력하세요."
            name="url"
            value={url}
            setValue={setUrl}
            label="링크"
          />

          <InputFile
            type="file"
            placeholder="사진을 첨부해 주세요."
            name="main_photo"
            label="사진"
            value={main_photo}
            setValue={setMain_photo}
            imgvalue={main_img}
            setImgValue={setMain_img}
            valid="image"
            full={true}
            deleteFunc={() => setis_main_del(1)}
          />
          <InputFile
            type="file"
            placeholder="사진을 첨부해 주세요."
            name="main_photoM"
            label="모바일 사진"
            value={main_photoM}
            setValue={setMain_photoM}
            imgvalue={main_imgM}
            setImgValue={setMain_imgM}
            valid="image"
            full={true}
            deleteFunc={() => setis_main_delM(1)}
          />

          <InputFile
            type="file"
            placeholder="사진을 첨부해 주세요."
            name="detail_photo"
            label="상세사진"
            value={detail_photo}
            setValue={setDetail_photo}
            imgvalue={detail_img}
            setImgValue={setDetail_img}
            valid="image"
            full={true}
            deleteFunc={() => setis_detail_del(1)}
          />
          <InputFile
            type="file"
            placeholder="사진을 첨부해 주세요."
            name="detail_photoM"
            label="모바일 상세사진"
            value={detail_photoM}
            setValue={setDetail_photoM}
            imgvalue={detail_imgM}
            setImgValue={setDetail_imgM}
            valid="image"
            full={true}
            deleteFunc={() => setis_detail_delM(1)}
          />

          <div className="checkbox_section2" style={{ marginBottom: "24px" }}>
            <label className="input_label">버튼</label>
            <div>
              <input
                type="checkbox"
                id={"checkboxbt1"}
                name="btn"
                className="checkbox"
                defaultChecked={btn}
                onChange={(e) => setBtn(e.target.checked ? 1 : 0)}
              />
              <label htmlFor={"checkboxbt1"}>사용</label>
            </div>
          </div>
          <Input
            className="input_text"
            type="text"
            placeholder="20자 이내로 입력하세요."
            name="btn_name"
            value={btn_name}
            setValue={setBtn_name}
            label="버튼명"
            readOnly={!btn}
            maxlength={20}
          />

          {/* <InputFileMulti type="file" multiple={true} placeholder="입력해주세요." name="photoList" label="사진 (5개까지 등록 가능)" value={photoList} setValue={setPhotoList} defaulteImgvalue={detail_img} setImgValue={setDetail_img} valid="image" full={true} maxlength={5}/> */}
        </div>
      )}

      <div className="popup_btn">
        <button
          type="button"
          className="popbtn cencle_btn"
          onClick={() => {
            dispatch(close());
          }}
          style={{ width: "100%", marginRight: "10px" }}
        >
          취소
        </button>
        <button
          type="button"
          className="popbtn ok_btn"
          onClick={() => {
            submitFunc();
          }}
          style={{ width: "100%" }}
        >
          수정
        </button>
      </div>
    </form>
  );
}
