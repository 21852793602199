/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import * as APIS from "../../utils/service";
import { findJson, numFormat } from "../../utils/utils";

import moment from "moment";

import Input from "../../components/Input";
import InputSelect from "../../components/InputSelect";
import Pagenation from "../../components/Pagenation";

import RealAdd from "./RealAdd";

import consts from "../../libs/consts";

import { open } from "../../redux/popupSlice";
import { setData } from "../../redux/searchSlice";
import AdminLayout from "../../components/Layout/admin";

export default function Real() {
  const dispatch = useDispatch();
  const userData = useSelector((s) => s.usersReducer);
  const search = useSelector((s) => s.searchReducer);
  const popup = useSelector((s) => s.popupReducer);

  /* search form */
  const [pageing, setPageing] = useState("");

  const [list, setList] = useState([]);
  /* search form End */
  useEffect(() => {
    dataFunc();
  }, [
    search.city,
    search.range,
    search.membership,
    search.state,
    search.license,
    search.sdate,
    search.edate,
    search.page,
    popup.open,
  ]);

  const dataFunc = () => {
    const sender = {
      admin_table_id: userData.admin_table_id,

      order_state: search.state,
      work_type: search.range,
      order_estimate_type: search.license,

      cont_table_id: search.stx1,
      order_table_id: search.stx,
      seller_biz_name: search.stx2,

      page: search.page + 1,
    };

    APIS.postData("/AdminReal/RealList", sender, 1)
      .then((res) => {
        if (res.data.code === "OK") {
          setList(res.data.case_list);
          setPageing({
            totalPages: Math.ceil(res.data.total_cnt / consts.pagerows),
          });
        } else {
          setList([]);
          setPageing({ totalPages: 1 });
        }
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };
  const odFunc = (key) => {
    if (search.od === key) {
      dispatch(
        setData({
          key: "od",
          value: "",
        })
      );
      sortHandler(key, "asc");
    } else {
      dispatch(
        setData({
          key: "od",
          value: key,
        })
      );
      sortHandler(key, "desc");
    }
  };

  const sortHandler = (key, od) => {
    let listArr = [...list]; // 새로 복사된 numbers

    if (od === "asc") {
      listArr.sort((a, b) => {
        return a[key] - b[key];
      });
    } else {
      listArr.sort((a, b) => {
        return b[key] - a[key];
      });
    }
    setList(listArr);
  };

  const searchFunc = (key, value) => {
    dispatch(
      setData({
        key: key,
        value: value,
      })
    );
  };

  const updateFunc = (x) => {
    dispatch(
      open({
        title: "실시공사례",
        wide: true,
        component: <RealAdd data={x} w={"u"} />,
      })
    );
  };
  const keyPressFunc = (e) => {
    if (e.key === "Enter") {
      dataFunc();
    }
  };
  return (
    <AdminLayout title="실시공 관리">
      <div>
        <div className="search_form search_form_long">
          <div className="search_form_left">
            <InputSelect
              name="state"
              label="상태"
              value={search.state}
              onSearch={searchFunc}
              option={[
                { idx: "", title: "전체" },
                ...consts.instanceStateoption,
              ]}
            />
            <InputSelect
              name="license"
              label="견적유형"
              value={search.license}
              onSearch={searchFunc}
              option={[{ idx: "", title: "전체" }, ...consts.sellerOrderType]}
            />
            <InputSelect
              name="range"
              label="시공범위"
              value={search.range}
              onSearch={searchFunc}
              option={[{ idx: "", title: "선택" }, ...consts.rangeoption]}
            />
            <Input
              className="input_text"
              type="text"
              placeholder={"요청번호 입력"}
              name="stx"
              value={search.stx}
              onSearch={searchFunc}
              onKeyDown={keyPressFunc}
              label={"검색"}
            />
            <Input
              className="input_text"
              type="text"
              placeholder={"계약번호 입력"}
              name="stx1"
              value={search.stx1}
              onSearch={searchFunc}
              onKeyDown={keyPressFunc}
            />
            <Input
              className="input_text"
              type="text"
              placeholder={"파트너명 입력"}
              name="stx2"
              value={search.stx2}
              onSearch={searchFunc}
              onKeyDown={keyPressFunc}
            />

            <button
              type="button"
              className="btn btn3"
              onClick={() => dataFunc()}
            >
              검색
            </button>
          </div>
          <div className="search_form_right">
            {/* <button type="button" className="btn btn1" onClick={() => addFunc()}>등록</button> */}
          </div>
        </div>

        <table className="tableStyle1">
          <thead>
            <tr>
              <th className="wd110">요청번호</th>
              <th className="wd110">계약번호</th>
              <th className="wd110">견적유형</th>
              <th className="wdFull">파트너명</th>
              <th className="wd110">시공범위</th>
              <th className="wd110">홈스타일링</th>
              <th className="wd110">상태</th>

              <th
                className={
                  "wd110 od " + (search.od === "order_price" ? "active" : "")
                }
                onClick={() => odFunc("order_price")}
              >
                시공가격
              </th>
              <th
                className={
                  "wd130 od " +
                  (search.od === "work_start_date" ? "active" : "")
                }
                onClick={() => odFunc("work_start_date")}
              >
                시공시작일
              </th>
              <th
                className={
                  "wd130 od " + (search.od === "work_end_date" ? "active" : "")
                }
                onClick={() => odFunc("work_end_date")}
              >
                시공종료일
              </th>

              <th className="wd130">등록일자</th>
              <th className="wd130">수정일자</th>
            </tr>
          </thead>
          <tbody>
            {list && list.length > 0 ? (
              list.map((x, i) => {
                return (
                  <tr
                    key={i}
                    className={"pointer"}
                    onClick={() => updateFunc(x)}
                  >
                    <td>{x.order_table_id}</td>
                    <td>{x.cont_table_id}</td>
                    <td>
                      {findJson(consts.sellerOrderType, x.order_estimate_type)}
                    </td>
                    <td>{x.seller_biz_name}</td>
                    <td>{findJson(consts.rangeoption, x.work_type)}</td>
                    <td>{numFormat(x.style_price)}만원</td>
                    <td>
                      {findJson(consts.instanceStateoption, x.case_state)}
                    </td>
                    <td>{numFormat(x.order_price)}만원</td>
                    <td>{moment(x.work_start_date).format("YYYY.MM.DD")}</td>
                    <td>{moment(x.work_end_date).format("YYYY.MM.DD")}</td>
                    <td>{moment(x.reg_date).format("YYYY.MM.DD")}</td>
                    <td>{moment(x.update_date).format("YYYY.MM.DD")}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="null_td" colSpan={9}>
                  데이터가 없습니다.
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <Pagenation
          pageing={pageing}
          onSearch={searchFunc}
          curpage={search.page}
        />
      </div>
    </AdminLayout>
  );
}
