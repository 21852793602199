import React, { useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import Modal from "../../../Common/ModalPortal";
import images from "../../../../libs/images";
import * as Colors from "../../../../constants/color";
import Button from "../../../Common/Button/Button";
import BasicInfo from "./BasicInfo";
import AddInfo from "./AddInfo";
import { stepStyles, stepTitleStyles } from "../../notice.style";
import { AddInfoType, BasicInfoType } from "../noticeEvent.type";
import useNoticeEvent from "../noticeEvent.hook";

const Container = styled.div`
  width: 1128px;
  background-color: #ffffff;
  margin: auto;
`;

const Header = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${Colors.gray_200};

  & > h3 {
    margin: auto 0;
  }
`;

const Content = styled.div`
  max-height: 580px;
  overflow-y: auto;
`;

const Footer = styled.div`
  padding: 20px;
  border-top: 1px solid ${Colors.gray_200};
  display: flex;
  justify-content: center;
  gap: 15px;
`;

interface Props {
  close: () => void;
  callback: () => void;
}

const CreateNoticeEvent = (props: Props) => {
  const { close, callback } = props;
  const [category, setCategory] = useState("event");

  const { create, urlList } = useNoticeEvent();

  const [basicInfo, setBasicInfo] = useState<BasicInfoType>({
    seller_table_id: "",
    seller_biz_name: "",
    avt_thumbnail: "",
    avt_title: "",
    avt_start_date: "",
    avt_end_date: "",
    url_table_id: "",
  });

  const [addInfo, setAddInfo] = useState<AddInfoType>({
    hero_banner: undefined,
    line_banner: undefined,
    center_banner: undefined,
    pop_banner: undefined,
  });

  const createHandler = async (): Promise<void> => {
    const res = await create({
      basicInfo,
      addInfo,
      avt_type: category === "event" ? "1" : "2",
    });

    if (typeof res === "boolean") {
      if (res) {
        callback();
      } else {
        alert("등록중 오류가 발생하였습니다.");
      }
    }
  };

  return (
    <Modal>
      <Container>
        <Header>
          <h3>이벤트 등록</h3>
          <button onClick={close}>
            <img src={images.exit} alt="exit_btn" />
          </button>
        </Header>
        <Content>
          <div
            css={css`
              display: flex;
              padding: 30px 20px;
              border-bottom: 1px solid ${Colors.gray_200};
            `}
          >
            <p css={stepStyles}>1</p>
            <h3 css={stepTitleStyles}>카테고리 구분</h3>
            <div
              css={css`
                margin: 2px 0 0 100px;
                display: flex;
                gap: 30px;
              `}
            >
              <div>
                <input
                  type="radio"
                  id="event"
                  value="event"
                  className="radio"
                  onChange={(e) => setCategory(e.target.value)}
                  checked={category === "event"}
                />
                <label htmlFor="event">이벤트</label>
              </div>

              <div>
                <input
                  type="radio"
                  id="content"
                  value="content"
                  className="radio"
                  onChange={(e) => setCategory(e.target.value)}
                  checked={category === "content"}
                />
                <label htmlFor="content">컨텐츠</label>
              </div>
            </div>
          </div>
          <div
            css={css`
              padding: 30px 20px;
              border-bottom: 1px solid ${Colors.gray_200};
            `}
          >
            <div
              css={css`
                display: flex;
              `}
            >
              <p css={stepStyles}>2</p>
              <h3 css={stepTitleStyles}>기본 정보 등록</h3>
            </div>
            <BasicInfo
              data={basicInfo}
              setData={setBasicInfo}
              urlList={urlList}
            />
          </div>
          <div
            css={css`
              padding: 30px 20px;
            `}
          >
            <div
              css={css`
                display: flex;
              `}
            >
              <p css={stepStyles}>3</p>
              <h3 css={stepTitleStyles}>추가 정보 등록</h3>
            </div>
            <AddInfo data={addInfo} info={basicInfo} setData={setAddInfo} />
          </div>
        </Content>
        <Footer>
          <Button theme="tertiary" onClick={close}>
            취소
          </Button>
          <Button onClick={createHandler}>등록</Button>
        </Footer>
      </Container>
    </Modal>
  );
};

export default CreateNoticeEvent;
