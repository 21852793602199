import React, { useState } from "react";

export default function Help(props) {
  const { msg } = props;

  const [stateOpen, setStateOpen] = useState(false);
  const [stateOpen2, setStateOpen2] = useState(false);

  const setOpenFunc = (e) => {
    setStateOpen(true);
    setStateOpen2(true);

    setTimeout(() => {
      setStateOpen(false);
    }, 3000);
    setTimeout(() => {
      setStateOpen2(false);
    }, 3300);

    e.stopPropagation();
  };
  return (
    <div className="info_btn_box">
      <button
        type="button"
        className="info_btn"
        onClick={(e) => setOpenFunc(e)}
      />
      <div
        className={"help_ballon_box " + (stateOpen ? "active" : "")}
        style={{ display: stateOpen2 ? "block" : "none" }}
      >
        <p className="help_ballon" style={{ whiteSpace: "pre-wrap" }}>
          {msg}
        </p>
      </div>
    </div>
  );
}
