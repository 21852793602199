const homeCsvHeaders = [
  { label: "요청번호", key: "ev_table_id" },
  { label: "시공지역", key: "reg" },
  { label: "평형", key: "size" },
  { label: "시공유형", key: "sigongTpye" },
  { label: "제품명", key: "product_name" },
  { label: "예상견적가", key: "cost" },
  { label: "처리상태", key: "event_state" },
  { label: "요청내역", key: "event_description" },
  { label: "연락처", key: "phone" },
  { label: "요청일시", key: "reg_date" },
];
const aptnCsvHeaders = [
  { label: "요청번호", key: "ev_table_id" },
  { label: "아파트명", key: "apart_name" },
  { label: "동호수", key: "donghosu" },
  { label: "평형", key: "size" },
  { label: "처리상태", key: "event_state" },
  { label: "요청내역", key: "event_description" },
  { label: "연락처", key: "phone" },
  { label: "요청일시", key: "reg_date" },
];

const hanssemCsvHeaders = [
  { label: "요청번호", key: "ev_table_id" },
  { label: "시공지역", key: "reg" },
  { label: "평형", key: "size" },
  { label: "희망가격", key: "cost" },
  { label: "철거여부", key: "demolish" },
  { label: "처리상태", key: "event_state" },
  { label: "요청내역", key: "event_description" },
  { label: "연락처", key: "phone" },
  { label: "요청일시", key: "reg_date" },
];

const aptnCounselCsvHeaders = [
  { label: "요청번호", key: "ev_table_id" },
  { label: "아파트명", key: "apart_name" },
  { label: "평형", key: "size" },
  { label: "처리상태", key: "event_state" },
  { label: "연락처", key: "phone" },
  { label: "요청일시", key: "reg_date" },
];

export {
  homeCsvHeaders,
  aptnCsvHeaders,
  hanssemCsvHeaders,
  aptnCounselCsvHeaders,
};
