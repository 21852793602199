import React from "react";
import { useSelector } from "react-redux";

export default function Loading() {
  const { open } = useSelector((state) => state.loadingReducer);

  return (
    <>
      <div className={open ? "loading show" : "loading"}>
        <div className="loading_spin"></div>
      </div>
    </>
  );
}
