import React from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import { open } from "../../../redux/popupSlice";
import { findJson } from "../../../utils/utils";
import consts from "../../../libs/consts";
import LogsUpdate from "./LogsUpdate";
import Table from "../../../components/Common/Table/Table";
import { css } from "@emotion/react";

export default function LogsSupplyInfo({ list, setRefundState }) {
  const dispatch = useDispatch();

  const updateFunc = (supplyType, supplyTableId, sellerTableId) => {
    dispatch(
      open({
        message:
          supplyType === "1"
            ? "해당 차감내역을 취소하시겠습니까?"
            : "해당 환불내역을 취소하시겠습니까?",
        component: (
          <LogsUpdate
            supplyType={supplyType}
            supply_table_id={supplyTableId}
            seller_table_id={sellerTableId}
            setRefundState={setRefundState}
          />
        ),
      })
    );
  };

  const col = [
    { key: "supply_table_id", title: "내역번호" },
    { key: "order_table_id", title: "요청번호" },
    { key: "seller_name", title: "파트너명" },
    { key: "seller_id", title: "파트너ID" },
    {
      key: "supply_type",
      title: "구분",
      render: (x) => (
        <span
          className={"pointer " + (x.supply_type === "1" ? "cl_5" : "cl_4")}
          onClick={() =>
            updateFunc(x.supply_type, x.supply_table_id, x.seller_table_id)
          }
        >
          {findJson(consts.supply_options, x.supply_type)}
        </span>
      ),
    },
    {
      key: "update_date",
      title: "처리일시",
      render: (x) => <span>{moment(x.update_date).format("YYYY.MM.DD")}</span>,
    },
    { key: "desc", title: "비고" },
  ];

  return (
    <div
      css={css`
        margin: 30px 0 20px 0;
      `}
    >
      <Table columns={col} datas={list} />
    </div>
  );
}
