import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export default function PGCallBack() {
  const [searchParams] = useSearchParams();

  const code = searchParams.get("code") ? searchParams.get("code") : "";
  const name = searchParams.get("name") ? searchParams.get("name") : "";
  const phone = searchParams.get("phone") ? searchParams.get("phone") : "";

  useEffect(() => {
    if (code === "OK" && name && phone) {
      window.opener.document.getElementById("PG_CODE").value = "OK";
      window.opener.document.getElementById("PG_NAME").value = name;
      window.opener.document.getElementById("PG_HP").value = phone;
    } else if (code === "ERR_306") {
      window.opener.document.getElementById("PG_CODE").value = code;
      window.opener.document.getElementById("PG_NAME").value = "";
      window.opener.document.getElementById("PG_HP").value = "";
    } else {
      window.opener.document.getElementById("PG_CODE").value = "ERR";
      window.opener.document.getElementById("PG_NAME").value = "";
      window.opener.document.getElementById("PG_HP").value = "";
    }
    window.close();
  }, [code, name, phone]);

  return <></>;
}
