import React from 'react';

export default function PartnerRespInputSelect(props) {

    const { 
        placeholder,
        name,
        value,
        setValue, 
        label,
        error,
        success,
        readOnly, 
        onChange,
        onChangeFunc,
        index,
        onSearch,
        option,
    } = props;


    const handleChange = (e) => {
        if(onChange) {
            onChange(index, e, e.target.value);
        } else if(onSearch) {
            onSearch(name, e.target.value);
        } else {
            setValue(e.target.value);
            onChangeFunc && onChangeFunc(e.target.value);
        }
    };
    return (
        <div className="input_box input_box_select">
            <label className="input_label" htmlFor={name}>{label}</label>
            <div className="input_section">
                <select name={name} onChange={handleChange} key={value} value={value} className={readOnly && "disable"} disabled={readOnly}>
                    {placeholder && <option>{placeholder}</option>}
                    {option && option.map((x, i) => {
                        return (
                            <option key={i} value={x.idx} defaultValue={x.idx}>{x.title}</option>
                        )
                    })}
                    
                </select>
            </div>
            {error &&
                <p className="input_error">{error}</p>
            }
            {success && 
                <p className="input_success">{success}</p>    
            }

        </div>
    )    
}