import {createSlice} from '@reduxjs/toolkit';

const imgSlice = createSlice({
  name: 'img',
  initialState: {
    open: false,
    imgs: "",
    active: 0,
  },
  reducers: {
    imgOpen(state, action) {
        state.open = true;
        state.active = action.payload.active;
        if(action.payload.imgs) state.imgs = action.payload.imgs;
    },
    imgClose(state) {
        state.open = false;
        state.imgs = "";
        state.active = 0;
    },
  },
});

export const {
    imgOpen,
    imgClose,
} = imgSlice.actions;
export default imgSlice.reducer;
