/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import Pagination from "../Common/Pagination/Pagination";
import * as APIS from "../../utils/service";
import { useSelector } from "react-redux";
import CreateSafeNumberModal from "./Create/CreateSafeNumberModal";
import UpdateSafeNumberModal from "./Update/UpdateSafeNumberModal";
import Button from "../Common/Button/Button";
import Table from "../Common/Table/Table";
import { css } from "@emotion/react";
import * as Colors from "../../constants/color";

const Container = styled.div``;

const CreateWrap = styled.div`
  text-align: right;
  margin-bottom: 20px;
  padding-top: 20px;
`;

export interface Datas {
  expire_date: string;
  group_code: string;
  new_num2: string;
  reg_date: string;
  s_memo: string;
  safe_num1: string;
  safe_table_id: string;
  seller_biz_name: string;
  seller_id: string;
  seller_table_id: string;
  state: string;
  tel_num1: string;
  tel_num2: string;
  update_date: string;
}

const List = () => {
  const userData = useSelector((s: any) => s.usersReducer);

  const [list, setList] = useState<Datas[]>([]);
  const [total, setTotal] = useState(0);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [detail, setDetail] = useState<Datas | null>(null);

  const params = new URLSearchParams(window.location.search);
  const page = params.get("page") || "1";

  useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    try {
      const params = {
        admin_table_id: userData.admin_table_id,
        page: page,
      };

      const res = await APIS.postData("/AdminSafety/SafetyList", params, 1);

      if (res.data.code === "OK") {
        setIsOpenModal(false);
        setList(res.data.safetylist);
        setTotal(res.data.total_cnt);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const deleteHandler = async (id: string | number) => {
    if (window.confirm("삭제 하시겠습니까?")) {
      try {
        const data = list.find((item) => item.safe_table_id === id);

        const params = {
          safe_table_id: id,
          safe_num1: data?.safe_num1,
          seller_table_id: data?.seller_table_id,
        };

        const res = await APIS.postData("/AdminSafety/SafetyDelete", params, 1);

        if (res.data.code === "OK") {
          alert("삭제되었습니다.");
          getList();
        } else {
          alert("삭제하는 중 오류가 발생하였습니다. 다시 시도해주세요.");
          return;
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const columns = [
    { key: "safe_table_id", title: "고유번호" },
    { key: "safe_num1", title: "안심번호" },
    { key: "tel_num1", title: "연동 HP" },
    { key: "seller_biz_name", title: "업체명" },
    { key: "reg_date", title: "등록일" },
    { key: "expire_date", title: "유효기간" },
    { key: "s_memo", title: "메모" },
  ];

  return (
    <Container>
      <CreateWrap>
        <Button onClick={() => setIsOpenModal(true)}>등록</Button>
      </CreateWrap>
      <Table
        columns={[
          ...columns,
          {
            key: "action",
            title: "삭제",
            render: (data) => (
              <button
                css={css`
                  background-color: #ff0000;
                  color: ${Colors.white};
                  padding: 5px 10px;
                  font-weight: bold;
                  border-radius: 5px;
                `}
                onClick={(e) => {
                  e.stopPropagation();
                  deleteHandler(data.safe_table_id);
                }}
              >
                삭제
              </button>
            ),
          },
        ]}
        datas={list}
        rowCallback={(data) => setDetail(data)}
      />
      <Pagination curPage={page} total={total} pageSize={10} />
      {isOpenModal && (
        <CreateSafeNumberModal
          close={() => setIsOpenModal(false)}
          submitCallback={getList}
        />
      )}
      {detail && (
        <UpdateSafeNumberModal
          data={detail}
          close={() => setDetail(null)}
          submitCallback={getList}
        />
      )}
    </Container>
  );
};

export default List;
