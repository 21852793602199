import React, { useState } from "react";
import styled from "@emotion/styled";
import Modal from "../../../Common/ModalPortal";
import images from "../../../../libs/images";
import Button from "../../../Common/Button/Button";
import * as Colors from "../../../../constants/color";
import { marginTopStyles } from "../../home.style";
import Input from "../../../Common/Input/Input";
import Upload, { FileData } from "../../../Common/Upload/Upload";
import useHomeManagePick from "../homeManagePick.hook";

const Container = styled.div`
  width: 560px;
  background-color: #ffffff;
  margin: auto;
`;

const Header = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${Colors.gray_200};

  & > h3 {
    margin: auto 0;
  }
`;

const Content = styled.div`
  padding: 10px 20px 30px 20px;
`;

const Footer = styled.div`
  padding: 20px;
  border-top: 1px solid ${Colors.gray_200};
  display: flex;
  justify-content: center;
  gap: 15px;
`;

interface Props {
  close: () => void;
  callback: () => void;
}

const CreateHomeManagePick = (props: Props) => {
  const { close, callback } = props;
  const { create } = useHomeManagePick();

  const [pickData, setPickData] = useState({
    pick_name: "",
    pick_img: "",
    pick_url: "",
  });

  const changeInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setPickData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const changeFileHandler = ({ fileImage }: FileData) => {
    setPickData((prevData) => ({
      ...prevData,
      pick_img: fileImage,
    }));
  };

  const createHandler = async (): Promise<void> => {
    const res = await create(pickData);

    if (res) {
      callback();
    }
  };

  return (
    <Modal>
      <Container>
        <Header>
          <h3>픽 메뉴 등록</h3>
          <button onClick={close}>
            <img src={images.exit} alt="exit_btn" />
          </button>
        </Header>
        <Content>
          <div css={[marginTopStyles]}>
            <Input
              label="* 메뉴 명"
              placeholder="키워드 작성"
              size="small"
              name="pick_name"
              onChange={changeInputHandler}
            />
          </div>
          <div css={[marginTopStyles]}>
            <Upload
              id="upload"
              label="* 이미지 등록 (140 X 140)"
              placeholder="사진을 첨부해주세요."
              callback={changeFileHandler}
            />
          </div>
          <div css={[marginTopStyles]}>
            <Input
              label="* 연결 URL"
              placeholder="https://www.layhome.co.kr/EventPage?id=28"
              size="big"
              name="pick_url"
              onChange={changeInputHandler}
            />
          </div>
        </Content>
        <Footer>
          <Button theme="tertiary" onClick={close}>
            취소
          </Button>
          <Button onClick={createHandler}>등록</Button>
        </Footer>
      </Container>
    </Modal>
  );
};

export default CreateHomeManagePick;
