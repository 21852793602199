/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, forwardRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Link, useLocation } from "react-router-dom";
import routes from "../../../libs/routes";
import { open, close } from "../../../redux/popupSlice";
import { logOut } from "../../../redux/usersSlice";
import { reset } from "../../../redux/searchSlice";
import { css } from "@emotion/react";
import * as Colors from "../../../constants/color";

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

const PartnerMenu = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [menuOpen, setMenuOpen] = useState(false);
  const [menuOpen2, setMenuOpen2] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const m_white = [routes.my, routes.membership, routes.acc];

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setCloseFunc();
  }, [location.pathname]);

  const CustomLnb = forwardRef(
    ({ type, value, title, className, multyTitle }: any, ref) =>
      type === "multy" ? (
        <li
          className={
            (value.includes(location.pathname) ? "active " : "") + className
          }
        >
          <p onClick={() => linkFunc(value[0])}>{title}</p>
          <ul className="lnb_multy">
            {value.map((x: any, i: number) => {
              return (
                <li
                  key={i}
                  className={location.pathname === x ? "active " : ""}
                >
                  <a onClick={() => linkFunc(x)}>{multyTitle[i]}</a>
                </li>
              );
            })}
          </ul>
        </li>
      ) : (
        <li
          className={(location.pathname === value ? "active " : "") + className}
        >
          <a onClick={() => linkFunc(value)}>{title}</a>
        </li>
      )
  );

  const linkFunc = (link: string) => {
    dispatch(reset({}));
    if (link) navigate(link);
  };

  const setCloseFunc = () => {
    setMenuOpen(false);
    setTimeout(() => {
      setMenuOpen2(false);
    }, 300);
  };

  const callFunc = () => {
    dispatch(
      open({
        message: "02-1234-1234",
        button: "전화연결",
        buttonCencle: "취소",
        onCancelPress: dispatch(close()),
        onPress: () => {
          window.location.href = "tel:02-1234-1234";
        },
      })
    );
  };

  const logOutFunc = () => {
    dispatch(
      open({
        message: "로그아웃 하시겠습니까?",
        button: "확인",
        buttonCencle: "취소",
        onCancelPress: dispatch(close()),
        onPress: () => {
          dispatch(logOut());
          navigate(routes.login);
        },
      })
    );
  };

  return (
    <>
      {/* 파트너 페이지 메뉴 */}
      <div
        className={"header " + (menuOpen ? "active" : "")}
        style={{
          display: menuOpen2 || windowDimensions.width > 767 ? "block" : "none",
        }}
      >
        <button
          type="button"
          className="close_btn"
          onClick={() => setCloseFunc()}
        />
        <Link to={routes.main} className="logo indent999">
          LOGO
        </Link>

        <ul
          className="lnb"
          css={css`
            @media screen and (max-width: 767px) {
              border-bottom: 1px solid ${Colors.white};
            }
          `}
        >
          <CustomLnb value={routes.main} title={"홈"} className="home" />

          {windowDimensions.width > 767 && (
            <CustomLnb
              value={routes.instance}
              title={"사례관리"}
              className="instance"
            />
          )}

          <CustomLnb
            value={routes.estimate}
            title={"견적관리"}
            className="estimate"
          />

          {windowDimensions.width > 767 && (
            <CustomLnb
              value={routes.payManage}
              title={"결제관리"}
              className="pay"
            />
          )}

          <CustomLnb
            value={routes.schedule}
            title={"일정관리"}
            className="estimate"
          />

          {windowDimensions.width > 767 && (
            <CustomLnb
              value={routes.user}
              title={"사용자관리"}
              className="user"
            />
          )}

          <CustomLnb value={routes.my} title={"파트너 정보"} className="user" />

          <CustomLnb
            value={routes.membership}
            title={"멤버십 정보"}
            className="user"
          />

          <CustomLnb value={routes.acc} title={"계정 정보"} className="user" />
        </ul>

        <Link to="#" className="logout_btn" onClick={() => logOutFunc()}>
          로그아웃
        </Link>
        <div className="m_contact">
          <p className="title">CONTACT US</p>
          <p className="tel">1577-6549 l 09:30~16:30</p>
          <Link to="#" className="call_btn" onClick={() => callFunc()}>
            문의하기
          </Link>
        </div>
      </div>

      <div
        className={
          m_white.includes(location.pathname) ? "m_header m_white" : "m_header"
        }
      >
        {m_white.includes(location.pathname) ? (
          <button
            type="button"
            className="back_btn"
            onClick={() => navigate(-1)}
          ></button>
        ) : (
          <Link to={routes.main} className="logo_m indent999">
            LOGO
          </Link>
        )}
        <button
          type="button"
          className="menu_btn"
          onClick={() => {
            setMenuOpen(true);
            setMenuOpen2(true);
          }}
        />
      </div>
    </>
  );
};

export default PartnerMenu;
