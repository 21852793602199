/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import * as APIS from "../../utils/service";
import { findJson, numFormat } from "../../utils/utils";

import Input from "../../components/Input";
import InputSelect from "../../components/InputSelect";
import MemberShip from "../../components/MemberShip";

import consts from "../../libs/consts";

import { open, close } from "../../redux/popupSlice";

export default function EstimatePartner(props) {
  const { order_table_id, order_estimate_type } = props;
  const dispatch = useDispatch();
  const userData = useSelector((s) => s.usersReducer);

  const [partnersDefault, setPartnersDefault] = useState([]); // 견적의뢰 파트너들 기본리스트

  const [stxId, setStxId] = useState("");

  const [cker, setCker] = useState(false);
  const [cker2, setCker2] = useState(false);
  const [vip_grade, setvip_grade] = useState("0");

  const [list] = useState([]);
  const [chkList, setChkList] = useState([]);
  const [chkIdList, setChkIdList] = useState([]);

  const [err, setErr] = useState([]);
  const [err2, setErr2] = useState([]);

  useEffect(() => {
    searchFunc();
  }, [cker, cker2, vip_grade]);

  const searchFunc = () => {
    if (!stxId) {
      const sender = {
        admin_table_id: userData.admin_table_id,
        order_table_id: order_table_id,
        check: cker,
        is_period: cker2,
        vip_grade: vip_grade,
      };

      APIS.postData("/AdminOrder/OrderBaseSrch", sender, 1)
        .then((res) => {
          if (res.data.code === "OK") {
            setPartnersDefault(res.data.seller_list);
          }
        })
        .catch((e) => {
          console.log("catch", e);
        });
    } else {
      const sender = {
        admin_table_id: userData.admin_table_id,
        order_table_id: order_table_id,
        seller_id: stxId,
      };

      APIS.postData("/AdminOrder/OrderSellerSrch", sender, 1)
        .then((res) => {
          if (res.data.code === "OK") {
            setPartnersDefault(res.data.seller_list);
          } else {
            setPartnersDefault([]);
          }
        })
        .catch((e) => {
          console.log("catch", e);
        });
    }
  };

  const submitFunc = async () => {
    let err = [];
    let err2 = [];

    if (chkList.length > 0) {
      for (let i = 0; i < chkList.length; i++) {
        let rt = await sellerUpdate(chkList[i]);

        if (rt.code === "ERR_601") err.push(rt.data);
        else if (rt.code === "ERR_602") err2.push(rt.data);
      }
    }
    dispatch(close());

    if (err.length > 0 || err2.length > 0) {
      setTimeout(() => {
        dispatch(
          open({
            title: "정보제공 실패알림",
            message: `정량제 수량부족 및 기간경과\n${err
              .map((x, i) => {
                return `· ${x.seller_biz_name}(${x.seller_id})`;
              })
              .join("\n")}\n\n정액제 수량부족 및 기간경과\n${err2
              .map((x, i) => {
                return `· ${x.seller_biz_name}(${x.seller_id})`;
              })
              .join("\n")}`,
            messageAuto: true,
          })
        );
      }, 300);
    } else {
      setTimeout(() => {
        dispatch(
          open({
            message: `저장되었습니다.`,
            messageAuto: true,
          })
        );
      }, 300);
    }
  };

  const sellerUpdate = (x) => {
    const sender = {
      admin_table_id: userData.admin_table_id,
      seller_table_id: x.seller_table_id,
      order_table_id: order_table_id,
      is_period: cker2,
    };

    let rt = "";

    rt = APIS.postData("/AdminOrder/OrderSellerAdd", sender, 1)
      .then((res) => {
        let rtt = "";
        if (res.data.code === "OK") {
        } else if (res.data.code === "ERR_601") {
          setErr([...err, x]);
          rtt = { code: "ERR_601", data: x };
        } else if (res.data.code === "ERR_602") {
          setErr2([...err2, x]);
          rtt = { code: "ERR_602", data: x };
        }
        return rtt;
      })
      .catch((e) => {
        console.log("catch", e);
      });

    return rt;
  };

  const checkFunc = (i, x, type = "") => {
    if (document.getElementById("sellerrd" + type + "" + i).checked === true) {
      document.getElementById("sellerrd" + type + "" + i).checked = false;

      setChkList(
        chkList.filter((item) => item.seller_table_id !== x.seller_table_id)
      );
      setChkIdList(chkIdList.filter((item) => item !== x.seller_table_id));
    } else {
      document.getElementById("sellerrd" + type + "" + i).checked = true;
      setChkIdList(() => [...chkIdList, x.seller_table_id]);
      setChkList(() => [...chkList, x]);
    }
  };

  return (
    <form className="popup_search">
      <div className="popup_search_box">
        <Input
          className="input_text"
          type="text"
          placeholder="파트너 ID를 입력해 주세요."
          name="stxId"
          value={stxId}
          setValue={setStxId}
          label="파트너 ID"
          withButton={"찾기"}
          withButtonPress={searchFunc}
        />
        <div
          className="popup_search_box_option"
          style={{ marginBottom: "30px" }}
        >
          <input
            type="checkbox"
            id="rachkpop"
            className="checkbox"
            checked={cker}
            onChange={(e) => setCker(e.target.checked)}
          />
          <label htmlFor="rachkpop">기존제공파트너 제외</label>
        </div>
        <InputSelect
          name="vip_grade"
          label="파트너 유형"
          value={vip_grade}
          setValue={setvip_grade}
          option={consts.settingFixoption}
        />
        <div className="popup_search_box_option">
          <input
            type="checkbox"
            id="rachkpop2"
            className="checkbox"
            checked={cker2}
            onChange={(e) => setCker2(e.target.checked)}
          />
          <label htmlFor="rachkpop2">정량제우선(정량제만)</label>
        </div>

        <div className="estimate_partner_list" style={{ width: "100%" }}>
          <label class="input_label">선택된 파트너명</label>
          <div>
            {chkList.length > 0 &&
              chkList.map((x, i) => {
                return (
                  <p key={i}>
                    {x.seller_biz_name}({x.seller_id})
                  </p>
                );
              })}
          </div>
        </div>

        <div className="table_section">
          <table className="tableStyle1">
            <thead>
              <tr>
                <th className="wd80">선택</th>
                <th className="wd130">파트너명</th>
                <th className="wd130">파트너 ID</th>
                <th className="wd110">정액제 등급</th>
                <th className="wd110">정액제 수량</th>
                <th className="wd110">정량제 수량</th>
                <th className="wd110">정보제공수</th>

                <th className="wd150">최근정보제공일</th>
                {/* <th className="wdFull">최근회신결과</th> */}
              </tr>
            </thead>
            <tbody>
              {partnersDefault.length > 0 &&
                partnersDefault.map((x, i) => {
                  return (
                    <tr
                      key={i}
                      onClick={() => checkFunc(i, x)}
                      className={"pointer"}
                    >
                      <td>
                        <input
                          type="checkbox"
                          id={"sellerrd" + i}
                          name="sellerrd"
                          checked={chkIdList.includes(x.seller_table_id)}
                          className="checkbox seller_list"
                          value={x.seller_table_id}
                          data-text={x.seller_biz_name}
                          data-id={x.seller_id}
                        />
                        <label />
                      </td>

                      <td
                        className={
                          x.is_first &&
                          !cker &&
                          !cker2 &&
                          vip_grade === "0" &&
                          !stxId &&
                          order_estimate_type !== "0" &&
                          order_estimate_type !== "2"
                            ? x.is_work * 1 === 1
                              ? "cl_5"
                              : "cl_3"
                            : ""
                        }
                      >
                        {x.seller_biz_name}
                      </td>
                      <td
                        className={
                          x.is_first &&
                          !cker &&
                          !cker2 &&
                          vip_grade === "0" &&
                          !stxId &&
                          order_estimate_type !== "0" &&
                          order_estimate_type !== "2"
                            ? x.is_work * 1 === 1
                              ? "cl_5"
                              : "cl_3"
                            : ""
                        }
                      >
                        {x.seller_id}
                      </td>

                      <td>
                        <MemberShip type={x.fixed_term_grade} />
                      </td>
                      <td>{numFormat(x.available_count)}</td>
                      <td>{numFormat(x.period_cnt)}</td>

                      <td>{numFormat(x.provide_cnt)}건</td>

                      <td>
                        {x.recent_prov
                          ? moment(x.recent_prov).format("YYYY/MM/DD HH:mm")
                          : ""}
                      </td>
                      {/* <td>{findJson(consts.sellerOrderRespState, x.recent_result)}</td> */}
                    </tr>
                  );
                })}

              {list.length > 0 &&
                list.map((x, i) => {
                  return (
                    <tr
                      key={i}
                      onClick={() => checkFunc(i, x, 2)}
                      className={"pointer"}
                    >
                      <td>
                        <input
                          type="checkbox"
                          id={"sellerrd2" + i}
                          name="sellerrd"
                          checked={chkIdList.includes(x.seller_table_id)}
                          className="checkbox seller_list"
                          value={x.seller_table_id}
                          data-text={x.seller_biz_name}
                          data-id={x.seller_id}
                        />
                        <label htmlFor={"sellerrd2" + i}></label>
                      </td>
                      <td>{x.seller_biz_name}</td>
                      <td>{x.seller_id}</td>
                      <td>
                        <MemberShip type={x.fixed_term_grade} />
                      </td>
                      <td>{numFormat(x.available_count)}</td>
                      <td>{numFormat(x.period_cnt)}</td>
                      <td>{numFormat(x.provide_cnt)}건</td>
                      <td>
                        {x.recent_prov
                          ? moment(x.recent_prov).format("YYYY/MM/DD HH:mm")
                          : ""}
                      </td>
                      <td>
                        {findJson(consts.sellerOrderRespState, x.recent_result)}
                      </td>
                    </tr>
                  );
                })}

              {partnersDefault.length < 1 && list.length < 1 && (
                <tr>
                  <td className="null_td" colSpan={8}>
                    파트너를 검색 해 주세요.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="popup_btn">
        <button
          type="button"
          className="popbtn cencle_btn"
          onClick={() => {
            dispatch(close());
          }}
          style={{ width: "100%", marginRight: "10px" }}
        >
          취소
        </button>
        <button
          type="button"
          className="popbtn ok_btn"
          onClick={() => {
            submitFunc();
          }}
          style={{ width: "100%" }}
        >
          확인
        </button>
      </div>
    </form>
  );
}
