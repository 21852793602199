/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as APIS from "../../utils/service";
import { findJson, findJsonCl } from "../../utils/utils";
import moment from "moment";
import InputDate from "../../components/InputDate";
import InputSelect from "../../components/InputSelect";
import Pagenation from "../../components/Pagenation";
import Add from "./Add";
import ListM from "./ListM";
import consts from "../../libs/consts";
import { open } from "../../redux/popupSlice";
import { setData } from "../../redux/searchSlice";
import PartnerLayout from "../../components/Layout/partner";
import { css } from "@emotion/react";

export default function Estimate() {
  const dispatch = useDispatch();
  const userData = useSelector((s) => s.usersReducer);
  const search = useSelector((s) => s.searchReducer);
  const popup = useSelector((s) => s.popupReducer);

  const [list, setList] = useState([]); // PC용 리스트
  const [mlist, setMList] = useState([]); // 모바일용 리스트

  const [pageing, setPageing] = useState("");

  useEffect(() => {
    dataFunc();
  }, [search.state, search.sdate, search.edate, search.page, popup.open]);

  const dataFunc = () => {
    let urls = "/OrderInfo/OrderList";
    let types = 2;
    let sender = {
      seller_table_id: userData.seller_table_id,
      order_state: search.state,
      start_date: search.sdate,
      end_date: search.edate,
      page: search.page + 1,
    };

    APIS.postData(urls, sender, types)
      .then((res) => {
        if (res.data.code === "OK") {
          setList(res.data.order_list);
          setMList(res.data.order_list);
          setPageing({
            totalPages: Math.ceil(res.data.total_cnt / consts.pagerows),
          });
          //setPageing({totalPages: Math.ceil(res.data.total_cnt / consts.pagerows)});
        } else {
          setList([]);
          setMList([]);
          setPageing({ totalPages: 1 });
        }
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };

  const updateFunc = (id) => {
    if (!id) return;

    let urls = "/OrderInfo/OrderTarget";
    let types = 2;
    let sender = {
      seller_table_id: userData.seller_table_id,
      order_table_id: id,
    };

    APIS.postData(urls, sender, types)
      .then((res) => {
        if (res.data.code === "OK" && res.data.order_list.length > 0) {
          dispatch(
            open({
              title: "견적관리",
              wide: true,
              component: <Add x={res.data.order_list[0]} />,
            })
          );
        } else {
          dispatch(
            open({
              message: "잘못된 접근입니다.",
            })
          );
        }
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };

  const searchFunc = (key, value) => {
    dispatch(
      setData({
        key: key,
        value: value,
      })
    );
  };

  return (
    <PartnerLayout title="견적관리">
      <div>
        <div className="search_form">
          <div
            className="search_form_left"
            css={css`
              @media screen and (max-width: 767px) {
                display: none !important;
              }
            `}
          >
            <InputSelect
              name="state"
              label="진행상태"
              value={search.state}
              onSearch={searchFunc}
              option={[{ idx: "", title: "전체" }, ...consts.sellerOrderState]}
            />

            <InputDate
              type="text"
              placeholder="시작날짜"
              placeholderEnd="종료날짜"
              name="ipd1"
              label="날짜선택"
              dateValue={search.sdate}
              onSearch={searchFunc}
              dateValueEnd={search.edate}
              multiple={true}
            />

            <button
              type="button"
              className="btn btn3"
              onClick={() => dataFunc()}
            >
              검색
            </button>
          </div>
        </div>

        <div
          className="list_table_view"
          css={css`
            @media screen and (max-width: 767px) {
              display: none;
            }
          `}
        >
          <table className="tableStyle1">
            <thead>
              <tr>
                <th className="wd130 th_range">시공범위</th>
                {userData.grade === 10 && <th className="wd130">파트너명</th>}
                <th className="wd300 th_area">시공지역</th>
                <th className="wdFull">아파트명</th>
                <th className="wd80">평형</th>
                <th className="wd130">희망시공일</th>
                <th className="wd130">진행상태</th>
                <th className="wd150">최종일시</th>
              </tr>
            </thead>
            <tbody>
              {list.length > 0 ? (
                list.map((x, i) => {
                  return (
                    <tr
                      key={i}
                      className={"pointer"}
                      onClick={() => updateFunc(x.order_table_id)}
                    >
                      <td>
                        {x.work_type === "1"
                          ? "전체"
                          : x.work_type === "2"
                          ? "부분"
                          : "미입력"}
                      </td>
                      {userData.grade === 10 && <td>{x.seller_biz_name}</td>}
                      <td>{x.area_name}</td>
                      <td>{x.order_home_name}</td>
                      <td>{x.order_size}</td>

                      <td>
                        {x.order_desired_date === "0000-00-00 00:00:00" ||
                        x.order_desired_date === null
                          ? "미정"
                          : moment(x.order_desired_date).format("YYYY.MM.DD")}
                      </td>
                      <td
                        className={findJsonCl(
                          consts.sellerOrderState,
                          x.order_state
                        )}
                      >
                        {findJson(consts.sellerOrderState, x.order_state)}
                      </td>
                      <td>
                        {moment(x.update_date).format("YYYY.MM.DD HH:mm")}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className="null_td" colSpan={8}>
                    데이터가 없습니다.
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <Pagenation
            pageing={pageing}
            onSearch={searchFunc}
            curpage={search.page}
          />
        </div>

        <div className="list_mobile_view">
          {mlist.length > 0 ? (
            mlist.map((x, i) => {
              return (
                <ListM
                  key={i}
                  x={x}
                  onPress={() => updateFunc(x.order_table_id)}
                />
              );
            })
          ) : (
            <div className="null_container">
              <p className="null_msg">데이터가 없습니다.</p>
            </div>
          )}
        </div>
      </div>
    </PartnerLayout>
  );
}
