import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import useInstance from "./instance.hook";
import InstanceReceiptModal from "../../components/Instance/Modal/Receipt";
import PriceInfo from "./priceAndInfo/PriceInfo";
import AddInfo from "./addInfo/AddInfo";
import ConstructionInfo from "./constructionInfo/ConstructionInfo";

const PreviewBtn = styled.button`
  position: absolute;
  right: 50px;
  padding: 5px 20px;
  bottom: 10px;
  border: solid 1px #c5cee0;
  background-color: #fff;
  color: #718096;
  font-size: 16px;
  font-weight: bold;
  transition: border 0.3s, color 0.3s;
`;
export default function Add(props) {
  const { w } = props;
  const [data, setData] = useState(props.data);
  const { isReceiptOpen, openReceiptModal, closeReceiptModal } = useInstance();
  const userData = useSelector((s) => s.usersReducer);
  const [tabNum, setTabNum] = useState(1);
  const [caseTableId, setCaseTableId] = useState(
    data.case_info ? data.case_info.case_table_id : ""
  );
  const [seller, setSeller] = useState(
    data.case_info ? data.case_info.seller_table_id : ""
  );
  /* tabNum2 state */
  /*시공사례 -> 영수증*/
  const [caseReceipt, setCaseReceipt] = useState(
    data && data.zone_parts && data.zone_parts.length > 0
      ? data.zone_parts
      : [{ case_parts_no: "1", case_price: "", case_desc: "" }] || []
  );
  const [receiptDeleteIds, setReceiptDeleteIds] = useState([]);
  /* tabNum2 state */
  useEffect(() => {
    document.querySelector(".popup_list_ul").scrollTo(0, 0);
  }, [tabNum]);
  const topNavFunc = (num) => {
    if (w !== "u") return;
    setTabNum(num);
  };
  const onClickPreviewHandler = (case_table_id) => {
    const { REACT_APP_BASEURL } = process.env;
    window.open(
      `${REACT_APP_BASEURL}/frontReportDetail?id=${case_table_id}`,
      "_blank"
    );
  };
  return (
    <div className="popup_list">
      <div className="pop_progress">
        <p
          className={tabNum === 1 ? "active" : ""}
          onClick={() => topNavFunc(1)}
        >
          1. 시공정보
        </p>
        <p
          className={tabNum === 2 ? "active" : ""}
          onClick={() => topNavFunc(2)}
        >
          2. 가격 및 정보
        </p>
        <p
          className={tabNum === 3 ? "active" : ""}
          onClick={() => topNavFunc(3)}
        >
          3. 추가정보
        </p>
        {caseTableId && (
          <PreviewBtn onClick={() => onClickPreviewHandler(caseTableId)}>
            미리보기
          </PreviewBtn>
        )}
      </div>
      {tabNum === 1 && (
        <ConstructionInfo
          data={data}
          caseTableId={caseTableId}
          setCaseTableId={setCaseTableId}
          userData={userData}
          tabNum={tabNum}
          setTabNum={setTabNum}
          seller={seller}
          setSeller={setSeller}
        />
      )}
      {tabNum === 2 && (
        <PriceInfo
          data={data}
          userData={userData}
          setData={setData}
          seller={seller}
          tabNum={tabNum}
          caseTableId={caseTableId}
          setCaseTableId={setCaseTableId}
          caseReceipt={caseReceipt}
          openReceiptModal={openReceiptModal}
          setCaseReceipt={setCaseReceipt}
          setTabNum={setTabNum}
          receiptDeleteIds={receiptDeleteIds}
        />
      )}
      {tabNum === 3 && (
        <AddInfo
          data={data}
          userData={userData}
          seller={seller}
          case_table_id={caseTableId}
          tabNum={tabNum}
        />
      )}
      {isReceiptOpen && (
        <InstanceReceiptModal
          close={closeReceiptModal}
          caseReceipt={caseReceipt}
          receiptDeleteIds={receiptDeleteIds}
          setReceiptDeleteIds={setReceiptDeleteIds}
          saveCallback={(value) => setCaseReceipt(value)}
        />
      )}
    </div>
  );
}
