import React, { useState } from "react";
import styled from "@emotion/styled";
import Modal from "../../../Common/ModalPortal";
import images from "../../../../libs/images";
import Button from "../../../Common/Button/Button";
import * as Colors from "../../../../constants/color";
import { marginTopStyles } from "../../home.style";
import Input from "../../../Common/Input/Input";
import useHomeManageMonthly from "../homeManageMonthly.hook";

const Container = styled.div`
  width: 560px;
  background-color: #ffffff;
  margin: auto;
`;

const Header = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${Colors.gray_200};

  & > h3 {
    margin: auto 0;
  }
`;

const Content = styled.div`
  padding: 10px 20px 30px 20px;
`;

const Footer = styled.div`
  padding: 20px;
  border-top: 1px solid ${Colors.gray_200};
  display: flex;
  justify-content: center;
  gap: 15px;
`;

interface Props {
  close: () => void;
  callback: () => void;
}

const CreateHomeManageMonthly = (props: Props) => {
  const { create } = useHomeManageMonthly();

  const { close, callback } = props;

  const [hotCaseName, setHotCaseName] = useState("");

  const [reportNumbers, setReportNumbers] = useState([
    { id: 1, report_id: "" },
    { id: 2, report_id: "" },
    { id: 3, report_id: "" },
  ]);

  const updateHandler = (
    id: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setReportNumbers(
      reportNumbers.map((num) =>
        num.id === id
          ? {
              ...num,
              report_id: e.target.value,
            }
          : num
      )
    );
  };

  const createHandler = async () => {
    const params = {
      hot_case_name: hotCaseName,
      case_table_id_1: reportNumbers[0].report_id,
      case_table_id_2: reportNumbers[1].report_id,
      case_table_id_3: reportNumbers[2].report_id,
    };

    const res = await create(params);

    if (res) {
      callback();
    }
  };

  return (
    <Modal>
      <Container>
        <Header>
          <h3>월간 인기 시공사례 등록</h3>
          <button onClick={close}>
            <img src={images.exit} alt="exit_btn" />
          </button>
        </Header>
        <Content>
          <div css={[marginTopStyles]}>
            <Input
              label="* 키워드 명"
              placeholder="키워드 작성"
              onChange={(e) => setHotCaseName(e.target.value)}
            />
          </div>
          {reportNumbers.map((number) => (
            <div key={number.id} css={[marginTopStyles]}>
              <Input
                label={`* 시공사례 번호 ${number.id}`}
                placeholder={`${100 + number.id}`}
                onChange={(e) => updateHandler(number.id, e)}
              />
            </div>
          ))}
        </Content>
        <Footer>
          <Button theme="tertiary" onClick={close}>
            취소
          </Button>
          <Button onClick={createHandler}>등록</Button>
        </Footer>
      </Container>
    </Modal>
  );
};

export default CreateHomeManageMonthly;
