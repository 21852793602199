import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import * as APIS from "../../utils/service";
import { regEmail, patternKor } from "../../utils/utils";
import { open, close } from "../../redux/popupSlice";
import { logIn } from "../../redux/usersSlice";
import Input from "../../components/Input";
import InputSelect from "../../components/InputSelect";
import TextArea from "../../components/TextArea";
import InputFile from "../../components/InputFile";
import PGForm from "../../components/PGForm";
import Area from "./Area";
import routes from "../../libs/routes";
import consts from "../../libs/consts";

export default function Register() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const joinData = useSelector((s) => s.joinReducer);

  const [step, setStep] = useState("1");

  const [terms1, setTerms1] = useState("");
  const [terms2, setTerms2] = useState("");
  const [terms3, setTerms3] = useState("");

  const [name, setName] = useState("");
  const [bn, setBn] = useState("");
  const [realBn, setRealBn] = useState("");
  const [hp, setHp] = useState("");
  const [chk1, setChk1] = useState(false);
  const [chk2, setChk2] = useState(false);
  const [chk3, setChk3] = useState(false);

  const [id, setId] = useState("");
  const [idReal, setIdReal] = useState("");
  const [pw, setPw] = useState("");
  const [repw, setRePw] = useState("");

  const [comp, setComp] = useState("");
  const [addr, setAddr] = useState("");
  const [addrDetail, setAddrDetail] = useState("");
  const [area, setArea] = useState("");
  const [areaText, setAreaText] = useState("");

  const [range1, setRange1] = useState(false);
  const [range2, setRange2] = useState(false);

  const [spec, setSpec] = useState("");
  const [serve, setServe] = useState("");
  const [as, setAs] = useState("");

  const [license, setLicense] = useState("0");

  const [email, setEmail] = useState("");
  const [comment, setComment] = useState("");
  const [logo, setLogo] = useState("");
  const [logoImg, setLogoImg] = useState("");

  const [error, setError] = useState("");
  const [error2, setError2] = useState("");
  const [error3, setError3] = useState("");
  const [error4, setError4] = useState("");
  const [success4, setSuccess4] = useState("");

  const [error5, setError5] = useState("");
  const [error6, setError6] = useState("");
  const [error7, setError7] = useState("");
  const [error9, setError9] = useState("");
  const [error10, setError10] = useState("");

  useEffect(() => {
    let sender = {
      tid: "3",
    };
    APIS.postData("/Login/GetTerms", sender, 2)
      .then((res) => {
        if (res.data.code === "OK") {
          setTerms1(res.data.terms);
        } else {
          setTerms1("");
        }
      })
      .catch((e) => {
        console.log("catch", e);
      });
    sender = {
      tid: "2",
    };
    APIS.postData("/Login/GetTerms", sender, 2)
      .then((res) => {
        if (res.data.code === "OK") {
          setTerms2(res.data.terms);
        } else {
          setTerms2("");
        }
      })
      .catch((e) => {
        console.log("catch", e);
      });
    sender = {
      tid: "4",
    };
    APIS.postData("/Login/GetTerms", sender, 2)
      .then((res) => {
        if (res.data.code === "OK") {
          setTerms3(res.data.terms);
        } else {
          setTerms3("");
        }
      })
      .catch((e) => {
        console.log("catch", e);
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  useEffect(() => {
    if (!pw || !repw) {
      setError4("");
      setSuccess4("");
      return;
    }

    if (pw !== repw) {
      setError4("비밀번호가 일치하지 않습니다.");
      setSuccess4("");
    } else {
      setError4("");
      setSuccess4("올바른 비밀번호 입니다.");
    }
  }, [pw, repw]);

  const bnSucFunc = () => {
    const code = document.getElementById("PG_CODE").value;

    if (code === "OK") {
      setName(document.getElementById("PG_NAME").value);
      setHp(document.getElementById("PG_HP").value);
      setStep("2");
    } else if (code === "ERR_306") {
      dispatch(
        open({
          message: "해당 휴대전화번호로 가입된 계정이 있습니다.",
          messageAuto: true,
          button: "확인",
          onPress: goLogin,
          onCenclePress: goLogin,
        })
      );
    } else {
      dispatch(
        open({
          message: "잠시 후 다시 시도해주세요.",
          button: "확인",
        })
      );
    }
  };

  const bnFunc = () => {
    if (!bn) {
      setError("사업자등록번호를 입력해주세요.");
      return;
    }
    setRealBn(bn);

    const sender = {
      biz_no: bn,
    };

    APIS.postData("/SellerInfo/CheckBiz", sender, 2)
      .then((res) => {
        if (res.data.code === "OK") {
          let PG_POP = window.payRequest();

          const interval = window.setInterval(function () {
            try {
              if (PG_POP == null || PG_POP.closed) {
                window.clearInterval(interval);
                bnSucFunc();
              }
            } catch (e) {}
          }, 1000);
        } else if (res.data.code === "ERR_301") {
          dispatch(
            open({
              message:
                "입력해주신 사업자등록번호로 가입된 계정이 있습니다. 로그인 하시겠습니까?",
              messageAuto: true,
              button: "확인",
              buttonCencle: "취소",
              onPress: goLogin,
            })
          );
        } else {
          dispatch(
            open({
              message:
                "사업자등록번호가 존재 하지 않습니다.\n다시 한번 확인 후 입력 부탁드립니다.",
              button: "확인",
            })
          );
        }
      })
      .catch((e) => {
        console.log("catch", e);
        dispatch(
          open({
            message: "잠시 후 다시 시도해주세요.",
            button: "확인",
          })
        );
      });
  };

  const goLogin = () => {
    navigate(routes.login);
  };

  const goLoginSuc = () => {
    const sender = {
      seller_id: id,
      seller_passwd: pw,
    };
    /* 아이디, 비밀번호 검사 */
    APIS.postData("/Login/SiteLogin", sender, 2)
      .then((res) => {
        dispatch(close());
        if (res.data.code === "OK") {
          dispatch(
            logIn({
              mbId: res.data.seller_id,
              mbName: res.data.seller_name,
              mbProfile: res.data.seller_icon,

              kakao_auth_key: res.data.kakao_auth_key,
              reg_date: res.data.reg_date,
              seller_addr: res.data.seller_addr,
              seller_addr_detail: res.data.seller_addr_detail,
              seller_area: res.data.seller_area,
              seller_area_text: res.data.seller_area_text,
              seller_as: res.data.seller_as,
              seller_biz_no: res.data.seller_biz_no,
              seller_biz_name: res.data.seller_biz_name,
              seller_career: res.data.seller_career,
              seller_desc: res.data.seller_desc,
              seller_email: res.data.seller_email,
              seller_grade: res.data.seller_grade,
              seller_icon: res.data.seller_icon,
              seller_id: res.data.seller_id,
              seller_license_yn: res.data.seller_license_yn,
              seller_name: res.data.seller_name,
              seller_passwd: res.data.seller_passwd,
              seller_phone: res.data.seller_phone,
              seller_service: res.data.seller_service,
              service_state: res.data.service_state,
              seller_state: res.data.seller_state,
              seller_table_id: res.data.seller_table_id,
              update_date: res.data.update_date,
              work_kind: res.data.work_kind,
            })
          );
          navigate(routes.main);
        } else {
          navigate(routes.login);
        }
      })
      .catch((e) => {
        console.log("catch", e);
        dispatch(
          open({
            message: "잠시 후 다시 시도해주세요.",
            button: "확인",
          })
        );
      });
  };
  const clauseFunc = () => {
    if (!chk1 || !chk2) {
      dispatch(
        open({
          message: "회원가입 약관에 모두 동의해 주세요.",
          button: "확인",
        })
      );
      return;
    }

    setStep("3");
  };

  const registerCencle = () => {
    dispatch(
      open({
        message: "회원가입을 중단 하시겠습니까?",
        button: "확인",
        buttonCencle: "취소",
        onPress: goLogin,
      })
    );
  };

  const infoFunc = () => {
    if (
      !id ||
      !pw ||
      !repw ||
      !comp ||
      !addr ||
      !area ||
      (!range1 && !range2) ||
      !spec ||
      !serve ||
      !license
    ) {
      dispatch(
        open({
          message: "모든 정보를 입력 후 다음 단계로 이동해주시기 바랍니다.",
          messageAuto: true,
          button: "확인",
        })
      );
      return;
    }

    if (id !== idReal) {
      dispatch(
        open({
          message: "아이디 중복확인을 해 주세요.",
          button: "확인",
        })
      );
      return;
    }

    /* 비밀번호 일치 및 최소글자수 확인 */
    if (pw !== repw || pw.length < 8) {
      dispatch(
        open({
          message: "비밀번호를 확인해 주세요.",
          button: "확인",
        })
      );
      return;
    }

    setStep("4");
  };

  /* 회원가입 완료 */
  const registerFunc = () => {
    if (email && !regEmail.test(email)) {
      dispatch(
        open({
          message: "이메일 형식을 확인해 주세요.",
          button: "확인",
        })
      );
      return;
    }

    const sender = {
      seller_id: id,
      seller_biz_no: realBn,
      seller_passwd: pw,
      seller_name: name,
      seller_biz_name: comp,
      seller_phone: hp,
      kakao_auth_key: joinData.kakaoId,
      seller_email: email,
      seller_area: area,
      seller_area_text: areaText.split(", "),
      seller_addr: addr,
      seller_addr_detail: addrDetail,
      seller_icon: logoImg,
      seller_career: spec,
      seller_service: serve,
      seller_as: as,
      seller_license_yn: license,
      seller_desc: comment,
      work_kind: [range1 ? 1 : 0, range2 ? 1 : 0],
      market_agree: chk3 ? 1 : 0,
    };

    APIS.postData("/SellerInfo/Create", sender, 2)
      .then((res) => {
        if (res.data.code === "OK") {
          dispatch(
            open({
              message: "회원가입이 완료되었습니다.",
              button: "확인",
              onPress: goLoginSuc,
              onCancelPress: goLoginSuc,
            })
          );
        } else {
          dispatch(
            open({
              message: "잠시 후 다시 시도해주세요.",
              button: "확인",
            })
          );
        }
      })
      .catch((e) => {
        console.log("catch", e);

        dispatch(
          open({
            message: "잠시 후 다시 시도해주세요.",
            button: "확인",
          })
        );
      });
    return;
  };
  const idChk = () => {
    if (!id) {
      setError2("아이디를 입력해주세요.");
      return;
    }

    if (patternKor.test(id)) {
      setError2(
        "영문 소문자, 숫자와 특수기호(@),(.),(-),(_) 만 사용 가능합니다."
      );
      return;
    }

    const sender = {
      seller_name: id,
    };

    APIS.postData("/SellerInfo/CheckName", sender, 2)
      .then((res) => {
        let msg = "";
        if (res.data.code === "OK") {
          // 사용가능
          setIdReal(id);
          msg = "사용 가능한 아이디 입니다.";
        } else {
          setIdReal("");
          msg = "이미 사용중인 아이디 입니다.";
        }
        dispatch(
          open({
            message: msg,
            button: "확인",
          })
        );
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };

  const areaFunc = () => {
    dispatch(
      open({
        title: "시공가능지역선택",
        component: (
          <Area value={area} setValue={setArea} setValueText={setAreaText} />
        ),
      })
    );
  };

  const setServeFunc = () => {
    let sv = [];
    const checkboxes = document.getElementsByClassName("serve");
    for (let cb of checkboxes) {
      if (cb.checked) sv.push(cb.value);
    }
    setServe(sv.join(", "));
  };

  return (
    <div className="register">
      <div className="register_section">
        <div className="register_title">
          <button type="button" className="logo4" />
          <p className="title">회원가입</p>

          <ul className="progress">
            <li className={step === "1" ? "active" : ""}>1</li>
            <li className={step === "2" ? "active" : ""}>2</li>
            <li className={step === "3" ? "active" : ""}>3</li>
            <li className={step === "4" ? "active" : ""}>4</li>
          </ul>
        </div>

        {step === "1" ? (
          <div className="register_body">
            <p className="sub_title">사업자등록번호를 입력해 주세요.</p>
            <input type="hidden" name="PG_CODE" id="PG_CODE" />
            <input type="hidden" name="PG_NAME" id="PG_NAME" />
            <input type="hidden" name="PG_HP" id="PG_HP" />

            <Input
              className="input_text"
              type="text"
              placeholder="숫자만 입력해 주세요."
              name="bn"
              value={bn}
              setValue={setBn}
              label="사업자등록번호"
              error={error}
              valid={"num"}
              setError={setError}
            />
            <button type="button" className="btn btn1" onClick={() => bnFunc()}>
              {"사업자등록번호 인증"}
            </button>
          </div>
        ) : step === "2" ? (
          <div className="register_body">
            <input
              type="checkbox"
              id="chk1"
              className="checkbox"
              onChange={(e) => setChk1(e.target.checked)}
            />
            <label htmlFor="chk1">이용약관 동의</label>

            <div
              className="clause_box"
              dangerouslySetInnerHTML={{ __html: terms1 }}
            ></div>

            <input
              type="checkbox"
              id="chk2"
              className="checkbox"
              onChange={(e) => setChk2(e.target.checked)}
            />
            <label htmlFor="chk2">개인정보 수집.이용 동의</label>

            <div
              className="clause_box"
              dangerouslySetInnerHTML={{ __html: terms2 }}
            ></div>

            <input
              type="checkbox"
              id="chk3"
              className="checkbox"
              onChange={(e) => setChk3(e.target.checked)}
            />
            <label htmlFor="chk3">마케팅 정보 수신 동의 (선택)</label>

            <div
              className="clause_box"
              dangerouslySetInnerHTML={{ __html: terms3 }}
            ></div>

            <div className="register_btn_box">
              <button
                type="button"
                className="btn btn3"
                onClick={() => goLogin()}
              >
                거부
              </button>
              <button
                type="button"
                className="btn btn1"
                onClick={() => clauseFunc()}
              >
                동의
              </button>
            </div>
          </div>
        ) : step === "3" ? (
          <div className="register_body">
            <p className="title">필수 정보 입력</p>

            <Input
              className="input_text"
              type="text"
              placeholder="영문/숫자만 입력해 주세요."
              name="id"
              value={id}
              setValue={setId}
              label="아이디"
              error={error2}
              valid={"id"}
              withButton={"중복확인"}
              withButtonPress={idChk}
              setError={setError2}
            />

            <Input
              className="input_text"
              type="password"
              placeholder="영문+숫자+특수문자 조합 (최소 8자~15자)"
              name="pw"
              value={pw}
              setValue={setPw}
              label="비밀번호"
              error={error3}
              setError={setError3}
              valid={"pw"}
              maxlength={15}
            />
            <Input
              className="input_text"
              type="password"
              placeholder="영문+숫자+특수문자 조합 (최소 8자~15자)"
              name="repw"
              value={repw}
              setValue={setRePw}
              label="비밀번호 확인"
              error={error4}
              success={success4}
              valid={"pw"}
              maxlength={15}
            />

            <Input
              className="input_text"
              type="text"
              placeholder="대표자명을 입력해 주세요."
              id="name"
              name="name"
              value={name}
              label="대표자명"
              readOnly={true}
            />
            <Input
              className="input_text"
              type="text"
              placeholder="영문/숫자만 입력해 주세요."
              id="hp"
              name="hp"
              value={hp}
              label="휴대폰번호"
              readOnly={true}
            />

            <Input
              className="input_text"
              type="text"
              placeholder="업체명을 입력해 주세요."
              name="comp"
              value={comp}
              setValue={setComp}
              label="업체명"
              error={error5}
              setError={setError5}
              maxlength={30}
            />
            <Input
              className="input_text"
              type="text"
              placeholder="주소 찾기를 해 주세요."
              name="addr"
              value={addr}
              setValue={setAddr}
              label="주소"
              withButton={"주소찾기"}
            />
            <Input
              className="input_text"
              type="text"
              placeholder="상세주소를 입력해 주세요."
              name="addrDetail"
              value={addrDetail}
              setValue={setAddrDetail}
              label="상세주소"
            />

            <Input
              className="input_text"
              type="text"
              placeholder="지역 선택을 해 주세요."
              name="area"
              value={areaText}
              setValue={setAreaText}
              label="시공가능지역"
              error={error6}
              withButton={"지역선택"}
              withButtonPress={areaFunc}
              setError={setError6}
              msg={
                "시공가능한 모든지역 선택. 서울시, 경기도는 시구 단위까지 선택"
              }
            />

            <div className="checkbox_section">
              <label className="input_label">시공범위</label>
              <div>
                <input
                  type="checkbox"
                  id="chk4"
                  className="checkbox"
                  onChange={(e) => setRange1(e.target.checked)}
                />
                <label htmlFor="chk4">전체</label>
                <input
                  type="checkbox"
                  id="chk5"
                  className="checkbox"
                  onChange={(e) => setRange2(e.target.checked)}
                />
                <label htmlFor="chk5">부분</label>
              </div>
            </div>

            <Input
              className="input_text"
              type="text"
              placeholder="경력 입력"
              name="spec"
              value={spec}
              setValue={setSpec}
              label="경력"
              withText={"년"}
              maxlength={2}
              valid={"num"}
              error={error7}
              setError={setError7}
            />
            {/* <Input className="input_text" type="text" placeholder="제공서비스" name="serve" value={serve} setValue={setServe} label="제공서비스" error={error8} setError={setError8}/> */}

            <div className="input_check_box input_check_box_long">
              <p className="input_label">제공서비스</p>
              <div>
                {consts.serveoption.map((x, i) => {
                  return (
                    <Fragment key={i}>
                      <input
                        type="checkbox"
                        id={"servechk" + i}
                        className="checkbox2 serve"
                        value={x.title}
                        onChange={() => setServeFunc()}
                      />
                      <label htmlFor={"servechk" + i}>{x.title}</label>
                    </Fragment>
                  );
                })}
              </div>
            </div>

            <InputSelect
              name="as"
              label="A/S보증"
              value={as}
              setValue={setAs}
              option={consts.asoption}
            />

            <div className="checkbox_section">
              <label className="input_label">실내건축업면허</label>
              <div>
                <input
                  type="radio"
                  id="rd1"
                  name="license"
                  className="radio"
                  value="1"
                  onChange={(e) => setLicense(e.target.value)}
                />
                <label htmlFor="rd1">보유</label>
                <input
                  type="radio"
                  id="rd2"
                  name="license"
                  className="radio"
                  value="0"
                  onChange={(e) => setLicense(e.target.value)}
                />
                <label htmlFor="rd2">미보유</label>
              </div>
            </div>

            <div className="register_btn_box">
              <button
                type="button"
                className="btn btn3"
                onClick={() => registerCencle()}
              >
                취소
              </button>
              <button
                type="button"
                className="btn btn1"
                onClick={() => infoFunc()}
              >
                다음
              </button>
            </div>
          </div>
        ) : (
          step === "4" && (
            <div className="register_body">
              <p className="title">선택 정보 입력</p>

              <Input
                className="input_text"
                type="text"
                placeholder="이메일을 입력해 주세요."
                name="email"
                value={email}
                setValue={setEmail}
                label="이메일"
                error={error9}
                setError={setError9}
                maxlength={50}
                valid={"email"}
              />
              <TextArea
                className="input_textarea"
                placeholder="파트너사의 업력 및 시공 전문분야를 잘표현할 수 있는 내용을 입력해 주세요. (500자 이내)"
                name="comment"
                value={comment}
                setValue={setComment}
                label="설명"
                error={error10}
                setError={setError10}
              />

              <InputFile
                type="file"
                placeholder="사진을 첨부해 주세요."
                name="logo"
                label="업체로고"
                value={logo}
                setValue={setLogo}
                imgvalue={logoImg}
                setImgValue={setLogoImg}
                valid="image"
              />

              <div className="register_btn_box">
                <button
                  type="button"
                  className="btn btn3"
                  onClick={() => registerCencle()}
                >
                  취소
                </button>
                <button
                  type="button"
                  className="btn btn1"
                  onClick={() => registerFunc()}
                >
                  가입
                </button>
              </div>
            </div>
          )
        )}
      </div>

      <PGForm />
    </div>
  );
}
