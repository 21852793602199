/* eslint-disable no-useless-escape */
export const regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/; // 휴대폰번호
export const regEmail =
  /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/; // 이메일

export const patternNum = /[0-9]/; // 숫자
export const patternPrice = /[0-9,]/; // 금액
export const patternEng = /[a-zA-Z]/; // 영문
export const patternEngUpper = /[A-Z]/; // 영문 대문자
export const patternSpc = /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/ ]/gim; // 특수문자 모두

export const patternSpcId = /[`~!#$%^&*()|+\=?;:'",<>\{\}\[\]\\\/ ]/gim; // 특수문자 아이디용
export const patternSpcPw = /[|+\=?;:'",.<>\{\}\[\]\\\/ ]/gim; // 특수문자 비밀번호용
export const patternSpcEmail = /[`~!#$%^&*()|+\-=?;:'"<>\{\}\[\]\\\/ ]/gim; // 특수문자 이메일용

export const patternKor = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/; // 한글체크

export const hpHypen = (str) => {
  return str.replace(
    /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
    "$1-$2-$3"
  );
};

export const numFormat = (num) => {
  if (num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return 0;
  }
};

export const findJson = (object, val) => {
  let title = "정보없음";

  object.forEach((one, index) => {
    if (one.idx * 1 === val * 1) {
      title = one.title;
    }
  });
  return title;
};

export const findJsonCl = (object, val) => {
  let title = "";

  object.forEach((one, index) => {
    if (one.idx * 1 === val * 1) {
      title = one.cl;
    }
  });
  return title;
};

export const findJsonNum = (object, val) => {
  let title = 0;

  object.forEach((one, index) => {
    if (one.idx * 1 === val * 1) {
      title = one.num;
    }
  });
  return title;
};

export const findArrInclues = (object, val, keyText) => {
  let chk = false;

  object.forEach((one, index) => {
    if (!keyText) {
      if (one * 1 === val * 1) {
        chk = true;
        return;
      }
    } else {
      if (one[keyText] * 1 === val * 1) {
        chk = true;
        return;
      }
    }
  });
  return chk;
};

export const getFileName = (url) => {
  let urls = url.split("/");
  return urls[urls.length - 1];
};

export const goLink = (navigate, route, state) => {
  console.log(state);
  navigate(route, { state: state });
};

export const clickImg = (imgsrc) => {
  let imageWin = new Image();
  imageWin = window.open("", "", "width=800px, height=600px");
  imageWin.onload = function () {
    imageWin.close();
  };
  imageWin.document.write("<html>");
  imageWin.document.write("<head></head>");
  imageWin.document.write("<body >");
  imageWin.document.body.style.backgroundColor = "#000000";

  imageWin.document.write(
    "<div style='display: flex ;flex-direction: column;align-items: flex-end;; width: 99vw; '>" +
      "<button onclick='Close()' style='width:50px; height:50px;type=reset; background: inherit ; border:none; box-shadow:none; border-radius:0; padding:0; overflow:visible; cursor:pointer;'>" +
      '<svg width="50" height="50" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">\n' +
      '    <g fill="none" fill-rule="evenodd">\n' +
      '        <path d="M0 24h24V0H0z"/>\n' +
      '        <path d="M13.595 12.48a.68.68 0 0 1 0-.96l6.174-6.173a.788.788 0 1 0-1.115-1.116l-6.174 6.174a.68.68 0 0 1-.96 0L5.347 4.231a.789.789 0 0 0-1.115 1.116l6.174 6.173a.678.678 0 0 1 0 .96L4.23 18.653a.788.788 0 1 0 1.115 1.116l6.175-6.174a.68.68 0 0 1 .96 0l6.173 6.174a.786.786 0 0 0 1.115 0 .788.788 0 0 0 0-1.116l-6.174-6.173z" fill="#FFF"/>\n' +
      "    </g>\n" +
      "</svg>\n</button>" +
      "<div style='display: flex; height: 100vh; padding-bottom: 50px; flex-direction: column;justify-content: center;'>" +
      "<img src='" +
      imgsrc +
      "' border=0 style='width: 100vw; object-fit: contain; margin-top:10px; margin-bottom: 60px;'/>" +
      // "<div class=\"down_btn\" style='width:100px; height:50px;type=reset; border: solid 1px #c5cee0; background: inherit ; border:none; box-shadow:none; border-radius:0; padding:0; overflow:visible; cursor:pointer;   background-color: #fff;color: #718096; font-size: 16px;font-weight: bold;transition: border .3s, color .3s;' > <a  href= '" + imgsrc + "'  type='image/html' download >" +
      // "<button style='height:50px;type=reset; background: inherit ; border:none; box-shadow:none; border-radius:0; padding:0; overflow:visible; cursor:pointer;''>다운로드</button></a>\n" +
      // "</div>" +
      "</div>" +
      "</div>"
  );
  imageWin.document.write(
    "<script> function Close() { window.close()} </script>"
  );
  imageWin.document.write("<html>");
};

export const groupBy = (xs, key) => {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};
