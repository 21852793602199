/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import * as APIS from "../../../utils/service";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export interface List {
  pick_table_id: string;
  pick_img: string;
  pick_name: string;
  update_date: string;
  state: string;
  pick_url: string;
  display_seq?: string;
}

interface Props {
  isList?: boolean;
  id?: string;
  state?: string;
}

const useHomeManagePick = (props?: Props) => {
  const { isList, id, state } = props ?? {};
  const userData = useSelector((s: any) => s.usersReducer);

  const [list, setList] = useState<List[]>([]);
  const [total, setTotal] = useState(0);

  const [detail, setDetail] = useState<List>();

  const params = new URLSearchParams(window.location.search);
  const page = params.get("page") || "1";

  const navigate = useNavigate();
  const { pathname } = window.location;

  const getList = async (page: string) => {
    const params = {
      admin_table_id: userData.admin_table_id,
      page: page,
      state: state,
    };

    try {
      const res = await APIS.postData("/AdminPick/PickList", params, 1);

      if (res.data.code === "OK") {
        setList(res.data.pick_list);
        setTotal(res.data.total_cnt);
      } else {
        setList([]);
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  useEffect(() => {
    if (isList) {
      getList(page);
    }
  }, [page]);

  const getTarget = async (): Promise<void> => {
    try {
      const res = await APIS.postData("/AdminPick/PickTarget", {
        admin_table_id: userData.admin_table_id,
        pick_table_id: id,
      });

      if (res.data.code === "OK") {
        setDetail(res.data.pick_list[0]);
      } else {
        setDetail(undefined);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (id) {
      getTarget();
    }
  }, [id]);

  const search = () => {
    if (page === "1") {
      getList("1");
    } else {
      navigate({
        pathname: pathname,
        search: `?page=${1}`,
      });
    }
  };

  const create = async (data: {
    pick_name: string;
    pick_img: string;
    pick_url: string;
  }): Promise<boolean | undefined> => {
    if (!data.pick_name || !data.pick_img || !data.pick_url) {
      alert("모든 항목을 입력해주세요.");
      return;
    }

    try {
      const res = await APIS.postData(
        "/AdminPick/PickCreate",
        {
          ...data,
          admin_table_id: userData.admin_table_id,
        },
        1
      );

      if (res.data.code === "OK") {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const update = async (
    data: Omit<List, "update_date">
  ): Promise<boolean | undefined> => {
    const { pick_img, pick_name, pick_url, state } = data;

    const isNewImage = data.pick_img.includes("data:image");

    if (!pick_name || !pick_url || !state || (!pick_img && !isNewImage)) {
      alert("모든 항목을 입력해주세요.");
      return;
    }

    try {
      const res = await APIS.postData(
        "/AdminPick/PickUpdate",
        {
          ...data,
          admin_table_id: userData.admin_table_id,
          pick_img: isNewImage ? pick_img : undefined,
        },
        1
      );

      if (res.data.code === "OK") {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const remove = async (ids: string[]): Promise<boolean | undefined> => {
    try {
      if (ids.length > 0) {
        if (window.confirm("해당 픽메뉴를 삭제 하시겠습니까?")) {
          const res = await APIS.postData(
            "/AdminPick/PickDel",
            {
              admin_table_id: userData.admin_table_id,
              pick_table_ids: ids,
            },
            1
          );

          if (res.data.code === "OK") {
            getList(page);

            return true;
          } else {
            return false;
          }
        }
      } else {
        alert("삭제 할 데이터를 선택해주세요.");
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const updateSeq = async (
    data: {
      pick_table_id: string;
      display_seq: string;
    }[]
  ): Promise<boolean | undefined> => {
    if (window.confirm("노출순위를 변경하시겠습니까?")) {
      try {
        const res = await APIS.postData(
          "/AdminPick/PickSeqUpdate",
          {
            admin_table_id: userData.admin_table_id,
            pick_data: data,
          },
          1
        );

        if (res.data.code === "OK") {
          return true;
        } else {
          return false;
        }
      } catch (err) {
        console.log(err);
        return false;
      }
    }
  };

  return {
    list,
    total,
    page,
    create,
    update,
    search,
    remove,
    updateSeq,
    detail,
  };
};

export default useHomeManagePick;
