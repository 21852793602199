/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import * as APIS from "../../utils/service";
import { findJson, numFormat } from "../../utils/utils";
import moment from "moment";
import Input from "../../components/Input";
import InputDate from "../../components/InputDate";
import InputSelect from "../../components/InputSelect";
import Pagenation from "../../components/Pagenation";
import Add from "./EstimateAdd";
import consts from "../../libs/consts";
import { open } from "../../redux/popupSlice";
import { setData } from "../../redux/searchSlice";
import AdminLayout from "../../components/Layout/admin";
import { css } from "@emotion/react";
import Table from "../../components/Common/Table/Table";
import Button from "../../components/Common/Button/Button";
import { CSVLink } from "react-csv";

export default function Estimate() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((s) => s.usersReducer);
  const search = useSelector((s) => s.searchReducer);
  const popup = useSelector((s) => s.popupReducer);

  /* search form */
  const [pageing, setPageing] = useState("");

  const [list, setList] = useState([]);
  const [downloadData, setDownloadData] = useState([]);
  const csvLinkRef = useRef();

  useEffect(() => {
    dataFunc();
  }, [
    search.city,
    search.range,
    search.membership,
    search.state,
    search.license,
    search.sdate,
    search.edate,
    search.page,
    popup.open,
  ]);

  const dataFunc = () => {
    const sender = {
      admin_table_id: userData.admin_table_id,
      order_state: search.state,
      start_date: search.sdate,
      end_date: search.edate,
      order_save_type: search.range,
      order_estimate_type: search.license,
      order_resp_state: search.membership,
      order_table_id: search.stx,
      page: search.page + 1,
    };

    APIS.postData("/AdminOrder/OrderList", sender, 1)
      .then((res) => {
        if (res.data.code === "OK") {
          setList(res.data.order_list);
          setPageing({
            totalPages: Math.ceil(res.data.total_cnt / consts.pagerows),
          });
        } else {
          setList([]);
          setPageing({ totalPages: 1 });
        }
      })
      .catch((e) => {
        console.log("catch", e);
      });
  };

  const searchFunc = (key, value) => {
    dispatch(
      setData({
        key: key,
        value: value,
      })
    );
  };

  const addFunc = () => {
    // 더미데이터
    dispatch(
      open({
        title: "견적관리 등록",
        wide: true,
        component: <Add />,
      })
    );
    return;
  };
  const keyPressFunc = (e) => {
    if (e.key === "Enter") {
      dataFunc();
    }
  };

  const getDownloadList = async () => {
    try {
      const sender = {
        admin_table_id: userData.admin_table_id,
        order_state: search.state,
        start_date: search.sdate,
        end_date: search.edate,
        order_save_type: search.range,
        order_estimate_type: search.license,
        order_resp_state: search.membership,
        order_table_id: search.stx,
        page: search.page + 1,
      };

      APIS.postData("/AdminOrder/OrderListDown", sender, 1)
        .then((res) => {
          if (res.data.code === "OK") {
            const { sellerOrderState, period_option } = consts;

            const list = res.data.order_list.map((item) => {
              return {
                ...item,
                order_state: sellerOrderState.find(
                  (test) => String(test.idx) === String(item.order_state)
                ).title,
                order_desired_period:
                  period_option.find(
                    (period) =>
                      String(period.idx) === String(item.order_desired_period)
                  )?.title || "알수 없음",
                work_type:
                  item.work_type === "0"
                    ? "미입력"
                    : item.work_type === "1"
                    ? "전체"
                    : item.work_type === "2"
                    ? "부분"
                    : "알수 없음",
                order_estimate_type:
                  item.order_estimate_type === "0"
                    ? "없음"
                    : item.order_estimate_type === "1"
                    ? "사례"
                    : item.order_estimate_type === "2"
                    ? "테마"
                    : "알수 없음",
                order_save_type:
                  item.order_save_type === "0"
                    ? "일반"
                    : item.order_save_type === "1"
                    ? "아파트너"
                    : item.order_save_type === "2"
                    ? "아파트 스토리"
                    : "알수 없음",
                img_list:
                  item.img_list.length > 0
                    ? JSON.stringify(
                        item.img_list.map((img) => {
                          return {
                            img: img.order_img,
                          };
                        })
                      ).replace(/"|img|:/g, "")
                    : "",
                work_list_adm:
                  item.work_list_adm.length > 0
                    ? JSON.stringify(
                        item.work_list_adm.map((work) => {
                          return {
                            text: work.tag_text,
                          };
                        })
                      ).replace(/"|text|:/g, "")
                    : "",
                estimate_msg_list:
                  item.estimate_msg_list.length > 0
                    ? JSON.stringify(
                        item.estimate_msg_list.map((msg) => {
                          return {
                            ...msg,
                            counsel_desc:
                              msg.counsel_desc === ""
                                ? "null"
                                : msg.counsel_desc,
                          };
                        })
                      ).replace(/"|counsel|_desc|:/g, "")
                    : "",
              };
            });

            setDownloadData(list);
            csvLinkRef.current.link.click();
          }
        })
        .catch((e) => {
          console.log("catch", e);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const downloadHeader = [
    { label: "견적 고유키", key: "order_table_id" },
    { label: "요청 구분", key: "order_save_type" },
    { label: "견적유형", key: "order_estimate_type" },
    { label: "시공범위", key: "work_type" },
    { label: "공사지역", key: "area_name" },
    { label: "아파트명", key: "order_home_name" },
    { label: "평형", key: "order_size" },
    { label: "공사희망일", key: "order_desired_date" },
    { label: "희망가격", key: "order_desired_price" },
    { label: "공사 기간", key: "order_desired_period" },
    { label: "진행상태", key: "order_state" },
    { label: "등록일시", key: "reg_date" },
    { label: "최종일시", key: "update_date" },
    { label: "휴대폰번호", key: "order_phone" },
    { label: "철거여부", key: "order_demolition_yn" },
    { label: "요청사항", key: "order_msg" },
    { label: "공사지 주소", key: "order_addr" },
    { label: "공사지 상세주소", key: "order_area_2nd" },
    { label: "스타일 구매 여부", key: "style_buy" },
    { label: "견적 사진", key: "order_photo" },
    { label: "direct_write", key: "direct_write" },
    { label: "seller_table_id", key: "seller_table_id" },
    { label: "참고사진 리스트", key: "img_list" },
    { label: "시공정보 리스트", key: "work_list_adm" },
    { label: "상담메시지리스트 리스트", key: "estimate_msg_list" },
  ];

  const col = [
    {
      key: "order_save_type",
      title: "요청구분",
      render: (x) => (
        <span>{findJson(consts.orderSaveType, x.order_save_type)}</span>
      ),
    },
    { key: "order_table_id", title: "요청번호" },
    {
      key: "order_estimate_type",
      title: "견적유형",
      render: (x) => (
        <span>{findJson(consts.sellerOrderType, x.order_estimate_type)}</span>
      ),
    },
    {
      key: "work_type",
      title: "시공범위",
      render: (x) => (
        <span>
          {x.work_type === "1"
            ? "전체"
            : x.work_type === "2"
            ? "부분"
            : "미입력"}
        </span>
      ),
    },
    { key: "area_name", title: "시공지역" },
    { key: "order_home_name", title: "아파트명" },
    {
      key: "order_desired_date",
      title: "희망시공일",
      render: (x) => (
        <span>
          {" "}
          {x.order_desired_date === "0000-00-00 00:00:00" ||
          x.order_desired_date === null
            ? "미정"
            : moment(x.order_desired_date).format("YYYY.MM.DD")}
        </span>
      ),
    },
    {
      key: "order_desired_price",
      title: "희망가격",
      render: (x) => <span>{numFormat(x.order_desired_price)}만원</span>,
    },
    {
      key: "order_state",
      title: "진행상태",
      render: (x) => (
        <span
          css={css`
            color: #48bb78;
          `}
        >
          {findJson(consts.sellerOrderState, x.order_state)}
        </span>
      ),
    },
    {
      key: "reg_date",
      title: "요청일시",
      render: (x) => (
        <span>{moment(x.reg_date).format("YYYY.MM.DD HH:mm")}</span>
      ),
    },
    {
      key: "update_date",
      title: "최종일시",
      render: (x) => (
        <span>{moment(x.update_date).format("YYYY.MM.DD HH:mm")}</span>
      ),
    },
  ];

  return (
    <AdminLayout title="견적 관리">
      <div>
        <div className="search_form search_form_long">
          <div className="search_form_left">
            <div className="search_form_long_box search_form_long_box_2">
              <InputSelect
                name="range"
                label="요청구분"
                value={search.range}
                onSearch={searchFunc}
                option={[{ idx: "", title: "전체" }, ...consts.orderSaveType]}
              />
              <InputSelect
                name="license"
                label="견적유형"
                value={search.license}
                onSearch={searchFunc}
                option={[{ idx: "", title: "전체" }, ...consts.sellerOrderType]}
              />
              <InputSelect
                name="state"
                label="진행상태"
                value={search.state}
                onSearch={searchFunc}
                option={[
                  { idx: "", title: "전체" },
                  ...consts.sellerOrderState,
                ]}
              />

              <Input
                className="input_text"
                type="text"
                placeholder={"요청번호 입력"}
                name="stx"
                value={search.stx}
                onSearch={searchFunc}
                onKeyDown={keyPressFunc}
                label={"요청번호"}
              />
              <InputDate
                type="text"
                placeholder="시작날짜"
                placeholderEnd="종료날짜"
                name="ipd1"
                label="날짜선택"
                dateValue={search.sdate}
                onSearch={searchFunc}
                dateValueEnd={search.edate}
                multiple={true}
              />
            </div>

            <button
              type="button"
              className="btn btn3"
              onClick={() => dataFunc()}
            >
              검색
            </button>
          </div>
          <div className="search_form_right" style={{ marginBottom: "14px" }}>
            <button
              type="button"
              className="btn btn1"
              onClick={() => addFunc()}
            >
              등록
            </button>
          </div>
        </div>
        <div>
          <Button theme="tertiary" onClick={getDownloadList}>
            DB 다운로드
          </Button>
          <CSVLink
            headers={downloadHeader}
            data={downloadData}
            ref={csvLinkRef}
            filename={
              "견적 관리_" +
              moment(new Date()).format("YYYY_MM_DD_HH_mm_ss") +
              ".csv"
            }
            target="_blank"
          />
        </div>
        <div
          css={css`
            margin: 30px 0 20px 0;
          `}
        >
          <Table
            columns={col}
            datas={list}
            rowCallback={(x) => navigate("/estimateDetail/" + x.order_table_id)}
          />
        </div>

        <Pagenation
          pageing={pageing}
          onSearch={searchFunc}
          curpage={search.page}
        />
      </div>
    </AdminLayout>
  );
}
